import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling'
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { config } from '../../utils/apiUrl';



function RegistrionReport() {
    let api = new API()
    const [start, setstart] = useState('')
    const [end, setEnd] = useState('')
    const [data, setReport] = useState()
   
    const columns = [
        {
            name: "Full Name",
            selector: "fullName",
            sortable: true
        },
        {
            name: "email",
            selector: "email",
            sortable: true
        },
        {
            name: "mobile",
            selector: "mobile",
            sortable: true
        },
        {
            name: "gender",
            selector: "gender",
            sortable: true
        },
        {
            name: "date",
            selector: "date",
            sortable: true
        },
        {
            name: "Refer Code",
            selector: "referCode",
            sortable: true
        },
        {
            name: "Country",
            selector: "country",
            sortable: true
        },
        
    ]
    const tableData = {
        columns,
        data
    };


    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        let data = {
            "endDate": start,
            "startDate": end
        }
        let result = await api.post(config.adminDashboardRegistrationReport, data)
        if (result.code === 200) {
            setReport(result.data)
            
        }
    }
    const referByReport = async()=>{
        let data = {
            "endDate": start,
            "startDate": end
        }
        let result = await api.post(config.adminDashboardTotalRefercodeCount,data)
            if(result.code === 200){
                setReport(result.data)

            }
        
    }

    return (

        <div>
            <div className="col-lg-5">
                <div className="d-flex flex-wrap flex-row align-items-center mndin">
                    <div className="divmnt">
                        <u> <input placeholder="Select your date" type="date" className=" fw-semibold text-secondary inpcls" onChange={e => setEnd(e.target.value)} name="checkIn" /> </u>
                    </div>
                    <h6 className="forange mx-2 fw-semibold">to</h6>
                    <input placeholder="Select your date" type="date" className=" dashbin fw-semibold text-secondary " onChange={e => setstart(e.target.value)} name="checkIn" />
                </div>
                <button className='newButton' onClick={getData}>Registration Report</button >&nbsp;
                <button className='newButton' onClick={referByReport}>Refer by Report</button>
            </div>
            
            <DataTableExtensions {...tableData}>
                <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="_id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                />
            </DataTableExtensions>
        </div>


    )
}

export default RegistrionReport;