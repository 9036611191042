import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import RewardUseModal from '../common/rewardUserModal'
import { Redirect, Link, useHistory } from "react-router-dom";
import Loader2 from '../../utils/loader2/loader2'

const PendingRedeemed = (props) => {
    const api = new API()
    const history = useHistory()

    const [rewardUser, setRewardUSer] = useState([])
    const [userRedeemHistory, setUserRedeemHistory] = useState([])
    const [userName, setUserName] = useState()
    const [loader_2, setLoader_2] = useState(true)
    const [emailForRedeemRequest, setEmailForRedeemRequest] = useState()
    const [user, setUser] = useState()
    const [type, setType] = useState()
    const [loader2, setLoader2] = useState(true)

    let adminToken = getLocalDataAsObject("adminToken")
    if (!adminToken) {
        history.push("/dashboard")
    }

    useEffect(() => {
        getPendingRewardUser()
    }, [])
    const getPendingRewardUser = async () => {
        const result = await api.post(config.adminDashboardPendingRedeemPointHistory, "none")
        if (result && result.code === 200) {
            setLoader2(false)
           
            setRewardUSer(result.data)

        }
    }
    const getUserAllRedeem = async (user, type) => {

        setType(type)
        setUserRedeemHistory("")
        setLoader_2(true)
        setUser(user)
        // let UserName = user.firstName + ' ' + user.lastName
        
        let data = {
            email: user.user,
            type: type
        }

        const result = await api.post(config.adminDashboardRedeemPointHistory, data)
        if (result && result.status == "success") {
          
            let finalResult = result.data[0].redeemPoint
            setUserRedeemHistory(finalResult)
            // setUserName(UserName)
            setEmailForRedeemRequest(user.user)
            setLoader_2(false)
        }
    }

    return (
        <div>
            <div className="container mt-4">
                {loader2 == true ?
                    <div className="container" style={{ marginTop: "10%" }}>
                        <Loader2 />
                    </div>
                    : <>
                        {rewardUser.map((rewarduser, index) => (
                            <div className="card mb-5" style={{ backgroundColor: "gray" }}>
                                <div className="card-header">
                                    <h6 className="text-white">{index + 1} Email :{rewarduser.user} <span style={{ color: "red", float: "right" }}>pending coupons</span></h6>
                                    <div className="card-body" style={{ borderTop: "1px solid" }}>
                                        <div className="row">

                                            <div className="col-md-10 item-center">
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <center><button onClick={() => getUserAllRedeem(rewarduser, "all")} data-toggle="modal" data-target="#rewardUserModal" className="btn btn-link btn-block col-md-6 text-white">Show All Redeem</button></center>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <center><button onClick={() => getUserAllRedeem(rewarduser, "pendingRedeem")} data-toggle="modal" data-target="#rewardUserModal" className="btn btn-link btn-block col-md-6 text-white">Approve One by One Pending</button></center>
                                                    </div>
                                                </div>
                                                {/* <center><button onClick={() => getUserAllRedeem(rewarduser)} className="btn btn-link btn-block col-md-6 mt-3 text-white">Show All Redeem Rewards</button></center> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="card-footer">
                               
                            </div> */}

                            </div>
                        ))}
                    </>}
            </div>
            <RewardUseModal userRedeemHistory={userRedeemHistory}
                userName={userName} loader_2={loader_2} emailForRedeemRequest={emailForRedeemRequest}
                getUserAllRedeem={getUserAllRedeem} user={user} type={type} />
        </div>

    );
}

export default PendingRedeemed;