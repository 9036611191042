
import React, { useState, useEffect } from 'react'
import Loader2 from '../utils/loader2/loader2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import API from '../utils/apiCalling';
import DataTable from "react-data-table-component";
import { capitalizeFirstLetter } from '../utils/CommonFunction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { set } from 'react-ga';
import Config from '../config/env'



function PanelistManagement() {
    const api = new API()
    const history = new useHistory()
    const [data, setData] = useState([])
    const [loader2, setLoader2] = useState(true)
    const [query, setQuery] = useState()
    const [input, setInput] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [click, setClick] = useState(false)
    const [id, setId] = useState()
    const [details, setDetails] = useState()
    const [user, setUser] = useState()
    const [pageCount, setPageCount] = useState(0);
    const [pagination, setPagination] = useState(0);




    useEffect(() => {
        fetchUsers();
    }, [])


    const fetchUsers = async (count, clear, e) => {
        let data = {
            filter: "panelist",
            pageSize: 10,
            searchKeywords: e ? e : "",
            startDate: startDate ? startDate : "",
            endDate: endDate ? endDate : "",
            pageNumber: count,
        }
        if (clear === "date") {
            data = {
                filter: "panelist",
                pageSize: 10,
            }
        }


        const result = await api.post(`${Config.new.site_api}/client-survey/getClientUsers`, data)

        if (result.code === 200) {
            setLoader2(false)
            setData(result.data);
            setPagination(result.valueCount)
        }
    }



    const clearData = (e) => {
        setQuery("")
        setStartDate("")
        setEndDate("")
        fetchUsers("", "date");
        e.preventDefault()

    }

    const sendPageCount = (value) => {
        if (value === 'back') {

            setPageCount(pageCount - 1)

            fetchUsers(pageCount - 1);

        } else if (value === "next") {

            setPageCount(pageCount + 1)

            fetchUsers(pageCount + 1);
        }
    }

    const clickable = async (e) => {

        let data = {
            _id: e,
        }

        const result = await api.post(`${Config.new.site_api}/admin-dashboard/getUserDetails`, data)

        if (result.code === 200) {
            setUser(result.data[0])
            setDetails(result.data[0]?.UserDetails.data[0]);
        }
    }
    const handleClick = (e) => {
        setQuery(input)
        fetchUsers("", "", input);

        e.preventDefault()
    }

    const updateDate = (e) => {
        fetchUsers();
        e.preventDefault()
    }

    const ClickingChagnge = (e) => {
        setClick(!click)
        setId(e)
        clickable(e)


    }





    return (
        <>

            <div className="main position-relative ">
                <div className="header m-2 ">
                    <form className="d-flex " role="search" >
                        <input className="form-control me-2 w-auto" type="search" value={input} placeholder="Search" aria-label="Search" onChange={(e) => setInput(e.target.value)} />
                        <button className="btn btn-outline-secondary" type="submit" onClick={handleClick}>Search</button>
                        <input type="date" className="ml-2" id="birthday" value={startDate} onChange={(e) => setStartDate(e.target.value)} name="from"></input>
                        <input type="date" className="ml-2" id="birthday" value={endDate} onChange={(e) => setEndDate(e.target.value)} name="from"></input>
                        <button className='btn btn-secondary ml-2 text-white' onClick={(e) => updateDate(e)}><i className="fa fa-filter" aria-hidden="true"></i></button>
                        <button className='btn btn-secondary ml-2 text-white' onClick={(e) => clearData(e)}><i class="fa fa-ban"></i></button>
                    </form>
                </div>
                <div style={{ textAlign: 'center', marginBottom: "10" }} >
                    <table className="table table-bordered" style={{ border: "1px solid #f47321" }} >

                        <thead style={{ position: "sticky", top: "0" }} >
                            <tr style={{ backgroundColor: "Gray" }}>
                                {/* <th scope="col">ID</th> */}
                                <th scope="col">Email</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Date of Birth</th>
                                <th scope="col">city</th>
                                <th scope="col">date</th>
                            </tr>
                        </thead>

                        {loader2 == true ?
                            <thead><tr><td></td><td></td></tr><div className="container mt-2  " style={{ maxHeight: "100%", maxWeidth: "100%" }}>
                                <Loader2 />
                            </div></thead>
                            :
                            <tbody>
                                <>

                                    {
                                        data.length > 0 ?
                                            <>
                                                {data.map((item) => (

                                                    <tr onClick={(e) => ClickingChagnge(item._id)}>
                                                        {/* <td key={item._id}>{item._id}</td> */}

                                                        <td  >{item.email}</td>
                                                        <td >{item.mobile}</td>
                                                        <td >{item.firstName}</td>
                                                        <td >{item.gender}</td>
                                                        <td >{item.dob}</td>
                                                        <td >{item.city}</td>
                                                        <td >{item.date}</td>

                                                    </tr>

                                                ))}
                                            </>
                                            :
                                            <><h1>No Data Available</h1></>
                                    }
                                </>




                            </tbody>}
                    </table>
                                <nav aria-label="Page navigation example ">
                                    <ul className=" pagination d-flex justify-content-center m-3">
                                        <li class="page-item m-1">

                                            {pageCount > 0 && <button class="btn btn-outline-primary" onClick={() => sendPageCount("back")} tabindex="-1"><i class="fa fa-chevron-left"></i></button>}
                                        </li>
                                        <li class="page-item btn border-0"><button class="page-link border-0" >{pageCount + 1}<></></button></li>

                                        <li class="page-item m-1">
                                            <button class="btn btn-outline-primary" onClick={() => sendPageCount("next")} ><i class="fa fa-chevron-right"></i></button>
                                        </li>
                                        <h4>Total Data-{pagination}</h4>
                                    </ul>
                                </nav>
                </div>



            </div>




            <Modal
                show={click}
                onHide={ClickingChagnge}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div class="card">
                        {details && details !== undefined ? <>

                            <div class="card-body">
                                <h5 >Name--{capitalizeFirstLetter(user.firstName) + " " + capitalizeFirstLetter(user.lastName)}</h5>

                            </div>
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item">Point Earned--{details.pointEarned}</li>
                                <li class="list-group-item">Point Redeemed--{details.pointRedeemed}</li>
                                <li class="list-group-item">Point Available--{details.pointAvailable}</li>
                                <li class="list-group-item">Profile Percent--{details.profilePercent}</li>
                                <li class="list-group-item">Point Available--{details.completedSurvey}</li>
                                <li class="list-group-item">Date--{details.date}</li>
                            </ul></>
                            : "No data Avaialable"}

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setClick(!click)}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}



export default PanelistManagement
