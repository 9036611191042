import React, { useEffect, useState } from "react";
import './npsCss.css'
import NpsShare from "./npsShare";
import JoditEditor from "jodit-react";
import API from '../../../utils/apiCalling';
import Config from '../../../config/env'
import { CSVLink } from "react-csv";
import Loader from '../../../utils/loader';
import { toast, ToastContainer } from 'react-toastify';

const NpsSmsShare = (props) => {
  console.log(props?.surveyData.survey, "props")
  let api = new API()
  const [screen, setScreen] = useState(2)
  const [editor, setEditor] = useState()
  const [contant, setContant] = useState()
  const [excelData, setExceldata] = useState([])
  const [loader, setLoader] = useState(false)
  console.log(props.surveyData.id)
  useEffect(() => {
    setScreen(props.screen)
    getExcelData()
  }, [])

  const getExcelData = async () => {
    let data = {
      surveyId: props.surveyData.id
    }
    const surveyData = await api.post(`${Config.new.site_api}/client-survey/getDataById`, data)
    if (surveyData.code === 200) {
      const data = []
      
      setExceldata(surveyData.data)
    }
  }

  const uploadExcel = async (e) => {
    setLoader(true)
    // console.log(e.target.files, "jhjhj")
    const file = e.target.files[0]
    let data = new FormData()
    data.append('file', file)
    // console.log(data, "jjhj")
    data.append('id', props.surveyData.id)
    data.append('client_id',props?.surveyData.survey.User_Id)
    let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadNpsExcel`, data)
    // console.log(result, "rrrrrrrrrrrrrrrrr")
    setLoader(false)
    if (result.code == 200) {
      toast.success(result.message, {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error(result.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }
  return (
    <>
      {/* <header>
    <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm">
      <div className="container-fluid">
        <a className="navbar-brand" href="javascript:void(0);">
          <img src="../img/new-logo.png" width={100} />
        </a>
        <div className="menuBoxx">
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <a href="" className="me-4 homeB">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.189"
              height="25.181"
              viewBox="0 0 25.189 25.181"
            >
              <g
                id="Group_25243"
                data-name="Group 25243"
                transform="translate(-225.573 -1117.25)"
              >
                <g
                  id="Icon_ionic-ios-home"
                  data-name="Icon ionic-ios-home"
                  transform="translate(225.573 1117.25)"
                >
                  <path
                    id="Path_17078"
                    data-name="Path 17078"
                    d="M16.907,7.312a.248.248,0,0,0-.313,0l-9.751,7.68a.257.257,0,0,0-.094.191V29.335a.124.124,0,0,0,.125.123h6.751a.124.124,0,0,0,.125-.123V20.72a.124.124,0,0,1,.125-.123h5.75a.124.124,0,0,1,.125.123v8.615a.124.124,0,0,0,.125.123h6.751a.124.124,0,0,0,.125-.123V15.182a.247.247,0,0,0-.094-.191Z"
                    transform="translate(-4.159 -4.278)"
                    fill="#354c9e"
                  />
                  <path
                    id="Path_17079"
                    data-name="Path 17079"
                    d="M28.235,11.8l-11.44-8.17a1.457,1.457,0,0,0-1.659,0l-4.982,3.61V4.967a.115.115,0,0,0-.121-.108H6.4a.115.115,0,0,0-.121.108V9.942L3.7,11.836a.739.739,0,0,0-.321.55.72.72,0,0,0,.248.577.9.9,0,0,0,.6.221.92.92,0,0,0,.533-.167L15.887,5.032a.118.118,0,0,1,.079-.022.16.16,0,0,1,.079.022l11.132,7.954a.92.92,0,0,0,.533.167.892.892,0,0,0,.6-.221.707.707,0,0,0,.254-.577A.746.746,0,0,0,28.235,11.8Z"
                    transform="translate(-3.374 -3.375)"
                    fill="#354c9e"
                  />
                </g>
              </g>
            </svg>
          </a>
          <div className="navbar-collapse collapse" id="navbarSupportedContent">
           
            <ul className="navbar-nav menu_top_link w-100">
              <li>
                <a href="javascript:void(0);" className="text-center d-block">
                  <span>Create Survey</span>
                </a>
              </li>
              <li>
                <a href="share.html" className="text-center d-block active">
                  <span>Share</span>
                </a>
              </li>
              <li>
                <a href="report-3.html" className="text-center d-block ">
                  <span>Report</span>
                </a>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto right-side-menu">
             
              <li>
                <a href="javascript:void(0);" className="">
                  Survey
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className="">
                  Admin
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  className="position-relative"
                  data-bs-toggle="dropdown"
                >
                  Notification
                  <span
                    className="badge bg-danger position-absolute"
                    style={{ top: "-10px" }}
                  >
                    3
                  </span>
                </a>
                <div
                  className="dropdown"
                  style={{ position: "absolute", right: 416, marginTop: 10 }}
                >
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        <i className="bi bi-chat-left-fill" /> Kamikaze Day 1
                        survey created
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <i className="bi bi-chat-left-fill" /> Kamikaze Day 2
                        survey created
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <i className="bi bi-file-earmark-text-fill" /> New
                        report available
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="profile-setting.html" className="userprofile">
                  <img src="../img/profile.png" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className="toggleTheme">
                  <img src="../img/top-toggle.png" />
                  <img src="../img/top-toggle-dark.png" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className="logoutUser">
                  <i className="fa fa-sign-out" aria-hidden="true" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>  */}
      {screen == 2 &&
        <main className="mainSec">
          {loader && <Loader />}
          <section className="main-dash-sec bg-white">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3">
                  <div
                    className=" p-3 position-relative Mmail_Email"
                    style={{ height: "90vh" }}
                  >
                    <div className="mb-2" onClick={() => { setScreen(0) }}>
                      <button className="border-0 bg-transparent d-flex align-items-center ">
                        {" "}
                        <img src="../../img/back-orange.svg" alt="arrow" width="10px" />
                        <h6 className="org-clr m-0 mx-2">Back </h6>
                      </button>
                      <h5 className="blue-clr fw-semibold">Email</h5>
                    </div>
                    <hr className="text-secondary" />
                    <div className="d-flex">
                      <div
                        className="nav flex-column nav-pills w-100 Email-2-share me-3"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                      >
                        <div className="First_tabs d-flex align-items-center mb-4 border-0">
                          <button
                            className="nav-link active text-black text-start fontsi13 w-75"
                            id="v-pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-home"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-home"
                            aria-selected="true"
                          >
                            Survey Trigger SMS
                          </button>
                          <label
                            className="switch text-black"
                            style={{ marginTop: 10 }}
                          >
                            <input type="checkbox" />
                            <span
                              className="slider round mb-0"
                              style={{ right: "-75px" }}
                            />
                          </label>
                        </div>
                        <div className="First_tabs_2 d-flex align-items-center">
                          <button
                            className="nav-link text-black fontsi13 text-start w-75"
                            id="v-pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#v-pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="v-pills-profile"
                            aria-selected="false"
                          >
                            Thank you SMS
                          </button>
                          <label
                            className="switch text-black"
                            style={{ marginTop: 10 }}
                          >
                            <input type="checkbox" />
                            <span
                              className="slider round mb-0"
                              style={{ right: "-75px" }}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="text-center mx-auto position-absolute bottom-0 left-0  w-100">
                      <button
                        className="ntun-mn bg-nones my-3"
                        data-bs-target="#exampleModalToggleSurveyT"
                        data-bs-toggle="modal"
                      >
                        Share Selected SMS
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 bg-white">
                  <div
                    className="Email_2 scrl"
                    style={{ overflow: "auto", height: 600, overflowX: "hidden" }}
                  >
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="v-pills-home"
                        role="tabpanel"
                        aria-labelledby="v-pills-home-tab"
                        tabIndex={0}
                      >
                        <div className="row">
                          <div
                            className="Mobiles position-relative"
                            style={{
                              width: 250,
                              height: 480,
                              padding: "30px 0px 10px",
                              margin: "auto"
                            }}
                          >
                            <img
                              src="../../img/PhoneSMS1.png"
                              alt="image"
                              className="lgoph position-absolute w-100 top-0 left-0"
                              style={{ height: "100%" }}
                            />
                            <div
                              className="Custmize_setting position-absolute"
                              style={{
                                right: "-79px",
                                top: 85,
                                transform: "rotate(90deg)",
                                borderRadius: "0px 5px 5px 0px"
                              }}
                            >
                              <button
                                className="border-0 bg-transparent"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                              >
                                <span className="blue-clr cus fw-bold text-end">
                                  <i className="bi bi-gear-fill" />
                                  &nbsp; Customize
                                </span>
                              </button>
                            </div>
                            <div
                              className="imag-phn scrl"
                              style={{
                                overflow: "auto",
                                overflowX: "hidden",
                                height: "100%"
                              }}
                            >
                              <div className="row">
                                <div className="Question p-3">
                                  <div className="massages-Email">
                                    <h6
                                      className="fontsi12 text-center grays-clr"
                                      style={{ paddingTop: 80, paddingBottom: 20 }}
                                    >
                                      Today,12:40 AM
                                    </h6>
                                    <div className="grays-bg-clr mx-4 p-2 rounded-2">
                                      <p className="fontsi12 fw-semibold">
                                        Hi {"{"}contact.fullName{"}"},
                                        <br />
                                        <br /> Thank you for responding to our survey.
                                        <br /> <br /> we appeciate you for <br />
                                        contributing your valuable time, <br /> your
                                        honest information, and
                                        <br /> your thoughtful suggestions <br />
                                        Thanks <br />
                                        Team {"{"}companyName{"}"}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="v-pills-profile"
                        role="tabpanel"
                        aria-labelledby="v-pills-profile-tab"
                        tabIndex={0}
                      >
                        <div className="row">
                          <div
                            className="Mobiles position-relative"
                            style={{
                              width: 250,
                              height: 480,
                              padding: "30px 0px 10px",
                              margin: "auto"
                            }}
                          >
                            {/* background-image: url('img/Phone.png'); background-repeat: no-repeat; background-size: contain; */}
                            <img
                              src="../../img/PhoneSMS_1.png"
                              alt="image"
                              className="lgoph position-absolute w-100 top-0 left-0"
                              style={{ height: "100%" }}
                            />
                            <div
                              className="Custmize_setting position-absolute"
                              style={{
                                right: "-79px",
                                top: 85,
                                transform: "rotate(90deg)",
                                borderRadius: "0px 5px 5px 0px"
                              }}
                            >
                              <button
                                className="border-0 bg-transparent"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#offcanvasRight"
                                aria-controls="offcanvasRight"
                              >
                                <span className="blue-clr cus fw-bold text-end">
                                  <i className="bi bi-gear-fill" />
                                  &nbsp; Customize
                                </span>
                              </button>
                            </div>
                            <div
                              className="imag-phn scrl"
                              style={{
                                overflow: "auto",
                                overflowX: "hidden",
                                height: "100%"
                              }}
                            >
                              <div className="row">
                                <div className="Question p-3">
                                  <div className="massages-Email">
                                    <h6
                                      className="fontsi12 text-center grays-clr"
                                      style={{ paddingTop: 80, paddingBottom: 20 }}
                                    >
                                      Today,12:40 AM
                                    </h6>
                                    <div className="grays-bg-clr mx-4 p-2 rounded-2">
                                      <p className="fontsi12 fw-semibold">
                                        Hi {"{"}contact.fullName{"}"},
                                        <br />
                                        <br /> New survey <br /> <br />
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Dolor, natus! Laboriosam,
                                        dignissimos.
                                      </p>
                                      <p className="fontsi12 fw-semibold">
                                        {" "}
                                        Thanks, <br />
                                        Best &amp; Regards
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Modal Survey Trigar */}
          <div
            className="modal fade"
            id="exampleModalToggleSurveyT"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabIndex={-1}
          >
            <div className="modal-dialog modal-dialog-centered survey_trigar rounded-0">
              <div className="modal-content rounded-0">
                <div className="modal-header">
                  <h1 className="modal-title fs-6 fw-semibold" id="exampleModalToggleLabel">
                    Survey Trigger
                  </h1>
                  <button
                    type="button"
                    className="btn-close fs-6"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <lottie-player
                      src="../../img/lf30_editor_7nocnjt0.json"
                      className="mx-auto"
                      background="transparent"
                      speed={1}
                      style={{ width: 215 }}
                      loop=""
                      autoPlay=""
                    />
                  </div>
                  <p className="text-black text-center fontsi14">Simple Dummy Text Enter</p>

                  <div className="Schedule d-flex justify-content-around">
                    <button
                      className="ntun-mns sche"
                      data-bs-target="#exampleModalToggleSetRem"
                      data-bs-toggle="modal"
                    >
                      Schedule Survey
                    </button>
                    <button
                      className="ntun-mn bg-nones"
                      data-bs-target="#exampleModalToggleSurveyTsucc"
                      data-bs-toggle="modal"
                    >
                      Survey Trigger Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal Survey Trigar Close */}
          {/* Modal Survey set reminder start */}
          <div
            className="modal fade"
            id="exampleModalToggleSetRem"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabIndex={-1}
          >
            <div className="modal-dialog modal-dialog-centered SetRemnder rounded-0">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-6 fw-semibold" id="exampleModalToggleLabel">
                    Set Reminder
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="Remindr d-flex justify-content-between align-items-center gap-2">
                    <div
                      className="Select-Time border d-flex justify-content-between"
                      style={{ padding: "12px 20px" }}
                    >
                      <span className="fontsi13 fw-semibold">Select Date</span>
                      <img
                        src="../../img/select_Date.svg"
                        alt=""
                        className="ms-4"
                        width="15px"
                      />
                    </div>
                    {/*  */}
                    <div
                      className="Select-Time border d-flex justify-content-between"
                      style={{ padding: "12px 20px" }}
                    >
                      <span className="fontsi13 fw-semibold">Select Time</span>
                      <img
                        src="../../img/Icon awesome-clock.svg"
                        alt=""
                        className="ms-3"
                        width="17px"
                      />
                    </div>
                    {/*  */}
                    <div className="Select-Time ">
                      <img src="../../img/plus_reminder.svg" alt="" width="25px" />
                    </div>
                    {/*  */}
                  </div>
                  <div className="text-center">
                    <button className="ntun-mn bg-nonese mt-5 mb-3">
                      Save Reminder
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal Survey set reminder End */}
          <div
            className="offcanvas offcanvas-end Edt-Email"
            tabIndex={-1}
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title fs-6 fw-semibold" id="offcanvasRightLabel">
                Edit SMS Template
              </h5>
              <hr />
            </div>
            <div encType="multipart/form-data">
              <div className="offcanvas-body pt-0">
                <div className="to-Emal d-flex justify-content-between">
                  <h6>
                    To <img src="../img/i_icon_black.svg" alt="icon" width="18px" />
                  </h6>
                  <button className="border-0 bg-transparent">
                    <h6 className="org-clr fontsi13">
                      <img src="../img/Download-d.svg" alt="" />
                      &nbsp; Download CSV Format
                    </h6>
                  </button>

                </div>
                <div className="input-group mb-1" style={{ height: 42 }}>
                  <input
                    type="email"
                    name="user_mail"
                    className="form-control rounded-0"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    style={{ height: 42 }}
                  />
                  <input type="file" name="user_mail" id="upload" style={{ display: "none" }} onChange={(e) => uploadExcel(e)} />
                  <label
                    htmlFor="upload"
                    className="input-group-text fontsi12 imprt rounded-0 text-white"
                  >
                    + Import Recipents
                  </label>
                  <CSVLink data={excelData}
                    filename={`${props?.surveyData.surveyName} Report.csv`} >
                    <button
                      className="input-group-text fontsi12 imprt rounded-0 text-white"
                      id="basic-addon2" style={{ height: 42 }}
                    >
                      <div className="bi " /> Download Excel With URL'S
                    </button></CSVLink>
                </div>
                <div className="text-area d-flex justify-content-between my-2">
                  <h6 className="grays-clr fontsi14 fw-semibold">Subject</h6>
                  <span className="grays-clr fontsi12">24/140</span>
                </div>
                <div className="form-floating mb-2 my-2">
                  <textarea
                    className="form-control"
                    name="subject"
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: 100 }}
                    required=""
                    defaultValue={""}
                  />
                  <label htmlFor="floatingTextarea2" className="fontsi12">
                    Thank you for helping us out!
                  </label>
                </div>
                <select
                  className="form-select selt border-0 fontsi14 mb-2"
                  name="from"
                  aria-label="Default select example"
                >
                  <option className="" value={1}>
                    From :&nbsp; yourmail@gmail.com
                  </option>
                  <option value={1}>One</option>
                  <option value={2}>Two</option>
                  <option value={3}>Three</option>
                </select>
                <div className="ck-editor mb-2">
                  <h6 className="grays-clr fontsi14 fw-semibold">Mail body</h6>
                  {/* <textarea
                                    name="mail_body"
                                    className="scrl"
                                    id="editor"
                                    style={{ height: 250 }}
                                    required=""
                                    defaultValue={"\n                         "}
                                /> */}
                  <div className="scrl"
                    id="editor"
                    style={{ height: 250 }}>
                    <JoditEditor
                      ref={editor}
                      value={contant}
                      onChange={newContent => setContant(newContent)}
                    />
                  </div>
                </div>
                <div className="set">
                  <h5 className="text-black fw-semibold fs-6">SETTINGS</h5>
                  <label className="switch w-100 text-black mb-2 ">
                    Show Company Logo
                    <input type="checkbox" name="company_logo" />
                    <span className="slider round" />
                  </label>
                  <label className="switch w-100 text-black">
                    Show SurveyCXM Logo
                    <input type="checkbox" name="surveycxm_logo" defaultChecked="" />
                    <span className="slider round" />
                  </label>
                </div>
                <div className="save-teemp d-flex my-2 ">
                  <button
                    className="border-0 text-white fontsi14 rounded-1 px-4 py-2 d-flex align-items-center"
                    style={{ backgroundColor: "#F47321" }}
                  >
                    Save&nbsp; <i className="bi bi-check2-all fontsi18" />
                  </button>
                  <button
                    className="border-0 text-black fontsi14 px-4 py-2 bg-transparent"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    <u> Cancel</u>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Off Canvas E-mail Template  end */}
          {/* modal Survey triggar mail successfully start */}
          <div
            className="modal fade"
            id="exampleModalToggleSurveyTsucc"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabIndex={-1}
          >
            <div className="modal-dialog modal-dialog-centered survey_trigar">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-6 fw-semibold" id="exampleModalToggleLabel">
                    Survey Triggered Successfully
                  </h1>
                  <button
                    type="button"
                    className="btn-close fs-6"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div className="text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <lottie-player
                      src="../../img/5242-check-orange.json"
                      className="mx-auto"
                      background="transparent"
                      speed={1}
                      style={{ width: 215 }}
                      loop=""
                      autoPlay=""
                    />

                  </div>
                  <p className="text-black text-center fw-semibold fontsi14">
                    Your Survey is triggered successfully
                  </p>
                  <div className="Schedule text-center mt-2">
                    <button className="ntun-mn bg-nonese">My Workspace</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal Survey triggar mail successfuly end */}
          <ToastContainer />
        </main>}
      {screen === 0 && <NpsShare sereen={screen} />}
    </>

  )
}
export default NpsSmsShare;