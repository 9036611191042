import React from 'react';


const ErrorPage = () => {
    return (
        <div className='main404'>
            <section id="all-section" className="admin-header position-relative">

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid py-3">
                        <a className="navbar-brand1" href="#"><img src="../images/new-logo.png" width="100px" alt="" /></a>

                        <ul className="navbar-nav flex-row align-items-center justify-contents-center">

                            <div className="nav-item dropdown notification-drop">
                                {/* <label>Language:</label>
                                <select className='form-control' name="languages" id="lang" value={selectedLang} onChange={(e) => getSurveyLangCode(e)}>
                                    <option >Select language</option>
                                    {language && language.length > 0 && language.map((data, index) => (<option name='language' value={data.lang_code}>{data.lang_name}</option>))}


                                </select> */}
                            </div>
                            {/* <li className="profile-drop nav-item dropdown" onClick={e => setSurveyScreen(false)}>
                                <a className="nav-link nav dropdown-toggle d-flex align-items-center position-static" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={user.gender == 'female' ? "../images/woman.png" : "../images/man.png"} width="50px" className='rounded-circle' alt="" />
                                </a>
                            </li> */}
                        </ul>
                    </div></nav>
            </section>
            <section className='page404'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex flex-column align-items-center justify-content-center text-center">
                            <div className="main-image404">
                                <lottie-player src="../images/page404.json" background="transparent" speed={1} style={{ width: '480px', height: '380px' }} loop autoPlay />
                            </div>
                            <div className="main-content404" style={{ width: '380px' }}>
                                <h2 className="fw-bold text-orange">404, We can't find this page</h2>
                                <p className='fs-5 mb-5'>It was moved, deleted, or never existed just go back to your survey page.</p>
                                <a className="mainbtn">Go to survey Page</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default ErrorPage