import React, { useState, useEffect } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import {  getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import RewardUseModal from '../common/rewardUserModal'
// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link, useHistory } from "react-router-dom";
import Loader2 from '../../utils/loader2/loader2'
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ViewSingleSurveyRecord from './viewSingleSurveyRecord';
import './surveyRecord.css'

const SurveyRecord = (props) => {
    const api = new API()
    const history = new useHistory()
    const [userSurveyRecord, setUserSurveyRecord] = useState([])
    const [loader2, setLoader2] = useState(true)
    const [isSurveyDetails, setIsSurveyDetails] = useState(false)
    const [singleUserSurveyRecord, setSingleUserSurveyRecord] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [pagination, setPagination] = useState();
    const [pageSize, setPageSize] = useState(9)
    const [index1, setIndex1] = useState(0)
    //const[page,setPage]=useState({pagesize:"99"})

    let adminToken = getLocalDataAsObject("adminToken")
    if(!adminToken) {
        history.push("/")
    }

    useEffect(() => {
        getUserSurveyRecord(0)      
    }, [])

    const getUserSurveyRecord = async (count) => {
        let data = {

            pageNumber: count,
            pageSize: 9

        }
        const result = await api.post(config.adminDashboardGetUserAttendedSurveyList, data)
        if (result&&result.code === 200) {
            setLoader2(false)
            setPagination(result.data.length)
            setUserSurveyRecord(result.data)

        }
    }

    const surveyDetails = (userSurveyRecord) => {
        setSingleUserSurveyRecord(userSurveyRecord)
        setIsSurveyDetails(true)
    }
    const goBack = () => {
        setIsSurveyDetails(false)
    }

    const sendPageCount = (value) => {
        if (value === '-1') {

            setPageCount(pageCount -1)
            updateIndex(value,pageCount);
           
        }
        if (value === '1') {

            setPageCount(pageCount + 1)          
            updateIndex(value,pageCount+1);
        }
        
    }

    const  updateIndex = (value,pageCount) =>{
        
        if (pageCount > 0){
            if(value==='-1'){
            setIndex1( pageSize * (pageCount - 1) )
            getUserSurveyRecord(pageCount );}
            else{
            setIndex1( pageSize * (pageCount) ) 
            getUserSurveyRecord(pageCount);
        }}else {
            setPageCount(pageCount = 0)
        }
        
    }    

    return (
        <div>

            <div className="container mt-5">
                <h1 align="center" style={{marginBottom:"5%"}}>Survey Record Panel dashboard</h1>
                <div className="container mt-4">
                    {loader2 == true ?
                        <div className="container" style={{ marginTop: "10%" }}>
                            <Loader2 />
                        </div>
                        : <>
                            {isSurveyDetails == false
                                ?
                                <>
                                    <div className="row d-flex">
                                        <div className="col-md-8">
                                        {userSurveyRecord.map((userSurveyRecord, index) => (
                                            
                                            <div className="card mb-5" >                                           
                                                    <div className="card-header">                                                
                                                        <h6 className="text-black">{index1 + index+1} User Email : {userSurveyRecord.email} </h6>                                                      
                                                        <div className="card-body" style={{ borderTop: "1px solid" }}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <h5>Total Survey UserAttended : <span style={{ color: "red" }}>{userSurveyRecord.attendedSurvey.length}</span></h5>

                                                                </div>
                                                                <div className="col-md-6 item-center">
                                                                    <div>
                                                                        <button onClick={() => surveyDetails(userSurveyRecord)} className="btn btn-secondary">View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>


                                        <div className="col-md-4" >
                                            <div className="fixedRightSection" style={{marginTop:"3%"}}>
                                                <ul class="list-group">
                                                    <li class="list-group-item">Click on mail to details</li>
                                                    <div style={{overflow:"auto",height:"450px",width:"389px"}}>
                                                        {userSurveyRecord.map((userSurveyRecord, index) => (
                                                            <li onClick={() => surveyDetails(userSurveyRecord)} style={{ cursor: "pointer" }} class="list-group-item list-group-item-secondary">{index1 + index + 1} : {userSurveyRecord.email}</li>
                                                        ))}
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <ViewSingleSurveyRecord goBack={goBack} singleUserSurveyRecord={singleUserSurveyRecord} />
                            }
                        </>}
                    <div className="d-flex justify-content-evenly">
                        {pageCount > 0 && <div><button className='btn btn-secondary' onClick={() => sendPageCount("-1")}><i class="fa fa-arrow-left"></i></button></div>}
                        <div><button className='btn btn-secondary' onClick={() =>sendPageCount("1")}><i class="fa fa-arrow-right"></i></button></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SurveyRecord;