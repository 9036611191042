import React, { useState, useEffect } from "react";
import { Progress, Input, Space } from "antd";
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import API from "../utils/apiCalling";
import { Link, useHistory } from "react-router-dom";
import SurveyScreen from "./surveyScreen";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter } from "../utils/CommonFunction";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ClientSurvey from './clientSurvey'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  VKShareButton,
  WhatsappShareButton,
  InstagramIcon,
} from "react-share";
import swal from "sweetalert";
// import config from '../config'
import ReactPlayer from "react-player";
import Config from "../config/env";
import moment from "moment";

function PanelistDashboard() {
  let api = new API();
  //    const[start,setStart]=useState([])
  //    const[end,setEnd]=useState([])
  //    const[reffelCode,setReffelCode]=useState([])

  const [rewardspillclose, setActive] = useState(true);
  const [redeemButton, setRedeemButtom] = useState(true);
  const [redeemError, setRedeemError] = useState(false);

  const [user, setUserData] = useState("");
  const [points, setPoints] = useState("");
  var [surveys, setSurveys] = useState([]);

  const [pendingProfileQues, setPendingProfileQues] = useState([]);
  const [screen, setSurveyScreen] = useState(false);
  const [surveyQues, setSurveyQues] = useState([]);

  const [transactions, setTransactions] = useState([]);
  const [profilePercent, setProfilePercent] = useState(0);
  var [tab, setTab] = useState("dashboard");
  const [completedSurveys, setCompletedSurveys] = useState("");

  const history = new useHistory();
  const [type, setSurveyType] = useState("survey");
  const [pointsToRedeem, setPointsToRedeem] = useState("");
  const [pwd, setCurrentPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [notifyCount, setNotifyCount] = useState(0);
  const [paytmNo, setPaytmNo] = useState();
  const [adminButton, setAdminButton] = useState();
  const [isClient, setisclient] = useState(false)
  // const [surveyStartTime,setSurveyStartTime] = useState('')

  const [email, setEmail] = useState();
  const [userName, setUserName] = useState();
  // const [referName, setReferName] = useState()
  // const [referEmail, setReferEmail] = useState()
  const [referCode, setReferCode] = useState();
  const [pointsPercent, setPointsPercent] = useState({});
  const [copyStatus, setCopyStatus] = useState(false);
  const [surveyId, setSurveyId] = useState();
  const [title1, setTitle1] = useState(
    "Earn points on every referral. Share the referral code "
  );
  const [title2, setTitle2] = useState(
    " with your friends & get 100 points for both while registering with the following link  'https://doyoursurvey.com/#/register' "
  );
  const [whatsAppTitle2, setWhatsAppTitle2] = useState(
    " with your friends and earn points on registration with the following link "
  );

  useEffect(() => {

    window.scroll(0, 0);
    const userData = JSON.parse(localStorage.getItem("user"));
    if (!userData) {
      history.push('/')
    } else {
      setUserData(userData);
      userData.userType === "admin"
        ? setAdminButton("admin")
        : setAdminButton("");
      setEmail(userData.email);
      setUserName(userData.firstName);
      setReferCode(userData.referCode);

      getNotifications(userData);
      getCompletedSurvey();
      getDashboardData();

      getPointsPercent();
    }
    if (userData.userType == "client") {
      history.push('/adminDash')
    }

  }, []);
  let date = [new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDay()]

  const titlecase = (value) => {
    let name = "";
    if (value) {
      for (let i = 0; i < value.length; i++) {
        name += i == 0 ? value[i].toUpperCase() : value[i];
      }
    }
    return name;
  };

  const getReferCode = async () => {
    setTab("refer");
    let result = await api.post(
      `${Config.new.site_api}/dashboard/countNoOfReferral`,
      { email: user?.email }
    );
    if (result.code === 200) {
      setReferCode(result.data);
    }
  };

  const getDemoSurvey = async (completed) => {
    let result = await api.post(
      `${Config.new.site_api}/survey/getDemoDynSurvey`,
      { email: email }
    );
    if (result.code === 200) {
      result.data.forEach((data) => {
        completed.push(data);
      });

      setSurveys(completed);
    }
  };

  const sliderig = () => {
    setActive(!rewardspillclose);
  };

  const getNotifications = async (userData) => {
    let body = {
      user_id: userData._id,
      page: 1,
      limit: 25,
    };
    let result = await api.post(
      `${Config.new.site_api}/common/notificationList`,
      body
    );
    if (result?.code === 200) {
      setNotifications(result?.data.notifications);
    } else {
      toast.error(result?.message);
    }

    let input = {
      user_id: userData._id,
    };
    let res = await api.post(
      `${Config.new.site_api}/common/totalUnReadCount`,
      input
    );
    if (res?.code === 200) {
      setNotifyCount(res.data.unread_notifications_count);
    } else {
      toast.error(res?.message);
    }
  };

  const readNotification = async (_id) => {
    let data = {
      notification_id: _id,
    };
    let res = await api.post(
      `${Config.new.site_api}/common/notificationReadApi`,
      data
    );
    if (res.code === 200) {
    }
  };

  const copyReferCode = () => {
    setCopyStatus(true);
  };

  const closeValue = () => {
    setCurrentPwd('');
    setNewPwd('');
    setConfirmPwd('');
  }

  const resetPassword = async () => {
    let validPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!newPwd.match(validPassword)) {
      toast.warn(
        "Password must be at least 8 characters and must contain at least one lower case letter, one upper case letter and one digit and one special character."
      );
    } else if (newPwd !== confirmPwd) {
      toast.warn("Password and confirm Password doesnt match");
    } else {
      let profile = {
        email: user.email,
        mobile: user.mobile,
        profileImage: "",
        password: pwd,
        newPassword: newPwd,
        confirmPassword: confirmPwd,
      };

      let result = await api.post(
        `${Config.new.site_api}/client/changePassword`,
        profile
      );

      if (result.code === 200) {
        toast.success("Your Password Succefully Changed", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setCurrentPwd('');
        setNewPwd('');
        setConfirmPwd('');

      } else {
        toast.error(result.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const getPointsPercent = async () => {
    let result = await api.get(
      `${Config.new.site_api}/admin-dashboard/referralPercent`
    );
    if (result.code === 200) {
      pointsPercent.active = result.data.active;
      pointsPercent.parent1 = result.data.parent1;
      pointsPercent.parent2 = result.data.parent2;
    } else {
      toast.error(result.message);
    }
  };

  const getCompletedSurvey = async () => {
    let result = await api.get(
      `${Config.new.site_api}/user/completedSurvey`
    );
    if (result.code === 200) {
      setCompletedSurveys(result.data);

      getAvailableSurveys(result.data);
    } else {
      toast.error(result.message);
    }
  };

  const getProfileSurveyQuestion = async (value) => {
    let result = await api.post(
      `${Config.new.site_api}/user-panel-profile/userProfileQuestion`,
      { type: value.taskName }
    );

    if (result.code === 200) {
      setSurveyQues(result.data);
      setSurveyType("profile");
      setSurveyScreen(true);
    } else {
    }
  };

  const logOut = () => {
    swal({
      // html: true,
      title: "Are you sure to LogOut?",
      // text: ``,
      // icon: "",
      buttons: ["No, cancel it!", "Yes"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        localStorage.clear();
        history.push("/login");
      } else {
      }
    });
  };

  const getAvailableSurveys = async (completed) => {
    let Data = {
      email: JSON.parse(localStorage.getItem("user")).email,
    };

    let result = await api.post(
      `${Config.new.site_api}/survey/getDynSurvey`,
      Data
    );

    if (result.code === 200) {
      let surv = result.data;
      surv &&
        surv.length > 0 &&
        surv.map(async (e) => {
          completed.map((c) => {
            if (c.survey_name === e.survey_name) {
              e.isCompleted = true;
            }
          })
          if (e?.logo) {
            let array = []
            array.push(e.logo)
            let url = { "url": JSON.stringify(array) }
            let logo = await api.post(`${Config.new.site_api}/client-survey/getImageUrl`, url)
            console.log(array, "aaaaaa")
            if (logo.code === 200) {
              e.logo = logo.data[0]
            }
          }
          e.questions.forEach((que) => {
            que.options.forEach(async (op) => {
              if (op?.imageUrl) {
                let url = { "url": JSON.stringify([op?.imageUrl]) }
                let opImage = await api.post(`${Config.new.site_api}/client-survey/getImageUrl`, url)
                if (opImage.code === 200) {
                  op.imageUrl = opImage.data[0]
                }
              }
            })
          })
        }
        );

      if (
        JSON.parse(localStorage.getItem("user")).email ==
        "kanika.pandey@qdegrees.com"
      ) {
        getDemoSurvey(surv);
      } else {
        setSurveys(surv);
      }
    }
  };

  const getProfileData = async () => {
    setTab("profile");
    window.scroll(0, 0);
    let result = await api.post(
      `${Config.new.site_api}/dashboard/clientprofile`,
      { email: user?.email }
    );

    if (result.code === 200) {
      setUserData(result.data.user[0]);
    } else {
    }
  };

  const getRedeemhistory = async () => {
    setTab("rewards");
    window.scroll(0, 0);
    let result = await api.post(
      `${Config.new.site_api}/survey/getRedeemedPointHistory`,
      { email: user?.email }
    );

    if (result.code === 200) {
      setTransactions(result.data.doc.redeemPoint);
    } else {
    }
  };

  const startSurvey = (ques) => {
    if (ques.is_client === 1) {
      setSurveyScreen(true);
      setSurveyQues(ques);
    } else {
      setSurveyQues(ques);
      setSurveyType("survey");
      setSurveyScreen(true);
    }
  };

  const redeemPoint = async () => {
    let regex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;
    if (!regex.test(paytmNo)) {
      toast.error("Enter Valid Paytm Number", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let userData = JSON.parse(localStorage.getItem("user"));
      const data = {
        email: userData.email,
        RedeemPoint: pointsToRedeem,
        paytmMobile: paytmNo,
        voucherCode: "paytm",
        voucherFor: "paytm",
        status: "pending",
      };
      let result = await api.post(
        `${Config.new.site_api}/survey/redeemPoint`,
        data
      );

      if (result.code === 200) {
        toast.success("Points Redeem Succefully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.status, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const getDashboardData = async () => {
    let userData = JSON.parse(localStorage.getItem("user"));

    let result = await api.post(
      `${Config.new.site_api}/user-panel-profile/dashboard`,
      { email: userData.email }
    );

    if (result?.code === 200) {
      setPoints(result.data.data[0]);
      let stats = result.data.profileTaskStatus;
      let percent = 0;
      let completeCount = 0;
      let pending = [];
      for (let i = 0; i < stats?.length; i++) {
        if (stats[i].status == true) {
          // let totalQuestions = stats.length
          completeCount += 1;
        } else {
          pending.push(stats[i]);
        }
      }
      percent = ((completeCount / stats?.length) * 100).toFixed(2);
      setPendingProfileQues(pending);
      setProfilePercent(percent);
    } else {
    }
  };
  const navigate = (tab) => {
    setTab(tab);
    window.scroll(0, 0);
  };

  const checkPointsAvailable = (e) => {
    if (
      e?.target.value !== 0 &&
      e?.target.value <= points.pointAvailable &&
      e?.target.value >= 1000
    ) {
      setPointsToRedeem(e?.target.value);
      setRedeemButtom(false);
      setRedeemError(false);
    } else if (e?.target.value > points.pointAvailable) {
      setRedeemError(true);
    } else {
      setPointsToRedeem(e?.target.value);
      setRedeemError(false);
      setRedeemButtom(true);
    }
  };

  const handleClose = () => {
    setPointsToRedeem('')
  }

  let surveylink = surveyId
    ? `https://doyoursurvey.com/#/attemp-survey/` + surveyId
    : "https://doyoursurvey.com/#/signup";
  let emailLink = `https://mail.google.com/mail/?view=cm&fs=1&body=${title1 + referCode + whatsAppTitle2 + surveylink
    }`;




  return (
    <>
      {!screen && (
        <main className="bg-lgrey h-100">
          <section id="all-section" className="admin-header">
            <nav className="navbar navbar-expand-lg nevigtsao bg-white p-0 border border-bottom">
              <div className="container-fluid ">
                <a className="navbar-brand1 logo-itemsw" href="#">
                  <img src="../images/new-logo.png" width="100px" alt="" />
                </a>
                <div className="d-flex">

                  <ul className="navbar-nav flex-row align-items-center justify-contents-center ms-3">
                    <a
                      className="text-white mainbtn mx-3 rede-b-nav navitemscon"
                      id="redeem-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#redeem-points"
                    >
                      Redeem Point
                    </a>
                    <div className="nav-item dropdown notification-drop">
                      <a
                        id="dLabel"
                        role="button"
                        className="nav-link dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-bell-fill text-blue fs-4 position-relative">
                          <span className="position-absolute top-0 start-100 translate-middle badge rounded-circle text-blue f-12 bg-grey">
                            <small className="fw-bold">{notifyCount}</small>
                            <span className="visually-hidden">
                              unread messages
                            </span>
                          </span>
                        </i>
                      </a>
                      <ul
                        className="dropdown-menu dropdown-menu-lg-end notifications"
                        role="menu"
                        aria-labelledby="dLabel"
                      >
                        <div className="notification-heading">
                          <p className="text-orange m-2 f-14">Notifications</p>
                        </div>
                        <li className="divider" />
                        <div className="notifications-wrapper">
                          {notifications.length &&
                            notifications.map((notify, index) => (
                              <a className="content" key={index}>
                                {notify.read_status == 1 && (
                                  <div
                                    // onClick={""}
                                    className="notification-item  unread"
                                  >
                                    <div style={{ width: "50px" }}
                                      className="rounded-circle" id="profileImage">{notify.title[0]}</div>
                                    <div className="tableform w-100">
                                      <p className="text-orange f-14">
                                        {notify.title}
                                        <span className="float-right f-12">
                                          {(JSON.stringify(date) == JSON.stringify([new Date(notify.created_at).getFullYear(), new Date(notify.created_at).getMonth() + 1, new Date(notify.created_at).getDay()])) ? new Date(new Date(notify.created_at) * 1000).toLocaleTimeString() : moment(notify.created_at).format('DD/MM/YY')}

                                        </span>
                                      </p>
                                      <p className="item-info f-12">
                                        {notify.message}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {notify.read_status == 0 && (
                                  <div
                                    onClick={() => readNotification(notify._id)}
                                    className="notification-item  read"
                                  >
                                    <img src="../images/woman.png"></img>
                                    <div className="tableform w-100">
                                      <p className="text-orange f-14">
                                        {notify.title}
                                        <span className="float-right f-12">
                                          {(JSON.stringify(date) == JSON.stringify([new Date(notify.created_at).getFullYear(), new Date(notify.created_at).getMonth() + 1, new Date(notify.created_at).getDay()])) ? new Date(new Date(notify.created_at) * 1000).toLocaleTimeString() : moment(notify.created_at).format('DD/MM/YY')}
                                        </span>
                                      </p>
                                      <p className="item-info f-12">
                                        {notify.message}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </a>
                            ))}
                        </div>

                        {/* <div className="notifications-wrapper">
                                                <a className="content" href="#">
                                                    <div className="notification-item  read">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>

                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                                        </div></div>
                                                </a>
                                                <a className="content" href="#">

                                                    <div className="notification-item  read">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content" href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item  ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                            </div> */}
                      </ul>
                      <li className="profile-drop nav-item dropdown navitemscon">
                        <a
                          className="nav-link nav dropdown-toggle d-flex align-items-center position-static"
                          href="#profile"
                          role="button"
                          data-bs-toggle="dropdown"
                        >
                          <img
                            src={
                              user?.profileImage
                                ? user?.profileImage
                                : user?.gender == "female"
                                  ? "../images/woman.png"
                                  : "../images/man.png"
                            }
                            width="50px"
                            className="rounded-circle"
                            alt=""
                          />
                        </a>
                        <ul
                          className="dropdown-menu dropdown-menu-lg-end"
                          id="profile"
                        >
                          <li>
                            <a className="dropdown-item" onClick={getProfileData}>
                              My Profile
                            </a>
                          </li>
                          {adminButton === "admin" && (
                            <li>
                              <a className="dropdown-item" href="/#/adminDash">
                                Client Dashboard
                              </a>
                            </li>
                          )}
                          {adminButton === "admin" && (
                            <li>
                              <a
                                className="dropdown-item"
                                href="/#/192837465/adminDashboard"
                              >
                                Admin Dashboard
                              </a>
                            </li>
                          )}
                          <li>
                            <a className="dropdown-item" onClick={logOut}>
                              Log Out{" "}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </ul>
                  <button className="dash-ac-btn btn " onClick={sliderig}>
                    {/* <svg id="hamburgerIcon">
                    <path
                      id="stroke1"
                      d="M7, 10 L33, 10"
                      stroke="#fff"
                      stroke-width="3"
                    />
                    <path
                      id="stroke2"
                      d="M7, 20 L33, 20"
                      stroke="#fff"
                      stroke-width="3"
                    />
                    <path
                      id="stroke3"
                      d="M7, 30 L33, 30"
                      stroke="#fff"
                      stroke-width="3"
                    />
                  </svg> */}
                    <i className="bi bi-list"></i>
                  </button>
                </div>
              </div>
            </nav>
          </section>
          <section className="rewards-section h-100">
            <div className="d-flex align-items-start h-100">

              <div
                className="nav rewards-section-nav flex-column nav-pills me-2 com-bs sideBarmn "

                id={
                  rewardspillclose === true
                    ? "rewards-pills-tab"
                    : "rewardspillclose"
                }
                role="tablist"
                aria-orientation="vertical"
              >
                <ul className="navbar-nav flex-row align-items-center justify-contents-center navitemsconmedia">

                  <div className="nav-item dropdown notification-drop px-3 d-flex justify-content-between py-2">
                    <div>
                      <ul
                        className="dropdown-menu dropdown-menu-lg-end notifications"
                        role="menu"
                        aria-labelledby="dLabel"
                      >
                        <div className="notification-heading">
                          <p className="text-orange m-2 f-14">Notifications</p>
                        </div>
                        <li className="divider" />
                        <div className="notifications-wrapper">
                          {notifications.length &&
                            notifications.map((notify, index) => (
                              <a className="content" key={index}>
                                {notify.read_status == 1 && (
                                  <div
                                    // onClick={""}
                                    className="notification-item  unread"
                                  >
                                    <div style={{ width: "50px" }}
                                      className="rounded-circle" id="profileImage">{notify.title[0]}</div>
                                    <div className="tableform w-100">
                                      <p className="text-orange f-14">
                                        {notify.title}
                                        <span className="float-right f-12">
                                          {(JSON.stringify(date) == JSON.stringify([new Date(notify.created_at).getFullYear(), new Date(notify.created_at).getMonth() + 1, new Date(notify.created_at).getDay()])) ? new Date(new Date(notify.created_at) * 1000).toLocaleTimeString() : moment(notify.created_at).format('DD/MM/YYYY')}

                                        </span>
                                      </p>
                                      <p className="item-info f-12">
                                        {notify.message}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {notify.read_status == 0 && (
                                  <div
                                    onClick={() => readNotification(notify._id)}
                                    className="notification-item  read"
                                  >
                                    <img src="../images/woman.png"></img>
                                    <div className="tableform w-100">
                                      <p className="text-orange f-14">
                                        {notify.title}
                                        <span className="float-right f-12">
                                          {(JSON.stringify(date) == JSON.stringify([new Date(notify.created_at).getFullYear(), new Date(notify.created_at).getMonth() + 1, new Date(notify.created_at).getDay()])) ? new Date(new Date(notify.created_at) * 1000).toLocaleTimeString() : moment(notify.created_at).format('DD/MM/YYYY')}
                                        </span>
                                      </p>
                                      <p className="item-info f-12">
                                        {notify.message}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </a>
                            ))}
                        </div>

                        {/* <div className="notifications-wrapper">
                                                <a className="content" href="#">
                                                    <div className="notification-item  read">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>

                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                                        </div></div>
                                                </a>
                                                <a className="content" href="#">

                                                    <div className="notification-item  read">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content" href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item  ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                            </div> */}
                      </ul>
                      <li className="profile-drop nav-item dropdown">
                        <a
                          className="nav-link nav d-flex align-items-center position-static border-0"
                          href="#profile"
                          role="button"
                          data-bs-toggle="dropdown"
                        >
                          <img
                            src={
                              user?.profileImage
                                ? user?.profileImage
                                : user?.gender == "female"
                                  ? "../images/woman.png"
                                  : "../images/man.png"
                            }
                            width="50px"
                            className="rounded-circle"
                            alt=""
                          />
                        </a>
                        <ul
                          className="dropdown-menu dropdown-menu-lg-end"
                          id="profile"
                        >
                          <li>
                            <a className="dropdown-item" onClick={getProfileData}>
                              My Profile
                            </a>
                          </li>
                          {adminButton === "admin" && (
                            <li>
                              <a className="dropdown-item" href="/#/adminDash">
                                Client Dashboard
                              </a>
                            </li>
                          )}
                          {adminButton === "admin" && (
                            <li>
                              <a
                                className="dropdown-item"
                                href="/#/192837465/adminDashboard"
                              >
                                Admin Dashboard
                              </a>
                            </li>
                          )}
                          <li>
                            <a className="dropdown-item" onClick={logOut}>
                              Log Out{" "}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </div>
                    <a
                      className="text-white mainbtn mx-3 rede-b-nav text-nowrap"
                      id="redeem-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#redeem-points"
                    >
                      Redeem Point
                    </a>
                  </div>
                </ul>
                <button
                  className={
                    tab == "dashboard" ? "nav-link active" : "nav-link"
                  }
                  onClick={(e) => {
                    navigate("dashboard");
                  }}
                >
                  <img src="../images/Icon material-dashboard.svg"></img>
                  <p>Dashboard</p>
                </button>
                <button
                  className={tab == "profile" ? "nav-link active" : "nav-link"}
                  onClick={getProfileData}
                >
                  <img src="../images/Icon awesome-user-circle.svg"></img>
                  <p>My Profile</p>
                </button>
                <button
                  className={tab == "rewards" ? "nav-link active" : "nav-link"}
                  onClick={getRedeemhistory}
                >
                  <img src="../images/Group 17703.svg"></img>
                  <p>Rewards</p>
                </button>
                <button
                  className={tab == "refer" ? "nav-link active" : "nav-link"}
                  onClick={(e) => {
                    getReferCode();
                  }}
                >
                  <img src="../images/Icon material-group.svg"></img>
                  <p>Refer & Earn</p>
                </button>
                <button
                  className={tab == "help" ? "nav-link active" : "nav-link"}
                  onClick={(e) => {
                    navigate("help");
                  }}
                >
                  <img src="../images/help-svgrepo-com.svg"></img>
                  <p>Help</p>
                </button>
                <button
                  className={tab == "logout" ? "nav-link active" : "nav-link"}
                  onClick={(e) => {
                    logOut();
                  }}
                >
                  <img src="../images/Group 22628.svg"></img>
                  <p>Logout</p>
                </button>
              </div>
              <div
                className="tab-content  w-100 rewards1 dashuia"
                id="v-pills-tabContent"
              >
                {tab == "dashboard" && (
                  <div
                    className="tab-pane  fade show active"
                    id="v-pills-dash"
                    role="tabpanel"
                    aria-labelledby="v-pills-dash-tab"
                    tabIndex="0"
                  >
                    <div className="w-100">
                      <div className="reward-dash container-fluid py-1 mb-4">
                        <p className="fs-2 fw-bold dashBoardheading">
                          Welcome,
                          <span className="text-orange">
                            &nbsp;{titlecase(user?.firstName)}
                          </span>
                        </p>
                        <p className="f-14 text-dblue fw-500 fw-bold">
                          Quick stats
                        </p>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="l-sec mb-3">
                              <div className="row">
                                <div className="col-lg-6 col-md-12 mb-3 mb-lg-5">
                                  <a>
                                    <div className="rew-card h-100 rounded-2">
                                      <p className="f-12 text-dblue fw-500 m-0">
                                        Total Points Earned
                                      </p>
                                      <span className="fs-2 text-orange fw-bold">
                                        {points.pointEarned}
                                      </span>
                                      <img src="../images/Group 18183.svg" />
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-6 col-md-12 mb-3 mb-lg-5">
                                  <a>
                                    <div className="rew-card h-100 rounded-2">
                                      <p className="f-12 text-dblue fw-500 m-0">
                                        Points Redeemed
                                      </p>
                                      <span className="fs-2 text-orange fw-bold">
                                        {points.pointRedeemed}
                                      </span>
                                      <br />
                                      <p className="f-12 text-dblue fw-500 m-0">
                                        Points Worth ₹
                                        {points.pointRedeemed * 0.25}
                                      </p>
                                      <img src="../images/Group 18850.svg" />
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-6 col-md-12 mb-3 mb-lg-0">
                                  <a>
                                    <div className="rew-card h-100 rounded-2">
                                      <p className="f-12 text-dblue fw-500 m-0">
                                        Total Points Available
                                      </p>
                                      <span className="fs-2 text-orange fw-bold">
                                        {points.pointAvailable}
                                      </span>
                                      <p className="f-12 text-dblue fw-500 m-0">
                                        Points Worth ₹
                                        {points.pointAvailable * 0.25}
                                      </p>
                                      <img src="../images/Group 19733.svg" />
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-6 col-md-12 mb-3 mb-lg-0">
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#comple-survey"
                                  >
                                    <div className="rew-card h-100 rounded-2">
                                      <p className="f-12 text-dblue fw-500 m-0">
                                        Completed Survey
                                      </p>
                                      <span className="fs-2 text-orange fw-bold">
                                        {completedSurveys.length}
                                      </span>
                                      <img src="../images/Group 22108.svg" />
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 second-colmnWar mb-3">
                            <div className="avail-surs avail-surs rounded-1">
                              <table border={0} className="w-100">
                                <tbody className="bg-white">
                                  <tr className="bg-grey text-orange position-sticky top-0">
                                    <th>Available Survey</th>
                                    <th>Earn Points</th>
                                    <th />
                                    <th />
                                  </tr>

                                  {surveys &&
                                    surveys.length > 0 &&
                                    surveys.length > 0 ? (
                                    surveys.map((survey, index) => (
                                      <tr className="border-bottom" key={index}>
                                        <td>{survey.survey_name}</td>
                                        <td>
                                          {survey.points
                                            ? survey.points * 0.7
                                            : 0}
                                        </td>
                                        <td>
                                          <a
                                            className="bg-orange"
                                            data-bs-toggle="modal"
                                            data-bs-target="#share-survey"
                                            onClick={(e) =>
                                              setSurveyId(survey._id)
                                            }
                                          >
                                            <img src="../images/Icon metro-sharewhite.svg"></img>
                                          </a>
                                        </td>
                                        <td>
                                          {survey.isCompleted !== true && (
                                            <button
                                              className="mainbtn"
                                              onClick={() => {
                                                startSurvey(survey);
                                              }}
                                            >
                                              Start
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr className="border-bottom">
                                      <td>No survey found</td>
                                      <td>{""}</td>
                                      <td>{""}</td>
                                      <td>{""}</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="d-flex com-bs rounded-1 bg-white h-100 mb-3 referEran">
                              <div className="col-sm-4 p-3">
                                <p className="text-orange fw-semibold">
                                  Refer and Earn
                                </p>
                                <img
                                  src="../images/Group 22627.svg"
                                  className="w-100"
                                />
                              </div>
                              <div className="col-sm-8 px-3 py-2 justify-content-center flex-column d-flex">
                                <p className="f-16 mb-2">
                                  <b>Make friendship truly rewarding</b>
                                </p>
                                <p className="f-14 mb-1">
                                  Introduce friends to DoYourSurvey by referring
                                  and earn exclusive rewards.
                                </p>
                                <div className="bg-blue rounded-3 pt-earn-bg">
                                  <a>
                                    <p className="text-white f-14 m-0 p-2 text-center">
                                      Total earn points&nbsp;
                                      <br />
                                      <span className="fs-5 fw-bold">
                                        {points.pointEarned}
                                      </span>
                                      &nbsp;points
                                    </p>
                                  </a>
                                </div>
                                <a>
                                  <div
                                    className="bg-blue p-2 rounded-3 mt-2 text-white text-center"
                                    data-bs-toggle="modal"
                                    data-bs-target="#share-survey"
                                    onClick={() => setSurveyId("")}
                                  >
                                    Refer More Earn More
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="d-flex progress-pro complete_profile p-3 mb-3 h-100 rounded-2 com-bs bg-white mt-3">
                              {profilePercent == 100.0 ? (
                                <>
                                  <div className="col-sm-5 d-flex align-items-center justify-content-center">
                                    <Progress
                                      type="circle"
                                      strokeColor={{
                                        "0%": "#354c9e",
                                        "100%": "#d9661d",
                                      }}
                                      percent={profilePercent}
                                    />
                                  </div>
                                  <div className="col-sm-7 d-flex justify-content-center flex-column">
                                    <p className="f-14  fw-semibold text-orange">
                                      Profile Completion
                                    </p>
                                    <p
                                      className="f-14 fw-bold text-black"
                                      style={{ fontSize: "30px" }}
                                    >
                                      100% Profile
                                    </p>
                                    <div className="w-100 text-left pt-2">
                                      <a
                                        className="mainbtn"
                                        onClick={getProfileData}
                                      >
                                        Completed
                                      </a>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="col-sm-5 d-flex align-items-center justify-content-center">
                                    {/* <div className="ant-progress ant-progress-circle ant-progress-status-normal ant-progress-show-info ant-progress-default" role="progressbar">
                                                                     <div className="ant-progress-inner ant-progress-circle-gradient" style={{ width: '120px', height: '120px', fontSize: '24px' }}><svg className="ant-progress-circle" viewBox="-50 -50 100 100" role="presentation">
                                                                         <defs>
                                                                             <linearGradient id="rc_progress_0-gradient" x1="100%" y1="0%" x2="0%" y2="0%">
                                                                                 <stop offset="0%" stopColor="#354c9e" />
                                                                                 <stop offset="100%" stopColor="#d9661d" />
                                                                             </linearGradient>
                                                                         </defs>
                                                                         <circle className="ant-progress-circle-trail" r={47} cx={0} cy={0} strokeLinecap="round" strokeWidth={6} style={{ strokeDasharray: '270.701px, 295.31', strokeDashoffset: 0, transform: 'rotate(105deg)', transformOrigin: '0px 0px', transition: 'stroke-dashoffset 0.3s ease 0s, stroke-dasharray 0.3s ease 0s, stroke 0.3s ease 0s, stroke-width 0.06s ease 0.3s, opacity 0.3s ease 0s', fillOpacity: 0 }}>
                                                                         </circle>
                                                                         <circle className="ant-progress-circle-path" r={47} cx={0} cy={0} stroke="url(#rc_progress_0-gradient)" strokeLinecap="round" strokeWidth={6} opacity={1} style={{ strokeDasharray: '270.701px, 295.31', strokeDashoffset: '30.0701', transform: 'rotate(105deg)', transformOrigin: '0px 0px', transition: 'stroke-dashoffset 0s ease 0s, stroke-dasharray 0s ease 0s, stroke ease 0s, stroke-width ease 0.3s, opacity ease 0s', fillOpacity: 0 }}>
                                                                         </circle>
                                                                         <circle className="ant-progress-circle-path" r={47} cx={0} cy={0} strokeLinecap="round" strokeWidth={6} opacity={0} style={{ stroke: 'rgb(82, 196, 26)', strokeDasharray: '270.701px, 295.31', strokeDashoffset: '270.691', transform: 'rotate(105deg)', transformOrigin: '0px 0px', transition: 'stroke-dashoffset 0s ease 0s, stroke-dasharray 0s ease 0s, stroke ease 0s, stroke-width ease 0.3s, opacity ease 0s', fillOpacity: 0 }}>
                                                                         </circle>
                                                                     </svg><span className="ant-progress-text" title={profilePercent}>{profilePercent}</span></div>
                                                                 </div> */}

                                    <Progress
                                      type="circle"
                                      strokeColor={{
                                        "0%": "#354c9e",
                                        "100%": "#d9661d",
                                      }}
                                      percent={profilePercent}
                                    // gapDegree={30}
                                    />
                                  </div>
                                  <div className="col-sm-7 d-flex justify-content-center flex-column">
                                    <p className="f-14  fw-semibold text-orange">
                                      Profile Completion
                                    </p>
                                    <p className="f-14">
                                      We need the following information to
                                      invite you to the next survey. The more
                                      you provide the more accurate the surveys
                                      will be. Choose a category to start
                                    </p>
                                    <div className="w-100 text-left pt-2">
                                      <a
                                        className="mainbtn"
                                        onClick={getProfileData}
                                      >
                                        Complete Profile
                                      </a>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal fade"
                      id="share-survey"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header align-items-center bg-lgrey border-0">
                            <h5
                              className="modal-title m-0 text-orange"
                              id="staticBackdropLabel"
                            >
                              Refer a survey to your friend !
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div className="row text-center justify-content-center">
                              <p className="">
                                Refer a survey and earn points while register
                                and take survey
                              </p>
                              <lottie-player
                                src="../images/81306.json"
                                background="transparent"
                                speed={1}
                                style={{ width: "270px", height: "190px" }}
                                loop
                                autoPlay
                              />
                              {/* <p className='fw-semibold '>You get 25 Points, Friend gets 50 Points</p> */}

                              <CopyToClipboard
                                text={referCode}
                                onCopy={copyReferCode}
                              >
                                {copyStatus !== true ? (
                                  <a className="mb-3">
                                    <u className="text-darkblue">
                                      Copy Refer Survey Link
                                    </u>
                                  </a>
                                ) : (
                                  <a className="mb-3">
                                    <u className="text-darkblue">
                                      Refer Code Copied{" "}
                                    </u>
                                  </a>
                                )}
                              </CopyToClipboard>

                              <p className="text-dark fw-bold f-16">
                                Share With
                              </p>
                              <div className="row justify-content-center">
                                <div className="col-sm col-4  p-0">
                                  {/* <EmailShareButton
                                                                        title={title1 + `"${referCode}"` + title2}
                                                                        url="https://doyoursurvey.com/#/register"
                                                                        // separator=": "
                                                                        openShareDialogOnClick
                                                                        onClick={() => { }}> */}
                                  {/* https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=new */}
                                  {/* email link for body https://mail.google.com/mail/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY&bcc=someone.else@example.com */}
                                  <a
                                    className="border-0 rounded-1 m-share-card com-bs"
                                    href={emailLink}
                                    target="_blank"
                                  >
                                    <img
                                      className=""
                                      src="../images/Icon zocial-email.svg"
                                    ></img>
                                    <p className="f-12 m-0">Send Email</p>
                                  </a>
                                  {/* </EmailShareButton> */}
                                </div>

                                {/* <div className='col-sm col-4  p-0'>
                                                                    <a href='' className='border-0 m-share-card com-bs '>
                                                                        <img className='' src='../images/Icon metro-qrcode.svg'></img>
                                                                        <p className='f-12 m-0'>QR Code</p>
                                                                    </a>
                                                                </div> */}
                                <div className=" col-sm col-4  p-0">
                                  <WhatsappShareButton
                                    title={
                                      title1 + `"${referCode}"` + whatsAppTitle2
                                    }
                                    url={
                                      surveyId
                                        ? `https://doyoursurvey.com/#/attemp-survey/` +
                                        surveyId
                                        : "https://doyoursurvey.com/#/signup"
                                    }
                                    separator=":"
                                  >
                                    {/* <WhatsappIcon size={32} /> */}

                                    <a className="border-0 m-share-card com-bs ">
                                      <img
                                        className=""
                                        src="../images/Icon simple-whatsapp.svg"
                                      ></img>
                                      <p className="f-12 m-0">whatsapp</p>
                                    </a>
                                  </WhatsappShareButton>
                                </div>
                                <div className="col-sm col-4  p-0">
                                  <LinkedinShareButton
                                    title={title1 + `"${referCode}"` + title2}
                                    url={
                                      surveyId
                                        ? "https://doyoursurvey.com/#/attemp-survey/" +
                                        surveyId
                                        : "https://doyoursurvey.com/#/signup"
                                    }
                                    windowWidth={750}
                                    windowHeight={600}
                                  >
                                    <a className="border-0 m-share-card com-bs ">
                                      <img
                                        className=""
                                        src="../images/Icon awesome-linkedin.svg"
                                      ></img>
                                      <p className="f-12 m-0">Linkedin</p>
                                    </a>{" "}
                                  </LinkedinShareButton>
                                </div>
                                <div className=" col-sm col-4  p-0">
                                  <a className="border-0 m-share-card com-bs position-relative">
                                    <img
                                      className=""
                                      src="../images/Icon awesome-share.svg"
                                    ></img>

                                    <div className="dropdown social-share position-static">
                                      <a
                                        className=" dropdown-toggle d-flex align-items-center position-static"
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <p className="f-12 m-0">Others </p>
                                      </a>

                                      <ul className="dropdown-menu f-12 ">
                                        <li>
                                          {/* <a className="dropdown-item p-1" href={ */}
                                          <FacebookShareButton
                                            quote={
                                              title1 + `"${referCode}"` + title2
                                            }
                                            url={
                                              surveyId
                                                ? "https://doyoursurvey.com/#/attemp-survey/" +
                                                surveyId
                                                : "https://doyoursurvey.com/#/signup"
                                            }
                                          >
                                            <a className="dropdown-item p-1">
                                              {" "}
                                              Facebook
                                            </a>
                                          </FacebookShareButton>
                                          {/* > Facebook</a> */}
                                        </li>
                                        <li>
                                          {/* <InstagramIcon
                                                                                        quote={title1 + `"${referCode}"` + title2}
                                                                                        url="https://doyoursurvey.com/#/register" > */}
                                          <a
                                            className="dropdown-item p-1"
                                            href="https://www.instagram.com"
                                            target="_break"
                                          >
                                            Instagram
                                          </a>
                                          {/* </InstagramIcon> */}
                                        </li>
                                        <li>
                                          <TwitterShareButton
                                            title={
                                              title1 + `"${referCode}"` + title2
                                            }
                                            url={
                                              surveyId
                                                ? "https://doyoursurvey.com/#/attemp-survey/" +
                                                surveyId
                                                : "https://doyoursurvey.com/#/signup"
                                            }
                                          >
                                            <a
                                              className="dropdown-item p-1"
                                              href=""
                                            >
                                              Twitter
                                            </a>
                                          </TwitterShareButton>
                                        </li>
                                      </ul>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Understood</button>
              </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* ----------------------------panel profile---------------------------------------------------- */}
                {tab == "profile" && (
                  <div
                    className="tab-pane fade show active "
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="container-fluid pb-5">
                      <div className="row profileRow">
                        <div className="col-lg-6">
                          <div className="row progress-pro py-3 bg-white com-bs mx-1">
                            {profilePercent == 100.0 ? (
                              <>
                                <div className="col-sm-5 d-flex align-items-center justify-content-center">
                                  <Progress
                                    type="circle"
                                    strokeColor={{
                                      "0%": "#354c9e",
                                      "100%": "#d9661d",
                                    }}
                                    percent={profilePercent}
                                  />
                                </div>
                                <div className="col-sm-7 d-flex justify-content-center flex-column">
                                  <p className="f-14  fw-semibold text-orange">
                                    Profile Completion
                                  </p>
                                  <p
                                    className="f-14 fw-bold text-black "
                                    style={{
                                      fontSize: "30px",
                                      textAlign: "justify",
                                      marginLeft: "40px",
                                    }}
                                  >
                                    100%
                                  </p>
                                  <p
                                    className="f-14 fw-bold text-orange"
                                    style={{ fontSize: "30px" }}
                                  >
                                    {" "}
                                    Completed
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-sm-5 d-flex align-items-center justify-content-center">
                                  <Progress
                                    type="circle"
                                    strokeColor={{
                                      "0%": "#354c9e",
                                      "100%": "#d9661d",
                                    }}
                                    percent={profilePercent}
                                  // gapDegree={30}
                                  />
                                </div>
                                <div className="col-sm-7 d-flex justify-content-center flex-column">
                                  <p className="f-14  fw-semibold text-orange">
                                    Profile Completion
                                  </p>
                                  <p className="f-14">
                                    We need the following information to invite
                                    you to the next survey. The more you provide
                                    the more accurate the surveys will be.
                                    Choose a category to start
                                  </p>
                                  <div className="w-100 text-left pt-2">
                                    <a className="mainbtn">Complete Profile</a>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <hr />
                          <div className="comp-proTab bg-white com-bs">
                            {profilePercent == 100.0 ? (
                              <>
                                <b
                                  style={{
                                    fontSize: "25px",
                                    color: "#ee6917",
                                    marginLeft: "20px",
                                  }}
                                >
                                  Yipee !! You have completed profile.{" "}
                                </b>
                              </>
                            ) : (
                              <p
                                className="text-orange active position-sticky top-0 bg-white px-2 m-0 py-3 f-16 fw-semibold"
                                style={{ zIndex: 1 }}
                              >
                                Start Survey To Complete Profile
                              </p>
                            )}

                            <table border={0} className="w-100">
                              <tbody className="bg-white">
                                {pendingProfileQues &&
                                  pendingProfileQues.length > 0 &&
                                  pendingProfileQues.map((task, index) => (
                                    <tr className="border-bottom" key={index}>
                                      <td>
                                        {capitalizeFirstLetter(task.taskName)}
                                      </td>
                                      {/* <td width='45%'><Progress percent={getProfilePercentage(task)} /></td> */}
                                      <td>
                                        <a
                                          className="mainbtn start_button"
                                          onClick={() =>
                                            getProfileSurveyQuestion(task)
                                          }
                                        >
                                          Start
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-lg-6 ">
                          <div className="bg-white p-3 px-4 h-100 com-bs pro-sec-info">
                            <div className="d-flex">
                              <div className="profile-info w-100">
                                <p className="fw-500 f-16 m-0"></p>

                                <p className="fw-500 fs-6 mb-2 m-0 fw-bold">
                                  <i className="fa mr-1 fa-user" aria-hidden="true">
                                    &nbsp;
                                  </i>
                                  {titlecase(user?.firstName) +
                                    " " +
                                    titlecase(user?.lastName)}
                                </p>
                                <div className="d-flex align-items-center">
                                  <p className="fw-500 mb-1 f-12 m-0 ">
                                    <i className="bi mr-2 bi-phone-vibrate-fill"></i>
                                    {user?.mobile}
                                  </p>
                                </div>
                                <p className="fw-500 f-12">
                                  <i
                                    className="fa mr-1 fa-map-marker fa-xl fa fa-2x"
                                    viewBox="0 0 450 515"
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp;&nbsp;
                                  {user?.country ? user?.country : user?.city}
                                </p>
                              </div>
                              <div className="rounded-circle d-flex justify-content-center align-items-center">
                                <img
                                  src={
                                    user?.profileImage
                                      ? user?.profileImage
                                      : user?.gender == "female"
                                        ? "../images/woman.png"
                                        : "../images/man.png"
                                  }
                                  className="rounded-circle pro-img align-middle"
                                  alt=""
                                />
                              </div>
                            </div>
                            <p className="text-orange fw-500 mb-1">Bio</p>
                            <p className="f-12 fw-500">{user?.bio}</p>
                            <hr />
                            <div className="loginDetails">
                              <p className="fw-semibold text-orange">
                                Login Details
                              </p>
                              <div className="row">
                                <p className="text-secondary mb-2">
                                  Your email
                                </p>
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                  <p className="fw-500 m-0">{user?.email}</p>
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#change-mail"
                                    className=""
                                  ></a>
                                </div>
                                <p className="text-secondary mb-2">
                                  Your password
                                </p>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="fw-500 m-0">
                                    *****************
                                  </p>
                                  <a
                                    data-bs-toggle="modal"
                                    data-bs-target="#change-pass"
                                    className=""
                                  >
                                    <img
                                      src="../images/Icon awesome-edit.svg"
                                      className="w-50"
                                    ></img>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <hr />
                            {/* <p className='text-orange fw-500'>Language</p>
                                            <p className='text-secondary'>Your account language is currently:</p>
                                            <div className="lang-sel mb-3">
                                                <select className="form-select py-3  f-14 fw-500 rounded-1" id="floatingSelect" aria-label="Floating label select example">
                                                    <option selected>English</option>
                                                    <option value="1">Hindi</option>
                                                    <option value="2">English</option>
                                                    <option value="3">Hindi</option>
                                                </select>
                                            </div> */}
                            <form className="w-100 float-bottom">
                              <Link to="/editProfile">
                                {" "}
                                <button className="w-100 p-3 mainbtn">
                                  Edit Profile
                                </button>
                              </Link>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal fade"
                      id="change-mail"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header bg-lgrey border-0">
                            <h5
                              className="modal-title text-orange"
                              id="staticBackdropLabel"
                            >
                              Email Address Change
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <input
                              type={"text"}
                              className="f-14 my-4 text-black fw-500 bg-lgrey py-4 form-control w-100 border-1"
                              placeholder="Enter New Email"
                            ></input>
                            <div className="py-4 w-100 text-center">
                              <button className="mainbtn">Verify</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="modal fade"
                      id="change-pass"
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header bg-lgrey border-0">
                            <h5
                              className="modal-title text-orange"
                              id="staticBackdropLabel"
                            >
                              Re-set Password
                            </h5>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={closeValue}
                            ></button>
                          </div>
                          <div className="modal-body">
                            <Space direction="vertical" className="w-100 ">
                              <Input className='bg-lgrey my-2' type="password" placeholder="Enter Current Password" value={pwd} onChange={(e) => { setCurrentPwd(e?.target.value) }} />
                              <Input.Password
                                className="bg-lgrey my-2"
                                placeholder="Enter Your New Password"
                                value={newPwd}
                                onChange={(e) => {
                                  setNewPwd(e.target.value);
                                }}
                              />
                              <Input.Password
                                className="bg-lgrey my-2"
                                placeholder="Re-enter Your New Password"
                                value={confirmPwd}
                                onChange={(e) => {
                                  setConfirmPwd(e.target.value);
                                }}
                              />
                            </Space>
                            <div className="py-4 w-100 text-center">
                              <button
                                className="mainbtn"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  resetPassword();
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tab == "rewards" && (
                  <div
                    className="tab-pane com-bs fade show active manreward"
                    id="v-pills-rewards"
                    role="tabpanel"
                    aria-labelledby="v-pills-rewards-tab"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="reward-dash container-fluid py-1 mb-4">
                      <div className="l-sec w-100">
                        <div className="row">
                          <div className="col-md-6 col-xl-3 mb-3">
                            <a>
                              <div className="rew-card h-100 rounded-2">
                                <p className="f-12 text-dblue fw-500 m-0">
                                  Total Points Earned
                                </p>
                                <span className="fs-2 text-orange fw-bold">
                                  {points.pointEarned}
                                </span>
                                <img src="../images/Group 18183.svg"></img>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6 col-xl-3  mb-3">
                            <a>
                              <div className="rew-card h-100 rounded-2">
                                <p className="f-12 text-dblue fw-500 m-0">
                                  Points Redeemed
                                </p>
                                <span className="fs-2 text-orange fw-bold">
                                  {points.pointRedeemed}
                                </span>
                                <br />
                                <p className="f-12 text-dblue fw-500 m-0">
                                  Points Worth ₹{points.pointRedeemed * 0.25}
                                </p>
                                <img src="../images/Group 18850.svg"></img>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6 col-xl-3 mb-3">
                            <a>
                              <div className="rew-card h-100 rounded-2">
                                <p className="f-12 text-dblue fw-500 m-0">
                                  Total Points Available
                                </p>
                                <span className="fs-2 text-orange fw-bold">
                                  {points.pointAvailable}
                                </span>
                                <p className="f-12 text-dblue fw-500 m-0">
                                  Points Worth ₹{points.pointAvailable * 0.25}
                                </p>
                                <img src="../images/Group 19733.svg"></img>
                              </div>
                            </a>
                          </div>
                          <div className="col-md-6 col-xl-3 mb-3">
                            <a
                              data-bs-toggle="modal"
                              data-bs-target="#comple-survey"
                            >
                              <div className="rew-card h-100 rounded-2">
                                <p className="f-12 text-dblue fw-500 m-0">
                                  Completed Survey
                                </p>
                                <span className="fs-2 text-orange fw-bold">
                                  {completedSurveys.length}
                                </span>
                                <img src="../images/Group 22108.svg"></img>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        className="avail-sur rounded-3 bg-white"
                        style={{
                          overflowX: "inherit",
                          maxHeight: "inherit !important;",
                        }}
                      >
                        <table border={0} className="w-100">
                          <tbody className="bg-white">
                            <tr className="bg-grey text-orange position-sticky top-0">
                              <th>S.No</th>
                              <th>Date</th>
                              <th>Redeemed Point</th>
                              <th>Voucher</th>
                              <th>Voucher Code</th>
                              <th>Status</th>
                            </tr>
                            {transactions &&
                              transactions.length > 0 &&
                              transactions.map((list, index) => (
                                <tr className="border-bottom" key={index}>
                                  <td>{index + 1}</td>
                                  <td>{list.date}</td>
                                  <td>{list.RedeemPoint}</td>
                                  <td>{list.voucherFor}</td>
                                  <td>{list.voucherCode}</td>
                                  <td>
                                    <span className="statusRewd">
                                      {list.status}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            {transactions && transactions.length == 0 && (
                              <tr className="border-bottom">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <b>No Record Found</b>
                                </td>
                                <td></td>
                                <td>
                                  <span className="statusRewd"></span>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        {/* <div className='row'>
                                                <div className='col-md-12 py-4' style={{textAlign:"right"}}>
                                        <div className="pagination">
          <a href="#"><i className="fa fa-chevron-left" aria-hidden="true"></i></a>
          <a href="#">1</a>
          <a href="#" className="active">2</a>
          <a href="#">3</a>
          <a href="#">4</a>
          <a href="#">5</a>
            <a href="#">6</a>
            <a href="#"><i className="fa fa-chevron-right" aria-hidden="true"></i></a>
          </div>
                                        </div>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                )}
                {tab == "refer" && (
                  <div
                    className="tab-pane fade show active pt-0"
                    id="v-pills-refer"
                    role="tabpanel"
                    aria-labelledby="v-pills-refer-tab"
                  >
                    <div className="container-fluid px-3 pt-1 earnSec manearn">
                      <div className="row p-2">
                        <div className=" col-xl-8 fotnSparagraph">
                          <h4 className="text-orange">Refer and Earn</h4>
                          <p>
                            Your views & opinions matter to an organization, so
                            why not get rewarded for it? How? Just Do Your
                            Survey!
                          </p>
                          <p>
                            Leverage the power of the referral program by
                            sharing the platform where you can not only earn for
                            completing surveys but also keep on getting rewards
                            for surveys done by people referred by you,
                            DoYourSurvey offers a multi-level-referral program
                            with 2-level depth and unlimited width with only
                            investment is your time and experiences.{" "}
                          </p>
                          <img
                            src="../images/Group 24273.svg"
                            className="w-100 mb-4"
                          />
                        </div>
                        <div className=" col-xl-4">
                          <br></br>

                          {/* ---------------Yfhjfjh,gfkjdgfhjgfjhdfgfukdkf------------------- */}

                          <div className="earnCrd p-4 bg-white">
                            <div className="treeview js-treeview">
                              <ul className="p-0">
                                <li className="firstTreek">
                                  <div
                                    className="treeview__level"
                                    data-level=""
                                  >
                                    <span className="level-title">User</span>
                                  </div>
                                  <ul>
                                    <li>
                                      <div
                                        className="treeview__level"
                                        data-level="L1"
                                      >
                                        <span className="level-title">
                                          {" "}
                                          {referCode &&
                                            referCode[0].referral1_count}{" "}
                                          Members
                                        </span>
                                      </div>
                                      <ul>
                                        <li>
                                          <div
                                            className="treeview__level"
                                            data-level="L2"
                                          >
                                            <span className="level-title">
                                              {referCode &&
                                                referCode[0].referral2_count}
                                              Members
                                            </span>
                                          </div>

                                          {/* <ul>
                                                                                    <li>
                                                                                        <div className="treeview__level" data-level="D">
                                                                                            <span className="level-title">Level A</span>
                                                                                        </div>
                                                                                        <ul>
                                                                                        </ul>
                                                                                    </li>
                                                                                </ul> */}
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {/* ---------------Yfhjfjh,gfkjdgfhjgfjhdfgfukdkf------------------- */}
                          <div className="">
                            {/* <ReactPlayer url='../images/video/Comp1_1.mp4'  style={{marginTop:"20px",blockSize:"400px",position:"relative",width:"347px",height:"210px",paddingbottom: "100px",paddingright: "174px"}} controls={true} config={{ file: { attributes: {autoPlay: true,muted: false}}}}/> */}
                            <video
                              src="../images/video/Comp1_1.mp4"
                              className="mt-3 com-bs earnCrd"
                              preload="auto"
                              style={{ width: "100%", height: "100%" }}
                              controls={true}
                              config={{
                                file: {
                                  attributes: { autoPlay: true, muted: false },
                                },
                              }}
                              loop
                              autoPlay
                            ></video>
                          </div>
                        </div>

                        <div className="col-md-12 pt-3">
                          {/* <h4 className='text-orange'>Example</h4> */}

                          {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classNameical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p> */}

                          <p>Consider this example:</p>
                          <p>
                            You refer DoYourSurvey to a friend, X. X completes
                            the survey & gets rewarded.
                          </p>
                          <p>
                            X further refers the platform to Y who will also
                            earn rewards after completing the survey.
                          </p>
                          <p>
                            {" "}
                            Now, in this entire process, you will be earning
                            reward points:
                          </p>
                          <p> • For each survey you attempt yourself</p>
                          <p>• A percentage of each survey X attempts</p>
                          <p> • A percentage of each survey Y attempts</p>
                          <p>
                            {" "}
                            Now when Y refers DYS to someone, X & Y will enjoy
                            the earnings of this referral.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {tab == "help" && (
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-help"
                    role="tabpanel"
                    aria-labelledby="v-pills-help-tab"
                  >
                    <div className="container-fluid bg-white">
                      <div className="row text-center h-100">
                        {/* <div className='d-flex justify-content-center align-items-center flex-column py-5 bg-lorange'>
                                                    <p className='fs-1 text-black fw-semibold mb-2'>Frequently <span className='text-orange'>asked Questions</span></p>
                                                    <div className='col-md-6 text-center'>
                                                        <p className='text-secondary m-0'></p>
                                                    </div>
                                                </div> */}
                        <p className="text-orange fw-semibold mt-4">
                          Our FAQ's
                        </p>
                        <p className="fs-3 fw-bold">Have any Questions?</p>
                        <ul
                          className="nav nav-tabs help-tabs justify-content-center"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="general-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#general-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="general-tab-pane"
                              aria-selected="true"
                            >
                              general
                            </button>
                          </li>
                          {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="service-tab" data-bs-toggle="tab" data-bs-target="#service-tab-pane" type="button" role="tab" aria-controls="service-tab-pane" aria-selected="false">service</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment-tab-pane" type="button" role="tab" aria-controls="payment-tab-pane" aria-selected="false">payment</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="shop-tab" data-bs-toggle="tab" data-bs-target="#shop-tab-pane" type="button" role="tab" aria-controls="shop-tab-pane" aria-selected="false">shop</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="refund-tab" data-bs-toggle="tab" data-bs-target="#refund-tab-pane" type="button" role="tab" aria-controls="refund-tab-pane" aria-selected="false">refund</button>
                                        </li> */}
                        </ul>
                        <div
                          className="tab-content help-tab-content"
                          id="myTabContent"
                        >
                          <div
                            className="tab-pane fade show active"
                            id="general-tab-pane"
                            role="tabpanel"
                            aria-labelledby="general-tab"
                            tabIndex="0"
                          >
                            <div className="row justify-content-center">
                              <div className="col-md-8">
                                <div
                                  className="accordion"
                                  id="accordionExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        + About DoYourSurvey
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          {" "}
                                          DoYourSurvey Services works in
                                          transforming the customer experience.
                                          We provide process consulting,
                                          sentiment analysis, quality assurance
                                          audits, service excellence, market
                                          research, mystery shopping, and much
                                          more to enhance customer experience
                                          enabled with in-house developed
                                          solutions. Working with both large and
                                          small organizations from diverse
                                          industries pertaining to automobile,
                                          telecom, banking, fashion, retail,
                                          consumer electronics, fitness,
                                          e-commerce, etc., this service sector
                                          organization is continuously expanding
                                          its footprints
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        + How do I become a member of
                                        DoYourSurvey
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          It's very easy. Simply fill in your
                                          details on our sign-up page. We'll
                                          send you a mail after Successfully
                                          registration so you are able to
                                          confirm your membership.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        + Can I join DoYourSurvey if I live
                                        outside India
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No, unfortunately membership of this
                                          online panel is open only to residents
                                          of India
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFour"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                      >
                                        + Will it cost me anything to join
                                        DoYourSurvey
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFour"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFour"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          There are no costs associated with
                                          joining the panel and you can leave at
                                          any time. However, you will need an
                                          e-mail address to receive invitations
                                          to participate in surveys and you need
                                          to be able to access the internet to
                                          complete our online surveys
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFive"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive"
                                        aria-expanded="false"
                                        aria-controls="collapseFive"
                                      >
                                        + What happens after I join DoYourSurvey
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFive"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFive"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          We will send you an e-mail welcoming
                                          you to DoYourSurvey and you will begin
                                          receiving e-mail invitations to take
                                          surveys. The e-mails will contain all
                                          the information you need to take part.
                                          Once you complete the survey, you'll
                                          have the opportunity to earn points
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingsix"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapsesix"
                                        aria-expanded="false"
                                        aria-controls="collapsesix"
                                      >
                                        + How often will I be asked to take
                                        surveys
                                      </button>
                                    </h2>
                                    <div
                                      id="collapsesix"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingsix"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          We send out survey invitations on a
                                          regular basis, but cannot say who or
                                          when any member will receive
                                          invitations. This is because each
                                          survey is customized and based around
                                          the research goals for each individual
                                          client. Depending on your profile, we
                                          may ask you to complete surveys more
                                          or less often. For this reason, we ask
                                          you to keep your profile as up to date
                                          as possible
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingseven"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseseven"
                                        aria-expanded="false"
                                        aria-controls="collapseseven"
                                      >
                                        + Why are surveys conducted
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseseven"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingseven"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          DoYourSurvey conducts surveys because
                                          businesses, governments, public
                                          bodies, and similar organizations are
                                          interested in the views and attitudes
                                          of the people who use their products
                                          and services. The more they know about
                                          what customers and citizens think, the
                                          easier it is for them to improve and
                                          adapt what they supply
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingeight"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseeight"
                                        aria-expanded="false"
                                        aria-controls="collapseeight"
                                      >
                                        + How long does an average survey take
                                        to complete
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseeight"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingeight"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          The number of questions varies from
                                          one survey to another. We try to keep
                                          surveys as short as possible, although
                                          we have to make sure the results are
                                          meaningful. In general a survey should
                                          take around 10 to 15 minutes to
                                          complete
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingnine"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapsenine"
                                        aria-expanded="false"
                                        aria-controls="collapsenine"
                                      >
                                        + Can you mail the surveys to me or
                                        conduct them over the phone
                                      </button>
                                    </h2>
                                    <div
                                      id="collapsenine"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingnine"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Unfortunately there is no other way to
                                          take part in these online surveys, you
                                          need to complete them over the
                                          Internet
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingten"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseten"
                                        aria-expanded="false"
                                        aria-controls="collapseten"
                                      >
                                        + If I become a member of DoYourSurvey,
                                        will I receive e-mails advertising other
                                        products and services
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseten"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingten"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          No. We do not sell the personal
                                          information of our members and we will
                                          never try to sell you a product or
                                          service
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="service-tab-pane"
                            role="tabpanel"
                            aria-labelledby="service-tab"
                            tabIndex="0"
                          >
                            <div className="row justify-content-center">
                              <div className="col-md-8">
                                <div
                                  className="accordion"
                                  id="accordionExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        Accordion Item #1
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFour"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFour"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFour"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFive"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive"
                                        aria-expanded="false"
                                        aria-controls="collapseFive"
                                      >
                                        Accordion Item #3
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFive"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFive"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="payment-tab-pane"
                            role="tabpanel"
                            aria-labelledby="payment-tab"
                            tabIndex="0"
                          >
                            {" "}
                            <div className="row justify-content-center">
                              <div className="col-md-8">
                                <div
                                  className="accordion"
                                  id="accordionExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        Accordion Item #1
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFour"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFour"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFour"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFive"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive"
                                        aria-expanded="false"
                                        aria-controls="collapseFive"
                                      >
                                        Accordion Item #3
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFive"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFive"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="shop-tab-pane"
                            role="tabpanel"
                            aria-labelledby="shop-tab"
                            tabIndex="0"
                          >
                            <div className="row justify-content-center">
                              <div className="col-md-8">
                                <div
                                  className="accordion"
                                  id="accordionExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        Accordion Item #1
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFour"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFour"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFour"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFive"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive"
                                        aria-expanded="false"
                                        aria-controls="collapseFive"
                                      >
                                        Accordion Item #3
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFive"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFive"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade"
                            id="refund-tab-pane"
                            role="tabpanel"
                            aria-labelledby="refund-tab"
                            tabIndex="0"
                          >
                            {" "}
                            <div className="row justify-content-center">
                              <div className="col-md-8">
                                <div
                                  className="accordion"
                                  id="accordionExample"
                                >
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingOne"
                                    >
                                      <button
                                        className="accordion-button"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        Accordion Item #1
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseOne"
                                      className="accordion-collapse collapse show"
                                      aria-labelledby="headingOne"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingTwo"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseTwo"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingTwo"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingThree"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseThree"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingThree"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFour"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                      >
                                        Accordion Item #2
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFour"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFour"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="accordion-item">
                                    <h2
                                      className="accordion-header"
                                      id="headingFive"
                                    >
                                      <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFive"
                                        aria-expanded="false"
                                        aria-controls="collapseFive"
                                      >
                                        Accordion Item #3
                                      </button>
                                    </h2>
                                    <div
                                      id="collapseFive"
                                      className="accordion-collapse collapse"
                                      aria-labelledby="headingFive"
                                      data-bs-parent="#accordionExample"
                                    >
                                      <div className="accordion-body">
                                        <p>
                                          Lorem Ipsum is simply dummy text of
                                          the printing and typesetting industry.
                                          Lorem Ipsum has been the industry's
                                          standard dummy text ever since the
                                          1500s, when an unknown printer took a
                                          galley of type and scrambled it to
                                          make a type specimen book
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className="tab-pane com-bs fade show active" id="v-pills-logout" role="tabpanel" aria-labelledby="v-pills-logout-tab" ></div> */}
              </div>
            </div>
          </section>
          {/* --------------------------------------------------------modals main dashboard-------------------------------------------------------------- */}

          <div
            className="modal fade"
            id="redeem-points"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header align-items-center bg-lgrey border-0">
                  <h5
                    className="modal-title text-orange m-0"
                    id="staticBackdropLabel"
                  >
                    Redeem Reward Points
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClose}
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-6 mb-3">
                      <a>
                        <div className="p-0 card h-100 rounded-2">
                          <div className="card-body">
                            <p className="f-12 text-orange fw-500 m-0">
                              Total Points Available
                            </p>
                            <span className="fs-2 text-blue fw-bold">
                              {points.pointAvailable}
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-6 mb-3">
                      <a>
                        <div className="p-0 card h-100 rounded-2">
                          <div className="card-body">
                            <p className="f-12 text-orange fw-500 m-0">
                              Points Worth
                            </p>
                            <span className="fs-2 text-blue fw-bold">
                              ₹{points.pointAvailable * 0.25}
                            </span>
                            <br />
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="rew-box rounded-1 d-flex justify-content-center p-2 mb-3">
                    <i className="bi mx-3 bi-info-circle-fill fs-6 text-orange"></i>
                    <span className="fw-semibold">
                      Minimum 1000 reward points redeem at a time
                    </span>
                  </div>
                  <input
                    type={"number"}
                    className=" bg-lgrey form-control w-100"
                    placeholder="Enter Points to Redeem"
                    value={pointsToRedeem}
                    onChange={(e) => {
                      checkPointsAvailable(e);
                    }}
                  ></input>
                  <br></br>
                  {redeemError && (
                    <div className="rew-box rounded-1 d-flex justify-content-center">
                      <i className="bi mx-3 bi-info-circle-fill fs-6 text-orange"></i>
                      <span className="fw-semibold">
                        You do not have enough points to redeem !
                      </span>
                    </div>
                  )}
                  <div className="my-3 mx-2 d-flex align-items-center fw-semibold ">
                    Points Worth
                    <span className="bg-blue text-white rounded-1 fs-4 mx-3 px-3 py-1">
                      ₹{pointsToRedeem * 0.25}
                    </span>
                  </div>
                  <input
                    type="text"
                    className=" bg-lgrey form-control w-100 mb-3"
                    value={user?.mobile}
                    maxLength="10"
                    onChange={(e) => setPaytmNo(e.target.value)}
                    placeholder="Enter Paytm Number"
                  ></input>
                  {!redeemButton && (
                    <div className="text-center">
                      <button
                        className="mainbtn w-75 my-2"
                        onClick={redeemPoint}
                        data-bs-toggle="modal"
                        data-bs-target="#redeempnts"
                      >
                        Redeem Point
                      </button>
                    </div>
                  )}
                </div>
                {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Understood</button>
       </div> */}
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="redeempnts"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header bg-lgrey border-0">
                  <h5
                    className="modal-title text-orange"
                    id="staticBackdropLabel"
                  >
                    Completed Survey
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="text-center pb-5 px-3">
                      <lottie-player
                        src="../images/a283e16a-e614-40b0-b9af-66e1ebd8fd1a.json"
                        background="transparent"
                        speed={1}
                        style={{ width: "100%", height: "250px" }}
                        loop
                        autoPlay
                      />
                      <p className="mb-2 text-orange fs-3 fw-semibold">
                        Congratulations
                      </p>
                      <p className="px-4 py-2">
                        imply dummy text of the printing and typesetting
                        industry. Lorem
                      </p>
                      <a href="#" className="mainbtn ">
                        Go to Dashboard
                      </a>
                    </div>
                  </div>
                </div>
                {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Understood</button>
       </div> */}
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="comple-survey"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header bg-lgrey border-0">
                  <h5
                    className="modal-title text-orange"
                    id="staticBackdropLabel"
                  >
                    Completed Survey
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body p-2">
                  <div className="avail-sur rounded-3">
                    <table border={0} className="w-100">
                      <tbody className="bg-white">
                        <tr className="bg-grey text-orange position-sticky top-0">
                          <th>Survey Completed</th>
                          <th>Points Earned</th>
                          <th></th>
                          <th></th>
                        </tr>
                        {completedSurveys &&
                          completedSurveys.map((survey, index) => (
                            <tr className="border-bottom" key={index}>
                              <td>{survey.survey_name}</td>
                              <td>{survey.points ? survey.points * 0.7 : 0}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Understood</button>
       </div> */}
              </div>
            </div>
          </div>
          {/* --------------------------------------------------------modals main dashboard-------------------------------------------------------------- */}
        </main>
      )}
      {screen && (
        <SurveyScreen
          survey={surveyQues}
          type={type}
          profilePercent={profilePercent}
          pointsPercent={pointsPercent}
          setSurveyScreen={setSurveyScreen}
        />
      )}

      <ToastContainer />
    </>
  );
}

export default PanelistDashboard;
