import React, { useEffect, useState } from 'react'
// var NewComponent = React.createClass({
const Templeates=()=>{

  const [userData, setUserData] = useState()


  useEffect(() => {
      let userData = JSON.parse(localStorage.getItem('user'));
      setUserData(userData);
    

  }, [])
  const logOut=()=>{
      localStorage.clear();
    }
      return (
        <div>
          <section id="all-section" className="admin-header">
                    <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                        <div className="container-fluid ">
                            <a className="navbar-brand1" href="#"><img src="../images/new-logo.png" width="100px" alt="" /></a>
                            <ul className="navbar-nav flex-row">
                                
                                <li className="nav-item dropdown">
                                    <a className="nav-link nav dropdown-toggle d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="../images/2@2x.png" width="50px" className='rounded-circle' alt="" /><p className='aname m-0 px-2 text-black'>{userData && userData.fullName}</p><i className="text-black bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-lg-end">
                                        <li><a className="dropdown-item border-bottom" href="#"><i className="bi bi-person-fill pe-2" />Profile</a></li>
                                        <li><a className="dropdown-item" onClick={logOut}><i className="fa fa-sign-out pe-2" aria-hidden="true" />Logout</a></li></ul>
                                </li>
                            </ul>
                        </div></nav>
                </section>
          <section id="all-section" className="section-survey ">
            <div className="container-fluid ">
              <div className="row ">
                {/* Sidebar  */}
                <div className="col-lg-3 col-md-4">
                  <div className="py-4 px-3 templateCatSelect bg-white h-100 mt-3" style={{border: '1px solid #D8D8D8'}}>
                    <h5>Template gallery</h5>
                    <p>Use templates to get to know <a href="#">Do Your Survey</a>, or just get inspired. There's something for every job you need to get done</p>
                    <hr />
                    <h5>Categories</h5>
                    <ul className="clategoryListt">
                      <li><a href="#">Feedback <span>10</span></a></li>
                      <li><a href="#">Research <span>16</span></a></li>
                      <li><a href="#">Quiz <span>17</span></a></li>
                      <li><a href="#">Registration, <span>22</span></a></li>
                      <li><a href="#">Feedback <span>40</span></a></li>
                      <li><a href="#">Quiz <span>15</span></a></li>
                      <li><a href="#">Feedback <span>10</span></a></li>
                      <li><a href="#">Research <span>16</span></a></li>
                      <li><a href="#">Quiz <span>17</span></a></li>
                      <li><a href="#">Registration, <span>22</span></a></li>
                      <li><a href="#">Feedback <span>40</span></a></li>
                      <li><a href="#">Quiz <span>15</span></a></li>
                      <li><a href="#">Feedback <span>10</span></a></li>
                      <li><a href="#">Research <span>16</span></a></li>
                      <li><a href="#">Quiz <span>17</span></a></li>
                      <li><a href="#">Registration, <span>22</span></a></li>
                      <li><a href="#">Feedback <span>40</span></a></li>
                      <li><a href="#">Quiz <span>15</span></a></li>
                    </ul>
                    <p className="text-center mt-5">Have your own ideas in mind?</p>
                    <p className="text-center"><a href="#" style={{color: '#354C9E', borderBottom: '1px solid', fontWeight: 500}}>Start Blank Survey</a></p>
                  </div>
                </div>
                {/* Page Content  */}
                <div className="col-md-8 col-lg-9">
                  <div id="content" className="w-100 p-0 m-0 mt-3">
                    <div className="w-100  px-4 pt-4 bg-white">
                      <form>
                        <div className="d-flex formImport">
                          <div className="input-group mr-3">
                            <span className="input-group-text bg-white"><i className="fa fa-search" /></span>
                            <input type="text" placeholder="Search for a Template" name="search" className="bg-white form-control" style={{height: '48px', borderLeft: 'none'}} />
                          </div>
                          <button type="submit" className="mainbtn">Import Form</button>
                        </div>
                      </form>
                    </div>
                    <div className=" px-4 py-4 h-100 w-100 bg-white templateListSec">
                      <h6 className="mb-4">Feedback</h6>
                      <div className="row mb-5">
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                      </div>
                      <h6 className="mb-4">Feedback</h6>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Template</p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="templateBox text-center">
                            <div className="imgBox w-100">
                              <img src="src/try-popup.png" className="h-100 w-100" />
                              <div className="overflowWatch w-100 h-100">
                                <a href="#" className="mainbtn ">Preview</a>
                              </div>
                            </div>
                            <p className="mt-2">Product Feedback Survey Templatefgfg</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* jQuery CDN - Slim version (=without AJAX) */}
                {/* Popper.JS */}
                {/* Bootstrap JS */}
                {/* jQuery Custom Scroller CDN */}
              </div>
            </div>
          </section>
        </div>
      );
    }

    export default Templeates
//   });