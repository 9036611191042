import React, { useState } from 'react'
import SurveyDashboard from '../surveyDashboard/surveyDashboard'
import { toast, ToastContainer } from 'react-toastify';
import API from '../../utils/apiCalling';
import Config from "../../config/env";

export default function Pushnotification() {
  const [title,setTitle]=useState()
  const [body,setBody]=useState()

  const api = new API
   const handleClick=async()=>{
    // console.log(title,body)
    if(!title){
      toast.error('Please enter title', {
        position: toast.POSITION.TOP_RIGHT
    });
    }else if(!body){
      toast.error('Please enter details', {
        position: toast.POSITION.TOP_RIGHT
    });
    }else{
    let data ={
      title:title,
      body: body
    }
    const result = await api.post(`${Config.new.site_api}/admin-dashboard/adminPushNotification`,data)
    if(result){
      setTitle("")
      setBody("")
      if (result.code === 200) {
        toast.success(result.message, {
          position: toast.POSITION.TOP_RIGHT
      });
      }else{
        toast.error(result.message, {
          position: toast.POSITION.TOP_RIGHT
      });
      }
    }else{
      alert('Somthing went wrong')
    }
   }
   }
  return (
    <div className='form-control' style={{textAlign:'center',marginBottom:"10"}}>
       
        <form>
        <div >
        <textarea className='mb-25 mt-5 w-50' type='text'  placeholder='Please enter your title' onChange={(e)=>setTitle(e.target.value)} /><br/>
        <textarea className='mb-25 mt-5 w-50' type='text' placeholder='Please enter your details' onChange={(e)=>setBody(e.target.value)} />
        </div>
       <div>
       <button type="submit" value="Submit"  className="btn btn-primary btn-lg" onClick={(e)=>handleClick()}>Push</button>
       </div>
       </form>
       <ToastContainer/>
    </div>
  )
}
