import React, { useState } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
// import { config } from '../../utils/apiUrl';
// import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import './paytm.css'
// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link, useHistory } from "react-router-dom";

const Navbar = (props) => {
    const api = new API()
    const history = new useHistory()

    let adminToken = getLocalDataAsObject("adminToken")
    if (!adminToken) {
        history.push("/")
    }
    const Logout = () => {
        localStorage.removeItem("adminToken");
        localStorage.removeItem("user");
        history.push("/")
    }
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light text-white" style={{ backgroundColor: "#6f4e37" }}>
                <a className="navbar-brand text-white" href="#/192837465/adminDashboard">DoYourSurvey</a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            {/* <a className="nav-link text-white" >Paytm Dashboard </a> */}
                        </li>
                      
                        
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                        <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
                    </form>
                    <button className="btn btn-secondary ml-5"onClick={Logout}>Logout</button>
                </div>
            </nav>
            


        </div>
    );
}

export default Navbar;