import { React, useState, useEffect } from 'react'

import Header2 from '../Components/ReusableComponents/Header2'
import Footer from '../Components/ReusableComponents/Footer';
import API from '../utils/apiCalling';
import Config from '../config/env'
import { toast, ToastContainer } from 'react-toastify';


export default function ClientProfile() {
    const api = new API();
    const [file, setFile] = useState('')
    const [isShown, setIsShown] = useState();
    const [planName, setPlanName] = useState()
    const [country, setCountry] = useState([])
    const [editProfileImg, setEditProfileImg] = useState()
    const [attribute, setAttribute] = useState(true)
    const [client, setClient] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        email: '',
        mobile: '',
        country: '',
        profileImage:''
    });

    useEffect(() => {
        getPlan();
        selectCountry();
        setClient({
            ...client,
            "profileImage":client.profileImage,
            "firstName": client.firstName,
            "lastName": client.lastName,
            "dob": client.dob,
            "gender": client.gender,
            "email": client.email,
            "mobile": client.mobile,
            "country": client.country
        })
    }, [file]);


    const getPlan = async () => {
        let result = await api.gett(
            `${Config.new.site_api}/client-survey/getPlan`
        );
        if (result.code === 200) {

            let clientData = JSON.parse(localStorage.getItem("user"));
            setClient(clientData);
            getClientName(clientData, result.data)
        }
    };

   


    const getClientName = (clientData, plan) => {

        let index = plan.findIndex(x => x._id === clientData.plan_id)
        setPlanName(plan[index].Name);

    };

    const selectCountry = async () => {
        let result = await api.get(`${Config.new.site_api}/client-survey/getCountries`, {
        })
        if (result && result.code === 200) {
            let Data = result.data[0].options
            setCountry(Data);
        }

    };

    const editClientProfile = () => {
        setAttribute(false);
        setIsShown("false");
    };


    const handlechange = (e) => {
        let { name, value } = e.target
        setClient({ ...client, [name]: value })
        

    };

    const updateProfile = async () => {
        setIsShown("");
        setAttribute(true);
        let result = await api.post(`${Config.new.site_api}/dashboard/editProfile`, client)

        if (result.code === 200) {

            localStorage.setItem("user", JSON.stringify(client));
            window.location.reload();
            toast.success('Profile updated successfully.', {
                position: toast.POSITION.TOP_RIGHT

            });
            let clientData={...client}
            clientData.profileImage=result.data
            setClient(clientData); 
        }
        if (result.code !== 200) {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });

        }
    };
    const editProfiledp = async (e) => {
        let file = e.target?.files;
        setEditProfileImg(URL.createObjectURL(file[0]))
        let newdata = new FormData();
        newdata.append("Id", client._id);
        newdata.append("file", file[0]);
        let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadDP`, newdata)
        if (result.code === 200) {
            localStorage.setItem("user", JSON.stringify(result.data));
            toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            let clientData={...client}
            clientData.profileImage=result.data.profileImage
            setClient(clientData); 
        }

    }

    return (
        <>

            <div className="nav" >
                <Header2 />
            </div>

            <div className="bg-white p-5 h-100 com-bs pro-sec-info d-flex align-items-center flex-column" >
             
                <div className="col-md-12 col-lg-7">
                <div className='shadow-sm p-3 mb-3  bg-body rounded'>
                <div className="d-flex justify-content-center h-100 " style={{ marginTop: "30px" }}>
                        <div className="profile-editsc d-flex">
                            <div class="rounded-circle d-flex justify-content-center align-items-center border-dark position-relative" >
                           {attribute==false&& <i class=" bi bi-pencil-square" style={{    bottom: "13px", right: "52px" }}></i>}
                            <div style={{ position:"absolute", right:"-5", bottom:"11px"}}>
                            {attribute==false&&<input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" className=" right-0 top-0" 
                            style={{width: "90px", opacity: "0", marginLeft:"40px"}}
                                onChange={(e) => editProfiledp(e)} />}
                                </div>
                                <img src={editProfileImg? editProfileImg:
                                    client.profileImage
                                        ?  client.profileImage
                                        :client.gender == "female"
                                            ? "../images/woman.png"
                                            : "../images/man.png"
                                } class="rounded-circle pro-img align-middle" alt="" style={{ height: 120, width: 120 }} /> 
                        </div>
                        
                            </div>
                            <label for="imageUpload"></label>
                    </div>
                
                <hr />
                    <div className="bg-white loginBox text-center p-0">
                        <div className="row">


                            <div className="col-lg-6 col-md-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-person-fill"></i></span>
                                    <input type="text" className="form-control " placeholder="First Name"
                                        value={client.firstName}
                                        name="firstName"
                                        onChange={(e) => handlechange(e)}
                                        readOnly={attribute}

                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="bi bi-person-fill"></i></span>
                                    <input type="text" className="form-control  " placeholder="Last Name"
                                        value={client.lastName}
                                        name="lastName" onChange={(e) => handlechange(e)}
                                        readOnly={attribute}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="row">

                            <div className="col-lg-6 col-md-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1"><i className="fa fa-calendar"></i><i className="fa-solid fa-calendar-days" /></span>
                                    <input type="date" className="form-control " placeholder="Date Of Birth..." aria-label="DOB"
                                        value={client.dob?.slice(0, 10)}
                                        aria-describedby="basic-addon1" name="dob"
                                        onChange={(e) => handlechange(e)}
                                        readOnly={attribute}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="input-group mb-3"><span class="input-group-text" id="basic-addon1"> <i class="fa fa-venus-mars"></i></span>
                                    <select class="form-select mediaender" aria-label="Default select example" name="gender" onChange={(e) => handlechange(e)} disabled={attribute}
                                        style={{
                                            backgroundColor: attribute == true ? 'transparent' : 'transparent'
                                        }}
                                    >
                                        <option hidden>{(client.gender) ? client.gender : "Select gender"}</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="row">



                            <div className="col-lg-6 col-md-12">
                                <div className="input-group mb-3 ">
                                    <span className="input-group-text"><i className="fa fa-envelope" /></span>
                                    <input type="email" className="form-control " placeholder="Enter Your Email"
                                        value={client.email}
                                        name="email"
                                        onChange={(e) => handlechange(e)}
                                        readOnly
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1"> <i className="bi bi-phone-fill"></i></span>
                                    <input type="Number" className="form-control  quantity" placeholder="Enter Mobile No..." aria-label="Username"
                                        value={client.mobile}
                                        name="mobile" aria-describedby="basic-addon1"
                                        onChange={(e) => handlechange(e)}
                                        readOnly={attribute}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <div className="input-group mb-3">
                                    <span className="input-group-text"><i className="fa fa-globe" ></i></span>
                                    <select class="form-select mediaender  " aria-label="Default select example" name="country"
                                        onChange={(e) => handlechange(e)}
                                        disabled={attribute}
                                        style={{
                                            backgroundColor: attribute == true ? 'transparent' : 'transparent'
                                        }}
                                    >
                                        <option value={client.country} hidden>{(client.country) ? client.country : "Select country"}</option>
                                        {country.map(e => (
                                            <option value={e.type}>{e.type}</option>
                                        ))}

                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="input-group mb-3 ">
                                    <span className="input-group-text"><i className="fa fa-key" /></span>
                                    <input type="text" className="form-control" placeholder="Your Plan"
                                        value={planName}
                                        name="plan"
                                        readOnly />
                                </div>
                            </div>

                        </div>
                {isShown === "false" ?
                    <button onClick={updateProfile} className="p-3 mainbtn">Update Profile</button> :
                    <button onClick={editClientProfile} className="p-3 mainbtn">Edit Profile</button>
                }
                    </div>
                </div>
                </div>
            </div>
            <br />
            <div className="footer">
                <Footer />
            </div>
        </>
    )
}
