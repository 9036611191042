import React, { useEffect, useRef } from "react";
import { useState } from 'react';
import Header from "../Components/ReusableComponents/Header";
import API from "../utils/apiCalling";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { Button, Select, Form, Input, TextArea } from 'antd';
import Admindash from "./admindash";
import { useForm } from "antd/lib/form/Form";
import Header2 from "../Components/ReusableComponents/Header2";
import Config from '../config/env'


const TemplateQuestion = () => {
  let api = new API();
  let memberPopup = useRef()
  const[search,setSearch]=useState("")
 


  const [member, setMembers] = useState({ user_role: "member", email: "", name: "" })
  const [user, setUserData] = useState("");
  const [client, setClient] = useState([])
  const [index, setIndex] = useState()
  const roles = [{ role: "admin", name: "Admin" }, { role: "member", name: "Member" }]
  const [edit, setEdit] = useState(false)
  const [memberForm] = useForm();
 const [addClient,setAddClient]=useState("")
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user"));
    setUserData(userData);
    getClient(userData)
    console.log(client,"???????????")
 
  }, [])
  // const filterSearch=(value)=>{
  //   // console.log(client,">>>>>>>")
  //   let temp = [];
   
  //   client.forEach((element,i) => {
  //     console.log(element.name,value.target.value,"?????????????")
  //     if (element.name.includes(value.target.value))
  //      {
  //       temp.push(element)
  //     };
  //   });
  //   console.log(temp)
  //  setSearch(temp);
  //  console.log(search,">>>>>>")
  
  // }

  const getRoles = (value) => {
    let name = "-"
    roles.forEach((role) => {
      if (role.role == value) {
        name = role.name
      }
    })

    return name
  }

  const saveMember = async (mode, ind, memberData) => {
    let info = memberData ? memberData : member

    let data = {
      user_role: info.user_role,
      email: info.email,
      name: info.name,
      _id: info._id,
      updated_by: user._id,
      is_deleted: mode && mode == 'delete' ? 1 : 0
    }

    let result = await api.post(`${Config.new.site_api}/client/updateClientMembers`, data)
    if (result.code === 200) {
      memberPopup.current.click()
      setMembers({ ...member, name: "", user_role: "", email: "" })
      memberForm.setFieldsValue({
        username: "",
        useremail: "",
        userrole: ""
      })
      let options = [...client]
      mode == "delete" ? options.splice(ind, 1) : options[index] = data;
      setClient(options)
      toast.success(result.message, {
        position: toast.POSITION.TOP_RIGHT,
      });

    } 
  }


  const addMembers = async () => {
    let data = {
      user_role: member.user_role,
      email: member.email,
      name: member.name,
      parent_id: user._id

    }

    let result = await api.post(`${Config.new.site_api}/client/addClientMembers`, data)
    if (result.code === 200) {
       setAddClient(result.data)
      setMembers({ ...member, name: "", user_role: "", email: "" })
      memberForm.setFieldsValue({
        username: "",
        useremail: "",
        userrole: ""
      })
   
      let options = [...client]
      options.push(result.data)
      setClient(options)
    
      toast.success(result.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
        memberPopup.current.click()

    }else if (result.code === 201) {
      
      toast.error("email already exist", {
        position: toast.POSITION.TOP_RIGHT,
        
      });
      memberPopup.current.click()

    }else{

    }

  }

  function editMember(info, index) {
    setEdit(true)
    setIndex(index)
    setMembers({ ...member, name: info.name, user_role: info.user_role, email: info.email, _id: info._id })
    memberForm.setFieldsValue({
      username: info.name,
      useremail: info.email,
      userrole: info.user_role
    })
  }

  const getClient = async (user) => {
 
    let result = await api.post(`${Config.new.site_api}/client/getClientMembers`, { parent_id: user._id })
    if (result.code === 200) {

      setClient(result.data)
    

    }
  }

  function deleteMember(info, index) {
    saveMember("delete", index, info)
  }
  return (
    <>
      <div
        className="modal fade"
        id="demoThisVart"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h1 className="modal-title m-0 text-orange" id="exampleModalLabel">
                {edit ? "Edit Member Details" : "Add Member"}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={memberPopup}
              ></button>
            </div>
            <div className="modal-body">
              <Form
                name="normal_login"
                className="login-form "
                form={memberForm}
                initialValues={{ remember: true }}
                onFinish={(e) => edit ? saveMember() : addMembers()}>
                <div className="row dattimesha">
                  <div className="col-md-6  site-form-item-icon">
                    <label for="birthday" className="fw-4 text-blue">
                      User Role :
                    </label>
                    <Form.Item
                      name="userrole"
                      rules={[{ required: true, message: 'Please input role!' }]}>
                      <select class="form-select" aria-label="Default select example" name="userole" value={member.user_role} onChange={(e) => setMembers({ ...member, user_role: e.target.value })}>
                        <option selected>Please Select Your Role</option>
                        <option value="admin" >Admin</option>
                        <option value="member" >Member</option>

                      </select>
                    </Form.Item>

                  </div>
                  <div className="col-md-6">
                    <label for="birthday" className="fw-4 text-blue site-form-item-icon">
                      Name:
                    </label>
                    <Form.Item
                      name="username"
                      rules={[{ required: true, message: 'Please input name!' }]}>
                      <Input
                        type="text"
                        className="mb-3 site-form-item-icon"
                        id="Demo"
                        name="name"
                        value={"popopoppp"}
                        onChange={(e) =>
                          setMembers({ ...member, name: e.target.value })
                        }
                      />
                    </Form.Item>

                  </div>
                  <div className="col-md-6 site-form-item-icon">
                    <label for="birthday" className="fw-4 text-blue">
                      Email:
                    </label>
                    <Form.Item name="useremail"
                      rules={[{
                        type: 'email', message: 'The input is not valid E-mail!',
                      }, {
                        required: true, message: 'Please input your E-mail!',
                      }]}>
                      <Input
                        type="text"
                        id="Demo"
                        className="form-control mb-3 site-form-item-icon"

                        name="email"
                        style={{ width: "100%", height: "40px" }}
                        value={member.email}
                        onChange={(e) =>
                          setMembers({ ...member, email: e.target.value })
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-12 site-form-item-icon">
                    <Form.Item >
                      <Button

                        type="submit" htmlType="submit"
                        className="mainbtn text-white login-form-button d-flex align-items-center justify-content-center" >
                        Submit
                      </Button>

                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <section className="surveytemplets">
      {/* Categories Small sc offcanvas */}
      <div class="offcanvas offcanvas-start" tabindex="-1" id="sidecnvsda" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header position-absolute w-100">
          <button type="button" class="btn-close position-absolute" data-bs-dismiss="offcanvas" aria-label="Close" style={{bottom:"-17px",right:"14px"}}></button>
        </div>
        <div class="offcanvas-body offMantance">
        <nav id="sidebarl" className="mystiHeader">
                  <div className="position-relative  d-flex flex-column justify-content-between">
                    <div className="f-14 py-3">
                      <h5 className="text-orange mb-0">Account</h5>

                    </div>
                    <div className="intconq">
                      <button type="button" id="sidebarCollapsel" className="btn border-0 text-blue searchNima">
                        <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="white" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                        </svg>
                      </button>
                      <div className="questions-tab py-3 active" style={{ borderBottom: '1px solid rgba(175, 175, 175, 0.314)' }}>
                        <div className="question-count d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <p className="f-12 m-0 px-2">Members</p>
                          </div>
                        </div>
                      </div>
                      <div className="questions-tab py-3 " style={{ borderBottom: '1px solid rgba(175, 175, 175, 0.314)' }}>
                        <div className="question-count d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <p className="f-12 m-0 px-2">Communications</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
        </div>
      </div>
      {/* Categories Small sc offcanvas */}
        <Header2></Header2>
        <div className="surveys-bar bg-white">
          <div className="tab-content" id="myTabContent">
            <div className="layout-grid-content justify-content-center">
              <div className="wrapper p-0">
                <nav id="sidebarl" className="mystiHeader thisHideWay">
                  <div className="position-relative  d-flex flex-column justify-content-between">
                    <div className="f-14 py-3">
                      <h5 className="text-orange mb-0">Account</h5>

                    </div>
                    <div className="intconq">
                      <button type="button" id="sidebarCollapsel" className="btn border-0 text-blue searchNima">
                        <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="white" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                        </svg>
                      </button>
                      <div className="questions-tab py-3 active" style={{ borderBottom: '1px solid rgba(175, 175, 175, 0.314)' }}>
                        <div className="question-count d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <p className="f-12 m-0 px-2">Members</p>
                          </div>
                        </div>
                      </div>
                      <div className="questions-tab py-3 " style={{ borderBottom: '1px solid rgba(175, 175, 175, 0.314)' }}>
                        <div className="question-count d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <p className="f-12 m-0 px-2">Communications</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
                <div id="content" className="scratsurmid mb-0">
                  <div className="tab-content tabuisa d-flex align-items-center justify-content-between" id="aligned-survey">
                    <div>
                      <h5 className="text-blue fw-bold">User Management</h5>
                      <p className="f-12">Invite people and assign organization roles</p>
                    </div>
                    <div className="d-flex gap-2">
                    <button
                      className="mainbtn actYuas sideMiha"
                      type="button"
                      data-bs-toggle="offcanvas" data-bs-target="#sidecnvsda" aria-controls="offcanvasExample"
                      style={{lineHeight:"1",padding: "6px 14px"}}
                    >
                    <i class="bi bi-list" style={{fontSize:"26px"}}></i>
                    </button>
                    <button type="button" className=" mainbtnblue mainheadbtun d-flex mainbtn"
                      data-bs-toggle="modal" data-bs-target="#demoThisVart">Add New Member</button>
                      </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="text-bold">Organization Members <span>({client.length})</span></h5>
                    <div className="search-container position-relative my-2">
                      <button type="button" id="sidebarCollapse" className="btn border-0 text-blue searchNima">
                        <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="white" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                        </svg>
                      </button>
                      <form className="bg-lgrey d-flex align-items-center">
                        <button type="submit" className="border-0">
                          <img  src="../images/Icon feather-search.svg" alt="" className="m-2 w-50" />
                        </button>
                        <input type="text" placeholder="Search" name="search" className="bg-lgrey form-control"  onChange={(e)=>setSearch(e.target.value)}/>
                      </form>
                    </div>
                  </div>
                  <div className="table-responsive">
                  <table className="tblMAx" style={{ border: "0" }}>
                    <thead>
                      <tr>
                        <th className="text-orange" rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>No.</th>
                        <th className="text-orange" rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>Name<i class="bi bi-chevron-expand"></i></th>
                        <th className="text-orange" rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>Role</th>
                        <th className="text-orange" rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>Email</th>
                        <th className="text-orange" rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>Status</th>
                        <th className="text-orange" rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>Edit</th>
                        <th className="text-orange" rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {client.filter((item)=>{
                        return search.toLowerCase()==''?item:item.name.includes(search)
                      }).map((item, index) => (<tr>
                        <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>{index + 1}</td>
                        <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>{item.name}</td>
                        <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>
                          <span>{getRoles(item.user_role)}</span>
                        </td>
                        <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>{item.email}</td>
                        <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}><span className={item.is_deleted==1?"text-danger":"text-success"}>{item.is_deleted==1?"InActive":"Active"}</span></td>
                        
                        <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}><a><i class="fa text-orange fa-pencil-square-o" onClick={(e) => editMember(item, index)} data-bs-toggle="modal" data-bs-target="#demoThisVart" aria-hidden="true"></i></a></td>
                        <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}><a><i class="bi text-danger bi-trash3-fill" onClick={() => deleteMember(item, index)}></i></a></td>
                      </tr>))}
                     {/* {client && client.length > 0 && client.map((item, index) => (<tr>
                      //   <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>{index + 1}</td>
                      //   <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>{item.name}</td>
                      //   <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>
                      //     <span>{getRoles(item.user_role)}</span>
                      //   </td>
                      //   <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}>{item.email}</td>
                      //   <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}><span className={item.is_deleted==1?"text-danger":"text-success"}>{item.is_deleted==1?"InActive":"Active"}</span></td>
                        
                      //   <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}><a><i class="fa text-orange fa-pencil-square-o" onClick={(e) => editMember(item, index)} data-bs-toggle="modal" data-bs-target="#demoThisVart" aria-hidden="true"></i></a></td>
                      //   <td rowspan="1" colspan="1" style={{ borderLeft: "0", borderRight: "0" }}><a><i class="bi text-danger bi-trash3-fill" onClick={() => deleteMember(item, index)}></i></a></td>
                       </tr>))} */}

                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};
export default TemplateQuestion;
