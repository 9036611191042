import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import RewardUseModal from '../common/rewardUserModal'
import { Redirect, Link, useHistory } from "react-router-dom";
import Loader2 from '../../utils/loader2/loader2'
import PendingRedeemed from './pendingRedeemed';
import NotificationEmail from './notificationEmail';

const RewardPanelAdminDashboard = (props) => {
    const api = new API()
    const history = useHistory()

    const [rewardUser, setRewardUSer] = useState([])
    const [userRedeemHistory, setUserRedeemHistory] = useState([])
    const [userName, setUserName] = useState()
    const [loader_2, setLoader_2] = useState(true)
    const [emailForRedeemRequest, setEmailForRedeemRequest] = useState()
    const [user, setUser] = useState()
    const [type, setType] = useState()
    const [loader2, setLoader2] = useState(true)
    const [isPendingRedeemed, setIsPendingRedeemed] = useState(false)
    const [isEmailNotificationSection, setIsEmailNotificationSection] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [Pagination, setPagination] = useState()
    const [pageSize, setPageSize] = useState()
    const [index1, setIndex1] = useState()

    let adminToken = getLocalDataAsObject("adminToken")
    if (!adminToken) {
        history.push("/dashboard")
    }

    useEffect(() => {
        getAllRewardUser()
    }, [])



    const getAllRewardUser = async (count) => {
        let data = {
            pageNumber: count,
            pageSize: 9
        }
        const result = await api.post(config.adminDashboardRedeemPanelUser, data)
        if (result.code === 200) {
            setLoader2(false)

            setRewardUSer(result.data)

        }
    }
    const getUserAllRedeem = async (user, type) => {

        setType(type)
        setUserRedeemHistory("")
        setLoader_2(true)
        setUser(user)
        let UserName = user.firstName + ' ' + user.lastName
        let data = {
            email: user.email,
            type: type
        }

        const result = await api.post(config.adminDashboardRedeemPointHistory, data)
        if (result && result.status == "success") {

            let finalResult = result.data[0].redeemPoint
            setUserRedeemHistory(finalResult)
            setUserName(UserName)
            setEmailForRedeemRequest(user.email)
            setLoader_2(false)
        }
    }
    const showAllUserSection = () => {
        // alert("show pending redeemed")
        setIsEmailNotificationSection(false)
        setIsPendingRedeemed(false)

    }
    const showPendingRedeemed = () => {
        // alert("show pending redeemed")
        setIsEmailNotificationSection(false)
        setIsPendingRedeemed(true)
    }
    const addNotificationEmail = () => {
        alert("notification")
        setIsEmailNotificationSection(true)
    }

    const sendPageCount = (value) => {
        if (value === -1) {
            setPageCount(pageCount + 1)
        }
        if (value === 1) {
            setPageCount(pageCount - 1)
        }
        updateIndex();
    }

    const updateIndex = (value, pageCount) => {
        if (pageCount > 0) {
            if (value === -1) {
                setIndex1(pageSize * (pageCount - 1))
            }
            if (value === 1) {
                setIndex1(pageSize * (pageCount + 1))
            }
        }
    }

    return (
        <div>
            {/* <Header {...props} /> */}
            {/* <Tabs {...props}/> */}
            {/* <Footer {...props} /> */}
            <div className="row">


                <div className="col-md-10">
                    <div className="container mt-5">
                        <h1 align="center">Reward admin panel dashboard</h1>

                        <div className="container">
                            <span> <button onClick={showAllUserSection} style={{ backgroundColor: "gray", margin: "5px", color: "white" }} className="btn w3-button mt-4 ml-5">Show All User Redeemed </button></span>
                            <span> <button onClick={showPendingRedeemed} style={{ backgroundColor: "gray", margin: "5px", color: "white" }} className="btn w3-button mt-4 ml-5">Show Pending Redeemed </button></span>
                            <span> <button onClick={addNotificationEmail} style={{ backgroundColor: "gray", margin: "5px", color: "white" }} className="btn w3-button mt-4 ml-5">Add email for notification </button></span>
                        </div>

                        {isEmailNotificationSection == false ?
                            <div>
                                {isPendingRedeemed == false ?
                                    <div className="container mt-4 ml-5">
                                        {loader2 == true ?
                                            <div className="container" style={{ marginTop: "10%" }}>
                                                <Loader2 />
                                            </div>
                                            : <>
                                                {rewardUser.map((rewarduser, index) => (
                                                    <div className="card mb-5" >
                                                        <div className="card-header" >
                                                            <h6 className="text-black" >{index1 + index + 1} User Name : {rewarduser.firstName}  {rewarduser.lastName}</h6>
                                                            <div className="card-body" style={{ borderTop: "1px solid" }}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <table class="table text-center text-black" >
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th scope="row">Email</th>
                                                                                    <td>{rewarduser.email}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Dob</th>
                                                                                    <td>{rewarduser.dob}</td>

                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Mobile</th>
                                                                                    <td>{rewarduser.mobile}</td>

                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="row">Gender</th>
                                                                                    <td>{rewarduser.gender}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="col-md-6 item-center">

                                                                        <center><button onClick={() => getUserAllRedeem(rewarduser, "all")} data-toggle="modal" data-target="#rewardUserModal" className="btn btn-link btn-block col-md-6 text-black">Show All Redeem</button></center>
                                                                        <center><button onClick={() => getUserAllRedeem(rewarduser, "pendingRedeem")} data-toggle="modal" data-target="#rewardUserModal" className="btn btn-link btn-block col-md-6 mt-3 text-black">Approve One by One Pending</button></center>
                                                                        {/* <center><button onClick={() => getUserAllRedeem(rewarduser)} className="btn btn-link btn-block col-md-6 mt-3 text-white">Show All Redeem Rewards</button></center> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="card-footer">
                               
                            </div> */}

                                                    </div>
                                                ))}
                                            </>}
                                    </div>
                                    :
                                    <div className="container mt-4">
                                        <PendingRedeemed />
                                    </div>
                                }

                            </div>
                            :
                            <NotificationEmail />
                        }
                    </div>
                    <div className='d-flex justify-content-evenly'>
                        <div><button className='btn btn-secondary' onClick={() => sendPageCount(-1)} ><i className="fa fa-arrow-left"></i></button></div>
                        <div><button className='btn btn-secondary' onClick={() => sendPageCount(1)} ><i className="fa fa-arrow-right"></i></button></div>
                    </div>
                </div>
            </div>
            <RewardUseModal userRedeemHistory={userRedeemHistory}
                userName={userName} loader_2={loader_2} emailForRedeemRequest={emailForRedeemRequest}
                getUserAllRedeem={getUserAllRedeem} user={user} type={type} />
        </div>
    );
}

export default RewardPanelAdminDashboard;