import { useEffect, useState } from "react";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify';
const ShareSurvey = (props) => {
    let access = props.access
    console.log(props)
    let msgbody = `Hi there! `
    let msgbody1 = `We would love to hear your feedback. Please take a moment to complete our survey by clicking on the following link`
    let surveylink = (props.id) ? `https://doyoursurvey.com/#/attemp-survey/` + props.id : "https://doyoursurvey.com/#/signup"
    let emailbody = `Dear,%0DWe hope this email finds you well. As a valued member of our community, we would greatly appreciate your feedback. We are conducting a survey to better understand the needs and preferences of our customers, and we would be grateful if you could take a few moments to complete it.%0DYour input will help us to improve our services and provide you with the best possible experience. To participate in the survey, please click on the following link. Thank you for your time and participation. We look forward to hearing your thoughts and feedback.` + `https://doyoursurvey.com/#/attemp-survey/${props.id}`
    let emailLink = `https://mail.google.com/mail/?view=cm&fs=1&body=${(emailbody)}`
    useEffect(() => {
        console.log(props.survey?.surveyFor == "NPS")
        props.survey?.surveyFor == "NPS" ? setSurveyFor("nps") : setSurveyFor("researchSurvey")
        setReferCode(`https://doyoursurvey.com/#/newSurvey/${((props.id === undefined) ? props.survey?._id : props.id)}`)
    }, [])
    const [referCode, setReferCode] = useState("")
    const [copyStatus, setCopyStatus] = useState(false)
    const [surveyFor, setSurveyFor] = useState('')
    const [title1, setTitle1] = useState(`Hi there! We would love to hear your feedback. Please take a moment to complete our survey by clicking on the following link.`)
    const [title2, setTitle2] = useState(" Thank you in advance for your participation! ")
    const [whatsAppTitle2, setWhatsAppTitle2] = useState(" with your friends and earn points on registration with the following link ")
    const share = (e, mode) => {
        if (access[0].share_Survey == 1) {
            e.preventDefault()
            toast.warn(`${mode} Facility is not available in this package , Kindly upgrade your package`, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        if(mode=="sms"){
            
        }
    }
    return (
        <div >
            <ToastContainer />
            <div className='container-fluid sectionlas'>
                {(props.survey?.audience === "Buy Audience") && <div className='row text-center sur-people  justify-content-center com-bsl py-4 arownew bg-white m-3'>
                    <div className='titll-heads my-4 d-flex flex-wrap justify-content-between col-md-12'>
                        {/* <div className='titll-headsss pb-3 pb-md-0'>
                            <p class="fs-5 fw-semibold text-orange text-left mb-2">All in One Payment Mechanism<span>  <a href='#'>Edit</a></span>
                            </p>
                            <div className='token-userr'>
                                <span>SURVEY NUMBER</span>3124219
                            </div>
                        </div> */}
                        <div class="px-3 table_class_for_button">
                            <table>
                                {/* <tr className='pt-2'>
                            <th className='p-0'><a href="#" className='mainbtn'><i class="fs-3 bi bi-play-fill"></i><span>Play</span></a></th>
                            <th className='p-0'><a href="#" className='mainbtn'><i class="fs-3 bi bi-pause-fill"></i><span>Pause</span></a></th>
                            <th className='p-0'><a href="#" className='mainbtn'><i class="fs-3 bi bi-stop-fill"></i><span>Stop</span></a></th>
                        </tr> */}
                                <div class="pps d-flex border border-secondary-subtle text-secondary">
                                    <div class="m-3 play_button"> <i class="fs-3 bi bi-play-fill"></i></div>
                                    <div class="m-3  pause_button"> <i class="fs-3 bi bi-pause-fill"></i></div>
                                    <div class="m-3  stay_button"> <i class="fs-3 bi bi-stop-fill"></i></div>
                                </div>
                            </table>
                        </div>
                    </div>
                    <div className='col-md-12 py-5'>
                        <div className=''>
                            <div class="servey-actt">
                                <p class="fw-semibold text-left text-orange mb-2 form-tilld">Expected Metrics &amp; Data</p>
                                <p class="text-start mb-4">Fill in to inform suppliers of your survey before receiving traffic. Riview in-field data when survey is live!</p>
                            </div>
                            <label className='text-start'>Expected Completes</label>
                            <input type="number" maxlength="11" size="50%" class="form-control" value={(props.survey?.response_Limit) ? props.survey.response_Limit : ""} className='form-control' id="formGroupExampleInput" placeholder="Number Type" autoComplete='off' />
                            <label className='text-start'>Expected Incidence Rate</label>
                            <input type="number" maxlength="11" size="50%" class="form-control" value={(props.survey?.Incidence) ? props.survey.Incidence : ""} className='form-control' id="formGroupExampleInput" placeholder="Number Type" autoComplete='off' />
                            <label className='text-start'>Expected Completion LOI</label>
                            <input type="number" maxlength="11" size="50%" class="form-control" value={""} className='form-control' id="formGroupExampleInput" placeholder="Number Type" autoComplete='off' />
                            <div class="text-left"><button class="mainbtn mr-3 mb-2">Edit</button><button class="mb-2 mainbtn">Save</button></div>
                        </div>
                    </div>
                </div>}
                {(props.survey?.audience === "Buy Audience") && <div className='row text-center sur-people  justify-content-center com-bsl py-4 arownew bg-white m-3'>
                    <div className='col-md-12 py-5'>
                        <div className=''>
                            <div class="servey-actt">
                                <p class="fw-semibold text-left text-orange mb-2 form-tilld">Change Log</p>
                                <p class="text-start mb-4">Review change to your survey configuration. See who made changes and when</p>
                            </div>
                            <div className='surv-items'>
                                <div className='main-maild pb-4 border-bottom'>
                                    <div className='info-rwe pb-2 text-start'>
                                        <span className='mail-logs'>admin@info.com</span><span className='dot-logs'>•</span><date>Fabruary 12, 2023</date><span>@</span><time>7:00:15PM</time>
                                    </div>
                                    <div className='surven-clse text-start pb-2'>Survey</div>
                                    <div className='statusMenu d-flex py-3 justify-content-around'><span className='text-bold'>Status</span><del className='redColoras'>Live</del><span className='greenColoras'>Complete</span></div>
                                </div>
                                <div className='main-maild py-4 border-bottom'>
                                    <div className='info-rwe pb-2 text-start'>
                                        <span className='mail-logs'>admin@info.com</span><span className='dot-logs'>•</span><date>Fabruary 12, 2023</date><span>@</span><time>7:00:15PM</time>
                                    </div>
                                    <div className='surven-clse text-start pb-2'>Survey</div>
                                    <div className='statusMenu d-flex py-3 justify-content-around'><span className='text-bold'>Status</span><del className='redColoras'>Live</del><span className='greenColoras'>Complete</span></div>
                                </div>
                                <div className='main-maild py-4'>
                                    <div className='info-rwe pb-2 text-start'>
                                        <span className='mail-logs'>admin@info.com</span><span className='dot-logs'>•</span><date>Fabruary 12, 2023</date><span>@</span><time>7:00:15PM</time>
                                    </div>
                                    <div className='surven-clse text-start pb-2'>Survey</div>
                                    <div className='statusMenu d-flex py-3 justify-content-around'><span className='text-bold'>Status</span><del className='redColoras'>Awarded</del><span className='greenColoras'>Live</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className='row text-center sur-people  justify-content-center com-bsl bg-white m-3'>
                    <p className='fs-5 fw-semibold text-orange pt-5'>How would you like to survey people?</p>
                    <div className='col-md-7'>
                        <div className="input-group py-3">
                            <input type="text" className="form-control p-4 fw-500 text-lgrey bg-lgrey rounded-start-1 " placeholder={referCode} aria-label="Recipient's username" aria-describedby="button-addon2" />
                            <CopyToClipboard text={referCode}
                                onCopy={() => setCopyStatus(true)}>
                                {copyStatus !== true ?
                                    <button className="btn px-4  " type="button" id="button-addon2">Copy URL</button>
                                    :
                                    <button className="btn px-4  " type="button" id="button-addon2">URL Copied</button>
                                }
                            </CopyToClipboard>
                        </div>
                        {/* <p className='text-lgrey text-start'>Looking for the full URL? <a href=''><u className='text-blue'>Click here</u></a> to copy</p> */}
                    </div>
                </div>
                <div className='row text-center create-share justify-content-center com-bsl bg-white m-3'>
                    <div className='col-md-10'>
                        <div className='rowbtnshare mt-3 d-flex justify-content-between align-items-center'>
                            <p className='fs-5 fw-semibold text-orange text-start pt-3'>Share your personalized survey URL</p>
                            <div>
                                {/* <p className='f-14 fw-500 m-0 '>Collect data from a wide variety of people across the globe&nbsp; &nbsp;<span><button className='mainbtn f-14'>Buy Target Audience</button></span></p> */}
                            </div>
                        </div>
                        {(surveyFor === 'researchSurvey') ? <div className='row py-4 justify-content-center'>
                            <div className='col-lg-3 col-md-6'>
                                {<a href={emailLink} target="_blank" className='border-0 m-share-card com-bs ' onClick={(e) => share(e, 'Email')}>
                                    <img className='' src='../images/Icon zocial-email.svg'></img>
                                    <p className='f-7 m-0'>Send Email</p>
                                </a>}
                            </div>
                            {/* <div className='col-lg col-4'>
                            <a href='' className='border-0 m-share-card com-bs '>
                            <img className='' src='../images/Icon metro-qrcode.svg'></img>
                            <p className='f-12 m-0'>QR Code</p>
                            </a>
                            </div> */}
                            <div className=' col-lg-3 col-md-6'>
                                {/* <a href='' className='border-0 m-share-card com-bs '>
                             <img className='' src='../images/Icon simple-whatsapp.svg'></img>
                                <p className='f-7 m-0'>whatsapp</p>
                                 </a> */}
                                <WhatsappShareButton className='border-0 m-share-card com-bs mr-4'
                                    title={title1 + title2}
                                    url={`https://doyoursurvey.com/#/newSurvey/` + props.id}
                                    separator=":" >
                                    <img className='' src='../images/Icon simple-whatsapp.svg'></img>
                                    <p className='f-7 m-0'>whatsapp</p>
                                </WhatsappShareButton>
                            </div>
                            <div className='col-lg-3 col-md-6'>
                                <LinkedinShareButton className='border-0 m-share-card com-bs '
                                    title={title1 + title2}
                                    url={"https://doyoursurvey.com/#/newSurvey/" + props.id}
                                    windowWidth={750}
                                    windowHeight={600}
                                >
                                    <img className='' src='../images/Icon awesome-linkedin.svg'></img>
                                    <p className='f-7 m-0'>Linkedin</p>
                                </LinkedinShareButton>
                            </div>
                            <div className=' col-lg-3 col-md-6'>
                                <a className='border-0 m-share-card com-bs position-relative'>
                                    <img className='' src='../images/Icon awesome-share.svg'></img>
                                    <div className="dropdown social-share position-static">
                                        <a className=" dropdown-toggle d-flex align-items-center position-static" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <p className='f-7 m-0'>Others</p>
                                        </a>
                                        <ul className="dropdown-menu f-12 ">
                                            <li><FacebookShareButton
                                                quote={title1 + title2}
                                                url={"https://doyoursurvey.com/#/newSurvey/" + props.id} >
                                                <a className="dropdown-item p-1"> Facebook</a>
                                            </FacebookShareButton></li>
                                            {/* <li><a className="dropdown-item p-1" href="#">Instagram</a></li> */}
                                            <li><TwitterShareButton
                                                title={title1 + title2}
                                                url={"https://doyoursurvey.com/#/newSurvey/" + props.id}
                                                summary={title2}>
                                                <a className="dropdown-item p-1" href="">Twitter</a>
                                            </TwitterShareButton></li>
                                        </ul>
                                    </div>
                                </a>
                            </div>
                        </div> :
                            <div className='row py-4 justify-content-center'>
                                <div className='col-lg-3 col-md-6'>
                                    {<a target="_blank" className='border-0 m-share-card com-bs ' onClick={(e) => share(e, 'sms')}>
                                        <img className='' src='../images/Icon zocial-email.svg'></img>
                                        <p className='f-7 m-0'>Send Sms</p>
                                    </a>}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ShareSurvey;