import AnalysisCart from '../../Components/ClientHome/AnalysisCart';
import BlogCart from '../../Components/ClientHome/BlogCart'
import ClientServicesCart from '../../Components/ClientHome/ClientServicesCart';
import Footer from '../../Components/ReusableComponents/Footer';
import GetInTouch from '../../Components/ReusableComponents/GetInTouch'
import Header from '../../Components/ReusableComponents/Header';
import { useEffect, useState } from 'react';
import React from 'react';
import ClientInTouchModel from '../../Components/ClientHome/GetInTouchModel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactAudioPlayer from 'react-audio-player';
import { Link } from 'react-router-dom'


// import { Link } from 'react-router-dom';


function ClientHome() {
     const [modalShow, setModalShow] = React.useState(false);
     const [tab, setTab] = useState(0)
     const [showAudio, setShowAudio] = useState(false)
     const [showRecording, setShowRecording] = useState(false)
     var settings = {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000
     };

     useEffect(() => {

          window.scroll(0, 0)
          localStorage.setItem("userType", `client`)


     }, [])

     return (
          <>
               {/* <Header /> */}
               <section>
                    <div className="topBar nevigtsao w-100 p-2 bg-blue" style={{ minHeight: "40px" }}>
                         <div className="container-fluid">
                              <div className="row" style={{ position: "relative" }}>
                                   <div className="col-md-8 col-lg-12 mx-auto d-flex justify-content-between">

                                        <div className='topTextSlide'>
                                             <span className='text-heading-header text-white text-center' style={{ fontWeight: "500" }}>Streamlining the Market Research Process..</span>

                                             {/* <Slider {...{
                                                dots: false,
                                                autoplay: true,
                                                infinite: true,
                                                speed: 500,
                                                autoplaySpeed: 3000,
                                                arrows: false,
                                                slidesToShow: 1,
                                                slidesToScroll: 1,
                                                vertical: true,
                                                verticalSwiping: true,
                                                swipeToSlide: true
                                            }}
                                                className="topTextSlide">
                                                <div className="">
                                                    <p className="text-white text-center mb-0">All that Construction needs</p>
                                                </div>
                                                <div className="">
                                                    <p className="text-white text-center mb-0">All that Construction needs2</p>
                                                </div>
                                                <div className="">
                                                    <p className="text-white text-center mb-0">All that Construction needs3</p>
                                                </div>

                                            </Slider> */}

                                        </div>

                                        <ul className="head-email d-flex mb-0 justify-content-end detailTopView">
                                             <li><a href="https://mail.google.com/mail/u/0/?fs=1&to=dysinfo@qdegrees.com&tf=cm" target="break">
                                                  <i className="fa fa-envelope text-white" aria-hidden="true"></i>&nbsp;&nbsp;<span className='text-white' style={{ fontWeight: "500" }}>dysinfo@qdegrees.com</span>
                                             </a></li>

                                             <li> <Link href="/get-app">
                                                  <a href="#" className='' style={{ fontWeight: "500" }}>
                                                       Get App
                                                  </a>
                                             </Link></li>
                                             <li><a href="https://play.google.com/store/apps/details?id=com.qdegrees.doyoursurvey" target="_blank">
                                                  {/* <img src='../images/playstore.png' width={"20px"} aria-hidden="true"></img> */}
                                                  <i class="bi bi-google-play"></i>
                                             </a></li>
                                             <li> <Link href="" target="_blank">
                                                  <a href="https://apps.apple.com/us/app/doyoursurvey/id1662091417" target="_blank" >
                                                       <i class="bi bi-apple" aria-hidden="true"></i>

                                                  </a>
                                             </Link></li>
                                        </ul>
                                   </div>
                              </div>
                         </div>
                    </div>
                    {/* <div className="topBar w-100 ">
                         <div className="container">
                              <div className="row" style={{ position: 'relative', padding:"6px" }}>
                                   <div className="col-md-4 col-lg-6 mx-auto ">
                                   <p className="text-white text-center mb-0 fs-6">All that Construction needs3</p>
                                   </div>
                                   <div className='col-lg-4 mx-auto'>
                                   <ul className="d-flex mb-0 justify-content-end detailTopView">
                                        <li><a href="#"><i className="fa fa-phone" aria-hidden="true" /> +91 7878159468</a></li>
                                        <li><a href="#"><i className="fa fa-envelope" aria-hidden="true" /></a></li>
                                        <li> <a href="#">Get App</a></li>
                                    </ul>
                                   </div>
                              </div>
                         </div>
                    </div> */}
                    {/* <div data-index={0} className="slick-slide" tabIndex={-1} aria-hidden="true" style={{ outline: 'none', width: '546px' }}><div>
                    <div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs</p></div>
                    </div></div><div data-index={1} className="slick-slide" tabIndex={-1} aria-hidden="true" style={{ outline: 'none', width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs2</p></div></div></div><div data-index={2} className="slick-slide slick-active slick-current" tabIndex={-1} aria-hidden="false" style={{ outline: 'none', width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs3</p></div></div></div><div data-index={3} tabIndex={-1} className="slick-slide slick-cloned" aria-hidden="true" style={{ width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs</p></div></div></div><div data-index={4} tabIndex={-1} className="slick-slide slick-cloned" aria-hidden="true" style={{ width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs2</p></div></div></div><div data-index={5} tabIndex={-1} className="slick-slide slick-cloned" aria-hidden="true" style={{ width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs3</p></div></div></div></div><ul className="d-flex mb-0 justify-content-end detailTopView"><li><a href="#"><i className="fa fa-phone" aria-hidden="true" /> +91 7878159468</a></li><li><a href="#"><i className="fa fa-envelope" aria-hidden="true" /></a></li><li> <a href="#">Get App</a></li></ul> */}
               </section>
               <section id="all-section nav-section" className='myheaderLogo position-relative' style={{ background: "none" }}>

                    <nav className="navbar menuglta navbar-expand-lg py-2 w-100 fadeInDown">
                         <div className="container-fluid">
                              <a className="navbar-brand" href="/">
                                   <img src="../images/new-logo.png" width={"25"} alt="" ></img>
                              </a>
                              {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                   <span className="navbar-toggler-icon" />
                              </button> */}
                              <div className="" id="navbarSupportedContent">
                                   <form className="ms-auto" role="search">
                                        <Link to="/demo">
                                             {/* <button className="mainbtn mx-3 fadeInUp" href="#" target="_self">
                                                  Book a Demo
                                             </button> */}
                                        </Link>
                                        <Link to="/login"> <button className="mainbtn fadeInUp px-0" href="#" target="_self">
                                             Sign In
                                        </button>
                                        </Link>
                                   </form>
                              </div>
                         </div>
                    </nav>
               </section>

               <section id="all-sections" className="bg-grey fullheight-client bannersec-client measheader">
                    <div className="container text-center">
                         <div className="row">
                              <div className='col-md-6 mt-5'>
                                   <div className='mt-5'>
                                        {
                                             tab == 0 &&
                                             <div className='box-images'>
                                                  <div className='' style={{ marginTop: "20px", padding: "20px" }}>
                                                       <img className='images-box' src='../images/english/english.png' width="550px"></img>
                                                  </div>
                                             </div>
                                        }
                                        {tab == 1 &&
                                             <div className='box-images'>
                                                  <div className='' style={{ marginTop: "20px", padding: "20px" }}>
                                                       <img className='images-box' src='../images/Hindi/Hindi.png' width="550px"></img>
                                                  </div>
                                             </div>

                                        }
                                        {
                                             tab == 2 &&
                                             <div className='box-images'>
                                                  <div className='' style={{ marginTop: "20px", padding: "20px" }}>
                                                       <img className='images-box' src='../images/mrathi/Group 24481.png' width="550px"></img>
                                                  </div>
                                             </div>
                                        }
                                        {tab == 3 &&
                                             <div className='box-images'>
                                                  <div className='' style={{ marginTop: "20px", padding: "20px" }}>
                                                       <img className='images-box' src='../images/tamil/Group 24482.png' width="550px"></img>
                                                  </div>
                                             </div>
                                        }

                                   </div>
                              </div>

                              <div className="col-md-6">
                                   <div className='box-nav p-3' style={{ marginTop: "100px" }}>
                                        <Slider {...settings}>
                                             <div className='col'>
                                                  <h5 className='text-left fw-bold py-2 p-1'>Digital to Conversational Surveys</h5>
                                                  <p className=' text-left boldnormal-p p-1' style={{ lineHeight: "25px", fontSize: "16px" }}>
                                                       Transform your traditional web forms into a delightful conversation
                                                  </p>
                                             </div>
                                             <div className='col'>
                                                  <h5 className='text-left fw-bold py-2 p-1'>Conduct Digital Survey for Rural to Global Reach</h5>
                                                  <p className=' text-left boldnormal-p p-1' style={{ lineHeight: "25px", fontSize: "16px" }}>
                                                       Get a deep dive insight from a rural to a global audience
                                                  </p>
                                             </div>
                                             <div className='col'>
                                                  <h5 className='text-left fw-bold py-2 p-1'> Connect to the audience in Vernacular Languages</h5>
                                                  <p className=' text-left boldnormal-p p-1' style={{ lineHeight: "25px", fontSize: "16px" }}>
                                                       Reach out to your audience to seek insights by communicating with them in their language.
                                                  </p>
                                             </div>
                                             <div className='col'>
                                                  <h5 className='text-left fw-bold py-2 p-1'>Seek inputs through Voice based Survey</h5>
                                                  <p className=' text-left boldnormal-p p-1' style={{ lineHeight: "25px", fontSize: "16px" }}>
                                                       Reach out to your audience to seek insights by communicating with them in their language.
                                                  </p>
                                             </div>
                                             <div className='col'>
                                                  <h5 className='text-left fw-bold py-2 p-1'>Conduct Personal Interviews across India </h5>
                                                  <p className=' text-left boldnormal-p p-1' style={{ lineHeight: "25px", fontSize: "16px" }}>
                                                       Communicate with B2B and B2C audiences in Pan India
                                                  </p>
                                             </div>
                                             <div className='col'>
                                                  <h5 className='text-left fw-bold py-2 p-1'>Telephonic Interviews across India & Abroad</h5>
                                                  <p className=' text-left boldnormal-p p-1' style={{ lineHeight: "25px", fontSize: "16px" }}>
                                                       Seek in-depth insights by connecting with the audience in their language through telephonic discussions
                                                  </p>
                                             </div>
                                             <div className='col'>
                                                  <h5 className='text-left fw-bold py-2 p-1'>44 Million+ Global Panel</h5>
                                                  <p className=' text-left boldnormal-p p-1' style={{ lineHeight: "25px", fontSize: "16px" }}>
                                                       To meet the needs of our clients, we constantly build, fortify, and maintain our panels. We are pleased with our double opt-in, quality-assured proprietary panels all over the world.
                                                  </p>
                                             </div>
                                        </Slider>

                                        <div className='growth'>
                                             <div className='box-card-1 py-3'>
                                                  <button type='bottom' className={tab == 0 ? "tab-button-1" : "tab-button-2"} onClick={() => setTab(0)}>English</button>
                                                  <button type='bottom' className={tab == 1 ? "tab-button-1" : "tab-button-2"} onClick={() => setTab(1)}>हिन्दी</button>
                                                  {/* <ul className="list-inline">
                                             <li className={tab == 0 ? "text-color" : ""}><a onClick={() => setTab(0)}>English</a></li>
                                             <li className={tab == 1 ? "text-color" : ""}><a onClick={() => setTab(1)}>हिन्दी</a></li>
                                             
                                        </ul> */}
                                             </div>
                                             <div className='box-card-1 py-3'>
                                                  <button type='bottom' className={tab == 2 ? "tab-button-1" : "tab-button-2"} onClick={() => setTab(2)}>मराठी</button>
                                                  <button type='bottom' className={tab == 3 ? "tab-button-1" : "tab-button-2"} onClick={() => setTab(3)}>తెలుగు</button>
                                                  {/* <ul className="list-inline">
                                             <li className={tab == 0 ? "text-color" : ""}><a onClick={() => setTab(0)}>English</a></li>
                                             <li className={tab == 1 ? "text-color" : ""}><a onClick={() => setTab(1)}>हिन्दी</a></li>
                                        </ul> */}
                                             </div>
                                        </div>
                                        <div className='box-card-1 justify-content-center align-items-center'>
                                             {/* <ul className="list-inline">
                                           <li className={tab == 2 ? "text-color" : ""}><a onClick={() => setTab(2)}>मराठी</a></li>
                                             <li className={tab == 3 ? "text-color" : ""}><a onClick={() => setTab(3)}>తెలుగు</a></li>
                                        </ul> */}
                                        </div>
                                        <ClientInTouchModel
                                             show={modalShow}
                                             onHide={() => setModalShow(false)}
                                        />
                                   </div>
                                   <div className='col-md-12 text-left mb-2 p-3' style={{ cursor: "pointer" }}>
                                        {/* play button */}
                                        {/* {showAudio === false && */}
                                        <i className='p-3'><img src='../images/audio/c4559ad4-1cf8-4416-9f97-386f93d3772c.png' width={"50"} onClick={() => setShowRecording('play')}></img></i>
                                        {/* } */}
                                        {/* {
                                             showRecording === "play" &&
                                             //   pause button 
                                             <i className='p-4'><img src='../images/audio/Group 24483.svg' width={"130px"} onClick={() => setShowRecording(!showAudio)}></img></i>

                                        } */}
                                        {
                                             (showRecording === "play") ?
                                                  (tab === 0) ? <ReactAudioPlayer autoPlay controls
                                                       src='../images/audio/WhatsApp Audio 2022-11-29 at 4.53.27 PM.mpeg' className='w-100'
                                                  /> :
                                                       (tab === 1) ? <ReactAudioPlayer autoPlay controls
                                                            src='../images/audio/Hindi first screen.mpeg' className='w-100'
                                                       /> :
                                                            (tab === 2) ? <ReactAudioPlayer autoPlay controls
                                                                 src='../images/audio/Marathi first screen.mpeg' className='w-100'
                                                            /> :
                                                                 (tab === 3) ? <ReactAudioPlayer autoPlay controls
                                                                      src='../images/audio/telgu first screen.mpeg' className='w-100'
                                                                 /> : null
                                                  : null
                                        }
                                        {/* stop button */}

                                        {showRecording === "mic" &&
                                             <i className='p-3'><img src='../images/audio/Group 24485.svg' width={"130px"} onClick={() => setShowRecording("undefined")}></img></i>
                                        }
                                        {/* mic */}
                                        <i className='p-1'><img src='../images/audio/457a4b01-36e3-462d-a3b2-2a84cfdf784b.png' onClick={() => setShowRecording("mic")} width={"50"}></img></i>
                                        {showRecording == "mic" && <><span>Leave Your Responce</span></>}
                                        {showRecording === "mic" &&
                                             <lottie-player src="../images/audio/c03bbe7d-010f-4ec2-89f5-c254ac5aae15 (2).json" background="transparent" speed={1} style={{ width: '200px', height: '200px' }} loop autoPlay />
                                        }
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <section id="all-section" className='fullheight-client-11'>
                    <div className="container">
                         <div className="row text-center justify-content-center mt-5">
                              <div className='col-sm-12'>
                                   <div className='border-0'>
                                        {/* <div className='img-box-slider mt-4'>
                                             <lottie-player className='img-survey' src="../images/lotti/c03bbe7d-010f-4ec2-89f5-c254ac5aae15.json"
                                                  style={{width:"195px",position: "absolute",  left: "75px",
                                                  bottom: "86px" }} background="transparent" speed="1" loop autoplay></lottie-player>
                                                  <lottie-player className='img-survey' src="../images/lotti/b11fb277-13ca-48f7-a649-88cefbd79765.json"
                                                  style={{width:"110px",position: "absolute",  left: "216px",
                                                  top:"84px" }} background="transparent" speed="1" loop autoplay>
                                             </lottie-player>  
                                             <img src='../images/Group 24446@2x.png' width={"400px;"}></img>
                                             <h6 className="text-center text-black fw-bold py-2">
                                             Convert Digital Surveys to Conversation Surveys</h6>
                                             </div> */}                                        <div className='text-center'>
                                             <button className="mainbtn tada me-3" href="#" target="_self">
                                                  <Link to="signup">Sign Up</Link>
                                             </button>
                                             <button className="mainbtn tada" href="#" target="_self">
                                                  <Link to="plan">Plan</Link>
                                             </button>
                                        </div>
                                   </div>
                              </div>
                              {/* <div className='col-sm-6'>
                                   <div className='card bg-grey border-0'>
                                        <div className='img-box-slider mt-4'>

                                             <img src='../images/reach.gif' width={"250px;"}></img>
                                             <h6 className="text-black fw-bold py-2">
                                                  Get Deeper Insights from Rural to Global Audience</h6>
                                        </div>
                                   </div>
                              </div> */}

                              {/* <Slider {...settings}>
                                   <div className='card-1'>
                                   <h4 className="text-center text-black lh-base">
                                   Convert Digital Surveys to Conversation Surveys
                                     <img src='../images/converstational survey.gif'></img>
                                   </h4>
                                   </div>

                                   <div className='card'>
                                   <h4 className="text-black lh-base">
                                   Conduct surveys in Vernacular languages
                                   <img src='../images/language.gif'></img>

                                   </h4>
                                   </div>
                                   <div className=''>
                                   <h4 className="text-white lh-base">
                                   Get Deeper Insights from Rural to Global Audience
                                   </h4>
                                   </div>
               
                                  </Slider> */}
                         </div>
                    </div>
               </section>
               <section id="all-section" className="clientpg">
                    <div className="container">
                         <ClientServicesCart
                              img="../images/Group 3275.svg"
                              heading="Quick responses for quick decisions"
                              description="You can't afford to be slow. In a world where trends change
                                             instantly,
                                             your decisions need to be instant too. Get quick responses from your target audience and
                                             uncover insights in real-time."
                              imgDirection="right"
                         />
                         <ClientServicesCart
                              img="../images/Group 4255.svg"
                              heading="DIY with a state-of-the-art dashboard"
                              description="We pride ourselves on the simplicity of our solution. Backed by powerful
                                             AI engines and an interactive dashboard, you can get your primary research started in no
                                             time with our DIY tool and SaaS-based model."
                              imgDirection="left"
                         />
                    </div>
               </section>
               <section id="all-section">
                    <div className="container">
                         <div className="row justify-content-center text-center py-5">
                              <h4 className="py-3 fw-bold mb-0">Different modes of research for a detailed analysis</h4>
                              <div className="col-md-5">
                                   <h5 className="lh-base boldcommon-p peragraphFont">We ensure your research is in-depth by offering four modes of
                                        research for
                                        your study.</h5>
                              </div>
                         </div>
                         <div className="row research-cards-row">
                              <AnalysisCart
                                   heading="1. Digital Surveys"
                                   description="Digital surveys are easy to conduct and provide immediate access
                          to
                          intelligence while remaining cost-effective. As more people get connected to
                          the internet, digital surveys provide unprecedented access to this online
                          population. It is the most popular form of research amongst our clients."
                              />
                              <AnalysisCart
                                   heading="2. Personal Interviews"
                                   description="Best suited for qualitative research,
                                   personal interviews give you the opportunity to gain deeper insights into your
                                   consumer base. This form of research comes in handy when detailed answers and
                                   in-depth insights are needed to power your study."
                              />
                         </div>
                         <div className="row research-cards-row">
                              <AnalysisCart
                                   heading="3. Telephonic Interviews"
                                   description="(Australia, Singapore, UAE, Saudi Arabia, Qatar, Oman, Kuwait,
                                        Bahrain) - Our telephonic interviews provide you with direct access to respondents
                                        around the globe. You can easily connect with your target audience in a multitude of
                                        countries to get the answers you need."
                              />
                              <AnalysisCart
                                   heading="4. Focus group discussions"
                                   description="With a skilled moderator, we bring in qualified panelists from your target market to help you unlock qualitative insights fast. You get to hear customer feedback in their own words and voices."
                              />
                         </div>
                    </div>
               </section>
               <section id="all-section" className="bg-pink bannersec my-5">
                    <div className="container-fluid">
                         <div className="row text-center justify-content-center py-5">
                              <h4 className="text-orange fw-bold fs-4">Consultancy with customized research</h4>
                              <div className="col-md-10">

                                   <p className="text-white fw-normal lh-base fs-5 p-2 m-0">

                                        One of our key offerings is our customized consultancy. Research studies require a level of
                                        expertise that is hard to build in-house due to the dearth of skilled talent and financial
                                        constraints. We support you fully with our experienced in-house team to help you conduct
                                        your research end-to-end - from setting down the goals and selecting panelists to curating
                                        answers, studying the numbers, and unearthing insights. We build customized research
                                        packages to service the specific goals and expectations you have from your study.
                                   </p>
                              </div>
                         </div>
                    </div>
               </section>
               <section id="all-section" className="clientpg">
                    <div className="container">
                         <ClientServicesCart
                              img="../images/Group 4718.svg"
                              heading="High-quality responses from global panelists"
                              description="When you're hinging crucial business decisions on collected
                          information, it needs to be as accurate as possible. We ensure, through adequate
                          incentivization, that all responses from our panelists are honest, clear, and real.
                          Moreover, you can zero in on your target respondents, location-wise, with unmatched
                          accuracy via our geo-targeting mechanism."
                              imgDirection="right"
                         />

                    </div>
               </section>
               <section id="all-section">
                    <div className="container">
                         <div className="row justify-content-center text-center py-5">
                              <h4 className="py-3 fw-bold mb-0">Direct access to real-time analytics</h4>
                              <div className="col-md-9">
                                   <h5 className="lh-base boldcommon-p peragraphFont">You can access your data in multiple visuals as soon as
                                        responses pour in.
                                        You can immediately download the analytics with easy integrations and start shaping them
                                        into the insights you're looking for.</h5>
                              </div>
                              <div className="col-md-6 py-3">
                                   <img src="../images/Group 6276.svg" alt="" className="w-100" />
                              </div>
                         </div>
                    </div>
               </section>
               <section id="all-section" className="clientpg">
                    <div className="container">
                         <ClientServicesCart
                              img="../images/Group 5291.svg"
                              heading="Multi-level quality check mechanism"
                              description="The quality of your research depends on two key factors - your
                              chosen panelists and their answers. With our three-level quality check standards, we
                              ensure that your research returns only the most accurate insights. First, we dip-check
                              each panelist so that only the eligible ones get through. Second, our robust AI engine
                              grades each answer on its quality and relevance. Third, our in-house QC team reviews
                              each answer, and only allows the most accurate ones to pass through."
                              imgDirection="right"
                         />
                    </div>
               </section>
               <section id="all-section" className="bg-pink bannersec my-5">
                    <div className="container-fluid">
                         <div className="row text-center justify-content-center py-5">
                              <div className="col-md-9">
                                   <p className=" text-white fw-normal lh-base fs-5 p-2 mb-0 py-4">
                                        Market research is more than just a numbers game. Armed with the right intelligence, you can
                                        reshape your brand, reimagine your customer experience, optimize your marketing campaigns,
                                        and build tailor-made products with the right market fit.
                                   </p>
                              </div>
                         </div>
                    </div>
               </section>
               <section id="all-section">
                    <div className="container">
                         <div className="insights-cards">
                              <div className="row py-5">
                                   <div className="col-lg-4">
                                        <div className="insights-col">
                                             <BlogCart
                                                  img="../images/Group 6279.svg"
                                                  heading="Branding Insights"
                                                  description="Learn why people love your brand. Understand what values and emotions
                                         resonate with them. Capture your brand's growth over time. Branding insights
                                         will help you build a brand that people love, respect, and remember."
                                             />
                                             <BlogCart
                                                  img="../images/noun-industry-2052326.svg"
                                                  heading="Benchmarking Insights"
                                                  description="Dig deep into how the industry in your vertical is growing. Uncover their
                                                  secrets and incorporate them into your own strategies to increase your own
                                                  market share."
                                             />
                                        </div>
                                   </div>
                                   <div className="col-lg-4 position-relative d-flex justify-content-center">
                                        <div className="insights-col bumpup position-absolute">
                                             <BlogCart
                                                  img="../images/Group 6280.svg"
                                                  heading="Advertising and Marketing Insights"
                                                  description="Dissect your target market into understandable slices and A/B test different
                                                  concepts, copies, and ideas." />
                                             <BlogCart img="../images/Group 6282.svg"
                                                  heading="Product Validation"
                                                  description="Learn what your audience wants and build products that meet their
                                                  expectations. Now that's a sure-fire way to have a successful product
                                                  launch."
                                             />
                                        </div>
                                   </div>
                                   <div className="col-lg-4">
                                        <div className="insights-col">
                                             <BlogCart
                                                  img="../images/Group 6281.svg"
                                                  heading="Market testing"
                                                  description="It’s a volatile market and the only way to guarantee success is to first
                                                  check the market for your product and services. With market testing, you can
                                                  uncover the why, what, where, who, when, and how of your target market to
                                                  accurately articulate your product design and marketing strategies."
                                             />
                                             <BlogCart
                                                  img="../images/Group 6283.svg"
                                                  heading="Consumer Insights"
                                                  description="Your consumers are talking. By initiating dialogue with them, you can uncover
                                                  insights to power your decisions. Armed with more knowledge about your
                                                  audience, you can deliver more personalized and memorable experiences to
                                                  them."
                                             />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <GetInTouch redirectionStatus={false} />
               <Footer />
               {/* ======audio modal===== */}
               <div class="modal fade" id="TelephonicModel" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                    <div class="modal-dialog modal-dialog-centered size bg-none">
                         <div class="modal-content">
                              {/* <div class="p-0 modal-header py-1">
            <button type="button" class="btn-close pt-2" aria-label="Close"></button></div>
            </div> */}
                              <div class="modal-body text-center" style={{ cursor: "pointer" }}>

                                   <div className='box-card-1 mb-5'>
                                        <ul className="list-inline">
                                             <li className={tab == 0 ? "text-color" : ""}><a onClick={() => setTab(0)}>English</a></li>
                                             <li className={tab == 1 ? "text-color" : ""}><a onClick={() => setTab(1)}>हिन्दी</a></li>
                                             <li className={tab == 2 ? "text-color" : ""}><a onClick={() => setTab(2)}>मराठी</a></li>
                                             <li className={tab == 3 ? "text-color" : ""}><a onClick={() => setTab(3)}>తెలుగు</a></li>
                                        </ul>
                                   </div>
                                   <div class="successimg">

                                        <img src="../images/audio/listen.svg" ></img>
                                   </div>

                                   {/* <ReactAudioPlayer autoPlay controls 
                src='../images/audio/sample-3s.mp3' className='w-100'
               
                /> */}
                                   {/* <ReactAudioPlayer autoPlay controls 
                src='../images/audio/WhatsApp Audio 2022-11-29 at 4.53.27 PM.mpeg' className='w-100'
               
                /> */}



                                   <div class="contents text-center">
                                        {/* Popup itself */}
                                        <div id="test-popup" className="white-popup mfp-hide">
                                             <audio src id="hidden-player" autoPlay />
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               {/* ======end modal====*/}
          </>
     )
}

export default ClientHome
