import React from 'react'
import { Link } from 'react-router-dom'
// import { Space, Input } from 'antd'
import BarChart from 'react-bar-chart';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export default function Report(props) {

     const data = [
          { text: 'Man', value: 500 },
          { text: 'Woman', value: 300 }
     ];
     const value = 0.66;
     const margin = { top: 20, right: 20, bottom: 30, left: 40 };


     const handleBarClick = (element, id) => {
          
     }

     return (
          <>
               <div className="tab-content  w-100 rewards1" id="v-pills-tabContent">
                    <div className='container-fluid py-3 mt-2 earnSec'>
                         <div className='row'>
                              <div className='col-lg-8'>
                                   <select class="form-select p-3" aria-label="Default select example">
                                        <option selected>Select Question</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                   </select>
                              </div>
                              {/* <div className="col-lg-4">
        <div className="d-flex flex-row align-items-center mndin">
          <div className="divmnt">
            <u> <input placeholder="Select your date" type="date" className="ps-1 border-0 fw-semibold text-secondary inpcls" name="checkIn" /> </u>
          </div>
          <h6 className="forange mx-2 fw-semibold">to</h6>
          <input placeholder="Select your date" type="date" className=" dashbin border-0 fw-semibold text-secondary ps-1" name="checkIn" />
        </div>
      </div> */}

                         </div>
                    </div>

                    <div className="tab-pane com-bs fade show active" id="v-pills-rewards" role="tabpanel" aria-labelledby="v-pills-rewards-tab" style={{ boxShadow: "none" }}>
                         <div className='reward-dash container-fluid py-2'>
                              <div className='l-sec w-100'>
                                   <div className='row'>
                                        <div className='col-md-6 col-lg-3 mb-3'>

                                             <a><div className='rew-card h-100 rounded-2'>
                                                  <p className=' text-dblue-re m-0'>Survey Viewed</p>
                                                  <div className='d-flex justify-content-between align-items-center'>
                                                       <div>
                                                            <span className='fs-2 text-orange-re fw-bold'>2705</span><br></br>
                                                            <i class="bi bi-arrow-up-right-circle-fill" style={{ color: "#0E9023", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i>
                                                       </div>
                                                       <div style={{ width: 100, height: 100, }}>
                                                            <CircularProgressbar value={value} maxValue={1} text={`${value * 100}%`} /></div>

                                                  </div>

                                             </div></a>


                                        </div>
                                        <div className='col-md-6 col-lg-3  mb-3'>
                                             <a><div className='rew-card h-100 rounded-2'>
                                                  <p className='text-dblue-re m-0'>Survey Completed</p>
                                                  <div className='d-flex justify-content-between align-items-center'>
                                                       <div>
                                                            <span className='fs-2 text-orange-re fw-bold'>5000</span><br />
                                                            <i class="bi bi-arrow-up-right-circle-fill" style={{ color: "#0E9023", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i>
                                                       </div>
                                                       <div style={{ width: 100, height: 100 }}>
                                                            <CircularProgressbar value={value} maxValue={1} text={`${value * 100}%`} /></div>
                                                  </div>
                                             </div>
                                             </a>
                                        </div>
                                        <div className='col-md-6 col-lg-3 mb-3'>
                                             <a><div className='rew-card h-100 rounded-2'>
                                                  <p className='text-dblue-re m-0'>Drop-Out (After Starting)</p>
                                                  <div className='d-flex justify-content-between align-items-center'>
                                                       <div>
                                                            <span className='fs-2 text-orange-re fw-bold'>03</span><br></br>
                                                            <i class="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i>
                                                       </div>
                                                       <div style={{ width: 100, height: 100 }}>
                                                            <CircularProgressbar value={value} maxValue={1} text={`${value * 100}%`} /></div>
                                                  </div>
                                             </div>
                                             </a>
                                        </div>


                                        <div className='col-md-6 col-lg-3 mb-3'>
                                             <a data-bs-toggle="modal" data-bs-target="#comple-survey"><div className='rew-card h-100 rounded-2'>
                                                  <p className='text-dblue-re m-0'>Completion Rate</p>
                                                  <div className='d-flex justify-content-between align-items-center'>
                                                       <div>
                                                            <span className='fs-2 text-orange-re fw-bold'>53.74 %</span><br></br>
                                                            <i class="bi bi-arrow-up-right-circle-fill" style={{ color: "#0E9023", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i>
                                                       </div>
                                                       <div style={{ width: 100, height: 100 }}>
                                                            <CircularProgressbar value={value} maxValue={1} text={`${value * 100}%`} /></div>
                                                  </div>
                                             </div></a>
                                        </div>
                                   </div>


                              </div>
                         </div>
                    </div>

                    <div className='container-fluid py-3 mt-2 earnSec'>
                         <div className='row'>
                              <div className='col-lg-12'>
                                   <select class="form-select p-3" aria-label="Default select example">
                                        <option selected>Select Question</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                   </select>
                              </div>
                         </div>
                    </div>

                    <div className='container-fluid py-3 mt-2 earnSec'>
                         <div className='row'>
                              <div className='col-lg-6'>
                                   <div className='card' style={{minHeight:"400px"}}>

                                        <h4 className='text-orange p-2'>Device</h4>
                                        <div className='row'>
                                             <div className='col-sm-6'>
                                                  <div className='text-center w-100'>
                                                       <img src="../images/Group 11577.svg" className='mb-4' />
                                                       <p className='text-dblue-re m-0'>Desktop</p>
                                                       <p className='text-black fs-3 fw-bold'>30%</p>

                                                  </div>
                                             </div>
                                             <div className='col-sm-6'>
                                                  <div className='text-center w-100'>
                                                       <img src="../images/Group 12402.svg" width={'150px'} className='mb-4' />
                                                       <p className='text-dblue-re m-0'>Mobile</p>
                                                       <p className='text-black fs-3 fw-bold'>70%</p>
                                                  </div>
                                             </div>
                                        </div>

                                        <div className='w-100 p-3'>
                                             <button class="mainbtn">Report Export</button>
                                        </div>
                                   </div>
                              </div>
                              <div className='col-md-6 col-lg-6'>
                                   <div className='card' style={{minHeight:"400px"}}>
                                        <h4 className='text-orange p-2'>Users</h4>
                                        <div className='row'>
                                             <div className='col-sm-6'>
                                                  <div className='text-center w-100'>
                                                       <img src="../images/Group 17662.svg" className='mb-4' />

                                                  </div>
                                             </div>
                                             <div className='col-sm-6 d-flex align-items-center'>
                                                  <div className='text-center w-100'>                                                          {/* <img src="../images/Group 12402.svg" width={'150px'} className='mb-4' /> */}

                                                       <span className='text-dblue-re m-0'>Male</span><span className='text-black fs-3 fw-bold'>&nbsp;&nbsp;&nbsp;70%</span><br></br>
                                                       <span className='text-dblue-re m-0'>Female</span><span className='text-black fs-3 fw-bold'>&nbsp;&nbsp;&nbsp;5%</span><br></br>
                                                       <span className='text-dblue-re m-0'>Other</span><span className='text-black fs-3 fw-bold'>&nbsp;&nbsp;&nbsp;05%</span>



                                                       {/* <p className='text-dblue-re m-0'>Mobile</p>
                                                            <p className='text-black fs-3 fw-bold'>70%</p>
                                                            <p className='text-dblue-re m-0'>Mobile</p>
                                                            <p className='text-black fs-3 fw-bold'>70%</p>
                                                            <p className='text-dblue-re m-0'>Mobile</p>
                                                            <p className='text-black fs-3 fw-bold'>70%</p> */}

                                                  </div>
                                             </div>
                                        </div>
                                        <div className='w-100 p-3'>
                                             <button class="mainbtn">Report Export</button>
                                        </div>
                                   </div>
                              </div>

                         </div>

                    </div>
                    {/* } */}

                    <div className='container-fluid py-3 mt-2 earnSec'>
                         <div className='row'>
                              <div className='col-lg-6'>
                                   <div className='card'>
                                        <div style={{ width: '50%' }}>
                                             <BarChart ylabel='Quantity'
                                                  //    width={state.width}
                                                  height={500}
                                                  margin={margin}
                                                  data={data}
                                                  onBarClick={handleBarClick} />
                                        </div>

                                   </div>
                              </div>
                              <div className='col-lg-6'>
                                   <div className='card'>
                                        <div style={{ width: '50%' }}>
                                             <BarChart ylabel='Quantity'
                                                  //    width={state.width}
                                                  height={500}
                                                  margin={margin}
                                                  data={data}
                                                  onBarClick={handleBarClick} />
                                        </div>

                                   </div>
                              </div>

                         </div>
                    </div>


                    <div className='container-fluid py-3 mt-2 earnSec'>
                         <div className='row'>
                              <div className='col-lg-12'>
                              <table class="table table-striped">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
      <th scope="col">Last</th>

    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
      <td>@mdo</td>

      
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
      <td>@mdo</td>

    </tr>
    <tr>
      <th scope="row">3</th>
      <td colspan="2">Larry the Bird</td>
      <td>@twitter</td>
      <td>@mdo</td>
      

    </tr>
  </tbody>
</table>

                              </div>
                         </div>
                    </div>


               </div>
          </>
     )
}