import { useEffect, useState, useRef, useSyncExternalStore } from "react";
import ReactApexChart from "react-apexcharts";
// import Multiselect from 'multiselect-react-dropdown';
import API from '../../utils/apiCalling';
import Config from "../../config/env";
import { toast, ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ReactWordcloud from 'react-wordcloud';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import pptxgen from "pptxgenjs";
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import { CSVLink } from "react-csv";
import Loader from '../../utils/loader';
import moment from "moment"


const ReportScreen = (props) => {

    let api = new API()
    const pptx = new pptxgen();
    const pdfref = useRef()
    let history = new useHistory();
    useEffect(() => {
        getDashBoard(props.id)
    }, [])
    const [dashboard, setDashBoard] = useState()
    const [report, setReport] = useState([])
    const [loader, setLoader] = useState(false)
    const [Selected, setSelected] = useState([])
    const [graph, setGraph] = useState('')
    const [questiondata, setQuestiondata] = useState([])
    const [selectedSurveyId, setSelectedId] = useState(props.id)
    const [matrix, setmatrix] = useState([])
    const [matReports, setMatReport] = useState({})
    const [matrixResponse, setTotalMatrixReponse] = useState([])
    const [words, setWords] = useState([])
    const [freeTest, setFreeText] = useState([])
    const [formtype, setformtype] = useState(0)
    const [questionvalue, setQuestionvalue] = useState('')
    const [formAns, setFormAns] = useState()
    const [neww, setnew] = useState([])
    const [arrayOfInadex, setarrayOfInadex] = useState([])
    const [newAnswer, setNewAnswer] = useState([])
    const [newMatrix, setNewMatrix] = useState([])
    const [newArray, setNewarray] = useState([])
    const [excelData, setExcelData] = useState([])
    const [surveyName, setSurveyName] = useState('')


    const options = {
        rotations: 2,
        rotationAngles: [-90, 0],
    };



    const handleGeneratePDF = async () => {
        setLoader(true)
        const pdf = new jsPDF("p", "mm", "a4");
        const pages = document.querySelectorAll(".allQueRport");
        for (const [index, page] of pages.entries()) {
            const canvas = await html2canvas(page, { scale: 3 });
            const paddingTop = 50;
            const paddingRight = 50;
            const paddingBottom = 50;
            const paddingLeft = 50;

            const canvasWidth = canvas.width + paddingLeft + paddingRight;
            const canvasHeight = canvas.height + paddingTop + paddingBottom;

            const newCanvas = document.createElement("canvas");
            newCanvas.width = canvasWidth;
            newCanvas.height = canvasHeight;
            const ctx = newCanvas.getContext("2d");

            if (ctx) {
                ctx.fillStyle = "#ffffff"; // Background color
                ctx.fillRect(0, 0, canvasWidth, canvasHeight);
                ctx.drawImage(canvas, paddingLeft, paddingTop);
            }

            const imgData = newCanvas.toDataURL("image/png");
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

            if (index < pages.length - 1) {
                pdf.addPage();
            }
        }

        pdf.save(`${surveyName}-Report.pdf`);
        setLoader(false)
    };


    const handleGeneratePDFx = async () => {
        const element = document.getElementById('v-pills-tabContent');

        if (element) {
            const pdfOptions = {
                margin: 10,
                filename: 'downloaded-pdf.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };

            const pdf = await html2pdf().from(element).set(pdfOptions).outputPdf();

            // Create a blob URL and trigger download
            const blobUrl = URL.createObjectURL(pdf);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'downloaded-pdf.pdf';
            link.click();

            // Clean up the blob URL
            URL.revokeObjectURL(blobUrl);
        }
    };


    const handleDownload = async () => {
        setLoader(true)
        const pages = document.querySelectorAll(".allQueRport");

        const capturePromises = [];

        for (const [index, page] of pages.entries()) {
            const canvas = await html2canvas(page, { scale: 4 });
            const imageData = canvas.toDataURL('image/png');
            capturePromises.push(imageData)
        }

        capturePromises.forEach((base64Image) => {
            const slide = pptx.addSlide();

            // Add the image to the slide
            const imgOpts = {
                data: base64Image,
                x: 0,
                y: 0,
                w: '100%',
                h: '100%',
            };

            slide.addImage(imgOpts);
        });

        pptx.writeFile(`${surveyName}-Report`);
        setLoader(false)
    }



    const SetGragh = (id, ID, index) => {
        setgraph(id, index)
        console.log(id, ID, index,"id, ID, index")
        let newID = report.filter(e => e._id === ID)
console.log(report,"report")
console.log(newID,"newIDnewIDnewIDnewID")
        report[index].chartData = {
            series: [{
                name: 'Answer',
                data: newID[0]?.GraphValue
            }],
            options: {
                colors: ['#F47321'],
                chart: {
                    type: 'bar',
                    height: 450
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: newID[0]?.GraphKey
                    ,
                },
                yaxis: {
                    title: {
                        text: 'Answer %'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return + val + " % "
                        }
                    }
                },
                toolbar: {
                    show: false
                }

            },

        }

        report[index].picData = {
            series: newID[0]?.GraphValue,
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: newID[0]?.GraphKey,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        }
    }

    const convertSecondsToMinutesAndSeconds=(seconds)=> {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}.${remainingSeconds}`;
      }

    const getDashBoard = async (sueveyId) => {
        setLoader(true)
        let data = {
            surveyId: sueveyId
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/getDashboard`, data)
        if (result?.code === 200) {
            setSelectedId(sueveyId)
            setDashBoard(result.data)
            // setSurveyName
        } else {
            toast.error(result?.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        let surveyData = await api.post(`${Config.new.site_api}/client-survey/Dashboard`, data)
        if (surveyData.code === 200) {
            setQuestiondata(surveyData.data)
            setLoader(false)


            setSurveyName(surveyData.data.length > 0 && surveyData.data[0].surveyName)
            let filterData
            surveyData.data.map((res) => {
                filterData = {}
                filterData['ResponceId'] = res._id
                filterData['Date'] = res.date && res.date
                filterData['LOI']=res.loi&&convertSecondsToMinutesAndSeconds(res.loi)
                res.questions.map((que) => {
                    console.log(que, "quequeque")
                    if (sueveyId === "64f82904ef78b12eea62a014") {

                        const utcTimestamp = new Date(res.date);
                        const istDateTime = new Intl.DateTimeFormat("en-US", {
                            timeZone: "Asia/Kolkata",
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            // second: "2-digit",
                        }).format(utcTimestamp);

                        var gmtDateTime = moment.utc(res.date, "YYYY-MM-DD HH")
                        var local = gmtDateTime.local().format('YYYY-MMM-DD h:mm A')
                        // let date = local.split(" ")
                        let date = istDateTime.split(", ")
                        if (que._id == "6527ebe03b3e4e9b6adba2b5") {
                            filterData[que.question] = filterData[que.question] ? filterData[que.question] : (que.answer !== undefined) ? date[0] : date[0]
                        } else if (que._id == "6527ebef5dcf6e3ae53322cb") {
                            filterData[que.question] = filterData[que.question] ? filterData[que.question] : (que.answer !== undefined) ? date[1] : date[1]
                        } else {
                            filterData[que.question] = filterData[que.question] ? filterData[que.question] : (que.answer !== undefined) ? que.answer : que.answer
                        }

                    } else {
                        filterData[que.question] = filterData[que.question] ? filterData[que.question] : (que.answer !== undefined) ? que.answer : que.answer
                    }
                })
                excelData.push(filterData)


            })
            newDashBoardData(surveyData?.data)
        }
    }
    const newDashBoardData = (newData) => {
        (newData[0]?.questions)?.map(e => {
            if (e.type === "radio" || e.type === 'checkbox') {
                let finalArray = []
                let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                let Answer = Question.map(e => {
                    if (Array.isArray(e)) {
                        return e[0]?.answer
                    } else {
                        return e
                    }
                })
                let response = Question[0]
                let Option = Question[0]?.map(e => (e.options))

                Answer = Answer.map((ele) => {
                    // console.log(ele,"dfdf")
                    Answer = ele[0]?.split(":")
                    return Answer

                })
                console.log(Answer, "Answer")
                let newArray = []
                Option[0]?.map((e) => {
                    let sum = 0
                    Answer?.map((q) => {
                        if (q) {
                            if (Array.isArray(q)) {
                                if (e.option == q[0] || e.option == Answer) {
                                    return sum = sum + 1
                                }
                            } else if (e.option.toLowerCase() == 'other') {
                                return sum = sum + 1
                            }
                        }
                    })
                    newArray.push({ Option: e.option, Answer: sum })
                })

                let totalResponses = Answer.length
                newArray.map(e => {
                    e.count = parseInt(((e.Answer) * 100) / totalResponses)
                })
                let GraphKey = []
                let GraphValue = []
                newArray.map(e => {
                    GraphKey.push(e.Option)
                    GraphValue.push(e.count)
                })
                response.map(e => {
                    e.ans = newArray
                    e.GraphKey = GraphKey
                    e.GraphValue = GraphValue
                })
                finalArray.push(response[0])

                report.push(...finalArray)

                setGraph('')

            } else if (e.type === 'star' || e.type === "nps" || e.type === "rating") {
                let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                let Answer = Question.map(e => e[0]?.answer)
                let response = Question[0]
                let finalArray = []
                let newArray = []
                let Option = Question[0]?.map(e => (e.options))
                Option[0]?.map(e => {
                    let sum = 0
                    Answer.map(q => {
                        if (e.option == q) {
                            sum = sum + 1
                        }
                    })
                    newArray.push({ "Option": e.option, "Answer": sum })
                })
                let totalResponses = Answer.length
                newArray.map(e => {
                    e.count = parseInt(((e.Answer) * 100) / totalResponses)
                })
                let GraphKey = []
                let GraphValue = []
                newArray.map(e => {
                    GraphKey.push(e.Option)
                    GraphValue.push(e.count)
                })

                response.map(e => {
                    e.ans = newArray
                    e.GraphKey = GraphKey
                    e.GraphValue = GraphValue
                })
                finalArray.push(response[0])

                report.push(...finalArray)

                setGraph('')

            } else if (e.type === "csat") {
                let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id == e._id) })
                let Answer = Question.map(e => e[0]?.answer)
                let response = Question[0]
                let finalArray = []
                let newArray = []
                let Option = Question[0]?.map(e => (e.options))
                Option[0]?.map((e, ind) => {
                    let sum = 0
                    Answer.map(q => {
                        if (ind + 1 == q) {
                            sum = sum + 1
                        }
                    })
                    newArray.push({ "Option": e.option, "Answer": sum })
                })
                let totalResponses = Answer.length
                newArray.map(e => {
                    e.count = parseInt(((e.Answer) * 100) / totalResponses)
                })
                let GraphKey = []
                let GraphValue = []
                newArray.map(e => {
                    GraphKey.push(e.Option)
                    GraphValue.push(e.count)
                })

                response.map(e => {
                    e.ans = newArray
                    e.GraphKey = GraphKey
                    e.GraphValue = GraphValue
                })
                finalArray.push(response[0])

                report.push(...finalArray)

                setGraph('')

            }

            else if (e.type === "text") {
                let Answer
                let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                Answer = countDuplicates((Question.map(e => e[0]?.answer)).map(e => { if (e !== undefined) { return e[0] } }))
                function countDuplicates(array) {
                    const countMap = new Map();
                    array.forEach((value) => {
                        countMap.set(value, (countMap.get(value) || 0) + 1);
                    });
                    const duplicates = [];
                    countMap.forEach((value, text) => {
                        if (value >= 1) {
                            duplicates.push({ text, value });
                        }
                    });
                    return duplicates;
                }
                e.Ans = Answer
                words.push(e)
            } else if (e.type === "form") {
                setQuestionvalue(e)
                setformtype(1)
                let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                let Answer = Question.map(e => e[0]?.answer)
                // setarrayOfInadex(Question[0][0].options)
                // setFormAns(Answer)
                newhandleformtype(Question[0][0].options, Answer)
            } else if (e.type === 'matrix') {
                if (e.selection !== "input") {
                    let newResult = e
                    let matrixAnswerReport = {}
                    let tempQue = []
                    let newArray = []
                    let totalResponses = []
                    let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                    let Answer = Question.map(e => e[0]?.answer)
                    Answer.length > 0 && Answer.map(e => {
                        if (e) {
                            let newValue = (((e.toString()).replaceAll('-', ',')).slice(0, -1)?.split(','))
                            const result = [];
                            for (let i = 0; i < newValue.length; i += 2) {
                                const field = newValue[i]?.split(':')[1];
                                const answer = newValue[i + 1]?.split(':')[1];
                                result.push({ field, answer });
                                if (!tempQue.includes(field)) {
                                    tempQue.push(field)
                                }
                            }
                            totalResponses.push(result)

                        }
                    })
                    // })
                    setmatrix(newResult)
                    tempQue.forEach((que) => {
                        matrixAnswerReport[que] = {}
                    })

                    setTotalMatrixReponse(totalResponses)
                    totalResponses.forEach((responses) => {
                        responses.forEach((response) => {
                            matrixAnswerReport[response.field][response.answer] = matrixAnswerReport[response.field][response.answer] ? matrixAnswerReport[response.field][response.answer] + 1 : 1
                        })
                    })
                    setMatReport(matrixAnswerReport);
                    e.matReport = matrixAnswerReport
                    newMatrix.push(e)
                    newArray.push(matrixAnswerReport)

                }
            }
        })

    }
    const newhandleformtype = async (item, formAnss) => {
        setnew(item)
        let newwww = 0
        item.map((q, i) => {
            let indexvalue = item.map((e, index) => { if (e.option === q.option) { return index } })
            let IndexValue = (indexvalue.filter(e => e !== undefined))
            for (let value of IndexValue) { newwww = value }
            let newAnsArray = countDuplicates(findName(formAnss))
            function findName(array) {
                const names = [];
                for (let i = 0; i < array.length; i++) {
                    const element = array[i];
                    const name = (element?.split(',')[newwww])?.split(':')[1];
                    names.push(name);
                }
                return names;
            }


            function countDuplicates(array) {
                const countMap = new Map();
                array.forEach((value) => {
                    countMap.set(value, (countMap.get(value) || 0) + 1);
                });
                const duplicates = [];
                countMap.forEach((value, text) => {
                    if (value >= 1) {
                        duplicates.push({ text, value });
                    }
                });
                return duplicates;
            }
            q.Ans = newAnsArray
        })
        newAnswer.push(...item)

    }

    // const getQuestion = async (id, item) => {
    //     newArray.push(item)

    //     setSelected(item)
    //     const multiValue = id

    //     if (item.type === "radio" || item.type === 'checkbox') {
    //         let finalArray = []
    //         multiValue.map(a => {
    //             let Question = questiondata?.map(e => { return (e.questions).filter(q => q._id === item._id) })
    //             let Answer = Question.map(e => e[0]?.answer)
    //             let response = Question[0]
    //             let Option = Question[0]?.map(e => (e.options))
    //             let newArray = []
    //             Option[0]?.map((e) => {
    //                 let sum = 0
    //                 Answer?.map((q) => {
    //                     if (q) {
    //                         if (Array.isArray(q)) {
    //                             if (e.option == q[0]) {
    //                                 return sum = sum + 1
    //                             }
    //                         } else if (e.option.toLowerCase() == 'other') {
    //                             return sum = sum + 1
    //                         }
    //                     }
    //                 })
    //                 newArray.push({ "Option": e.option, "Answer": sum })
    //             })

    //             let totalResponses = Answer.length
    //             newArray.map(e => {
    //                 e.count = parseInt(((e.Answer) * 100) / totalResponses)
    //             })
    //             let GraphKey = []
    //             let GraphValue = []
    //             newArray.map(e => {
    //                 GraphKey.push(e.Option)
    //                 GraphValue.push(e.count)
    //             })
    //             response.map(e => {
    //                 e.ans = newArray
    //                 e.GraphKey = GraphKey
    //                 e.GraphValue = GraphValue
    //             })

    //             finalArray.push(response[0])


    //             setReport(finalArray)
    //             setGraph('')
    //         })
    //     } else if (item.type === 'matrix') {
    //         let newResult = item
    //         let matrixAnswerReport = {}
    //         let tempQue = []
    //         let newArray = []
    //         let totalResponses = []
    //         // multiValue?.map(a => {
    //         let Question = questiondata?.map(e => { return (e.questions).filter(q => q._id === item._id) })
    //         let Answer = Question.map(e => e[0]?.answer)
    //         Answer.map(e => {
    //             if (e) {
    //                 let newValue = (((e.toString()).replaceAll('-', ',')).slice(0, -1).split(','))
    //                 const result = [];
    //                 for (let i = 0; i < newValue.length; i += 2) {
    //                     const field = newValue[i].split(':')[1].trim();
    //                     const answer = newValue[i + 1].split(':')[1].trim();
    //                     result.push({ field, answer });
    //                     if (!tempQue.includes(field)) {
    //                         tempQue.push(field)
    //                     }
    //                 }
    //                 totalResponses.push(result)
    //             }
    //         })
    //         // })
    //         setmatrix(newResult)
    //         tempQue.forEach((que) => {
    //             matrixAnswerReport[que] = {}
    //         })

    //         setTotalMatrixReponse(totalResponses)
    //         totalResponses.forEach((responses) => {
    //             responses.forEach((response) => {
    //                 matrixAnswerReport[response.field][response.answer] = matrixAnswerReport[response.field][response.answer] ? matrixAnswerReport[response.field][response.answer] + 1 : 1
    //             })
    //         })
    //         setMatReport(matrixAnswerReport);
    //         item.matReport = matrixAnswerReport
    //         newMatrix.push(item)
    //         newArray.push(matrixAnswerReport)
    //     } else if (item.type === 'star' || item.type === "nps" || item.type === "rating" || item.type === "csat") {
    //         // multiValue.map(a => { 
    //         let Question = questiondata?.map(e => { return (e.questions).filter(q => q._id === item._id) })
    //         let Answer = Question.map(e => e[0]?.answer)
    //         let response = Question[0]
    //         let finalArray = []
    //         let newArray = []
    //         let Option = Question[0]?.map(e => (e.options))
    //         Option[0]?.map(e => {
    //             let sum = 0
    //             Answer.map(q => {
    //                 if (e.option == q) {
    //                     sum = sum + 1
    //                 }
    //             })
    //             newArray.push({ "Option": e.option, "Answer": sum })
    //         })
    //         let totalResponses = Answer.length
    //         newArray.map(e => {
    //             e.count = parseInt(((e.Answer) * 100) / totalResponses)
    //         })
    //         let GraphKey = []
    //         let GraphValue = []
    //         newArray.map(e => {
    //             GraphKey.push(e.Option)
    //             GraphValue.push(e.count)
    //         })

    //         response.map(e => {
    //             e.ans = newArray
    //             e.GraphKey = GraphKey
    //             e.GraphValue = GraphValue
    //         })
    //         finalArray.push(response[0])
    //         setReport(finalArray)
    //         setGraph('')

    //         // })
    //     } else if (item.type === 'form') {
    //         setQuestionvalue(item)
    //         setformtype(1)
    //         // multiValue?.map(a => {
    //         let Question = questiondata?.map(e => { return (e?.questions).filter(q => q._id === item._id) })
    //         let Answer = Question.map(e => e[0]?.answer)
    //         setarrayOfInadex(Question[0][0].options)
    //         setFormAns(Answer)
    //         // })
    //     } else if (item.type === "text") {
    //         setFreeText(item)
    //         let Answer
    //         // multiValue?.map(a => {s
    //         let Question = questiondata?.map(e => { return (e.questions).filter(q => q._id === item._id) })
    //         Answer = countDuplicates((Question.map(e => e[0]?.answer)).map(e => { if (e !== undefined) { return e[0] } }))
    //         function countDuplicates(array) {
    //             const countMap = new Map();
    //             array.forEach((value) => {
    //                 countMap.set(value, (countMap.get(value) || 0) + 1);
    //             });
    //             const duplicates = [];
    //             countMap.forEach((value, text) => {
    //                 if (value >= 1) {
    //                     duplicates.push({ text, value });
    //                 }
    //             });
    //             return duplicates;
    //         }
    //         // })
    //         item.Ans = Answer
    //         words.push(item)
    //     }
    // }
    // const handleformtype = async (id, item) => {
    //     setnew(item)
    //     let indexvalue = arrayOfInadex.map((e, index) => { if (e.option === item.option) { return index } })
    //     let IndexValue = (indexvalue.filter(e => e !== undefined))
    //     let newwww = 0
    //     for (let value of IndexValue) { newwww = value }
    //     let newAnsArray = countDuplicates(findName(formAns))
    //     function findName(array) {
    //         const names = [];
    //         for (let i = 0; i < array.length; i++) {
    //             const element = array[i];
    //             const name = (element?.split(',')[newwww])?.split(':')[1];
    //             names.push(name);
    //         }
    //         return names;
    //     }
    //     item.Ans = newAnsArray
    //     function countDuplicates(array) {
    //         const countMap = new Map();
    //         array.forEach((value) => {
    //             countMap.set(value, (countMap.get(value) || 0) + 1);
    //         });
    //         const duplicates = [];
    //         countMap.forEach((value, text) => {
    //             if (value >= 1) {
    //                 duplicates.push({ text, value });
    //             }
    //         });

    //         return duplicates;
    //     }
    //     newAnswer.push(item)
    // }

    const parentToChild = () => {
        history.push(`/crossTab/${selectedSurveyId}`)
    }



    const setgraph = (value, index) => {
        setGraph(value)
        if (value == report[index].graph) {
            let newReport = [...report]
            let newObject = newReport[index]
            newObject.graph = null
            setReport(newReport)
        }
        else {
            let newReport = [...report]
            let newObject = newReport[index]
            newObject.graph = value
            setReport(newReport)
        }
    }

    const excelGenerate = async () => {
        window.location.href = `${Config.new.site_api}/client-survey/getTataReport`

    }



    // const DownloadPdf = () => {
    //     const input = pdfref.current
    //     html2canvas(input).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/*');
    //         const pdf = new jsPDF('p', 'mm', 'a4', true);

    //         const pdfWidth = pdf.internal.pageSize.getWidth()
    //         const pdfHeigth = pdf.internal.pageSize.getHeight()
    //         const imgWidth = canvas.width
    //         const imgHeigth = canvas.height
    //         const width = pdf.internal.pageSize.getWidth();
    //         const height = pdf.internal.pageSize.getHeight();
    //         const radio = Math.min(pdfWidth / imgWidth, pdfHeigth / imgHeigth)
    //         const imgx = (pdfWidth - imgWidth * radio) / 2
    //         const imgy = 30
    //         pdf.addImage(imgData, 'JPEG', 0, 0, width, height);

    //         pdf.save('report.pdf')
    //     })
    // }
    return (
        <div className="surveyDashboard sectionlas" >
            <>
                <div className="tab-content  w-100 rewards1" id="v-pills-tabContent" ref={pdfref}>
                    <div className='container-fluid py-3 mt-2 earnSec '>
                        <div className='row'>
                            <div className='col-lg-12 thisTopbar d-flex gap-0'>
                                <select className="form-select p-3" aria-label="Default select example" onChange={e => getDashBoard(e.target.value)}>
                                    <option hidden>Select Survey</option>
                                    {props.selectedSurveys && props.selectedSurveys.map(e =>
                                        <option value={e._id}>{e.survey_name}</option>
                                    )}
                                </select>
                                <div className="d-flex flex-row align-items-center mndin">

                                    {/* DownloadPdf */}
                                    {/* <div className="divmnt">
                                        <u> <input placeholder="Select your date" type="date" className=" fw-semibold text-secondary inpcls" name="checkIn" /> </u>
                                    </div>
                                    <h6 className="forange mx-2 fw-semibold">to</h6>
                                    <input placeholder="Select your date" type="date" className=" dashbin fw-semibold text-secondary " name="checkIn" />
                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {dashboard &&
                        <div className="col-md-12 tab-pane fade show active" id="v-pills-rewards" role="tabpanel" aria-labelledby="v-pills-rewards-tab" style={{ boxShadow: "none" }}>
                            <div className='reward-dash container-fluid px-0 py-2'>
                                <div className='l-sec w-100'>
                                    <div className='row'>
                                        <div className='col-md-6 col-lg-3 mb-3'>
                                            <a><div className='rew-card h-100 rounded-2 d-flex justify-content-center px-3 flex-column '>
                                                <p className='head-Surveysa m-0 fw-semibold'><i className="bi bi-person-check-fill"></i> Survey Completed</p>
                                                <div className='d-flex justify-content-between align-items-center pt-2'>
                                                    <div className='w-100'>
                                                        <div className='progPress justify-content-between d-flex align-items-center'>
                                                            <span className='fw-semibold fs-4' style={{ color: "#F47321" }}>{dashboard && dashboard[0]?.Count}%</span>
                                                            <span className='fs-5 text-blue fw-bold text-blue'>{dashboard && dashboard[0]?.TotalCount}</span>
                                                        </div>
                                                        {/* <br></br> */}
                                                        {/* <i className="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i> */}
                                                        <div className="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                            <div className="progress-bar" style={{ width: `${dashboard && dashboard[0]?.Count}%` }}></div>
                                                        </div>
                                                        <span className='srvesa-items fw-semibold'>Expected:</span><span style={{ color: "#354C9E" }}> {dashboard && dashboard[0]?.Expected}</span>
                                                    </div>
                                                    {/* <div style={{ width: 65, height: 65 }}>
                                                        <CircularProgressbar value={dashboard && dashboard.survey[1] && (dashboard.survey[1].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[1] && dashboard.survey[1].percent}%`} />
                                                   </div> */}
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                        <div className='col-md-6 col-lg-3 mb-3'>
                                            <a><div className='rew-card h-100 rounded-2 d-flex justify-content-center px-3 flex-column '>
                                                <p className='head-Surveysa m-0 fw-semibold'><i className="bi fw-bold fs-5 bi-arrow-bar-down"></i> Drop-Off Rate</p>
                                                <div className='d-flex justify-content-between align-items-center pt-2'>
                                                    <div className='w-100'>
                                                        <div className='progPress justify-content-between d-flex align-items-center'>
                                                            <span className='fw-semibold fs-4' style={{ color: "#F47321" }}>{dashboard && dashboard[1]?.Count}%</span>
                                                            <span className='fs-5 text-blue fw-bold text-blue'>{dashboard && dashboard[1].TotalCount}</span>
                                                        </div>
                                                        {/* <br></br> */}
                                                        {/* <i className="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i> */}
                                                        <div className="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                            <div className="progress-bar" style={{ width: `${dashboard && dashboard[1]?.Count}%` }}></div>
                                                        </div>
                                                        <span className='srvesa-items fw-semibold'>Entrants:</span><span style={{ color: "#354C9E" }}> {dashboard && dashboard[1]?.Expected}</span>
                                                    </div>
                                                    {/* <div style={{ width: 65, height: 65 }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[1] && (dashboard.survey[1].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[1] && dashboard.survey[1].percent}%`} />
                                                                        </div> */}
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                        <div className='col-md-6 col-lg-3 mb-3'>
                                            <a><div className='rew-card h-100 rounded-2 d-flex justify-content-center px-3 flex-column rew-crdItems'>
                                                <p className='head-Surveysa m-0 fw-semibold'><i className="bi bi-calendar2-check"></i> Completion LOI</p>
                                                <div className='d-flex justify-content-between align-items-center pt-2'>
                                                    <div className='w-100'>
                                                        <div className='progPress justify-content-between d-flex align-items-center'>
                                                            <span className='fw-semibold fs-4' style={{ color: "#F47321" }}>{dashboard && dashboard[2].Count}</span>
                                                            <span className='fs-5 text-blue fw-bold text-blue'>{dashboard && dashboard[2].TotalCount}</span>
                                                        </div>
                                                        {/* <br></br> */}
                                                        {/* <i className="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i> */}
                                                        <div className="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                            <div className="progress-bar " style={{ width: `${(dashboard && dashboard[2]?.Count) * 10}%` }}></div>
                                                        </div>
                                                        <span className='srvesa-items fw-semibold'>Expected :</span><span style={{ color: "#354C9E" }}> {dashboard && dashboard[2].Expected} min</span>
                                                    </div>
                                                    {/* <div style={{ width: 65, height: 65 }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[1] && (dashboard.survey[1].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[1] && dashboard.survey[1].percent}%`} />
                                                                        </div> */}
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                        <div className='col-md-6 col-lg-3 mb-3'>
                                            <a><div className='rew-card h-100 rounded-2 d-flex justify-content-center px-3 flex-column rew-crdItems'>
                                                <p className='head-Surveysa m-0 fw-semibold'><i className="bi bi-calendar-x"></i> Termination LOI</p>
                                                <div className='d-flex justify-content-between align-items-center pt-2'>
                                                    <div className='w-100'>
                                                        <div className='progPress justify-content-between d-flex align-items-center'>
                                                            <span className='fw-semibold fs-4' style={{ color: "#F47321" }}>{dashboard && dashboard[3].Count}</span>
                                                            <span className='fs-5 text-blue fw-bold text-blue'>{dashboard && dashboard[3].TotalCount}</span>
                                                        </div>
                                                        {/* <br></br> */}
                                                        {/* <i className="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i> */}
                                                        <div className="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                            <div className="progress-bar" style={{ width: `${(dashboard && dashboard[3]?.Count) * 10}%` }}></div>
                                                        </div>
                                                        <span className='srvesa-items fw-semibold'>Goal:</span><span style={{ color: "#354C9E" }}> {dashboard && dashboard[3].Expected} min</span>
                                                    </div>
                                                    {/* <div style={{ width: 65, height: 65 }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[1] && (dashboard.survey[1].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[1] && dashboard.survey[1].percent}%`} />
                                                                        </div> */}
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {loader && <Loader />}
                    {dashboard &&
                        <div className='container-fluid py-3 earnSec'>
                            <div className='row'>
                                <div className='col-lg-12 d-flex mb-md-0 mb-3 filter-Quap'>
                                    {/* <!-- Button trigger modal --> */}

                                    {/* <button type="button" className="mainbtn mainbtsase mr-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        <i className="bi bi-funnel-fill"></i>Filter
                                    </button> */}
                                    <button type="button" className="mainbtn mainbtsase mr-3" onClick={() => parentToChild()}>
                                        Cross Tab
                                    </button>
                                    <button type="button" className="mainbtn mainbtsase mr-3" onClick={handleGeneratePDF}>
                                        Export to PDF
                                    </button>
                                    <button type="button" className="mainbtn mainbtsase mr-3" onClick={handleDownload} >Export to PPT</button>
                                    <CSVLink color="black" data={excelData}
                                        filename={`${surveyName} Report.csv`} >
                                        <button type="button" className="mainbtn mainbtsase"  >Export to CSV</button>
                                    </CSVLink >
                                    {(selectedSurveyId == "64f85721ef78b12eea62a95d") && <button type="button" className="mainbtn mainbtsase ml-3" onClick={excelGenerate}>
                                        Excel Download
                                    </button>}
                                    {(selectedSurveyId == "64f85721ef78b12eea62a95d") && <button type="button" className="mainbtn mainbtsase ml-3" onClick={() => history.push('/SurveyImages')}>
                                        Image Download
                                    </button>}
                                    {(selectedSurveyId == "64f82904ef78b12eea62a014" || selectedSurveyId == "653a032f1f22aef0e80f6510" || selectedSurveyId == "655f2f38e08dda447c4d1186") && <button type="button" className="mainbtn mainbtsase ml-3" onClick={() => history.push(`/SurveyImages/${selectedSurveyId}`)}>
                                        Image Download
                                    </button>}
                                    {/* <!-- Modal --> */}
                                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <div className="modal-content">
                                                <div className="modal-header align-items-center">
                                                    <h1 className="modal-title fs-6 fw-bold mt-0" id="exampleModalLabel">Filter Applied</h1>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="filters-applied">
                                                        <div className="filter-stat">
                                                            <h5 style={{ cursor: 'pointer' }}>
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                <span>Gender</span>
                                                                <div className="clearfix" />
                                                            </h5>
                                                            <div>
                                                                <div role="presentation" className="active flexContainer">
                                                                    <div className="flex-item icon">
                                                                        <span className="icon-checked" />
                                                                    </div>
                                                                    <div className="flex-item title"><input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />male</div>

                                                                </div>
                                                                <div role="presentation" className="active flexContainer">
                                                                    <div className="flex-item icon">
                                                                        <span className="icon-checked" />
                                                                    </div>
                                                                    <div className="flex-item title"><input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />female</div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="filter-stat">
                                                            <h5 style={{ cursor: 'pointer' }}>
                                                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                <span>Age</span>
                                                                <div className="clearfix" />
                                                            </h5>
                                                            <div>
                                                                <div role="presentation" className="active flexContainer">
                                                                    <div className="flex-item icon">
                                                                        <span className="icon-checked" />
                                                                    </div>
                                                                    <div className="flex-item title"><input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />25 - 34</div>

                                                                </div>
                                                                <div role="presentation" className="active flexContainer">
                                                                    <div className="flex-item icon">
                                                                        <span className="icon-checked" />
                                                                    </div>
                                                                    <div className="flex-item title"><input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />35 - 44</div>

                                                                </div>
                                                                <div role="presentation" className="active flexContainer">
                                                                    <div className="flex-item icon">
                                                                        <span className="icon-checked" />
                                                                    </div>
                                                                    <div className="flex-item title"><input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />18 - 24</div>

                                                                </div>
                                                                <div role="presentation" className="active flexContainer">
                                                                    <div className="flex-item icon">
                                                                        <span className="icon-checked" />
                                                                    </div>
                                                                    <div className="flex-item title"><input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />45 - 54</div>

                                                                </div>
                                                                <div role="presentation" className="active flexContainer">
                                                                    <div className="flex-item icon">
                                                                        <span className="icon-checked" />
                                                                    </div>
                                                                    <div className="flex-item title"><input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />16 - 17</div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="mainbtn">Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='col-lg-8 col-md-6 filter-Quap2'>
                                    <Multiselect
                                        placeholder='Select Question'
                                        options={(dashboard[4]?.Question[0]).map(q => q)} // Options to display in the dropdown
                                        selectedValues={Selected[0]?.question} // Preselected value to persist in dropdown
                                        onSelect={getQuestion} // Function will trigger on select event
                                        onRemove={onRemove} // Function will trigger on remove event
                                        displayValue="question" // Property name to display in the dropdown options
                                    />
                                </div> */}
                                {/* {formtype === 1 &&
                                    <div className='col-lg-12 mt-3 col-md-6 filter-Quap2'>
                                        <Multiselect
                                            placeholder='Select Question'
                                            options={(questionvalue.options).map(e => e)}
                                            selectedValues={neww[0]?.options}
                                            onSelect={handleformtype}
                                            onRemove={onRemove}
                                            displayValue="option"
                                        />
                                    </div>} */}
                            </div>
                        </div>
                    }
                    {/* Que start */}
                    {/* 1 */}

                    {report && report.map((setValue, index) => (
                        ((setValue.type) !== "form" && (setValue.type) !== "text") ? <>
                            <div className='container-fluid allQueRport py-3 mt-2 earnSec'>

                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className="panel panel-default" style={{ opacity: 1 }}>
                                            <div className="panel-heading">
                                                <div>
                                                    <div className="result-title">
                                                        <div className="question-type result-title__type">{setValue.type === 'radio' ? "Single" : setValue.type} Selection</div>
                                                        <div className="clearfix">
                                                            <div className="result-title__question--overflow-hidden">
                                                                <div>
                                                                    <h4 className="result-title__question">
                                                                        <span className="result-title__question-label"></span>{setValue?.question}
                                                                    </h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="clearer" />
                                                </div>
                                            </div>
                                            <div className="panel-body">
                                                <div>
                                                    <div className="react-bs-table-container">
                                                        <div className="react-bs-table react-bs-table-bordered reponsive-table" style={{ height: '100%' }}>
                                                            <div className="react-bs-container-header table-header-wrapper">
                                                                <table className="table table-hover table-bordered " data-classes-applied="true">
                                                                    <colgroup>
                                                                        {/* <col style={{ display: 'none' }} />
                                                                        <col style={{ width: '45px', minWidth: '45px' }} />
                                                                        <col />
                                                                        <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                        <col style={{ width: '140px', minWidth: '140px' }} />
                                                                        <col style={{ width: '100px', minWidth: '100px' }} /> */}
                                                                        <col style={{ display: 'none' }} />
                                                                        <col style={{ width: '45px', minWidth: '45px' }} />
                                                                        <col style={{ width: '140px', minWidth: '140px' }} />
                                                                        <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                        <col style={{ width: '200px', minWidth: '200px' }} />
                                                                        <col style={{ width: '300px', minWidth: '300px' }} />
                                                                    </colgroup>
                                                                    <thead>
                                                                        <tr>
                                                                            <th data-is-only-head="false" data-field="id" style={{ textAlign: 'left', display: 'none' }}>
                                                                                <div />
                                                                            </th>
                                                                            <th className="text-title" data-is-only-head="false" title="#" data-field="ai" style={{ textAlign: 'center' }}>#
                                                                                <div />
                                                                            </th>
                                                                            <th className="text-title answer-legible-text" data-is-only-head="false" title="Answers" data-field="body" style={{ textAlign: 'left' }}>Answers
                                                                                <div />
                                                                            </th>
                                                                            <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                <div className="nowrap">Answers (%)</div>
                                                                                <div />
                                                                            </th>
                                                                            <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                <div className="nowrap">Count</div>
                                                                                <div />
                                                                            </th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th className="text-title hidden-xs" data-is-only-head="false" data-field="percent" style={{ textAlign: 'left' }}>
                                                                                <div />
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                </table>
                                                            </div>
                                                            {(setValue.ans)?.map((e, index) => (
                                                                <div className="react-bs-container-body" style={{ height: '100%' }}>
                                                                    <table className="table table-bordered table-hover" data-classes-applied="true">
                                                                        <colgroup>
                                                                            <col style={{ display: 'none' }} />
                                                                            <col style={{ width: '45px', minWidth: '45px' }} />
                                                                            <col style={{ width: '140px', minWidth: '140px' }} />
                                                                            <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                            <col style={{ width: '200px', minWidth: '200px' }} />
                                                                            <col style={{ width: '300px', minWidth: '300px' }} />
                                                                        </colgroup>
                                                                        <tbody>
                                                                            <tr className="selected">
                                                                                <td tabIndex={1} style={{ textAlign: 'left', display: 'none' }}>33665813</td>
                                                                                <td tabIndex={2} className="indicator" style={{ textAlign: 'center' }}>
                                                                                    <div title="Filter results by this answer">A{index + 1}</div>
                                                                                </td>
                                                                                <td tabIndex={3} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                    <div className="answer__container selected-item">
                                                                                        <div className="answer__text">
                                                                                            <div className="flex">
                                                                                                <span className="flex-1">{e.Option}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td tabIndex={5} className="percent" style={{ textAlign: 'right' }}>
                                                                                    <div>
                                                                                        <div>
                                                                                            <span className="selected-item">{e.count}%</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td tabIndex={6} className="percent" style={{ textAlign: 'right' }}>
                                                                                    <div>
                                                                                        <div className="selected-item">{e.Answer}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td tabIndex={4} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                    <div>
                                                                                        <div className="full-bar">
                                                                                            <div className="bar-container">
                                                                                                <span className="percentage-bar selected" style={{ width: `${e.count}%` }} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>))}
                                                        </div>
                                                        <div className="s-alert-wrapper" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="panel-footer">
                                                <div className="row">
                                                    <div className="available-charts col-sm-4">
                                                        <div>
                                                            <button type="button" className="chart-type chart-type-btn" onClick={(e) => SetGragh("chart", setValue._id, index)} >
                                                                <i className="bi bi-bar-chart-line-fill" />
                                                            </button>
                                                            <button type="button" className="chart-type chart-type-btn" onClick={(e) => SetGragh("pichart", setValue._id, index)}>
                                                                <i className="bi bi-pie-chart-fill" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-8">
                                                        <div className="result-footer">
                                                            <div className="total-unique-answers-label">Completes</div>
                                                            {/* <div className="total-unique-answers">{((setValue?.ans)?.map(e => e.Answer).reduce((a, b) => a + b))}</div> */}
                                                            <div className="total-unique-answers"> {setValue?.ans?.length > 0 ? setValue.ans.map(e => e.Answer).reduce((a, b) => a + b, 0) : 0}</div>
                                                            <div className="clearer" />
                                                        </div>
                                                    </div>
                                                </div>
                                                {setValue.graph && setValue.graph === 'chart' && (<div className='d-flex justify-content-center'> <ReactApexChart
                                                    options={setValue.chartData ? setValue.chartData.options : []}
                                                    series={setValue.chartData ? setValue.chartData.series : []}
                                                    type="bar"
                                                    width="500"
                                                />
                                                </div>)}
                                                {setValue.graph && setValue.graph === 'pichart' && (<div className='d-flex justify-content-center'><ReactApexChart options={setValue.picData?.options} series={setValue.picData?.series} type="pie" width={380} /> </div>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div></> : ""))}

                    {/* Matrix Question 2 */}
                    {newMatrix && newMatrix?.map((matrix) => (
                        <div className='container-fluid allQueRport py-3 mt-2 earnSec'>


                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="with-simple-view panel panel-default" style={{ opacity: 1 }}>
                                        <div className="panel-heading">
                                            <div>
                                                <div className="result-title">
                                                    <div className="question-type result-title__type">Matrix, Single Selection</div>
                                                    <div className="clearfix">
                                                        <div className="result-title__question--overflow-hidden">
                                                            <div>
                                                                <h4 className="result-title__question">
                                                                    <span className="result-title__question-label"></span>
                                                                    {matrix.question}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearer" />
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="matrix">
                                                <div className="react-bs-table-container matrix-table">
                                                    <div className="react-bs-table react-bs-table-bordered" style={{ height: '100%' }}>
                                                        <div className="react-bs-container-header table-header-wrapper">
                                                            <table className="table table-hover table-bordered ">
                                                                <colgroup>
                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                    <col style={{ width: '120px', minWidth: '120px' }} />
                                                                    <col style={{ display: 'none' }} />
                                                                    <col />
                                                                    <col />
                                                                    <col />
                                                                    <col />
                                                                    <col />
                                                                    <col style={{ display: 'none' }} />
                                                                </colgroup>
                                                                <thead>
                                                                    <tr>
                                                                        <th className="text-title" data-is-only-head="false" title="#" data-field="ai" style={{ textAlign: 'center' }}>#
                                                                            <div />
                                                                        </th>
                                                                        <th className="text-title" data-is-only-head="false" title="Statements" data-field="body" style={{ textAlign: 'left' }}>Statements
                                                                            <div />
                                                                        </th>
                                                                        {matrix && (matrix.matOption).map(e => (
                                                                            <th className="text-title" data-is-only-head="false" title="Statements" data-field="body" style={{ textAlign: 'left' }}>{e}
                                                                                <div />
                                                                            </th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                            </table>
                                                        </div>
                                                        <div className="react-bs-container-body" style={{ height: '100%' }}>
                                                            <table className="table table-bordered table-hover">
                                                                <colgroup>
                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                    <col style={{ width: '120px', minWidth: '120px' }} />
                                                                    <col style={{ display: 'none' }} />
                                                                    <col />
                                                                    <col />
                                                                    <col />
                                                                    <col />
                                                                    <col />
                                                                    <col style={{ display: 'none' }} />
                                                                </colgroup>
                                                                <tbody>

                                                                    {matrix && (matrix.options).map((e, Index) => (
                                                                        <tr className>
                                                                            <td tabIndex={1} className="indicator" style={{ textAlign: 'center' }}>A {Index + 1}</td>
                                                                            <td tabIndex={2} className="text" style={{ textAlign: 'left' }}>
                                                                                <div className="flex">
                                                                                    <span className="flex-1">{e.option}</span>
                                                                                </div>
                                                                            </td>

                                                                            {(matrix.matOption).map(q => (
                                                                                <td tabIndex={4} className style={{ textAlign: 'left' }}>

                                                                                    <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                        <div className="values">
                                                                                            <div>

                                                                                                <div className="count">{matrix?.matReport[e?.option] !== undefined ? matrix?.matReport[e?.option][q] : '' ? matrix.matReport[e?.option][q] : '0'}</div>

                                                                                                <div className="percent">( <span className>{((matrix?.matReport[e?.option] !== undefined ? matrix?.matReport[e?.option][q] : '' ? matrix.matReport[e?.option][q] : '0') * 100 / matrixResponse.length).toFixed(2)}</span> % escratch) </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>))}
                                                                        </tr>))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="s-alert-wrapper" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-footer">
                                            <div className="row">
                                                <div className="available-charts col-sm-4">
                                                    <div>
                                                        {/* <button type="button" className="chart-type chart-type-btn">
                                                            <i className="bi bi-bar-chart-steps"></i>
                                                        </button> */}
                                                        {/* <button type="button" className="score-points-top-btn">Show Top 2 Box Reporting</button> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="result-footer">
                                                        <div className="total-unique-answers-label">Completes</div>
                                                        <div className="total-unique-answers">{matrixResponse?.length}</div>
                                                        <div className="clearer" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* Free Text Question 3 */}
                    {words && words.map(e => (
                        <div className="container-fluid allQueRport py-3 mt-2 earnSec">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="panel panel-default" style={{ opacity: 1 }}>
                                        <div className="panel-heading">
                                            <div>
                                                <div className="result-title">
                                                    <div className="question-type result-title__type">Free Text</div>
                                                    <div className="clearfix">
                                                        <div className="result-title__question--overflow-hidden">
                                                            <div>
                                                                <h4 className="result-title__question">
                                                                    <span className="result-title__question-label"></span>{e?.question}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearer" />
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div>
                                                <div className="react-bs-table-container">
                                                    <div className="react-bs-table react-bs-table-bordered" style={{ height: '100%' }}>
                                                        <div className="react-bs-container-header table-header-wrapper">
                                                            <ReactWordcloud
                                                                options={options}
                                                                words={e.Ans} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                    {newAnswer && newAnswer?.map(e => (

                        <div className="container-fluid allQueRport py-3 mt-2 earnSec">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="panel panel-default" style={{ opacity: 1 }}>
                                        <div className="panel-heading">
                                            <div>
                                                <div className="result-title">
                                                    <div className="question-type result-title__type">Form Type</div>
                                                    <div className="clearfix">
                                                        <div className="result-title__question--overflow-hidden">
                                                            <div>
                                                                <h4 className="result-title__question">
                                                                    <span className="result-title__question-label"></span>{e?.option}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="clearer" />
                                            </div>
                                        </div>
                                        <div className="panel-body">
                                            <div>
                                                <div className="react-bs-table-container">
                                                    <div className="react-bs-table react-bs-table-bordered" style={{ height: '100%' }}>
                                                        <div className="react-bs-container-header table-header-wrapper">
                                                            <ReactWordcloud
                                                                // callbacks={callbacks}
                                                                options={options}
                                                                words={e.Ans} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='container-fluid py-3 mt-2 earnSec'>
                        <div className='row'>
                            <div className='col-lg-12'>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        </div>
    )
}

export default ReportScreen