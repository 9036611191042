import React from "react";

const UploadExcel = () => {

    return (
        <form>
            <spam>Survey Id</spam>
            <input type="text" /><br />
            <input type='file'></input>
        </form>
    )
}
export default UploadExcel