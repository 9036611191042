import { Link } from 'react-router-dom'
import React from 'react'
import { useState } from "react";
import { useHistory } from 'react-router-dom';
import API from '../../utils/apiCalling';
// import config from '../../utils/apiUrl';
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { FB_APP_ID, GOOGLE_CLIENT_ID, MICROSOFT_CLIENT_ID } from './SocialKey';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from '../../App';
import { useEffect, useRef } from 'react';
import Loader from '../../utils/loader';
import ForgetPassword from './forgetPassword';
import firebase from '../../utils/notification'
import Config from '../../config/env'
import { useCookies } from 'react-cookie';
import { Button, Select, Form, Input, TextArea } from 'antd';


function Login(props) {
     let api = new API()
     let openPopup = useRef()
     let closePopup = useRef(null)
     const [email, setEmail] = useState("");
     const [password, setPassword] = useState("");
     const history = new useHistory();
     const [loginAs, setLoginAs] = useState("panelist")
     const [loader, setLoader] = useState(false)
     const [pasword, setPasword] = useState({ newPassword: "", confirmPassword: "" })
     const [MemberDetails, setMemberDetails] = useState("")
     const [rememberMe, setRememberMe] = useState(false);
     const [cookies, setCookie, removeCookie] = useCookies(['rememberedPassword']);
     const isOpen = false
     useEffect(() => {
          if (props.match.path == '/:id/setPassword') {
               openPopup.current.click()
               getDetailMember()
               return;
          }
          {
               'Notification' in window &&
                    'serviceWorker' in navigator &&
                    'PushManager' in window && firebase()
          }
          if (localStorage.getItem("user")) {
               history.push("/panelistDash")
          } else {
               history.push('/login')
          }
     }, [])

     const getDetailMember = async () => {
          let data = {
               _id: props.match.params.id,

          }
          let result = await api.post(`${Config.new.site_api}/client/getDetailsOfMembers`, data)
          if (result.code === 200) {
               setMemberDetails(result.data)
          }
     }

     const resetPassword = async () => {
          if (pasword.newPassword !== pasword.confirmPassword) {
               toast.error("Password and confirm Password doesn't match");
          }
          else {
               let data = {
                    _id: props.match.params.id,
                    password: pasword.confirmPassword,
                    email: MemberDetails[0].email
               }
               let result = await api.post(`${Config.new.site_api}/client/updateClientMembersPassword`, data)
               if (result.code === 200) {
                    toast.success("Your Password  updated Succefully ", {
                         position: toast.POSITION.TOP_RIGHT,
                    });
                    closePopup.current.click()
               } else {
                    toast.error(result.message, {
                         position: toast.POSITION.TOP_RIGHT,
                    });
               }
          }
     }

     const redirectPage = (type) => {
          // history.push("/client")
          (type == "penelist") ? setLoginAs("penelist") : setLoginAs("client")
          // setLoginAs("client")
     }

     function myFunction() {
          var x = document.getElementById("myInput");
          if (x.type === "password") {
               x.type = "text";
               setTimeout(() => {
                    x.type = "password";
               }, 3000);
          } else {
               x.type = "password";
          }
     }


     const handleLogin = async () => {

          let deviceId = localStorage.getItem("deviceId")
          setLoader(true)
          let data = {
               email: email,
               password: password,
               deviceId: deviceId
          }

          let result = await fetch(`${Config.new.site_api}/client/login`, {
               method: 'post',
               body: JSON.stringify(data),
               headers: { 'content-Type': 'application/json' },
          });
          result = await result.json();
          if (result.code === 200) {
               toast.success('Logged In Succefully', {
                    position: toast.POSITION.TOP_RIGHT
               });


               localStorage.setItem("user", JSON.stringify(result.data));
               localStorage.setItem("token", JSON.stringify(result.token));
               setLoader(false)
               if (result.data.userType === "admin") {
                    history.push("/panelistDash")
                    localStorage.setItem("adminToken", JSON.stringify(result.data));
               }
               else if (result.data.userType === 'client') {
                    result.data.plan_id ? history.push('/adminDash') : history.push('/plan')
               } else if (result.data.userType === 'panelist') {
                    history.push("/panelistDash")
               } else if (result.data.user_role === 'member') {
                    history.push("/adminDash")
               }
               else if(result.data.userType === 'csat'){
                    history.push('/dashboard');
               }else if(result.data.userType === 'UDS'){
                    history.push('/dashboardReport');
               }
          } if (result.code !== 200) {
               if (result.message == "email must be a valid email") {
                    toast.error("Email must be a valid email", {
                         position: toast.POSITION.TOP_RIGHT
                    });
               } else {
                    toast.error(result.message, {
                         position: toast.POSITION.TOP_RIGHT
                    });
               }
               setLoader(false)
          }

          if (rememberMe) {
               setCookie('rememberedPassword', 'tokenOrIdentifier', { path: '/' });
          } else {
               removeCookie('rememberedPassword', { path: '/' });
          }
          setPassword('');

     }
     //Login with google start
     const responseGoogle = async (response) => {

          const registredwith = {
               registredId: response.googleId,
               email: response.profileObj.email
          }

          let isRegistred = await isLoginSubmit(registredwith)

          if (isRegistred == undefined) {
               toast.error('User not registered please signup now!', {
                    position: toast.POSITION.TOP_RIGHT
               });

          }

     }

     const responseGoogleFailed = () => {
       ;
     }



     const isLoginSubmit = async (registredId) => {

          // let result = await api.post('http://localhost:3002/client/isRegistred', registredId)
          let result = await fetch(`${Config.new.site_api}/client/isRegistred`, {
               method: 'post',
               body: JSON.stringify(registredId),
               headers: { 'content-Type': 'application/json' },
          });
          result = await result.json();

          if (result.code === 200) {

               localStorage.setItem("user", JSON.stringify(result.data));
               localStorage.setItem("token", JSON.stringify(result.token));
               if (result.data.userType === "client") {
                    history.push("/client")
               } else {
                    history.push("/panelistDash")
               }
               return "success"
          } else {

               toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
               });
               return
          }
     }
     //login with google end

     //login with facebook start
     const responseFacebook = async (response) => {


          const registredwith = {
               registredId: response.userID,
               email: response.email
          }

          let isRegistred = await isLoginSubmit(registredwith)
          if (isRegistred == undefined) {
               //   toast.warning("User not registered please signup now!")
               alert("User not registered please signup now!")
          }
     }
     const responseFacebookFailed = () => {

     }



     return (
          <>
               {/* <div class="modal fade" id="TelephonicModel" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered size">
                    <div class="modal-content">
                        <div class="modal-body ">

                            <div class="contents text-center">
                                <h4 style={{ color: "#ff6a00" }} class="text-center fs-3">Success</h4>
                                <p class="w-75 text-center mx-auto" style={{ fontSize: "25px" }}>Our Team Will Contact You</p>

                            </div>
                            <div class="goprofile text-center">
                                <button class="border-0 text-white px-5 py-2 rounded-1" style={{ backgroundColor: "#ff6a00" }}>Go to Profile</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
               <div
                    data-bs-toggle="modal"
                    data-bs-target="#demoThisVart"
                    ref={openPopup}
               />
               <div
                    className="modal fade"
                    id="demoThisVart"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"

               >
                    <div className="modal-dialog">
                         <div className="modal-content">
                              <div className="modal-header align-items-center">
                                   <h1 className="modal-title m-0 text-orange" id="exampleModalLabel">
                                        Set Password
                                   </h1>
                                   <button
                                        type="button"
                                        className="btn-close"
                                        ref={closePopup}
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                   ></button>
                              </div>
                              <div className="modal-body">
                                   <Form
                                        name="normal_login"
                                        className="login-form d-flex justify-content-center"
                                        initialValues={{ remember: true }}
                                        onFinish={resetPassword}

                                        autoComplete="off"

                                   >
                                        <div className="row dattimesha w-50">
                                             <div className="col-md-6  site-form-item-icon">



                                             </div>
                                             <div className="col-md-6">



                                             </div>
                                             <div className="col-md-12 site-form-item-icon">
                                                  <label for="birthday" className="fw-4 text-blue">
                                                       Password:
                                                  </label>
                                                  <Form.Item
                                                       name="completes" rules={[{
                                                            required: true,
                                                            pattern: new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                                                            message: "Password must be at least 8 characters and must contain at least one lower case letter, one upper case letter and one digit and one special character"
                                                       }]}
                                                  >
                                                       <Input
                                                            type="password"
                                                            id="Demo"
                                                            className="form-control mb-3 site-form-item-icon"
                                                            value={pasword.newPassword}
                                                            onChange={(e) =>
                                                                 setPasword({ ...pasword, newPassword: e.target.value })
                                                            }

                                                            name="password"
                                                            style={{ width: "100%", height: "40px" }}

                                                       /></Form.Item>


                                             </div>
                                             <div className="col-md-12 site-form-item-icon">
                                                  <label for="birthday" className="fw-4 text-blue">
                                                       Confirm Password:
                                                  </label>

                                                  <Input
                                                       type="password"
                                                       id="Demo"
                                                       className="form-control mb-3 site-form-item-icon"
                                                       value={pasword.confirmPassword}
                                                       onChange={(e) =>
                                                            setPasword({ ...pasword, confirmPassword: e.target.value })
                                                       }
                                                       name="password"
                                                       style={{ width: "100%", height: "40px" }}
                                                  />


                                             </div>
                                             <div className="col-md-12 site-form-item-icon">




                                                  <Button

                                                       type="submit" htmlType="submit"
                                                       className="mainbtn text-white login-form-button"
                                                  >
                                                       Submit
                                                  </Button>


                                             </div>
                                        </div>
                                   </Form>
                              </div>
                         </div>
                    </div>
               </div>

               <main>
                    {loader && <Loader />}
                    <section className="loginSec">
                         <div className="container text-center h-100">
                              <div className="row justify-content-center">
                                   <div className="col-md-6 col-lg-5">
                                        <div className="bg-white loginBox">
                                             <div className='logoBox w-100 text-center'>
                                                  <Link to='/'>  <img src="../images/new-logo.png" alt width="140px" className='mb-0' style={{ float: "inherit" }} /></Link>
                                             </div><br></br>
                                             {/* <div className='d-flex p-2' style={{ justifyContent: "space-around" }}>
                                                  <h1 className="loginHeading text-left fs-6 mb-4 colorBluee" onClick={()=>setLoginAs("panelist")}>
                                                       Login as &nbsp;<strong className=" fs-5 text-orange">Panelist</strong>
                                                  </h1>
                                                  <i class="bi bi-arrow-left-right fw-bold fs-2" style={{ color: "#f47321" }}></i>
                                                  <h1 className="loginHeading text-left fs-6 mb-4 colorBluee" onClick={()=>setLoginAs("client")}>
                                                       Login as &nbsp;<strong className="fs-5 text-orange" >Client</strong>

                                                  </h1>
                                             </div> */}
                                             <form>
                                                  <div className="input-group mb-4">
                                                       <span className="input-group-text"><i className="fa fa-envelope" /></span>
                                                       <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter Your Email" name="email" value={email} />
                                                  </div>
                                                  <div className="input-group mb-4">
                                                       <span className="input-group-text"><i className="fa fa-lock" /></span>
                                                       <input type="password" onChange={(e) => setPassword(e.target.value)} onKeyPress={(e) => {
                                                            if (e.key === "Enter") {
                                                                 handleLogin();
                                                            }
                                                       }} className="form-control" placeholder="Password" name="password" value={password} id="myInput" />
                                                       <span className="input-group-text eyePass" ><i className="fa fa-eye" style={{cursor:"pointer"}} onClick={(e) => myFunction()} /></span>
                                                  </div>
                                                  <div className="d-flex mb-4 remberMe">
                                                       <div className="checkbox">
                                                            <label><input type="checkbox"
                                                                 checked={rememberMe}
                                                                 onChange={(e) => setRememberMe(e.target.checked)}
                                                            /> Remember me</label>
                                                       </div>

                                                       <a
                                                            data-bs-toggle="modal" href="#forgetPassword"
                                                      >Forgot Password</a>

                                                  </div>
                                                  <button type="button" onClick={handleLogin} className="mainbtnblue tada w-100">Log In</button>
                                             </form>
                                             <div className="d-flex btnLogType loginGoogleBtn my-4 flex-wrap justify-content-center">
                                                  {/* <button type="button" class="kep-login-google metro"><i class="fa fa-google text-primary new"></i> Google</button> */}
                                                  <GoogleLogin
                                                       // className="kep-login-google metro"
                                                       type='button'
                                                       icon={false}
                                                       clientId={GOOGLE_CLIENT_ID}
                                                       onSuccess={responseGoogle}
                                                       onFailure={responseGoogleFailed}
                                                  >
                                                       <i className="fa fa-google text-primary new" style={{ fontWeight: "600" }}> </i> Google
                                                  </GoogleLogin>
                                                  <FacebookLogin
                                                       fields="name,email,picture"
                                                       // cssClass=" fa fa-facebook-official mainbtn tada"
                                                       textButton=" Facebook"
                                                       // className="mainbtn tada"
                                                       appId={FB_APP_ID}
                                                       autoLoad={false}
                                                       icon="fa-facebook text-primary new"
                                                       callback={responseFacebook}
                                                       onFailure={responseFacebookFailed}
                                                       status='connected'
                                                  >
                                                       <i className="fa fa-facebook-official " > </i>
                                                  </FacebookLogin>
                                             </div>
                                             <p className='signup-btm text-center my-3'>If you are not a user <a href="/#/signup">Sign Up</a></p>
                                             {/* <p className="mb-0">Don't Have An Account? <Link to='/signup'><a href="#">Signup</a> </Link></p> */}
                                        </div>


                                   </div>
                              </div>
                         </div>
                         <ForgetPassword isSet={isOpen} />
                    </section>
                    <ToastContainer />


               </main>

          </>

     )
}

export default Login;