import React from "react";
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Loader from '../utils/loader'
import API from '../utils/apiCalling';
import Config from "../config/env";

const CrossTab = () => {
  const api = new API()
  const { id } = useParams()
  console.log("hellll",id)
  const [ID, setId] = useState(id)
  const [loader, serLoader] = useState(false)
  const [crossData, setCrossData] = useState([])
  const [surveyName, setSurveyName] = useState('')
  const [rowValue, setRowVallue] = useState([])
  console.log("rowValue",rowValue)
  const [colValue, setColVallue] = useState([])
  console.log("colValue",colValue)
  const [total, setTotal] = useState()
 

  useEffect(() => {
    getSurveyData()
  }, [])

  const getSurveyData = async () => {
    let data = {
      surveyId: id
    }
    let result = await api.post(`${Config.new.site_api}/client-survey/Dashboard`, data)
    if (result.code === 200) {
      serLoader(true)
      setCrossData(result.data)
      setTotal((result.data).length)
      setSurveyName(result.data.length>0&&result.data[0].surveyName)
    }
  }
  const setRow = (id) => {
    // setRowVallue(((crossData[0]?.questions).filter((e) => e._id === id))[0])
    let valueQuestion = (((crossData[0]?.questions).filter((e) => e._id === id))[0])
    let setValue = ((crossData[0]?.questions).filter((e) => e._id === id))[0].options
    let Question = crossData?.map(e => { return (e.questions).filter(q => q._id === id) })
    let Answer = Question?.map(e => e[0]?.answer)
    let Option = Question[0]?.map(e => (e.options))
    let newArray = []
    Option[0]?.map((e) => {
      let sum = 0
      Answer?.map((q) => {
        if (q) {
          if (Array.isArray(q)) {
            if (e.option == q[0]) {
              return sum = sum + 1
            }
          } else if (e.option.toLowerCase() == 'other') {
            return sum = sum + 1
          }
        }
      })
      newArray.push({ "Option": e.option, "Answer": sum })
    })

    let temp = []
    setValue?.map((e) => {
      newArray?.map((q) => {
        if (e.option == q.Option) {
          return temp.push(e.sum = q.Answer)
        }
      })
    })
    setRowVallue(valueQuestion)
  }
  const setCol = (id) => {
    // setColVallue(((crossData[0]?.questions).filter((e) => e._id === id))[0])
    let valueQuestion = (((crossData[0]?.questions)?.filter((e) => e._id === id))[0])
    let setValue = ((crossData[0]?.questions)?.filter((e) => e._id === id))[0].options
    let Question = crossData?.map(e => { return (e.questions).filter(q => q._id === id) })
    let Answer = Question?.map(e => e[0]?.answer)
    let Option = Question[0]?.map(e => (e.options))
    let newArray = []
    Option[0]?.map((e) => {
      let sum = 0
      Answer?.map((q) => {
        if (q) {
          if (Array.isArray(q)) {
            if (e.option == q[0]) {
              return sum = sum + 1
            }
          } else if (e.option.toLowerCase() == 'other') {
            return sum = sum + 1
          }
        }
      })
      newArray.push({ "Option": e.option, "Answer": sum })
    })
    let temp = []
    setValue?.map((e) => {
      newArray?.map((q) => {
        if (e.option == q.Option) {
          return temp.push(e.sum = q.Answer)
        }
      })
    })
    setColVallue(valueQuestion)
  }


  return (
    <>
      {loader !== true ? <Loader /> :
        <section className="mt-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="w-100 py-4 bg-white topCustomerSec px-3 mb-3">
                  <div className="row align-items-center">
                    <div className="col-md-4 col-lg-3 col-xl-4">
                      <h4 className="mt-0"><span className="sub-head">Cross</span> Tabulation</h4>
                      <p className="mb-0 textCross"><span>Survey :</span> {surveyName}</p>
                    </div>
                    <div className="col-md-8 col-lg-9 col-xl-8">
                      <div className="row">
                        <div className="col-md-6 col-lg-8 col-xl-6">
                          <div className="d-flex linkSec">
                            <h6 className="mb-0 mt-0">Sample Survey :</h6>
                            <div className="d-flex alignLiknBox w-100">
                              <p className="mb-0">
                              </p>
                              <a href="javascript:void(0);"><i className="fa fa-link" aria-hidden="true"></i></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-xl-6">
                          <div className="d-flex mainOptionSelection">
                            <div className="dropdownnma w-100 dropQsRight">
                              <input type="checkbox" className="dropdown__switch" id="filter-switch" hidden />
                              <label htmlFor="filter-switch" className="dropdown__options-filter">
                                <ul className="mb-0 dropdown__filter" role="listbox" tabIndex="-1">
                                  <li className="dropdown-toggle1 dropDownCrssoBtn w-100">
                                    <span><i className="fa fa-list-ul mr-2" aria-hidden="true"></i> Option </span>
                                    <i className="fa fa-sort-desc" aria-hidden="true"></i>

                                  </li>
                                  <ul className="dropdown__select p-2">
                                    <li>
                                      <label className="custLbl border-0">Row Percentage
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                      </label>
                                    </li>
                                    <li>
                                      <label className="custLbl border-0">Column Percentage
                                        <input type="checkbox" />
                                        <span className="checkmark"></span>
                                      </label>
                                    </li>
                                    <li><label className="custLbl border-0">Column Percentage(Base N)
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                    </label></li>
                                    <li><label className="custLbl border-0">Row Mean
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                    </label></li>
                                    <li><label className="custLbl border-0">Row Percentage
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                    </label></li>
                                    <li><label className="custLbl border-0">Row Percentage
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                    </label></li>
                                  </ul>
                                </ul>
                              </label>

                            </div>
                            <div className="exlSec d-flex">
                              <a href="javascript:void(0);" className="tooltip">
                                <i className="fa fa-file-text"></i>
                                <sapn className="tooltiptext">Export to Excel</sapn>
                              </a>
                              <a href="javascript:void(0);" className="tooltip">
                                <i className="fa fa-file-text"></i>
                                <sapn className="tooltiptext">Export to Excel</sapn>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="w-100 tabulationSec">
                  <div className="row">
                    <div className="col-md-4 col-lg-4 col-xl-3">
                      <div className="crossSeetingSec w-100 py-3 h-100">
                        <h5><a href="javascript:void(0);" onClick="ShowCrossSetting()"><i className="fa fa-close mr-2"></i> Report Settings</a></h5>
                        <div className="tabSettingBox">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <a className="nav-link active" data-bs-toggle="tab" href="#row">Rows</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-bs-toggle="tab" href="#clm">Columns</a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" data-bs-toggle="tab" href="#fltr">Filters</a>
                            </li>
                          </ul>
                          {crossData &&
                            <div className="tab-content">
                              <div id="row" className=" tab-pane active">
                                <div className="dropdownnma w-100 dropQsRight pt-3 pb-1">
                                  <input type="checkbox" className="dropdown__switch" id="filter-switch1" hidden />
                                  <label htmlFor="filter-switch1" className="dropdown__options-filter">
                                    <ul className="mb-0 dropdown__filter" role="listbox" tabIndex="-1">
                                      <li className="dropdown-toggle1 dropDownCrssoBtn w-100">
                                        <span>Add Question <span className="plusCrossOp">+</span></span>
                                        <span>2</span>
                                      </li>

                                      <ul className="dropdown__select p-2">
                                        <li><label className="custLbl border-0">Select All
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        </li>
                                        <li>
                                          <label className="custLbl border-0">[Q1] Gender
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                          </label>
                                        </li>
                                        <li>
                                          <label className="custLbl border-0">[Q4]What is the operating...
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                          </label>
                                        </li>
                                        <li>
                                          <label className="custLbl border-0">[Q7] Price
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                          </label>
                                        </li>
                                      </ul>

                                    </ul>
                                  </label>

                                </div>

                                <div className="selectQsCross w-100">
                                  <h6 className="mb-2 mt-0 d-flex">{rowValue?.question} <a href="#"><i className="fa fa-trash"></i></a></h6>
                                  <label className="mb-1">Drilldown</label>
                                  <select className="form-select" onChange={(e) => setRow(e.target.value)}>
                                    <option hidden>-Select-</option>
                                    {(crossData[0]?.questions)?.map((e) => {
                                      return <option value={e._id}>{e.question}</option>
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div id="clm" className=" tab-pane fade">
                                <div className="dropdownnma w-100 dropQsRight pt-3 pb-1">
                                  <input type="checkbox" className="dropdown__switch" id="filter-switch2" hidden />
                                  <label htmlFor="filter-switch2" className="dropdown__options-filter">
                                    <ul className="mb-0 dropdown__filter" role="listbox" tabIndex="-1">
                                      <li className="dropdown-toggle1 dropDownCrssoBtn w-100">
                                        <span>Add Question <span className="plusCrossOp">+</span></span>
                                        <span>2</span>
                                      </li>

                                      <ul className="dropdown__select p-2">
                                        <li><label className="custLbl border-0">Select All
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        </li>
                                        <li>
                                          <label className="custLbl border-0">[Q1] Gender
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                          </label>
                                        </li>
                                        <li>
                                          <label className="custLbl border-0">[Q4]What is the operating...
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                          </label>
                                        </li>
                                        <li>
                                          <label className="custLbl border-0">[Q7] Price
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                          </label>
                                        </li>
                                      </ul>

                                    </ul>
                                  </label>

                                </div>
                                <div className="selectQsCross w-100">
                                  <h6 className="mb-2 mt-0 d-flex">{colValue?.question} <a href="#"><i className="fa fa-trash"></i></a></h6>
                                  <label className="mb-1">Drilldown</label>
                                  <select className="form-select" onChange={(e) => setCol(e.target.value)}>
                                    <option hidden>-Select-</option>
                                    {(crossData[0]?.questions)?.map((e) => {
                                      return <option value={e._id}>{e.question}</option>
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div id="fltr" className=" tab-pane fade">
                                <div className="dropdownnma w-100 dropQsRight pt-3 pb-1">
                                  <input type="checkbox" className="dropdown__switch" id="filter-switch3" hidden />
                                  <label htmlFor="filter-switch3" className="dropdown__options-filter">
                                    <ul className="mb-0 dropdown__filter" role="listbox" tabIndex="-1">
                                      <li className="dropdown-toggle1 dropDownCrssoBtn w-100">
                                        <span>Filters <span className="plusCrossOp">+</span></span>
                                        <span>2</span>
                                      </li>

                                      <ul className="dropdown__select p-2">
                                        <li className="mb-2"><b>Questions</b></li>
                                        <li><label className="custLbl border-0">Select All
                                          <input type="checkbox" />
                                          <span className="checkmark"></span>
                                        </label>
                                        </li>
                                        <li>
                                          <label className="custLbl border-0">[Q1] Gender
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                          </label>
                                        </li>
                                        <li>
                                          <label className="custLbl border-0">[Q4]What is the operating...
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                          </label>
                                        </li>
                                        <li>
                                          <label className="custLbl border-0">[Q7] Price
                                            <input type="checkbox" />
                                            <span className="checkmark"></span>
                                          </label>
                                        </li>
                                      </ul>

                                    </ul>
                                  </label>

                                </div>
                                <div className="selectQsCross w-100">
                                  <h6 className="mb-2">[Q4] What is the operating system for your phone?<a href="#"><i className="fa fa-trash"></i></a></h6>
                                  <p className="filtergryTxt mb-1">Filter By:</p>
                                  <div className="filterCheckPs">
                                    <label className="custLbl border-0">Apple (iOS)
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                    </label>
                                    <label className="custLbl border-0">Android
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                    </label>
                                    <label className="custLbl border-0">Windows
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                    </label>
                                    <label className="custLbl border-0">Blackberry
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                    </label>
                                    <label className="custLbl border-0">Other
                                      <input type="checkbox" />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 col-lg-8 col-xl-9">
                      <div className="tableViewCross px-2 mt-5 py-4">
                        <div className="w-100 table-responsive tableCross h-100">
                          <table className="w-100 text-center">
                            <tbody>
                              <tr>
                                <td colSpan="2"></td>
                                <td colSpan={(((colValue?.options)?.map((w) => (w?.option)))?.length)}>{colValue?.question}</td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                {(colValue?.options)?.map((e) => {
                                  return <td >{e?.option}</td>
                                })}
                              </tr>

                              <td className="tabind" rowSpan={(((rowValue?.options)?.map((w) => (w?.option)))?.length + 1)}>{rowValue?.question}</td>
                              {(rowValue?.options)?.map((e) => {
                                return <tr><td >{e?.option}</td>
                                  {(colValue?.options)?.map((q) => (
                                    <td className="lowTd"><div className="textCrsTb">
                                      <p>{e.sum + q.sum}</p>
                                      <p>{(((e.sum + q.sum) * 100) / total).toFixed(2)}%</p>
                                    </div>
                                    </td>
                                  ))} </tr>
                              })}

                              {/* <td>1</td>
                            <td><div className="textCrsTb">
                              <p>y</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>y</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>y</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>y</p>
                              <p>0.00%</p>
                            </div></td>
                            <td className="lowTd"><div className="textCrsTb">
                              <p>y</p>
                              <p>0.00%</p>
                            </div></td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td><div className="textCrsTb">
                              <p>x</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td className="highTd"><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td className="highTd"><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td className="lowTd"><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td className="lowTd"><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td className="highTd"><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td className="highTd"><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td className="highTd"><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td className="lowTd"><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                            <td><div className="textCrsTb">
                              <p>0</p>
                              <p>0.00%</p>
                            </div></td>
                          </tr> */}

                              <tr>
                                <td></td>
                                {/* <td>Total</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td> */}

                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tableViewCross px-2 mt-5 py-4 d-none">
                        <div className="w-100 table-responsive tableCross h-100">
                          <table className="w-100 text-center">
                            <tbody>
                              <tr>
                                <td colSpan="2"></td>
                                <td colSpan="5">[Q4] What is the operating system for your phone?</td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                <td >Apple(iOS)</td>
                                <td >Android</td>
                                <td >Windows</td>
                                <td >Blackberry</td>
                                <td className="bg-lightGray" >Other</td>
                              </tr>
                              <tr>
                                <td colSpan="2" className="bg-transprent"></td>
                                <td colSpan="5" className="bg-darkBlue">Gender</td>
                              </tr>
                              <tr>
                                <td colSpan="2" className="bg-transprent"></td>
                                <td className="bg-lightGray">Male</td>
                                <td className="bg-lightGray">Female</td>
                                <td className="bg-lightGray">Male</td>
                                <td className="bg-lightGray">Female</td>
                                <td className="bg-lightGray">Male</td>
                                <td>Total</td>
                              </tr>
                              <tr>
                                <td rowSpan="5" className="bg-darkBlue">[Q2]Price</td>
                                <td className="bg-lightGray">1</td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>

                                <td>3</td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>

                                <td>4</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>

                                <td>5</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Total</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>3</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tableViewCross px-2 mt-5 py-4 d-none">
                        <div className="w-100 table-responsive tableCross h-100">
                          <table className="w-100 text-center">
                            <tbody>
                              <tr>
                                <td colSpan="2"></td>
                                <td colSpan="5">[Q4] What is the operating system for your phone?</td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                <td >Apple(iOS)</td>
                                <td >Android</td>
                                <td >Windows</td>
                                <td >Blackberry</td>
                                <td className="bg-lightGray" >Other</td>
                                <td >Total</td>
                              </tr>

                              <tr>
                                <td rowSpan="5" >
                                  <div className="d-flex priceWithGender">[Q2]Price <div className="ppTextCount bg-white">
                                    <p>1</p>
                                    <p>2</p>
                                    <p>3</p>
                                    <p>4</p>
                                    <p>5</p>
                                  </div>Gender</div>
                                </td>
                                <td className="bg-lightGray">Male</td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>Female</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>Male</td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>Female</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>Male</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Total</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>3</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tableViewCross px-2 mt-5 py-4 d-none">
                        <div className="w-100 table-responsive tableCross h-100">
                          <table className="w-100 text-center">
                            <tbody>
                              <tr>
                                <td colSpan="2"></td>
                                <td colSpan="5">[Q4] What is the operating system for your phone?</td>
                              </tr>
                              <tr>
                                <td colSpan="2"></td>
                                <td >Apple(iOS)</td>
                                <td >Android</td>
                                <td >Windows</td>
                                <td >Blackberry</td>
                                <td className="bg-lightGray" >Other</td>
                                <td >Total</td>
                              </tr>
                              <tr>
                                <td rowSpan="5">
                                  [Q2]Price
                                </td>
                                <td className="bg-lightGray">1</td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>4</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>5</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td className="bg-transprent"></td>
                                <td className="bg-lightBlue">Total</td>
                                <td className="bg-lightBlue">1</td>
                                <td className="bg-lightBlue">2</td>
                                <td className="bg-lightBlue">3</td>
                                <td className="bg-lightBlue">4</td>
                                <td className="bg-lightBlue">5</td>
                                <td className="bg-lightBlue">3</td>
                              </tr>
                              <tr>
                                <td rowSpan="5" className="bg-darkBlue">
                                  Gender
                                </td>
                                <td className="bg-lightGray">4</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td>5</td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="highTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td className="lowTd"><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                                <td><div className="textCrsTb">
                                  <p>0</p>
                                  <p>0.00%</p>
                                </div></td>
                              </tr>
                              <tr>
                                <td className="bg-lightBlue">Total</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>3</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default CrossTab;