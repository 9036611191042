import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../utils/loader";
import Config from '../config/env'

export function RawReport() {
  let params = useParams();

  const [loader, setLoader] = useState(false)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  async function download() {

    let data = {
      survey_id: params.id,
      startDate: startDate,
      endDate: endDate
    };

    setLoader(true);

    try {
      const response = await fetch(`${Config.new.site_api}/admin-dashboard/getSurveyWiseOnlyResponseById`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
      if (response.ok) {
        setLoader(false);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'survey_responses.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        setLoader(false);
        console.error('Failed to download file');
      }
    } catch (error) {
      setLoader(false);
      console.error('Error:', error);
    }

  }


  return (<>
    {loader && <Loader />}

    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-1">
          <label>From</label>
        </div>
        <div className="col-md-5">
          <input
            type="date"
            className="form-control"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-md-1">
          <label>To</label>
        </div>
        <div className="col-md-5">
          <input
            type="date"
            className="form-control"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
    </div>



    <div className="container ">
      <button className="btn btn-primary w-100 " onClick={download}>Download Raw Data</button>
    </div>
  </>)

} 