import { useState,useEffect } from "react";
import API from "../../utils/apiCalling";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Config from "../../config/env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

const Header2 = () => {
    const history = new useHistory();
    const api =new API()
    const [notifications, setNotifications] = useState([]);
    const [notifyCount, setNotifyCount] = useState()
    const [rewardspillclose, setActive] = useState(true);
    const [user, setUserData] = useState("");
    const [adminButton, setAdminButton] = useState();

    useEffect(() => {
        window.scroll(0, 0);
        const userData = JSON.parse(localStorage.getItem("user"));
        setUserData(userData);
        // userData.userType === "admin"
        //   ? setAdminButton("admin"): 
          setAdminButton(userData?.userType);
        // setEmail(userData.email);
        // setUserName(userData.firstName);
        // setReferCode(userData.referCode);
    
        getNotifications(userData);
        // getCompletedSurvey();
        // getDashboardData();
    
        // getPointsPercent();
      }, []);

  const sliderig = () => {
    setActive(!rewardspillclose);
  };
  let date=[new Date().getFullYear(),new Date().getMonth()+1,new Date().getDay()]
  const logOut = () => {
    swal({
      // html: true,
      title: "Are you sure to LogOut?",
      // text: ``,
      // icon: "",
      buttons: ["No, cancel it!", "Yes"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        localStorage.clear();
        history.push("/login");
      } else {
      }
    });
  };

  const getNotifications = async (userData) => {
    let body = {
      user_id: userData._id,
      page: 1,
      limit: 25,
    };
    let result = await api.post(
      `${Config.new.site_api}/common/notificationList`,
      body
    );
    if (result?.code === 200) {
      setNotifications(result?.data.notifications);
    } else {
      toast.error(result?.message);
    }

    let input = {
      user_id: userData._id,
    };
    let res = await api.post(
      `${Config.new.site_api}/common/totalUnReadCount`,
      input
    );
    if (res?.code === 200) {
      setNotifyCount(res.data.unread_notifications_count);
    } else {
      toast.error(res?.message);
    }
  };
  const readNotification = async (_id) => {
    let data = {
      notification_id: _id,
    };
    let res = await api.post(
      `${Config.new.site_api}/common/notificationReadApi`,
      data
    );
    if (res.code === 200) {
    }
  };
  return (
    <section id="all-section" className="admin-header">
      <nav className="navbar navbar-expand-lg nevigtsao bg-white p-0 border border-bottom">
        <div className="container-fluid ">
        <div className="d-flex justify-content-between align-items-center w-100">
          <a className="navbar-brand1 logo-itemsw" href="#">
            <img src="../images/new-logo.png" width="100px" alt="" />
          </a>
          {/* <button className="dash-ac-btn btn " onClick={sliderig}>
            <svg id="hamburgerIcon">
              <path
                id="stroke1"
                d="M7, 10 L33, 10"
                stroke="#fff"
                stroke-width="3"
              />
              <path
                id="stroke2"
                d="M7, 20 L33, 20"
                stroke="#fff"
                stroke-width="3"
              />
              <path
                id="stroke3"
                d="M7, 30 L33, 30"
                stroke="#fff"
                stroke-width="3"
              />
            </svg>
          </button> */}
          { user?.plan_id &&
          <ul className="navbar-nav width-auto">
            {/* <a
              className="text-white mainbtn mx-3 rede-b-nav"
              id="redeem-btn"
              data-bs-toggle="modal"
              data-bs-target="#redeem-points"
            >
              Redeem Point
            </a> */}
            <div className="nav-item dropdown notification-drop">
              <a
                id="dLabel"
                role="button"
                className="nav-link dropdown-toggle"
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-bell-fill text-blue fs-4 position-relative">
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-circle text-blue f-12 bg-grey">
                    <small className="fw-bold">{notifyCount}</small>
                    <span className="visually-hidden">unread messages</span>
                  </span>
                </i>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-lg-end notifications"
                role="menu"
                aria-labelledby="dLabel"
              >
                <div className="notification-heading">
                  <p className="text-orange m-2 f-14">Notifications</p>
                </div>
                <li className="divider" />
                <div className="notifications-wrapper">
                  {notifications.length &&
                    notifications.map((notify, index) => (
                      <a className="content" key={index}>
                        {notify.read_status == 1 && (
                          <div
                            onClick={""}
                            className="notification-item  unread"
                          >
                            <img src="../images/2.png"></img>
                            <div className="tableform w-100">
                              <p className="text-orange f-14">
                                {notify.title}
                                <span className="float-right f-12">
                                  
                                {(JSON.stringify(date)==JSON.stringify([new Date(notify.created_at).getFullYear(),new Date(notify.created_at).getMonth()+1,new Date(notify.created_at).getDay()]))?new Date(new Date(notify.created_at)*1000).toLocaleTimeString():moment(notify.created_at).format('DD/MM/YY')}
                                </span>
                              </p>
                              <p className="item-info f-12">{notify.message}</p>
                            </div>
                          </div>
                        )}
                        {notify.read_status == 0 && (
                          <div
                            onClick={() => readNotification(notify._id)}
                            className="notification-item  read"
                          >
                            <img src="../images/woman.png"></img>
                            <div className="tableform w-100">
                              <p className="text-orange f-14">
                                {notify.title}
                                <span className="float-right f-12">
                                  
                                {(JSON.stringify(date)==JSON.stringify([new Date(notify.created_at).getFullYear(),new Date(notify.created_at).getMonth()+1,new Date(notify.created_at).getDay()]))?new Date(new Date(notify.created_at)*1000).toLocaleTimeString():moment(notify.created_at).format('DD/MM/YY')}
                                </span>
                              </p>
                              <p className="item-info f-12">{notify.message}</p>
                            </div>
                          </div>
                        )}
                      </a>
                    ))}
                </div>

                {/* <div className="notifications-wrapper">
                                                <a className="content" href="#">
                                                    <div className="notification-item  read">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>

                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                                        </div></div>
                                                </a>
                                                <a className="content" href="#">

                                                    <div className="notification-item  read">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content" href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item  ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                            </div> */}
              </ul>
              <li className="profile-drop nav-item dropdown">
                <a
                  className="nav-link nav dropdown-toggle d-flex align-items-center position-static"
                  href="#profile"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src={
                      user.profileImage
                        ? user.profileImage
                        : user.gender == "female"
                        ? "../images/woman.png"
                        : "../images/man.png"
                    }
                    width="50px"
                    height="50px" 
                    className="rounded-circle"
                    alt=""
                  />
                </a>
                <ul className="dropdown-menu dropdown-menu-lg-end" id="profile">
                {adminButton === "client" && (
                  <li>
                    <a className="dropdown-item" href="/#/adminDash">
                      Dashboard
                    </a>
                  </li>)}
                  {adminButton === "admin" && (
                    <li>
                      <a className="dropdown-item" href="/#/adminDash">
                        Client Dashboard
                      </a>
                    </li>
                  )}
                  {adminButton === "admin" && (
                    <li>
                      <a
                        className="dropdown-item"
                        href="/#/192837465/adminDashboard"
                      >
                        Admin Dashboard
                      </a>
                    </li>
                  )}
                  <li>
                    <a className="dropdown-item" onClick={logOut}>
                      Log Out{" "}
                    </a>
                  </li>
                </ul>
              </li>
            </div>
          </ul>}
          </div>
        </div>
      </nav>
      <ToastContainer/>
    </section>
  );
};
export default Header2;
