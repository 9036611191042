import '../../App.css';

import React from 'react'

function Footer() {
     return (
          <section id="all-section" className="footer bg-pink py-5">
               <div className="container">
                    <div className="row">
                         <div className="col-md-4 text-start">
                              <h4 className="text-orange py-2">Our Privacy Guarantee</h4>
                              <p className='footer-heading-y'>We go above and beyond to ensure your data is safe and secure with us.</p>
                              <ul className="list-unstyled">
                                   <li><div className="footer-sec"><i class="bi bi-geo-alt-fill mr-1 text-blue"></i><p>B-9, 1st Floor, Mahalaxmi Nagar, Behind WTP South Block, Malviya Nagar, Jaipur, Rajasthan 302017.</p></div></li>
                                   <li><div className="footer-sec"><i class="bi bi-telephone-fill mr-1 text-blue"></i><p>+91-9773342427</p></div></li>
                                   <li><div className="footer-sec"><i class="bi bi-envelope-fill mr-1 text-blue"></i><p>dysinfo@qdegrees.com</p></div></li>
                              </ul>
                         </div>
                         <div className='col-md-12 col-lg-5 text-start'>
                              <div className='row'>
                              <div className="col-lg-6 col-md-6">
                              <h4 className="text-orange py-2">Services</h4>
                              <ul className="list-unstyled">
                                   <li><a href="https://www.qdegrees.com/services/quality-assurance-audits" className='text-primarys' target="_blank"><p>Interaction Quality </p></a></li>
                                   <li><a href="https://www.qdegrees.com/services/quality-assurance-audits" className='text-primarys' target="_blank"><p>Quality Audits</p></a></li>
                                   <li><a href="https://www.qdegrees.com/services/mystery-shopping" className='text-primarys' target="_blank"><p>Mystery Shopping</p></a></li>
                                   <li><a href="https://www.qdegrees.com/services/market-research" className='text-primarys' target="_blank"><p>Market Research</p></a></li>
                                   <li><a href="https://www.qdegrees.com/services/business-process-consulting-services" className='text-primarys' target="_blank"><p>Process Consulting</p></a></li>
                                   <li><a href="https://www.qdegrees.com/services/customer-experience-measurement" className='text-primarys' target="_blank"><p>Customer Experience Measurement</p></a></li>
                              </ul>
                              
                         </div>
                         <div className="col-lg-6 col-md-6 text-start">
                              <h4 className="text-orange py-2">Products</h4>
                              <ul className="list-unstyled">
                                   <li><a href="https://qdegrees.com/products/net-promoter-score-tool" className='text-primarys' target="_blank"><p>SurveyCXM </p></a></li>
                                   <li><a href="https://qdegrees.com/products/quality-assurance-audit-tool" className='text-primarys' target="_blank"><p>QAViews</p></a></li>
                                   <li><a href="https://qdegrees.com/products/retail-store-audit-tool" className='text-primarys' target="_blank"><p>Retail IQ</p></a></li>
                                   <li><a href="https://qdegrees.com/products/hrms-tool" className='text-primarys' target="_blank"><p>One HRMS</p></a></li>

                                   <li><a href="https://qdegrees.com/products/employee-engagement-tool" className='text-primarys' target="_blank"><p>SamparQ</p></a></li>
                                   <li><a href="https://qdegrees.com/products/customized-software-product-development" className='text-primarys' target="_blank"><p>Customized Software Solution</p></a></li>

                              </ul>
                             
                         </div>
                              </div>
                         </div>
                         
                         
                         <div className="col-lg-3 col-md-6 text-start">
                              <h4 className="text-orange py-2">Need Help</h4>
                              <p className>We've driven online revenues of over $2 billion for our clients. Ready to know how we can help you?</p>
                              <ul className="list-unstyled">
                                   <li><div className="footer-sec"><i class="bi bi-telephone-fill mr-1 text-blue"></i><p>+91-9773342427</p></div></li>
                                   <li><div className="footer-sec"><i class="bi bi-envelope-fill mr-1 text-blue"></i><p><a href="https://mail.google.com/mail/u/0/?fs=1&to=dysinfo@qdegrees.com&tf=cm" target="break">dysinfo@qdegrees.com</a></p></div></li>
                              </ul>
                              <ul className="social-iconm list-unstyled">
                                   <li><a href="https://www.instagram.com/qdegreesofficial/"  target="break" className="footer-sec"><i class="bi bi-instagram"></i></a></li>
                                   <li><a href="https://www.facebook.com/qdegrees"  target="break" className="footer-sec"><i class="bi bi-facebook"></i></a></li>
                                   <li><a href="https://twitter.com/qdegrees"  target="break" className="footer-sec"><i class="bi bi-twitter"></i></a></li>
                                   {/* <li><a href=""  target="break" className="footer-sec"><i class="bi bi-youtube"></i></a></li> */}
                                   <li><a href="https://www.linkedin.com/company/qdegrees-services"  target="break" className="footer-sec"><i class="bi bi-linkedin"></i></a></li>
                              </ul>
                         </div>
                    </div>
               </div>
          </section>
     )
}

export default Footer
