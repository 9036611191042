import React, { useEffect, useState } from 'react'
// import '../../../public/images/2@2x.png'



function TempleateDetails() {
    const [userData, setUserData] = useState()


    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user'));
        setUserData(userData);
       

    }, [])
    const logOut=()=>{
        localStorage.clear();
      }
    return (
        <div>

            <div>
                <section id="all-section" className="admin-header">
                    <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                        <div className="container-fluid ">
                            <a className="navbar-brand1" href="#"><img src="../images/new-logo.png" width="100px" alt="" /></a>
                            <ul className="navbar-nav flex-row">
                                
                                <li className="nav-item dropdown">
                                    <a className="nav-link nav dropdown-toggle d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="../images/2@2x.png" width="50px" className='rounded-circle' alt="" /><p className='aname m-0 px-2 text-black'>{userData && userData.fullName}</p><i className="text-black bi bi-chevron-down"></i>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-lg-end">
                                        <li><a className="dropdown-item" href="#">Profile Settings</a></li>
                                        <li><a className="dropdown-item" onClick={logOut} href="#">Logout</a></li>
                                        {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                    </ul>
                                </li>
                            </ul>
                        </div></nav>
                </section>
                <section id="all-section" className="section-survey ">
                    <div className="container-fluid ">
                        <div className="row ">
                            {/* Sidebar  */}
                            <div className="col-lg-3 col-md-4">
                                <div className="py-4 px-3 templateCatSelect bg-white h-100 mt-3" style={{ border: '1px solid #D8D8D8' }}>
                                    <h5>Template gallery</h5>
                                    <p className="pt-2">Feedback</p>
                                    <h4 style={{ fontSize: '20px' }}>Post Event Survey Feedback Template</h4>
                                    <ul className="qusBox p-0 mx-0 mb-5 mt-4 d-flex">
                                        <li>
                                            <p className="mb-0">Question</p>
                                            <span className="d-block">19</span>
                                        </li>
                                        <li>
                                            <p className="mb-0">Time to answer</p>
                                            <span className="d-block">1-3 min</span>
                                        </li>
                                    </ul>
                                    <p className="text-center mt-5"><a href="#" className="mainbtn text-white" style={{ padding: '10px 30px' }}>Use This Template</a></p>
                                </div>
                            </div>
                            {/* Page Content  */}
                            <div className="col-md-8 col-lg-9">
                                <div id="content" className="w-100 p-0 m-0 mt-3">
                                    <div className=" px-3 py-4 h-100 w-100 bg-white templateListSec ">
                                        <div className="d-flex mb-4" style={{ justifyContent: 'space-between' }}> <h6 className>Preview the template</h6>
                                            <a href="#" className="mainbtn">Back</a></div>
                                        <div className="templateBox text-center templtDltBox w-100">
                                            <div className="imgBox w-100 ">
                                                <img src="src/template.png" className="h-100 w-100" />
                                                <div className="overflowWatch w-100 h-100 d-flex pt-5" style={{ backgroundColor: 'transparent' }}>
                                                    <a href="#" className="mainbtn mt-5">Start Survey</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* jQuery CDN - Slim version (=without AJAX) */}
                            {/* Popper.JS */}
                            {/* Bootstrap JS */}
                            {/* jQuery Custom Scroller CDN */}
                        </div>
                    </div>
                </section>
            </div>
            );
        </div>
    )
}

export default TempleateDetails
