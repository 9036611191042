import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import"../../../public/images"
import API from "../../utils/apiCalling";
import ReactPlayer from 'react-player'
import { ToastContainer, toast } from 'react-toastify'
import { TumblrShareCount } from "react-share";
import ReactGA from 'react-ga';
import { Link } from 'react-router-dom'
import Config from '../../config/env'


const Register = () => {
  const [active, setActive] = useState(0)
  const [activeVideo, setActiveVideo] = useState(0)
  const [Stop, setStop] = useState(true)
  const [loginAs, setLoginAs] = useState("")
  // const[loggedInAs,setLoggedInAs]=useState("")

  let userType = (localStorage.getItem("userType") == undefined) ? 'panelist' : localStorage.getItem("userType")

    ;
  // const refferCode = localStorage.getItem("reffer", JSON.parse("reffer"));
  let refferCode1 = ((localStorage.getItem("reffer")) === undefined) ? '' : localStorage.getItem("reffer");
  let refferCode = refferCode1?.replaceAll('"', '');
  // localStorage.getItem("reffer", JSON.stringify(Params.reffer));


  const [from, setFrom] = useState({
    email: localStorage.getItem("user_email"),
    mobile: "",
    firstName: "",
    lastName: "",
    fullName: "",
    gender: "",
    dob: "",
    company: "",
    country: "",
    password: "",
    pinCode: "",
    userType: "",
    clientType: "",
    image: "",
    refferal: refferCode,
    device: "webapp",

  });
  const [country, setCountry] = useState();
  useEffect(() => {
    selectCountry();
    ReactGA.initialize('AW-10936833410');
  }, [activeVideo])


  const OnProgress = (value) => {
    if (parseInt(value.playedSeconds) === 18) {
      setActive(1)
    }
  }
  const seStop = () => {
    setStop(false)
    if (from.userType == `panelist`) {
      history.push('/panelistDash')
    }
  }
  const selectCountry = async () => {
    let result = await api.get(`${Config.new.site_api}/client-survey/getCountries`, {})

    if (result && result.code === 200) {
      let optData = result.data[0].options
      setCountry(optData)
      // setIsVerify(true)
    }
  }
  function myFunction() { //for seen password
    var x = document.getElementById("myInput");
    if (x.type === "password") {
      x.type = "text";
      setTimeout(() => {
        x.type = "password";
      }, 3000);
    } else {
      x.type = "password";
    }
  }

  const history = new useHistory();
  let api = new API();
  const handlechange = (e) => {
    let { name, value } = e.target
    if (e.target.name == "lastName") {
      setFrom({ ...from, "fullName": from.firstName && from.firstName.concat(value), [name]: value })
    } else {
      setFrom({ ...from, [name]: value })

    }

  }
  const Sigin = async () => {
    let vaildMobile = /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/;
    let validPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (from.clientType === "Student") {
      from.plan_days = 30
      from.plan_id = "6454ebc60b562924b060226a"
      setFrom(from)
    }
    if (from.userType == "") {
      toast.error("Select the Role First", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (from.userType === "client"&&from.clientType === "") {
      toast.error("Signup as is not allowed to be empty", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (from.firstName === '') {
      toast.error("First Name is not allowed to be empty", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (from.lastName === '') {
      toast.error("Last Name is not allowed to be empty", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (from.userType == "panelist" && from.gender === '') {
      toast.error("Gender is not allowed to be empty", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (from.userType == "panelist" && from.dob === '') {
      toast.error("DOB is not allowed to be empty", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (!from.mobile.match(vaildMobile)) {
      toast.error('Enter valid mobile No.', {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (!from.password.match(validPassword)) {
      toast.error("Password must be at least 8 characters and must contain at least one lower case letter, one upper case letter and one digit and one special character", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (from.country === "") {
      toast.error("Country is not allowed to be empty", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (from.userType == "panelist" && from.pinCode === '') {
      toast.error("Pincode is not allowed to be empty", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (from.clientType == 'Organization' && from.company === '') {
      toast.error("Company Name is not allowed to be empty", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else if (from.clientType === "Student" && from.image === '') {
      toast.error("Please upload Student's College Id", {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
     let result = await fetch(`${Config.new.site_api}/client/signup`, {
        method: 'post',
        body: JSON.stringify(from),
        headers: { 'content-Type': 'application/json' },
      });
      result = await result.json();
      if (result?.error === false) {
        localStorage.setItem("user", JSON.stringify(result.data));
        localStorage.setItem("token", JSON.stringify(result.token));
        ReactGA.event({
          category: 'User',
          action: 'Created an Account'
        });
        toast.success("User Saved Successfully", {
          position: toast.POSITION.TOP_RIGHT
        });
        if (result.data.userType === 'panelist') {
          setActiveVideo(1)
        } else if (result.data.userType === 'client' && result.data.clientType === 'Organization') {
          history.push('/plan')
        } else if (result.data.clientType === 'Student') {
          history.push('/adminDash')
        }
      } else {
        toast.error(result?.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  const onImageChange = async (e) => {
    const [file] = e.target.files;
    var binaryData = [];
    binaryData.push(file);
    setFrom({ ...from, image: window.URL.createObjectURL(new Blob(binaryData, { type: "application/zip" })) })
    let data = {

      email: from.email,
      file: file
    }

    let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/studentIdSave`, data)


  };
  return (
    <main>
      {
        activeVideo === 1 ? (<section className="signPoma SignVidContent">
          {/* Popup Sign in Video */}

          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                {
                  active === 1 ? (<button type="button" className="btn-close" onClick={seStop} data-bs-dismiss="modal" aria-label="Close"></button>) : ''
                }
              </div>
              <div className="modal-body">
                <div className="inner-video">
                  <ReactPlayer url='../images/video/Comp1_1.mp4' controls={true} config={{ file: { attributes: { autoPlay: true, muted: false } } }} onProgress={(state) => { OnProgress(state) }} playing={Stop} />
                </div>
              </div>
            </div>
          </div>
        </section>) : ''
      }
      <section className="loginSec">
        <div className="container text-center h-100">
          <div className="row justify-content-center">
            <div className="col-sm-6 col-lg-7">
              <div className="bg-white loginBox text-left">
                <h1 className="loginHeading text-left mb-4">
                  Register Now! It's Free!
                </h1>
                <select className="gender_select roleoj text-left mb-4" style={{ blockSize: "30px" }} aria-label="Default select example" name="userType" value={from.userType} onChange={(e) => handlechange(e)}>
                  <option className="text-grey" value="" selected>Select Your Role</option>
                  <option value="client">Client</option>
                  <option value="panelist">Panelist</option>
                </select>
                {/* {console.log(loginAs)} */}
                {(from.userType === "client") ? <div>
                  {/* <h6>sign up as :</h6> */}
                  <select className="gender_select roleoj text-left mb-4" placeholder="--Sign up as--" name="clientType" value={from.clientType} onChange={(e) => handlechange(e)} >

                    <option className="text-grey" value="" >--Signup as--</option>
                    <option value='Organization' >Organization</option>
                    <option value='Student'>Student</option>
                  </select>

                </div> : ""}
                {/* {(from.clientType=="Student"&&from.userType=="client") &&
             <div>
               <h6>Student's College Id :</h6>
                <input
                placeholder="Student's Collage Id"
                           className="gender_select roleoj text-left  "
                            type="file" onChange={onImageChange} accept="image/x-png/jpeg"  />
              </div>} */}
                {
                  (from.userType == "panelist") ?
                    <>
                      <form >
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">
                              <span className="input-group-text"><i className="bi bi-person-fill"></i></span>
                              <input type="text" className="form-control" placeholder="First Name"
                                value={from.firstName}
                                name="firstName"
                                onChange={(e) => handlechange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">
                              <span className="input-group-text"><i className="bi bi-person-fill"></i></span>
                              <input type="text" className="form-control" placeholder="Last Name"
                                value={from.lastName}
                                name="lastName" onChange={(e) => handlechange(e)} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* <div className="col">
                    <div className="input-group mb-3">
                        <input list="magicHouses" className="form-select input-group-text text-left newSelect" id="filter-bt" name="myHouse" placeholder="Select Gender" />
                        <datalist id="magicHouses">
                          <div>
                            <option className="border-bottom p-2" >Male</option>
                            <option className="border-bottom p-2" >Female</option>
                            <option className="border-bottom p-2" >Another</option>

                          </div>
                        </datalist>
                      </div>
                    </div> */}
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">

                              <select className="form-select gender_select" aria-label="Default select example" name="gender" onChange={(e) => handlechange(e)}>
                                <option className="text-grey" selected disabled hidden> Select Gender</option>
                                <option value="male"  >  Male </option>
                                <option value='female'>  Female </option>
                                <option value='others'>  Other </option>
                              </select>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-3">
                              <span className="input-group-text" id="basic-addon1"><i className="bi bi-calendar-fill"></i><i className="fa-solid fa-calendar-days" /></span>
                              <input type="date" className="form-control" placeholder="Enter Phone..." aria-label="Username"
                                value={from.dob.slice(0, 10)}
                                aria-describedby="basic-addon1" name="dob" onChange={(e) => handlechange(e)} />
                            </div>
                          </div>
                          {/* <div className="col">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i className="bi bi-pin-map"></i></span>
                        <input type="text" className="form-control" placeholder="Location" name="city"
                          value={from.city}
                          onChange={(e) => handlechange(e)} />
                      </div>
                    </div> */}
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className="input-group mb-4 ">
                              <span className="input-group-text"><i className="fa fa-envelope" /></span>
                              <input type="email" className="form-control" placeholder="Enter Your Email"
                                value={from.email}
                                name="email" onChange={(e) => handlechange(e)} readOnly />
                            </div>
                          </div>

                          {/* <div className="col">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i className="bi bi-geo-alt-fill"></i></span>
                        <input type="text" className="form-control" placeholder="Pincode" name="pincode"value={from.city} onChange={(e) => handlechange(e)} />
                      </div>
                      </div> */}
                        </div>
                        <div className="row">
                          <div className="col">

                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-3">
                              <span className="input-group-text" id="basic-addon1"> <i className="bi bi-phone-fill"></i></span>
                              <input type="Number" className="form-control quantity" placeholder="Enter Mobile No..." aria-label="Username"
                                value={from.mobile}
                                name="mobile" aria-describedby="basic-addon1" onChange={(e) => handlechange(e)} />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">
                              <span className="input-group-text"><i className="fa fa-lock" /></span>
                              <input type="password" className="form-control" placeholder="Password" name="password" id="myInput"
                                value={from.password}
                                onChange={(e) => handlechange(e)} />
                              <span className="input-group-text eyePass"   ><i className="fa fa-eye" onClick={(e) => myFunction()} /></span>
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">
                              <span className="input-group-text"><i className="fa fa-globe" ></i></span>

                              {/* <select className="form-select country_select" aria-label="Default select example" name="country" onChange={(e) => handlechange(e)}>
                          <option className="text-grey" selected disabled hidden> Select Country </option>
                          <option value='India'>  India  </option>
                          <option value='India'>  India  </option>
                          <option value='India'>  India  </option>
                        </select> */}

                              <select className="form-control" type="select" name="country" value={from.country} onChange={(e) => handlechange(e)} >
                                <option hidden>Please select country</option>
                                {country && country.length > 0 && country.map((data, index) => (

                                  <option name="country" value={data.type}>{data.type}</option>
                                ))}
                              </select>

                            </div>
                          </div>

                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">
                              <span className="input-group-text"><i className="bi bi-person-fill"></i></span>
                              <input type="number" className="form-control" placeholder="Pincode" value={from.pinCode}
                                name="pinCode" onChange={(e) => handlechange(e)} />
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                    <div className="col">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i className="fa fa-envelope" /></span>
                        <input type="email" className="form-control" placeholder="Enter Your Email" name="email" value={localStorage.getItem("user_email")} readOnly />
                      </div>
                    </div>
                    <div className="col">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i className="fa fa-lock" /></span>
                        <input type="password" className="form-control" placeholder="Password" name="password" onChange={(e) => handlechange(e)} id="myInput" />
                        <span className="input-group-text eyePass"><i className="fa fa-eye" onClick= {(e)=>myFunction()}  /></span>
                      </div>
                    </div>
                  </div> */}
                        {/* <div className="row">
                    <div className="col">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i className="fa fa-mobile" aria-hidden="true"/></span>
                        <input type="phone" className="form-control" placeholder="Enter Phone..." aria-label="Username" name="mobile" aria-describedby="basic-addon1" onChange={(e) => handlechange(e)} />
                      </div>
                    </div>
                    <div className="col">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">DOB<i className="fa-solid fa-calendar-days" /></span>
                        <input type="date" className="form-control" placeholder="Enter Phone..." aria-label="Username" aria-describedby="basic-addon1" name="dob" onChange={(e) => handlechange(e)} />
                      </div>
                    </div>
                  </div> */}
                        {/* <div className="row">
                    <div className="col">
                      <div className="d-flex">
                        <h6>Gender</h6>&nbsp;;
                        <div className="condite me-3">
                          <div className="form-check">
                            <input className="form-check-input" style={{ marginTop: '14px' }} type="radio" name="gender" id="flexRadioDefault1" onChange={(e) => handlechange(e)} value="male" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                              <img src="..//images/Group 43057.svg" className="org" alt="image" width="35px" />
                            </label>
                          </div>
                        </div>
                        <div className="women me-3">
                          <div className="form-check">
                            <input className="form-check-input" style={{ marginTop: '14px' }} type="radio" name="gender" id="flexRadioDefault2" onChange={(e) => handlechange(e)} value="female" />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                              <img src="..//images/Group 43056.svg" className="org" alt="image" width="28px" />
                            </label>
                          </div>
                        </div>
                        <div className="trans">
                          <div className="form-check">
                            <input className="form-check-input" style={{ marginTop: '14px' }} type="radio" name="gender" id="flexRadioDefault3" onChange={(e) => handlechange(e)} value="others" />
                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                              <img src="..//images/Path 39212.svg" className="org" alt="image" width="36px" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i  className="fa fa-map-marker" aria-hidden="true"/></span>
                        {/* <input type="text" className="form-control" placeholder="Location" name="city" onChange={(e) => handlechange(e)} /> */}
                        {/* <div className="form-control" placeholder="Location">
                        <select type="select"   onChange={(e) => handlechange(e)} >
                        <option selected>Select Location</option>
                          <option> India</option>
                          <option>Pakistan</option>
                        </select>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i className="fa fa-envelope" /></span>
                        <input type="text" className="form-control" placeholder="Reffer Code" name="refferal" onChange={(e) => handlechange(e)} value={refferCode} readOnly />
                      </div>
                    </div>
                  </div> */}
                        <div className='d-flex mb-4  justify-content-evenly remberMe'>
                          <button type="button" onClick={Sigin} className="mainbtn w-50" style={{ color: "white" }} >Sign up</button>
                        </div>
                      </form>
                    </>
                    :

                    <>
                      <form >
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">
                              <span className="input-group-text"><i className="bi bi-person-fill"></i></span>
                              <input type="text" className="form-control" placeholder="First Name"
                                value={from.firstName}
                                name="firstName"
                                onChange={(e) => handlechange(e)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">
                              <span className="input-group-text"><i className="bi bi-person-fill"></i></span>
                              <input type="text" className="form-control" placeholder="Last Name"
                                value={from.lastName}
                                name="lastName" onChange={(e) => handlechange(e)} />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-3">
                              <span className="input-group-text" id="basic-addon1"> <i className="bi bi-phone-fill"></i></span>
                              <input type="Number" className="form-control quantity" placeholder="Enter Mobile No..." aria-label="Username"
                                value={from.mobile}
                                name="mobile" aria-describedby="basic-addon1" onChange={(e) => handlechange(e)} />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">
                              <span className="input-group-text"><i className="fa fa-lock" /></span>
                              <input type="password" className="form-control" placeholder="Password" name="password" id="myInput"
                                value={from.password}
                                onChange={(e) => handlechange(e)} />
                              <span className="input-group-text eyePass"   ><i className="fa fa-eye" onClick={(e) => myFunction()} /></span>
                            </div>
                          </div>

                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4 ">
                              <span className="input-group-text"><i className="fa fa-envelope" /></span>
                              <input type="email" className="form-control" placeholder="Enter Your Email"
                                value={from.email}
                                name="email" onChange={(e) => handlechange(e)} readOnly />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12">
                            <div className="input-group mb-4">
                              <span className="input-group-text"><i className="fa fa-globe" ></i></span>

                              <select className="form-control" type="select" name="country" value={from.country} onChange={(e) => handlechange(e)} >
                                <option hidden>Please select country</option>
                                {country && country.length > 0 && country.map((data, index) => (

                                  <option name="country" value={data.type}>{data.type}</option>
                                ))}
                              </select>

                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6 col-md-12">
                            {/* <div  className="input-group mb-4">
                        <span className="input-group-text"><i className="fa fa-lock" /></span>
                        <input type="password" className="form-control" placeholder="Password" name="password" id="myInput"
                          value={from.password}
                          onChange={(e) => handlechange(e)} />
                        <span className="input-group-text eyePass"   ><i className="fa fa-eye" onClick={(e) => myFunction()} /></span>
                      </div> */}
                          </div>

                          {from.clientType == "Student" ?
                            <div className="row">


                              <div className="col-lg-6 col-md-12" >

                                <p className="mt-3 ml-5" style={{ color: "Gray" }}>Upload Student's College ID : </p>
                              </div>

                              <div className="col-lg-6 col-md-12 " >
                                <div className="input-group mb-4">
                                  <span className="input-group-text ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" fill="currentColor" viewBox="0 0 576 512"><path d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 192a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm176 16c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" /></svg></span>
                                  <input
                                    placeholder="Student's Collage Id"
                                    className="form-control  pt-3 "
                                    type="file" onChange={(e) => onImageChange(e)} accept="image/x-png/jpeg" />
                                </div>
                              </div>
                            </div>
                            :
                            <div className="row">
                              <div className="col-lg-6 col-md-12">
                                <div className="input-group mb-4">
                                  <span className="input-group-text">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building-fill-check" viewBox="0 0 16 16">
                                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514Z" />
                                      <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.493 4.493 0 0 0 12.5 8a4.493 4.493 0 0 0-3.59 1.787A.498.498 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.476 4.476 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1V1Zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5Zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1ZM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5ZM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1Z" />
                                    </svg></span>
                                  <input type="text" className="form-control" placeholder="Enter Company Name"
                                    value={from.company}
                                    name="company" onChange={(e) => handlechange(e)} />
                                </div>

                              </div>
                            </div>
                          }
                        </div>

                        <div className='d-flex mb-4  justify-content-evenly remberMe'>
                          <button type="button" onClick={Sigin} className="mainbtn w-50 "  >Sign up</button>
                        </div>
                      </form>
                    </>

                }
                {/* <p className="mb-0">Click here to <a href="#/login">Login</a></p> */}
              </div>
              <div className="bg-white loginBox d-none">
                <img src="src/new-logo.png" alt="" width="140px" />
                <h1 className="loginHeading text-left mb-4">
                  Sign Up
                </h1>
                <form action="/action_page.php">
                  <div className="input-group mb-4">
                    <span className="input-group-text"><i className="fa fa-envelope" /></span>
                    <input type="email" className="form-control" placeholder="Enter Your Email" name="email" />
                  </div>
                  <button type="submit" className="btn mainbtn tada w-100">Verify</button>
                </form>
                <div className="d-flex btnLogType my-4">
                  <a href="#" className="mainbtn tada"><i className="fa fa-google" /> Google</a>
                  <a href="#" className="mainbtn tada"><i className="fa fa-facebook-official" /> Facebook</a>
                </div>
                <p className="mb-0">Click here to <a href="#">Login</a></p>
              </div>
              <div className="bg-white loginBox d-none">
                <img src="src/new-logo.png" alt="" width="140px" />
                <h1 className="loginHeading text-left mb-4">
                  OTP Verifaction
                </h1>
                <form action="/action_page.php">
                  <div className="input-group mb-4">
                    <span className="input-group-text"><i className="fa fa-envelope" /></span>
                    <input type="password" className="form-control" placeholder="Password" name="password" />
                    <span className="input-group-text eyePass"><i className="fa fa-pencil-square-o" /></span>
                  </div>
                  <p className="mb-3 text-center">Enter OTP</p>
                  <div className="input-group mb-4 otpDv bg-white">
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                  </div>
                  <p className="mb-4 text-center"><a href="#">Resend OTP</a></p>
                  <button type="submit" className="btn mainbtn tada w-100">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </main>




  )
}

export default Register;
