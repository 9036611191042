import React, { useState, useEffect } from 'react'
import Loader2 from '../../utils/loader2/loader2';
import API from '../../utils/apiCalling';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { capitalizeFirstLetter } from '../../utils/CommonFunction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Config from '../../config/env'

export default function ClientManagement() {
    const api = new API()
    const history = new useHistory();
    const [data, setData] = useState([]);
    const [loader2, setLoader2] = useState(true);
    const [query, setQuery] = useState("");
    const [input, setInput] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [userPlan, setUserPlan] = useState([]);
    const [plan, setPlan] = useState("");
    const [click, setClick] = useState(false);
    const [mail, setMail] = useState();
    const [user, setUser] = useState([]);
    const [name, setName] = useState();
    const [pagination, setPagination] = useState(0);

    useEffect(() => {

        fetchUsers('');

        getUserPlan();

        clientDetails();

        clientLogin();

    }, [])

    const fetchUsers = async (e,srchfor,count) => {
        let data = {
            filter: "client",
            // filter: "panelist",
            pageSize: 10,
            searchKeywords: query ? query : "",
            endDate: endDate ? endDate : "",
            startDate: startDate ? startDate : "",
            plan_id: plan ? plan : "",
            pageNumber:count,

        }
        if (srchfor == "plan") {
            data.plan_id = e
        } else if (srchfor == 'query') {
            console.log(e, "eetttt")
            data.searchKeywords = e
        }

        const result = await api.post(`${Config.new.site_api}/client-survey/getClientUsers`, data)

        if (result.code === 200) {
            setLoader2(false);
            setData(result.data);
            setPagination(result.valueCount)
        }
    }

    const clientDetails = async (e) => {
        let data = {
            _id: e

        }

        const result = await api.post(`${Config.new.site_api}/client-survey/countOfSurveyRecord`, data)


        if (result.code === 200) {

            setUser(result.data);
        }
    }
    const clientLogin = async () => {
        let data = {
            email: mail

        }

        const result = await api.post(`${Config.new.site_api}/client-survey/clientLoginForDetails`, data)


        if (result.code === 200) {

            localStorage.setItem('user', JSON.stringify(result.data));

            history.push('/adminDash')
        }
    }

    const getUserPlan = async () => {

        const result = await api.get(`${Config.new.site_api}/client-survey/getPlan`)


        if (result && result.code === 200) {

            setUserPlan(result.data);

        }

    }

    const sendPageCount = (value) => {
        if (value === 'back') {

            setPageCount(pageCount-1)
            
            fetchUsers(pageCount-1);

        } else if (value === "next") {

            setPageCount(pageCount + 1)
            
            fetchUsers(pageCount+1);
        } 
    }

    const handleSearch = (e) => {
        setQuery(input);
        fetchUsers(input, 'query');
        e.preventDefault()
    }
    const updateDate = (e) => {
        fetchUsers();
        e.preventDefault();
    }

    const clearFilter = (e) => {
        setPlan("");
        setInput("");
        setStartDate("");
        setEndDate("");
        fetchUsers("", "query");
        e.preventDefault();
    }

    const selectUserPlan = (e) => {

        let a = e.target.selectedIndex
        setPlan(userPlan[a]._id);
        fetchUsers(userPlan[a]._id, 'plan')
    }

    const getClientData = (e, f, l, email) => {

        { e !== undefined && clientDetails(e) }
        setClick(!click);
        setMail(email)
        setName(capitalizeFirstLetter(f) + " " + capitalizeFirstLetter(l))
    }

    const clientUserLogin = () => {

        clientLogin();


    }


    return (
        <>
            <div className="main position-relative">
                <div className="header m-2 col-6">
                    <form className="d-flex " role="search">
                        <select name="clientplan" id="plans" className='form-control me-2' onChange={(e) => selectUserPlan(e)} style={{ width: 'auto' }}>
                            <option hidden>Select Client Plan</option>
                            {
                                userPlan && userPlan.length > 0 && userPlan.map((data) => (

                                    <option value={data.Name}>{data.Name}</option>

                                ))
                            }
                        </select>
                        <input className="form-control me-2 w-auto" type="search" value={input} placeholder="Search" aria-label="Search" onChange={(e) => setInput(e.target.value)} />

                        <button className="btn btn-outline-secondary my-2 my-sm-0" onClick={handleSearch} >Search</button>
                        <input type="date" id="from" name="from" value={startDate} className='form-control me-2 ml-3' onChange={(e) => setStartDate(e.target.value)}></input>

                        <input type="date" id="to" name="to" value={endDate} className='form-control me-2' onChange={(e) => setEndDate(e.target.value)}></input>

                        <button className='btn btn-secondary ml-2 text-white' onClick={(e) => updateDate(e)}><i className="fa fa-filter" aria-hidden="true"></i></button>
                        <button className='btn btn-secondary ml-2 text-white' onClick={(e) => clearFilter(e)}><i className="fa fa-ban" aria-hidden="true"></i></button>

                        <div className="dropdown ml-2">


                        </div>
                    </form>
                </div>
                <div style={{ textAlign: 'center', marginBottom: "10" }}>
                    <table className="table table-bordered " >

                        <thead style={{ position: "sticky", top: "0" }} >
                            <tr style={{ backgroundColor: "Gray" }}>
                                <th scope="col">Name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">city</th>
                                <th scope="col">date</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>

                        {loader2 == true ?
                            <thead><tr><td></td><td></td></tr><div className="container mt-2  " style={{ maxHeight: "100%", maxWeidth: "100%" }}>
                                <Loader2 />
                            </div></thead>
                            :
                            <tbody>
                                <>

                                    {

                                        data.map((item) => (

                                            <tr onClick={(e) => getClientData(item._id, item.firstName, item.lastName, item.email)} >
                                                <td >{capitalizeFirstLetter(item.firstName) + " " + capitalizeFirstLetter(item.lastName)}</td>
                                                <td >{item.gender}</td>
                                                <td >{item.mobile}</td>
                                                <td >{item.city}</td>
                                                <td >{item.date.slice(0, 10)}</td>
                                                <td >{item.email}</td>
                                            </tr>

                                        ))

                                    }
                                </>


                                <Modal
                                    show={click}
                                    onHide={getClientData}
                                    backdrop="true"
                                    keyboard={false}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Client Detail</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="card" >
                                            <div className="card-body">
                                                <h5 className="card-title">Name : {name}</h5>
                                                <p className="card-text"></p>
                                            </div>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item">Total Survey : {user[0]?.Total_Survey}</li>
                                                <li className="list-group-item">Completed Survey : {user[1]?.Completed_Survey}</li>
                                                <li className="list-group-item">Response Count : {user[2]?.Responce_Count}</li>
                                            </ul>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="primary" onClick={clientUserLogin}>
                                            Open Acount
                                        </Button>
                                        <Button variant="danger" onClick={getClientData}>
                                            Close
                                        </Button>

                                    </Modal.Footer>
                                </Modal>


                            </tbody>}

                    </table>
                                <nav aria-label="Page navigation example ">
                        <ul className=" pagination d-flex justify-content-center m-3">
                            <li class="page-item m-1">
                            
                                {pageCount > 0 && <button class="btn btn-outline-primary" onClick={() => sendPageCount("back")} tabindex="-1"><i class="fa fa-chevron-left"></i></button>}
                            </li>
                            <li class="page-item btn border-0"><button class="page-link border-0" >{pageCount + 1}<></></button></li>
         
                            <li class="page-item m-1">
                                <button class="btn btn-outline-primary" onClick={() => sendPageCount("next")} ><i class="fa fa-chevron-right"></i></button>
                            </li>
                              <h4>Total Data-{pagination}</h4>
                        </ul>
                    </nav>


                </div>
            </div>
        </>
    )
}
