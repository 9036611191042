import React, { useState } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import './surveyDashboard.css'
// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link, useHistory } from "react-router-dom";
import Users from '../usersManagement/users';
import ProfilePanelAdminDashboard from '../profilePanelAdminDashboard/profilePanelAdminDashboard';
import RewardPanelAdminDashboard from '../rewardPanelAdminDashboard/rewardPanelAdminDashboard';
import SurveyRecord from '../surveyRecordAdminDashboard/surveyRecord';
import SurveyWiseResponse from '../surveyWiseResponce/surveyWiseResponce';
import ProfileSurveyRecord from '../profileSurveyRecordAdminDashboard/profileSurveyRecord';
import RegistrionReport from '../Registration report/registrationReport';
import Pushnotification from '../pushNotification/Pushnotification';
import Clientdemo from '../demoSurvey/Clientdemo';
import swal from 'sweetalert';
import AddSurvey from '../AddSurveyAdminDashboard/addSurvey';
import PanelistManagement from '../../website/PanelistManagement';
import { Spaces } from '../../utils/CommonFunction';
import PlanManagement from '../surveyWiseResponce/planmanagement';
import ClientManagement from '../Client Management/ClientManagement';
import UploadExcel from '../uploadQCExcel/uploadQcExcel';
import SurveyQCResponse from '../surveyWiseResponce/surveyQCResponse';
const SurveyDashboard = (props) => {
    const api = new API()
    const history = new useHistory()
    const [userName,setUserName] = useState("")
    const [password,setPassword] = useState("")
    const [show, setShow] = useState("Users")
//     let adminToken = getLocalDataAsObject("adminToken")
//     if(!adminToken) {
//         history.push("/dashboard")
//     }
   const logout = () => {
    swal({
      title: "Are you sure to LogOut?",
      buttons: ["No, cancel it!", "Yes"],
      dangerMode: true,
    }).then(async function (isConfirm) {
      if (isConfirm) {
        localStorage.clear();
        history.push("/login");
      } else {
      }
    });
  };


    return (
        <div>
           
            <div className="container-fluid">



                <div className="w3-sidebar w3-light-grey w3-bar-block" style={{ overflowX: "hidden" }}>
                    <h3 className="w3-bar-item" >QDegrees</h3>
                    
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('users')})} className="w3-bar-item w3-button">Users</a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('panelistManagement')})} className="w3-bar-item w3-button">Panelist Management</a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('clientManagement')})} className="w3-bar-item w3-button">Client Management</a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('addSurvey')})} className="w3-bar-item w3-button">Survey Panel</a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('savedAddSurvey')})} className="w3-bar-item w3-button">Saved Survey Panel </a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('rewardPanelAdminDashboard')})} className="w3-bar-item w3-button">Rewards Panel</a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('profilePanelAdminDashboard')})}  className="w3-bar-item w3-button">Profile Panel</a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('surveyRecord')})}  className="w3-bar-item w3-button">Survey Record</a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('profileSurveyRecord')})}   className="w3-bar-item w3-button">Profile Survey Record</a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('surveyQCResponse')})}  className="w3-bar-item w3-button">Survey Response QC</a>
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('surveyWiseResponse')})}  className="w3-bar-item w3-button">Survey Wise Response</a>
                    {/* <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('savedSurveyWiseResponse')})}  className="w3-bar-item w3-button">Saved Survey Wise Response </a> */}
                    <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('Upload QC Excel')})} className="w3-bar-item w3-button">Upload QC Excel</a>
                    {/* <Link to="/192837465/seo-panel"><a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} href="#" className="w3-bar-item w3-button">SEO Panel</a></Link> */}
                    {/* <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} onClick={((e)=>{setShow('registration-report')})}  className="w3-bar-item w3-button">Registration-Report </a> */}

                    <Link to="/paytm"><a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} href="#" className="w3-bar-item w3-button">Paytm Panel</a></Link>
                    <Link to="/192837465/registration-report"><a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} href="#" className="w3-bar-item w3-button">Registration Report</a></Link>
                    <Link to="/192837465/pushnotification"><a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} href="#" className="w3-bar-item w3-button">Push Notification</a></Link>
                    <Link to="/192837465/planmanagement"><a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} href="#" className="w3-bar-item w3-button">Plan Management</a></Link>

                    <Link to="/192837465/sudentcollegeid"><a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} href="#" className="w3-bar-item w3-button">Student's College ID</a></Link>
                    
                    {/* <a style={{ backgroundColor: "gray", margin: "5px", color: "white" }} href="#" className="w3-bar-item w3-button">Profile Panel</a> */}
                </div>

                <div style={{ marginLeft: "15%" }}>

                    <div className="w3-container w3-teal sticky-top">
                        <h3><a href="#">{Spaces(show)}</a>
                      
                  
                        
                        <button onClick={logout} style={{float:"right"}} className="btn btn-danger ">Logout</button>
                        <a style={{float:"right"}} href='#/panelistDash' className="btn btn-danger mr-2">Panelist Dashboard</a>
                        </h3>
                    </div>
                    {show==='users'&&<Users/>}
                    {show==='addSurvey'&&<AddSurvey heading={"DoYourSurvey Survey Panel Dashboard"}/>}
                    {show==='panelistManagement'&&<PanelistManagement heading={"DoYourSurvey Survey Panel Dashboard"}/>}
                    {show==='clientManagement' && <ClientManagement/>}
                    {show==='savedAddSurvey' && <AddSurvey />}
                    {show==='rewardPanelAdminDashboard'&&<RewardPanelAdminDashboard/>}
                    {show==='profilePanelAdminDashboard'&&<ProfilePanelAdminDashboard/>}
                    {show==='surveyRecord'&&<SurveyRecord/>}
                    {show==='profileSurveyRecord'&&<ProfileSurveyRecord/>}
                    {show==='surveyQCResponse'&&<SurveyQCResponse/>}
                    {show==='surveyWiseResponse'&&<SurveyWiseResponse/>}
                    {/* {show==='savedSurveyWiseResponse'&&<SurveyWiseResponse/>} */}
                    {show==='registrionReport'&&<RegistrionReport/>}
                    {show==='pushnotification'&&<Pushnotification/>}
                    {show==='clientdemo'&&<Clientdemo/>}
                    {show==='planmanagement'&&<PlanManagement/>}
                    {show==='Upload QC Excel'&&<UploadExcel/>}
                    
                     
                </div>
            </div>
   
        </div>
    );
}

export default SurveyDashboard;