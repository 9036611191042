export const setLocalDataAsObject = (keyAsString, value) => {
     localStorage.setItem(keyAsString, JSON.stringify(value));
}

export const getLocalDataAsObject = (keyAsString) => {
     //  alert('getLocalDataAsObject=', keyAsString + '=' + localStorage.getItem(keyAsString))


     var data = localStorage.getItem(keyAsString);
     if (data) {

          try {
               return JSON.parse(data);

          } catch (error) {
              
          }
          // return objData;
     }
}

export const clearLocalData = () => {

     try {
          localStorage.clear();
     } catch (error) {
         
          // Error retrieving data
     }

}