import React from 'react'

function AdminDashboard() {
     return (
          <>
               <section id="all-section" className="admin-header">
                    <nav className="navbar navbar-expand-lg bg-white p-0">
                         <div className="container-fluid px-5">
                              <a className="navbar-brand" href="/"><img src="../images/new-logo.png" width="120px" alt="" /></a>
                              <ul className="navbar-nav">
                                   <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                             <img src="../images/2@2x.png" width="50px" alt="" />
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-lg-end">
                                             <li><a className="dropdown-item" href="/">Action</a></li>
                                             <li><a className="dropdown-item" href="/">Another action</a></li>
                                             <li><a className="dropdown-item" href="/">Something else here</a></li>
                                        </ul>
                                   </li>
                              </ul>
                         </div></nav>
               </section>
               <section id="all-section" className="section-survey ">
                    <div className="container-fluid ">
                         <div className="row ">
                              <div className="wrapper">
                                   {/* Sidebar  */}
                                   <nav id="sidebar">
                                        <div className="search-container position-relative m-2">
                                             <button type="button" id="sidebarCollapse" className="btn border-0 text-blue">
                                                  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                                       <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                                  </svg>
                                             </button>
                                             <form action="/action_page.php" className="bg-lgrey d-flex align-items-center">
                                                  <button type="submit" className="border-0"><img src="../images/Icon feather-search.svg" alt="" className="m-2 w-50" /></button>
                                                  <input type="text" placeholder="Find Survey" name="search" className="bg-lgrey form-control" />
                                             </form>
                                        </div>
                                        <details className="sidebar-details">
                                             <summary>
                                                  <div className="sidebar-btn"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                                       <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                                  </svg>
                                                       <p className=" f-14 m-0">Private</p>
                                                       <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                       </svg>
                                                  </div>
                                             </summary>
                                             <div>
                                                  <div className="f-14 d-flex justify-content-between py-4">
                                                       <p>My Workspace</p>
                                                       <p>1</p>
                                                  </div>
                                             </div></details>
                                   </nav>
                                   {/* Page Content  */}
                                   <div id="content">
                                        <nav className="navbar navbar-expand-lg  bg-lgrey px-2">
                                             <div className="container-fluid p-0">

                                                  <p className="text-orange boldnormal-p m-0">My Workspace</p>

                                                  <div className="vr m-3" />
                                                  <a href className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="currentColor" className="bi bi-share-fill text-blue" viewBox="0 0 16 16">
                                                       <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                                  </svg>
                                                       <p className="px-2 m-0">Share</p>
                                                  </a>
                                                  <div className=" dropdown ">
                                                       <a className="dropdown-toggle d-flex three-dot-drop" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                 <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                            </svg></p>
                                                       </a>
                                                       <ul className="dropdown-menu dropdown-menu">
                                                            <li><a className="dropdown-item" href="/">Rename</a></li>
                                                            <li><a className="dropdown-item text-danger" href="/">Delete</a></li>
                                                       </ul>
                                                  </div>
                                                  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                                       <span className="navbar-toggler-icon" />
                                                  </button>
                                                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                                       <div className=" mx-auto">
                                                            <ul className="nav navbar-nav">
                                                                 <li className="nav-item dropdown admin-drop ">
                                                                      <a className="nav-link dropdown-toggle d-flex align-items-center justify-content-between bg-white border px-2 m-2" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                           <p className=" m-0 admin-drop-p py-1 ">Active Survey</p>
                                                                      </a>
                                                                      <ul className="dropdown-menu dropdown-menu">
                                                                           <li><a className="dropdown-item" href="/">Action</a></li>
                                                                           <li><a className="dropdown-item" href="/">Another action</a></li>
                                                                           <li><a className="dropdown-item" href="/">Something else here</a></li>
                                                                      </ul>
                                                                 </li>
                                                                 <li className="nav-item dropdown admin-drop">
                                                                      <a className="nav-link dropdown-toggle d-flex align-items-center justify-content-between bg-white border px-2 m-2" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                           <p className=" m-0 admin-drop-p py-1">Date Created</p>
                                                                      </a>
                                                                      <ul className="dropdown-menu dropdown-menu">
                                                                           <li><a className="dropdown-item" href="/">Action</a></li>
                                                                           <li><a className="dropdown-item" href="/">Another action</a></li>
                                                                           <li><a className="dropdown-item" href="/">Something else here</a></li>
                                                                      </ul>
                                                                 </li>
                                                            </ul>
                                                       </div>
                                                       <ul className="nav navbar-nav ml-auto layout-admin">
                                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                                 <li className="nav-item" role="presentation">
                                                                      <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
                                                                           <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
                                                                      </svg>Grid</button>
                                                                 </li>
                                                                 <li className="nav-item" role="presentation">
                                                                      <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"><img src="../images/Icon ionic-md-list.svg" alt="" />List</button>
                                                                 </li>
                                                            </ul>
                                                       </ul>
                                                  </div>
                                             </div>
                                        </nav>
                                        <div className="w-100 bg-lgrey p-4"><button className="mainbtn " data-bs-toggle="modal" data-bs-target="#create-survey-modal">Create Survey</button></div>
                                        <div className="surveys-bar px-4  bg-white">
                                             <div className="tab-content" id="myTabContent">
                                                  <div className="tab-pane fade show active  layout-grid" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                                                       <div className="layout-grid-content d-flex justify-content-center">
                                                            <lottie-player src="../images/a5afec80-d37c-40cf-a91f-0c346cd2d996.json" background="transparent" speed={1} style={{ width: '40%', height: '40%' }} loop autoPlay />
                                                       </div>
                                                       <div className="grid-content w-100 text-center">
                                                            <h5 className="text-orange">
                                                                 Come on in, John Smith
                                                            </h5>
                                                            <p className="pb-4">It's time to create something special.</p>
                                                            <button className="mainbtn " data-bs-toggle="modal" data-bs-target="#create-survey-modal">Create Survey</button>
                                                       </div>
                                                  </div>
                                                  <div className="tab-pane fade  layout-list" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>...</div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              {/* modals */}
                              <div className="modal fade survey-popup" id="create-survey-modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                   <div className="modal-dialog modal-dialog-centered create-survey-modal">
                                        <div className="modal-content">
                                             <div className="modal-header">
                                                  <h5 className="modal-title" id="exampleModalLabel">Create a new survey</h5>
                                                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                             </div>
                                             <div className="modal-body py-5">
                                                  <div className="row">
                                                       <div className="col-sm-4">
                                                            <div className="card bg-lgrey bg-gray-card-border " type="button" data-bs-toggle="modal" data-bs-target="#from-scratch-modal">
                                                                 <lottie-player src="../images/85b866aa-4863-4db0-8817-b61c399de968.json" background="transparent" speed={1} style={{ width: '12.5rem', height: '12.5rem' }} loop autoPlay />
                                                                 <div className="card-body p-3 text-center">
                                                                      <h5 className="card-title">Start from scratch</h5>
                                                                      <p className="card-text">Jump right in and build something beautiful</p>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                       <div className="col-sm-4">
                                                            <div className="card bg-lgrey bg-gray-card-border ">
                                                                 <lottie-player src="../images/1c33fc10-e7d9-497c-9765-43d68895b665.json" background="transparent" speed={1} style={{ width: '12.5rem', height: '12.5rem' }} loop autoPlay />
                                                                 <div className="card-body p-3 text-center">
                                                                      <h5 className="card-title">Use a template---</h5>
                                                                      <p className="card-text">Pick a premade survey and customize it as you
                                                                           please <br />Or <a href><u>import your questions</u></a></p>
                                                                 </div>
                                                                 
                                                            </div>
                                                       </div>
                                                       <div className="col-sm-4">
                                                            <div className="card bg-lgrey bg-gray-card-border ">
                                                                 <lottie-player src="../images/67ea5ba5-92b0-4d1b-81b9-f0b09b98f6f5.json" background="transparent" speed={1} style={{ width: '12.5rem', height: '12.5rem' }} loop autoPlay />
                                                                 <div className="card-body p-3 text-center">
                                                                      <h5 className="card-title">Create it for me</h5>
                                                                      <p className="card-text">Tell us what you need and we will generate a survey
                                                                           based on that</p>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                        {/* <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                          <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}
                                   </div>
                              </div>
                              {/* scratch modal */}
                              <div className="modal fade survey-popup" id="from-scratch-modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                   <div className="modal-dialog h-100 modal-dialog-centered survey-title-modal">
                                        <div className="modal-content">
                                             <div className="modal-header pb-0">
                                                  <h5 className="modal-title" id="exampleModalLabel">Create a new survey</h5>
                                                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                             </div>
                                             <div className="modal-body text-center model-image-newsurvey">
                                                  <lottie-player src="/../images/6c4fd321-e509-4d3a-a3ff-8da5bea55f10.json" background="transparent" speed={1} style={{ width: '250px', height: '250px' }} loop autoPlay />
                                                  <form className="form-group w-100">
                                                       <input type="text" className="form-control bg-grey py-3 place" id="exampleInputPassword1" placeholder="Enter Survey Title" />
                                                  </form>
                                                  <div className="text-center py-4">
                                                       <a className="mainbtn text-white" href="/">Create Survey</a>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
          </>
     )
}

export default AdminDashboard;
