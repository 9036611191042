
import React from 'react'
// import { message, Slider } from 'antd';
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../utils/apiCalling';
import { useHistory } from 'react-router-dom';
import Loader from '../../utils/loader';
import ReactPlayer from 'react-player'
import Select from "react-select";
import { useLocation } from "react-router-dom";
import Webcam from 'react-webcam';
import Config from "../../config/env";

const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: 'user',
}

function Index(props) {

    const {
        // survey, 
        // setSurveyScreen,
        //  type, 
        profilePercent } = props;

    const [surveyScreen, setSurveyScreen] = useState(false)
    const [survey, setSurveys] = useState()
    const [type, setSurveyType] = useState('survey')
    const [surveyQues, setSurveyQues] = useState()
    const [quesNo, setQuesNo] = useState(0)
    const [user, setUserData] = useState("");
    const [button, setButton] = useState(false)
    const [answer, setTemporaryAns] = useState([])
    const [progress, setProgress] = useState(0)
    const history = new useHistory();
    const [inputValue, setInputValue] = useState("")
    const [prefillData, setPrefillData] = useState({});
    const [loanNumber, setLoanNumber] = useState("")
    var [checkboxCount, setCount] = useState(0);
    const [eventRadio, setEvent] = useState("");
    const [radioOption, setRadioOption] = useState("");
    const [eventText, setEventText] = useState("");
    const [eventForm, setEventForm] = useState([])
    const [eventCheckbox, setEventCheckbox] = useState([])
    const [eventMatrix, setEventMatrix] = useState([])
    const [text_box, setTextBox] = useState(false)
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [location, setLocation] = useState()
    const [month, setMonth] = useState()
    const [emailScreen, setEmailScreen] = useState(true)
    const [surveySubmited, setSurveySubmited] = useState(false)
    const [surveyStutas, setSurveyStutas] = useState()
    const [shareId, setShareId] = useState()
    const [doubleDrop, setDoubleDrop] = useState(["", ""])
    const [loader, setLoader] = useState(false)
    const [savedAns, setSavedAns] = useState([])
    const [actionId, setActionId] = useState([])
    const [previousQue, setPreveiousQue] = useState()
    const [dropdownAns, setDropdownAns] = useState()
    const [anslength, setAnslength] = useState([])
    const [starClass, setStarClass] = useState()
    const [form, setForm] = useState(false)
    const search = useLocation().search;
    const [Namefirst, setNamefirst] = useState()
    const [qdid, setQdId] = useState()
    const [filee, setFile] = useState('')
    const [selectImage, setselectImage] = useState('no')
    const [picture, setPicture] = useState('')
    const [partnerForm, setPartnerForm] = useState(false)
    const [Images, SetImages] = useState([])
    const [newImage, setnewImage] = useState({})
    const [loanvalue, setLoanValue] = useState()
    const [count, setcount] = useState(0)
    const [idfcAgency, setIdfcAgency] = useState(false)
    // console.log("loanvalue", loanvalue)

    const webcamRef = React.useRef(null)
    const capture = React.useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot()

        setPicture(pictureSrc)
    })



    const api = new API
    const goToNextQuestion = (question, option) => {
        setCount(0)
        // console.log(answer)
        let finalAns = answer
        if (question.type === 'matrix' && question.selection === 'single') {
            let tempAns = ""
            answer.forEach(ans => {
                tempAns = `${tempAns}field:${ans.bank}-Answer:${ans.answer},`
            })
            finalAns = [tempAns]
        }
        else if (question.type === 'note') {
            setButton(false)
            setQuesNo(quesNo + 1)
            return
        }
        else if (question.type === 'matrix' && question.selection === 'multiple') {
            let tempAns = ""

            answer.forEach(ans => {
                let matAns = ""
                ans.answer.forEach(an => {
                    matAns = `${matAns} ${an},`
                })
                tempAns = `${tempAns}field:${ans.bank}-Answer:${matAns},`
            })
            finalAns = [tempAns]
        }
        else if (question.type === 'form') {
            let tempAns = "";
            let fields = Object.keys(finalAns[0])
            fields.forEach(f => {
                tempAns = `${tempAns}${f}:${finalAns[0][f]},`
            })
            finalAns = tempAns
        } else if (question.type === 'star') {
            setQuesNo(quesNo + 1)
        }


        question.answer = finalAns;

        let finalActionId = [...actionId]
        if (question.selection === "multiple") {

            question.options.forEach((op) => {
                if (op.trigger && actionId.includes(op.action_id)) {
                    finalActionId = [op.action_id]

                }
            })
        }

        setTemporaryAns([]);
        if (quesNo + 1 === surveyQues.length || finalActionId.includes('final')) {
            // setSurveys(survey.questions)

            setSurveyStutas('complete')
            saveResponses('complete');
        }
        if (finalActionId.includes("exit")) {

            saveResponses('uncomplete')
        }
        else {
            let event
            for (let i = 0; i < surveyQues.length; i++) {
                if (finalActionId.includes(surveyQues[i]._id)) {
                    setPreveiousQue(quesNo)
                    setQuesNo(i);
                    setActionId([])
                  
                    setInputValue(surveyQues[i].prefill ? prefillData[surveyQues[i]?.object]?.[surveyQues[i]?.field] : "")
                    if (surveyQues[i].prefill) {
                        let data = prefillData[surveyQues[i]?.object]?.[surveyQues[i].field]
                            console.log(data,"dataaaa")

                        event = { target: {} }
                        event['target']['value'] = data


                    }
                    if (event) {

                        setAnswer(event, surveyQues[i].type, surveyQues[i].options[0], surveyQues[i], true)
                    }
                    break
                }
               

            }
            setButton(false)
            if (question.nextQutionButton === "yes") {
                setButton(true)
            }
        }
        if (question.type === 'radio') {
            eventRadio.target.checked = false
        } else if (question.type === 'checkbox') {
            eventCheckbox.forEach((eve) => {
                eve.target.checked = false;
            })
        }
        else if (question.type === 'matrix') {
            eventMatrix.forEach((eve) => {
                eve.target.checked = false;
            })
        }
        else if (question.type === 'text') {
            eventText.target.value = ""
        } else if (question.type === 'number' || question.type === 'date') {
            eventText.target.value = ""
        }
        else if (question.type === 'form') {
            eventForm.forEach((eve) => {
                eve.target.value = "";
            })
        }
        else if (question.type === 'matrix') {
            eventRadio.target.checked = false

        }
        if (question.dependency && question.dependency === 'yes') {

            setSavedAns(answer);
        }

        setTextBox(false)
        setselectImage('no')
        setRadioOption("")
        setAnslength([])
        setDoubleDrop([])

    }

    const submitEmail = () => {

        if (!qdid) {
            toast.error("Please Enter QD id", {
                position: toast.POSITION.TOP_RIGHT
            });

        }

        else {


            registerIfNewUser()
        }
    }

    const registerIfNewUser = async () => {
        let data = {
            // name: name,
            qd_id: qdid
        }
        const result = await api.post(`${Config.new.site_api}/Avanti/SubmitSurvey`, data)
        // const result = await api.post(`${Config.new.site_api}/client/registerByEmail`, data)
        if (result.code === 200) {
            if (props.match.path === '/IDFC/Agency') {
                setIdfcAgency(true)
                setEmailScreen(false)
                surveyDynamic('6454beffdaf7b26a6df961f7')
                setName(result.data[0].name)
            } else if (props.match.path === '/IDFC/Yard') {
                setIdfcAgency(true)
                setEmailScreen(false)
                surveyDynamic('646219aa0c022822ffccd6bb')
                setName(result.data[0].name)
            } else {
                setEmailScreen(false)
                surveyDynamic("642a8c6792e2d50b453852fc")

                let pData = { ...prefillData }
                pData['reviewerData'] = result.data[0]
                setName(result.data[0].name)
                setPrefillData(pData)
            }
        }
        else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    const setQDID = async () => {
        if (Namefirst === '' || Namefirst === undefined) {
            toast.error("Please Enter Name", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else if (qdid === '' || qdid === undefined) {
            toast.error("Please Enter QD ID", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            let data = {
                name: Namefirst,
                qd_id: qdid
            }
            const result = await api.post(`${Config.new.site_api}/Avanti/avanti_info`, data)
            if (result.code === 200) {
                setQdId('')
                setNamefirst('')
                toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }
    // "642a8c6792e2d50b453852fc"
    const surveyDynamic = async (id) => {
        setLoader(true)
        let data = {
            survey_id: id,
            email: email
        }
        const result = await api.post(`${Config.new.site_api}/survey/getDynSurveyAttemp`, data)
        if (result && result.code === 200) {
            setSurveys(result.data[0])
            setSurveyType(result.data[0].type)
            if (result.data[0].response_Limit === 0) {
                toast.error("This Survey Is Closed", {
                    position: toast.POSITION.TOP_RIGHT
                });
                setSurveyScreen(false)
            } else {

                setSurveyScreen(true)
            }

            setSurveyQues(result.data[0].type === "matrix" ? result.data[0].questions[0] : result.data[0].questions)
            setLoader(false)

        }
        else {

            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });

        }
    }

    const saveProfile = async (user) => {
        setLoader(true)
        const data = {
            email: user.email,
            type: survey.survey_name,
            attempQuestionTask: JSON.stringify(surveyQues),
            profileCompletionPoint: 0,
            newPercentage: profilePercent + 10
        }
        const result = await api.post(`${Config.new.site_api}/user-panel-profile/userProfileTaskSubmit`, data);
        if (result) {
            setTimeout(() => {
                if (result.code === 200) {
                    setLoader(false)
                    setSurveyScreen(false)
                    toast.success('Profile Saved Succesfully', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    window.location.reload();
                } else {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }, 100)
        }
    }

    const saveResponses = async (status) => {

        setLoader(true)

        let result
        if (props.match.path === '/Avanti/Partner&Market') {


            let data = {
                reviewer_name: name,
                partner_name: email,
                location_name: location,
                month: month,
                questions: JSON.stringify(surveyQues)
            }

            result = await api.post(`${Config.new.site_api}/Avanti/partnerAndMarketSurvey`, data);

        } else if (props.match.path === '/IDFC/Agency' || props.match.path === '/IDFC/Yard') {
            let data = {
                Id: qdid,
                Name: name,
                type: 'agency',
                questions: JSON.stringify(surveyQues)
            }
            if (props.match.path === '/IDFC/Yard') {
                data.type = 'yard'
            }

            result = await api.post(`${Config.new.site_api}/Idfc/SubmitSurvey`, data);
        }
        else {
            let data = {
                Id: qdid,
                Name: name,
                questions: JSON.stringify(surveyQues)
            }

            result = await api.post(`${Config.new.site_api}/Avanti/SubmitSurvey`, data);
        }

        if (result.code === 200) {
            toast.success(result.message)
            setSurveyScreen(false)
            setSurveySubmited(true)
            setLoader(false)
            // getAvailableSurveys()
        } else {
            setSurveyScreen(false)
            // updateCount()
            toast.error(result.message)
            setSurveySubmited(true)
            setLoader(false)
        }
        // }
    }

    let lucidUrl
    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user'));
        setUserData(userData);
        lucidUrl = props.match.path
        if (lucidUrl === "/avanti_info" || lucidUrl === '/NewSurvey/:id' || props.match.path === '/lucidSurvey/:id') {
            setEmailScreen(false)
            setForm(true)
            // surveyDynamic()
            let params = new URLSearchParams(search).get('shareId');
            setShareId(params)
        }
        if (lucidUrl === "/Avanti/Partner&Market") {
            setPartnerForm(true)
            setEmailScreen(false)

        }

    }, [])


    const getLoanDetails = async (survey, value) => {
        if (survey?.que_message === 'Please enter loan number' && value.toString().length === 13) {
            setLoanNumber(value)
            let data = {
                loan_number: value
            }
            let result = await api.post(`${Config.new.site_api}/Avanti/loanDetails`, data)
            if (result.code === 200) {
                console.log("Data", result)
                setLoanValue(result?.data[0])
                let pData = { ...prefillData }
                pData['loanData'] = result?.data[0]



                setPrefillData(pData)
                
            }
        }
    }
    const setAnswer = (event, type, option, question, selectedColumn, selection) => {

        setInputValue(event.target.value)

        getLoanDetails(question, event.target.value)

        if (type === 'star') {

            setStarClass(event + 1)

            setButton(true)

            setTemporaryAns([...answer, event + 1])
            return
        }

        let actId = selectedColumn ? [] : [...actionId]
        // if(type==="radio" && event.target.checked){
        //     if(option.action_id.length>0){
        //         actId=""
        //     }else{
        //         actId.push(option.action_id)
        //     }
        // }
        if (event.target.checked || type === 'form' || type === 'text' || type === "number" || type === "date") {
            if (type === "radio" && actId.length > 0) {
                actId = []
                actId.push(option.action_id)

            } else {
                actId.push(option.action_id)
            }
        } else {
            if (option.trigger) {
                let index = actId.indexOf(option.action_id)
                actId.splice(index, 1)

            } else {
                let index = actId.indexOf(option.action_id)
                actId.splice(index, 1)

            }
        }
        setActionId(actId)




        if (type == "radio") {
            if (option.Imagerequired === 'yes' || option.option == 'Yes') {
                setselectImage('yes')
            } else if (option.Imagerequired === 'yes' || option.option == 'No') {
                setselectImage('no')
            }
            setEvent(event)
        } else if (type == "checkbox") {
            eventCheckbox.push(event);
            setEventCheckbox(eventCheckbox)
        }
        else if (type == "matrix") {
            eventMatrix.push(event);
            setEventMatrix(eventMatrix)
        }
        else if (type === "form") {
            eventForm.push(event)
            setEventForm(eventForm)
        }
        else {

            setEventText(event)
        }
        let ans = []

        if (type == 'checkbox' || question?.type == 'checkbox') {


            if (answer.length < 0) {
                setButton(false)
            }


            if (option && option.text_box == 'yes') {
                // option.textOpen = true
                // setTextBox(true)

                let { value, checked } = event.target;
                setButton(false)
                if (value.trim() == "" || (checked == true) || value == null || value == undefined) {
                    let ans = [...answer]
                    ans.push(event.target.value)
                    setTemporaryAns(ans)
                    setButton(false)
                }
                else {

                    answer[answer.length > 0 ? answer.length - 1 : answer.length] = `${option.option}:${event.target.value}`
                    if (checkboxCount == 0) {
                        setCount(1)
                    }

                    setButton(true)
                }

                return
            }
            else {
                if (answer.includes(event.target.value)) {

                    let index = answer.indexOf(event.target.value)
                    answer.splice(index, 1);


                }
                else {

                    setTemporaryAns([...answer, event.target.value])
                }

            }
        }


        else if (type === 'form') {

            let temp = answer.length > 0 ? answer[0] : {}

            if (selectedColumn) {
                doubleDrop[1] = event.target.value
            }
            else {
                doubleDrop[0] = event.target.value
            }

            temp[option.option] = option.text_box !== 'yes' ? doubleDrop : event.target.value
            setTemporaryAns([temp])


            let keyInAnswer = Object.keys(temp)

            let notEmpty = true
            keyInAnswer.forEach(op => {
                if (!temp[op] || temp[op] == "") {
                    notEmpty = false
                }
            })
            if (question.options.length === keyInAnswer.length && notEmpty) {

                setButton(true)
            } else {
                setButton(false)
            }


        }
        else if (type == 'video') {

            setButton(true)
        }

        else {
            if (option && option.text_box == 'yes') {
                option.textOpen = true

                setButton(false)
                if ((type == 'radio' || event.target.value.trim() == "" || event.target.value == null || event.target.value == undefined) && option.text_box == 'yes') {
                    setButton(false)
                }
                else {
                    if (type == 'radio') {
                        setRadioOption(event.target.value)
                    }
                    else {
                        setTemporaryAns(`${radioOption || option.option}:${event.target.value}`)
                    }

                    setButton(true)
                }
                return
            }

            else {

                ans.push(event.target.value)
                setTemporaryAns(ans)

                if (event.target.value.trim() == "") {
                    setButton(false)
                }
                if (ans.length == 0) {
                    setButton(false)
                }
            }
        }
        if (type !== 'matrix' && type !== 'form') {
            if (typeof event.target.value == "string") {
                if (event.target.value.trim() == "") {
                    setButton(false)

                }

                if (event.target.value.trim() > "") {

                    setButton(true)
                }
            }
        }
        if (type === "checkbox") {
            (actId.length == 0) && setButton(false)
        }
        // setButton(true)

    }

    const backQuestion = (question) => {
        setButton(false)
        if (question.type == 'radio') {
            eventRadio.target.checked = false
        } else if (question.type == 'checkbox') {
            eventCheckbox.forEach((eve) => {
                eve.target.checked = false;
            })
        }
        setQuesNo(previousQue)

    }
    const sevaImage = async () => {
        let data = {
            Id: prefillData.loanData.loan_number,
            Image: picture
        }
        let result = await api.post(`${Config.new.site_api}/Avanti/saveImage`, data)
        if (result.code === 200) {
            setFile('')
            setPicture('')
            toast.success(result.message, {
                position: toast.POSITION.TOP_RIGHT
            });

        }
    }

    const handleImageChange = (event) => {
        SetImages(event.target.files)
 
    };
    
    const sevaImage1 = async () => {
        let newData = new FormData()
        if (Images.length > 0) {
            for (let i = 0; i < Images.length; i++) {
                setcount(count + i)
                const file = Images[i];
                console.log("File", file[0])
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onloadend = async () => {
                    let data = new File([reader.result], `${loanvalue.loan_number}-${(loanvalue.branch).split(' ').join('_')}-${(loanvalue.partner).split(' ').join('_')}-${Date.now() + '-' + i}.jpeg`, { type: 'image/jpeg' })
                    // Use newFile to upload the renamed file
                    console.log("data", data)
                    newData.append('file', data);
                    if (i == Images.length - 1) {
                        newData.append("Id", prefillData.loanData.loan_number)
                        let result = await api.putPostFileFun(`${Config.new.site_api}/Avanti/saveImage`, newData)
                        console.log(result)
                        if (result.code === 200) {
                            toast.success(result.message, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            setFile('')
                            SetImages([])
                        }
                    }
                }
            }
        }

    }
    function keyPressNumbers(event) {

        const keyCode = event.keyCode;

        const excludedKeys = [8, 37, 39, 46];

        if (!((keyCode >= 48 && keyCode <= 57) ||
            (keyCode >= 96 && keyCode <= 105) ||
            (excludedKeys.includes(keyCode)))) {
            event.preventDefault();
        }
    }
    const submitPartnerForm = () => {
        if (!name) {
            toast.error("Please Enter Reviewer Name", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if (!email) {
            toast.error("Please Enter Partner Name", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if (!location) {
            toast.error("Please Enter Location", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else if (!month) {
            toast.error("Please Enter Month", {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setPartnerForm(false)
            surveyDynamic("642e71d1061023c13a7cc3cf")
        }

    }


    return (
        <main className='bg-lgrey position-relative container-fluid p-0'>
            {loader && <Loader />}
            <section id="all-section" className="admin-header position-relative">

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid mt-2 mb-2">
                        <a className="navbar-brand1" ><img src={(props.match.path === '/IDFC/Agency' || props.match.path === '/IDFC/Yard') ? "../images/idfc-first-bank-logo.jpg" : "../images/avantiFinancelogo.png"} width="100px" alt="" /></a>

                        <ul className="navbar-nav flex-row align-items-center justify-contents-center">

                        </ul>
                    </div></nav>
            </section>
            <section className='h-100 survey-screen container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-md-9 mb-5'>
                        <div className='survey-questions bg-white p-3 my-3 com-bsl w-100 mb-5'>
                            {/* <div className='company-name w-100 mb-3'><img src='../images/logo (3).png' className=''></img></div> */}
                            <div className='row justify-content-center' >
                                {!surveySubmited && <>
                                    {emailScreen &&

                                        <div className='con col-md-6'>
                                            <p className=''
                                            >General information</p>
                                            <label>Enter Qd Id :</label><br></br>
                                            <input className='form-control' onChange={(e) => setQdId(e.target.value)} placeholder='Enter your Qd Id'></input>
                                            {/* <label>Enter Name :</label><br></br> */}
                                            {/* <input className='form-control' onChange={(e) => setName(e.target.value)} placeholder='Enter your name'></input> */}
                                            <br /><br />
                                            <button className='py-2 mainbtn' onClick={submitEmail}>Submit</button>
                                        </div>
                                    }
                                    {partnerForm &&
                                        <div className='con col-md-6'>
                                            <form>
                                                <p className=''>General information</p>
                                                <label>Reviewer Name :</label><br></br>
                                                <input className='form-control' onChange={(e) => setName(e.target.value)} placeholder='Enter reviewer name'></input>
                                                <label>Partner Name :</label><br></br>
                                                <input className='form-control' onChange={(e) => setEmail(e.target.value)} placeholder='Enter partner name'></input>
                                                <label>Location Name :</label><br></br>
                                                <input className='form-control' onChange={(e) => setLocation(e.target.value)} placeholder='Enter loction'></input>
                                                <label>Month :</label><br></br>
                                                <input type='month' className='form-control' onChange={(e) => setMonth(e.target.value)} placeholder='Select month'></input>
                                                <br /><br />
                                                <button type="submit" className='py-2 mainbtn' onClick={submitPartnerForm}>Submit</button>
                                            </form>
                                        </div>
                                    }
                                    {form && <div className='con col-md-6'>
                                        <label>Enter Name :</label><br></br>
                                        <input className='form-control' value={Namefirst} onChange={(e) => setNamefirst(e.target.value)} placeholder='Enter your name'></input>
                                        <label>Enter QD Id :</label><br></br>
                                        <input className='form-control' value={qdid} onChange={(e) => setQdId(e.target.value)} placeholder='Enter your QD ID'></input>
                                        <br /><br />
                                        <button className='py-2 mr-3 mainbtn' onClick={setQDID}>Submit</button>

                                        <a href='/#/downloadexcel'> <button className='py-2 mainbtn mr-10 mr-3' >Report</button></a>
                                        <a href='/#/Image'> <button className='py-2 mainbtn mr-10' >Image</button></a>
                                    </div>}
                                    {!emailScreen && surveyScreen && <>
                                        <div className='col-md-10 justify-content-center d-flex flex-column'>
                                            <p className={surveyQues && surveyQues[quesNo].que_message ? 'fs-5 fw-bold py-2 text-center mb-0' : 'fs-5 fw-bold py-2 text-center'}>{surveyQues && surveyQues[quesNo].question}</p>
                                            <spam className='fs-5 fw-bold py-2 text-center'>{surveyQues && surveyQues[quesNo].que_message}</spam>

                                        </div>
                                        {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto" id="options" >
                                            <textarea type="text" maxLength="255" value={inputValue} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>
                                            </textarea>
                                            {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                                option.Imagerequired == 'yes' && <> <button className='mainbtn mr-3 mt-2 text-white px-4' onClick={(e) => setFile("galley")} >Gallery</button>
                                                    {/* <button className='mainbtn mt-2 text-white px-4' onClick={(e) => setFile("camera")}>Camera</button> */}
                                                </>))}
                                        </div>)}

                                        {surveyQues && surveyQues[quesNo].type == 'date' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto" id="options" >
                                            <input type={surveyQues[quesNo].selection} maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }} />

                                            {/* </textarea> */}
                                        </div>)}


                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'form' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 mb-3' >
                                                    <div className="form-check h-100">
                                                        {/* <textarea className="form-check-input fs-3" type="text" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }} /> */}
                                                        <div className='row w-100'>
                                                            <div className='col-md-8'>
                                                                <label className="fs-5 form-check-label w-100" >{option.option}</label></div>
                                                            <div className='col-md-4'>
                                                                {(option.option_type === "dropdown") ?
                                                                    <> <select className='form-control' name="que" id="que" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                        <option hidden value="">{(option.dropdown_option_header) ? option.dropdown_option_header : 'Select Option'} </option>
                                                                        {option.dropdown_option && option.dropdown_option.length > 0 && option.dropdown_option.map((op) =>
                                                                            <>
                                                                                <option value={op}>{op}</option></>
                                                                        )}
                                                                    </select>
                                                                        {(option.dropdown_option1 && option.dropdown_option1.length > 0) &&
                                                                            <select className='form-control mt-2' name="que2" id="que2" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo], surveyQues[quesNo].selection) }} >
                                                                                <option value="" hidden>{(option.dropdown_option1_header) ? option.dropdown_option1_header : "Select Option"}</option>
                                                                                {option.dropdown_option1 && option.dropdown_option1.length > 0 && option.dropdown_option1.map((op) =>
                                                                                    <>
                                                                                        <option value={op}>{op}</option></>
                                                                                )}
                                                                            </select>}
                                                                    </> :
                                                                    <input type={option.option_type} maxLength="255" className="form-control" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />}
                                                            </div>
                                                        </div>

                                                        {/* <textarea className="form-control col-md-10 mt-2" type="text" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }} /> */}
                                                        {/* {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, 'text', option,surveyQues[quesNo]) }}>
                                                        </textarea>} */}
                                                    </div>
                                                </div>))}

                                            </div>)}

                                        </div>



                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'dropdown' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 mb-3' >
                                                    <div className="form-check h-100 ">

                                                        <div className='row w-100'>
                                                            <div className='col-md-8'>
                                                                <label className="fs-5 form-check-label w-100" >{option.option}</label></div>
                                                            <div className='col-md-4'>
                                                                {/* <input type="text" maxLength="255" className="form-control" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} /> */}
                                                                {/* <Select isMulti
                                                                    placeholder={<div>Search Option</div>}
                                                                    // onClick={(e) => getCity()}
                                                                    aria-label="Default select example"
                                                                    // onChange={handleSelectedcity}
                                                                    options={
                                                                        option.dropdown_option && option.dropdown_option.map((op, index) => {
                                                                            op = op
                                                                            op = index
                                                                          
                                                                            // op
                                                                            return op;
                                                                        })

                                                                    }

                                                                    
                                                                /> */}

                                                                {option.dropdown_option1 && option.dropdown_option1.length > 0 && <select className='form-control' name="que" id="que" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                    <option disabled>Select Option</option>
                                                                    {option.dropdown_option1 && option.dropdown_option1.length > 0 && option.dropdown_option1.map((op) =>
                                                                        <>
                                                                            <option value={op}>{op}</option></>
                                                                    )}
                                                                </select>}
                                                            </div>
                                                        </div>

                                                        {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                        </textarea>}
                                                    </div>
                                                </div>
                                                ))}

                                            </div>)}

                                        </div>
                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'checkbox' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-6 mb-3' >
                                                    <div className="form-check h-100 ">
                                                        <label className="fs-5 form-check-label  w-100" >
                                                            <input className="form-check-input fs-3" type="checkbox" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />
                                                            {option.option}

                                                            {option.text_box == 'yes' && option.textOpen && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                            </textarea>}
                                                        </label>
                                                    </div>

                                                </div>))}

                                            </div>)}

                                        </div>
                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'video' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 ' >
                                                    <div className="form-checkvideo h-100 w-100 justify-content-center" style={{ backgroundColor: "#f1f1f1 !important" }}>
                                                        <ReactPlayer url={option.option} controls={true} value={option.option} onEnded={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />

                                                    </div>
                                                </div>))}

                                            </div>)}

                                        </div>
                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'note' && (<div className='row surv-op'>

                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 ' >
                                                    <div className="form-checkvideo h-100 w-100 justify-content-center" style={{ backgroundColor: "#f1f1f1 !important" }}>
                                                        {/* <ReactPlayer url='http://localhost:3009/survey_video/UPI_AutoPay.mp4'  controls={true} value={option.option} onStart={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }}/> */}

                                                    </div>
                                                </div>))}

                                            </div>)}

                                        </div>

                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'radio' && (
                                                <div className='row surv-op'>
                                                    {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-6 mb-3'>

                                                        <div className="form-check h-100 ">
                                                            <label className="fs-5  form-check-label d-flex align-items-center justify-content-between w-100" >
                                                                <input className="form-check-input fs-3" type="radio" name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                {option.option}

                                                                {option.text_box == 'yes' && option.textOpen && ((surveyQues[quesNo].selection=='date')?<input type={surveyQues[quesNo].selection} maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, "text", option) }} />
                                                                :<textarea type="text" maxLength="255" className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option) }}>
                                                                </textarea>)}
                                                                {option.Imagerequired == 'yes' && selectImage == 'yes' && <button className='mainbtn mr-3 text-white px-4' onClick={(e) => setFile("galley")} >Gallery</button>}
                                                                {/* {option.Imagerequired == 'yes' && selectImage == 'yes' && <button className='mainbtn text-white px-4' onClick={(e) => setFile("camera")}>Camera</button>} */}
                                                            </label>
                                                        </div>
                                                    </div>))}

                                                </div>)}

                                            {surveyQues && quesNo == surveyQues.length - 1 && button && (<div className='w-100 text-center my-4'>
                                                <a className='mainbtn text-white px-4' onClick={(e) => goToNextQuestion(surveyQues[quesNo])}>Submit</a>
                                            </div>)}

                                        </div>

                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == "number" && (<div className=""  >
                                                {(surveyQues[quesNo].selection === "mobile") ? <input type='number' onKeyDown={(event) => keyPressNumbers(event)} maxLength={surveyQues[quesNo].inputLimit} className="form-control col-md-10 mt-2" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0], surveyQues[quesNo]) }} /> :
                                                    <input type="number" value={inputValue} className="form-control col-md-10 mt-2" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0], surveyQues[quesNo]) }} />}
                                                {surveyQues[quesNo].options[0].Imagerequired === 'yes' && <button className='mainbtn mr-3 text-white px-4 mt-2' onClick={(e) => setFile("galley")}>Gallery</button>}
                                                {/* {surveyQues[quesNo].options[0].Imagerequired === 'yes' && <button className='mainbtn text-white px-4 ' onClick={(e) => setFile("camera")}>Camera</button>} */}


                                                {/* </input> */}
                                            </div>)}
                                        </div>

                                        <div className="col-md-12 ">
                                            {surveyQues && surveyQues[quesNo].type == 'star' && (<div className="">
                                                {/* app.css line no.-6842    color: #F8B84E !important;*/}
                                                <div className="quationSec w-100 position-relative mt-3">

                                                    <div className="optionsBox w-100 mb-5">
                                                        <div className="startReview1" id="startReview1" style={{ display: '{{(isset($created_survey_options[0]["option_value"]) && ($created_survey_options[0]["option_value"] == "startReview1") ? "block":"none")}}' }}>
                                                            <ul className="d-flex p-0 startReview" >
                                                                {/* <li><a href="javascript:void(0);"><i className="fa fa-star-o" />
                                                                        <i className="fa fa-star" />
                                                                        <span>0</span></a>
                                                                    </li> */}
                                                                {surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (

                                                                    <li><a style={{ color: '#F8B84E ' }} onClick={(e) => setAnswer(index, surveyQues[quesNo].type, surveyQues[quesNo])}><i className={starClass >= index + 1 ? "fa fa-star" : "fa fa-star-o"} />
                                                                        <i className="fa fa-star" />
                                                                        <span>{option}</span></a>
                                                                    </li>))}
                                                                {/* <li><a href="javascript:void(0);" style={{color: '#F8B84E '}} value={2} onClick={()=>setButton(true)}><i className="fa fa-star-o"  />
                                                                        <i className="fa fa-star" />
                                                                        <span>2</span></a>
                                                                    </li>
                                                                    <li><a href="javascript:void(0);" style={{color: '#F8B84E '}} value={3} onClick={()=>setButton(true)}><i className="fa fa-star" />
                                                                        <i className="fa fa-star" />
                                                                        <span>3</span></a>
                                                                    </li>
                                                                    <li><a href="javascript:void(0);" style={{color: '#F8B84E '}} value={4} onClick={()=>setButton(true)}><i className="fa fa-star-o" />
                                                                        <i className="fa fa-star" />
                                                                        <span>4</span></a>
                                                                    </li>
                                                                    <li><a href="javascript:void(0);" style={{color: '#F8B84E '}} value={5} onClick={()=>setButton(true)}><i className="fa fa-star-o" />
                                                                        <i className="fa fa-star" />
                                                                        <span>5</span></a>
                                                                    </li> */}


                                                                {/* <li><a href="javascript:void(0);" className="tooltip swipIocn" id="swipIcon1" data-id="startReview2"><img src="../img/swip-icon.svg" height="28px" />
                                                                        <span className="tooltiptext ">Reverse Rating</span></a>
                                                                    </li> */}
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>)}
                                        </div>


                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == "matrix" && (
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            {surveyQues[quesNo].matOption && surveyQues[quesNo].matOption.length > 0 && surveyQues[quesNo].matOption.map((column, index) => (
                                                                <th scope="col" key={index}>{column} </th>

                                                            ))

                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {surveyQues[quesNo].options && surveyQues[quesNo].options.length > 0 && surveyQues[quesNo].options.map((row, index) => (
                                                            <tr>

                                                                <>
                                                                    <th scope="row">{row.option}</th>
                                                                    {surveyQues[quesNo].matOption && surveyQues[quesNo].matOption.length > 0 && surveyQues[quesNo].matOption.map((column, i) => (
                                                                        <td>
                                                                            <div className="form-check">
                                                                                {surveyQues[quesNo].selection == 'single' && <input className="form-check-input" type="radio" value={column} onClick={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], row.option)} name={index} />
                                                                                }
                                                                                {surveyQues[quesNo].selection == 'multiple' && <input type="checkbox" name="checkbox" value={row.option} onClick={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], column)} className="form-check-input" />
                                                                                }
                                                                            </div>
                                                                        </td>

                                                                    ))}


                                                                </>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                        <div className='col-md-12 text-center'>
                                            {surveyQues && quesNo < surveyQues.length - 1 && (button || surveyQues[quesNo].prefill) && (<div className='py-3'><button className=' mainbtn ' onClick={(e) => { goToNextQuestion(surveyQues[quesNo]) }}  >Next Question</button></div>)}

                                        </div>
                                    </>}
                                </>}
                                {surveySubmited && <div className="row m-0">
                                    <div className="col-md-12 p-0">

                                        <div className="w-100 qusSec px-4 py-5 h-100 text-center congSrc">
                                            {(surveyStutas == 'complete') && <h3 style={{ color: "#ee6917" }}>Congratulations!! </h3>}
                                            <lottie-player src="../images/112766.json" background="transparent" speed={1} className=" text-center mx-auto d-block" style={{ height: '150px' }} autoPlay>
                                            </lottie-player>
                                            {/* <h4>Congratulations!!</h4> */}
                                            {(surveyStutas == 'complete') ? <p style={{ fontWeight: 600 }} className="mb-5">You have successfully submited the survey</p> : <p style={{ fontWeight: 600 }} className="mb-5">Thank You<br />You are not eligible for this survey</p>}
                                            <a  className="mainbtn" data-bs-dismiss="modal" onClick={()=>window.location.reload(false)}  >Add New from</a>
                                        </div>
                                    </div>
                                </div>}
                                <div className='d-flex flex-column align-items-center' style={{ gap: '15px' }}>
                                    {filee == "galley" && <input type="file" name="file"  onChange={handleImageChange} multiple />}
                                    {/* {filee == "galley" && Images && Images?.map(e=>{<img src={e} style={{ width: '100%', maxWidth: 400 }} />})} */}
                                    {filee == "galley" && Images.length !== 0 ? (
                                        <button onClick={sevaImage1} className="btn btn-primary"> Upload
                                        </button>
                                    ) : ""}
                                </div>

                                {filee == "camera" && <div>
                                    <div className='d-flex justify-content-center mb-4'>

                                        {picture == '' ? (
                                            <Webcam audio={false} height={400} ref={webcamRef} width={400} screenshotFormat="image/jpeg" videoConstraints={videoConstraints} />
                                        ) : (
                                            <img src={picture} />
                                        )}
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {picture !== '' ? (
                                            <button
                                                onClick={sevaImage}
                                                className="btn btn-primary"
                                            >
                                                Upload
                                            </button>
                                        ) : (
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    capture()
                                                }}
                                                className="btn btn-danger"
                                            >
                                                Capture
                                            </button>
                                        )}
                                    </div>
                                </div>}
                                {/* {!surveyScreen && !surveySubmited &&!emailScreen&& <div className="row m-0">
                                    <div className="col-md-12 p-0">

                                        <div className="w-100 qusSec px-4 py-5 h-100 text-center congSrc ">

                                            <lottie-player src="../images/112766.json" background="transparent" speed={1} className="mt-10 text-center mx-auto d-block" style={{ height: '150px' }} autoPlay>
                                            </lottie-player>
                                            <h3 className='mt-5' style={{ color: "#ee6917" }}>This Survey Is Closed </h3>
                                          
                                        </div>
                                    </div>
                                </div>} */}

                            </div>
                        </div>

                    </div>
                </div>




                <div className='w-100' style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
                    <div className='container'>
                        <div className='row progress-survey w-100 bg-white com-bsl m-0 p-2'>
                            {surveyQues && quesNo > 0 && <div className='col-1 p-0'>
                                <div className='bg-blue h-100 d-flex align-items-center justify-content-center w-fit rounded-2'><i className="bi bi-chevron-left text-white fs-3" onClick={(e) => { backQuestion(surveyQues[quesNo]) }}></i></div>
                            </div>}

                        </div>
                    </div>
                </div>
            </section>
            <div className='w-100  powered-btn'>
                <a href='https://qdegrees.com/' target="_blank"> <div className='mainbtnblue m-2 position-fixed bottom-0 end-0'><small className='f-12'>Powered by</small>&nbsp;<b className='f-14'>DoYourSurvey</b></div></a>
            </div>
            <ToastContainer />
        </main>
    )
}

export default Index

