import React, { useState } from 'react'
// import Header from '../Components/Header';

// import Footer from '../Components/Footer';
function AllRecentSyrvey() {
    const [divClass, setDivClass] = useState(" ")
    const [divId, setDivId] = useState("sidebar")
    // const[changeId,setChangeId]=useState("sidebar")
    // const handleId=()=>{
    //     if(changeId==="sidebar"){
    //         setChangeId(" ")

    //     }else{
    //         setChangeId("sidebar")

    //     }

    // }
    const changeclassname = () => {
        if (divId === "sidebar" && divClass === " ") {
            setDivClass("handleClassNameAndId")
            setDivId(" ")
        } else {
            setDivClass(" ")
            setDivId("sidebar")

        }
    }


    return (
        <>
            <section id="all-section" className="admin-header">
                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid ">
                        <a className="navbar-brand1" href="#"><img src="../images/new-logo.png" width="100px" alt="" /></a>
                        <ul className="navbar-nav flex-row">
                            <div className="nav-item dropdown notification-drop">
                                <a id="dLabel" role="button" className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi bi-bell-fill text-blue fs-4 position-relative"><span className="position-absolute top-0 start-100 translate-middle badge rounded-circle text-blue f-12 bg-grey">
                                        <small className='fw-bold'>10</small>
                                        <span className="visually-hidden">unread messages</span>
                                    </span></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-lg-end notifications" role="menu" aria-labelledby="dLabel">
                                    <div className="notification-heading"><p className="text-orange m-2 f-14">Notifications</p>
                                    </div>
                                    <li className="divider" />
                                    <div className="notifications-wrapper">
                                        <a className="content" href="#">
                                            <div className="notification-item  read">
                                                <img src='../images/2.png'></img>
                                                <div className='tableform'>

                                                    <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                    <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                                </div></div>
                                        </a>
                                        <a className="content" href="#">

                                            <div className="notification-item  read">
                                                <img src='../images/2.png'></img>
                                                <div className='tableform'>
                                                    <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                    <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                </div></div>
                                        </a>
                                        <a className="content" href="#">

                                            <div className="notification-item ">
                                                <img src='../images/2.png'></img>
                                                <div className='tableform'>
                                                    <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                    <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                </div></div>
                                        </a>
                                        <a className="content " href="#">

                                            <div className="notification-item  ">
                                                <img src='../images/2.png'></img>
                                                <div className='tableform'>
                                                    <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                    <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                </div></div>
                                        </a>
                                        <a className="content " href="#">

                                            <div className="notification-item ">
                                                <img src='../images/2.png'></img>
                                                <div className='tableform'>
                                                    <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                    <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                </div></div>
                                        </a>
                                        <a className="content " href="#">

                                            <div className="notification-item ">
                                                <img src='../images/2.png'></img>
                                                <div className='tableform'>
                                                    <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                    <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                </div></div>
                                        </a>
                                    </div>

                                </ul>
                            </div>
                            <li className="nav-item dropdown">
                                <a className="nav-link nav dropdown-toggle d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="../images/2@2x.png" width="50px" className='rounded-circle' alt="" /><p className='aname m-0 px-2 text-black'>Smith Jones</p><i className="text-black bi bi-chevron-down"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-lg-end">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div></nav>
            </section>

            <section id="all-section" className="section-survey ">
                <div className="container-fluid ">
                    <div className="row ">
                        <div className="wrapper">
                            {/* Sidebar  */}
                            <nav id={divId === "sidebar" ? "sidebar" : " "} className={divClass === "handleClassNameAndId" ? "handleClassNameAndId" : " "} >
                                {/*{changeId}  onClick={handleId} */}
                                <div className="search-container position-relative m-2">
                                    <button type="button" id="sidebarCollapse" onClick={changeclassname} className="btn border-0 text-blue">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="white" className="bi bi-arrow-left-right" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                                        </svg>
                                    </button>
                                    <form action="/action_page.php" className="bg-lgrey d-flex align-items-center">
                                        <button type="submit" className="border-0"><img src="../images/Icon feather-search.svg" alt="" className="m-2 w-50" /></button>
                                        <input type="text" placeholder="Find Survey" name="search" className="bg-lgrey form-control" />
                                    </form>
                                </div>
                                <details className="sidebar-details">
                                    <summary>
                                        <div className="sidebar-btn"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                            <p className=" f-14 m-0">Private</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg>
                                        </div>
                                    </summary>
                                    <div>
                                        <div className="f-14 d-flex justify-content-between py-4">
                                            <p>My Workspace</p>
                                            <p>1</p>
                                        </div>
                                    </div></details>
                            </nav>
                            {/* Page Content  */}
                            <div id="content">
                                <nav className="navbar navbar-expand-lg  bg-lgrey px-2">
                                    <div className="container-fluid p-0">

                                        <p className="text-orange boldnormal-p m-0">My Workspace</p>

                                        <div className="vr m-3" />
                                        <a href className="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="currentColor" className="bi bi-share-fill text-blue" viewBox="0 0 16 16">
                                            <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                                        </svg>
                                            <p className="px-2 m-0">Share</p>
                                        </a>
                                        <div className=" dropdown ">
                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                </svg></p>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                            </ul>
                                        </div>
                                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon" />
                                        </button>
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                            <div className="mx-auto my-2 d-flex justify-content-around align-items-center ">

                                                {/* <li className="nav-item dropdown admin-drop ">
                            <a className="nav-link dropdown-toggle d-flex align-items-center justify-content-between bg-white border px-2 m-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <p className=" m-0 admin-drop-p py-1 ">Active Survey</p>
                            </a>
                            <ul className="dropdown-menu dropdown-menu">
                              <li><a className="dropdown-item" href="#">Action</a></li>
                              <li><a className="dropdown-item" href="#">Another action</a></li>
                              <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                          </li> */}

                                                <select className="form-select survey-op" aria-label="Default select example">
                                                    <option selected>Active Survey</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                                <select className="form-select survey-op" aria-label="Default select example">
                                                    <option selected>Date Created</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                                {/* <li className="nav-item dropdown admin-drop">
                            <a className="nav-link dropdown-toggle d-flex align-items-center justify-content-between bg-white border px-2 m-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <p className=" m-0 admin-drop-p py-1">Date Created</p>
                            </a>
                            <ul className="dropdown-menu dropdown-menu">
                              <li><a className="dropdown-item" href="#">Action</a></li>
                              <li><a className="dropdown-item" href="#">Another action</a></li>
                              <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                          </li> */}

                                            </div>
                                            <ul className="nav navbar-nav ml-auto layout-admin">
                                                <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-grid-3x3-gap-fill" viewBox="0 0 16 16">
                                                            <path d="M1 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2zM1 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V7zM1 12a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-2zm5 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-2z" />
                                                        </svg>Grid</button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"><img src="../images/Icon ionic-md-list.svg" alt="" />List</button>
                                                    </li>
                                                </ul>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>

                                <div className="w-100 bg-lgrey p-2">
                                    <button className="mainbtn " data-bs-toggle="modal" data-bs-target="#create-survey-modal">Create Survey</button>
                                </div>
                                <div className="w-100 bg-lgrey p-2">
                                    <button className="mainbtn " data-bs-toggle="modal" data-bs-target="#create-survey-modal-options">Create Survey-options</button>
                                </div>
                                <div className="surveys-bar px-4  bg-white">
                                    <div className="tab-content" id="myTabContent">
                                        <p className="text-orange boldnormal-p m-0 py-3">Recent Survey</p>

                                        <div className='row'>
                                            <div className='col-sm-3'>
                                                <div className='card'>
                                                    <div className=''>
                                                        <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='text-center'>

                                                        <img className='' src='../images/Group 7568.svg' width={"100px"}></img>
                                                        <p className="text-orange boldnormal-p m-0 py-3">Feedback Survey</p>
                                                        <p className='sub-mini-recent'>Question: 10</p>
                                                        <p className='sub-mini-recent'>Date : 02/Sep/2022</p>
                                                        <hr></hr>
                                                        <div className='d-flex justify-content-around'>
                                                            <i className="bi bi-play-fill fs-4" style={{ color: "#0E9023" }}></i>
                                                            <i className="bi bi-pause-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                            <i className="bi bi-square-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className='card'>
                                                    <div className=''>
                                                        <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='text-center'>

                                                        <img className='' src='../images/Group 7568.svg' width={"100px"}></img>
                                                        <p className="text-orange boldnormal-p m-0 py-3">Research Survey</p>
                                                        <p className='sub-mini-recent'>Question: 10</p>
                                                        <p className='sub-mini-recent'>Date : 02/Sep/2022</p>
                                                        <hr></hr>
                                                        <div className='d-flex justify-content-around'>
                                                            <i className="bi bi-play-fill fs-4" style={{ color: "#0E9023" }}></i>
                                                            <i className="bi bi-pause-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                            <i className="bi bi-square-fill fs-4" style={{ color: "#FF0000" }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className='card'>
                                                    <div className=''>
                                                        <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='text-center'>

                                                        <img className='' src='../images/Group 7568.svg' width={"100px"}></img>
                                                        <p className="text-orange boldnormal-p m-0 py-3">Quiz Survey</p>
                                                        <p className='sub-mini-recent'>Question: 10</p>
                                                        <p className='sub-mini-recent'>Date : 02/Sep/2022</p>
                                                        <hr></hr>
                                                        <div className='d-flex justify-content-around'>
                                                            <i className="bi bi-play-fill fs-4" style={{ color: "#0E9023" }}></i>
                                                            <i className="bi bi-pause-fill fs-4" style={{ color: "#F47321" }}></i>
                                                            <i className="bi bi-square-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className='card'>
                                                    <div className=''>
                                                        <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='text-center'>

                                                        <img className='' src='../images/Group 7568.svg' width={"100px"}></img>
                                                        <p className="text-orange boldnormal-p m-0 py-3">Registration Survey</p>
                                                        <p className='sub-mini-recent'>Question: 10</p>
                                                        <p className='sub-mini-recent'>Date : 02/Sep/2022</p>
                                                        <hr></hr>
                                                        <div className='d-flex justify-content-around'>
                                                            <i className="bi bi-play-fill fs-4" style={{ color: "#0E9023" }}></i>
                                                            <i className="bi bi-pause-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                            <i className="bi bi-square-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                           

                                        </div>

                                        <p className="text-orange boldnormal-p m-0 py-3">All Survey</p>

                                        <div className='row py-2'>
                                            <div className='col-sm-3'>
                                                <div className='card'>
                                                    <div className=''>
                                                        <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='text-center'>

                                                        <img className='' src='../images/Group 7568.svg' width={"100px"}></img>
                                                        <p className="text-orange boldnormal-p m-0 py-3">Feedback Survey</p>
                                                        <p className='sub-mini-recent'>Question: 10</p>
                                                        <p className='sub-mini-recent'>Date : 02/Sep/2022</p>
                                                        <hr></hr>
                                                        <div className='d-flex justify-content-around'>
                                                            <i className="bi bi-play-fill fs-4" style={{ color: "#0E9023" }}></i>
                                                            <i className="bi bi-pause-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                            <i className="bi bi-square-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className='card'>
                                                    <div className=''>
                                                        <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='text-center'>

                                                        <img className='' src='../images/Group 7568.svg' width={"100px"}></img>
                                                        <p className="text-orange boldnormal-p m-0 py-3">Feedback Survey</p>
                                                        <p className='sub-mini-recent'>Question: 10</p>
                                                        <p className='sub-mini-recent'>Date : 02/Sep/2022</p>
                                                        <hr></hr>
                                                        <div className='d-flex justify-content-around'>
                                                            <i className="bi bi-play-fill fs-4" style={{ color: "#0E9023" }}></i>
                                                            <i className="bi bi-pause-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                            <i className="bi bi-square-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className='card'>
                                                    <div className=''>
                                                        <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='text-center'>

                                                        <img className='' src='../images/Group 7568.svg' width={"100px"}></img>
                                                        <p className="text-orange boldnormal-p m-0 py-3">Feedback Survey</p>
                                                        <p className='sub-mini-recent'>Question: 10</p>
                                                        <p className='sub-mini-recent'>Date : 02/Sep/2022</p>
                                                        <hr></hr>
                                                        <div className='d-flex justify-content-around'>
                                                            <i className="bi bi-play-fill fs-4" style={{ color: "#0E9023" }}></i>
                                                            <i className="bi bi-pause-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                            <i className="bi bi-square-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-3'>
                                                <div className='card'>
                                                    <div className=''>
                                                        <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className='text-center'>

                                                        <img className='' src='../images/Group 7568.svg' width={"100px"}></img>
                                                        <p className="text-orange boldnormal-p m-0 py-3">Feedback Survey</p>
                                                        <p className='sub-mini-recent'>Question: 10</p>
                                                        <p className='sub-mini-recent'>Date : 02/Sep/2022</p>
                                                        <hr></hr>
                                                        <div className='d-flex justify-content-around'>
                                                            <i className="bi bi-play-fill fs-4" style={{ color: "#0E9023" }}></i>
                                                            <i className="bi bi-pause-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                            <i className="bi bi-square-fill fs-4" style={{ color: "#C0C0C0" }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <hr></hr>

                                        <div className='row'>
                                            <div className="w-100 table-responsive table-view">
                                                <table className="w-100">
                                                    <thead>
                                                        <tr>
                                                            <th>Survey</th>

                                                            {/* <th>Question</th>
              <th>Responses</th> */}
                                                            <th>Question</th>
                                                            <th>Responses</th>
                                                            <th>Completion</th>
                                                            <th>More</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="feedbackTxt">
                                                                    <div className="iconBox">
                                                                        <img src="../images/Group 7568.svg" width={"70px"} className />
                                                                    </div>
                                                                    <div className>
                                                                        <h6 style={{ color: "#ee6917" }}>Feedback Survey</h6>
                                                                        <p>Created: 01 Sep 2022</p>
                                                                        <p>Last Modified : 2d ago</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><div className="txtlb">2</div></td>
                                                            <td><div className="txtlb">0</div></td>
                                                            <td><div className="txtlb">50%</div></td>
                                                            
                                                            <td>
                                                                <div className="palyicons">
                                                                    <a href="#"><i className="fa fa-play" aria-hidden="true" /></a>
                                                                    <a href="#"><i className="fa fa-pause" aria-hidden="true" /></a>
                                                                    <a href="#"><i className="fa fa-stop" aria-hidden="true" /></a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="tablIcon">
                                                                <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                                    {/* <a href="#" data-bs-toggle="modal" data-bs-target=".remindr"><i className="fa fa-calendar" aria-hidden="true" /></a> */}
                                                                </div>
                                                            </td>
                                                          
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="feedbackTxt">
                                                                    <div className="iconBox">
                                                                        <img src="../images/Group 7568.svg" className />
                                                                    </div>
                                                                    <div className>
                                                                        <h6 style={{ color: "#ee6917" }}>Feedback Survey</h6>
                                                                        <p>Created: 01 Sep 2022</p>
                                                                        <p>Last Modified : 2d ago</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><div className="txtlb">2</div></td>
                                                            <td><div className="txtlb">0</div></td>
                                                            <td><div className="txtlb">50%</div></td>
                                                            <td>
                                                                <div className="palyicons">
                                                                    <a href="#"><i className="fa fa-play" aria-hidden="true" /></a>
                                                                    <a href="#"><i className="fa fa-pause" aria-hidden="true" /></a>
                                                                    <a href="#"><i className="fa fa-stop" aria-hidden="true" /></a>
                                                                </div>
                                                            </td>
                                                            {/* <td>
                                                                <div className="tablIcon">
                                                                    <a href="#" data-bs-toggle="modal" data-bs-target=".setRemindr"><i className="fa fa-calendar" aria-hidden="true" /></a>
                                                                </div>
                                                            </td> */}
                                                            <td>
                                                                <div className="tablIcon">
                                                                <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="feedbackTxt">
                                                                    <div className="iconBox">
                                                                        <img src="../images/Group 7568.svg" className />
                                                                    </div>
                                                                    <div className>
                                                                        <h6 className='' style={{ color: "#ee6917" }}>Feedback Survey</h6>
                                                                        <p>Created: 01 Sep 2022</p>
                                                                        <p>Last Modified : 2d ago</p>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td><div className="txtlb">2</div></td>
                                                            <td><div className="txtlb">0</div></td>
                                                            <td><div className="txtlb">50%</div></td>
                                                            <td>
                                                                <div className="palyicons">
                                                                    <a href="#"><i className="fa fa-play" aria-hidden="true" /></a>
                                                                    <a href="#"><i className="fa fa-pause" aria-hidden="true" /></a>
                                                                    <a href="#"><i className="fa fa-stop" aria-hidden="true" /></a>
                                                                </div>
                                                            </td>
                                                            {/* <td>
                                                                <div className="tablIcon">
                                                                    <a href="#"><i className="fa fa-calendar" aria-hidden="true" /></a>
                                                                </div>
                                                            </td> */}
                                                            <td>
                                                                <div className="tablIcon">
                                                                <div className="dropdown d-flex justify-content-end">
                                                            <a className="dropdown-toggle  d-flex three-dot-drop" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <p className=" m-0  "><svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                                                </svg></p>
                                                            </a>
                                                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start ">
                                                                <li><a className="dropdown-item" href="#">Rename</a></li>
                                                                <li><a className="dropdown-item text-danger" href="#">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* <div className="tab-pane fade show active  layout-grid" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                      <div className="layout-grid-content d-flex justify-content-center">
                        <lottie-player src="../images/a5afec80-d37c-40cf-a91f-0c346cd2d996.json" background="transparent" speed={1} style={{ width: '40%', height: '40%' }} loop autoPlay />
                      </div>
                      <div className="grid-content w-100 text-center">
                        <h5 className="text-orange">
                          Come on in, John Smith
                        </h5>
                        <p className="pb-4">It's time to create something special.</p>
                        <button className="mainbtn " data-bs-toggle="modal" data-bs-target="#create-survey-modal">Create Survey</button>
                      </div>
                    </div> */}
                                        <div className="tab-pane fade  layout-list" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex={0}>Hi content in list here</div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        {/* modals */}

                        <div className="modal fade survey-popup" id="create-survey-modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered create-survey-modal">
                                <div className="modal-content">
                                    <div className="modal-header d-flex align-items-center">
                                        <h5 className="modal-title" id="exampleModalLabel">Create a new survey</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body py-5">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="card bg-lgrey bg-gray-card-border text-center" type="button" data-bs-toggle="modal" data-bs-target="#from-scratch-modal">
                                                    <lottie-player src="../images/85b866aa-4863-4db0-8817-b61c399de968.json" background="transparent" speed={1} style={{ width: '12.5rem', height: '12.5rem' }} loop autoPlay />
                                                    <div className="card-body p-3 text-center">
                                                        <h5 className="card-title">Start from scratch</h5>
                                                        <p className="card-text">Jump right in and build something beautiful</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="card bg-lgrey bg-gray-card-border ">
                                                    <lottie-player src="../images/1c33fc10-e7d9-497c-9765-43d68895b665.json" background="transparent" speed={1} style={{ width: '12.5rem', height: '12.5rem' }} loop autoPlay />
                                                    <div className="card-body p-3 text-center">
                                                        <h5 className="card-title">Use a template</h5>
                                                        <p className="card-text">Pick a premade survey and customize it as you
                                                            please <br />Or <a href><u>import your questions</u></a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="card bg-lgrey bg-gray-card-border ">
                                                    <lottie-player src="../images/67ea5ba5-92b0-4d1b-81b9-f0b09b98f6f5.json" background="transparent" speed={1} style={{ width: '12.5rem', height: '12.5rem' }} loop autoPlay />
                                                    <div className="card-body p-3 text-center">
                                                        <h5 className="card-title">Create it for me</h5>
                                                        <p className="card-text">Tell us what you need and we will generate a survey
                                                            based on that</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="modal fade survey-popup" id="create-survey-modal-options" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered create-survey-modal">
                                <div className="modal-content">
                                    <div className="modal-header d-flex align-items-center">
                                        <h5 className="modal-title" id="exampleModalLabel">Select your survey platform</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className=" py-5">


                                        <div className='col-lg-12'>

                                            <select className="form-select p-3 rounded-0 w-100" aria-label="Default select example">
                                                <option selected>Digital Surveys (CAWI)</option>
                                                <option value="1">Focused Group Discussion (FGD)</option>
                                                <option value="2">Telephonic Interview (CATI)</option>
                                                <option value="3">Personal Interview (CAPI)</option>
                                            </select>

                                        </div>
                                        
                                        <div className="row py-2">
                                            <div className="col-sm-6">
                                                <div className='text-center w-100 p-2'>
                                                <img src='../images/Mask Group 37.png' width={'300px'}></img>
                                                </div>

                                                    </div>
                                                    <div className='col-sm-6'>
                                                <div className="text-center">
                                                    <div className="card-body p-3 text-left">
                                                        <h5 className="card-title">Focused Group Discussion (FGD)</h5>
                                                        <p className="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-center mt-3 p-2">
                                            <a className="mainbtn text-white">Submit</a>
                                        </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                        {/* scratch modal */}
                        <div className="modal fade survey-popup" id="from-scratch-modal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog h-100 modal-dialog-centered survey-title-modal">
                                <div className="modal-content">
                                    <div className="modal-header pb-0">
                                        <h5 className="modal-title" id="exampleModalLabel">Create a new survey</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                    </div>
                                    <div className="modal-body text-center model-image-newsurvey">
                                        <lottie-player src="/../images/6c4fd321-e509-4d3a-a3ff-8da5bea55f10.json" background="transparent" speed={1} style={{ width: '250px', height: '250px' }} loop autoPlay />
                                        <form className="form-group w-100">
                                            <input type="text" className="form-control bg-grey py-3 place" id="exampleInputPassword1" placeholder="Enter Survey Title" />
                                        </form>
                                        <div className="text-center py-4">
                                            <a className="mainbtn text-white">Create Survey</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllRecentSyrvey;
