import { useHistory, useParams, Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import API from '../../utils/apiCalling';
import { FB_APP_ID, GOOGLE_CLIENT_ID, MICROSOFT_CLIENT_ID } from './SocialKey';
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { ToastContainer, toast } from 'react-toastify'
import validator from "validator";
import Config from '../../config/env';

const Signup = () => {
     let api = new API()
     const [email, setEmail] = useState();
     const [referalcode, setReffercode] = useState('');
     const history = useHistory();
     const Params = useParams();
     const [message, setMessage] = useState("")



     // useEffect(()=>{
     // const auth= localStorage.getItem('user');
     // if(auth){
     //      history('/otp-verify')
     // }
     //  })

     const handleSignup = async () => {

          if (!email || (validator.isEmail(email)) == '') {
               setMessage("Please, enter valid Email!");
          }
          else {
               let reffercode = referalcode && referalcode !== '' ? referalcode : Params.reffer;
             
               let result = await api.post(`${Config.new.site_api}/client/sendOtpForVerifyEmail`, { email, reffercode })
               if (result.code === 200) {     
                  
                    localStorage.setItem("user_email", email);
                  
                    localStorage.setItem("reffer", JSON.stringify(reffercode));
                    // localStorage.setItem("user", JSON.stringify(result.data));
                    history.push('/otpVerify');
               } if (result.code !== 200) {
                 
                    // alert(result.message)
                    toast.error(result.message, {
                         position: toast.POSITION.TOP_RIGHT
                    });
               }
               //  else{
               //      alert('please enter correct details')
               //  }
          }


     }





     //Login with google start
     const responseGoogle = async (response) => {

          const registredwith = {
               registredId: response.googleId,
               email: response.profileObj.email
          }

          let isRegistred = await isLoginSubmit(registredwith)

          if (isRegistred == undefined) {
               //   toast.warning("User not registered please signup now!")
               // alert('User not registered please signup now!')
               toast.error('User not registered please signup now!', {
                    position: toast.POSITION.TOP_RIGHT
               });

          }
     }

     const responseGoogleFailed = () => {
        
     }



     const isLoginSubmit = async (registredId) => {
     
          // let result = await api.post('http://localhost:3002/client/isRegistred', registredId)
          let result = await fetch(`${Config.new.site_api}/client/isRegistred`, {
               method: 'post',
               body: JSON.stringify(registredId),
               headers: { 'content-Type': 'application/json' },
          });
          result = await result.json();
          
          if (result.code === 200) {

               // localStorage.setItem("user", JSON.stringify(result.data));
               // localStorage.setItem("token", JSON.stringify(result.token));
               // alert(result.message)
               toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
               });

               if (result.data.userType = "client") {
                    history.push("/client")
               } else {
                    history.push("/panelistDash")
               }
               return "success"
          } else {
               // toast.error(result.message)
               toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
               });

               return
          }
     }
     
     //login with google end

     //login with facebook start
     const responseFacebook = async (response) => {


          const registredwith = {
               registredId: response.userID,
               email: response.email
          }

          let isRegistred = await isLoginSubmit(registredwith)
          if (isRegistred == undefined) {
               //   toast.warning("User not registered please signup now!")
               toast.error("User not registered please signup now!", {
                    position: toast.POSITION.TOP_RIGHT
               });

          }
     }
     const responseFacebookFailed = () => {
        
     }

     useEffect(() => {
          //  setReffercode(Params.reffer?Params.reffer:referalcode)
     }, [])

     return (
          <div>
               <section className="loginSec">
                    <div className="container text-center h-100">
                         <div className="row justify-content-center">
                              <div className="col-md-6 col-lg-5">
                                   <div className="bg-white loginBox">
                                        <Link to="/"> <img src="../images/new-logo.png" alt width="140px" /></Link>
                                        <h1 className="loginHeading text-left mb-4">
                                             Sign Up
                                        </h1>
                                        <form >
                                             <div className="input-group mb-2">
                                                  <span className="input-group-text"><i className="fa fa-envelope" /></span>
                                                  <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Enter Your Email" name="email" value={email} required />
                                             
                                                  
                                             </div>
                                             <span style={{color: "red",width: "100%", display: "block",textAlign: 'left'}}>
                                                       {message}
                                                  </span>
                                            
                                             <div className="input-group my-4">
                                                  <span className="input-group-text"><i className="fa fa-user-plus" /></span>
                                                  <input type="text" onChange={(e) => setReffercode(e.target.value)} className="form-control" placeholder="Refer Code (Optional)" value={Params.reffer} name="referalcode" />
                                             </div>
                                             {/* {<Link to="/otpVerify"><button type="submit" className="btn mainbtn tada w-100">Verify</button></Link> } */}
                                             <button type="button" onClick={handleSignup} className="mainbtnblue tada w-100">Verify</button>
                                        </form>
                                        <div className="d-flex btnLogType loginGoogleBtn my-4 flex-wrap justify-content-center">
                                             <GoogleLogin
                                                  type='button'
                                                  boxshadow='none'
                                                  icon={false}
                                                  clientId={GOOGLE_CLIENT_ID}
                                                  onSuccess={responseGoogle}
                                                  onFailure={responseGoogleFailed}
                                             > 
                                                  <i className=" tada fa fa-google " > </i>Google
                                             </GoogleLogin>
                                             {/* <button type="button" class="kep-login-google metro"><i class="fa fa-google text-primary new"></i> Google</button> */}
                                             <FacebookLogin
                                                  fields="name,email,picture"
                                                  // cssClass=" fa fa-facebook-official mainbtn tada "
                                                  textButton=" Facebook"
                                                  // className="mainbtn tada"
                                                  appId={FB_APP_ID}
                                                  autoLoad={false}
                                                  icon="fa-facebook text-primary new"
                                                  callback={responseFacebook}
                                                  onFailure={responseFacebookFailed}
                                             >
                                                  <i className="fa fa-facebook-official " > </i>
                                             </FacebookLogin>
                                        </div>
                                        <p className="mb-0">Already Have An Account? <Link to='/login'> <a href="#">Login</a> </Link></p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <ToastContainer />

          </div>
     )
}

export default Signup
