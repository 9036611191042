import React, { useState, useEffect } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
// import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import './addSurvey.css'
// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link, useHistory } from "react-router-dom";
import ShowSurveyModal from '../common/showSurveyModal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader2 from '../../utils/loader2/loader2';
import LogicSurveyModal from '../common/logicSurveyModal'
import editSurveyModal from "../common/editSurveyModal"
import SendByEmailModal from '../common/sendByEmailModal';
import SendBySmsModal from '../common/sendBySmsModal';
import Config from '../../config/env'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const AddSurvey = (props) => {
    const api = new API()
    let optionsArr = []
    let history = new useHistory()
    let adminToken = getLocalDataAsObject("adminToken")
    if (!adminToken) {
        history.push("/dashboard")
    }

    const [surveyName, setSurveyName] = useState()
    const [surveyId, setSurveyId] = useState()
    const [surveyPoints, setSurveyPoints] = useState()
    const [surveyStatus, setSurveyStatus] = useState("inactive")
    const [surveyType, setSurveyType] = useState("private")
    const [previousPage, setPreviousPage] = useState()
    
    const [addingQuestion, setAddingQuestion] = useState(false)
    const [modal, setModal] = useState(false)
    const [heading, setHeading] = useState("")
    const [type, setType] = useState("")
    const [question, setQuestion] = useState({
        selectionType: "",
        inputType: "",
        condition: "",
        question: "",
        dynamicSelection: "",
        is_response_count_applicable: "",
        status: ""
    })

    const [option, setOption] = useState()
    const [textBox, setTextBox] = useState()
    const [responseLimit, setResponseLimit] = useState()
    const [allOptions, setAllOptions] = useState([])


    const [addSurvey, setAddSurvey] = useState(false)
    const [surveyList, setSurveyList] = useState(true)
    const [addNewSurveyQuestionId, setAddNewSurveyQuestionId] = useState()
    const [emailButton,setEmailButton]=useState(false)


    const [allSurvey, setAllSurvey] = useState([])
    const [newStatus, setNewStatus] = useState("")
    const [loader2, setLoader2] = useState(true)
    const [survey_View, setSurvey_View] = useState([])
    const [surveyViewQuestions, setSurveyViewQuestions] = useState([])

    const [filterForEmailNotification, setFilterForEmailNotification] = useState("all")
    const [surveyLogic, setSurveyLogic] = useState([])
    const [surveyForEdit, setSurveyForEdit] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [pagination, setPagination] = useState();
    const [pageSize, setPageSize] = useState(9)
    const [index1, setIndex1] = useState(0)
    // const [questions, setQuestions] = useState([
    //     {
    //         question: "",
    //         selection: "",
    //         type: "",
    //         condition: "",
    //         status: "",
    //         dynamicSelection: "",
    //         is_response_count_applicable : "",
    //         options: []
    //     }
    // ])

    useEffect(() => {
        let adminToken = getLocalDataAsObject("adminToken")
        if (!adminToken) {
            history.push("/dashboard")
        }
        getAllSurvey()
        
    }, [])

    
    const getAllSurvey = async (count) => {
        let data = {
    
            pageNumber: count,
            pageSize: 9
    
        }
        let result = await api.post(config.AdminDashboardGetAllSurvey, data, {mode : "saved"})
        if (result && result.code === 200) {
            setAllSurvey(result.data)
           
            setLoader2(false)
        }
    }

    const deleteSurvey = async (allsurvey) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let data = {
                            id: allsurvey._id
                        }
                        let result = await api.post(config.AdminDashboardDeleteSurvey, data)
                        if (result.code === 200) {
                            toast.success("Survey Deleted Succesfully")
                            getAllSurvey()
                        }
                        else {
                            toast.error("Something Went Worng")
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.success("Survey Not Deleted")
                    }
                }
            ]
        });


    }

    const survey_type_status = async (allsurvey) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do Make this survey open for everyone.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let set_type = "open"
                        if (allsurvey.survey_type == "open") {
                            set_type = "private"
                        }

                        let data = {
                            id: allsurvey._id,
                            type: set_type
                        }
                        let result = await api.post(config.AdminDashboardSurveyTypeStatus, data)
                        if (result && result.code === 200) {
                            toast.success("Request done Succesfully")
                            getAllSurvey()
                        }
                        else {
                            toast.error("Something Went Worng")
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.warning("Survey Status not changed")
                    }
                }
            ]
        });


    }
    const surveyActiveInactive = async (allsurvey) => {

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let NewStatus = ""
                        if (allsurvey.status == "active") {
                            NewStatus = "inactive"
                        } else {
                            NewStatus = "active"
                        }
                        let data = {
                            id: allsurvey._id,
                            status: NewStatus
                        }

                        let result = await api.post(config.AdminDashboardActiveInactiveSurvey, data)
                        if (result.code === 200) {
                            toast.success(`Survey ${NewStatus} Succesfully`)
                            setNewStatus("")
                            getAllSurvey()
                        }
                        else {
                            toast.error("Something Went Worng")
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.success("Request not success")
                    }
                }
            ]
        });

    }

    const makeOnlineSurvey = async (allsurvey) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this survey Live. & Send Notification to all DoYourSurvey users',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let NewStatus = ""
                        if (allsurvey.status == "active") {
                            NewStatus = "inactive"
                        } else {
                            NewStatus = "active"
                        }
                        let data = {
                            id: allsurvey._id,
                            status: NewStatus,
                            surveyName: allsurvey.survey_name,
                            filter: filterForEmailNotification
                        }

                        let result = await api.post(config.AdminDashboardSurveyLive, data)
                        if (result && result.code === 200) {
                            toast.success(`Survey ${NewStatus} Succesfully`)
                            setNewStatus("")
                            getAllSurvey()
                        }
                        else {
                            toast.error("Something Went Worng")
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.success("Request not success")
                    }
                }
            ]
        });

    }
    const addOption = (e) => {
        if (option == "" || option == undefined) {
            toast.warning("please enter option")
        } else {

            setAllOptions([...allOptions, { option: option, action_id: "", text_box: "no", response_limit: "no" }])

            setOption("")
        }
    }
    const removeOption = () => {
        if (allOptions.length > 0) {
            allOptions.pop()

            setAllOptions([...allOptions])
        }
    }
    const onChangeHandler = (e) => {
        let val = e.target.value
        let name = e.target.name
        if (name == "option") {
            setOption(val)
        }
        if (name == "textBox") {
            setTextBox(val)
        }
        if (name == "responseLimit") {
            setResponseLimit(val)
        }
    }


    const addNewSurveybtn = () => {
        setAddSurvey(true)
        setSurveyList(false)
        setAddingQuestion(false)
    }
    const addNewSurveybtnn = () => {
        setAddSurvey(false)
        setSurveyList(true)
        setAddingQuestion(true)
    }
    const surveyView = async (surveyId) => {
        let data = {
            id: surveyId
        }
        let result = await api.post(config.AdminDashboardGetSurveyView, data)

        // let result = await getSurveyById(data)

        if (result && result.code === 200) {
            setSurvey_View(result.data[0])
            setSurveyViewQuestions(result.data[0].questions)

        }
    }
    const addNewSurvey = async () => {
        let newSurveyData = {
            surveyName: surveyName,
            surveyId: surveyId,
            surveyPoints: surveyPoints,
            surveyStatus: surveyStatus,
            surveyType: surveyType
        }

        let result = await api.post(config.AdminDashboardAddNewSurvey, newSurveyData)

        if (result && result.code === 200) {
            alert("added new survey")
        }
    }
    const addQuestionInSurvey = (survey) => {
        setAddingQuestion(true)

        setAddNewSurveyQuestionId(survey._id)
        setAddSurvey(false)
        setSurveyList(false)
    }
    const addingQuestionOnChange = (e) => {
        const { name, value } = e.target

        setQuestion({
            ...question,
            [name]: value
        })

    }

    const submitQuestion = async (e) => {
        let questionsData = {
            surveyId: addNewSurveyQuestionId,
            question: question.question,
            options: JSON.stringify(allOptions),
            selection: question.selectionType,
            type: question.selectionType,
            status: question.status,
            dynamicSelection: question.dynamicSelection,
            condition: question.condition,
        }

        let result = await api.post(config.adminDashboardAddQuestionInNewSurvey, questionsData)
        if (result && result.code === 200) {
            toast.success(result.message)
            setAllOptions([])
            setQuestion({})
        }

    }
    const selectFilter = (e) => {
        // alert(e.target.value)
        setFilterForEmailNotification(e.target.value)
    }
    const addSurveyLogic = async (allsurvey) => {
        let data = {
            surveyId: allsurvey._id
        }
        let result = await getSurveyById(data)
        setSurveyLogic(result.data)
    }
    const editSurveyBtn = async (allsurvey) => {
        let data = {
            surveyId: allsurvey._id
        }
        let result = await getSurveyById(data)

        setSurveyForEdit(result.data)
    }

    const getSurveyById = async (data) => {


        let result = await api.post(config.adminDashboardGetSurveyById, data)
        if (result && result.code === 200) {
            return result
        }
    }

    const sendSurveyLinkByEmail = async (survey) => {
        setEmailButton(true)
        // alert(data.survey_name)
        let data = {
            surveyName: survey.survey_name,
            surveyid: survey._id,
            surveyPoints: survey.points
        }
        toast.success("Emails are sendind")
        // await api.post(config.adminDashboardSendSurveyActiveNotification,{surveyName: survey.survey_name})
        await api.post(`${Config.new.site_api}/common/surveyActiveNotification`, { surveyName: survey.survey_name })
        let result= await api.post(config.adminDashboardSendSurveyLinkByEmail, data)

        if (result && result.code === 200) {
            setEmailButton(false)
            return result
        }
    }
    const sendInvitationEmailForRegisterPopup = () => {
        setType("registerInviteEmail")
        setModal(!modal)
    }
    const sendInvitationEmailForRegister = async (emailBulkFile, bulkEmailManual, resetValue) => {

        const formData = new FormData()
        formData.append('emailBulkFile', emailBulkFile.length > 0 ? emailBulkFile[0] : [])
        formData.append('type', "file")
        formData.append('bulkEmailManual', JSON.stringify(bulkEmailManual))

        setModal(false)
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to send email Invite for Register',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {

                        // alert(data.survey_name)
                        let data = {
                            type: "file",
                            emailBulkFile: emailBulkFile
                        }
                        setHeading("Sending mail to invite for register")
                        let result = await api.postUpload(config.adminDashboardSendInvitationEmailForRegister, formData)
                        if (result && result.code === 200) {
                            resetValue()
                            alert("Sent Email")
                            setType("")
                            return result
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.success("Request not success")
                    }
                }
            ]
        });
    }


    const sendEmailForProfilePendingUser = async () => {


        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to send email for profile pending user',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let data = {
                        }
                        let result = await api.post(config.adminDashboardSendEmailForProfilePendingUser, data)
                        if (result && result.code === 200) {
                            alert("Sent Email")
                            setType("")
                            return result
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.success("Request not success")
                    }
                }
            ]
        });
    }
    const sendEmailForReferInvatation = async () => {


        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to send email for Refer Invatation ',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let data = {
                        }
                        let result = await api.post(config.adminDashboardSendEmailForReferInvatation, data)
                        if (result && result.code === 200) {
                            alert("Sent Email")
                            setType("")
                            return result
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.success("Request not success")
                    }
                }
            ]
        });
    }

    const toggle = () => {
        setModal(!modal)
    }

    const sendPageCount = (value) => {
        if (value === '-1') {

            setPageCount(pageCount -1)
            updateIndex(value,pageCount);
           
        }
        if (value === '1') {

            setPageCount(pageCount + 1)          
            updateIndex(value,pageCount+1);
        }
        
    }

    const  updateIndex = (value,pageCount) =>{
        
        if (pageCount > 0){
            if(value==='-1'){
            setIndex1( pageSize * (pageCount - 1) )
            getAllSurvey(pageCount );}
            else{
            setIndex1( pageSize * (pageCount) ) 
            getAllSurvey(pageCount);
        }}else {
            setPageCount(pageCount = 0)
        }      
    }

    return (
        <div>
            {/* <Header {...props} /> */}
            {/* <Tabs {...props}/> */}
            {/* <Footer {...props} /> */}
            <div className="container-fluid mt-5">



                <div>
                    <div className="w3-container">
                        <h3 style={{ marginLeft: "35%", marginTop: "2%", marginBottom: "2%" }}>{props.heading}</h3>
                    </div>
                    {loader2 == true
                        ?
                        <div style={{ marginTop: "10%" }}>
                            <Loader2 />
                        </div>
                        :
                        <>
                            {surveyList == true &&
                                <>
                                <div className="container" style={{ display: "flex",justifyContent: "space-between"}}>
                                <h3 style={{ display: "inline-block" }} >List of All Survey</h3>
                                
                                <div className="container" style={{ display: "flex",justifyContent: "space-between"  }}>
                                <button style={{ float: "right" }} onClick={addNewSurveybtn} className="btn btn-success mt-3">Add New Survey</button>
                                <span style={{ display: "inline-block", verticalAlign: "" }}>
                                <button onClick={sendInvitationEmailForRegisterPopup} className="btn btn-primary btn-sm mt-3 ">Send E-Mail for DoYourSurvey <br/> Register Invatation</button></span>
                                <span style={{ display: "inline-block", verticalAlign: "" }}>
                                <button onClick={sendEmailForProfilePendingUser} className="btn btn-primary btn-sm mt-3 ">Send E-Mail for DoYourSurvey <br/> Profile Pendign User</button></span>
                                <span style={{ display: "inline-block", verticalAlign: "" }}>
                                {/* ongoing functionality */}
                                <button onClick={sendEmailForReferInvatation} className="btn btn-outline-danger btn-sm mt-3 ">Send E-Mail for DoYourSurvey  Registered User <br/> Refer  Invatation ongoing--</button></span>
                                </div>
                                </div>
                                

                                    {allSurvey.map((allsurvey, index) => (
                                        <div className="container col-md-9 mt-4">

                                            <div className="card ">
                                                <div className="card card-header">
                                                    <h6>{index1 + index+1}  Survey Name : <span style={{ color: "#6f4e37" }}>{allsurvey.survey_name}</span>
                                                        {allsurvey.status == "active" ? <span className="onlive" >Active</span>
                                                            : <>
                                                                <span className="offlive" onClick={() => makeOnlineSurvey(allsurvey)}>InActive</span>
                                                                <select className="form-control col-md-3" value={filterForEmailNotification} onChange={selectFilter} style={{ float: "right", marginRight: "15px" }}>
                                                                    <option selected>Select Filter</option>
                                                                    <option value="all">All</option>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                    {/* <option value="">Gamer</option>
                                                                <option value="">Singer</option>
                                                                <option value="">Business Man</option>
                                                                <option value="">Employee</option> */}
                                                                </select>
                                                                <button className="form-control col-md-3" style={{ float: "right", marginRight: "15px" }}>
                                                                    Select Email Tempalte</button>

                                                            </>}
                                                    </h6>
                                                </div>
                                                <div className="card card-body">
                                                    <h6> Survey Point : {allsurvey.points}</h6>
                                                    <h6 className="text-capitalize"> Survey Status : {allsurvey.status}</h6>
                                                    <h6 className="text-capitalize"> Survey Id : {allsurvey._id}</h6>
                                                    {/* <h6> Survey created Date : 11-1-2121</h6> */}
                                                </div>
                                                <div className="card-footer">
                                                    <button onClick={() => editSurveyBtn(allsurvey)}  className="btn btn-outline-primary" data-toggle="modal" data-target="#editSurveyModal" style={{ fontSize: "14px" }}>Edit Survey</button>
                                                    <button onClick={() => surveyView(allsurvey._id)} data-toggle="modal" data-target="#showSurveyModal" className="btn btn-outline-success ml-1" style={{ fontSize: "14px" }}>View Survey</button>
                                                    <button onClick={() => surveyActiveInactive(allsurvey)} className="btn btn-outline-secondary ml-2" style={{ fontSize: "14px" }}>{allsurvey.status == "active" ? "Inactive" : "Active"}</button>
                                                    <button onClick={() => deleteSurvey(allsurvey)} className="btn btn-outline-danger ml-1" style={{ fontSize: "14px" }}>Delete Survey</button>
                                                    <button onClick={() => addQuestionInSurvey(allsurvey)} className="btn btn-outline-danger ml-1" style={{ fontSize: "14px" }}>Add Questions</button>
                                                    <button onClick={() => addSurveyLogic(allsurvey)} data-toggle="modal" data-target="#logicSurveyModal" className="btn btn-outline-danger ml-1" style={{ fontSize: "14px" }}>Add Logic</button>
                                                    <button onClick={() => sendSurveyLinkByEmail(allsurvey)} data-toggle="modal" data-target="#sendByEmailModal" className="btn btn-outline-danger ml-1" style={{ fontSize: "14px" }} disabled={emailButton}>Send by email</button>

                                                    <button onClick={() => survey_type_status(allsurvey)} className="btn btn-outline-danger ml-1" style={{ fontSize: "14px" }}>{allsurvey && allsurvey.survey_type == "open" ? "Make Survey Private" : "Make Survey Open"}</button>
                                                    {/* <button onClick={() => sendSurveyLinkBySms(allsurvey)} data-toggle="modal" data-target="#sendBySmsModal" className="btn btn-outline-danger ml-3">Send by SMS</button> */}


                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </>                               
                            }
                            <div className="d-flex justify-content-evenly">
                            {pageCount > 0 && <div><button className='btn btn-secondary' onClick={() => sendPageCount("-1")}><i className="fa fa-arrow-left"></i></button></div>}
                            <div><button className='btn btn-secondary' onClick={() =>sendPageCount("1")}><i className="fa fa-arrow-right"></i></button></div>
                        </div>
                        </>}




                    {/* *********************start add survey******************* */}
                    {addSurvey == true &&
                        <div className="container mt-0" style={{ marginLeft: "11%" }}>
                            <div classNameName="mt-5 ">

                            </div>
                            <div>
                                <form>
                                    <div className="row ml-3">
                                        <div className="col-md-10">
                                            <h3 align="center">Survey Panel</h3>
                                            <div className=" bordersection" >
                                                <h5 align="center">Survey Details</h5>

                                                <div className="form-group">
                                                    <label for="surveyName">Enter Survey Name</label>
                                                    <input type="text" onChange={(e) => setSurveyName(e.target.value)} value={surveyName} className="form-control" id="surveyName" aria-describedby="emailHelp" placeholder="Enter Survey Name" />
                                                </div>

                                                <div className="form-group">
                                                    <label for="surveyId">Enter Survey Id</label>
                                                    <input type="text" onChange={(e) => setSurveyId(e.target.value)} value={surveyId} className="form-control" id="surveyId" aria-describedby="emailHelp" placeholder="Enter Survey Id" />
                                                </div>

                                                <div className="form-group">
                                                    <label for="surveyPoints">Enter Survey Points</label>
                                                    <input type="text" onChange={(e) => setSurveyPoints(e.target.value)} value={surveyPoints} className="form-control" id="surveyPoints" aria-describedby="emailHelp" placeholder="Enter Survey Points" />
                                                </div>
                                                <div class="form-group">
                                                    <label for="status">Status Type</label>
                                                    <select onChange={(e) => setSurveyStatus(e.target.value)} value={surveyStatus} class="form-control" id="status">
                                                        <option value="active">active</option>
                                                        <option value="inactive">inactive</option>
                                                    </select>
                                                </div>
                                                <div class="form-group">
                                                    <label for="status">Survey Type</label>
                                                    <select onChange={(e) => setSurveyType(e.target.value)} value={surveyType} class="form-control" id="status">
                                                        <option value="public">public</option>
                                                        <option value="private">private</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="text-center mt-3" style={{ margin: "auto;" }}>
                                                <button type="button" onClick={addNewSurvey} className="btn btn-outline-primary">Add Survey</button>
                                            </div>
                                            <div className="text-center mt-3" style={{ margin: "auto;" }}>
                                                <button type="button" onClick={(e)=>addNewSurveybtnn()} className="btn btn-outline-primary">Go Back</button>
                                            </div>

                                        </div>
                                    </div>

                                    <hr></hr>
                                </form>
                            </div>
                        </div>
                    }
                    {/* **********************end add survey******************* */}
                    {/* **********************start ading question************* */}
                    {addingQuestion == true &&
                        <div>
                            <div className="col-md-10 mt-3" style={{ marginLeft: "13%" }}>
                                <div className="bordersection">
                                    <h5 align="center">Survey Questions Details</h5>
                                    <div className="row">


                                        <div className="col-md-6" >
                                            <div class="form-group">
                                                <label for="selection">Selection Type</label>
                                                <select class="form-control" onChange={addingQuestionOnChange} name="selectionType" value={question.selectionType} id="selection">
                                                    <option selected>select</option>
                                                    <option value="single">single</option>
                                                    <option value="multiple">multiple</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="inputType">Input Type</label>
                                                <select class="form-control" onChange={addingQuestionOnChange} name="inputType" value={question.inputType} id="inputType">
                                                    <option selected >select</option>
                                                    <option value="radio">radio</option>
                                                    <option value="checkbox">checkbox</option>
                                                    <option value="text">text</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="condition">Condition</label>
                                                <select class="form-control" onChange={addingQuestionOnChange} name="condition" value={question.condition} id="condition">
                                                    <option selected >select</option>
                                                    <option value="optional">optional</option>
                                                    <option value="required">required</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="condition">Dynamic Selection</label>
                                                <select class="form-control" onChange={addingQuestionOnChange} name="dynamicSelection" value={question.dynamicSelection} id="condition">
                                                    <option selected >select</option>
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="condition">Question Status</label>
                                                <select class="form-control" onChange={addingQuestionOnChange} name="status" value={question.status} id="condition">
                                                    <option selected >select</option>
                                                    <option value="active">active</option>
                                                    <option value="inactive">inactive</option>
                                                </select>
                                            </div>
                                            <div class="form-group">
                                                <label for="condition">is_response_count_applicable</label>
                                                <select class="form-control" onChange={addingQuestionOnChange} name="is_response_count_applicable" value={question.is_response_count_applicable} id="condition">
                                                    <option selected >select</option>
                                                    <option value="true">true</option>
                                                    <option value="false">false</option>
                                                </select>
                                            </div>




                                        </div>
                                        <div className="col-md-6" >

                                            <div className="form-group">
                                                <label for="question">Enter Question here</label>
                                                <input type="text" className="form-control" name="question" onChange={addingQuestionOnChange} value={question.question} id="question" aria-describedby="emailHelp" placeholder="Enter question" />
                                            </div>
                                            <div className="form-group">
                                                <label for="option">Enter option</label>
                                                <input onChange={onChangeHandler} type="text" name="option" className="form-control" value={option} id="option" placeholder="Enter option" />
                                            </div>
                                            {/* <div className="form-group">
                                                <label for="text_box">text_box</label>
                                                <input onChange={onChangeHandler} type="text" name="textBox" className="form-control" value={textBox} id="text box" placeholder="text box" />
                                            </div>
                                            <div className="form-group">
                                                <label for="response_limit">Response_limit</label>
                                                <input onChange={onChangeHandler} type="text" name="responseLimit" className="form-control" value={responseLimit} id="response_limit" placeholder="response limit" />
                                            </div> */}

                                            <div>
                                                <button style={{ margin: "10px" }} type="button" onClick={addOption} className="btn btn-outline-dark btn-sm">Add Option</button>
                                                <button type="button" onClick={removeOption} className="btn btn-outline-dark btn-sm">Remove Option</button>

                                            </div>
                                            <div>
                                                <ul class="list-group">
                                                    {allOptions.map((op, index) =>
                                                        <li class="list-group-item list-group-item-secondary" style={{ height: "40px", margin: "5px" }}>Option{index + 1} :{op.option}</li>
                                                    )}
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="text-center" style={{ margin: "auto;" }}>
                                        <button type="button" onClick={submitQuestion} className="btn btn-outline-secondary">Submit</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    }

                    {/* **********************end adding question************** */}
                </div>

            </div>
            <ShowSurveyModal surveyViewQuestions={surveyViewQuestions} survey_View={survey_View} />
            <LogicSurveyModal surveyLogic={surveyLogic} />
            <editSurveyModal surveyForEdit={surveyForEdit} editSurveyBtn={editSurveyBtn} />
            <SendByEmailModal toggle={toggle} type={type} modal={modal} heading={heading} sendInvitationEmailForRegister={sendInvitationEmailForRegister} />
            <SendBySmsModal toggle={toggle} modal={modal} heading={heading} />
            <ToastContainer />
        </div>
    );
}

export default AddSurvey;