// import React from "react"
// import {  } from "react";
import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import API from '../../utils/apiCalling';
import Loader from '../../utils/loader';
import "react-data-table-component-extensions/dist/index.css";
import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import Config from "../../config/env";

const DownloadExcel = () => {
    const api = new API
    const url = useLocation()
    const [loader, setLoader] = useState(true)
    const [header, setHeader] = useState([
        "QDId", "Name"
    ])
    const [data1, setData] = useState([])
    console.log("Hello Data  data1", data1)
    let columns
    (url.pathname === '/downloadYardexcel' || url.pathname === '/downloadAgencyexcel') ?
        columns = [{
            name: "QDId",
            selector: "QDId",
            sortable: true
        },
        {
            name: "Date",
            selector: "Date",
            sortable: true
        },
        {
            name: "Auditor Name",
            selector: "Auditor Name",
            sortable: true
        }]
        :
        columns = [{
            name: "QDId",
            selector: "QDId",
            sortable: true
        },
        {
            name: "Name of Reviewer",
            selector: "Name of Reviewer",
            sortable: true
        },
        {
            name: "Date",
            selector: "Date",
            sortable: true
        },
        {
            name: "Enter Partner Name",
            selector: "Enter Partner Name",
            sortable: true
        }
        ]


    useEffect(() => {
        getData()

    }, []);


    const getData = async () => {
        console.log(url)
        let result
        let data
        if (url.pathname === "/downloadAgencyexcel" || url.pathname === "/downloadYardexcel") {
            if (url.pathname === "/downloadYardexcel") {
                data = { type: 'yard' }
            } else {
                data = { type: 'agency' }
            }

            result = await api.get(`${Config.new.site_api}/Idfc/downloadExcel`, { haveParams: true }, data)
        } else {

            result = await api.get(`${Config.new.site_api}/Avanti/downloadExcel`)
        }

        if (result.code === 200) {
            // setData(result.data)

            headerData(result.data)
            setTimeout(() => {
                responseData(result.data)
            }, 2000);

        }
    }

    const headerData = async (data) => {
        let filterResponse = [{ label: "QDId", key: "Id" },
        // { label: "Name", key: "Name" },
        { label: "Date", key: "date" }]

        console.log("jjj", url.pathname)
        if (url.pathname === "/downloadAgencyexcel" || url.pathname === "/downloadYardexcel") {
            data && data.length > 0 && data[0].questions.map((que) => {

                filterResponse.push({ label: que.question, key: que.question })
                header.push(que.question)
            })
        } else {

            data && data.length > 0 && data[0].questions.map((que) => {

                filterResponse.push({ label: que.que_message, key: que.que_message })
                header.push(que.que_message)
            })
        }


        //  columns.push({
        //     name: que.que_message,
        //     selector: que.que_message,
        //     sortable: true
        // },)

        // setLoader(false)


    }

    const responseData = (data) => {
        var filterData
        data && data.length > 0 && data.map((e) => {
            let date = e.date
            date = date.split('T')[0]

            filterData = {
                QDId: e.Id,
                Date: date
            }
            if (url.pathname === "/downloadAgencyexcel" || url.pathname === "/downloadYardexcel") {
                e && e.questions.length > 0 && e.questions.map((que) => {

                    filterData[que.question] = filterData[que.question] ? filterData[que.question] : (que.answer !== undefined) ? que.answer : que.answer
                })
            } else {
                e && e.questions.length > 0 && e.questions.map((que) => {

                    filterData[que.que_message] = filterData[que.que_message] ? filterData[que.que_message] : (que.answer !== undefined) ? que.answer : que.answer
                    // data1.push(que.answer[0])
                })
            }
            data1.push(filterData)


            // setData(filterData)
            // { (data1.length>7)&& (columns.length>81) && setLoader(false)}
            setLoader(false)
            console.log("hii", filterData)
        })
        setTimeout(() => {

        }, 5000);

    }

    const tableData = {
        columns,
        data1
    };

    return (
        <main className='bg-lgrey position-relative container-fluid p-0'>
            <section id="all-section" className="admin-header position-relative">

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid mt-2 mb-2">
                        <a className="navbar-brand1" ><img src={(url.pathname === '/downloadAgencyexcel' || url.pathname === '/downloadYardexcel') ? "../images/idfc-first-bank-logo.jpg" : "../images/avantiFinancelogo.png"} width="100px" alt="" /></a>

                        <ul className="navbar-nav flex-row align-items-center justify-contents-center">
                        </ul>
                    </div></nav>
            </section>
            {loader && <Loader />}
            <div><CSVLink color="black" data={data1} headers={""}
                filename={"surveyWiseUserResponseFullDetails.csv"}>
                <button className="btn btn-success ml-3 float-right">Download Response Details</button>
            </CSVLink>


                <DataTable
                    columns={columns}
                    data={data1}
                    noHeader
                    defaultSortField="_id"
                    defaultSortAsc={false}
                    // pagination
                    highlightOnHover />


                <div className='w-100  powered-btn'>
                    <a href='https://qdegrees.com/' target="_blank"> <div className='mainbtnblue m-2 position-fixed bottom-0 end-0'><small className='f-12'>Powered by</small>&nbsp;<b className='f-14'>DoYourSurvey</b></div></a>
                </div>
            </div>
        </main>
    )

}

export default DownloadExcel