export default {
     development: {
          // site_api: 'http://localhost:3009'
          // site_api: 'http://103.231.8.86:3002',
          // site_api: 'https://doyoursurvey.com:3009'
          site_api: 'https://api.doyoursurvey.com:3009',
     },
     production: {
          // site_api: 'http://103.231.8.86:3002',
          // site_api: 'https://doyoursurvey.com:3002'
          site_api: 'https://demo.doyoursurvey.com:3009',
          // site_api: 'http://localhost:3009'
     },
     local:{
          site_api: 'http://localhost:3009',
     },
     new:{
          site_api: 'https://api.doyoursurvey.com:3009',
          // site_api: 'http://localhost:3009',
     }
};
