import React, { useState } from 'react';
import './loader.css';

const Loader2 = (props) => {

  return (
    <div>
      
      {/* <h4>loader</h4> */}
      <div className="container text-center mt-5">
         <div className="lds-ripple"><div></div><div></div></div>
      </div>

  </div>
  );
}

export default Loader2;