import React from 'react'

function BlogCart(props) {
     const { description, heading, img } = props
     return (
          <div className="card">
               <div className="card-body text-center">
                    <img src={img} alt="" />
                    <h5>{heading}</h5>
                    <p>{description}</p>
               </div>
          </div>
     )
}

export default BlogCart