import { Link, useHistory } from 'react-router-dom'
import React from 'react'
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from 'react-toastify'
import { useState } from "react";
import { useEffect } from 'react';
import API from '../../utils/apiCalling';
import Config from '../../config/env';



const OtpVerify = () => {
     let api = new API()
     const history = useHistory();
     const [email, setEmail] = useState("");
     const [state, setState] = useState({ otp: '' });
     const [message, setMessage] = useState()
     //  const queryParameters = new URLSearchParams(window.location.search)
     //  const reffer = queryParameters.get("reffer")

     const handleChange = (otp) => setState({ otp })

     const handleOtpVerify = async () => {
          if (!state.otp) {
               setMessage("Please Enter OTP")
          }
          else {
               let email = localStorage.getItem("user_email");
               let otp = state.otp
               let result = await fetch(`${Config.new.site_api}/client/verifyOtpForEmail`, {
                    method: 'post',
                    body: JSON.stringify({ email, otp }),
                    headers: { 'content-Type': 'application/json' },
               });
               result = await result.json();
               if (result.code === 200 && result.error === false) {
                    history.push('/registration');
               } else if (result.code === 201 && result.error === true) {
                    setMessage(result.message)
               }
          }

     }

     const changeEmail = () => {
          history.push('/signup');
     }
     const ResendOtp = async () => {
          let email = localStorage.getItem("user_email");
          let reffercode = ''
          let result = await api.post(`${Config.new.site_api}/client/sendOtpForVerifyEmail`, { email, reffercode })
          if (result.code === 200) {
               toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT
               });
          } else {
               toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
               });
          }
     }


     return (
          <div>
               <section className="loginSec">
                    <div className="container text-center h-100">
                         <div className="row justify-content-center">
                              <div className="col-md-6 col-lg-5">

                                   <div className="bg-white loginBox ">
                                        <Link to="/"> <img src="../images/new-logo.png" alt width="140px" /></Link>
                                        <h1 className="loginHeading text-left mb-4">
                                             OTP Verification
                                        </h1>

                                        <div className="input-group mb-4">
                                             <span className="input-group-text"><i className="fa fa-envelope" /></span>
                                             <input type="email" className="form-control" placeholder="Email" name="email" value={localStorage.getItem("user_email")} readOnly />
                                             <span className="input-group-text eyePass"><i className="fa fa-pencil-square-o" onClick={changeEmail} /></span>
                                        </div>
                                        <p className="mb-3 text-center">Enter OTP</p>
                                        <div className="input-group mb-4 otpDv bg-white justify-content-center">
                                             <OtpInput
                                                  inputStyle={{ width: "3em", height: "3em" }}
                                                  value={state.otp}
                                                  onChange={handleChange}
                                                  numInputs={6}
                                                  separator={<span> &nbsp;&nbsp; </span>}
                                                />
                                              </div>
                                              <span style={{color: "red",width: "100%", display: "block",textAlign: 'left'}}>
                                                       {message}
                                                  </span>
                                              <p className="mb-4 otpResent text-center"><a className="btn btn-light text-decoration-none" onClick={ResendOtp}>Resend OTP</a></p>
                                              <button type="submit" onClick={handleOtpVerify} className=' mainbtnss mainbtnorange fadeInUp tada w-100' >Submit</button>
                                        
                                   </div>
                              </div>
                         </div>
                    </div>
               </section>
               <ToastContainer />


          </div>
     )
}

export default OtpVerify
