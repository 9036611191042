import Config from '../config';

export const config = {

     login: `${Config.site_api}/client/login`,
     register: `${Config.site_api}/client/signup`,
     isRegistred: `${Config.site_api}/client/isRegistred`,
     mobileverifySendOtp: `${Config.site_api}/client/mobileverifySendOtp`,
     mobileverifySendOtpChangePassword: `${Config.site_api}/client/mobileverifySendOtpChangePassword`,
     mobileverify: `${Config.site_api}/client/mobileverify`,
     updateProfile: `${Config.site_api}/dashboard/updateProfile`,
     userPanelProfile: `${Config.site_api}/user-panel-profile/dashboard`,
     userProfileQuestion: `${Config.site_api}/user-panel-profile/userProfileQuestion`,
     userProfileTaskSubmit: `${Config.site_api}/user-panel-profile/userProfileTaskSubmit`,
     getSurvey: `${Config.site_api}/survey/getSurvey`,
     getDynSurvey: `${Config.site_api}/survey/getDynSurvey`,
     AdminDashboardGetSurveyById: `${Config.site_api}/survey/getSurveyById`,

     getDynSurveyAttemp: `${Config.site_api}/survey/getDynSurveyAttemp`,
     getDynSurveyAttempOnlyResponse: `${Config.site_api}/survey/getDynSurveyAttempOnlyResponse`,
     SubmitSurveyOnlyResponse: `${Config.site_api}/survey/SubmitSurveyOnlyResponse`,

     submitFreelancerSurvey: `${Config.site_api}/survey/SubmitSurvey`,
     redeemPoint: `${Config.site_api}/survey/redeemPoint`,
     paytmRedeem: `${Config.site_api}/admin-dashboard_paytm/paytmpayment`,
     paytmVoucherDecline: `${Config.site_api}/admin-dashboard_paytm/paytmVoucherDecline`,
     paytmVoucherAutoApproval: `${Config.site_api}/admin-dashboard_paytm/paytmVoucherAutoApproval`,
     getPaytmAutoApprovalStatus: `${Config.site_api}/admin-dashboard_paytm/getPaytmAutoApprovalStatus`,

     getRedeemedPointHistory: `${Config.site_api}/survey/getRedeemedPointHistory`,
     SurveyResponseLimit: `${Config.site_api}/survey/SurveyResponseLimit`,
     submitProgressOnEachQuestion: `${Config.site_api}/survey/submitProgressOnEachQuestion`,
     getLanguage: `${Config.site_api}/survey/getLanguage`,
     getDynSurveyByLangCode: `${Config.site_api}/survey/getDynSurveyByLangCode`,
     getDemoDynSurvey: `${Config.site_api}/survey/getDemoDynSurvey`,
     totalRefercodeCount: `${Config.site_api}/dashboard/totalRefercodeCount`,
     countNoOfReferral:`${Config.site_api}/dashboard/countNoOfReferral`,


     changePassword: `${Config.site_api}/client/changePassword`,
     forgetPassword: `${Config.site_api}/client/forgetPassword`,
     referFriend: `${Config.site_api}/dashboard/referFriend`,
     communicationPreference: `${Config.site_api}/dashboard/communicationPreference`,
     welcomePopup: `${Config.site_api}/dashboard/welcomePopup`,
     closeWelcomePopup: `${Config.site_api}/dashboard/closeWelcomePopup`,

     sendOtpForVerifyEmail: `${Config.site_api}/client/sendOtpForVerifyEmail`,
     verifyOtpForEmail: `${Config.site_api}/client/verifyOtpForEmail`,
     registerByEmail:`${Config.site_api}/client/registerByEmail`,

     // _______________________admin dashboard api url________________________
     adminLogin: `${Config.site_api}/admin-dashboard/adminLogin`,

     adminDashboardRedeemPanelUser: `${Config.site_api}/admin-dashboard/redeemPanelUser`,
     adminDashboardRedeemPointHistory: `${Config.site_api}/admin-dashboard/redeemPointHistory`,
     adminDashboardPendingRedeemPointHistory: `${Config.site_api}/admin-dashboard/pendingRedeemPointHistory`,
     couponApprovalRequest: `${Config.site_api}/survey/couponApproval`,   //this api created in client api section, will have to create in admin panel api
     AdminDashboardGetAllSurvey: `${Config.site_api}/admin-dashboard/getAllSurvey`,
     AdminDashboardDeleteSurvey: `${Config.site_api}/admin-dashboard/deleteSurvey`,
     AdminDashboardActiveInactiveSurvey: `${Config.site_api}/admin-dashboard/activeInactiveSurvey`,
     AdminDashboardGetSurveyView: `${Config.site_api}/admin-dashboard/getSurveyView`,
     AdminDashboardAddNewSurvey: `${Config.site_api}/admin-dashboard/addNewSurvey`,
     adminDashboardAddQuestionInNewSurvey: `${Config.site_api}/admin-dashboard/addQuestionInNewSurvey`,
     AdminDashboardSurveyLive: `${Config.site_api}/admin-dashboard/surveyFinalLive`,
     adminDashboardLogicTargetSet: `${Config.site_api}/admin-dashboard/logicTargetSet`,
     adminDashboardEditSurvey: `${Config.site_api}/admin-dashboard/editSurvey`,

     adminDashboardGetSurveyById: `${Config.site_api}/admin-dashboard/getSurveyById`,
     adminDashboardEditSurveyPoint: `${Config.site_api}/admin-dashboard/editSurveyPoint`,

     adminDashboardEditSurveyQuestion: `${Config.site_api}/admin-dashboard/editSurveyQuestion`,

     adminDashboardProfilePanelGetDynamicContent: `${Config.site_api}/admin-dashboard/profilePanelGetDynamicContent`,
     adminDashboardProfilePanelWelcomePoint: `${Config.site_api}/admin-dashboard/profilePanelWelcomePoint`,
     adminDashboardProfilePanelReferPoint: `${Config.site_api}/admin-dashboard/profilePanelReferPoint`,
     adminDashboardSendSurveyLinkByEmail: `${Config.site_api}/admin-dashboard/sendSurveyLinkByEmail`,
     adminDashboardSendInvitationEmailForRegister: `${Config.site_api}/admin-dashboard/sendInvitationEmailForRegister`,
     adminDashboardSendEmailForProfilePendingUser: `${Config.site_api}/admin-dashboard/sendEmailForProfilePendingUser`,
     adminDashboardSendEmailForReferInvatation: `${Config.site_api}/admin-dashboard/sendEmailForReferInvatation`,
     adminDashboardSendSurveyLinkBySms: `${Config.site_api}/admin-dashboard/sendSurveyLinkBySms`,
     adminDashboardSendSurveyActiveNotification: `${Config.site_api}/common/surveyActiveNotification`,
     adminDashboardReferralPercent:`${Config.site_api}/admin-dashboard/referralPercent`,
     adminDashboardRegistrationReport:`${Config.site_api}/admin-dashboard/RegistrationReport`,
     adminDashboardTotalRefercodeCount:`${Config.site_api}/admin-dashboard/totalRefercodeCount`,
     
     adminDashboardGetUserBasicsDetails: `${Config.site_api}/admin-dashboard/userBasicsDetails`,
     adminDashboardDeleteUser: `${Config.site_api}/admin-dashboard/DeleteUser`,

     adminDashboardGetUserAttendedSurveyList: `${Config.site_api}/admin-dashboard/getUserAttendedSurveyList`,
     adminDashboardGetUserProfileSurvey: `${Config.site_api}/admin-dashboard/getUserProfileSurvey`,
     unsubscribeRegisterEmailTemplate: `${Config.site_api}/admin-dashboard/unsubscribeRegisterEmailTemplate`,
     AdminDashboardGetSurveyWiseResponse: `${Config.site_api}/admin-dashboard/getSurveyWiseResponse`,
     AdminDashboardGetSurveyWiseOnlyResponse: `${Config.site_api}/admin-dashboard/getSurveyWiseOnlyResponse`,
     AdminDashboardSurveyTypeStatus: `${Config.site_api}/admin-dashboard/surveyTypeStatus`,
     adminDashboardAddEmailTemplateNotificationEmail: `${Config.site_api}/admin-dashboard/addEmailTemplateNotificationEmail`,
     adminDashboardgetNotificationEmail: `${Config.site_api}/admin-dashboard/getNotificationEmail`,
     adminDashboardDeleteNotificationEmail: `${Config.site_api}/admin-dashboard/deleteNotificationEmail`,


     // SEOCONTENT
     AdminDashboardAddSeoContent: `${Config.site_api}/admin-dashboard/addSeoContent`,
     AdminDashboardGetSeoContent: `${Config.site_api}/admin-dashboard/getSeoContent`,
     AdminDashboardDeleteSeoContent: `${Config.site_api}/admin-dashboard/deleteSeoContent`,
     AdminDashboardGetSurveyWiseResponseFullDetails: `${Config.site_api}/admin-dashboard/surveyWiseResponseFullDetails`,
     getPaytmRedeem: `${Config.site_api}/admin-dashboard_paytm/getPaytmRedeem`,

     /*...............................................................Demo-DoYourSurvey..................................*/
     createSurvey: `${Config.site_api}/client-survey/createSurvey`,
     addQuestionInSurvey: `${Config.site_api}/client-survey/addQuestionInSurvey`,
     // getSurvey: `${Config.site_api}/client-survey/getSurvey`,
     deleteSurvey: `${Config.site_api}/client-survey/deleteSurvey`,
     searchSurveyByNameOrId: `${Config.site_api}/client-survey/searchSurveyByNameOrId`,

     deleteQuestion: `${Config.site_api}/client-survey/deleteQuestion`,
     allQuestions: `${Config.site_api}/client-survey/allQuestions`,
     favoriteArchiveSurvey: `${Config.site_api}/client-survey/favoriteArchiveSurvey`,
     templateCategory: `${Config.site_api}/client-survey/templateCategory`,
     templateQuestion: `${Config.site_api}/client-survey/templateQuestion`,
     surveyTitleDescription: `${Config.site_api}/client-survey/surveyTitleDescription`,
     surveyLogo: `${Config.site_api}/client-survey/surveyLogo`,
     businessQuery: `${Config.site_api}/clientBusiness/businessQuery`,
     createInquiryformAPI: `${Config.site_api}/client-survey/createInquiryformAPI`,
     // http://localhost:3002/client-survey/createInquiryformAPI
     editSurveyCategory : `${Config.site_api}/client-survey/editSurveyCategory`,
     AddFreeDemo:`${Config.site_api}/client-survey/AddFreeDemo`,
     createNewSurvey:`${Config.site_api}/client-survey/createNewSurvey`,
     SurveyPointDistribution:`${Config.site_api}/survey/SurveyPointDistribution`,
     getStudentImage:`${Config.site_api}/client-survey/getStudentImage`,
     verifyStudentId:`${Config.site_api}/client-survey/verifyStudentId`               

    
     
     
    
























}
