import React, { useState, useEffect } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
// import Header from '../../Components/ReusableComponents/Header'
// import Footer from '../../Components/ReusableComponents/Footer';
import { Redirect, Link, useHistory } from "react-router-dom";
import Navbar from './navbar'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import DeclinePaytmVoucher from './declinePaytmVoucher';
import SuccessPaytmVoucher from './successPaytmVoucher';
import './paytm.css'
import Moment from 'react-moment';
// import { MDBSwitch } from 'mdb-react-ui-kit';
const Paytm = (props) => {

    const { } = props
    const api = new API()
    const history = new useHistory()

    const [pendingRedeemVoucher, setPendingRedeemVoucher] = useState([])
    const [viewPendingVoucher, setViewPendingVoucher] = useState(true)
    const [viewDeclineVoucher, setViewDeclineVoucher] = useState(false)
    const [viewSuccessVoucher, setViewSuccessVoucher] = useState(false)
    const [totalPendingVoucher, setTotalPendingVoucher] = useState(0)
    const [totalApprovedVoucher, setTotalApprovedVoucher] = useState(0)
    const [totalDeclineVoucher, setTotalDeclineVoucher] = useState(0)
    const [totalVoucher, setTotalVoucher] = useState(0)
    const [isAutoApproval, setIsAutoApproval] = useState(false)

    useEffect(() => {
        getPaytmRedeem()

        paytmAutoApprovalStatus()

    }, [])

    const countVoucher = (pendingRedeemVoucher) => {
        let tempPendingIndex = 0;
        let tempApprovedIndex = 0;
        let tempDeclineIndex = 0;
        let tempTotalIndex = 0;
        pendingRedeemVoucher.map(pendingVou => {

            (pendingVou.redeemPoint).filter((isVoucher, index) => {

                if (isVoucher && isVoucher.status == "pending" && isVoucher.voucherFor == "paytm") {
                    
                    tempPendingIndex = tempPendingIndex + 1

                    setTotalPendingVoucher(tempPendingIndex)
                }

                if (isVoucher && isVoucher.status == "success" && isVoucher.voucherFor == "paytm") {
                 
                    tempApprovedIndex = tempApprovedIndex + 1
                    setTotalApprovedVoucher(tempApprovedIndex)
                }
                if (isVoucher && isVoucher.status == "decline" && isVoucher.voucherFor == "paytm") {
                  
                    tempDeclineIndex = tempDeclineIndex + 1
                    setTotalDeclineVoucher(tempDeclineIndex)
                }
                if (isVoucher.voucherFor == "paytm") {
                    
                    tempTotalIndex = tempTotalIndex + 1
                    setTotalVoucher(tempTotalIndex)
                }
            })

        })
    }


    const getPaytmRedeem = async () => {
        let result = await api.get(config.getPaytmRedeem)
        if (result && result.status == "success") {
    
            setPendingRedeemVoucher(result.data)

            countVoucher(result.data)
            setInterval(function () {
               
            }, 5000);
        }
    }
    const approveCoupon = async (voucherUser, voucher) => {
       
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to redeem points.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let data = {
                            couponeId: voucher._id,
                            email: voucherUser,
                            RedeemPoint: voucher.RedeemPoint,
                            paytmMobile: voucher.paytmMobile,
                            voucherFor: voucher.voucherFor,
                            voucherCode: voucher.voucherCode,
                            status: voucher.status
                        }
                        let result = await api.post(config.paytmRedeem, data)
                        if (result && result.status == "success") {
                            toast.success(result.data.statusMessage)
                            getPaytmRedeem()
                        }

                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.warning("Request canceled")
                    }
                }
            ]
        })

    }
    const declineCoupon = async (email, couponeId) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to redeem points.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let data = {
                            email: email,
                            couponeId: couponeId
                        }
                        let result = await api.post(config.paytmVoucherDecline, data)
                       
                        if (result && result.status == "success") {
                            toast.warning(result.message)
                            getPaytmRedeem()
                        }

                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.warning("Request canceled")
                    }
                }
            ]
        })

    }
    const isViewPendingVoucher = () => {
        setViewDeclineVoucher(false)
        setViewSuccessVoucher(false)
        setViewPendingVoucher(true)
    }
    const isViewDeclineVoucher = () => {
        setViewSuccessVoucher(false)
        setViewPendingVoucher(false)
        setViewDeclineVoucher(true)
    }
    const isViewSuccessVoucher = () => {
        setViewDeclineVoucher(false)
        setViewPendingVoucher(false)
        setViewSuccessVoucher(true)
    }

    const showVoucherDetails = () => {
        alert("show")
    }

    const autoApproval = async (e) => {
        // alert(e.target.checked)
        setIsAutoApproval(e.target.checked)
        let data = {
            isautoApproval: e.target.checked,
            autoApprovalFor: "paytm"
        }

        let result = await api.post(config.paytmVoucherAutoApproval, data)
        if (result && result.status == "success") {
            toast.success(result.message)
        }
    }

    const paytmAutoApprovalStatus = async () => {
      

        let data = {
            autoApprovalFor: "paytm"
        }

        let result = await api.post(config.getPaytmAutoApprovalStatus, data)
        if (result && result.status == "success") {
            // alert(result.data.isApproval)
            setIsAutoApproval(result.data.isApproval)
        }
    }

    return (
        <div>

            <Navbar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2 Section" style={{ borderRight: "1px solid" }}>
                        <h3 align="center">SEO PANEL</h3>
                        <div id="mySidebar" className="sidebar">
                            {/* <a >SEO</a> */}
                            <a value="home">Home</a>
                            <a value="home" className="primary" onClick={isViewPendingVoucher}>Pending Voucher</a>
                            <a value="home" className="primary" onClick={isViewDeclineVoucher}>Decline Voucher</a>
                            <a value="home" className="primary" onClick={isViewSuccessVoucher}>Success Voucher</a>

                        </div>

                    </div>
                    <div className="col-md-10 Section">



                        <div className="col-md-10 Section">
                            <div className="container col-md-10">

                                <div className="card mt-5">

                                    <div className="card-header ">
                                        <h3 align="center">paytm Dashboard</h3>

                                    </div>
                                    <div className="card-body">
                                        <table className="table">
                                            <thead>
                                                <tr>

                                                    <th scope="col">Total Pending Voucher</th>
                                                    <th scope="col">Total Approved Voucher</th>
                                                    <th scope="col">Total Decline Voucher</th>
                                                    <th scope="col">All Voucher</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td>{totalPendingVoucher}</td>
                                                    <td>{totalApprovedVoucher}</td>
                                                    <td>{totalDeclineVoucher}</td>
                                                    <td>{totalVoucher}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" onChange={autoApproval} checked={isAutoApproval ? true : false} className="custom-control-input btn-lg" id="customSwitch1" />
                                            <label className="custom-control-label" for="customSwitch1">Auto Approval</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {viewPendingVoucher == true &&
                                <div className="container  col-md-10">

                                    {pendingRedeemVoucher && pendingRedeemVoucher.map((voucherUser, index) => (

                                        <div className="card mt-3">
                                            <div className="card-header">
                                                <span>{index + 1} : {voucherUser.user}</span>
                                            </div>
                                            <div className="card-body">
                                                {voucherUser.redeemPoint.map((voucher, index) => (

                                                    <div>
                                                        {voucher.voucherFor == "paytm" && voucher.status == "pending" &&

                                                            <div className="card">

                                                                <div className="card-body">
                                                                    <table className="table">

                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row">Mobile Number</th>
                                                                                <td>{voucher.paytmMobile}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">Redeem Point</th>
                                                                                <td>{voucher.RedeemPoint}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">Voucher For</th>
                                                                                <td>{voucher.voucherFor}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">Status</th>
                                                                                <td>{voucher.status}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">Redeem Date</th>
                                                                                <td><Moment format="YYYY/MM/DD - hh:mm:ss a">
                                                                                    {voucher.date}</Moment></td>
                                                                            </tr>


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="card-footer">
                                                                    {/* <button type="submit" onClick={showVoucherDetails} className="btn btn-primary m-2">Show Voucher Details</button> */}
                                                                    <button type="submit" onClick={() => approveCoupon(voucherUser.user, voucher)} className="btn btn-primary m-2">Accept</button>
                                                                    <button type="submit" onClick={() => declineCoupon(voucherUser.user, voucher._id)} className="btn btn-danger m-2">Decline</button>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                ))}

                                            </div>

                                        </div>

                                    ))}
                                </div>
                            }
                            {viewDeclineVoucher == true &&
                                <div>
                                    <DeclinePaytmVoucher />

                                </div>
                            }
                            {
                                viewSuccessVoucher == true &&
                                <div>
                                    <SuccessPaytmVoucher />
                                </div>
                            }
                        </div>








                    </div>
                </div>
            </div>

            {/* <Footer /> */}
        </div>
    );
}

export default Paytm;