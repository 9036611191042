import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from "../utils/apiCalling";
import { toast, ToastContainer } from 'react-toastify';
import { Select } from "antd";
import Config from '.././config/env'

const EditProfile = () => {

  let user = JSON.parse(localStorage.getItem('user'));

  const [file, setFile] = useState('')
  const [country,setCountry] = useState([])
  const [editProfileImg,setEditProfileImg]=useState()
  const [clientUserData,setClientUserData]=useState()
  const [from, setFrom] = useState({
    _id: "",
    email: "",
    mobile: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: "",
    country: "",
    bio: "",
  });

  useEffect(() => {

    selectCountry();
    getUserData();
    setFrom({
      ...from,
      "_id": user._id,
      "firstName": user.firstName,
      "lastName": user.lastName, "email": user.email,
      "mobile": user.mobile, "gender": user.gender,
      "bio": user.bio, "country": user.country,
      "dob": user.dob
    })


  }, [file])

  const getUserData = async (e) => {
    let data = {
      email: user.email
    }
    let result = await api.post(`${Config.new.site_api}/dashboard/clientprofile`, data)
    if (result.code === 200) {
      console.log(result.data.user[0])
      localStorage.setItem('userData', JSON.stringify(result.data.user))
      setFrom(result.data.user[0])
    }


  }
  const editProfiledp = async (e) => {
    let file = ((e === undefined) ? [] : e?.target?.files);
    setEditProfileImg(file[0])
    let newdata = new FormData();
    newdata.append("Id", user._id);
    newdata.append("file", file[0]);
    let result = await api.putPostFileFun(`${Config.new.site_api}/client-survey/uploadDP`, newdata)
    if (result.code === 200) {
      toast.success(result.message, {

        position: toast.POSITION.TOP_RIGHT
      });
      getUserData();
    }


  }



  const history = new useHistory();
  let api = new API();
  const handlechange = (e) => {


    let { name, value } = e.target
    if (e.target.name == "lastName") {
      setFrom({ ...from, "fullName": from.firstName && from.firstName.concat(value), [name]: value })
    } else {
      setFrom({ ...from, [name]: value })

    }

  }
  const selectCountry = async () => {
    let result = await api.get(`${Config.new.site_api}/client-survey/getCountries`, {
    })
    if (result && result.code === 200) {
      let Data = result.data[0].options
      setCountry(Data)
    }

  }
  const Update = async () => {
    console.log("asdfsdfs")
    editProfiledp();

    let result = await api.post(`${Config.new.site_api}/dashboard/editProfile`, from)

    if (result.code === 200) {
      // alert(result.message)
      toast.success('Profile updated successfully.', {
        position: toast.POSITION.TOP_RIGHT
      });
      localStorage.setItem("user", JSON.stringify(from));
      setTimeout(() => {
        history.push("/panelistDash");
      }, 400);
    }
    if (result.code !== 200) {
      toast.error(result.message, {
        position: toast.POSITION.TOP_RIGHT
      });

    }

  }


  return (

    <main>
      <section className="loginSec">
        <div className="container text-center h-100">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="bg-white loginBox">
                <div className="logn-edst mb-4">
                  <h1 className="loginHeading text-left ">
                    Edit Profile
                  </h1>
                  <a href="#/panelistDash"><i class="bi bi-x-lg"></i></a>
                </div>
                <form >
                  <div className="row">
                    <div className="col">
                      <div className="mb-4 profile-editsc">
                        <div class="rounded-circle position-relative d-flex justify-content-center align-items-center">
                          <img src={from.profileImage} class="rounded-circle pro-img align-middle" alt=" dp" /> <i class=" bi bi-pencil-square"></i>
                          <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" className=" position-absolute opacity-0 right-0 top-0" style={{ left: "168px", height: "80px", width: "83px" }} onChange={(e) => editProfiledp(e)} /></div>
                        <label for="imageUpload"></label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i class="bi bi-person-fill"></i></span>
                        {/* {console.log(from.firstName)} */}
                        <input type="text" className="form-control" placeholder="First Name"

                          value={from.firstName}


                          name="firstName"
                          onChange={(e) => handlechange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i class="bi bi-person-fill"></i></span>
                        <input type="text" className="form-control" placeholder="Last Name"
                          value={from.lastName}
                          name="lastName" onChange={(e) => handlechange(e)} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      {/* <div className="d-flex">
                        <h6>Gender</h6>
                        <div className="condite me-3">
                          <div className="form-check">
                            <input className="form-check-input" style={{ marginTop: '14px' }} type="radio" name="gender" id="flexRadioDefault1" onChange={(e) => handlechange(e)} value="men" />
                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                              <img src="..//images/Group 43057.svg" className="org" alt="image" width="35px" />
                            </label>
                          </div>
                        </div>
                        <div className="women me-3">
                          <div className="form-check">
                            <input className="form-check-input" style={{ marginTop: '14px' }} type="radio" name="gender" id="flexRadioDefault2" onChange={(e) => handlechange(e)} value="women" />
                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                              <img src="..//images/Group 43056.svg" className="org" alt="image" width="28px" />
                            </label>
                          </div>
                        </div>
                        <div className="trans">
                          <div className="form-check">
                            <input className="form-check-input" style={{ marginTop: '14px' }} type="radio" name="gender" id="flexRadioDefault3" onChange={(e) => handlechange(e)} value="trans" />
                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                              <img src="..//images/Path 39212.svg" className="org" alt="image" width="36px" />
                            </label>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="input-group mb-3"> */}
                      {/* <input list="magicHouses" class="form-select input-group-text text-left newSelect" id="filter-bt" name="myHouse" placeholder="Select Gender" />
                        <datalist id="magicHouses">
                          <div>
                            <option class="border-bottom p-2" >Male</option>
                            <option class="border-bottom p-2" >Female</option>
                            <option class="border-bottom p-2" >Another</option>

                          </div>
                        </datalist> */}
                      <select class="form-select mediaender mb-4" aria-label="Default select example" name="gender" onChange={(e) => handlechange(e)}>
                        <option hidden>{(from.gender) ? from.gender : "Select gender"}</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      {/* </div> */}
                    </div>
                    <div className="col-lg-6 col-md-12">
                      {/* <div className="input-group mb-4"> */}
                      {/* <span className="input-group-text"><i class="bi bi-pin-map"></i></span>
                        <input type="text" className="form-control" placeholder="Location" name="city"
                          value={from.city}
                          onChange={(e) => handlechange(e)} /> */}
                      <select class="form-select mediaender mb-4" aria-label="Default select example" name="country" onChange={(e) => handlechange(e)}>
                        <option value={from.country} hidden>{(from.country) ? from.country : "Select country"}</option>
                        {country.map(e => (
                          <option value={e.type}>{e.type}</option>
                        ))}


                      </select>
                      {/* </div> */}
                    </div>
                  </div>
                  {/* row endedn */}
                  <div className="row">
                    <div className="col">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i className="fa fa-envelope" /></span>
                        <input type="email" className="form-control" placeholder="Enter Your Email"
                          value={from.email}
                          name="email" onChange={(e) => handlechange(e)} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="input-group mb-4">
                        <span className="input-group-text"><i class="bi bi-person-video2"></i></span>
                        <input type="text" className="form-control" placeholder="Bio"
                          value={from.bio}
                          name="bio" onChange={(e) => handlechange(e)} id="myInput" />
                        {/* <span className="input-group-text eyePass"><i className="fa fa-eye" onClick= {(e)=>myFunction()}  /></span> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"> <i class="bi bi-phone"></i></span>
                        <input type="Nnumber" className="form-control quantity" placeholder="Enter Phone..." aria-label="Username"
                          value={from.mobile}
                          name="mobile" aria-describedby="basic-addon1" onChange={(e) => handlechange(e)} />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                      <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1"><i class="bi bi-calendar"></i><i className="fa-solid fa-calendar-days" /></span>
                        <input type="date" className="form-control" placeholder="Enter Phone..." aria-label="Username"
                          value={from.dob.slice(0, 10)}
                          aria-describedby="basic-addon1" name="dob" onChange={(e) => handlechange(e)} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex mb-4 remberMe" onClick={(e) => Update()} >
                    <button type="button" className="mainbtn w-100">Update</button>
                  </div>
                </form>
                {/* <p className="mb-0">Click here to<a href="#">Login</a></p> */}
              </div>
              <div className="bg-white loginBox d-none">
                <img src="src/new-logo.png" alt="" width="140px" />
                <h1 className="loginHeading text-left mb-4">
                  Sign Up
                </h1>
                <form action="/action_page.php">
                  <div className="input-group mb-4">
                    <span className="input-group-text"><i className="fa fa-envelope" /></span>
                    <input type="email" className="form-control" placeholder="Enter Your Email" name="email" />
                  </div>
                  <button type="submit" className="btn mainbtn tada w-100">Verify</button>
                </form>
                <div className="d-flex btnLogType my-4">
                  <a href="#" className="mainbtn tada"><i className="fa fa-google" /> Google</a>
                  <a href="#" className="mainbtn tada"><i className="fa fa-facebook-official" /> Facebook</a>
                </div>
                {/* <p className="mb-0">Click here to <a href="#">Login</a></p> */}
              </div>
              <div className="bg-white loginBox d-none">
                <img src="src/new-logo.png" alt="" width="140px" />
                <h1 className="loginHeading text-left mb-4">
                  OTP Verifaction
                </h1>
                <form action="/action_page.php">
                  <div className="input-group mb-4">
                    <span className="input-group-text"><i className="fa fa-envelope" /></span>
                    <input type="password" className="form-control" placeholder="Password" name="password" />
                    <span className="input-group-text eyePass"><i className="fa fa-pencil-square-o" /></span>
                  </div>
                  <p className="mb-3 text-center">Enter OTP</p>
                  <div className="input-group mb-4 otpDv bg-white">
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                    <input type="text" className="form-control" placeholder name="password" />
                  </div>
                  <p className="mb-4 text-center"><a href="#">Resend OTP</a></p>
                  <button type="submit" className="btn mainbtn tada w-100">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />

    </main>
  )
}

export default EditProfile;
