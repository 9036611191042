import React, { useState, useEffect } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
// import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
// import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CoustomStorage';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const SendByEmailModal = (props) => {
    const api = new API()
    const { type, modal, toggle, heading, sendInvitationEmailForRegister } = props

    const [] = useState(true)

    const [Name, setName] = useState()
    const [Email, setEmail] = useState()
    const [bulkEmail, setBulkEmail] = useState([])
    const [emailBulkFile, setEmailBulkFile] = useState([])
    const [fileLabelName, setFileLabelName] = useState("")

    useEffect(() => {

    }, []);
    const sendingEmail = () => {
        if (emailBulkFile.length !== 0 || bulkEmail.length !== 0) {
        
            switch (type) {
                case "registerInviteEmail":
                    sendInvitationEmailForRegister(emailBulkFile, bulkEmail, resetValue)
                    break;
                case "":
    
                    break;
            }
        } else {
            toast.warning("please upload a file or enter email manualy")
    }}
    const addBulkMail = () => {
    
        if (Name !== "" && Email !== "") {

            let bulk = { "name": Name, "email": Email }
            bulkEmail.push(bulk)
            setBulkEmail(bulkEmail)
            setName("")
            setEmail("")
        } else {
            toast.warning("please enter name and email")
        }
    }
    const addBulkMailFile = (e) => {
        setEmailBulkFile(e.target.files)
        setFileLabelName(e.target.files[0])
    }
    const resetValue = () =>{
        setName("")
        setEmail("")
        setBulkEmail("")
        setEmailBulkFile("")
    }


    return (
        <div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} className="mar-left">{heading}<span></span></ModalHeader>
                <ModalBody>
                    <div className="container">

                        <form className="">
                            <div className="custom-file col-md-12">
                                <input type="file" name="file" onChange={addBulkMailFile} className="custom-file-input" id="customFile" />
                                <label className="custom-file-label" for="customFile">{fileLabelName && fileLabelName.name == "" ? "chose file" : fileLabelName.name }</label>

                            </div>
                            <h4 align="center">OR</h4>
                            <div class="row">
                                {/* <div class="col"> */}
                                <input type="text" onChange={(e) => setName(e.target.value)} value={Name} className="form-control col" placeholder="Enter Name" />
                                {/* </div> */}
                                {/* <div class="col"> */}
                                <input type="text" onChange={(e) => setEmail(e.target.value)} value={Email} className="form-control col ml-1" placeholder="Enter Email" />
                                {/* </div> */}
                                <div class="col-3 mt-1">
                                    <button type="button" onClick={addBulkMail} className="btn btn-secondary">Add</button>
                                </div>
                            </div>
                        </form>
                        <div style={{ maxHeight: "140px", overflow: "auto" }}>

                            <table class="table table-sm table-primary" style={{fontSize:"13px"}}>
                                <thead>
                                    <tr >
                                        <th scope="col">#</th>
                                        <th scope="col">name</th>
                                        <th scope="col">email</th>
                                    </tr>
                                </thead>
                                {bulkEmail && bulkEmail.map((data, index) => (
                                    <tbody >
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>

                                        </tr>

                                    </tbody>
                                ))}
                            </table>



                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="mt-5">
                    <div className="col-lg-4 col-4 custom mb-3">
                        <button type='submit' onClick={sendingEmail} className="btn btn-primary btn-block">Send Email</button>

                    </div>
                </ModalFooter>
            </Modal>
        </div>



    );
}

export default SendByEmailModal;