import React from 'react';
import { useState, useEffect } from 'react';
import API from '../../utils/apiCalling';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

// import FileSaver from 'file-saver';


const ImageView = () => {
  let api = new API()

  const [image, setImage] = useState([])
  const [findImage, findLoanNumber] = useState('')
  const [secondsearch, setSecondSearch] = useState('')
  const [MonthWise, setMonthWise] = useState('')

  useEffect(() => {
    getImage()
  }, [])

  const setartifact = async (value) => {
    let data = {
      artifactType: value
    }
    let result = await api.post(`https://api.doyoursurvey.com:3009/Avanti/GetImageFilter`, data)
    if (result.code === 200) {
      setImage(result.data)
    } else {
      setImage(result.data)
    }
  }

  const MonthWiseImage = async () => {
    let data = {
      date: MonthWise
    }
    let result = await api.post(`https://api.doyoursurvey.com:3009/Avanti/MonthWiseImage`, data)
    if (result.code === 200) {
      setImage(result.data)
    } else {
      setImage(result.data)
    }
  }

  const getImage = async () => {
    let data = {
      Image: findImage
    }
    let result = await api.post(`https://api.doyoursurvey.com:3009/Avanti/searchImage`, data)
    if (result.code === 200) {
      // console.log(result)
      setImage(result.data)
    }
  }

  const secondImage = async () => {
    let data = {
      ImageArray: JSON.stringify(image),
      searchValue: secondsearch
    }
    let result = await api.post('https://api.doyoursurvey.com:3009/Avanti/searchImagetwo', data)
    if (result.code === 200) {
      setImage(result.data)
    }
  }


  const handleDownload = (e) => {
    saveAs(e.Image, e.Id);
  }

  const handleDownloadImage = async () => {
    // createZipFile(image.map(e=>e.Image));
    window.location.href = 'https://api.doyoursurvey.com:3009/Avanti/downloadImage';
  }


  const createZipFile = async (imageUrls) => {
    const zip = new JSZip();

    for (let i = 0; i < imageUrls.length; i++) {
      const response = await fetch(imageUrls[i]);
      const blob = await response.blob();
      zip.file(`image_${i + 1}.jpg`, blob);
    }
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, 'images.zip');
    });
  };

  return (
    <>
      <div className='col-lg-12 '>
        <label>Select Artifact</label>
        <select className='ml-1' type="month" placeholder='Search...' name="bdaymonth" onChange={(e) => setartifact(e.target.value)}>
          <option hidden>Select option</option>
          <option value="LUC">Artifact</option>
          <option value="Other">Other Artifact</option>
        </select>
        {/* <button className='py-1 mainbtn m-3' onClick={Artifact}>Find</button> */}
        <label>Enter month</label>
        <input className='ml-1' type="month" placeholder='Search...' name="bdaymonth" onChange={(e) => setMonthWise(e.target.value)} />
        <button className='py-1 mainbtn m-3' onClick={MonthWiseImage}>Find</button>
        <label>Enter Loan No</label>
        <input className='ml-4' type='text' onChange={e => findLoanNumber(e.target.value)} placeholder='Search...' />
        <button className='py-1 mainbtn m-3' onClick={getImage}>Find</button>
        <label>Enter location</label>
        <input className='ml-4' type='text' onChange={e => setSecondSearch(e.target.value)} placeholder='Search...' />
        <button className='py-1 mainbtn m-4' onClick={secondImage}>second Find</button>



        <button className='py-1 mainbtn m-4' onClick={handleDownloadImage}>All Download</button>
        <div className='d-flex flex-wrap'>
          {image && image?.map((e) => {
            return (
              <div className='card ContentTap1' >
                <div className='UnnderItems text-center' >
                  <img src={e.Image} alt='Img' height={180} width={240} />
                  <p className='mb-0'>Loan Number <span>: {e.Id}</span></p>
                  <p className='mb-0 text-orange' onClick={(q) => handleDownload(e)} style={{ cursor: 'pointer' }}>Download </p>
                </div>
                <div className='UnnderItems UnderSamers h-100'>
                </div>
              </div>)
          })}
        </div>
      </div>
    </>
  );
}

export default ImageView;
