import React, { useState, useEffect } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
// import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CoustomStorage';

// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link } from "react-router-dom";
import Moment from 'react-moment';
import Loader2 from '../../utils/loader2/loader2'
const RewardUseModal = (props) => {
    const api = new API()
    const { userRedeemHistory, userName, loader_2, emailForRedeemRequest, getUserAllRedeem, user, type } = props

    const [loader2, setLoader2] = useState(true)
    const [couponCode, setCouponCode] = useState()

    useEffect(() => {
       
        if (loader_2 == false) {
            setLoader2(false)
        }
        else {
            setLoader2(true)
        }
    })

    const couponApprovalRequest = async (redeemPoint) => {
        if (couponCode == "" || couponCode == "undefined" || couponCode == undefined) {
            toast.warning("Please Enter Voucher Code")
        } else {
            let data = {
                email: emailForRedeemRequest,
                couponeId: redeemPoint._id,
                couponeCode: couponCode,
                voucherFor : redeemPoint.voucherFor,
                voucherPoint : redeemPoint.RedeemPoint,
                newStatus: "success"
            }
       
            let result = await api.post(config.couponApprovalRequest, data)
         
            if (result.status == "Success") {
                toast.success("Coupon Approved Successfully")
                setCouponCode("")
                getUserAllRedeem(user, type)
                // window.location.reload(); 
            }
        }
    }


    return (
        <div>
            {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".bd-example-modal-lg">Large modal</button> */}
            <div className="modal fade" id="rewardUserModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ minHeight: "450px" }}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Redeem Point History</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                {loader2 == true ?
                                    <div>
                                        <Loader2 />
                                    </div>
                                    :
                                    <>
                                        {userRedeemHistory.length > 0
                                            ?
                                            <>
                                                {userRedeemHistory.map((redeemPoint, index) => (
                                                    <div className="card mb-5">
                                                        <div className="card-header">
                                                            <h6>{index + 1} User Email : {user.user}</h6>
                                                            <div className="card-body" style={{ borderTop: "1px solid" }}>
                                                                <div className="col-md-12">
                                                                    <table class="table text-center">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col">Voucher Id</th>
                                                                                <th scope="col">Redeem Point</th>
                                                                                <th scope="col">Voucher For</th>
                                                                                <th scope="col">Voucher Code</th>
                                                                                <th scope="col">Voucher Status</th>
                                                                                <th scope="col">Voucher Created Date</th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {/* <tr>
                                                                    <th scope="row">1</th>
                                                                    <th scope="row">2</th>
                                                                    <th scope="row">3</th>
                                                                    <th scope="row">4</th>
                                                                    <th scope="row">5</th>
                                                                    <th scope="row">6</th>
                                                                    <td>{redeemPoint._id}</td>
                                                                </tr> */}
                                                                            <tr>
                                                                                <td>{redeemPoint._id}</td>
                                                                                <td>{redeemPoint.RedeemPoint}</td>
                                                                                <td>{redeemPoint.voucherFor}</td>
                                                                                <td>{redeemPoint.voucherCode}</td>
                                                                                <td>{redeemPoint.status}</td>
                                                                                <td><Moment format="YYYY/MM/DD - hh:mm:ss a">
                                                                                    {redeemPoint.date}</Moment></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {redeemPoint.status == "Pending" &&
                                                                    <form class="form-inline">
                                                                        <div class="form-group mx-sm-3 mb-2">
                                                                            <label for="vouchercode" class="sr-only">Voucher code</label>
                                                                            <input type="text" onChange={(e) => setCouponCode(e.target.value)} id="vouchercode" class="form-control" value={couponCode} placeholder="Enter Voucher Code ...." />
                                                                        </div>
                                                                        <button type="submit" onClick={() => couponApprovalRequest(redeemPoint)} class="btn btn-primary mb-2">Click to Approve</button>
                                                                    </form>
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <div className="card-footer">
                                       
                                    </div> */}

                                                    </div>
                                                ))}
                                            </>
                                            :
                                            <>
                                                <div className="container text-center">
                                                    <h4>No Redeem Point History Yet</h4>
                                                </div>
                                            </>}
                                    </>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
}

export default RewardUseModal;