// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics, getToken,getMessaging } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAEb9xon0D4meT1G_KJH-0-q4vJP22nilM",
  authDomain: "doyoursurvey-311900.firebaseapp.com",
  projectId: "doyoursurvey-311900",
  storageBucket: "doyoursurvey-311900.appspot.com",
  messagingSenderId: "479780986499",
  appId: "1:479780986499:web:e486ce76a387b0c19a45b6",
  measurementId: "G-N1BTT8JH3N"
  };
// "AAAAb7Us3oM:APA91bHosUjpdwRkK1s0kQf_Dhv_jPFjVz2JpafqloEh4H9qgVznDeqgS2Q2hYRPW1dQa0RLlAHjgWoPz5I6bPtR3t8NegJ6UT1qVUIlxNsQhXUQDEnzkLRY1-W6lYgPAnj9I8Rb3rrd"

// Initialize Firebase

function requestPermission() {
   
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
      
        const app = initializeApp(firebaseConfig);
        // const analytics = getAnalytics(app);
        const messaging = getMessaging(app);
        getToken(messaging,{vapidKey:"BCZruTUuPcS6Qgf4U91ofNNcxhQHlPrzN__fMgcC2SWAyWQcw43QKWeQlhONK6rNm2OI31Mu0L18ff_nRFn4ovc",
    }).then((currentToken)=>{
        if(currentToken){
          console.log(currentToken)
            localStorage.setItem("deviceId",currentToken)
    
        }else{
      console.log("somthing went wrong")
        }
    });
}else{
  
}
});
}
     { 'Notification' in window &&
     'serviceWorker' in navigator &&
     'PushManager' in window &&requestPermission()}

 export default requestPermission;     