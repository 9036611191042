import React, { useState, useEffect, useRef } from "react";
import Header from "../Components/ReusableComponents/Header";
import Header2 from "../Components/ReusableComponents/Header2";
import Footer from "../Components/ReusableComponents/Footer";
import { useHistory } from "react-router-dom";
import API from "../utils/apiCalling";
import Payment from "./payment";
import Config from '.././config/env'

const PlanPage = () => {
  const api = new API();
  const modelOpen1 = useRef(null);
  const history = new useHistory()
  let getUser = JSON.parse(localStorage.getItem("user"));
  const [monthly, setMonthly] = useState("Month");
  const [pland, setPlan] = useState([]);
  const [basicPlan, setBasicPaln] = useState();
  const [premiumplan, setPremiumPlan] = useState();
  const [EnterpricePlan, setEnterpricePlan] = useState();
  const [amount, setAmount] = useState();
  const [Package, setPackage] = useState();
  const [actvie, setActive] = useState(0)
  const [Plan, SetPlan] = useState([])


  // let date=new Date();
  // date.toString();


  useEffect(() => {
    getPlan();
    getPackages()
  }, []);

  const handlePlan = (type) => {
    if (type === true) {
      setMonthly("Year");
      setBasicPaln(pland[1]?.Yearly);
      setPremiumPlan(pland[2]?.Yearly);
      setEnterpricePlan(pland[3]?.Yearly);
    } else {
      setMonthly("Month");
      setBasicPaln(pland[1]?.Monthly);
      setPremiumPlan(pland[2]?.Monthly);
      setEnterpricePlan(pland[3]?.Monthly);
    }
    console.log(type);
  };
  const handleClick = () => {
    modelOpen1.current.click();
  }
  const trialDate = () => {
    let today = new Date();
    let dd = today.getDate();

    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    today = dd + 7 + '-' + mm + '-' + yyyy;
    return today
  }

  const handlepaymentt = async (amount, Packages) => {
    if (Packages.Monthly === 'Free') {
      let data = {
        plan_id: Packages._id,
        Id: getUser._id,
        plan_days: 7,
      };
      console.log("Hello Freee", data)
      let result = await api.post(`${Config.new.site_api}/client-survey/addPlan`, data);
      console.log("sdjfhjksdf", result)
      if (result?.code === 200) {
        handleClick()
      }

    } else {
      setAmount(amount);
      setPackage(Packages);
      setActive(1);
    }
  };
  const getPlan = async () => {
    let result = await api.gett(
      `${Config.new.site_api}/client-survey/getPlan`
    );
    if (result.code === 200) {
      console.log(result.data);
      let temparray=[]
      for(let i=0;i<result.data.length; i++){

        if(result.data[i].Status===0)
          {
            temparray.push(result.data[i])
          }
      }
      setPlan(temparray);
      setBasicPaln(result?.data[1].Monthly);
      setPremiumPlan(result?.data[2].Monthly);
      setEnterpricePlan(result?.data[3].Monthly);
    }
  };

  const getPackages = async () => {
    let data = {
      _id: getUser?._id
    }
    let result = await api.post(`${Config.new.site_api}/client-survey/getPackages`,
      data)
    if (result?.code === 200) {
      // console.log("Heelllloxcxvxcvxcv", result.data[0].totalPlan)
      SetPlan(result.data)
    }
  }

  return (
    <>
      {actvie === 0 ? (
        <>
          {/* <!-- Modal --> */}
          <div className="modal fade" id="trynowtog" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header align-items-center">
                  <h1 className="modal-title fs-5 mt-0" id="exampleModalLabel">Free plan</h1>
                  <button type="button" className="btn-close" data-bs-target="#trynowtog" data-bs-dismiss="modal"></button>
                </div>
                <div className="modal-body">
                  Free plan is activeted in your account for 7 days. This will end on {trialDate()}.
                </div>
                {/* date.setDate(date.getDate() + 7) */}
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#trynowtog" onClick={() => history.push('/adminDash')} aria-label="Close">Save</button>
                </div>
              </div>
            </div>
          </div>
          <Header2 />
          <section className="PlanPricing mb-3">
            <div className="container">
              <div className="form-check form-switch p-0 m-0 mb-3 d-flex justify-content-end">
                <span className="fw-bold text-orange">Monthly</span>
                <input
                  className="form-check-input position-relative p-0 mx-3 m-0"
                  type="checkbox"
                  role="switch"
                  onChange={(e) => handlePlan(e.target.checked)}
                  id="flexSwitchCheckDefault"
                  value="1"
                />
                <span className="fw-bold text-orange">Yearly</span>
              </div>
              <div className={(Plan[0]?.plan_id) || (Plan[0]?.totalPlan)?.length > 0 ? "" : ''}>
                <table className="table table-bordered text-center mb-0">
                  <thead className="top-theader">
                    <tr className="res-d-none">
                      <th className="align-middle py-5 text-center px-2">
                        <div className="pricing-duration">
                          <div className="text-center">
                            <p
                              className="fs-3 text-blue p-0 m-0 mb-2"
                            >
                              Our Plans
                            </p>
                            {/* <a className="navbar-brand" href="/">
                          <img src="../images/new-logo.png" width="35%" alt="" />
                          </a> */}
                          </div>
                        </div>
                      </th>
                      {pland &&
                        pland.map((e) => (
                          (e.Name !== "Unlimited access") ?
                            <th className="align-middle py-5 text-center px-2" >
                              <div className="pricing-duration">
                                <div className="text-center">
                                  <h5>
                                    {" "}
                                    <span className="text-blue">{(e.Name)}</span>
                                  </h5>
                                  <div className="monthly">
                                    <div className="d-flex align-items-end justify-content-center mt-3 mb-2 text-nowrap">
                                      <h5 className="align-self-start">₹</h5>
                                      <h2 className="mb-0 lh30 text-orange font-weight-bold">
                                        {monthly === "Month"
                                          ? e.Monthly
                                          : e.Billed_Annually}
                                      </h2>
                                      /
                                      {e.Monthly === "Free" ? " 7 Days" : monthly === "Month" ? "Month" : "Month"}
                                    </div>
                                    <p className="fw-normal">{monthly !== "Month" ? (e.Monthly !== "Free" ? "Billed Annually" : " ") : ''} </p>
                                  </div>
                                  <button
                                    className="mainbtn"


                                    type="button"
                                    onClick={(q) =>
                                      handlepaymentt(
                                        monthly === "Month"
                                          ? e.Monthly
                                          : e.Yearly,
                                        e
                                      )
                                    }
                                  >
                                    {e.Monthly === "Free" ? "Try Now" : "Buy Now"}
                                  </button>
                                </div>
                              </div>
                            </th> : ''
                        ))}
                    </tr>
                  </thead>

                  <tbody>
                    <tr className="monthly">
                      <td className="text-left">
                        <b>
                          {" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </b>
                      </td>
                      <td>
                        <p className="mb-0 exte">
                          Pay As You Go{" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </p>
                      </td>
                      <td>
                        <p className="mb-0 exte">
                          Pay As You Go{" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </p>
                      </td>
                      <td>
                        <p className="mb-0 exte">
                          Pay As You Go{" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </p>
                      </td>
                      <td className="">
                        <p className="mb-0 exte">
                          Customised Solutions for Your Business{" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem1"
                    aria-expanded="false"
                    aria-controls="groupItem1"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Limit</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem1"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td className="text-left">
                        <b>Number of Users</b>
                      </td>
                      <td className="align-middle">1</td>
                      <td className="align-middle">2</td>
                      <td className="align-middle">4</td>
                      <td className="align-middle">4</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Number of Responses/month <span className="planquess ms-1"><i className="bi bi-question-circle"></i></span><span className="planTooltips">For reaching out specific TG/respondents use our panel</span></b>
                      </td>
                      <td className="align-middle">50</td>
                      <td className="align-middle">500</td>
                      <td className="align-middle">2000</td>
                      <td className="align-middle">Unlimited</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Share Survey<span className="planquess ms-1"><i className="bi bi-question-circle"></i></span><span className="planTooltips">Panel fee applicable
                        </span></b>
                      </td>
                      <td className="align-middle">System-Generated URL</td>
                      <td className="align-middle">
                        System-Generated URL + Email + WhatsApp + Social Media
                      </td>
                      <td className="align-middle">System-Generated URL + Email + WhatsApp + Social Media + QR Code</td>
                      <td>
                        System-Generated URL + Email + WhatsApp + Social Media + QR Code
                      </td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem2"
                    aria-expanded="false"
                    aria-controls="groupItem2"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Research Approach</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem2"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td className="text-left">
                        <b>Digital/Web Surveys</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Focus Group Discussion<span className="planquess ms-1"><i className="bi bi-question-circle"></i></span><span className="planTooltips">Customized Cost Applicable
                        </span></b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Telephonic Interviews<span className="planquess ms-1"><i className="bi bi-question-circle"></i></span><span className="planTooltips">Customized Cost Applicable
                        </span></b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Personal Interview<span className="planquess ms-1"><i className="bi bi-question-circle"></i></span><span className="planTooltips">Customized Cost Applicable
                        </span></b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem3"
                    aria-expanded="false"
                    aria-controls="groupItem3"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Digital Survey Type</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem3"
                    className="collapse collapse-horizontal"
                  >
                    <tr>
                      <td className="text-left">
                        <b>Classic Digital Survey</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Vernacular Survey</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Chat Based Surveys<span className="planquess ms-1"><i className="bi bi-question-circle"></i></span><span className="planTooltips">Differential Panel fee applicable
                        </span></b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Audio Survey<span className="planquess ms-1"><i className="bi bi-question-circle"></i></span><span className="planTooltips">Differential Panel fee applicable
                        </span></b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Video Survey<span className="planquess ms-1"><i className="bi bi-question-circle"></i></span><span className="planTooltips">Differential Panel fee applicable
                        </span></b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem6"
                    aria-expanded="false"
                    aria-controls="groupItem6"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Survey Designing</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem6"
                    className="collapse collapse-horizontal"
                  >
                    <tr>
                      <td className="text-left">
                        <b>No. of Questions</b>
                      </td>
                      <td>10</td>
                      <td>As per requirement</td>
                      <td>As per requirement</td>
                      <td>As per requirement</td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Survey Layout</b>
                      </td>
                      <td>Basic Layout</td>
                      <td>Customizable</td>
                      <td>Customizable</td>
                      <td>Customizable</td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Questions Logics</b>
                      </td>
                      <td>Basic Logics</td>
                      <td>Advanced Logics</td>
                      <td>Advanced Logics</td>
                      <td>Advanced Logics</td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Languages</b>
                      </td>
                      <td>English + Hindi</td>
                      <td>English + Hindi</td>
                      <td>25+ Languages</td>
                      <td>25+ Languages</td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Font Types & Sizes</b>
                      </td>
                      <td>Basic Font Type and Size</td>
                      <td>Adjustable font size for both Q&A</td>
                      <td>Adjustable font size for both Q&A</td>
                      <td>Adjustable font size for both Q&A</td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Customizing Header & Footer</b>
                      </td>
                      <td></td>
                      <td>Customizable</td>
                      <td>Customizable</td>
                      <td>Customizable</td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Whitelisting surveys</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Personal branding</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>API Integrations</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem4"
                    aria-expanded="false"
                    aria-controls="groupItem4"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Question Types</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem4"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td className="text-left">
                        <b>Contact Information</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Single line text Input</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Multiple Choice</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Number Input</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Radio Option</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    {/* <tr>
                      <td className="text-left">
                        <b>Multiple Choice</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr> */}
                    <tr>
                      <td className="text-left">
                        <b>Rating Question</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>NPS</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>CSAT</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Matrix Question</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Emoji Rating scale</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Ranking</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Opinion scale</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>2 column checkbox</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Checkbox Grid</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Checkbox with Others Grid</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>NPS Grid</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Dropdown Grid</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Dropdown with other Grid</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Single line Text Grid</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem5"
                    aria-expanded="false"
                    aria-controls="groupItem5"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Online FGD Delivarables</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem5"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td className="text-left">
                        <b>Discussion Guide (combined)</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Transcripts (per group)</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Recordings (per group)</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Live FGD Link (per group)</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Reporting (combined)</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem91"
                    aria-expanded="false"
                    aria-controls="groupItem5"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Survey Time Data</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem91"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td className="text-left">
                        <b>Real Time Access To Data</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Download Data to Excel/CSV</b>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem92"
                    aria-expanded="false"
                    aria-controls="groupItem5"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Report and Analysis</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem92"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td className="text-left">
                        <b>Dashboard Analytics (User Customized)</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Online Survey Analytics</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Response Count Summary</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Response Reports</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Download Report in PDF</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Download Report in PPT</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Detailed Consultative Report</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem93"
                    aria-expanded="false"
                    aria-controls="groupItem5"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Consultation</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem93"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td className="text-left">
                        <b>Chat Support</b>
                      </td>
                      <td>
                        <i className="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i className="bi bi-check-circle"></i>
                      </td>
                      <td><i className="bi bi-check-circle"></i></td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <div ref={modelOpen1} data-bs-toggle="modal" data-bs-target="#trynowtog"></div>
          <Footer />
        </>
      ) : (
        <Payment amount={amount} Package={Package} monthly={monthly} />
      )}
    </>
  );
};

export default PlanPage;
