import React, { useEffect, useState } from 'react'

function TargetingYearOptions() {
    const [userData, setUserData] = useState()


    useEffect(() => {
        let userData = JSON.parse(localStorage.getItem('user'));
        setUserData(userData);
       

    }, [])
    const logOut = () => {
        localStorage.clear();
    }
    return (
        <div>
            <section id="all-section" className="admin-header">
                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom">
                    <div className="container-fluid ">
                        <a className="navbar-brand1" href="#"><img src="../images/new-logo.png" width="100px" alt="" /></a>
                        <ul className="navbar-nav flex-row">

                            <li className="nav-item dropdown">
                                <a className="nav-link nav dropdown-toggle d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="../images/2@2x.png" width="50px" className='rounded-circle' alt="" /><p className='aname m-0 px-2 text-black'>{userData && userData.fullName}</p><i className="text-black bi bi-chevron-down"></i>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-lg-end">
                                    <li><a className="dropdown-item" href="#">Profile Settings</a></li>
                                    <li><a className="dropdown-item" onClick={logOut} href="#">Logout</a></li>
                                    {/* <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                                </ul>
                            </li>
                        </ul>
                    </div></nav>
            </section>
            <section id="all-section" className="admin-header">
                <nav className="navbar navbar-expand-lg bg-white p-0">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#"><img src="src/new-logo.png" width="120px" alt="" /></a>
                        <ul className="navbar-nav">
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="src/2@2x.png" width="50px" alt="" />
                                </a>
                                <ul className="dropdown-menu dropdown-menu-lg-end">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </section>
            <section id="all-section" className="section-survey ">
                <div className="container-fluid ">
                    <div className="row ">
                        {/* Sidebar  */}
                        {/* Page Content  */}
                        <div className="col-md-8 col-lg-9">
                            <div id="content" className="w-100 p-0 m-0 mt-3">
                                <div className=" px-3 pt-4 pb-2 h-100 w-100 bg-white templateListSec ">
                                    <h6 className="mb-4 targetHdng">Targeting options</h6>
                                    <div className="w-100 innterselectSec mb-3 bg-light">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <select className="form-select w-100 mb-4 selectInnr">
                                                    <option>Ages of children</option>
                                                    <option>Ages of children</option>
                                                </select>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend ">
                                                        <span className="input-group-text selectInnr"><i className="fa fa-search" aria-hidden="true" /></span>
                                                    </div>
                                                    <input className="form-control selectInnr" type="text" placeholder="Search" style={{ borderLeft: 'none' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="text-right mt-2"><a href="#" className="mainbtn text-white" style={{ padding: '10px 30px' }}>Select all</a>
                                            <a href="#" className="mainbtn mainbtn2" style={{ padding: '10px 30px' }}>Deselect all</a></p>
                                        <h6 className="mb-3 mt-2">Most Popular</h6>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="containerCheck d-block">2017
                                                    <input type="checkbox" defaultChecked="checked" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2018
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2019
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2020
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2010
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2021
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">1991 or earlier
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">1992
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">1993
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">1994
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">1995
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="containerCheck d-block">1996
                                                    <input type="checkbox" defaultChecked="checked" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">1997
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">1998
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2000
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2001
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2002
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2003
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2004
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2005
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2006
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2007
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="containerCheck d-block">I don't have children
                                                    <input type="checkbox" defaultChecked="checked" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2008
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2011
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2012
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2013
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2014
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">Prefer not to say
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2015
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                                <label className="containerCheck d-block">2016
                                                    <input type="checkbox" name="radio" />
                                                    <span className="checkmarkCheck" />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4">
                            <div className="py-3 px-3 templateCatSelect bg-white  mt-3" style={{ border: '1px solid #D8D8D8' }}>
                                <h5 className="mb-4 targetHdng">Your selections</h5>
                                <h6 className=" noTargetTxt">2017 <span className="float-right"><i className="fa fa-times" aria-hidden="true" /></span></h6>
                                <p className="text-center mt-5"><a href="#" className="mainbtn text-white w-75 d-block mx-auto" style={{ padding: '10px 30px' }}>Apply</a></p>
                            </div>
                        </div>
                        {/* jQuery CDN - Slim version (=without AJAX) */}
                        {/* Popper.JS */}
                        {/* Bootstrap JS */}
                        {/* jQuery Custom Scroller CDN */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TargetingYearOptions
