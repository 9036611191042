import React, { useEffect, useState } from "react";
import NpsQuestions from "./allQuestions";
import API from '../../../utils/apiCalling';
import Config from '../../../config/env'
import moment from "moment"
import { CSVLink } from "react-csv";
// import './npsCss.css'

const NpsReport = (props) => {
   
    let api = new API()
    const [user, setUser] = useState({})
    const [screen, setScreen] = useState(4)
    const [reportData, setReportData] = useState()
    const [excelData, setExcelData] = useState([])
    const [excalButton,setExcalButton]= useState(true)
    const [boxCounts, setBoxCounts] = useState({
        triggerCount: 0,
        deliverCount: 0,
        responseRate: 0
    })

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')))
        getData()
    }, [])

    let data = {
        chart: {
            type: 'column',
            padding: 0
        },
        title: {
            text: null,
            align: 'left'
        },

        xAxis: {
            categories: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
            crosshair: true,
            gridLineWidth: 0
        },
        yAxis: {
            title: {
                text: null
            },
            labels: {
                enabled: false // Hide x-axis labels
            },
            gridLineWidth: 0,

        },
        tooltip: {
            enabled: false // Hide tooltip
        },
        legend: {
            enabled: false // Hide legend
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                borderWidth: 0,
                borderRadius: 10,
                colorByPoint: true,
                dataLabels: {
                    enabled: true,
                },

                colors: ['#F51818', '#F51818', '#F51818', '#F51818', '#F51818', '#F51818', '#F51818', '#FFB740', '#FFB740', '#3AC16B', '#3AC16B'] // Set a specific color for the bars
            }
        },
        series: [{
            name: null,
            data: [20, 35, 48, 41, 45, 50, 48, 103, 89, 142, 200]
        },
        {
            name: 'Temperature',
            type: 'line',
            color: '#7467F0',
            data: [20, 35, 48, 41, 45, 50, 48, 103, 89, 142, 200],
            marker: {
                fillColor: 'white', // Point background color
                lineWidth: 2, // Point border width
                lineColor: 'orange' // Point border color
            }

        }]
    };
    const getData = async () => {
        let counts = { ...boxCounts }
        console.log({ ...boxCounts }, 'props')
        let data = {
            surveyId: props.id
        }
        const surveyData = await api.post(`${Config.new.site_api}/client-survey/getNpsReport`, data)
        if (surveyData.code === 200) {
            setReportData(surveyData.data)
            generateExcelData(surveyData.data)
            surveyData.data.map((e, index) => {
                if (e?.dynamicFields?.MobileNo) {
                    counts.triggerCount = counts.triggerCount + 1
                    console.log(counts.triggerCount, "kkk")
                }
                if (surveyData.data.length - 1 == index) {
                    setBoxCounts(counts)
                    console.log("e?.dynamicFields?.MobileNo", counts.triggerCount, boxCounts, "e?.dynamicFields?.MobileNo")
                }
            })

        }
    }

    const generateExcelData = (surveyData) => {
        let filterData
        surveyData.map((res) => {
            filterData = {}
            // filterData['Enquiryid']=res.dynamicFields.Enquiryid
            let dynamicFields=res.dynamicFields
            filterData={...dynamicFields}
            res.questions.length>0&&res.questions.map((que) => {
               
                    filterData[que.question] = filterData[que.question] ? filterData[que.question] : (que.answer !== undefined) ? que.answer : que.answer
                
            })
            // console.log(filterData, "filterData")
            excelData.push(filterData)


        })
        setExcalButton(false)
    }


    const wish = () => {
        let d = new Date();
        let timeOfDay = d.getHours();

        if (timeOfDay >= 0 && timeOfDay < 12) {
            return 'Good morning'
        }
        if (timeOfDay >= 12 && timeOfDay < 16) {
            return 'Good afternoon'
        }
        if (timeOfDay >= 16 && timeOfDay < 21) {
            return 'Good Evening'
        } if (timeOfDay >= 21 && timeOfDay < 24) {
            return "Good Night"
        }
    }


    return (
        <>
            {/* <header>
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        <div className="headLeft">
                            <a href="">
                                <img src="../images/new-logo.png" alt="" width={90} />
                            </a>
                        </div>
                        <div className="headRight">
                            <div className="dropdown">
                                <a href="" className="userprofile" data-bs-toggle="dropdown">
                                    <img src="../images/man.png" /> Smith Jhones
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            My Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header> */}
            {screen == 4 && <main>
                <div className="dashMian">
                    <div className="container-fluid">
                        <div className="cardBox dashboardBnner mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <h1>
                                    {wish()}, <b className="fw-semibold">{user?.fullName}!</b>
                                </h1>
                                <div className="d-flex align-items-center">
                                    <div className="calenderCont">
                                        <h6>Today :</h6>
                                        <span className="grays-clr fontsi13">
                                            2022-12-13 To 2022-12-13
                                        </span>
                                    </div>
                                    <div className="dropdown">
                                        <button
                                            data-bs-toggle="dropdown"
                                            className="border-0 bg-transparent ms-2"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src="images/calender-blue-round.svg"
                                                width={40}
                                                alt="calender-image"
                                            />
                                        </button>
                                        <ul
                                            className="dropdown-menu calenderMenuman"
                                            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 10px" }}
                                        >
                                            <li>
                                                <a className="dropdown-item" href="javascript:void(0);">
                                                    {" "}
                                                    Today
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Yesterday
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Last 7 Days
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Last 30 Days
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    This Month
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Select Months
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Custom Range Month
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <button
                                        className="filterToggle"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#filterOffcanvas"
                                    >
                                        <img src="../images/Filters-orgs-rounnd.svg" alt="" width={40} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 d-flex gap-3 justify-content-end mb-3">
                                <button type="button" className="btn btn-secondary">
                                    Export to PDF
                                </button>
                                <button type="button" className="btn btn-secondary">
                                    Export to PPT
                                </button>
                                {/* <button type="button" className="btn btn-secondary">
                                    Export to CSV
                                </button> */}
                                {excalButton?<button type="button" className="btn btn-secondary" disabled={excalButton} >Export to CSV</button>:
                                <CSVLink color="black" data={excelData}
                                    filename={`${props?.SurveyName} Report.csv`} >
                                    <button type="button" className="btn btn-secondary" >Export to CSV</button>
                                </CSVLink >}
                            </div>
                        </div>
                        <div className="cardBox mb-3 responseBox">
                            <div className="row px-md-1">
                                <div className="col-md-4 px-md-2">
                                    <div className="innerBox contactResponded position-relative h-100">
                                        <figure className="m-0 position-absolute end-0 top-0">
                                            <img
                                                src="images/rightgreen.svg"
                                                alt="survey-invitations"
                                                width={80}
                                            />
                                        </figure>
                                        <p>TOTAL SURVEY TRIGGER</p>
                                        <span>{boxCounts.triggerCount}</span>
                                        <b className="text-success">
                                            <i className="bi bi-arrow-up-right-circle-fill" /> +14% Inc
                                        </b>
                                    </div>
                                </div>
                                <div className="col-md-4 px-md-2">
                                    <div className="innerBox contactResponded position-relative h-100">
                                        <figure className="m-0 position-absolute end-0 top-0">
                                            <img
                                                src="images/contactresponded.svg"
                                                alt="survey-invitations"
                                                width={80}
                                            />
                                        </figure>
                                        <p>TOTAL SURVEY RESPONSE</p>
                                        <span>3,500</span>
                                        <b className="text-success">
                                            <i className="bi bi-arrow-up-right-circle-fill" /> +14% Inc
                                        </b>
                                    </div>
                                </div>
                                <div className="col-md-4 px-md-2">
                                    <div className="innerBox contactResponded position-relative h-100">
                                        <figure className="m-0 position-absolute end-0 top-0">
                                            <img
                                                src="images/thumsup.svg"
                                                alt="survey-invitations"
                                                width={80}
                                            />
                                        </figure>
                                        <p>SURVEY RESPONSES RATE</p>
                                        <span>53.74%</span>
                                        <b className="text-success">
                                            <i className="bi bi-arrow-up-right-circle-fill" /> +14% Inc
                                        </b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="scoreTabToggle">
                                <ul className="nav nav-pills mb-3">
                                    <li className="nav-item">
                                        <button
                                            className="nav-link active"
                                            data-bs-toggle="pill"
                                            data-bs-target="#nspTab"
                                            type="button"
                                        >
                                            NPS Score
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className="nav-link"
                                            data-bs-toggle="pill"
                                            data-bs-target="#csatTab"
                                            type="button"
                                        >
                                            CSAT Score
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="nspTab">
                                    <div className="cardBox netpromotor mb-3 progressMain">
                                        <div className="promotorTxt">
                                            <b>NET PROMOTOR SCORE</b>
                                            <strong>60.23</strong>
                                            <p className="d-flex align-items-center gap-1">
                                                <img src="images/yes.svg" width={16} />
                                                Customers are likely to recommend us
                                            </p>

                                            <div className="progress-stacked overflow-visible">
                                                <div
                                                    className="progress progressredhvr"
                                                    role="progressbar"
                                                    aria-label="Segment one"
                                                    aria-valuenow={15}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "75%" }}
                                                >
                                                    <div className="hoverInfo progresshvr">
                                                        <strong>Promoter</strong>
                                                        <span className="text-success">75%</span>
                                                    </div>
                                                    <div className="progress-bar bg-success text-start ps-2">
                                                        75%
                                                    </div>
                                                </div>
                                                <div
                                                    className="progress progresshvr"
                                                    role="progressbar"
                                                    aria-label="Segment two"
                                                    aria-valuenow={30}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "10%" }}
                                                >
                                                    <div className="hoverInfo progresshvr">
                                                        <strong>Passive</strong>
                                                        <span className="text-warning">10%</span>
                                                    </div>
                                                    <div className="progress-bar bg-warning">10%</div>
                                                </div>
                                                <div
                                                    className="progress"
                                                    role="progressbar"
                                                    aria-label="Segment three"
                                                    aria-valuenow={20}
                                                    aria-valuemin={0}
                                                    aria-valuemax={100}
                                                    style={{ width: "15%" }}
                                                >
                                                    <div className="hoverInfo progresshvr">
                                                        <strong>Detractor</strong>
                                                        <span className="text-danger">15%</span>
                                                    </div>
                                                    <div className="progress-bar bg-danger">15%</div>
                                                </div>
                                            </div>
                                            <div className="pt-2 customLegend d-flex gap-4 align-items-center">
                                                <span>Promoter</span>
                                                <span>Passive</span>
                                                <span>Detractor</span>
                                            </div>
                                        </div>
                                        <div className="promotorImg">
                                            <lottie-player
                                                src="images/promotor.json"
                                                background="transparent"
                                                speed={1}
                                                style={{ width: "100%", height: 230 }}
                                                loop=""
                                                autoPlay=""
                                            />
                                        </div>
                                    </div>
                                    <div className="cardBox mb-3">
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <h3 className="section-title">NPS RESPONSES BY RATING</h3>
                                            <span className="npsNumber">
                                                <img src="images/nps.svg" width={15} alt="" />
                                                n= 5026
                                            </span>
                                        </div>
                                        {/* <div id="responseRating" >{data}</div> */}
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="csatTab">
                                    <div className="cardBox netpromotor mb-3">
                                        <div className="promotorTxt">
                                            <b>CSAT SCORE</b>
                                            <strong>36%</strong>
                                            <p className="d-flex align-items-center gap-1">
                                                <img src="images/yes.svg" width={16} />
                                                Customers are likely to recommend us
                                            </p>
                                            <div className="csatScroe row mt-3">
                                                <div className="col text-center">
                                                    <strong className="bg-danger">8%</strong>
                                                    <img src="images/emoji1.svg" width={40} />
                                                </div>
                                                <div className="col text-center">
                                                    <strong className="bg-danger2">11%</strong>
                                                    <img src="images/emoji2.svg" width={40} />
                                                </div>
                                                <div className="col text-center">
                                                    <strong className="bg-danger3">17%</strong>
                                                    <img src="images/emoji3.svg" width={40} />
                                                </div>
                                                <div className="col text-center">
                                                    <strong className="bg-warning">28%</strong>
                                                    <img src="images/emoji4.svg" width={40} />
                                                </div>
                                                <div className="col text-center">
                                                    <strong className="bg-success">36%</strong>
                                                    <img src="images/emoji5.svg" width={40} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="promotorImg">
                                            <lottie-player
                                                src="../images/promotor.json"
                                                background="transparent"
                                                speed={1}
                                                style={{ width: "100%", height: 230 }}
                                                loop=""
                                                autoPlay=""
                                            />
                                        </div>
                                    </div>
                                    <div className="cardBox mb-3">
                                        <div className="d-flex align-items-center justify-content-between mb-3">
                                            <h3 className="section-title">CSAT RESPONSES BY RATING</h3>
                                            <span className="npsNumber">
                                                <img src="images/nps.svg" width={15} alt="" />
                                                n= 5026
                                            </span>
                                        </div>
                                        <div id="responseRating2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cardBox">
                            <div className="d-flex align-items-center gap-2 justify-content-between mb-3">
                                <h3 className="section-title mb-0">NPS Score</h3>
                                <div className="npsFiltr">
                                    <button type="button" className="filtrTgl active">
                                        All
                                    </button>
                                    <button type="button" className="filtrTgl">
                                        SMS
                                    </button>
                                    <button type="button" className="filtrTgl">
                                        Email
                                    </button>
                                    <button type="button" className="filtrTgl">
                                        WhatsApp
                                    </button>
                                </div>
                            </div>
                            <div className="tableDiv table-responsive progressMain">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="">Journey</th>
                                            <th className="">NPS Score </th>
                                            <th className="">Total Triggered</th>
                                            <th className="">Total Response</th>
                                            <th className=" ">Response%</th>
                                            <th className="npsScoreTh">NPS Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Touch Point 1</td>
                                            <td>24.46</td>
                                            <td>7100</td>
                                            <td>601</td>
                                            <td>8%</td>
                                            <td>
                                                <div className="progress-stacked overflow-visible">
                                                    <div
                                                        className="progress progressredhvr"
                                                        role="progressbar"
                                                        aria-label="Segment one"
                                                        aria-valuenow={15}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "75%" }}
                                                    >
                                                        <div className="progress-bar bg-success text-start ps-2">
                                                            75%
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="progress progresshvr"
                                                        role="progressbar"
                                                        aria-label="Segment two"
                                                        aria-valuenow={30}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "10%" }}
                                                    >
                                                        <div className="progress-bar bg-warning">10%</div>
                                                    </div>
                                                    <div
                                                        className="progress"
                                                        role="progressbar"
                                                        aria-label="Segment three"
                                                        aria-valuenow={20}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "15%" }}
                                                    >
                                                        <div className="progress-bar bg-danger">15%</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Touch Point 2</td>
                                            <td>59.66</td>
                                            <td>10112</td>
                                            <td>528</td>
                                            <td>5%</td>
                                            <td>
                                                <div className="progress-stacked overflow-visible">
                                                    <div
                                                        className="progress progressredhvr"
                                                        role="progressbar"
                                                        aria-label="Segment one"
                                                        aria-valuenow={15}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "55%" }}
                                                    >
                                                        <div className="progress-bar bg-success text-start ps-2">
                                                            55%
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="progress progresshvr"
                                                        role="progressbar"
                                                        aria-label="Segment two"
                                                        aria-valuenow={30}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "30%" }}
                                                    >
                                                        <div className="progress-bar bg-warning">30%</div>
                                                    </div>
                                                    <div
                                                        className="progress"
                                                        role="progressbar"
                                                        aria-label="Segment three"
                                                        aria-valuenow={20}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "15%" }}
                                                    >
                                                        <div className="progress-bar bg-danger">15%</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Touch Point 3</td>
                                            <td>33.76</td>
                                            <td>38450</td>
                                            <td>3572</td>
                                            <td>9%</td>
                                            <td>
                                                <div className="progress-stacked overflow-visible">
                                                    <div
                                                        className="progress progressredhvr"
                                                        role="progressbar"
                                                        aria-label="Segment one"
                                                        aria-valuenow={15}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "55%" }}
                                                    >
                                                        <div className="progress-bar bg-success text-start ps-2">
                                                            75%
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="progress progresshvr"
                                                        role="progressbar"
                                                        aria-label="Segment two"
                                                        aria-valuenow={30}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "15%" }}
                                                    >
                                                        <div className="progress-bar bg-warning">15%</div>
                                                    </div>
                                                    <div
                                                        className="progress"
                                                        role="progressbar"
                                                        aria-label="Segment three"
                                                        aria-valuenow={20}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "35%" }}
                                                    >
                                                        <div className="progress-bar bg-danger">35%</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Touch Point 4</td>
                                            <td>21.29</td>
                                            <td>45200</td>
                                            <td>4853</td>
                                            <td>11%</td>
                                            <td>
                                                <div className="progress-stacked overflow-visible">
                                                    <div
                                                        className="progress progressredhvr"
                                                        role="progressbar"
                                                        aria-label="Segment one"
                                                        aria-valuenow={15}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "61%" }}
                                                    >
                                                        <div className="progress-bar bg-success text-start ps-2">
                                                            61%
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="progress progresshvr"
                                                        role="progressbar"
                                                        aria-label="Segment two"
                                                        aria-valuenow={30}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "15%" }}
                                                    >
                                                        <div className="progress-bar bg-warning">15%</div>
                                                    </div>
                                                    <div
                                                        className="progress"
                                                        role="progressbar"
                                                        aria-label="Segment three"
                                                        aria-valuenow={20}
                                                        aria-valuemin={0}
                                                        aria-valuemax={100}
                                                        style={{ width: "24%" }}
                                                    >
                                                        <div className="progress-bar bg-danger">24%</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="cardBox mb-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h3 className="section-title">Timelines / NPS Trend Chart</h3>
                                <div className="d-flex justify-content-end align-items-center gap-3">
                                    <span className="npsNumber">
                                        <img src="images/nps.svg" width={15} alt="" />
                                        n= 5026
                                    </span>
                                    <button type="button" className="btn btn-primary">
                                        Export Report
                                    </button>
                                </div>
                            </div>
                            <div id="timelineNps" />
                        </div>
                        <div className="cardBox mb-3">
                            <h3 className="section-title mb-3">CSAT :</h3>
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="innerBox h-100">
                                        <h3 className="section-title mb-3">Journey</h3>
                                        <div className="table-responsive tableDiv">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th width="25%" className="">
                                                            Journey
                                                        </th>
                                                        <th width="25%" className="">
                                                            Response
                                                        </th>
                                                        <th width="25%" className="">
                                                            NPS
                                                        </th>
                                                        <th width="25%" className="">
                                                            CSAT
                                                        </th>
                                                        <th width="25%" className="">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>CDC</td>
                                                        <td>58946</td>
                                                        <td>9</td>
                                                        <td>3.69</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button
                                                                    href="#"
                                                                    className="bg-transparent border-0 journey-1"
                                                                >
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>HL</td>
                                                        <td>6022</td>
                                                        <td>8</td>
                                                        <td>3.80</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button
                                                                    href="#"
                                                                    className="bg-transparent border-0 journey-1"
                                                                >
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>SME</td>
                                                        <td>5800</td>
                                                        <td>6</td>
                                                        <td>4.33</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button
                                                                    href="#"
                                                                    className="bg-transparent border-0 journey-1"
                                                                >
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="man-tr ">
                                                        <td>VL</td>
                                                        <td>35851</td>
                                                        <td>5</td>
                                                        <td>4.13</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button
                                                                    href="#"
                                                                    className="bg-transparent border-0 journey-1"
                                                                >
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="aboutCsat" style={{ display: "none" }}>
                                        <h6 className="fw-semibold">About CSAT</h6>
                                        <p>
                                            These table shows drill down of satisfaction questions asked for
                                            NPS dependable variables identified in customer journey
                                        </p>
                                    </div>
                                    <div className="innerBox  h-100">
                                        <h3 className="section-title mb-3">Select Category</h3>
                                        <div className="table-responsive tableDiv">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th width="25%" className="">
                                                            Journey
                                                        </th>
                                                        <th className="">Response</th>
                                                        <th className="">NPS</th>
                                                        <th className="">CSAT</th>
                                                        <th className="">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="man-tr active border-bottom">
                                                        <td>Applying personal loan</td>
                                                        <td>58946</td>
                                                        <td>9</td>
                                                        <td>3.69</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button
                                                                    href="#"
                                                                    className="bg-transparent border-0"
                                                                    onclick="myFunction()"
                                                                >
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Disbursal of Loan</td>
                                                        <td>6022</td>
                                                        <td>8</td>
                                                        <td>3.80</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button
                                                                    href="#"
                                                                    className="bg-transparent border-0"
                                                                    onclick="myFunction()"
                                                                >
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Interaction with SE</td>
                                                        <td>5800</td>
                                                        <td>6</td>
                                                        <td>4.33</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button
                                                                    href="#"
                                                                    className="bg-transparent border-0"
                                                                    onclick="myFunction()"
                                                                >
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3" id="journeyCategory">
                                    <div className="innerBox h-100">
                                        <h3 className="section-title mb-3">Journey : HL</h3>
                                        <div className="table-responsive tableDiv">
                                            <table width="100%" className="table third-tbl">
                                                <thead>
                                                    <tr>
                                                        <th width="25%" className="">
                                                            RCA
                                                        </th>
                                                        <th width="25%" className="">
                                                            Response
                                                        </th>
                                                        <th width="25%" className="">
                                                            NPS
                                                        </th>
                                                        <th width="25%" className="">
                                                            CSAT
                                                        </th>
                                                        <th width="25%" className="">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Accuracy of information provided by the executive</td>
                                                        <td>58946</td>
                                                        <td>9</td>
                                                        <td>3.69</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Documents required to process my application</td>
                                                        <td>6022</td>
                                                        <td>8</td>
                                                        <td>3.80</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ease of applying loan</td>
                                                        <td>5800</td>
                                                        <td>6</td>
                                                        <td>4.33</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="man-tr">
                                                        <td>Regular updates on application status</td>
                                                        <td>35851</td>
                                                        <td>5</td>
                                                        <td>4.13</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr className="man-tr">
                                                        <td>Time taken to process loan application</td>
                                                        <td>35851</td>
                                                        <td>5</td>
                                                        <td>4.13</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3" id="journeyCategoryResponse">
                                    <div className="innerBox h-100">
                                        <h3 className="section-title mb-3">Response Data</h3>
                                        <div className="table-responsive tableDiv fourt-man">
                                            <table width="100%" className="table">
                                                <thead>
                                                    <tr>
                                                        <th width="20%" className="">
                                                            Customer ID
                                                        </th>
                                                        <th width="20%" className="">
                                                            NPS
                                                        </th>
                                                        <th width="20%" className="">
                                                            Touchpoint
                                                        </th>
                                                        <th width="20%" className="">
                                                            Collected
                                                        </th>
                                                        <th width="20%" className="">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td className="Dat">Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td>Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td>Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td>Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td>Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td>Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td>Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td>Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td>Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>26938373</td>
                                                        <td>10/10</td>
                                                        <td>Dummy Text</td>
                                                        <td>Wed 11 May 2022 at 16:27 PM</td>
                                                        <td>
                                                            <div className="act_eye">
                                                                <button href="#" className="bg-transparent border-0">
                                                                    <i className="bi bi-eye-fill" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cardBox mb-3 d-none">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                                <div className="d-flex align-items-center gap-3">
                                    <h3 className="section-title">VOC (Free Text)</h3>
                                    <div className="catSelect">
                                        <label>Select Category</label>
                                        <select className="form-select">
                                            <option>ABC Category</option>
                                            <option>ABC Category</option>
                                            <option>ABC Category</option>
                                            <option>ABC Category</option>
                                        </select>
                                    </div>
                                </div>
                                <button type="button" className="btn btn-primary">
                                    Export Report
                                </button>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h4 className="section-title text-orange mb-3">
                                    Product Category sentiment
                                </h4>
                                <span className="npsNumber">
                                    <img src="images/nps.svg" width={15} alt="" />
                                    n= 5026
                                </span>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    {/* Nav tabs */}
                                    <ul className="nav nav-tabs vocTab">
                                        <li className="nav-item">
                                            <button
                                                className="nav-link active"
                                                data-bs-toggle="tab"
                                                data-bs-target="#pdcategory1"
                                                type="button"
                                            >
                                                <div className="pdtext">
                                                    <h3>Product Category 1</h3>
                                                    <div className="row p-2">
                                                        <div className="col">
                                                            <strong>Contribution</strong>
                                                            <span>40%</span>
                                                        </div>
                                                        <div className="col">
                                                            <strong>NPS Score</strong>
                                                            <span>48.6</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                data-bs-target="#pdcategory2"
                                                type="button"
                                            >
                                                <div className="pdtext">
                                                    <h3>Product Category 2</h3>
                                                    <div className="row p-2">
                                                        <div className="col">
                                                            <strong>Contribution</strong>
                                                            <span>50%</span>
                                                        </div>
                                                        <div className="col">
                                                            <strong>NPS Score</strong>
                                                            <span>55.8</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                data-bs-target="#pdcategory3"
                                                type="button"
                                            >
                                                <div className="pdtext">
                                                    <h3>Product Category 3</h3>
                                                    <div className="row p-2">
                                                        <div className="col">
                                                            <strong>Contribution</strong>
                                                            <span>40%</span>
                                                        </div>
                                                        <div className="col">
                                                            <strong>NPS Score</strong>
                                                            <span>48.6</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                data-bs-target="#pdcategory4"
                                                type="button"
                                            >
                                                <div className="pdtext">
                                                    <h3>Product Category 4</h3>
                                                    <div className="row p-2">
                                                        <div className="col">
                                                            <strong>Contribution</strong>
                                                            <span>50%</span>
                                                        </div>
                                                        <div className="col">
                                                            <strong>NPS Score</strong>
                                                            <span>55.8</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                data-bs-target="#pdcategory5"
                                                type="button"
                                            >
                                                <div className="pdtext">
                                                    <h3>Product Category 5</h3>
                                                    <div className="row p-2">
                                                        <div className="col">
                                                            <strong>Contribution</strong>
                                                            <span>40%</span>
                                                        </div>
                                                        <div className="col">
                                                            <strong>NPS Score</strong>
                                                            <span>48.6</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                data-bs-target="#pdcategory6"
                                                type="button"
                                            >
                                                <div className="pdtext">
                                                    <h3>Product Category 6</h3>
                                                    <div className="row p-2">
                                                        <div className="col">
                                                            <strong>Contribution</strong>
                                                            <span>50%</span>
                                                        </div>
                                                        <div className="col">
                                                            <strong>NPS Score</strong>
                                                            <span>55.8</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                data-bs-target="#pdcategory7"
                                                type="button"
                                            >
                                                <div className="pdtext">
                                                    <h3>Product Category 7</h3>
                                                    <div className="row p-2">
                                                        <div className="col">
                                                            <strong>Contribution</strong>
                                                            <span>40%</span>
                                                        </div>
                                                        <div className="col">
                                                            <strong>NPS Score</strong>
                                                            <span>48.6</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className="nav-link"
                                                data-bs-toggle="tab"
                                                data-bs-target="#pdcategory8"
                                                type="button"
                                            >
                                                <div className="pdtext">
                                                    <h3>Product Category 8</h3>
                                                    <div className="row p-2">
                                                        <div className="col">
                                                            <strong>Contribution</strong>
                                                            <span>50%</span>
                                                        </div>
                                                        <div className="col">
                                                            <strong>NPS Score</strong>
                                                            <span>55.8</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-8">
                                    {/* Tab panes */}
                                    <div className="tab-content">
                                        <div className="tab-pane active" id="pdcategory1">
                                            <div id="pdGraph1" style={{ height: 400 }} />
                                        </div>
                                        <div className="tab-pane" id="pdcategory2">
                                            <div id="pdGraph2" style={{ height: 400 }} />
                                        </div>
                                        <div className="tab-pane" id="pdcategory3">
                                            <div id="pdGraph3" style={{ height: 400 }} />
                                        </div>
                                        <div className="tab-pane" id="pdcategory4">
                                            <div id="pdGraph4" style={{ height: 400 }} />
                                        </div>
                                        <div className="tab-pane" id="pdcategory5">
                                            <div id="pdGraph5" style={{ height: 400 }} />
                                        </div>
                                        <div className="tab-pane" id="pdcategory6">
                                            <div id="pdGraph6" style={{ height: 400 }} />
                                        </div>
                                        <div className="tab-pane" id="pdcategory7">
                                            <div id="pdGraph7" style={{ height: 400 }} />
                                        </div>
                                        <div className="tab-pane" id="pdcategory8">
                                            <div id="pdGraph8" style={{ height: 400 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cardBox mb-3">
                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h3 className="section-title">
                                    Region Wise / Demographic Wise Charts
                                </h3>
                                <div className="d-flex justify-content-end align-items-center gap-3">
                                    <span className="npsNumber">
                                        <img src="images/nps.svg" width={15} alt="" />
                                        n= 5026
                                    </span>
                                    <button type="button" className="btn btn-primary">
                                        Export Report
                                    </button>
                                </div>
                            </div>
                            <div id="demographicWise" />
                        </div>
                        <div className="cardBox mb-3">
                            <h3 className="section-title mb-3">NPS by Branch </h3>
                            <div id="NPSbyBranch" />
                        </div>
                        <div className="cardBox mb-3">
                            <div className="d-flex align-items-center justify-content-between mb-4 border-bottom pb-4">
                                <div>
                                    <h3 className="section-title mb-3">Survey Question Wise Summary</h3>
                                    <div className="catSelect">
                                        <label>Select Survey</label>
                                        <select className="form-select">
                                            <option>XYZ Survey</option>
                                            <option>XYZ Survey</option>
                                            <option>XYZ Survey</option>
                                            <option>XYZ Survey</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="btn btn-primary" onClick={() => setScreen(5)}>
                                    View All Question
                                </div>
                            </div>
                            <div className="row progressMain">
                                <div className="col-md-3">
                                    <h4 className="fs-7 fw-bold">Question</h4>
                                    <p className="fs-8 fw-medium">
                                        Based on the availed two-wheeler loan, how likely are you to
                                        recommend XYZ Finance to your friends and family? (10 Being the
                                        highest and 0 being the lowest)
                                    </p>
                                    <span className="npsNumber">
                                        <img src="images/nps.svg" width={15} alt="" />
                                        n= 5026
                                    </span>
                                </div>
                                <div className="col-md-6 px-md-5">
                                    <h4 className="fs-7 fw-bold">NPS</h4>
                                    <div className="progress-stacked overflow-visible">
                                        <div
                                            className="progress progressredhvr"
                                            role="progressbar"
                                            aria-label="Segment one"
                                            aria-valuenow={15}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                            style={{ width: "75%" }}
                                        >
                                            <div className="hoverInfo progresshvr">
                                                <strong>Promoter</strong>
                                                <span className="text-success">75%</span>
                                            </div>
                                            <div className="progress-bar bg-success text-start ps-2">
                                                75%
                                            </div>
                                        </div>
                                        <div
                                            className="progress progresshvr"
                                            role="progressbar"
                                            aria-label="Segment two"
                                            aria-valuenow={30}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                            style={{ width: "10%" }}
                                        >
                                            <div className="hoverInfo progresshvr">
                                                <strong>Passive</strong>
                                                <span className="text-warning">10%</span>
                                            </div>
                                            <div className="progress-bar bg-warning">10%</div>
                                        </div>
                                        <div
                                            className="progress"
                                            role="progressbar"
                                            aria-label="Segment three"
                                            aria-valuenow={20}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                            style={{ width: "15%" }}
                                        >
                                            <div className="hoverInfo progresshvr">
                                                <strong>Detractor</strong>
                                                <span className="text-danger">15%</span>
                                            </div>
                                            <div className="progress-bar bg-danger">15%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 ps-md-4">
                                    <div className="netPromotorScore">
                                        <h4 className="fs-7 fw-bold">NET PROMOTOR SCORE</h4>
                                        <strong className="d-block fs-3">60.25</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cardBox mb-3">
                            <div className="bg-gradient mb-3">
                                <img
                                    src="images/cloud-bg.png"
                                    alt=""
                                    className="w-100 h-100 p-md-3 p-2"
                                />
                            </div>
                            <p className=" d-flex gap-1 preg ">
                                {" "}
                                <i className="bi bi-info-circle-fill fs-6" /> Lorem Ipsum is simply
                                dummy text of the printing and typesetting industry. Lorem Ipsum has
                                been the industry's standard dummy text ever since the 1500s, when an
                                unknown printer took a galley of type and scrambled it to
                            </p>
                            <div className="tabs-al-cloud d-flex justify-content-between align-items-center">
                                <button type="button" className="btn btn-primary">
                                    Export Report
                                </button>
                                <ul
                                    className="nav nav-pills clouds justify-content-end"
                                    role="tablist"
                                >
                                    <li className="nav-item">
                                        <button
                                            className="nav-link active"
                                            data-bs-toggle="pill"
                                            data-bs-target="#tabAll"
                                            type="button"
                                            aria-selected="true"
                                            role="tab"
                                        >
                                            All
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className="nav-link"
                                            data-bs-toggle="pill"
                                            data-bs-target="#tabPromotor"
                                            type="button"
                                            aria-selected="false"
                                            tabIndex={-1}
                                            role="tab"
                                        >
                                            Promoter
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className="nav-link"
                                            data-bs-toggle="pill"
                                            data-bs-target="#tabPassive"
                                            type="button"
                                            aria-selected="false"
                                            tabIndex={-1}
                                            role="tab"
                                        >
                                            Passive
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className="nav-link"
                                            data-bs-toggle="pill"
                                            data-bs-target="#TabDetractor"
                                            type="button"
                                            aria-selected="false"
                                            tabIndex={-1}
                                            role="tab"
                                        >
                                            Detractor
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade active show"
                                    id="tabAll"
                                    role="tabpanel"
                                >
                                    <figure className="p-3">
                                        <img src="../images/graph.svg" />
                                    </figure>
                                </div>
                                <div className="tab-pane fade" id="tabPromotor" role="tabpanel">
                                    <figure className="p-3">
                                        <img src="../images/graph.svg" />
                                    </figure>
                                </div>
                                <div className="tab-pane fade" id="tabPassive" role="tabpanel">
                                    <figure className="p-3">
                                        <img src="../images/graph.svg" />
                                    </figure>
                                </div>
                                <div className="tab-pane fade" id="TabDetractor" role="tabpanel">
                                    <figure className="p-3">
                                        <img src="../images/graph.svg" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="offcanvas offcanvas-end filterOffcanvas"
                    tabIndex={-1}
                    id="filterOffcanvas"
                >
                    <div className="offcanvas-header border-bottom">
                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                            Filter
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        />
                    </div>
                    <div className="offcanvas-body pe-0">
                        <div className="filterForm pe-3">
                            <div className="form-group mb-3">
                                <label>Channel</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>SMS</option>
                                    <option>WhatsApp</option>
                                    <option>Email</option>
                                    <option>QR</option>
                                    <option>IVR</option>
                                    <option>CATI</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Zone </label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>East</option>
                                    <option>West</option>
                                    <option>North</option>
                                    <option>South</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Service Centre</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>Q</option>
                                    <option>W</option>
                                    <option>D</option>
                                    <option>F</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Branch</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>A</option>
                                    <option>B</option>
                                    <option>C</option>
                                    <option>D</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>State</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>State </option>
                                    <option>State </option>
                                    <option>State </option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Segment</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>P</option>
                                    <option>PAS</option>
                                    <option>DET</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Feedback Status</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>Response Received</option>
                                    <option>Response Not Received</option>
                                </select>
                            </div>
                        </div>
                        <div className="filterButton">
                            <button type="button" className="btn btn-primary">
                                Apply
                            </button>
                            <button type="button" className="btn btn-light">
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            </main>}
            {screen == 5 && <NpsQuestions screen={screen} surveyData={props}/>}
        </>

    )
}

export default NpsReport