import React from "react";

const PointDistribution=()=>{

  return  (
    <hi>hii</hi>
    
    
    
    )


}
export default PointDistribution