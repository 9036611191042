import React, { useState, useEffect } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
// import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CoustomStorage';

// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link } from "react-router-dom";
import Moment from 'react-moment';
import Loader2 from '../../utils/loader2/loader2'
const LogicSurveyModal = (props) => {
    const api = new API()
    const { surveyLogic } = props

    const [loader2, setLoader2] = useState(true)
    const [test, setTest] = useState(true)
    const [surveyLogicData, setSurveyLogicData] = useState([])
    const [responseLimit, setResponseLimit] = useState()
    const [textBox, setTextBox] = useState()

    useEffect(() => {
        setSurveyLogicData(surveyLogic)


    })

    const clickedOnlogicOption = async (e, surveylogic, selectedOption, whatfor) => {
        let data = {
            surveyId: surveyLogic._id,
            selectedQuestionId: surveylogic._id,
            seletedQuestionOption: selectedOption,
            target: e.target.value,
            whatfor: whatfor
        }
       

        // alert(e.target.value)


        let result = await api.post(config.adminDashboardLogicTargetSet, data)
        if (result && result.code === 200) {
            alert("success");
        }
    }

    return (
        <div>

            <div className="modal fade" id="logicSurveyModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ minHeight: "450px" }}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Survey Logic </h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                {test == false ?
                                    <div>
                                        <Loader2 />
                                    </div>
                                    :
                                    <>
                                        <div className="container">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4 style={{ color: "blue" }}><span>Survey Name :</span> <span style={{ color: "blue" }}>{surveyLogic.survey_name}</span></h4>
                                                </div>
                                                <div className="card-body" >
                                                    <h5 style={{ color: "blue" }}><span>Survey Points : </span>{surveyLogic.points}</h5>
                                                    <h5 style={{ color: "blue" }}><span>Survey Status : </span>{surveyLogic.status}</h5>
                                                    <h5 style={{ color: "blue" }}><span>Survey Id : </span>{surveyLogic._id}</h5>
                                                    <h5 style={{ color: "blue" }}><span>Survey Created Date : </span>{surveyLogic.date}</h5>
                                                </div>
                                                <div className="container">



                                                    {surveyLogicData && surveyLogicData.questions && surveyLogicData.questions.length > 0 && surveyLogicData.questions.map((surveylogic, index) => (

                                                        <div className="card">
                                                            <div className="card-header">
                                                                <h4>question {index + 1}: <span style={{ color: "red" }}>{surveylogic.question}</span></h4>
                                                            </div>
                                                            <table class="table ml-5">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Option</th>
                                                                        <th scope="col">Question</th>
                                                                        <th scope="col">Target question</th>
                                                                        <th scope="col">Text box</th>
                                                                        <th scope="col">Response limit</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                </tbody>
                                                            </table>
                                                            <div className="card-body">
                                                                <div className="container">
                                                                    {(surveylogic.options).map((op, indexOp) => (
                                                                        <div className="container">
                                                                            <div className="row">
                                                                                <div className="col-md-5">
                                                                                    <table class="table">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <th>option {indexOp + 1}</th>
                                                                                                <th>{op.option}</th>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                                <div className="col-md-7">
                                                                                    <div className="row">
                                                                                        <div className="col-md-4">

                                                                                            <select className="form-control col-md-8" onChange={(e) => clickedOnlogicOption(e, surveylogic, op.option, "targetId")}>

                                                                                                <option value="">{op.action_id}</option>
                                                                                                <option value="">1. None</option>
                                                                                                <option value="exit">2. exit</option>
                                                                                                <option value="final">3. final</option>
                                                                                                {surveyLogicData.questions.map((logicquestion, index) => (
                                                                                                    <option value={logicquestion._id}>{index + 4}. {logicquestion.question}</option>
                                                                                                ))
                                                                                                }
                                                                                            </select>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <div class="form-group">
                                                                                                {/* <label for="textBox">Text box</label> */}
                                                                                                <select class="form-control" onChange={(e) => clickedOnlogicOption(e, surveylogic, op.option, "text_box")} name="textBox" id="textBox">
                                                                                                    <option selected >{op.text_box}</option>
                                                                                                    <option value="yes">yes</option>
                                                                                                    <option value="no">no</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-md-4">
                                                                                            <div class="form-group">
                                                                                                {/* <label for="textBox">Response Limit</label> */}

                                                                                                <input type="text" value={op.response_limit} onMouseLeave={(e) => clickedOnlogicOption(e, surveylogic, op.option, "response_limit")} placeholder="enter respons limit" />

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>


                                        </div>
                                    </>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
}

export default LogicSurveyModal;