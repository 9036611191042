import React, { useState, useEffect } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import RewardUseModal from '../common/rewardUserModal'
// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link, useHistory } from "react-router-dom";
import Loader2 from '../../utils/loader2/loader2'
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ViewProfileSingleSurveyRecord from './viewProfileSingleSurveyRecord';
import './profileSurveyRecord.css'

const ProfileSurveyRecord = (props) => {
    const api = new API()
    const history = new useHistory()

    const [profileSurveyRecord, setProfileSurveyRecord] = useState([])
    const [loader2, setLoader2] = useState(true)
    const [isProfileSurveyDetails, setIsProfileSurveyDetails] = useState(false)
    const [singleUserProfileSurveyRecord, setSingleUserProfileSurveyRecord] = useState([])
    const [pageCount, setPageCount] = useState(1);
    const [pagination, setPagination] = useState();
    const [pageSize, setPageSize] = useState(9)
    const [index1, setIndex1] = useState(0)
    const [lastSelected, setLastSelected] = useState(0)

    let adminToken = getLocalDataAsObject("adminToken")
    if (!adminToken) {
        history.push("/dashboard")
    }

    useEffect(() => {
        getProfileSurveyRecord()
    }, [])

    const getProfileSurveyRecord = async (count) => {
        console.log(count, "count")
        let data = {
            pageSize: 10,
            pageNumber: count
        }

        const result = await api.post(config.adminDashboardGetUserProfileSurvey, data)
        if (result.code === 200) {
            setLoader2(false)

            setProfileSurveyRecord(result.data)
            setPagination(result.resultCount)

        }
    }

    const surveyDetails = (profile_Record) => {
        setSingleUserProfileSurveyRecord(profile_Record)
        setIsProfileSurveyDetails(true)
    }
    const goBack = () => {
        setIsProfileSurveyDetails(false)
    }

    const sendPageCount = (value) => {
        if (value === 'back') {

            setPageCount(pageCount-1)
            // updateIndex(value, pageCount-1);
            getProfileSurveyRecord(pageCount-1);

        } else if (value === "next") {

            setPageCount(pageCount + 1)
            // updateIndex(value, pageCount + 1);
            getProfileSurveyRecord(pageCount+1);
        } 
    }

    const updateIndex = (value, pageCount, lastSelected) => {
console.log(pageCount,"pageCount")
        if (pageCount > 0) {
            if (value === '-1') {
                setIndex1(pageSize * (pageCount - 1))
                getProfileSurveyRecord(pageCount - 1);
            } else if (value === '1') {
                setIndex1(pageSize * (pageCount))
                getProfileSurveyRecord(lastSelected);
            }
            else {
                setIndex1(pageSize * (pageCount))
                getProfileSurveyRecord(pageCount);
            }
        } else {
            setPageCount(pageCount = 0)
        }
    }

    return (
        <div>
            {/* <Header {...props} /> */}
            {/* <Tabs {...props}/> */}
            {/* <Footer {...props} /> */}

            <div className="container mt-5">
                <h1 align="center">Profile Survey Record Panel dashboard </h1>
                <div className="container mt-4">
                    {loader2 == true ?
                        <div className="container" style={{ marginTop: "10%" }}>
                            <Loader2 />
                        </div>
                        : <>
                            {isProfileSurveyDetails == false
                                ?
                                <>
                                    <div className="row">
                                        <div className="col-md-8">
                                            {profileSurveyRecord.map((profileRecord, index) => (


                                                <div className="card mb-5" style={{ backgroundColor: "gray " }}>
                                                    <div className="card-header">
                                                        <h6 className="text-white">{(pageCount*10) + index + 1} User Email : {profileRecord.user} </h6>
                                                        <div className="card-body" style={{ borderTop: "1px solid" }}>
                                                            <div className="row">
                                                                <div className="col-md-8">
                                                                    <h5 className="text-white">Profile Completed Percentage : <span style={profileRecord.data[0] && profileRecord.data[0].profilePercent == 100 ? { color: "blue" } : { color: "red" }}>{profileRecord.data[0] && profileRecord.data[0].profilePercent} %</span><span style={{ color: "red" }}></span></h5>

                                                                </div>
                                                                <div className="col-md-4 item-center">
                                                                    <div>
                                                                        <button onClick={() => surveyDetails(profileRecord)} className="btn btn-secondary">View Details</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>


                                        <div className="col-md-4" >
                                            <div className="fixedRightSection">
                                                <ul class="list-group">
                                                    <li class="list-group-item">Click on mail to details</li>
                                                    <div style={{ overflow: "auto", height: "450px" }}>
                                                        {profileSurveyRecord.map((pro_SurveyRecord, index) => (
                                                            <li onClick={() => surveyDetails(pro_SurveyRecord)} style={{ cursor: "pointer" }} class="list-group-item list-group-item-secondary">{(pageCount*10) + index + 1}: {pro_SurveyRecord.user}</li>
                                                        ))}
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <ViewProfileSingleSurveyRecord goBack={goBack} singleUserProfileSurveyRecord={singleUserProfileSurveyRecord} />
                            }
                        </>}

                </div>
            </div>
            <nav aria-label="Page navigation example ">
                        <ul className=" pagination d-flex justify-content-center m-3">
                            <li class="page-item m-1">
                                {pageCount > 0 && <button class="btn btn-outline-primary" onClick={() => sendPageCount("back")} tabindex="-1"><i class="fa fa-chevron-left"></i></button>}
                            </li>
                            <li class="page-item btn border-0"><button class="page-link border-0" >{pageCount + 1}</button></li>
         
                            <li class="page-item m-1">
                                <button class="btn btn-outline-primary" onClick={() => sendPageCount("next")} ><i class="fa fa-chevron-right"></i></button>
                            </li>
                            <h4>Total Data-{pagination}</h4>
                        </ul>
                    </nav>
        </div>

    );
}

export default ProfileSurveyRecord;