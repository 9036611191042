import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { CSVLink } from "react-csv";
import axios from "axios";
import Config from '../../../config/env'
import './onlyraw.css'

export const DynamicOnlyExcel = () => {
    let param = useParams()
    const [excelData, setExceldata] = useState([])
    const [columns, setColumns] = useState([])






    async function getSurvey() {
        let data = {
            survey_id: param.id,
            email: ""
        }
        axios.post(`${Config.new.site_api}/survey/getDynSurveyAttemp`, data).then(result => {
            if (result.data.code == 200) {
                if (result.data.data.length) {
                    let questions = result.data.data[0].questions
                    getData(questions);
                }
            }
        })
    }

    const getData = async (questions) => {
        let data = {
            surveyId: param.id
        }
        const surveyData = await axios.post(`${Config.new.site_api}/client-survey/getNpsReport`, data)
        if (surveyData.data.code === 200) {
            console.log(surveyData.data)
            manageLangWIse(surveyData.data.data, questions);

        }
    }

    function manageLangWIse(responses, questions) {
        let ex = []

        responses.forEach((ele) => {

            let tempex = {}
            tempex.Date = ele.response_date ? ele.response_date : "N/A"
            //    tempex.Language=ele.answeredLanguage?lang.filter(e=>{return e.lang_code==ele.answeredLanguage})[0]?.lang_name:"N/A"


            let keyss = Object.keys(ele.dynamicFields)
            keyss.forEach((k) => {
                tempex[k] = ele.dynamicFields[k];
            })
            questions.forEach((q)=>{
            let i=ele.questions.findIndex((e)=>e.question==q.question)
            if(i>=0)
            {tempex[q.question]=ele.questions[i].answer}
            else{
                tempex[q.question]=""
            }

            })

            ex.push({ ...tempex });

        })
        console.log(ex)
        let headers = Object.keys(ex[0] || {});
        questions.forEach((que) => {
            if (!headers.includes(que.question)) {
                headers.push(que.question)
            }
        })

        setExceldata(ex)
        


        const col = headers.map(key => ({
            Header: key,
            accessor: key,
        }));
        setColumns(col)


    }
    useEffect(() => {
        getSurvey()
    }, [])

    return (
        <div className="container-fluid">
            <CSVLink
                data={excelData}
                filename={`selected_survey_name_Report.csv`}
            >
                <button className="btn btn-secondary mx-1">Download Responses</button>
            </CSVLink>
            <div className="button-container">

            </div>
            <table className="table table-responsive mt-3">
                <thead>
                    <tr>
                        {columns.map(column => (
                            <th key={column.accessor}>{column.Header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {excelData.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map(column => (
                                <td key={column.accessor}>{row[column.accessor]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};


