import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import GetInTouch from "../ReusableComponents/GetInTouch";


const ClientInTouchModel =  (props) => {

  return (
    <>
      <Modal
        {...props}
        className="modalContact"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header  closeButton className="p-0">
        {/* <Modal.Title id="contained-modal-title-vcenter">
          Modal heading
        </Modal.Title> */}
      </Modal.Header>
        <Modal.Body>

          <GetInTouch redirectionStatus={true} />
        
        </Modal.Body>
        {/* <Modal.Footer>
          <Button className="mainbtn tada" onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>


    </>
  )

}
export default ClientInTouchModel