import '../../App.css';
import API from '../../utils/apiCalling';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Alert } from 'bootstrap';
import { Button, Checkbox, Form, Input } from 'antd';
import { UserOutlined, BankOutlined, MailOutlined, MobileOutlined, InsertRowRightOutlined } from '@ant-design/icons';
import Config from '../../config/env'
function GetInTouch(props) {
     let api = new API()
     const [name, setName] = useState()
     const [email, setEmail] = useState()
     const [message, setMessage] = useState()
     const [company, setCompany] = useState()
     const [mobile, setMobile] = useState()
     const [industry, setInduatry] = useState()
     const history = new useHistory();
     const handlegettouch = async (e) => {
          let data = {
               name: name,
               email: email,
               company_name: company,
               mobile: mobile,
               message: message,
               industry: industry
          }
          let result = await api.post(`${Config.new.site_api}/common/addGetInTouch`, data)
          if (result.code === 200) {
               setName("")
               setEmail("")
               setMessage("")
               setCompany("")
               setCompany("")
               setMobile("")
               setInduatry("")
               if (props.redirectionStatus === true) {
                    window.scroll(0, 0)
                    history.push('/estimatePage');
                    localStorage.setItem("clientEmail", email);
               }
               toast.success(result.message, {
                    position: toast.POSITION.TOP_RIGHT
               });
          }
     } 
      const onFinish = (values) => {
          console.log('Received values of form: ', values);
     }
     return (
          <section id="all-section" className="bg-lblue py-5">
               <div className="container">
                    <div className="getintouch ">
                         <h2 className="fw-bold fs-2 text-center pb-4">
                              Get In <strong className="fs-1 text-orange">Touch</strong>
                         </h2>
                         <div className="row py-3">
                              <div className="col-md-6">
                                   <div className="touch-content h-100 d-flex flex-column justify-content-center px-3">
                                        <h1 className="text-orange">You’re one click away from valuable consumer insights</h1>
                                        <br />
                                        <h4 className>Submitting this form is going to be the first of many great decisions that
                                             will boost your growth.</h4>
                                        <br />
                                        <p className="fw-normal font500">Our team will get back to you to discuss how DoYourSurvey can
                                             help you create a regular flow of consumer insights to support your daily
                                             decision-making, end the guesswork and grow faster.</p>
                                   </div>
                              </div>
                              <div className="col-md-6 px-3">
                                   <div className="contact-form bg-white">
                                        <Form
                                             name="normal_login"
                                             className="form"
                                             initialValues={{ remember: true }}
                                             onFinish={onFinish}
                                        >
                                             <div className="row">
                                                  {console.log(name)}
                                                  <Form.Item
                                                       name="username"
                                                       rules={[{ required: true, message: 'Please input your Username!' }]}>
                                                       <Input className="site-form-item-icon"
                                                            type="text" placeholder='Name *' 
                                                             onChange={(e)=>setName(e.target.value)}
                                                            value={name}
                                                       />
                                                  </Form.Item >
                                                  <Form.Item
                                                       name="useremail"
                                                       rules={[
                                                            {
                                                              type: 'email',
                                                              message: 'The input is not valid E-mail!',
                                                            },
                                                            {
                                                              required: true,
                                                              message: 'Please input your E-mail!',
                                                            },
                                                          ]} >
                                                       <Input className="site-form-item-icon" type="email" placeholder='Email Id *'
                                                            onChange={(e)=>setEmail(e.target.value)}
                                                            value={email}  />
                                                  </Form.Item>
                                                  <Form.Item
                                                       name="usermobile"
                                                       rules={[{ required: true, message: 'Please input User mobile no.!', }]}>
                                                       <Input className="site-form-item-icon" type="text" maxLength={10} placeholder='Mobile Number *'
                                                              onChange={(e)=>setMobile(e.target.value)}
                                                           value={mobile}
                                                       />
                                                  </Form.Item>
                                                  <Form.Item
                                                       name="usercompanyname"
                                                       rules={[{ required: true, message: 'Please input your company name!' }]}>
                                                       <Input className="site-form-item-icon" type="text" placeholder='Company Name *'
                                                          onChange={(e)=>setCompany(e.target.value)}
                                                            value={company} />
                                                  </Form.Item>
                                                  <Form.Item name="userindustryname"
                                                       rules={[{ required: true, message: 'Please input your industry name!' }]}>
                                                       <Input className="site-form-item-icon" type="text" placeholder='Industry *'
                                                              onChange={(e)=>setInduatry(e.target.value)}
                                                         value={industry} />
                                                  </Form.Item>
                                                  <Form.Item
                                                       name="usermessage"
                                                       rules={[{ required: true, message: 'Please input your User message!' }]}>
                                                       <Input className="site-form-item-icon" type="text" placeholder='Message *'
                                                            onChange={(e)=>setMessage(e.target.value)}
                                                            value={message} />
                                                  </Form.Item>
                                                  <div className="button-section position-relative pt-5">
                                                       <Form.Item >
                                                            <Button  htmlType="submit"  className="mainbtn text-white" onClick={handlegettouch} style={{lineHeight:"0",padding: "19px 0"}}>
                                                                 Submit
                                                            </Button>
                                                       </Form.Item>
                                                       <img src="../images/Group 2199.svg" alt="" />
                                                  </div>
                                             </div>
                                        </Form>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
               <ToastContainer />
          </section>
     )
}
export default GetInTouch