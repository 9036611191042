import '../../App.css';

import React from 'react'

function QualityHomeCart(props) {
     const { img, Heading, description } = props;
     return (
          <div className="col-xl-4 col-md-6">
               <div className="card">
                    <div className="card-body text-start">
                         <img src={img} width="100px" alt="" />
                         <h5 className="text-orange fw-bold">
                              {Heading}
                         </h5>
                         <p dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
               </div>
          </div>
     )
}

export default QualityHomeCart