import React from "react";
import Header from "../Components/ReusableComponents/Header";
import { useHistory } from "react-router-dom";
import API from "../utils/apiCalling";
import { useState } from "react";
import { Button, Form, Input } from 'antd';
import Config from '../config/env'

const Payment = (props) => {
  const api = new API();
  let getUser = JSON.parse(localStorage.getItem("user"));
  const history = useHistory();
  const Package = props.Package;
  const amount = props.amount;
  const monthly = props.monthly;
  // const [amountData, setAmountData] = useState();
  const [Billing, setBilling] = useState(false);
  console.log("Billing", Billing)
  const [TermsandCondition, setTermsandCondition] = useState(false);
  // const [message, setMessage] = useState("");
  // const [message1, setMessage1] = useState("");
  // const [message2, setMessage2] = useState("");
  // const [message3, setMessage3] = useState("");
  // const [message4, setMessage4] = useState("");
  // const [message5, setMessage5] = useState("");
  // const [Invoice, setInvoice] = useState();


  const addPlan = async (newData) => {
    let plan_types;
    if (Package.Name === "7 Days Free Trial") {
      plan_types = 7;
    } else if (
      (Package.Name === "Basic Plan" &&
        Package.Name === "Premium Plan" &&
        Package.Name === "Enterprise Plan") ||
      monthly === "Month"
    ) {
      plan_types = 30;
    } else if (
      (Package.Name === "Basic Plan" &&
        Package.Name === "Premium Plan" &&
        Package.Name === "Enterprise Plan") ||
      monthly === "Year"
    ) {
      plan_types = 365;
    }
    let data = {
      plan_id: Package._id,
      Id: getUser._id,
      plan_days: plan_types,
    };
    let result = await api.post(
      `${Config.new.site_api}/client-survey/addPlan`,
      data
    );
    if (result.code === 200) {
      saveData(newData);
      localStorage.setItem("user", JSON.stringify(result.data));
    }
  };
  const handleopenrazorpay = (data) => {
    const options = {
      key: "rzp_live_dlxnsBcRcuarar",
      amount: Number(data.amount),
      currency: data.currency,
      order_id: data.id,
      image: "../images/new-logo.png",
      name: "DoYourSurvey",
      description: "",

      handler: async (response) => {
        console.log("response", response);
        let result = await api.post(
          `${Config.new.site_api}/client-survey/verify`,
          response
        );
        if (result.code === 200) {
          addPlan(data);
        }
      },
      notes: {
        address: "Qdegrees",
      },
      theme: {
        color: "#f47321",
      },
    };
    console.log("hello razorpay");
    const razor = new window.Razorpay(options);
    razor.open();
  };

  const handlepayment = async () => {

    let gst = (amount * 18) / 100
    if (Package.Monthly === "Free") {
      addPlan(amount);
    } else {
      let data = {
        amount: Number(amount) + Number(gst),
      };
      let result = await api.post(
        `${Config.new.site_api}/client-survey/order`,
        data
      );
      if (result.code === 200) {
        // setAmountData(result.data);
        handleopenrazorpay(result.data);
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFrom({ ...from, [name]: value });
  };

  const [from, setFrom] = useState({
    userId: getUser._id,
    b_address: "",
    b_city: "",
    b_pin_code: "",
    b_state: "",
    c_address: "",
    c_city: "",
    c_pin_code: "",
    c_state: "",
    email: getUser.email,
    gstin_number: "",
    register_mobile: "",
    // amount:"" ,
    // currency:"",
    // order_id:"" ,
  });
  const saveData = async (data) => {
    from.amount = data.amount ? data.amount : "free";
    from.currency = data.currency ? data.currency : "free";
    from.order_id = data.id ? data.id : "free";
    let result = await api.post(
      `${Config.new.site_api}/client-survey/Invoice`,
      from
    );
    if (result.code === 200) {
      history.push(`/invoice/${result.data._id}`);
    }
  };
  const onFinishFail = (props) => {
    console.log("faild:", props)
  }
  return (
    <>
      <Header />
      <section className="payment-page">
        <div className="container">
          {/* Button trigger modal */}
          <div
            className="modal fade"
            id="exampleModalassasa"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "none" }}
          >
            <div className="modal-dialog" style={{ maxWidth: "750px" }}>
              <div className="modal-content">
                <div className="modal-header border-0">
                  {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div
                    style={{
                      minHeight: "600px",
                      margin: "0 auto",
                      backgroundColor: "white",
                    }}
                    className="email-container"
                  >
                    {/* BEGIN BODY */}
                    <table
                      align="center"
                      role="presentation"
                      cellSpacing={0}
                      cellPadding={0}
                      border={0}
                      width="100%"
                      style={{ margin: "auto" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            valign="top"
                            className="bg_white"
                            style={{ padding: "1em 2.5em 0 2.5em" }}
                          >
                            <table
                              role="presentation"
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="logo"
                                    style={{ textAlign: "center" }}
                                  >
                                    <img
                                      src="../images/logo-QDegrees.svg"
                                      alt="logo"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <hr className="line_rating" />
                          </td>
                        </tr>
                        {/* end tr */}
                      </tbody>
                    </table>
                    <table
                      style={{
                        fontSize: "13px",
                        color: "#000",
                        fontFamily: '"Work Sans", sans-serif',
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            <div
                              className="text"
                              style={{ padding: "10px", textAlign: "left" }}
                            >
                              <h4
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                  textAlign: "center",
                                }}
                              >
                                <u>Cancellation and Refund Policy</u>
                              </h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Introduction:
                              </p>
                              <p>
                                The terms hereof shall constitute QDegrees
                                Services Private Limited’s (“Company”)
                                cancellation and refund policy in relation to
                                the Services rendered on the Website
                                (“Cancellation and Refund Policy”). Capitalized
                                terms not defined herein shall have the meaning
                                ascribed to them in the Terms of Use.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Cancellation and Refunds by the Company:
                              </p>
                              <p>
                                Please note that there may be certain orders
                                that we are unable to accept and must cancel. We
                                reserve the right, at our sole discretion, to
                                refuse or cancel any order for any reason,
                                without any claims or liability to pay finance
                                charges or interest on the amount. Some
                                situations that may result in your order being
                                cancelled include but are not limited to
                                inaccuracies or errors in Solutions or pricing
                                information, technical or technological problems
                                or problems identified in relation to credit /
                                debit fraud. We may also require additional
                                verifications or information before accepting
                                any order. We will contact you if all or any
                                portion of your order is cancelled or if
                                additional information is required to accept
                                your order. If your order is cancelled by the
                                Company after your credit / debit card has been
                                charged, the said amount will be refunded to
                                that credit / debit card account.
                              </p>

                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Cancellation by you:
                              </p>
                              <p>
                                You agree and acknowledge that unless stated
                                otherwise you are not entitled to cancel any
                                orders made by you on this Website. In the event
                                you subscribe to any services, the same may be
                                cancelled prior to the commencement of the
                                services, in such a case you will be refunded
                                the entire amount after deducting any bank
                                charges that may have been applicable. Further
                                during a period between 1 to 7 days from the
                                commencement of Services or product delivered,
                                if there is a cancellation request received, we
                                may at our discretion refund 50% of the amount,
                                after deducting any bank charges that may have
                                been applicable, to you. After passing of 7 days
                                no request for cancelation or refund will be
                                entertained by the Company.
                              </p>
                              <hr className="line_rating" />
                              <table
                                role="presentation"
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      className="bg_light"
                                      style={{ textAlign: "center" }}
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="text-footer1 pb10"
                                      style={{
                                        color: "#000",
                                        fontFamily: '"Work Sans", sans-serif',
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        textAlign: "center",
                                        paddingBottom: "10px",
                                      }}
                                    >
                                      © Copyright (2023)Powered by&nbsp;
                                      <a
                                        className="copyrightTerms"
                                        href="https://www.qDegrees.com/"
                                      >
                                        QDegrees
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="text-footer2 pb20"
                                      style={{
                                        color: "#000",
                                        fontFamily: '"Work Sans", sans-serif',
                                        fontSize: "12px",
                                        lineHeight: "26px",
                                        textAlign: "center",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      B-9, 1st Floor, Mahalaxmi Nagar, Behind
                                      WTP South Block, Malviya Nagar, Jaipur,
                                      Rajasthan (302017)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="exampleModal1"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            style={{ display: "none" }}
          >
            <div className="modal-dialog" style={{ maxWidth: "750px" }}>
              <div className="modal-content">
                <div className="modal-header border-0">
                  {/* <h5 class="modal-title" id="exampleModalLabel">Modal title</h5> */}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <div
                    style={{
                      minHeight: "600px",
                      margin: "0 auto",
                      backgroundColor: "white",
                    }}
                    className="email-container"
                  >
                    {/* BEGIN BODY */}
                    <table
                      align="center"
                      role="presentation"
                      cellSpacing={0}
                      cellPadding={0}
                      border={0}
                      width="100%"
                      style={{ margin: "auto" }}
                    >
                      <tbody>
                        <tr>
                          <td
                            valign="top"
                            className="bg_white"
                            style={{ padding: "1em 2.5em 0 2.5em" }}
                          >
                            <table
                              role="presentation"
                              border={0}
                              cellPadding={0}
                              cellSpacing={0}
                              width="100%"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    className="logo"
                                    style={{ textAlign: "center" }}
                                  >
                                    <img
                                      src="../images/logo-QDegrees.svg"
                                      alt="logo"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <hr className="line_rating" />
                          </td>
                        </tr>
                        {/* end tr */}
                      </tbody>
                    </table>
                    <table
                      style={{
                        fontSize: "13px",
                        color: "#000",
                        fontFamily: '"Work Sans", sans-serif',
                      }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            <div
                              className="text"
                              style={{ padding: "10px", textAlign: "left" }}
                            >
                              <h4
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                  textAlign: "center",
                                }}
                              >
                                <u>Terms &amp; Conditions</u>
                              </h4>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Introduction:
                              </p>
                              <p>
                                These terms and conditions apply to the User who
                                uses the Online Services provided for any
                                payment made to QDegrees Services Private
                                Limited. Kindly read these terms and conditions
                                carefully. By authorizing a payment to QDegrees
                                Services Private Limited through the online
                                payment service, it would be treated as a deemed
                                acceptance to these terms and conditions.
                                QDegrees Services Private Limited reserves all
                                the rights to amend these terms and conditions
                                at any time without giving prior notice. It is
                                the responsibility of the User to have read the
                                terms and conditions before using the Service.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Key terms: <br />
                                The following is a summary of the key terms of
                                this service:
                              </p>
                              <ul>
                                <li>
                                  Payment(s) through this Service may only be
                                  made with a Credit Card, Debit Card or Net
                                  Banking.
                                </li>
                                <li>
                                  The Credit Card information supplied at the
                                  time of using the service is processed by the
                                  payment gateway of the service provider and is
                                  not supplied to QDegrees Services Private
                                  Limited. It is the sole responsibility of the
                                  User of the service to ensure that the
                                  information entered in the relevant fields are
                                  correct. It is recommended that you take and
                                  retain a copy of the transaction for record
                                  keeping purposes, which might assist in
                                  resolution of any disputes that may arise out
                                  or usage of the service.
                                </li>
                                <li>
                                  The Client/Customer agrees, understands and
                                  confirms that his/ her personal data including
                                  without limitation details relating to
                                  Debit/Credit Card/Net Banking transmitted over
                                  the Internet may be susceptible to misuse,
                                  hacking, theft and/or fraud and that QDegrees
                                  Services Private Limited or the Payment
                                  Service Provider(s) have no control over such
                                  matters.
                                </li>
                                <li>
                                  The service is provided using a payment
                                  gateway service provider through a secure
                                  website. However, neither the payment gateway
                                  service provider nor the QDegrees Services
                                  Private Limited gives any assurance, that the
                                  information so provided online by a user is
                                  secured or may be read or intercepted by a
                                  third party. QDegrees Services Private Limited
                                  does not accept or assume any liability in the
                                  event of such unauthorized interception,
                                  hacking or other unauthorized access to
                                  information provided by a user of the service.
                                </li>
                                <li>
                                  The Client/Customer agrees that QDegrees
                                  Services Private Limited or any of its
                                  employees will not be held liable By the
                                  Client/Customer for any loss or damages
                                  arising from your use of, or upon the
                                  information contained on the Website, or any
                                  failure to comply with these Terms and
                                  Conditions where such failure is due to
                                  circumstance beyond QDegrees Services Private
                                  Limited reasonable control.
                                </li>
                              </ul>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Debit/Credit Card, Bank Account Details:
                              </p>
                              <ul style={{ listStyle: "none" }}>
                                <li>
                                  1. The Client/Customer agrees that the
                                  Debit/Credit Card details provided by him/her
                                  for use of the aforesaid Service(s) must be
                                  correct and accurate and that the
                                  Client/Customer shall not use a Debit/Credit
                                  Card, that is not lawfully owned by him/her or
                                  the use of which is not authorized by the
                                  lawful owner thereof. The Client/Customer
                                  further agrees and undertakes to provide
                                  correct and valid Debit/Credit Card details.
                                </li>
                                <li>
                                  2. The Client/Customer may pay his/her
                                  amount/charges to QDegrees Services Private
                                  Limited by using a Debit/Credit Card or
                                  through Online Banking account. The
                                  Client/Customer warrants, agrees and confirms
                                  that when he/she initiates a payment
                                  transaction and/or issues an online payment
                                  instruction and provides his/her card /bank
                                  details:
                                </li>
                                <li>
                                  3. The Client/Customer is fully and lawfully
                                  entitled to use such Debit/Credit Card, Bank
                                  Account for such transactions;
                                </li>
                                <li>
                                  4. The Client/Customer is responsible to
                                  ensure that the Card/Bank account details
                                  provided by him/her are accurate;
                                </li>
                                <li>
                                  5. The Client/Customer is responsible to
                                  ensure that sufficient credit is available on
                                  the nominated Card/Bank account at the time of
                                  making the payment to permit the Payment of
                                  the dues payable or fees dues selected by the
                                  Client/Customer inclusive of the applicable
                                  charges.
                                </li>
                              </ul>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                No warranty:
                              </p>
                              <p>
                                No warranty, representation or guarantee,
                                express or implied, is given by the QDegrees
                                Services Private Limited in respect of the
                                operation of the service.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Disclaimer and Limitation of liability:
                              </p>
                              <p>
                                QDegrees Services Private Limited and/or the
                                Payment Service Providers shall not be liable
                                for any inaccuracy, error or delay in, or
                                omission of (a) any data, information or
                                message, or (b) the transmission or delivery of
                                any such data, information or message; or (c)
                                any loss or damage arising from or occasioned by
                                any such inaccuracy, error, delay or omission,
                                non-performance or interruption in any such
                                data, information or message &amp; consequential
                                damage of any kind which may be suffered or
                                incurred by the User from the use of this
                                service. Under no circumstances shall the
                                QDegrees Services Private Limited and/or the
                                Payment Service Providers, its employees,
                                directors, and its third party agents involved
                                in processing, delivering or managing the
                                Services, be liable for any direct, indirect,
                                incidental, special or consequential damages, or
                                any damages whatsoever, including punitive or
                                exemplary arising out of or in any way connected
                                with the provision of or any inadequacy or
                                deficiency in the provision of the Services or
                                resulting from unauthorized access or alteration
                                of transmissions of data or arising from
                                suspension or termination of the Service.
                                Governing law: These terms and conditions are
                                governed by the laws of India and the competent
                                courts at Jaipur, Rajasthan shall have exclusive
                                jurisdiction.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Refund/Cancellation Policy:
                              </p>
                              <p>
                                Amount once paid will not be refunded under any
                                circumstances.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Privacy Policy:
                              </p>
                              <ul>
                                <li>
                                  {" "}
                                  In General Client/Customer may browse this
                                  website for obtaining any data or information.
                                  However, in order to access the classified
                                  information, one needs to use the credentials
                                  provided as a Client/Customer user.
                                </li>
                                <li>
                                  {" "}
                                  The information provided by the user on this
                                  website shall be kept confidential and shall
                                  not be shared with anyone. The DATA will
                                  neither be made accessible to third parties
                                  nor transferred in any form or manner unless
                                  required by law from a competent authority of
                                  Government.
                                </li>
                                <li>
                                  {" "}
                                  QDegrees Services Private Limited may share
                                  your personal information with our bankers for
                                  clearing necessary paid payments. This
                                  information shall be strictly up to the legal
                                  permissible limit and not exceeding.
                                </li>
                                <li>
                                  {" "}
                                  Online Payment system purpose to provide
                                  flexibility and conform to its continuing
                                  Client/Customer by offering option to pay
                                  online using various payment methods.
                                </li>
                                <li>
                                  {" "}
                                  It is sole discretion of Client/Customer to
                                  choose to make their respective payments.
                                  QDegrees Services Private Limited do not
                                  recommend/ prefer/promote any particular
                                  method.
                                </li>
                                <li>
                                  {" "}
                                  If payment is made by means of a card that you
                                  do not personally own, the permission of the
                                  card owner must always be obtained to make
                                  payments using the card. In using the system
                                  you confirm that you have such permission.
                                </li>
                                <li>
                                  {" "}
                                  Information related to payments using debit or
                                  credit card is not accessed or stored by the
                                  QDegrees Services Private Limited.
                                </li>
                                <li>
                                  {" "}
                                  No Warranty: The information and materials
                                  contained in this site including, graphics,
                                  links or other items are provided as on “As
                                  Is” and “As Available” basis by the QDegrees
                                  Services Private Limited which organized and
                                  tries to provide information accurately and
                                  expressly disclaims liability for error or
                                  omission in this information and materials. No
                                  warranty of any kind, implied, express or
                                  statutory shall be given by the QDegrees
                                  Services Private Limited shall not be limited
                                  to the warranty of fitness for a particular
                                  purpose and freedom from computer virus is
                                  given in conjunction with the information and
                                  materials.
                                </li>
                                <li>
                                  {" "}
                                  Limitation of Liability: In no event, QDegrees
                                  Services Private Limited will be liable for
                                  any damage direct or indirect losses or
                                  expenses arising in connection with site or
                                  use thereof inability to use by any person
                                  delay of operation or transaction, computer
                                  virus etc.
                                </li>
                              </ul>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Security:
                              </p>
                              <p>
                                All payment details which are entered through
                                this payment gateway are encrypted when the
                                Client/Customer, or third party making payment,
                                enters them. The site is secure and uses best
                                practices for encryption to offer secure
                                communications by encrypting all data to and
                                from the site. QDegrees Services Private Limited
                                shall not be liable for any failure by the
                                Client/Customer or third party making payment of
                                Fees to properly protect data from being seen on
                                their screen by other persons or otherwise
                                obtained by such other persons, during the
                                Online Payment process or in respect of any
                                omission to provide accurate information in the
                                Course of the Online Payment Process.
                              </p>
                              <p>
                                Variations to the Terms and Conditions: The
                                QDegrees Services Private Limited reserves the
                                right to vary these Terms and Conditions from
                                time to time and the current version will be
                                that published on this website.
                              </p>
                              <p>
                                We reserve the right to decline the acceptance
                                of an online payment if your account is in
                                default for any reason. QDegrees Services
                                Private Limited may also make
                                additions/deletions/alteration to the services
                                offered, at its sole discretion. We reserves the
                                right to withdraw the service at any time at its
                                discretion. We retain the right to change the
                                terms and conditions for Online Installation,
                                renewal &amp; any other Payments, without any
                                prior notice.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Entire Agreement:
                              </p>
                              <p>
                                These above Terms of Service constitute the
                                entire agreement between the parties with
                                respect to the subject i.e. “Online payment
                                convenience facility through third party/payment
                                gateway” hereof and supersedes and replaces all
                                prior or contemporaneous understandings or
                                agreements, written or oral, regarding such
                                subject matter.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Nondisclosure Agreement:
                              </p>
                              <p>
                                This Nondisclosure Agreement (this “Agreement”)
                                is entered into by QDegrees Services Pvt. Ltd.
                                (hereinafter referred to as “Company”) for the
                                benefit of Client/Customer and its Affiliates.
                                In connection with Company’s provision of
                                partnering with QDegrees for doing online
                                surveys, Company may receive information on
                                Clinet/Customer operations and businesses. In
                                consideration of the receipt of such
                                information, the Company agrees as follows:
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Confidential Information
                              </p>
                              <p>
                                1. “Affiliate” means any entity in which
                                Client/Customer or its group companies have
                                directly or indirectly, certain economic
                                interest, and “Confidential Information” means
                                all nonpublic information relating to operations
                                and businesses disclosed by Client/Customers or
                                its affiliates to the company or company’s
                                affiliates and such other information that is
                                designated as confidential or that, given the
                                nature of the information or the circumstances
                                surrounding its disclosure, reasonably should be
                                considered as confidential.{" "}
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Exclusions
                              </p>
                              <p>
                                2. Confidential Information excludes information
                                that (i) is or becomes publicly available
                                without breach of this Agreement, (iii) is
                                disclosed to Company from any third party who
                                did not acquire or disclose such information by
                                a wrongful or tortious act, or (iv) can be shown
                                by documentation to have been independently
                                developed by Company without reference to any
                                Confidential Information.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Use of Confidential Information
                              </p>
                              <p>
                                3. Company may use Confidential Information only
                                in pursuance of its business relationship with
                                Client/Customers. Except as provided in this
                                Agreement, Company will not disclose
                                Confidential Information to anyone without
                                Client/Customers’s prior written consent.
                                Company will take all responsbility to avoid
                                disclosure, dissemination or unauthorized use of
                                Confidential Information, including, at a
                                minimum, those measures it takes to protect its
                                own confidential information of a similar nature{" "}
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Company Personnel
                              </p>
                              <p>
                                4. Company will restrict the possession,
                                knowledge and use of Confidential Information to
                                its employees and subcontractors who (i) need to
                                know the Confidential Information, and (ii)
                                shall ensure that they are legally obligated to
                                protect the Confidential Information to the same
                                or greater degree as required under this
                                Agreement.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Disclosures to Governmental Authorities
                              </p>
                              <p>
                                5. Company may disclose Confidential Information
                                as directed to comply with orders of judicial
                                and/or governmental authorities. Before such
                                disclosure, the Company will (i) give 15 days
                                prior written notice to Client/Customers
                                providing sufficient time to allow
                                Client/Customers to seek a protective order or
                                other remedy (except to the extent that
                                Company’s compliance would cause it to violate
                                an order of the judicial and/or governmental
                                authority or other legal requirement), (ii)
                                disclose only such information as is required by
                                the judicial and/or governmental authorities,
                                and (iii) use commercially reasonable efforts to
                                obtain confidential treatment for any
                                Confidential Information so disclosed
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Ownership of Confidential Information
                              </p>
                              <p>
                                6. All Confidential Information will remain the
                                exclusive property of Client/Customers.
                                Disclosure of Confidential Information with or
                                without the written consent will not constitute
                                an express or implied grant to Company of any
                                rights to or under patents, copyrights, trade
                                secrets, trademarks or other intellectual
                                property rights. Company will not use any trade
                                name, trademark, logo or any other proprietary
                                rights of Client/Customers (or any of its
                                Affiliates) in any manner without prior written
                                authorization of such use by an authorized
                                representative of Client/Customers (or its
                                applicable Affiliate).
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Notice of Unauthorized Use
                              </p>
                              <p>
                                7. Company will notify Client/Customers
                                immediately upon discovery of any unauthorized
                                use or disclosure of Confidential Information or
                                any other breach of this Agreement. Company will
                                cooperate with Client/Customers in every
                                reasonable way to help regain possession of such
                                Confidential Information and prevent its further
                                unauthorized use and disclosure.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Return of Confidential Information
                              </p>
                              <p>
                                8. Company will return or destroy all tangible
                                materials embodying Confidential Information (in
                                any form and including, without limitation, all
                                summaries, copies and excerpts of Confidential
                                Information) promptly following written request.{" "}
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Injunctive Relief
                              </p>
                              <p>
                                9. Company acknowledges that a breach of its
                                obligations under this Agreement could cause
                                irreparable harm to Client/Customers as to which
                                monetary damages would be taken care by company.
                                Company agrees that Client/Customers will have
                                the right, in addition to its other rights and
                                remedies, to seek injunctive relief for any
                                violation of this Agreement.{" "}
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Scope; Termination
                              </p>
                              <p>
                                10. This Agreement covers Confidential
                                Information received by Company prior and
                                subsequent to the date hereof. This Agreement is
                                effective as of the date Confidential
                                Information is first received and will continue
                                for 1 years, after which it automatically renews
                                unless either party terminates this Agreement by
                                providing at least 90 days prior written notice
                                to the other party, provided, that Company’s
                                obligations with respect to Confidential
                                Information will survive for perpetuity
                                following termination of this Agreement.
                              </p>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "bolder",
                                  fontFamily: '"Work Sans", sans-serif',
                                }}
                              >
                                Miscellaneous.
                              </p>
                              <p>
                                11. This Agreement constitutes the entire
                                non-disclosure agreement between the parties
                                relating to the matters discussed herein and may
                                be amended, modified, or waived only with the
                                mutual written consent of the parties. Company
                                may not assign this Agreement without
                                Client/Customers;s written consent. If a
                                provision of this Agreement is held invalid
                                under applicable law, such invalidity will not
                                affect any other provision of this Agreement
                                that can be given effect. This Agreement will be
                                governed by the laws of India, without reference
                                to its conflict of law rules. The courts at
                                Jaipur, Rajasthan will have exclusive
                                jurisdiction in relation to any disputes arising
                                out of or relating to this Agreement. All
                                notices hereunder will be in writing and will be
                                sent by overnight courier, confirmed facsimile
                                transmission certified mail. Notices to Company
                                will be delivered to the address set forth.
                              </p>
                              <hr className="line_rating" />
                              <table
                                role="presentation"
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                width="100%"
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      className="bg_light"
                                      style={{ textAlign: "center" }}
                                    ></td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="text-footer1 pb10"
                                      style={{
                                        color: "#000",
                                        fontFamily: '"Work Sans", sans-serif',
                                        fontSize: "14px",
                                        lineHeight: "20px",
                                        textAlign: "center",
                                        paddingBottom: "10px",
                                      }}
                                    >
                                      © Copyright (2023)Powered by&nbsp;
                                      <a
                                        className="copyrightTerms"
                                        href="https://www.qDegrees.com/"
                                      >
                                        QDegrees
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      className="text-footer2 pb20"
                                      style={{
                                        color: "#000",
                                        fontFamily: '"Work Sans", sans-serif',
                                        fontSize: "12px",
                                        lineHeight: "26px",
                                        textAlign: "center",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      B-9, 1st Floor, Mahalaxmi Nagar, Behind
                                      WTP South Block, Malviya Nagar, Jaipur,
                                      Rajasthan (302017)
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Save changes</button>
                        </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h3 className="text-center text-orange bg-white com-bs rounded-2 py-3">
                QDegrees Software Price List: Installation &amp; Updation
              </h3>
            </div>
          </div>
          <Form
            onsubmit="return checkForm(this);"
            action=""
            method="post"
            encType="multipart/form-data"
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handlepayment}
            onFinishFailed={onFinishFail}
          >
            <div>
              <Form.Item>
                <Input
                  type="hidden"
                  name="_token"
                  defaultValue="rV3CdxuRvPx7Kw8cTkGJsyMuSJEU3BvGIEZDmxol"
                />
              </Form.Item>
              <div className="row mt-4 mb-2">
                <div className="col-md-6 mt-2">
                  <div className="card rounded-2">
                    <div className="card-body">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-form-heding"
                        >
                          Full Name *
                        </label>
                        <Form.Item>
                          <Input
                            type="text"
                            name="name"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value={getUser.fullName}
                            readOnly
                            // required
                            data-last-active-input
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="card rounded-2">
                    <div className="card-body">
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-form-heding"
                        >
                          Firm Name *
                        </label>
                        <Form.Item>
                          <Input
                            type="text"
                            name="firm_name"
                            className="form-control"
                            id="exampleFormControlInput1"
                            value={getUser.company}
                            readOnly
                          // placeholder
                          // required
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-md-6">
                  <div className="card rounded-2">
                    <div className="card-body">
                      <h4 style={{ fontSize: "18px !important" }}>
                        Correspondence Address
                      </h4>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label text-form-heding"
                        >
                          Address(C) *
                        </label>
                        <Form.Item
                          name="useraddress"
                          rules={[{ required: true, message: 'Please input  User address!' }]}>
                          <Input.TextArea
                            className="form-control site-form-item-icon"
                            name="c_address"
                            id="primaryaddress"
                            // required
                            // rows={2}
                            defaultValue={""}
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>

                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="Maketags form-label text-form-heding"
                        >
                          City(C) *
                        </label>
                        <Form.Item
                          name="usercity"
                          rules={[{ required: true, message: 'Please input  your city!' }]}>
                          <Input
                            type="text"
                            name="c_city"
                            id="city"
                            className="form-control site-form-item-icon"
                            // placeholder
                            // required
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>

                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-form-heding"
                        >
                          State(C) *
                        </label>
                        <Form.Item
                          name="userstate"
                          rules={[{ required: true, message: 'Please input your state!' }]}>
                          <Input
                            type="text"
                            name="c_state"
                            className="form-control site-form-item-icon"
                            id="state"
                            // placeholder
                            // required
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>

                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-form-heding"
                        >
                          Pin Code(C) *
                        </label>
                        <Form.Item
                          name="userpincode"
                          rules={[{ required: true, message: 'Please input  your pincode!' }]}>
                          <Input
                            type="Number"
                            name="c_pin_code"
                            className="form-control site-form-item-icon"
                            id="pin"
                            // placeholder
                            // required
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>

                        <div className="form-check mt-2">
                          <label className="form-check-label">
                            <input
                              className="form-check-input "
                              type="checkbox"
                              id="same"
                              name="same"
                              onChange={(e) => setBilling(!Billing)}
                            />
                            Billing Address Same as Correspondence Address
                          </label>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-form-heding"
                        >
                          Mobile Number *
                        </label>
                        <Form.Item
                          name="usermobile"
                          rules={[{
                            required: true,
                            type: "regexp",
                            pattern: new RegExp(/^[6-9]{1}[0-9]{9}$/),
                            message: "please input valid mobile no.!"
                          }
                          ]}
                        >
                          <Input
                            type="Number"
                            name="register_mobile"
                            className="form-control site-form-item-icon"
                            id="txtMobileNumber"
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>

                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-form-heding"
                        >
                          Email Id *
                        </label>
                        <Form.Item>
                          <Input
                            type="email"
                            name="email"
                            className="form-control site-form-item-icon"
                            id="exampleFormControlInput1"
                            value={getUser.email}

                            // placeholder

                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card rounded-2">
                        <div className="card-body">
                          <h4 style={{ fontSize: "18px" }}>GSTIN Number </h4>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label text-form-heding"
                            >
                              GSTIN Number*
                            </label>
                            <Form.Item
                              name="usergstinno"
                              rules={[{
                                required: true,
                                type: "regexp",
                                pattern: new RegExp(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/),
                                message: "please input valid GST no.!"
                              }]}
                            >
                              <Input
                                type="text"
                                name="gstin_number"
                                className="form-control site-form-item-icon"
                                id="exampleFormControlInput1"
                                onChange={(e) => handleChange(e)}
                              // placeholder
                              />
                            </Form.Item>

                            <p>Note: Fill NA If you don`t have GSTIN.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2" style={{ marginTop: "13px" }}>
                    <div className="card-body">
                      <h4 style={{ fontSize: "18px !important" }}>
                        Billing Address
                      </h4>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlTextarea1"
                          className="form-label text-form-heding"
                        >
                          Address(B)
                        </label>
                        <Form.Item
                          name={Billing === true ? "useraddress" : "useraddress1"}
                          rules={[{ required: true, message: 'Please input  your address!' }]}>
                          <Input.TextArea
                            className="form-control site-form-item-icon"
                            name="b_address"
                            readOnly={Billing}
                            id="secondaryaddress"
                            // value={Billing === true ? from.c_address : from.b_address}
                            // rows={2}
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-form-heding"
                        >
                          City(B)
                        </label>
                        <Form.Item
                          name={Billing === true ? 'usercity' : "usercity1"}
                          rules={[{ required: true, message: 'Please input  your city!' }]}>
                          <Input
                            type="text"
                            name="b_city"
                            id="seccity"
                            // value={Billing === true ? from.c_city : from.b_city}
                            className="form-control site-form-item-icon"
                            readOnly={Billing}
                            // placeholder

                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-form-heding"
                        >
                          State(B)
                        </label>
                        <Form.Item
                          name={Billing === true ? 'userstate' : "userstate1"}
                          rules={[{ required: true, message: 'Please input  your state!' }]}>
                          <Input
                            type="text"
                            name="b_state"
                            className="form-control site-form-item-icon"
                            id="secstate"
                            // value={Billing === true ? from.c_state : from.b_state}
                            readOnly={Billing}
                            // placeholder

                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="exampleFormControlInput1"
                          className="form-label text-form-heding"
                        >
                          Pin Code(B)
                        </label>
                        <Form.Item
                          name={Billing === true ? 'userpincode' : "userpincode1"}
                          rules={[{
                            required: true,
                            pattern: new RegExp(/^[1-9][0-9]{5}$/),
                            message: "please input valid pincode!"
                          }]}
                        >
                          <Input
                            type="Number"
                            className="form-control site-form-item-icon"
                            name="b_pin_code"
                            id="secpin"

                            // value={
                            //   Billing === true ? from.c_pin_code : from.b_pin_code
                            // }
                            readOnly={Billing}

                            // placeholder
                            onChange={(e) => handleChange(e)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="text-center text-orange bg-white my-4 com-bs rounded-2 py-3">
                  <div
                    className="fonter d-flex px-3 justify-content-between align-items-center"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="terms"
                          onChange={(e) => setTermsandCondition(e.target.checked)}
                        />{" "}
                        I have read and agree to the Terms and Condition
                      </label>
                    </div>
                    <div className="d-flex flex-wrap" style={{ gap: "6px" }}>
                      <Form.Item>
                        <Button
                          type="button"
                          className="btn-plan-btn-botton-BTN mainbtn login-form-button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                          style={{ backgroundColor: "#ff9350", border: "none" }}
                        >
                          Terms and Condition
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="button"
                          className="btn-plan-btn-botton-BTN bg-blue mainbtn login-form-button"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModalassasa"
                          style={{ backgroundColor: "#14078c", border: "none" }}
                        >
                          Refund Policy
                        </Button>
                      </Form.Item>
                      <Form.Item>
                        {/* <a href>
                      <Button
                        type="submit"
                        name="submit"
                        disabled={!TermsandCondition}
                        className="btn-plan-btn-botton-BTN mainbtn login-form-button"
                        style={{
                          backgroundColor: "cornflowerblue",
                          border: "none",
                        }}
                        onClick={handlepayment}
                        >
                        CONTINUE
                      </Button>
                    </a> */}
                        <Button type="submit" htmlType="submit"
                          className=" btn-plan-btn-botton-BTN bg-blue mainbtn login-form-button"
                          name="submit"
                          disabled={!TermsandCondition}
                          style={{
                            backgroundColor: "cornflowerblue",
                            border: "none",
                          }}

                        >
                          CONTINUE
                        </Button>

                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <hr className="line_rating"></hr>
          <table
            role="presentation"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            width="100%"
          >
            <tbody>
              <tr>
                <td className="bg_light" style={{ textAlign: "center" }} />
              </tr>
              <tr>
                <td
                  className="text-footer1 pb10"
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontSize: "14px",
                    lineHeight: "20px",
                    textAlign: "center",
                    paddingBottom: "10px",
                  }}
                >
                  © Copyright (2023)Powered by&nbsp;
                  <a
                    className="copyrightTerms"
                    href="https://www.qDegrees.com/"
                  >
                    QDegrees
                  </a>
                </td>
              </tr>
              <tr>
                <td
                  className="text-footer2 pb20"
                  style={{
                    color: "rgb(0, 0, 0)",
                    fontSize: "12px",
                    lineHeight: "26px",
                    textAlign: "center",
                    paddingBottom: "20px",
                  }}
                >
                  B-9, 1st Floor, Mahalaxmi Nagar, Behind WTP South Block,
                  Malviya Nagar, Jaipur, Rajasthan (302017)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

    </>
  );
};

export default Payment;
