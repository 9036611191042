import React from "react";
// import Header from "../../../Components/ReusableComponents/Header";
import Header2 from "../../../Components/ReusableComponents/Header2";
import Footer from "../../../Components/ReusableComponents/Footer";
import { useState, useEffect,useRef } from "react";
import API from "../../../utils/apiCalling";
// import { config } from "../../../utils/apiUrl";
import Select from "react-select";
import { event } from "react-ga";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Config from '../../../config/env'
import { Progress, Input, Space,Form, InputNumber } from "antd";
// import { Group } from "antd/lib/avatar";




function Estimate() {
    const closeModel= useRef(null)
    const api = new API()
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
    const [types, setTypes] = useState([]);
    const [country, setCountry] = useState('English - India');
    const [countryList, setCountrylist] = useState([]);
    const [state, setState] = useState(0)
    const [SelectedCountry, setSelectedCountry] = useState('');
    const [SelectedState, setSelectedState] = useState([""]);
    const [gender, setGender] = useState();
    const [age, setAge] = useState([]);
    const [finalPrices, setFinalPrise] = useState();
    const [show, setShow] = useState(true);
    const [cat, SetCat] = useState("digital");
    const [screeningQue, setScreeningQue] = useState("no");
    const [response, setResponse] = useState("");
    const [day, setDay] = useState("");
    const [group, setGroup] = useState();
    const [mode, setMode] = useState();
    const [fgdfinalPrices, setfgdFinalPrices] = useState()
    const [respondents, setRespondents] = useState("")
    const [city, setCity] = useState()
    const [selectedCity, setSelectedCity] = useState([])
    const [loi, setLoi] = useState("3");

    const [income, setIncome] = useState([])
    const [submitButton, setSubmitButton] = useState(false)
    const [finalSelectedAge, setSelectedAge] = useState([])
    const [gendeerr, setGenderr]=useState([])
    const [completee , setComplete]=useState(0)
    const [IncidenceRate, SetIncidenceRate] = useState('80')
    const [cpi, setCpi]=useState('')
    const [startRange, setStartRange] = useState(new Date().toISOString().split("T")[0])
    let date=new Date();
    date.setDate(date.getDate() + 7);
    const [EndReange,setEndRange] = useState(date.toISOString().split("T")[0])
    const [startDate,setstartDate] = useState('00:00')
    const [endDate,setendDate] = useState('23:00')
    const [ScreeningQuetions,setScreeningQuetions] = useState('')
    const [Pii,setPii] = useState('No')
    const [Calculate1,setcalculate] = useState('')
    const [maleQuota,setMaleQuota] = useState(102)
    const [femaleQuota,setFemaleQuota] = useState(98)
    const [ageQuota,setageQuota]=useState([])
    const [ageRange,setAgeRange] =useState({
        "18-25":42,
        "26-35":50,
        "36-45":41,
        "46-55":29,
        "55+":38,
        total:""
    })
    const [price,setPrice]= useState(0)
    

   
// const SetCalculate = ()=>{
//     setcalculate(valueee.filter(e=>e.country === country))
    
// }
    

 console.log(EndReange,'222')
   
    const setValue = (e) => {
        let { value, checked } = e.target;
        
        if (checked) {
            setGenderr([...gendeerr, value])
        }
        else {
            setGenderr(gendeerr.filter((e) => e !== value))
        }
          
    };
    const setPII = (e)=>{
        let {value, checked} = e.target
        if(checked){
            setPii([...Pii,value])
        }else{
            setPii(Pii.filter((e)=>e !== value))
        }
    }



    useEffect(() => {
        // window.scroll(0, 0)
        getCountry();
        // setCountrylist(countryList + 4)
        finalPrice();
        FgdFinalPrice();
        getCity();
        SelectCountry()

        //  getState()
    }, []);

    const userEmail = localStorage.getItem("clientEmail")
    const callAllFunction = () => {

        finalPrice();
        // setcountrystate();
        FgdFinalPrice();

    };
    const showEstimate = () => {
        callAllFunction()
        setSubmitButton(true)
    }


    const handleSelectedMultipleCountry = (evt) => {
        // setTypes([...type,evt.type])
        getCountry()
      
        const result = evt.map((e) => {
            return e.type
        })

        setTypes(result);
        getState(result);


    };


    const handleSelectedState = (evt) => {

        let result1 = evt.map((e) => e.type)
        setSelectedState(result1);
        getCity(result1)

    };

    const handleSelectedcity = (evt) => {

        let result1 = evt.map((e) => e.type)

        setSelectedCity(result1)

    };



    // setAge(selectedAge)
    const handleChange = (e) => {
        let { value, checked } = e.target;
        if (checked) {
            setAge([...age, value])
            if(value==="18-25"){
                ageQuota.push(18,19,20,21,22,23,24,25)
            }
            else if(value==="26-35"){
                ageQuota.push(26,27,28,29,30,31,32,33,34,35)
            }
            else if(value==="36-45"){
                ageQuota.push(36,37,38,39,40,41,42,43,44,45)
            }else if
            (value==="46-55"){
                ageQuota.push(46,47,48,49,50,51,52,53,54,55)
            }else if(value==="55+"){
                ageQuota.push(60)
            }
            // setAgeRange({ ...ageRange, "18-25": ageRange["18-25"],   [name]: value })
// console.log(country)
        }
        else {
            setAge(age.filter((e) => e !== value))
            if(value==="18-25"){
                let newArray = [18,19,20,21,22,23,24,25]
                let arr=[]
                ageQuota.map(e=>{
                    let c=0
                    newArray.map(q=>{
                        if(e===q){
                            c=1;
                        }
                    })
                    if(c==0){
                        arr.push(e)

                    }
                    })
                    
                    setageQuota(arr)
            }
            else if(value==="26-35"){

                // setageQuota(ageQuota.filter(26,27,28,29,30,31,32,33,34,35))
                let newArray = [26,27,28,29,30,31,32,33,34,35]
                let arr=[]
                ageQuota.map(e=>{
                    let c=0
                    newArray.map(q=>{
                        if(e===q){
                            c=1;
                        }
                    })
                    if(c==0){
                        arr.push(e)

                    }
                    })
                    
                    setageQuota(arr)
            }
            else if(value==="36-45"){
                // ageQuota.map((e)=>{e.filter(36,37,38,39,40,41,42,43,44,45) })
                    let newArray = [36,37,38,39,40,41,42,43,44,45]
                let arr=[]
                ageQuota.map(e=>{
                    let c=0
                    newArray.map(q=>{
                        if(e===q){
                            c=1;
                        }
                    })
                    if(c==0){
                        arr.push(e)

                    }
                    })
                    
                    setageQuota(arr)
                    
               
            }else if
            (value==="46-55"){
                // ageQuota.slice(46,47,48,49,50,51,52,53,54,55)
                let newArray = [46,47,48,49,50,51,52,53,54,55]
                let arr=[]
                ageQuota.map(e=>{
                    let c=0
                    newArray.map(q=>{
                        if(e===q){
                            c=1;
                        }
                    })
                    if(c==0){
                        arr.push(e)

                    }
                    })
                    
                    setageQuota(arr)
            }else if(value==="55+"){
                // ageQuota.slice(60)
                let newArray = [60]
                let arr=[]
                ageQuota.map(e=>{
                    let c=0
                    newArray.map(q=>{
                        if(e===q){
                            c=1;
                        }
                    })
                    if(c==0){
                        arr.push(e)

                    }
                    })
                    
                    setageQuota(arr)
            }
// console.log(ageQuota)
        }
         
    };


    const handleIncome = (e) => {
        let { value, checked } = e.target;
        if (checked) {
            setIncome([...income, value])

        }
        else {
            setIncome(income.filter((e) => e !== value))
        }

    };



    let arr = types.map(e => {
        return Object.create({ "type": e })
    })


    let arr1 = SelectedState.map(e => {
        return Object.create({ "type": e })
    })





    const finalPrice = async () => {
        let option = [];
        option.push({ "type": gender });
        arr.forEach(country => {
            option.push({ type: country.type })
        })
        arr1.forEach(state => {
            option.push({ type: state.type });
        })

        age.forEach((age) => {
            option.push(age)

            // setAge(age)

        })
        income.forEach((income) => {
            option.push(income)
        })

        const data = {

            "totalResponse": response,
            "days": day,
            "sub_category": JSON.stringify(["country", "state", "gender"]),
            "optionDetail": JSON.stringify(option),
            "screeningQuestion": screeningQue,
            "ir": respondents,
            "loi": loi,
            "email": userEmail,
            "queryType": cat,
            "status": 0
        }


        const result = await api.post(`${Config.new.site_api}/client-survey/calculatePriceForSurvey`, data)
        if (result.code === 200) {


            setFinalPrise(result.data)
        }

    };



    // for get country list
    const getCountry = async () => {
        const result = await api.get(`${Config.new.site_api}/client-survey/getCountries`)
        if (result.code === 200) {

            // setCountry(result.data[0].options);
        }


    }

    const getCity = async () => {

        const data = {
            state: JSON.stringify(SelectedState)
        }

        let result = await api.post(`${Config.new.site_api}/client-survey/searchCity`, data)
        if (result.code === 200) {

            setCity(result.data);

        }

    }


    const getState = async (types) => {

        const data = {
            country: JSON.stringify(types)
        }


        let result = await api.post(`${Config.new.site_api}/client-survey/searchState`, data)

        if (result.code === 200) {
            setState(result.data);

        }
    }

    const priceCalculate =async ()=> {

let countryCode 
country!=undefined&&countryList.map((e)=>{
    
    if(e.name==country){countryCode=e.code
     console.log(e.code)}
   
})
let ISOstartTime= startRange+'T'+startDate+`:00.000Z`
// new Date(startRange).toISOString()
let ISOendTime=EndReange+'T'+endDate+`:00.000Z`
// new Date(EndReange).toISOString()
let PII 
if (Pii=="Yes")
{
    PII=true
}else{
    PII =false
}

let data={
        "collects_pii":PII,
        "completes":completee,
        "start_date":ISOstartTime,
        "end_date":ISOendTime,
        "incidence_rate":(IncidenceRate/100),
        "length_of_interview":loi,
        "locale":countryCode,
        "ageRange":JSON.stringify(ageQuota),
        "ageQuota":JSON.stringify(ageRange),
        "genderRange":JSON.stringify(["Female","Male"]),
        "maleQuota":maleQuota,
        "femaleQuota":femaleQuota
    }
    let result = await api.post(`${Config.new.site_api}/client-survey/calculatePrice`,data)
    console.log(result)
    if(result&&result.code===200){
        setPrice(result)
        if (result.price===0){
            toast.success("Your requested completes seems infeasible", {
                position: toast.POSITION.TOP_RIGHT
            });
        }else{

            toast.success("Cost calculated", {
                position: toast.POSITION.TOP_RIGHT
            });
        }
      
    }
    
    else{
        let msg=JSON.parse(result.data)
        console.log(msg)
        toast.error(msg.messages, {
            position: toast.POSITION.TOP_RIGHT
       });

    }


    }

    const setDrops = async (e) => {
        SetCat(e)
        if (e === "digital" || e === "FGD") {
            setShow(true);
        }
        else {
            setShow(false)
        }
        if (e === "FGD") {
            FgdFinalPrice()
        }
        if (e == "telephonic" || e == "f-to-f") {
            const data = {
                "email": userEmail,
                "queryType": e,
            }
            await api.post(`${Config.new.site_api}/client-survey/saveQuery`, data)

        }
    };
    console.log("age",ageRange)

    // bit checkbox code start------------------------
    // -----------------------------------------------

    // var $table = document.querySelector('.minha-table');

    // $table.addEventListener("click", function (ev) {
    //     if (ev.target.tagName == "INPUT") {
    //         if (ev.target.checked) {
    //             ev.target.parentNode.parentNode.classList.add("selected");
    //         } else {
    //             ev.target.parentNode.parentNode.classList.remove("selected");
    //         }
    //     }
    // });

    // bit checkbox code End------------------------
    // -----------------------------------------------

    const FgdFinalPrice = async () => {
        let option = [];
        option.push({ "type": gender });
        arr.forEach(country => {
            option.push({ type: country.type })
        })
        arr1.forEach(state => {
            option.push({ type: state.type });
        })

        age.forEach((age) => {
            option.push(age)
        })
        income.forEach((income) => {
            option.push(income)
        })

        const data = {
            "mode_fgd": mode,
            "groups": group,
            "sub_category": JSON.stringify(["country", "state", "gender"]),
            "optionDetail": JSON.stringify(option),
            "screeningQuestion": screeningQue,
            "ir": respondents,
            "loi": loi,
            "email": userEmail,
            "queryType": cat,
            "status": 0
        }


        const result = await api.post(`${Config.new.site_api}/client-survey/calculatePriceForFGD`, data)
        if (result.code === 200) {


            setfgdFinalPrices(result.data)
        }
    };
    const SelectCountry = async()=>{
        // const Header = {'Authorization':'9C1DC633-6C3A-45A4-8028-CAA9649BB9BB'}
        let result = await api.get(`${Config.new.site_api}/client-survey/getLucidCountries`)

    console.log(result)
    if (result.code===200){
        setCountrylist(result.data)
    }

}

const setAgeState= (e)=>{
    let { name, value } = e.target
    console.log(ageRange,"ttt")
    setAgeRange({...ageRange, name:+value, [name]:+value})
}


const onFinish = (values) => {
    console.log('Received values of form: ', values);
}

const submitSurveyDetails=async()=>{
    let countryCode 
country!=undefined&&countryList.map((e)=>{
    
    if(e.name==country){countryCode=e.code
     console.log(e.code)}
   
})
let ISOstartTime= startRange+'T'+startDate+`:00.000Z`
// new Date(startRange).toISOString()
let ISOendTime=EndReange+'T'+endDate+`:00.000Z`
// new Date(EndReange).toISOString()
let PII 
if (Pii=="Yes")
{
    PII=true
}else{
    PII =false
}

let data={
        "email":user.email,
        "mobile":user.mobile,
        "collects_pii":PII,
        "completes":completee,
        "start_date":ISOstartTime,
        "end_date":ISOendTime,
        "incidence_rate":(IncidenceRate/100),
        "length_of_interview":loi,
        "locale":countryCode,
        "ageRange":JSON.stringify(ageQuota),
        "ageQuota":JSON.stringify(ageRange),
        "genderRange":JSON.stringify(["Female","Male"]),
        "maleQuota":maleQuota,
        "femaleQuota":femaleQuota
    }
    const result = await api.post(`${Config.new.site_api}/client-survey/submitClientSurveyDetails`, data)
    if (result){

        closeModel.current.click()
    }
    
}
    return (
        <>
            <Header2 /> 
            <div className="container-fluid" style={{ overflow: "hidden" }}>
                <div className="row estimatedata">
                    <div className="col-lg-8">
                    <div className="card border-0 insights-card mb-4 cardBitas">
                            <div className="title-bit w-100">
                                <div className="check-bit d-flex align-items-center d-flex align-items-center"><h5 className="m-0 fw-bold">Completes</h5>
                                    <div className="tooltip"><i className="bi bi-info-circle"></i>
                                        <span className="tooltiptextt">Expected total responses</span>
                                    </div>
                                </div>
                                <Form  name="normal_login"
                                             className="form"
                                             initialValues={{ remember: true }}
                                             onFinish={onFinish}>
                                <div className="ComplPercentage mt-3">
                                    <Form.Item  
                                    name="completes" rules={[{ required: true,
                                        pattern: new RegExp(/^(?:[1-9]|[1-9][0-9]{1,3}|10000)$/),
                                     message: '"Total Completes" must be a number and less than or equal to 10000' }]}>
                                    <Input type="number" min={1} max={10000} id="quantity" className="ComplPercentagwq" name="quantity"  onChange={e=>setComplete(e.target.value)} placeholder="0"  /></Form.Item></div>
                                </Form>
                            </div>
                        </div>
                        {console.log(completee)}
                        <div className="card border-0 insights-card mb-4 cardBitas">
                            <div className="title-bit w-100 ">
                                <div className="check-bit d-flex align-items-center"><h5 className="m-0 fw-bold">Select Country
                                </h5><div className="tooltip"><i className="bi bi-info-circle"></i><span className="tooltiptextt">Select the country where you want to conduct the survey.</span></div></div>
                                <div className="ComplPercentage mt-3">
                                    {/* <input className="form-control border-0" list="datalistOptions" id="exampleDataList" placeholder="Select Country..." onChange={e=>setCountry(e.target.value)}/> */}
                                    
                                        <select className="fa form-control"value={country} onChange={(e)=>setCountry(e.target.value)}>
                                        <option value='' hidden>Select Country...</option>
                                        
                                        {countryList && countryList.length > 0 && countryList.map(e=>(
                                            (e.name!=undefined)&& ((e.name.includes("English"))&&(<option value={e.name} >{e.name.split("- ").pop()}</option>))
                                        ))}
                                        </select>
                                        {/* <datalist id="datalistOptions"> */}
                                        {/* <option value="San Francisco">
                                        </option><option value="New York">
                                        </option><option value="Seattle">
                                        </option><option value="Los Angeles">
                                        </option><option value="Chicago"/>
                                        
                                        </datalist> */}
                                        </div>
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-6">
                                <div className="card border-0 insights-card mb-4 cardBitas">
                                    <div className="title-bit w-100 ">
                                        <div className="check-bit d-flex align-items-center">
                                            <h5 className="m-0 fw-bold">LOI</h5>
                                            <div className="tooltip"><span className="text-danger mr-2">*</span><i className="bi bi-info-circle"></i><span className="tooltiptextt">Length of interview</span></div>
                                        </div>
                                        <Form  name="normal_login"
                                             className="form"
                                             initialValues={{ remember: true }}
                                             onFinish={onFinish}>
                                        <div className="ComplPercentage mt-3">
                                        <Form.Item  
                                    name="Loi" rules={[{ required: true,
                                        pattern: new RegExp(/^(?:[1-9]|[1-3][0-9]|4[0-5])$/),
                                     message: '"LOI" must be less than or equal to 45' }]}>
                                            <Input type="number" id="quantity" name="quantity" value={loi} onChange={e=>setLoi(e.target.value)} placeholder="min"/>
                                            </Form.Item >
                                        </div>
                                            </Form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card border-0 insights-card mb-4 cardBitas">
                                    <div className="title-bit w-100 ">
                                        <div className="check-bit d-flex align-items-center">
                                            <h5 className="m-0 fw-bold">Incidence Rate</h5>
                                            <div className="tooltip"><span className="text-danger mr-2">*</span><i className="bi bi-info-circle"></i><span className="tooltiptextt"> The percentage of respondents that will qualify for the study after basic demographic targeting.</span></div>
                                        </div>
                                        <div className="ComplPercentage mt-3">
                                        <Form  name="normal_login"
                                             className="form"
                                             initialValues={{ remember: true }}
                                             onFinish={onFinish}>
                                                 <div className="row">
                                            <Form.Item  
                                    name="Ir" rules={[{ required: true,
                                        pattern: new RegExp(/^(?:[1-9]|[1-9][0-9]|100)$/),
                                        message: '"Incidence Rate" must be less than or equal to 100%'}]}>
                                            <Input type="number" id="quantity" name="quantity" placeholder="%"  style={{width:'97%'}} value={IncidenceRate} onChange={e=>SetIncidenceRate(e.target.value)}/>
                                            </Form.Item>  </div> 
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 insights-card mb-4 cardBitas">
                            
                            <div className="title-bit w-100 d-flex justify-content-between">
                                <div className="check-bit d-flex align-items-left flex-column">
                                    <h5 className="m-0 fw-bold">Gender</h5>
                                    <table className="minha-table mt-4">
                                        <tr>
                                            <td><label><input type="checkbox" value="Male" onChange={setValue}/> Male </label></td>
                                            <td><label><input type="checkbox" value="Female" onChange={setValue} /> Female </label></td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="togggle-bit d-flex flex-column align-items-end">
                                    {/* consoel.log("Hello") */}
                                    <div className="form-check form-switch d-flex align-items-center">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedMeasd" data-bs-toggle="collapse" data-bs-target="#collapseExampleQas" aria-expanded="false" aria-controls="collapseExample" />
                                        <label className="form-check-label ml-1" for="flexSwitchCheckCheckedMeasd">Set Quotas</label>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse mt-3 w-100" id="collapseExampleQas">
                                <div className="card card-body maintainTable w-100 border-0" style={{ background: '#FAFAFA' }}>
                                    <table className="table m-0 table-bordered table-hover" data-classes-applied="true">
                                        {/* <colgroup>
                                            <col style={{ display: 'none' }} />
                                            <col style={{ width: '45px', minWidth: '45px' }} />
                                            <col />
                                            <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                            <col style={{ width: '140px', minWidth: '140px' }} />
                                            <col style={{ width: '100px', minWidth: '100px' }} />
                                        </colgroup> */}
                                        <tbody>
                                            <tr>
                                                <td tabIndex={27} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                    <div className="answer__container">
                                                        <div className="answer__text">
                                                            <div className="flex">
                                                                <span className="flex-1">Male</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={29} className="percent" style={{ textAlign: 'center' }}>
                                                    <div>
                                                        <div>
                                                            <input type="number" value={maleQuota} onChange={(e)=>setMaleQuota(+e.target.value)} style={{border: "1px solid #dadada"}}></input>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={30} className="percent" style={{ textAlign: 'center' }}>
                                                    <div>
                                                        <div>
                                                            {((maleQuota/(maleQuota+femaleQuota)*100).toFixed(2))>0&&(maleQuota/(maleQuota+femaleQuota)*100).toFixed(2)}%
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabIndex={27} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                    <div className="answer__container">
                                                        <div className="answer__text">
                                                            <div className="flex">
                                                                <span className="flex-1">Female</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={29} className="percent" style={{ textAlign: 'center' }}>
                                                    <div>
                                                        <div>
                                                            <input type="number" value={femaleQuota} onChange={(e)=>setFemaleQuota(+e.target.value)} style={{border: "1px solid #dadada"}}></input>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={30} className="percent" style={{ textAlign: 'center' }}>
                                                    <div>
                                                        <div>{((femaleQuota/(maleQuota+femaleQuota)*100))>0&&(femaleQuota/(maleQuota+femaleQuota)*100).toFixed(2)}%</div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td tabIndex={27} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                    <div className="answer__container">
                                                        <div className="answer__text">
                                                            <div className="flex">
                                                                <span className="flex-1" style={{ fontSize: '16px', fontWeight: 'bold' }}>SUM</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={29} className="percent" style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', color: 'red' }}>
                                                    <div>
                                                        <div>
                                                            <span>{maleQuota+femaleQuota}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={30} className="percent" style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', color: 'red' }}>
                                                    <div>
                                                        {/* <div>83.33%</div> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <a className="needAddBit" href=""><i className="bi bi-plus-square-fill"></i>The sum needs to add up to 600</a> */}
                        </div>

                        <div className="card border-0 insights-card mb-4 cardBitas">
                            <div className="title-bit w-100 d-flex justify-content-between">
                                <div className="check-bit d-flex align-items-left flex-column">
                                    <h5 className="m-0 fw-bold">Age Range</h5>
                                    <table className="minha-table mt-4">
                                        <tr>
                                            <td><label><input type="checkbox" value='18-25' onClick={handleChange}/> 18 - 25 </label></td>
                                            <td><label><input type="checkbox" value='26-35' onChange={handleChange}/> 26-35 </label></td>
                                            <td><label><input type="checkbox" value='36-45' onChange={handleChange}/> 36-45 </label></td>
                                            <td><label><input type="checkbox" value='46-55' onChange={handleChange}/> 46-55 </label></td>
                                            <td><label><input type="checkbox" value='55+' onChange={handleChange}/> 55+ </label></td>
                                        </tr>
                                    </table>
                                </div>
                                <div className="togggle-bit d-flex flex-column align-items-end">
                                    <div className="form-check form-switch d-flex align-items-center">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckCheckedRtas" data-bs-toggle="collapse" data-bs-target="#collapseExampleuRe" aria-expanded="false" aria-controls="collapseExample" />
                                        <label className="form-check-label ml-1" for="flexSwitchCheckCheckedRtas">Set Quotas</label>
                                    </div>
                                </div>
                            </div>
                            <div className="collapse mt-3 w-100" id="collapseExampleuRe">
                                <div className="card card-body maintainTable w-100 border-0" style={{ background: '#FAFAFA' }}>
                                    <table className="table m-0 table-bordered table-hover" data-classes-applied="true">
                                        {/* <colgroup>
                                            <col style={{ display: 'none' }} />
                                            <col style={{ width: '45px', minWidth: '45px' }} />
                                            <col />
                                            <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                            <col style={{ width: '140px', minWidth: '140px' }} />
                                            <col style={{ width: '100px', minWidth: '100px' }} />
                                        </colgroup> */}
                                        <tbody>
                                            {age&&age.length>0&&age.map((e1)=> (<tr>
                                                <td tabIndex={27} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                    <div className="answer__container">
                                                        <div className="answer__text">
                                                            <div className="flex">
                                                                <span className="flex-1">{e1}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                
                                                <td tabIndex={29} className="percent" style={{ textAlign: 'center' }}>
                                                    <div>
                                                        <div>
                                                            <input type='number' name={e1} onChange={setAgeState} value={ageRange[e1]} style={{ background: '#ffffff', padding: '6px 24px' }}></input>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={30} className="percent" style={{ textAlign: 'center' }}>
                                                    <div>
                                                        <div>{((ageRange[e1]/(ageRange["18-25"]+ageRange["26-35"]+ageRange["36-45"]+ageRange["46-55"]+ageRange["55+"]))*100)>0&&((ageRange[e1]/(ageRange["18-25"]+ageRange["26-35"]+ageRange["36-45"]+ageRange["46-55"]+ageRange["55+"]))*100).toFixed(2)}%</div>
                                                    </div>
                                                    {console.log(ageRange[e1])}
                                                </td>
                                            </tr>))}
                                            {/* <tr>
                                                <td tabIndex={27} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                    <div className="answer__container">
                                                        <div className="answer__text">
                                                            <div className="flex">
                                                                <span className="flex-1">18 - 24</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={29} className="percent" style={{ textAlign: 'center' }}>
                                                    <div>
                                                        <div>
                                                            <span style={{ background: '#ffffff', padding: '6px 24px' }}>200</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={30} className="percent" style={{ textAlign: 'center' }}>
                                                    <div>
                                                        <div>33.33%</div>
                                                    </div>
                                                </td>
                                            </tr> */}



                                            {/* <tr>
                                                <td tabIndex={27} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                    <div className="answer__container">
                                                        <div className="answer__text">
                                                            <div className="flex">
                                                                <span className="flex-1" style={{ fontSize: '16px', fontWeight: 'bold' }}>SUM</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={29} className="percent" style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', color: 'red' }}>
                                                    <div>
                                                        <div>
                                                            <span>600</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td tabIndex={30} className="percent" style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', color: 'red' }}>
                                                    <div>
                                                        <div>83.33%</div>
                                                    </div>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <a className="needAddBit" href=""><i className="bi bi-plus-square-fill"></i>The sum needs to add up to 600</a> */}
                        </div>

                        
                        

                        <div className="row">
                            
                            <div className="col-md-6">
                                <div className="card border-0 insights-card mb-4 cardBitas">
                                    <div className="title-bit w-100 ">
                                        <div className="check-bit d-flex align-items-center">
                                            <h5 className="m-0 fw-bold">Select Date Range</h5>
                                            <div className="tooltip"><i className="bi bi-info-circle"></i><span className="tooltiptextt">Date Range for the survey execution</span></div>
                                        </div>
                                        <div className="ComplPercentage mt-3">
                                            <div className="d-flex flex-row flex-wrap align-items-center mndin" style={{ width: '108%' }}>
                                                <div className="divmnt">
                                                    <u>
                                                        <input placeholder="Select your date" min={new Date().toISOString().split("T")[0]}  type="date" className=" fw-semibold text-secondary inpcls"  name="checkIn" value={startRange} onChange={e=>setStartRange(e.target.value)} />
                                                    </u>
                                                </div>
                                                {console.log(startRange)}
                                                <h6 className="forange mx-2 fw-semibold">to</h6>
                                                <div className="divmnt">
                                                    <u>
                                                        <input placeholder="Select your date" type="date" className=" fw-semibold text-secondary inpcls" name="checkIn" value={EndReange} onChange={e=>setEndRange(e.target.value)}/>
                                                    </u>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card border-0 insights-card mb-4 cardBitas">
                                    <div className="title-bit w-100">
                                        <div className="check-bit d-flex align-items-center d-flex" style={{ gap: "59px" }}>
                                            <div className="InsKad d-flex align-items-center">
                                                <h5 className="m-0 fw-bold">Start Time</h5>
                                                <div className="tooltip"><i className="bi bi-info-circle"></i><span className="tooltiptextt">Starting time for the survey</span></div>
                                            </div>
                                            <div className="InsKad d-flex align-items-center">
                                                <h5 className="m-0 fw-bold">End Time</h5>
                                                <div className="tooltip"><i className="bi bi-info-circle"></i><span className="tooltiptextt">Ending time for the survey</span></div>
                                            </div>
                                        </div>
                                        <div className="ComplPercentage mt-3">
                                            <div className="d-flex flex-wrap flex-row align-items-center mndin">
                                                <div className="divmnt">
                                                    <u>
                                                        <input placeholder="Select your date" type="time"  className=" fw-semibold text-secondary inpcls" name="checkIn" value={startDate} onChange={e=>setstartDate(e.target.value)} />
                                                    </u>
                                                </div>
                                                <i className="bi mx-3 bi-arrow-left-right "></i>
                                                <div className="divmnt">
                                                    <u>
                                                        <input placeholder="Select your date" type="time"  className=" fw-semibold text-secondary inpcls" name="checkIn" value={endDate} onChange={e=>setendDate(e.target.value)} />
                                                    </u>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* <div className="card border-0 insights-card mb-4 cardBitas">
                            <div className="title-bit w-100 ">
                                <div className="check-bit d-flex align-items-center"><h5 className="m-0 fw-bold">CPI<span className="text-danger">*</span></h5></div>
                                <div className="ComplPercentage mt-3">
                                    <input type="number" id="quantity" name="quantity" placeholder="0" min="1" max="5" onChange={e=>setCpi(e.target.value)} />
                                </div>
                            </div>
                        </div> */}

                        <div className="card border-0 insights-card mb-4 cardBitas">
                            <div className="title-bit w-100 ">
                                <div className="check-bit d-flex align-items-center"><h5 className="m-0 fw-bold">Screening Questions</h5>
                                    <div className="tooltip"><i className="bi bi-info-circle"></i><span className="tooltiptextt">Lorem ipsum dolor</span></div>
                                </div>
                                <div className="ComplPercentage mt-3">
                                    <input type="number" id="quantity" name="quantity" placeholder="0" min="1" max="5" onChange={e=>setScreeningQuetions(e.target.value)}/>
                                </div>
                            </div>
                        </div>

                        <div className="card border-0 insights-card mb-4 cardBitas">
                            <div className="title-bit w-100 ">
                                <div className="check-bit d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                    <h5 className="m-0 fw-bold">PII</h5>
                                    <div className="tooltip"><i className="bi bi-info-circle"></i><span className="tooltiptextt">Lorem ipsum dolor</span></div>
                                    </div>
                                {/* <div className="form-check form-switch d-flex align-items-center">
                                <label className="form-check-label formlablensa ml-1" for="flexSwitchCheckCheckedMeasdsasas">Yes</label>
                                        <input className="form-check-input position-relative" type="checkbox" />
                                        <label className="form-check-label ml-1" for="flexSwitchCheckCheckedMeasdsasas">No</label>
                                    </div> */}
                                </div>
                                <div className="ComplPercentage mt-3">
                                    <table className="minha-table mt-4">
                                        <tr>
                                            <td>
                                                <label>
                                                    <input type="radio" value="All" name="1"  onChange={()=>setPii("Yes")} /> Yes </label>
                                            </td>
                                            <td>
                                                <label>
                                                    <input type="radio" value="Name" name="1" checked  onChange={()=>setPii("No")}/> No </label>
                                            </td>
                                            {/* <td>
                                                <label>
                                                    <input type="checkbox" value='Email' onChange={setPII}/> Email </label>
                                            </td>
                                            <td>
                                                <label>
                                                    <input type="checkbox" value="Mobile"onChange={setPII} /> Mobile </label>
                                            </td> */}
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 SidebarTsaL md-0">
                        <div className="card border-0 insights-card mb-4 cardBitas">
                            <div className="check-bit d-flex align-items-center d-flex justify-content-between w-100">
                                <div className="InsKad d-flex align-items-center">
                                    <h5 className="m-0 fw-bold">Details</h5>
                                    <div className="tooltip"><i className="bi bi-info-circle"></i><span className="tooltiptextt">Lorem ipsum dolor</span></div>
                                </div>
                                <h5 className="m-0">Completes: <span className="fw-bold text-orange">{completee}</span></h5>
                            </div>
                            <div className="AudienValues w-100">
                                <table className="table m-0 mt-4 table-bordered table-hover" data-classes-applied="true">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Gender:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas">{gendeerr.map(e=>e+', ')}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Age Range:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas">{age.map(e=>e+", ")}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Date Range:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas">{startRange} - {EndReange}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Time Range:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas">{startDate}AM ⇆ {endDate} PM</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Country:</div>
                                            </td>
                                            <td>{console.log(country)}
                                                <div className="ans-OLas">{country&&country.split("- ").pop()}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">LOI:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas">{loi}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Incidence Rate:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas" >{IncidenceRate}%</div>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">CPI:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas">{cpi}</div>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Screening Que:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas">{ScreeningQuetions}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">PII:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas">{Pii}</div>
                                            </td>
                                            
                                        </tr>
                                        
                                    </tbody>
                                    <div>
                                    {/* for lucid get price  */}
                                    {/* {completee!=0 &&<button className="btncalctld bg-orange text-white" target="_self" disabled={false} onClick={priceCalculate} >Calculate</button>} */}
                                    {completee!=0 &&<button className="btncalctld bg-orange text-white" target="_self" disabled={false} onClick={submitSurveyDetails} >Submit</button>}
                                            </div>
                                </table>
                            </div>
                        </div>

                        {/* <div className="card border-0 insights-card mb-4 cardBitas">
                            <div className="check-bit d-flex align-items-center d-flex w-100">
                                <h5 className="m-0 fw-bold">Cost Breakdown</h5>
                                <div className="tooltip"><i className="bi bi-info-circle"></i><span className="tooltiptextt">Lorem ipsum dolor</span></div>
                            </div>
                            <div className="AudienValues AudienSecondAns w-100">
                                <table className="table m-0 mt-4 table-bordered table-hover" data-classes-applied="true">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Standard CPI:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas text-secondary text-right fw-semibold">₹&nbsp;{Calculate1[0]?.cpi}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Demographics - Gender:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas text-secondary text-right fw-semibold">₹&nbsp;{Calculate1[0]?.Gender}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">Demographics - Age(quota):</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas text-secondary text-right fw-semibold">₹&nbsp;{Calculate1[0]?.Age}</div>
                                            </td>
                                        </tr>
                                        <tr className="border-bottom">
                                            <td>
                                                <div className="title-OLas text-secondary fw-bold opacity-50">1 - 5 Quetions:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas text-secondary text-right fw-semibold">₹&nbsp;{Calculate1[0]?.Quetions}</div>
                                            </td>
                                        </tr>

                                        <tr className="border-bottom">
                                            <td>
                                                <div className="title-OLas fw-bold">CPI:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas text-secondary text-right fw-semibold">₹&nbsp;{Calculate1[0]?.CPI1}</div>
                                            </td>
                                        </tr>
                                        <tr className="border-bottom">
                                            <td>
                                                <div className="title-OLas fw-bold">Completes:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas text-secondary text-right fw-semibold">&nbsp;{price&&price.lucid.completes_prediction.max}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="title-OLas fw-bold">Cost:</div>
                                            </td>
                                            <td>
                                                <div className="ans-OLas text-secondary text-right fw-semibold">$&nbsp;{price&&price.price.toFixed(2)}</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
            <div>
                <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered size">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h1 className="modal-title fs-5 orangeclr fw-normal" id="exampleModalToggleLabel">Country</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body ">
                                {/* First dropdown start */}
                                <div className="firstdropdown mb-4 pt-3">
                                    <Select isMulti
                                        //  values={types}
                                        placeholder={<div>Search Country</div>}
                                        aria-label="Default select example"
                                        onChange={handleSelectedMultipleCountry}
                                        // options={
                                        //     country && country?.map((op, index) => {
                                        //         op.label = op.type
                                        //         op.value = index
                                        //         return op;
                                        //     })
                                        // }
                                         />
                                </div>
                                <div className="Seconddropdown mb-4">

                                    <Select isMultiAnualModalToggle
                                        //  values={types}

                                        placeholder={<div>Search State</div>}
                                        onClick={(e) => getState()}
                                       
                                        aria-label="Default select example"
                                        onChange={handleSelectedState}
                                        options={
                                            state && state.map((op, index) => {
                                                op.label = op.type
                                                op.value = index
                                                return op;
                                            })

                                        }
                                    />
                                </div>
                                <div className="Thirddropdown mb-4">
                                    <Select isMulti
                                        //  values={types}

                                        placeholder={<div>Search City</div>}
                                        onClick={(e) => getCity()}
                                     
                                        aria-label="Default select example"
                                        onChange={handleSelectedcity}
                                        options={
                                            city && city.map((op, index) => {
                                                op.label = op.type
                                                op.value = index
                                                return op;
                                            })

                                        }
                                    />
                                </div>
                                <div className="btun text-center py-4">
                                    <button className="btn bgorangeclr text-white bttn" data-bs-dismiss="modal" onClick={(e) => { callAllFunction() }}>Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="modal fade" id="Secondmodal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered size">
                    <div className="modal-content">
                        <div className="modal-header pb-1 pt-2 border-0">
                            <h1 className="modal-title fs-5 orangeclr fw-normal" id="exampleModalToggleLabel">Gender</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body ">
                            <div className="d-flex justify-content-center mb-4">
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => setGender("Male")} value="Male" />
                                    <label className="form-check-label fontt" htmlFor="flexRadioDefault1">
                                        Male
                                    </label>
                                </div>
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) => setGender("Female")} value="Female" />
                                    <label className="form-check-label fontt" htmlFor="flexRadioDefault2">
                                        Female
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onChange={(e) => setGender("Both")} value="Both" />
                                    <label className="form-check-label fontt" htmlFor="flexRadioDefault3">
                                        Both
                                    </label>
                                </div>
                            </div>

                            <div className="btnsec text-center py-4">
                                <button className="btn bgorangeclr text-white px-5 py-2" data-bs-dismiss="modal" onClick={(e) => { callAllFunction() }} >Update</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <div>
                <div className="modal fade" id="exampleage" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-centered size">
                        <div className="modal-content">
                            <div className="modal-header border-0">
                                <h1 className="modal-title fs-5 orangeclr fw-normal" id="exampleModalToggleLabel">Age</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">

                                <div className=" checkboxes ps-3">
                                    <div className="form-check mb-2">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:18 , max:25}" onChange={handleChange} id="flexRadioDefault1020" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1020">
                                            18-25

                                        </label>
                                    </div>
                                    <div className="form-check mb-1">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:26 , max:35}" onChange={handleChange} id="flexRadioDefault2030" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2030">
                                            26-35

                                        </label>
                                    </div>
                                    <div className="form-check mb-1">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:36 , max:45}" onChange={handleChange} id="flexRadioDefault3040" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault3040">
                                            36-45
                                        </label>
                                    </div>
                                    <div className="form-check mb-1">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:46 , max:55}" onChange={handleChange} id="flexRadioDefault4040" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault4040">
                                            46-55
                                        </label>
                                    </div>
                                    <div className="form-check mb-1">
                                        <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:56 , max:99}" onClick={handleChange} id="flexRadioDefault5040" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault5040">
                                            55 +

                                        </label>
                                    </div>
                                </div>
                                <webrouk-custom-range start={0} end={1000} from={300} to={700} prefix-char="$">
                                    {/* This input will receive the value from the component (required) */}
                                    <input type="hidden" />
                                </webrouk-custom-range>
                                <div className="btnthr text-center py-4">
                                    <button className="btn bgorangeclr text-white bttn" data-bs-dismiss="modal" onClick={(e) => { callAllFunction() }} >Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="AnualModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
                tabindex="-1">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h1 className="modal-title fs-5 orangeclr fw-normal" id="exampleModalToggleLabel">Annual Household
                                Income</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="mand d-flex ">
                                <div className="Annualincome pe-4">

                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="checkbox" value="{min:0 max:1250}" onClick={(e) => setIncome("Less then $ 1250")} onChange={handleIncome} id="flexCheckDefault1" />
                                        <label className="form-check-label" for="flexCheckDefault1">
                                            Less then $ 1250
                                        </label>
                                    </div>
                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="checkbox" value="{min:1251 max:2500}" onClick={(e) => setIncome("$1251 to $2500")} onChange={handleIncome} id="flexCheckDefault2" />
                                        <label className="form-check-label" for="flexCheckDefault2">
                                            $1251 to $2500
                                        </label>
                                    </div>
                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="checkbox" value="{min:2501 max:3700}" onClick={(e) => setIncome("$2501 to 3700")} onChange={handleIncome} id="flexCheckDefault3" />
                                        <label className="form-check-label" for="flexCheckDefault3">
                                            $2501 to 3700
                                        </label>
                                    </div>
                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="checkbox" value="{min:3701 max:6100}" onClick={(e) => setIncome("$3701 to 6100")} onChange={handleIncome} id="flexCheckDefault4" />
                                        <label className="form-check-label" for="flexCheckDefault4">
                                            $3701 to 6100
                                        </label>
                                    </div>
                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="checkbox" value="{min:6100 max:8600}" onClick={(e) => setIncome("$6100 to $8600")} onChange={handleIncome} id="flexCheckDefault5" />
                                        <label className="form-check-label" for="flexCheckDefault5">
                                            $6100 to $8600
                                        </label>
                                    </div>
                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="checkbox" value="{min:8600 max:11110}" onClick={(e) => setIncome("$8600 to $11110")} onChange={handleIncome} id="flexCheckDefaul6" />
                                        <label className="form-check-label" for="flexCheckDefaul6">
                                            $8600 to $11110
                                        </label>
                                    </div>
                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="checkbox" value="{min:11111 max:13500}" onClick={(e) => setIncome("$11111 to $13500")} onChange={handleIncome} id="flexCheckDefault7" />
                                        <label className="form-check-label" for="flexCheckDefault7">
                                            $11111 to $13500
                                        </label>
                                    </div>

                                </div>

                                <div className="rightcheck">

                                    <div className="form-check pb-2">
                                        <input className="form-check-input" type="checkbox" value="{min:13501 max:33500}" onClick={(e) => setIncome("More then $13500")} onChange={handleIncome} id="flexCheckDefault8" />
                                        <label className="form-check-label" for="flexCheckDefault8">
                                            More then $13500
                                        </label>
                                    </div>


                                </div>
                            </div>
                            <div className="btnthr text-center pt-3">

                                <button className="btn bgorangeclr bttn text-white" data-bs-dismiss="modal" onClick={(e) => { callAllFunction() }} >Update</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="modal fade" id="TelephonicModel" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered size">
                    <div className="modal-content">

                        <div className="modal-body ">


                            <div className="contents text-center">
                                <p className="w-75 text-center mx-auto" style={{ fontSize: "18px" }}> Thank you for contacing us.<br></br>Our Team Will Contact You soon.</p>

                            </div>





                        </div>

                    </div>
                </div>
            </div>
            <div ref={closeModel} data-bs-toggle="modal" href="#submitModel"/>
            <div className="modal fade" id="submitModel" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered size">
                    <div className="modal-content">

                        <div className="modal-body ">

                            <div className="contents text-center">
                                <p className="w-75 text-center mx-auto" style={{ fontSize: "18px" }}> Thank you for sharing Details.<br></br>
                                    Our research representative will connect you soon.</p>

                            </div>





                        </div>

                    </div>
                </div>
            </div>
            <ToastContainer/>

        </>

    )
};

export default Estimate;



// import React from "react";
// import Header from "../../../Components/ReusableComponents/Header";
// import Footer from "../../../Components/ReusableComponents/Footer";
// import { useState, useEffect } from "react";
// import API from "../../../utils/apiCalling";
// // import { config } from "../../../utils/apiUrl";
// import Select from "react-select";
// // import { Group } from "antd/lib/avatar";




// function Estimate() {
//     const api = new API()

//     const [types, setTypes] = useState([]);
//     const [country, setCountry] = useState(0);
//     const [countryList, setCountrylist] = useState([]);
//     const [state, setState] = useState(0)
//     const [SelectedCountry, setSelectedCountry] = useState('');
//     const [SelectedState, setSelectedState] = useState([""]);
//     const [gender, setGender] = useState();
//     const [age, setAge] = useState([]);
//     const [finalPrices, setFinalPrise] = useState();
//     const [show, setShow] = useState(true);
//     const [cat, SetCat] = useState("digital");
//     const [screeningQue, setScreeningQue] = useState("no");
//     const [response, setResponse] = useState("");
//     const [day, setDay] = useState("");
//     const [group, setGroup] = useState();
//     const [mode, setMode] = useState();
//     const [fgdfinalPrices, setfgdFinalPrices] = useState()
//     const [respondents, setRespondents] = useState("")
//     const [city, setCity] = useState()
//     const [selectedCity, setSelectedCity] = useState([])
//     const [loi, setLoi] = useState("");
//     const [income, setIncome] = useState()
//     const [submitButton, setSubmitButton] = useState(false)
//     const [finalSelectedAge, setSelectedAge] = useState([])


//     useEffect(() => {
//         // window.scroll(0, 0)
//         getCountry();
//         setCountrylist(countryList + 4)
//         finalPrice();
//         FgdFinalPrice();
//         getCity();
       
//         //  getState()
//     }, [screeningQue, types, day, response, mode, respondents, age, SelectedState, state, loi,group,cat]);
    
//     const userEmail=localStorage.getItem("clientEmail")
//     const callAllFunction = () => {

//         finalPrice();
//         // setcountrystate();
//         FgdFinalPrice();
        
//     };
//     const showEstimate=()=>{
//         callAllFunction()
//         setSubmitButton(true)
//     }


//     const handleSelectedMultipleCountry = (evt) => {
//         // setTypes([...type,evt.type])
//         getCountry()
      
//         const result = evt.map((e) => {
//             return e.type
//         })
    
//         setTypes(result);
//         getState(result);
     

//     };


//     const handleSelectedState = (evt) => {
      
//         let result1 = evt.map((e) => e.type)
//         setSelectedState(result1);
//         getCity(result1)

//     };

//     const handleSelectedcity = (evt) => {
      
//         let result1 = evt.map((e) => e.type)
       
//         setSelectedCity(result1)

//     };


//     let selectedAge = []
//     // setAge(selectedAge)
//     const handleChange = (e) => {
//         let { checked } = e.target;
//         if (checked) {
//             selectedAge.push(e.target.value)
//             // finalSelectedAge.push(e.target.value)
//             // age.push(e.target.value)
//             // const result = selectedAge.map((e) => {
//             //     return e.type
//             // })
          
//             // setAge(e.target.value)
         
//             setSelectedAge(e.target.value)

//         }
//         else {
//             let index = selectedAge.indexOf(e.target.value)
//             selectedAge.splice(index, 1);
//             finalSelectedAge.splice(index, 1)
//             // setAge(selectedAge)
//         }
     
//     };
  

//     let selectedIncome = [];
//     const handleIncome = (e) => {
//         let { checked } = e.target;
//         if (checked) {
         
//             selectedIncome.push(e.target.value)
//         }
//         else {
          
//             let index = selectedIncome.indexOf(e.target.value)
//             selectedIncome.splice(index, 1);
//         }
      
//     };


//     let arr = types.map(e => {
//         return Object.create({ "type": e })
//     })
  

//     let arr1 = SelectedState.map(e => {
//         return Object.create({ "type": e })
//     })

 



//     const finalPrice = async () => {
//         // let AgeIncome = selectedAge.concat(selectedIncome,...arr) 
//         // AgeIncome.map(e=>  
      
//         // )

//         // let index = AgeIncome.indexOf(e.target.value)
//         //     selectedIncome.splice(index, 1);
//         let option = [];
//         option.push({ "type": gender });
//         arr.forEach(country => {
//             option.push({ type: country.type })
//         })
//         arr1.forEach(state => {
//             option.push({ type: state.type });
//         })

//         selectedAge.forEach((age) => {
//             option.push(age)
//             // setAge(age)
//         })
//         selectedIncome.forEach((income) => {
//             option.push(income)
//         })

//         const data = {

//             "totalResponse": response,
//             "days": day,
//             "sub_category": JSON.stringify(["country", "state", "gender"]),
//             "optionDetail": JSON.stringify(option),
//             "screeningQuestion": screeningQue,
//             "ir": respondents,
//             "loi": loi,
//             "email": userEmail,
//             "queryType":cat,
//             "status": 0
//         }
       

//         const result = await api.post('https://new.doyoursurvey.com:3009/client-survey/calculatePriceForSurvey', data)
//         if (result.code === 200) {

         
//             setFinalPrise(result.data)
//         }

//     };



//     // for get country list
//     const getCountry = async () => {
//         const result = await api.get('https://new.doyoursurvey.com:3009/client-survey/getCountries')
//         if (result.code === 200) {
        
//             setCountry(result.data[0].options);
//         }
       

//     }

//     const getCity = async () => {
//         const data = {
//             state: JSON.stringify(SelectedState)
//         }
//         let result = await api.post("https://new.doyoursurvey.com:3009/client-survey/searchCity", data)
//         if (result.code === 200) {
          
//             setCity(result.data);

//         }
//     }


//     const getState = async (types) => {

//         const data = {
//             country: JSON.stringify(types)
//         }

//         let result = await api.post("https://new.doyoursurvey.com:3009/client-survey/searchState", data)

//         if (result.code === 200) {
//             setState(result.data);

//         }
       
//     }

//     const setDrops = async(e) => {
//         SetCat(e)
//         if (e === "digital" || e === "FGD") {
//             setShow(true);
//         }
//         else {
//             setShow(false)
//         }
//         if (e === "FGD") {
//             FgdFinalPrice()
//         }
//         if(e=="telephonic"||e=="f-to-f"){
//             const data = {
//                 "email": userEmail,
//                 "queryType":e,
//             }
//             await api.post('https://new.doyoursurvey.com:3009/client-survey/saveQuery', data)

//         }
//     };

//     const FgdFinalPrice = async () => {
//         let option = [];
//         option.push({ "type": gender });
//         arr.forEach(country => {
//             option.push({ type: country.type })
//         })
//         arr1.forEach(state => {
//             option.push({ type: state.type });
//         })

//         selectedAge.forEach((age) => {
//             option.push(age)
//         })
//         selectedIncome.forEach((income) => {
//             option.push(income)
//         })

//         const data = {
//             "mode_fgd": mode,
//             "groups": group,
//             "sub_category": JSON.stringify(["country", "state", "gender"]),
//             "optionDetail": JSON.stringify(option),
//             "screeningQuestion": screeningQue,
//             "ir": respondents,
//             "loi": loi,
//             "email": userEmail,
//             "queryType":cat,
//             "status": 0
//         }
     

//         const result = await api.post('https://new.doyoursurvey.com:3009/client-survey/calculatePriceForFGD', data)
//         if (result.code === 200) {

        
//             setfgdFinalPrices(result.data)
//         }

//     };
    
//         // (cat=="telephonic"||cat=="f-to-f")&&
//         // const data ={
//         //     "email": userEmail,
//         //     "queryType":cat,
//         // }
        
    



//     return (
//         <>

//             <Header />


//             <div className="container-fluid" style={{ overflow: "hidden" }}>

//                 <div className="row">
//                     <div className="col-lg-8 md-6" style={{ marginTop: "120px" }}>
//                         <div className="w-100">
//                             <div className="card p-3">
//                                 <h5 className="p-2" style={{ color: '#F47321' }}>DoYourSurvey Audience</h5>
//                                 <p className="p-2">
//                                     Consumer needs and expectations are evolving, reshaping the market and changing how businesses function. Here's how DoYourSurvey will help you succeed in this new environment.
//                                 </p>
//                                 <h5 className="p-2" style={{ color: '#F47321' }} >Quick responses for quick decisions</h5>
//                                 <p className="p-2">
//                                     You can't afford to be slow. In a world where trends change instantly, your decisions need to be instant too. Get quick responses from your target audience and uncover insights in real-time.
//                                 </p>
//                                 <h5 className="p-2" style={{ color: '#F47321' }}>DIY with a state-of-the-art dashboard</h5>
//                                 <p className="p-2">
//                                     We pride ourselves on the simplicity of our solution. Backed by powerful AI engines and an interactive dashboard, you can get your primary research started in no time with our DIY tool and SaaS-based model.
//                                 </p>
//                                 <div className="row">
//                                     <div className="col-lg-12 md-3">
//                                         <div className="card mb-4 " id="card2-1" style={{ width: 'auto', height: 'auto' }}>
//                                             <div className="card-body">
//                                                 <div className="row">

//                                                     {/* <div className="col" onClick={(e) => setDrops("digital")}>
//                                                         <div className="card">
//                                                             <div className="card-body">
//                                                                 <a className="btn btn-p" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample1"><img className="icons" src="..//images/checklist.svg" /><b>Digital Survey</b></a> */}

//                                                     {/* </div> */}
//                                                     <div className="col-sm-3" onClick={(e) => setDrops("digital")} >
//                                                         <div className="card box-size">
//                                                             <a className="btn btn-p" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
//                                                                 <img className="icons" src="..//images/checklist.svg" /><br></br>
//                                                                 <p className="text-center blueclr-1 fw-semibold" style={{ fontSize: "12px", paddingTop: "10px" }}>Digital Survey</p>
//                                                             </a>
//                                                         </div>
//                                                     </div>
//                                                     {/* <div className="col" onClick={(e) => setDrops("FGD")}>
//                                                         <a className="btn btn-p" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample2"><img className="icons" src="..//images/group-svgrepo-com.svg" />Focus Group Discussions</a>
//                                                     </div> */}
//                                                     <div className="col-sm-3" onClick={(e) => setDrops("FGD")}>
//                                                         <div className="card box-size">
//                                                             <a className="btn btn-p" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample2"
//                                                             ><img className="icons" src="..//images/group-svgrepo-com.svg" /><br></br>
//                                                                 <p className="text-center blueclr-1 fw-semibold" style={{ fontSize: "12px", paddingTop: "10px" }}> Focus Group Discussion</p>
//                                                             </a>
//                                                         </div>
//                                                     </div>

//                                                     {/* <div className="col" onClick={(e) => setDrops("telephonic")}>
//                                                         <a className="btn btn-p" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample3"><img className="icons" data-bs-toggle="modal" href="#TelephonicModel" src="..//images/callAllocation.svg" />Telephonic Interviews</a>
//                                                     </div> */}
//                                                     <div className="col-sm-3" onClick={(e) => setDrops("telephonic")}>
//                                                         <div className="card box-size" data-bs-toggle="modal" href="#TelephonicModel" >
//                                                             <a className="btn btn-p" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample3">
//                                                                 <img className="icons" src="..//images/callAllocation.svg" /><br></br>

//                                                                 <p className="text-center blueclr-1 fw-semibold" style={{ fontSize: "12px", paddingTop: "10px" }}>Telephonic Interviews</p>
//                                                             </a>
//                                                         </div>
//                                                     </div>
//                                                     {/* <div className="col" onClick={(e) => setDrops("f-to-f")}>
//                                                         <a className="btn btn-p" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample4"><img className="icons" data-bs-toggle="modal" href="#TelephonicModel" src="..//images/meeting-svgrepo-com.svg" />Face-to-Face Interviews</a>
//                                                     </div> */}
//                                                     <div className="col-sm-3" onClick={(e) => setDrops("f-to-f")}>

//                                                         <div className="card box-size" data-bs-toggle="modal" href="#TelephonicModel" >
//                                                             <a className="btn btn-p" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="multiCollapseExample4">
//                                                                 <img className="icons" src="..//images/meeting-svgrepo-com.svg" /><br></br>

//                                                                 <p className="text-center blueclr-1 fw-semibold" style={{ fontSize: "12px", paddingTop: "10px" }}> Face-to-Face Interviews</p>
//                                                             </a>
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div className="row">
//                                                     {(cat == "digital") ? <div className="col-12">
//                                                         <div  >
//                                                             <div className="card card-body">
//                                                                 Digital surveys are easy to conduct and provide immediate access to intelligence while remaining cost-effective.
//                                                                 As more people get connected to the internet, digital surveys provide unprecedented access to this online population.
//                                                                 It is the most popular form of research amongst our clients.
//                                                             </div>
//                                                         </div>
//                                                     </div> : null}
//                                                     {(cat == "FGD") ? <div className="col-12">
//                                                         <div >
//                                                             <div className="card card-body">
//                                                             With a skilled moderator, we bring in qualified panelists from your target market to help you unlock qualitative insights fast.
//                                                          You get to hear customer feedback in their own words and voices.
//                                                             </div>
//                                                         </div>
//                                                     </div> : null}
//                                                     {(cat == "telephonic") ? <div className="col-12">
//                                                         <div >
//                                                             <div className="card card-body">
//                                                                 Our telephonic interviews provide you with direct access to respondents around the globe.
//                                                                 You can easily connect with your target audience in a multitude of countries to get the answers you need.
//                                                             </div>
//                                                         </div>
//                                                     </div> : null}
//                                                     {(cat === "f-to-f") ? <div className="col-12">
//                                                         <div >
//                                                             <div className="card card-body">
//                                                             Best suited for qualitative research, personal interviews give you the opportunity to gain deeper insights into your consumer base. 
//                                                             This form of research comes in handy when detailed answers and in-depth insights are needed to power your study.
//                                                             </div>
//                                                         </div>
//                                                     </div> : null}
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 {(show == true) ? <div>
//                                     <div className="row">
//                                         <div className="">
//                                             {/* <div className="card-body"> */}

//                                             <h5 className="card-title" style={{ color: '#F47321' }}>Step 1: Who do you want to survey?
//                                             </h5><br />
//                                             <div className="container">
//                                                 <div className="row" data-bs-toggle="modal" href="#exampleModalToggle">
//                                                     <div className="col-lg-3 md-6">
//                                                         <div className="card text-center mb-4" id="" style={{ width: 'auto', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//                                                             <div className="card-body ">

//                                                                 <img className="icons-2" src="../images/Group 24278.svg"  /><br />
//                                                                 <h5 class="card-title-size pt-3">Country </h5>
//                                                                 {/* <p style="padding-top: 70px;">Choose country & region as per the requirement</p> */}
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="col-lg-3 md-6" data-bs-toggle="modal" href="#Secondmodal">
//                                                         <div className="card text-center mb-4" id="" style={{ width: 'auto', height: 'auto', display: 'flex', alignItems: 'center' }}>
//                                                             <div className="card-body">

//                                                                 <img className="icons-2" src="..//images/Group 24279.svg"  /><br />
//                                                                 <h5 class="card-title-size pt-3">Gender</h5>

//                                                                 {/* <p style="padding-top: 70px;">Choose distribution of response you want from each gender</p> */}
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="col-lg-3 md-6" data-bs-toggle="modal" href="#exampleage" >
//                                                         <div className="card text-center mb-4" id="" style={{ width: 'auto', height: 'auto', display: 'flex', alignItems: 'center' }}>
//                                                             <div className="card-body">

//                                                                 <img className="icons-2" src="..//images/Group 10985.svg" /><br />
//                                                                 <h5 class="card-title-size pt-3">Age </h5>
//                                                                 {/* <p style="padding-top: 70px;">Choose distribution of responses from each age bracket</p> */}
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                     <div className="col-lg-3 md-6" data-bs-toggle="modal" href="#AnualModalToggle">
//                                                         <div className="card text-center mb-4" id="" style={{ width: 'auto', height: 'auto', display: 'flex', alignItems: 'center' }}>
//                                                             <div className="card-body">

//                                                                 <img className="icons-2" src="..//images/Group 11100.svg"  /><br />
//                                                                 {/* <p style="padding-top: 70px;">Choose the range of the income for the target group</p> */}
//                                                                 <h5 class="card-title-size pt-3">Income </h5>
//                                                             </div>

//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                                 <div className="moretargeting">
//                                                     {/* <a href="javascrpt:void(0);"
//                                                     class="bgorangeclr d-inline-block text-decoration-none text-white">
//                                                     More Targeting Options
//                                                 </a> */}
//                                                 </div>
//                                             </div>


//                                             {/* </div> */}
//                                         </div>
//                                     </div>
//                                     <div className="row">
//                                         <div className="">

//                                             {(cat == "digital") ?
//                                                 <div className="">
//                                                     <h5 className="card-title" style={{ color: '#F47321' }}>STEP 2: How many complete responses
//                                                         do you need ?</h5><br />

//                                                     {/* <div className="row"> */}

//                                                     {/* <input placeholder="Enter responses you want" onChange={(e) => setResponse(e.target.value)} value={response}></input>
//                                                     <br /><br />
//                                                     <input onChange={(e) => setDay(e.target.value)} value={day} placeholder="Enter days for complite survey"></input> */}
//                                                     {/* </div> */}

//                                                     <div className="row">
//                                                         <div className="col-lg-4 md-3">
//                                                             <div className=" text-center mb-4" id="" style={{ width: 'auto', height: 'auto', display: 'flex', alignItems: 'center' }}>

//                                                                 {/* <botton className="btn btn primary">{(response=="")? 0: response}</botton> */}
//                                                                 <input className="card btn btn primary " type="number" placeholder="Enter Required Responses" onChange={(e) => setResponse(e.target.value)} />

//                                                             </div>
//                                                         </div>


//                                                         <div className="col-lg-8 md-3">
//                                                             <div className="card text-center mb-4" id="" style={{ width: 'auto', height: 'auto', display: 'flex', alignItems: 'center' }}>
//                                                                 <div className="range-slider" >
//                                                                     <div id="tooltip" />
//                                                                     <div className="d-flex justify-content-around p-2">
//                                                                         {/* <h6 className="text-black">Male : <span className="orangeclr"> 51% </span></h6>
//                                                                 <h6 className="text-black">Male : <span className="orangeclr"> 49% </span></h6> */}
//                                                                     </div>
//                                                                     <input id="range" type="range" step={10}
//                                                                     //  min={50} max={800} 
//                                                                      onChange={(e) => setResponse(e.target.value)} value={response} disabled />
//                                                                 </div>
//                                                             </div>
//                                                         </div>

//                                                         <div className="row">
//                                                             <div className="">

//                                                                 <h5 className="card-title" style={{ color: '#F47321' }}>STEP 3: How many days do you need to complete the survey?</h5><br />

//                                                                 <div className="row">
//                                                                     <div className="col-lg-4 md-3">
//                                                                         <div className="text-center mb-4" id="" style={{ width: 'auto', height: 'auto', display: 'flex', alignItems: 'center' }}>

//                                                                             {/* <botton className="btn btn primary">{(day=="")?0:day}</botton> */}
//                                                                             <input className="card btn btn primary" type="number" placeholder="Enter Days" onChange={(e) => setDay(e.target.value)}/>

//                                                                         </div>
//                                                                     </div>



//                                                                     <div className="col-lg-8 md-3">
//                                                                         <div className="card text-center mb-4" id="" style={{ width: 'auto', height: 'auto', display: 'flex', alignItems: 'center' }}>
//                                                                             <div className="range-slider">
//                                                                                 <div id="tooltip" />
//                                                                                 <div className="d-flex justify-content-around p-2">
//                                                                                     {/* <h6 className="text-black">Male : <span className="orangeclr"> 51% </span></h6>
//                                                                 <h6 className="text-black">Male : <span className="orangeclr"> 49% </span></h6> */}
//                                                                                 </div>

//                                                                                 <input id="range" type="range"
//                                                                                 //  min={2} max={14} 
//                                                                                  onChange={(e) => setDay(e.target.value)} value={day} disabled/>

//                                                                             </div>
//                                                                         </div>
//                                                                     </div>




//                                                                 </div>
//                                                             </div>
//                                                         </div>


//                                                     </div>

//                                                 </div> : null}


//                                             {(cat == "FGD") ? <div className="">
//                                                 <h5 className="card-title" style={{ color: '#F47321' }}>STEP 2: Select mode of F-G-D.</h5>
//                                                 <p>Select mode of F-G-D</p>
//                                                 <form onChange={() => FgdFinalPrice()}>
//                                                     <div className="form-check form-check-inline">
//                                                         <input className="form-check-input" type="radio" onClick={(e) => setMode('online')} name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" />
//                                                         <label className="form-check-label" htmlFor="inlineRadio1">Online</label>
//                                                     </div>
//                                                     <div className="form-check form-check-inline">
//                                                         <input className="form-check-input" type="radio" name="inlineRadioOptions" onClick={(e) => setMode('offline')} id="inlineRadio2" defaultValue="option2" />
//                                                         <label className="form-check-label" htmlFor="inlineRadio2">Offline</label>
//                                                     </div>
//                                                 </form><br />

                                                
//                                                     <h5 className="card-title" style={{ color: '#F47321' }}>STEP 3: How many group do you.</h5>
                                                    
//                                                     {/* <span className="orangeclr">Step 3: How many group do you want for conduct survey</span> */}
//                                                     <p>Enter number of group</p>
//                                                     <input onChange={(e) => setGroup(e.target.value)} value={group} placeholder="Enter number of group"></input><br />

                                                
//                                             </div> : null}<br />
//                                         </div>
//                                     </div>

//                                     <div className="row">
//                                         <div className="">

//                                             <div className="row">
//                                                 <h5 className="card-title" style={{ color: '#F47321' }}>STEP 4: (Optional) Do you want to add
//                                                     your own screening questions to your survey?</h5><br />
//                                                 <div className="container">
//                                                     <p>Adding your own screening questions can help you target a more specific
//                                                         audience.</p>
//                                                     <div className="row">
//                                                         <form>
//                                                             <div className="form-check form-check-inline">
//                                                                 <input className="form-check-input" type="radio" onClick={(e) => setScreeningQue("yes")} name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" />
//                                                                 <label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
//                                                             </div>
//                                                             <div className="form-check form-check-inline">
//                                                                 <input className="form-check-input" type="radio" onClick={(e) => setScreeningQue("No")} name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" />
//                                                                 <label className="form-check-label" htmlFor="inlineRadio2">No</label>

//                                                             </div>
//                                                         </form>
//                                                         <span> To add a screening question:</span>
//                                                         <p className="mb-0"> 1. Add a question to the beginning of your survey. We recommend
//                                                             Multiple
//                                                             Choice or Checkboxes.</p>
//                                                         <p>2. Add skip logic that disqualifies people if they select certain answer
//                                                             choices.</p>
//                                                     </div>
//                                                 </div>
//                                             </div>



//                                         </div>
//                                     </div>

//                                     <div className="row">

//                                         <div className="container">

//                                             <div className="row">
//                                                 <form>

//                                                 </form>


//                                                 <div className="row">
//                                                     <h5 className="card-title" style={{ color: '#F47321' }}>STEP 5: How many people do you expect to qualify for your
//                                                         survey?</h5>


//                                                     <div className="col-md-5 mt-3" onChange={() => FgdFinalPrice()}>
//                                                         <div className="form-check mb-2">
//                                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setRespondents(10)} />
//                                                             <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                                 0-10% of respondents
//                                                             </label>
//                                                         </div>
//                                                         {/*  */}
//                                                         <div className="form-check mb-2">
//                                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setRespondents(20)} />
//                                                             <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                                 11-20% of respondents
//                                                             </label>
//                                                         </div>
//                                                         {/*  */}
//                                                         <div className="form-check mb-2">
//                                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setRespondents(30)} />
//                                                             <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                                 21-30% of respondents
//                                                             </label>
//                                                         </div>
//                                                         {/*  */}
//                                                         <div className="form-check mb-2">
//                                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setRespondents(40)} />
//                                                             <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                                 31-40% of respondents
//                                                             </label>
//                                                         </div>
//                                                         {/*  */}
//                                                         <div className="form-check mb-2">
//                                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setRespondents(50)} />
//                                                             <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                                 41-50% of respondents
//                                                             </label>
//                                                         </div>
//                                                         {/*  */}
//                                                         <div className="form-check mb-2">
//                                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setRespondents(60)} />
//                                                             <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                                 51-60% of respondents
//                                                             </label>
//                                                         </div>
//                                                         {/*  */}
//                                                         <div className="form-check mb-2">
//                                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setRespondents(70)} />
//                                                             <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                                 61-70% of respondents
//                                                             </label>
//                                                         </div>
//                                                         {/*  */}
//                                                         <div className="form-check mb-2">
//                                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setRespondents(80)} />
//                                                             <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                                 71-80% of respondents
//                                                             </label>
//                                                         </div>
//                                                         {/*  */}
//                                                         <div className="form-check mb-2">
//                                                             <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={() => setRespondents(90)} />
//                                                             <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                                 More then 80% of respondents
//                                                             </label>
//                                                         </div>
//                                                         {/*  */}

//                                                     </div>
//                                                     <div className="col-md-7 mt-3">
//                                                         <span className="orangeclr">Example</span>
//                                                         <p className="p-1 mb-0 sayu">Say you choose “Owns a dog” as a targeting
//                                                             option,
//                                                             but
//                                                             you only want responses from people who own a small dog. You add a
//                                                             screening question to your survey to disqualify anyone that owns a
//                                                             medium or large dog.</p> <br />
//                                                         <span className="sayu"> You estimate that 35% of dog owners own a small dog.
//                                                             In other
//                                                             words, 35% of your target audience will qualify for your survey
//                                                             after answering your screening question. <br /> Choose a Qualification
//                                                             Rate of 35-49% of respondents.</span>
//                                                     </div>
//                                                     <p className="text-center mt-4">Do your best to estimate the percentage of
//                                                         people that you expect to
//                                                         qualify for your survey based on your screening questions. We'll
//                                                         show you the Actual Qualification Rate after we deliver your
//                                                         responses, which can help you set an accurate Qualification Rate for
//                                                         future orders.</p>
//                                                 </div>


//                                             </div>
//                                             {/* <div className="col-md-7 mt-3">
//                                                     <span className="orangeclr">Example</span>
//                                                     <p className="p-1 mb-0 sayu">Say you choose “Owns a dog” as a targeting
//                                                         option,
//                                                         but
//                                                         you only want responses from people who own a small dog. You add a
//                                                         screening question to your survey to disqualify anyone that owns a
//                                                         medium or large dog.</p> <br />
//                                                     <span className="sayu"> You estimate that 35% of dog owners own a small dog.
//                                                         In other
//                                                         words, 35% of your target audience will qualify for your survey
//                                                         after answering your screening question. <br /> Choose a Qualification
//                                                         Rate of 35-49% of respondents.</span>
//                                                 </div> */}
//                                             {/* <p className="mt-2">Do your best to estimate the percentage of
//                                                     people that you expect to
//                                                     qualify for your survey based on your screening questions. We'll
//                                                     show you the Actual Qualification Rate after we deliver your
//                                                     responses, which can help you set an accurate Qualification Rate for
//                                                     future orders.</p> */}
//                                         </div>
//                                     </div>



//                                     {/* <div className="row">
//                                         <div className=" p-2">
//                                             <h5 className style={{ color: '#F47321' }}>STEP 6: Estimated survey length</h5>
//                                             <div className="" style={{ display: "flex", justifyContent: "space-around" }} >

//                                                 <div className="form-check mb-2">

//                                                     <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => setLoi(2)} value={2} />

//                                                     <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                         Less than 4 mins
//                                                     </label>
//                                                 </div>
                                                
//                                                 <div className="form-check mb-2">

//                                                     <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => setLoi(7)} value={6} />

//                                                     <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                         5 – 7 mins
//                                                     </label>
//                                                 </div>

//                                                 <div className="form-check mb-2">

//                                                     <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => setLoi(10)} value={10} />

//                                                     <label className="form-check-label" htmlFor="flexRadioDefault1">
//                                                         Greater than 7 mins
//                                                     </label>
//                                                 </div>

//                                             </div>
//                                         </div>


//                                     </div> */}


//                                 </div>

//                                     : null}
//                                 {/* step 4 */}
//                                 {/* <div className="row">
//                                     <div className="card">
//                                         <div className="card-body">
//                                             <h5 className="card-title" style={{ color: '#F47321' }}>STEP 4</h5><br />
//                                             <div className="container">
//                                                 <p>Vision Sampling helps you to easily send your survey to the right people.
//                                                     Simply fill out the details below and our Vision Sampling will create a
//                                                     Sampling Project for you. You will be directed to the Sampling Platform to
//                                                     further define your audience to get an estimate and start collecting survey
//                                                     respondents.</p>
//                                                 <div className="row">
                                                  
//                                                     <div className="col divone me-2">
//                                                         <label>&gt;N* = Number of survey completes</label>
//                                                         input
//                                                     </div>
//                                                     <div className="col divone me-2">
//                                                     </div>
//                                                     <div className="col divone me-2">
//                                                     </div>
//                                                     <div className="row mt-4">
//                                                         <span>Country</span>
//                                                         <div className="col-4 divone me-2">
//                                                         </div>
                                                        
//                                                         <div className="col-4 divone">
                                                            
//                                                         </div>
//                                                     </div>
//                                                 </div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div> */}
//                             </div>
//                         </div>
//                     </div>
//                     {/* Total Cost Card */}

//                     {(show == true) ? <div className="col-lg-4  md-0" style={{ marginTop: "120px" }}>
//                         <div className="card" id="">
//                             <div className="card-body">
//                                 {/* <div className="text-left mt-2 card p-2">
//                                     <h5 className style={{ color: '#F47321' }}>Estimated survey length</h5>
//                                     <div className="tan d-flex align-items-center">
//                                         <h6 className="border p-2 text-center mb-0" style={{ width: '50px' }}>10</h6>
//                                         <span className="ms-3 fw-semibold text-secondary fs-6">Survey Questions
//                                             <img src="img" alt="" />
//                                         </span>
//                                     </div>
//                                 </div> */}

//                                 {/* <div className="row">
//                                     <div className="col-6 text-start mb-2">
//                                         <h6>Currently editing </h6>
//                                     </div> */}

//                                 {/* </div>
//                                 <hr className="line" />
//                                 <div className="row">
//                                     <div className="col-6 text-start">
//                                         <h6> Prices per Response </h6>
//                                     </div>
//                                     <div className="col-6 text-end"> Rs. 78.00 </div>
//                                 </div>
//                                 <hr className="line" /> */}

//                                 <div className="row">
//                                     <div className="col">
//                                         <select className="form-control form-select " style={{ width: '100%' }}>
//                                             <option selected>(1-5) Survey Questions</option>
//                                             <option value={1}>(6-10) Survey Questions</option>
//                                             <option value={2}>(11-15) Survey Questions</option>
//                                             <option value={3}>(16-27) Survey Questions</option>
//                                             <option value={2}>(28-39) Survey Questions</option>
//                                             <option value={3}>(40-50) Survey Questions</option>
//                                         </select>
//                                     </div>
//                                 </div><br />

//                                 <div className="row">
//                                     <p style={{ fontWeight: 600, fontSize: 'large' }} className="orangeclr">Targeting Options</p><table className="table table-borderless" style={{ fontWeight: 'light' }}>
//                                         <tbody>
//                                             <tr>
//                                                 <td className="text-start blueclr fw-semibold">Country: {types}</td>
//                                                 {/* <td class="text-end">-</td> */}
//                                             </tr>
//                                             <tr>
//                                                 <td className="text-start blueclr fw-semibold">Regions: {SelectedState}</td>
//                                                 {/* <td class="text-end">-</td> */}
//                                             </tr>
//                                             <tr>
//                                                 <td className="blueclr fw-semibold">Gender: {gender}</td>
//                                                 {/* <td class="text-end">-</td> */}
//                                             </tr>
//                                             <tr>
//                                                 <td className="text-start blueclr fw-semibold">Age: {finalSelectedAge}</td>
//                                                 {/* <td class="text-end">-</td> */}
                                              
//                                             </tr>
//                                             <tr>
//                                                 <td className="text-start blueclr fw-semibold">Household Income: {income}</td>
//                                                 {/* <td class="text-end">-</td> */}
//                                                 {/* Rs. 0-200k + */}
//                                             </tr>
//                                         </tbody>
//                                     </table>
//                                 </div>
//                                 <div className="row">
//                                     {/* <p style={{ fontWeight: 600, fontSize: 'large' }} className="orangeclr"> Balancing Options </p> */}
//                                     <table className="table table-borderless">
//                                         <tbody>
//                                             {/* <tr>
//                                                 <td className="text-start blueclr fw-semibold">Gender Balancing: Census</td>

//                                             </tr>
//                                             <tr>
//                                                 <td className="text-start blueclr fw-semibold">Age Balancing: Basic Census</td>

//                                             </tr>
//                                             <tr>
//                                                 <td className="text-start blueclr fw-semibold">Region Balancing: None</td>

//                                             </tr> */}
                                           
//                                            <tr>
//                                             <td >
//                                              <button class="mainbtn mainbtnblue fadeInUp" onClick={showEstimate}>
//                                                 Calculate Price</button> 
//                                             </td>
//                                                 </tr>
//                                             {submitButton===true&&<tr>
//                                                 <td className="text-start orangeclr fw-bold fs-5">Subtotal</td>
//                                                 <td className="text-start orangeclr fw-bold fs-5">${(cat === "digital") ? finalPrices : (fgdfinalPrices=="NaN")?0.00:fgdfinalPrices}</td>

//                                             </tr>}

//                                         </tbody>
//                                     </table>



//                                     {submitButton===true&& <div className="text-center">
//                                     {/* id="submitModel"  aria-labelledby="exampleModalToggleLabel" */}
//                                             <button class="mainbtn mainbtnblue fadeInUp" data-bs-toggle="modal" href="#submitModel" 
//                                             // target="_self"
//                                             >Submit</button>
//                                     </div>}
                                  


//                                 </div>

//                                 <div className="row">
//                                     <div className="col-2" />
//                                     {/* <div className="col-8">
                                        
//                                         <a href="#" className="bgorangeclr text-decoration-none text-white rounded-1" style={{ padding: '10px 75px' }}>Make Payment</a>
//                                     </div> */}
//                                     <div className="col-2" />
//                                 </div>
//                                 {/*  */}
//                                 {/* Data Quality start */}
//                                 <div className="row mt-4">
//                                     <div className="card">
//                                         <div className="card-body">
//                                             <h5 className="card-title text-center p-2" style={{ color: '#F47321' }}>Data Quality</h5><br />
//                                             <div className="container">
//                                                 <div className="Accuratefrst">
//                                                     <h6>Accurate targeting</h6>
//                                                     <p style={{ fontSize: '15px' }}>We regularly refresh panelist profiles,
//                                                         and
//                                                         you can fine-tune your targeting with balancing and custom screeners.
//                                                     </p>
//                                                 </div>
//                                                 <div className="Accuratefrst mt-4">
//                                                     <h6>Bot detection</h6>
//                                                     <p style={{ fontSize: '15px' }}>We prevent duplicate or fraudulent respondents
//                                                         on your behalf, so you can focus on analyzing your insights rather than
//                                                         quality control.
//                                                     </p>
//                                                 </div>
//                                                 <div className="Accuratefrst mt-4">
//                                                     <h6>Panel calibration</h6>
//                                                     <p style={{ fontSize: '15px' }}>We run ongoing panel calibration studies to
//                                                         ensure our response quality meets internal and industry benchmarks.
//                                                     </p>
//                                                 </div>
//                                             </div>

//                                         </div>
//                                         {/* <div className="Accuratefrst mt-4">
//                                             <h6>Panel calibration</h6>
//                                             <p style={{ fontSize: '15px' }}>We run ongoing panel calibration studies to
//                                                 ensure our response quality meets internal and industry benchmarks.
//                                             </p>
//                                         </div> */}

//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div> : null}
//                 </div>
//             </div>
//             <Footer />

//             {/* //select Country model start */}
//             <div>
//                 <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex={-1}>
//                     <div className="modal-dialog modal-dialog-centered size">
//                         <div className="modal-content">
//                             <div className="modal-header border-0">
//                                 <h1 className="modal-title fs-5 orangeclr fw-normal" id="exampleModalToggleLabel">Country</h1>
//                                 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
//                             </div>
//                             <div className="modal-body ">
//                                 {/* First dropdown start */}
//                                 <div className="firstdropdown mb-4 pt-3">
//                                     {/* <Select isMulti className="form-select" aria-label="Default select example" onChange={(e) => setTypes(e.target.value)} options={!country ? [] : country.map((op, index) => (value:{index},op.lable:{op.type}))} /> */}

//                                     <Select isMulti
//                                         //  values={types}
//                                         placeholder={<div>Search Country</div>}
//                                         aria-label="Default select example"
//                                         onChange={handleSelectedMultipleCountry}
//                                         options={
//                                             country && country.map((op, index) => {
//                                                 op.label = op.type
//                                                 op.value = index
//                                                 return op;
//                                             })

//                                         } />


//                                     {/* <option selected >Search countries</option> */}




//                                 </div>
//                                 {/* First dropdown close */}
//                                 {/* Second drop down start */}
//                                 <div className="Seconddropdown mb-4">

//                                     <Select isMulti
//                                         //  values={types}

//                                         placeholder={<div>Search State</div>}
//                                         onClick={(e) => getState()}
                                       
//                                         aria-label="Default select example"
//                                         onChange={handleSelectedState}
//                                         options={
//                                             state && state.map((op, index) => {
//                                                 op.label = op.type
//                                                 op.value = index
//                                                 return op;
//                                             })

//                                         }
//                                     />
//                                 </div>
//                                 {/* Second drop down close */}
//                                 {/* Second drop down start */}
//                                 <div className="Thirddropdown mb-4">
//                                     <Select isMulti
//                                         //  values={types}

//                                         placeholder={<div>Search City</div>}
//                                         onClick={(e) => getCity()}
                                        
//                                         aria-label="Default select example"
//                                         onChange={handleSelectedcity}
//                                         options={
//                                             city && city.map((op, index) => {
//                                                 op.label = op.type
//                                                 op.value = index
//                                                 return op;
//                                             })

//                                         }
//                                     />
//                                 </div>
//                                 {/* Second drop down close */}
//                                 <div className="btun text-center py-4">
//                                     <button className="btn bgorangeclr text-white bttn" data-bs-dismiss="modal" onClick={(e) => { callAllFunction() }}>Update</button>
//                                 </div>
//                             </div>
//                             {/* <div class="modal-footer">
//                     <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Open
//                         second modal</button>
//                 </div> */}
//                         </div>
//                     </div>
//                 </div>
//                 {/* <a className="btn btn-primary" data-bs-toggle="modal" href="#exampleModalToggle" role="button">first modal</a> */}
//             </div>



//             {/* second model for gendet select */}

//             <div className="modal fade" id="Secondmodal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex={-1}>
//                 <div className="modal-dialog modal-dialog-centered size">
//                     <div className="modal-content">
//                         <div className="modal-header pb-1 pt-2 border-0">
//                             <h1 className="modal-title fs-5 orangeclr fw-normal" id="exampleModalToggleLabel">Gender</h1>
//                             <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
//                         </div>
//                         <div className="modal-body ">
//                             <div className="d-flex justify-content-center mb-4">
//                                 <div className="form-check me-3">
//                                     <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => setGender("Male")} value="Male" />
//                                     <label className="form-check-label fontt" htmlFor="flexRadioDefault1">
//                                         Male
//                                     </label>
//                                 </div>
//                                 {/*  */}
//                                 <div className="form-check me-3">
//                                     <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={(e) => setGender("Female")} value="Female" />
//                                     <label className="form-check-label fontt" htmlFor="flexRadioDefault2">
//                                         Female
//                                     </label>
//                                 </div>
//                                 {/*  */}
//                                 <div className="form-check">
//                                     <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onChange={(e) => setGender("Both")} value="Both" />
//                                     <label className="form-check-label fontt" htmlFor="flexRadioDefault3">
//                                         Both
//                                     </label>
//                                 </div>
//                                 {/*  */}
//                             </div>
                            
//                             <div className="balancing">

//                                 {/* <h6 className="orangeclr mt-2 mb-3">Gender Balancing</h6>
                                
//                                 <div className="d-flex">

//                                     <div className="form-check me-3">
//                                         <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" />
//                                         <label className="form-check-label fontt" htmlFor="flexRadioDefault5">
//                                             None
//                                         </label>
//                                     </div>

//                                     <div className="form-check">
//                                         <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
//                                         <label className="form-check-label fontt" htmlFor="flexRadioDefault6">
//                                             Custom
//                                         </label>
//                                         <h6 style={{ fontSize: '10px', color: 'rgb(192, 192, 192)' }}>At least 50 responses required</h6>
//                                     </div>

                                    
//                                 </div>
//                                 <div className="range-slider">

//                                     <div id="tooltip" />
//                                     <div className="d-flex justify-content-around">
//                                         <h6 className="text-black">Male : <span className="orangeclr"> 51% </span></h6>
//                                         <h6 className="text-black">Male : <span className="orangeclr"> 49% </span></h6>
//                                     </div>
//                                     <input id="range" type="range" step={10} min={0} max={800} />

//                                 </div>*/}
//                             </div>

//                             <div className="btnsec text-center py-4">
//                                 <button className="btn bgorangeclr text-white px-5 py-2" data-bs-dismiss="modal" onClick={(e) => { callAllFunction() }} >Update</button>
//                             </div>
//                         </div>
//                         {/* <div class="modal-footer">
//                     <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Open
//                         second modal</button>
//                 </div> */}
//                     </div>
//                 </div>
//             </div>



//             {/* third model */}
//             <div>
//                 <div className="modal fade" id="exampleage" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex={-1}>
//                     <div className="modal-dialog modal-dialog-centered size">
//                         <div className="modal-content">
//                             <div className="modal-header border-0">
//                                 <h1 className="modal-title fs-5 orangeclr fw-normal" id="exampleModalToggleLabel">Age</h1>
//                                 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
//                             </div>
//                             <div className="modal-body">

//                                 <div className=" checkboxes ps-3">
//                                     <div className="form-check mb-2">
//                                         <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:18 , max:25}" onChange={handleChange} id="flexRadioDefault1020" />
//                                         <label className="form-check-label" htmlFor="flexRadioDefault1020">
//                                             18-25

//                                         </label>
//                                     </div>
//                                     {/*  */}
//                                     <div className="form-check mb-1">
//                                         <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:26 , max:35}" onChange={handleChange} id="flexRadioDefault2030" />
//                                         <label className="form-check-label" htmlFor="flexRadioDefault2030">
//                                             26-35

//                                         </label>
//                                     </div>
//                                     {/*  */}
//                                     <div className="form-check mb-1">
//                                         <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:36 , max:45}" onChange={handleChange} id="flexRadioDefault3040" />
//                                         <label className="form-check-label" htmlFor="flexRadioDefault3040">
//                                             36-45
//                                         </label>
//                                     </div>
//                                     {/*  */}
//                                     <div className="form-check mb-1">
//                                         <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:46 , max:55}" onChange={handleChange} id="flexRadioDefault4040" />
//                                         <label className="form-check-label" htmlFor="flexRadioDefault4040">
//                                             46-55
//                                         </label>
//                                     </div>
//                                     {/*  */}
//                                     <div className="form-check mb-1">
//                                         <input className="form-check-input" type="checkbox" name="flexRadioDefault" value="{min:56 , max:99}" onClick={handleChange} id="flexRadioDefault5040" />
//                                         <label className="form-check-label" htmlFor="flexRadioDefault5040">
//                                             55 +

//                                         </label>
//                                     </div>
//                                     {/*  */}
//                                 </div>
//                                 <webrouk-custom-range start={0} end={1000} from={300} to={700} prefix-char="$">
//                                     {/* This input will receive the value from the component (required) */}
//                                     <input type="hidden" />
//                                 </webrouk-custom-range>
//                                 <div className="btnthr text-center py-4">
//                                     <button className="btn bgorangeclr text-white bttn" data-bs-dismiss="modal" onClick={(e) => { callAllFunction() }} >Update</button>
//                                 </div>
//                             </div>
//                             {/* <div class="modal-footer">
//               <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Open second modal</button>
//             </div> */}
//                         </div>
//                     </div>
//                 </div>
//                 {/* <a className="btn btn-primary" data-bs-toggle="modal" href="#exampleage" role="button">Third  modal</a> */}
//             </div>


//             {/* forth model */}
//             <div class="modal fade" id="AnualModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
//                 tabindex="-1">
//                 <div class="modal-dialog modal-dialog-centered ">
//                     <div class="modal-content">
//                         <div class="modal-header border-0">
//                             <h1 class="modal-title fs-5 orangeclr fw-normal" id="exampleModalToggleLabel">Annual Household
//                                 Income</h1>
//                             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div class="modal-body">
//                             <div class="mand d-flex ">
//                                 <div class="Annualincome pe-4">

//                                     <div class="form-check pb-2">
//                                         <input class="form-check-input" type="checkbox" value="{min:0 max:1250}" onClick={(e)=>setIncome("Less then $ 1250")} onChange={handleIncome} id="flexCheckDefault1" />
//                                         <label class="form-check-label" for="flexCheckDefault1">
//                                             Less then $ 1250
//                                         </label>
//                                     </div>
//                                     {/* <!--  --> */}
//                                     <div class="form-check pb-2">
//                                         <input class="form-check-input" type="checkbox" value="{min:1251 max:2500}" onClick={(e)=>setIncome("$1251 to $2500")} onChange={handleIncome} id="flexCheckDefault2" />
//                                         <label class="form-check-label" for="flexCheckDefault2">
//                                             $1251 to $2500
//                                         </label>
//                                     </div>
//                                     {/* <!--  --> */}
//                                     <div class="form-check pb-2">
//                                         <input class="form-check-input" type="checkbox" value="{min:2501 max:3700}" onClick={(e)=>setIncome("$2501 to 3700")} onChange={handleIncome} id="flexCheckDefault3" />
//                                         <label class="form-check-label" for="flexCheckDefault3">
//                                             $2501 to 3700
//                                         </label>
//                                     </div>
//                                     {/* <!--  --> */}
//                                     <div class="form-check pb-2">
//                                         <input class="form-check-input" type="checkbox" value="{min:3701 max:6100}" onClick={(e)=>setIncome("$3701 to 6100")} onChange={handleIncome} id="flexCheckDefault4" />
//                                         <label class="form-check-label" for="flexCheckDefault4">
//                                             $3701 to 6100
//                                         </label>
//                                     </div>
//                                     {/* <!--  --> */}
//                                     <div class="form-check pb-2">
//                                         <input class="form-check-input" type="checkbox" value="{min:6100 max:8600}" onClick={(e)=>setIncome("$6100 to $8600")} onChange={handleIncome} id="flexCheckDefault5" />
//                                         <label class="form-check-label" for="flexCheckDefault5">
//                                             $6100 to $8600
//                                         </label>
//                                     </div>
//                                     {/* <!--  --> */}
//                                     <div class="form-check pb-2">
//                                         <input class="form-check-input" type="checkbox" value="{min:8600 max:11110}" onClick={(e)=>setIncome("$8600 to $11110")} onChange={handleIncome} id="flexCheckDefaul6" />
//                                         <label class="form-check-label" for="flexCheckDefaul6">
//                                             $8600 to $11110
//                                         </label>
//                                     </div>
//                                     {/* <!--  --> */}
//                                     <div class="form-check pb-2">
//                                         <input class="form-check-input" type="checkbox" value="{min:11111 max:13500}" onClick={(e)=>setIncome("$11111 to $13500")} onChange={handleIncome} id="flexCheckDefault7" />
//                                         <label class="form-check-label" for="flexCheckDefault7">
//                                             $11111 to $13500
//                                         </label>
//                                     </div>
//                                     {/* <!--  --> */}

//                                 </div>

//                                 <div class="rightcheck">

//                                     <div class="form-check pb-2">
//                                         <input class="form-check-input" type="checkbox" value="{min:13501 max:33500}" onClick={(e)=>setIncome("More then $13500")} onChange={handleIncome} id="flexCheckDefault8" />
//                                         <label class="form-check-label" for="flexCheckDefault8">
//                                             More then $13500
//                                         </label>
//                                     </div>


//                                 </div>
//                             </div>
//                             <div class="btnthr text-center pt-3">

//                                 <button class="btn bgorangeclr bttn text-white" data-bs-dismiss="modal" onClick={(e) => { callAllFunction() }} >Update</button>

//                             </div>
//                         </div>
//                         {/* <!-- <div class="modal-footer">
//               <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Open second modal</button>
//             </div> --> */}
//                     </div>
//                 </div>
//             </div>

//             {/* {/* model for telephonic and f-to-f /*} */}


//             {/* <div class="modal fade" id="TelephonicModel" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
//                 tabindex="-1">
//                 <div class="modal-dialog modal-dialog-centered ">
//                     <div class="modal-content">
//                         <div class="modal-header border-0">

//                             <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
//                         </div>
//                         <div class="modal-body">
//                             <div class="mand d-flex ">
//                                 <div class="Annualincome pe-4">

//                                     <h3>Our team will contact you</h3>

//                                 </div>
//                             </div>
//                             <div class="btnthr text-center pt-3">

//                                 <button class="btn bgorangeclr bttn text-white" data-bs-dismiss="modal" >Close</button>

//                             </div>
//                         </div>

//                     </div>
//                 </div>
//             </div> */}

//             <div class="modal fade" id="TelephonicModel" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
//                 <div class="modal-dialog modal-dialog-centered size">
//                     <div class="modal-content">

//                         <div class="modal-body ">

//                             {/* <div class="successimg">

//                         <lottie-player src="../../../../public/images/Group 10985.svg" background="transparent"
//                             speed="1" style={{width: "400px", height: "400px" }} loop autoplay></lottie-player>
//                     </div>
//                     */}

//                             <div class="contents text-center">
//                                 {/* <h4 style={{ color: "#ff6a00" }} class="text-center fs-3">Success</h4> */}
//                                 <p class="w-75 text-center mx-auto" style={{ fontSize: "18px" }}> Thank you for contacing us.<br></br>Our Team Will Contact You soon.</p>

//                             </div>
//                             {/* <div class="goprofile text-center">
//                                 <button class="border-0 text-white px-5 py-2 rounded-1" style={{ backgroundColor: "#ff6a00" }}>Go to Profile</button>

//                             </div> */}





//                         </div>

//                     </div>
//                 </div>
//             </div>
            
//             <div class="modal fade" id="submitModel" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
//                 <div class="modal-dialog modal-dialog-centered size">
//                     <div class="modal-content">

//                         <div class="modal-body ">

//                             {/* <div class="successimg">

//                         <lottie-player src="../../../../public/images/Group 10985.svg" background="transparent"
//                             speed="1" style={{width: "400px", height: "400px" }} loop autoplay></lottie-player>
//                     </div>
//                     */}

//                             <div class="contents text-center">
//                                 {/* <h4 style={{ color: "#ff6a00" }} class="text-center fs-3">Success</h4> */}
//                                 <p class="w-75 text-center mx-auto" style={{ fontSize: "18px" }}> Thank you for sharing Details.<br></br>This is a tentative costing.
//                                  Our research representative will connect you soon.</p>

//                             </div>
//                             {/* <div class="goprofile text-center">
//                                 <button class="border-0 text-white px-5 py-2 rounded-1" style={{ backgroundColor: "#ff6a00" }}>Go to Profile</button>

//                             </div> */}





//                         </div>

//                     </div>
//                 </div>
//             </div>

//         </>

//     )
// };

// export default Estimate;