import React, { Fragment, useState, useEffect, useRef } from 'react';
// import { setLocalDataAsObject, getLocalDataAsObject } from '../../../utils/CoustomStorage';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import API from '../../utils/apiCalling';
import { AvField, AvForm } from 'availity-reactstrap-validation';
// import { validation } from '../../common/configCommon';
// import AvGroup from 'availity-reactstrap-validation/lib/AvGroup';
import validator from "validator";
import Config from '../../config/env'


const ForgetPassword = (props) => {
  // let { isOpen } = props


  const api = new API()
  const form = useRef()
  const [isVerify, setIsVerify] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [sendOtpButton, setSendOtpButton] = useState("SEND OTP")
  const [resendOtpButton, setResendOtpButton] = useState("RESEND OTP")
  const [messageAfterOtp, setMessageAfterOtp] = useState("")
  const [verifiedButton, setVerifiedButton] = useState("Verify")
  const [otpNotCorrect, setOtpNotCorrect] = useState("")
  const [otp, setOtp] = useState("")
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const [modal, setModal] = useState(false)
  const [isOpen, setIsOpen] = useState(props)
  const [buttonClicked, setButtonCLicked] = useState(false)
  const [profile, setProfile] = useState({
    email: '',
    newPassword: '',
    confirmPassword: '',
  })
  React.useEffect(() => {
    setProfile({
      ...profile,
      email: "",
    })

  }, [])


  const sendOtp = async (event) => {
    setButtonCLicked(true)
    let Otpdata = {
      // mobile: profile.mobile,
      email: profile.email.toLowerCase(),
      requestFor: "changePassword"
    }
    if (profile.email) {
      let result = await api.post(`${Config.new.site_api}/client/sendOtpForVerifyEmail`, Otpdata)
      if (result && result.code === 201) {
        toast.warning(result.message)
        return
      }
      toast.success(result.message)
      setOtpSent(true)
      setTimeout(() => {
        setOtpSent("resendOtp")
      }, 3000);
    }
    else {
      toast.warning("Please Enter email")
    }
  }

  const submitOtp = async () => {

    const otpData = {
      email: profile.email.toLowerCase(),
      otp: otp
    }

    let result = await api.post(config.verifyOtpForEmail, otpData)

    if (result.code === 200) {
      toast.success(result.message)
      setIsVerify(true)
      setOtpSent("")
      setOtpNotCorrect("")
      setChangePassword(true)
    } else {
      setVerifiedButton("Verify")
      // setOtpNotCorrect("OTP not correct")
      toast.warning("OTP not match")
    }
  }
  const onChangeHandle = (e) => {
    let { name, value } = e.target

    setProfile({
      ...profile,
      [name]: value
    })
  }
  const updatePasswordSubmit = async () => {

    const otpData = {
      email: profile.email.toLowerCase(),
      otp: otp,
      newPassword: profile.newPassword,
      confirmPassword: profile.confirmPassword
    }


    let result = await api.post(config.forgetPassword, otpData)

    if (result.code === 200) {
      toast.success(result.message)
      setProfile({
        ...profile,
        newPassword: "",
        confirmPassword: "",
      })
      setIsVerify(false)
      setOtpSent(false)
      setOtpNotCorrect("")
      setChangePassword(false)
      setModal(false)
      setTimeout(() => {
        window.location.reload();
      }, 0)

      // return <Redirect to="/dashboard" />
    } else {
      toast.warning(result.message)
    }
  }

  const closeValue = () => {
    setProfile({ email: '', newPassword: '', confirmPassword: '', })
    setOtpSent(false)
    setButtonCLicked(false)
    setOtpNotCorrect("")
    setChangePassword(false)
  }

  function eyeFunction() {
    var x = document.getElementById("eyeButtonFP");
    if (x.type === "password") {
      x.type = "text";
      setTimeout(() => {
        x.type = "password";
      }, 3000);
    } else {
      x.type = "password";
    }
  }


  function eyeFunction1() {
    var x = document.getElementById("eyeButtonFP1");
    if (x.type === "password") {
      x.type = "text";
      setTimeout(() => {
        x.type = "password";
      }, 3000);
    } else {
      x.type = "password";
    }
  }



  return (
    <Fragment>


      <div className="modal fade hide" data-bs-backdrop="static" id="forgetPassword" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">

            {/* <!-- Modal Header --> */}
            <div className="modal-header rounded-0">
              <h6 className="modal-title">Forgot Password</h6>
              
              <button type="button" data-bs-dismiss="modal" onClick={closeValue} className="close"
              >&times;</button>
            </div>

            {/* <!-- Modal body --> */}
            <div className="modal-body p-5">
              <AvForm ref={form}>
                <div className="input-group custom mb-0 miaemail">
                  <div className="input-group-prepend w-100">
                    <span className="input-group-text rounded-0" style={{ width: "40px", height: "36px" }}><i className="fa fa-envelope"></i></span>
                    <AvField required type="email" className="form-control w-100 rounded-0  details-dialog" name="email" disabled={otpSent} onChange={onChangeHandle} value={profile.email} placeholder="Enter Email"
                      validate={{
                        required: {
                          value: buttonClicked ? true : false,
                          errorMessage: "Email is required",
                        },
                        pattern: {
                          value: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                          errorMessage:
                            "Email should be correct",
                        },

                      }} />
                  </div>
                  {!otpSent && otpSent === false &&
                    <div className="col-lg-4">
                      <button className="btn btn-info btn-block mb-4 " disabled={otpSent} onClick={sendOtp}>{sendOtpButton}</button>
                    </div>}
                  {otpSent && otpSent === "resendOtp" &&
                    <div className="w-100 d-flex justify-content-end">
                      <a className="resendOTp mb-4" onClick={sendOtp}>{resendOtpButton}</a>

                      {/* <div className="">
                      <button className="btn btn-info btn-block mb-4" disabled={isVerify} onClick={sendOtp}>{sendOtpButton}</button> */}
                    </div>
                  }
                </div>



                <div className="">
                  <div className="">
                    <div className="">
                      {/* <div className="input-group-prepend">
                          <span className="input-group-text rounded-0" style={{ width: "40px", height: "38px" }}><i className="fa fa-phone"></i></span>
                        </div> */}
                      {/* <AvField type="number" className="form-control rounded-0  details-dialog" name="mobile" disabled={isVerify} onChange={onChangeHandle} placeholder="Enter Mobile..." value={profile.mobile}
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Mobile No. is required",
                            },
                            pattern: {
                              value: validation.mobileno,
                              errorMessage: "Please enter valid Mobile No.",
                            },
                            minLength: {
                              value: 10,
                              errorMessage: "Mobile No. must be minimum 10 digits",
                            },
                            maxLength: {
                              value: 10,
                              errorMessage: "Mobile No. must be maximum 10 digits",
                            },
                          }} /> */}
                    </div>
                  </div>

                  {/* <div className="col-lg-4">
                      <button className="btn btn-info btn-block" disabled={isVerify} onClick={sendOtp}>{sendOtpButton}</button>
                    </div> */}
                </div>
                {otpSent &&
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="input-group custom mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text rounded-0" style={{ width: "40px" }}><i className="fa fa-key"></i></span>
                        </div>
                        <input type="number" className="form-control rounded-0 bg-transparent" onChange={(e) => setOtp(e.target.value)} placeholder="Enter Otp..." value={otp} />
                      </div>
                    </div>

                    <div className="col-lg-12 d-flex justify-content-center">
                      <button className="btn w-25 btn-otpsa btn-block" onClick={submitOtp} disabled={otp.length < 6} >{verifiedButton}</button>
                    </div>
                    <span align="center" color={{ color: "#ff4444" }}>{otpNotCorrect}</span>
                  </div>
                }


              </AvForm>

              <AvForm ref={form} onValidSubmit={updatePasswordSubmit} >
                {changePassword == true &&
                  <>
                    <div className="input-group custom formPsD mb-0">
                      <div className="input-group-prepend text-center">
                        <span className="input-group-text rounded-0" style={{ width: "40px", height: "36px" }}><i className="fa fa-unlock"></i></span>
                      </div>
                      <AvField type="password" className="form-control rounded-0 bg-transparent"  style={{ width: "41vw"}} onChange={onChangeHandle} name="newPassword" placeholder="New Password..." id="eyeButtonFP" value={profile.newPassword}

                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Password is required",
                          },
                          pattern: {
                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            errorMessage:
                              "Password must be at least 8 characters and must contain at least one lower case letter, one upper case letter and one digit and one special character",
                          },
                          maxLength: {
                            value: 15,
                            errorMessage: "Your password must be 15 characters",
                          },
                          minLength: {
                            value: 8,
                            errorMessage:
                              "Your password must be at least 8 characters",
                          },
                        }} />

                      <span className="input-group-text eyePass" style={{ display: 'flex', position: 'absolute', border: 'none', right: '6%', top: '4px', cursor:'pointer' }}  onClick={() => eyeFunction()}><i className="fa fa-eye" /></span>

                    </div>
                    <div className="input-group formPsD custom mb-0">
                      <div className="input-group-prepend text-center">
                        <span className="input-group-text rounded-0" style={{ width: "40px", height: "36px" }}><i className="fa fa-lock"></i></span>

                      </div>

                      <AvField type="password" className="form-control rounded-0 bg-transparent" style={{ width: "41vw"}} onChange={onChangeHandle} name="confirmPassword" id="eyeButtonFP1" placeholder="Confirm New Password..." value={profile.confirmPassword}

                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Password is required",
                          },
                          pattern: {
                            value: /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            errorMessage:
                              "Password must be at least 8 characters and must contain at least one lower case letter, one upper case letter and one digit and one special character",
                          },
                          maxLength: {
                            value: 15,
                            errorMessage: "Your password must be 15 characters",
                          },
                          minLength: {
                            value: 8,
                            errorMessage:
                              "Your password must be at least 8 characters",
                          },

                        }} />

                      <span className="input-group-text eyePass" style={{ display: 'flex', position: 'absolute', top: '4px', right: '6%', border: 'none', cursor:'pointer' }} onClick={() => eyeFunction1()}><i className="fa fa-eye" /></span>

                    </div>



                    <div className="d-flex justify-content-center">
                      <button className="btn w-25 btn-otpsa btn-block" disabled={!isVerify}>Submit</button>
                    </div>
                  </>}
              </AvForm>
            </div>

            {/* <!-- Modal footer --> */}


          </div>

        </div>
      </div>
    </Fragment>
  );
}

export default ForgetPassword;