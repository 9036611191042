import React from 'react'

function ClientServicesCart(props) {
     const { img, heading, description, imgDirection } = props
     return (
          <div className="row pb-5 align-items-center">
               {imgDirection === "left" ?
                    (<div className="col-md-6">
                         <div className="clientpg-img ">
                              <img src={img} alt="haha" />
                         </div>
                    </div>)
                    : ''}
               <div className="col-md-6">
                    <div className="clientpg-content">
                         <h4 className="fw-bold py-2 peragraphHeading">{heading}</h4>
                         <p className="boldcommon-p peragraphFont" dangerouslySetInnerHTML={{ __html: description }} />
                    </div>
               </div>
               {imgDirection === "right" ?
                    (<div className="col-md-6">
                         <div className="clientpg-img ">
                              <img src={img} alt="haha" />
                         </div>
                    </div>)
                    : ''}

          </div>
     )
}

export default ClientServicesCart