import React, { useEffect, useState } from "react";
import NpsEmailShare from "./npsEmailShare";
import NpsSmsShare from "./npsSmsShare";
import { CopyToClipboard } from 'react-copy-to-clipboard';
// import './npsCss.css'

const NpsShare = (props) => {
  console.log(props)
  const [screen, setScreen] = useState(0)
  const [copyStatus, setCopyStatus] = useState(false)
  useEffect(() => {
    props?.screen && changeScreen()
  }, [])

  const changeScreen = (() => {
    props?.screen && setScreen(props?.screen)
  })
  return (
    <>
      {/* <header>
    <nav className="navbar navbar-expand-md navbar-light bg-white shadow-sm">
      <div className="container-fluid">
        <a className="navbar-brand" href="javascript:void(0);">
          <img src="../img/new-logo.png" width={100} />
        </a>
        <div className="menuBoxx">
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <a href="" className="me-4 homeB">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.189"
              height="25.181"
              viewBox="0 0 25.189 25.181"
            >
              <g
                id="Group_25243"
                data-name="Group 25243"
                transform="translate(-225.573 -1117.25)"
              >
                <g
                  id="Icon_ionic-ios-home"
                  data-name="Icon ionic-ios-home"
                  transform="translate(225.573 1117.25)"
                >
                  <path
                    id="Path_17078"
                    data-name="Path 17078"
                    d="M16.907,7.312a.248.248,0,0,0-.313,0l-9.751,7.68a.257.257,0,0,0-.094.191V29.335a.124.124,0,0,0,.125.123h6.751a.124.124,0,0,0,.125-.123V20.72a.124.124,0,0,1,.125-.123h5.75a.124.124,0,0,1,.125.123v8.615a.124.124,0,0,0,.125.123h6.751a.124.124,0,0,0,.125-.123V15.182a.247.247,0,0,0-.094-.191Z"
                    transform="translate(-4.159 -4.278)"
                    fill="#354c9e"
                  />
                  <path
                    id="Path_17079"
                    data-name="Path 17079"
                    d="M28.235,11.8l-11.44-8.17a1.457,1.457,0,0,0-1.659,0l-4.982,3.61V4.967a.115.115,0,0,0-.121-.108H6.4a.115.115,0,0,0-.121.108V9.942L3.7,11.836a.739.739,0,0,0-.321.55.72.72,0,0,0,.248.577.9.9,0,0,0,.6.221.92.92,0,0,0,.533-.167L15.887,5.032a.118.118,0,0,1,.079-.022.16.16,0,0,1,.079.022l11.132,7.954a.92.92,0,0,0,.533.167.892.892,0,0,0,.6-.221.707.707,0,0,0,.254-.577A.746.746,0,0,0,28.235,11.8Z"
                    transform="translate(-3.374 -3.375)"
                    fill="#354c9e"
                  />
                </g>
              </g>
            </svg>
          </a>
          <div className="navbar-collapse collapse" id="navbarSupportedContent">
            <ul className="navbar-nav menu_top_link w-100">
              <li>
                <a href="javascript:void(0);" className="text-center d-block">
                  <span>Create Survey</span>
                </a>
              </li>
              <li>
                <a href="share.html" className="text-center d-block active">
                  <span>Share</span>
                </a>
              </li>
              <li>
                <a href="report-3.html" className="text-center d-block ">
                  <span>Report</span>
                </a>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto right-side-menu">
              <li>
                <a href="javascript:void(0);" className="">
                  Survey
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className="">
                  Admin
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0);"
                  className="position-relative"
                  data-bs-toggle="dropdown"
                >
                  Notification
                  <span
                    className="badge bg-danger position-absolute"
                    style={{ top: "-10px" }}
                  >
                    3
                  </span>
                </a>
                <div
                  className="dropdown"
                  style={{ position: "absolute", right: 416, marginTop: 10 }}
                >
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        <i className="bi bi-chat-left-fill" /> Kamikaze Day 1
                        survey created
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <i className="bi bi-chat-left-fill" /> Kamikaze Day 2
                        survey created
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        <i className="bi bi-file-earmark-text-fill" /> New
                        report available
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="profile-setting.html" className="userprofile">
                  <img src="../img/profile.png" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className="toggleTheme">
                  <img src="../img/top-toggle.png" />
                  <img src="../img/top-toggle-dark.png" />
                </a>
              </li>
              <li>
                <a href="javascript:void(0);" className="logoutUser">
                  <i className="fa fa-sign-out" aria-hidden="true" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header> */}
      {screen == 0 && <main className="mainSec">
        <section className="main-dash-sec py-3">
          <div className="container">
            <div className="row bg-white box_shdow p-3">
              <div className="col-md-8 text-center mx-auto ">
                <h5 className="text-black text-center py-3 text-center fw-bold fs-5">
                  How would you like to survey people?
                </h5>
                <div className="px-md-5 mb-4">
                  <div className="input-group " style={{ height: 52 }}>
                    <input
                      type="text"
                      className="form-control fontsi14"
                      style={{
                        borderRadius: "2px 0px 0px 2px", height: 52, backgroundColor: "#e9ecef"
                      }}
                      placeholder="https://surveycxm.com/"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      defaultValue=""
                      disabled
                    />
                    <CopyToClipboard text={'referCode'}
                      onCopy={() => setCopyStatus(true)}>
                      {copyStatus !== true ?
                        <button className="input-group-text blue-bg-clr text-white px-4 clipboard"
                          style={{ borderRadius: "0px 0px 0px 0px" }} type="button" id="button-addon2">Copy URL</button>
                        :
                        <button className="input-group-text blue-bg-clr text-white px-4 clipboard"
                          style={{ borderRadius: "0px 0px 0px 0px" }} type="button" id="button-addon2">URL Copied</button>
                      }
                    </CopyToClipboard>
                    {/* <button
                      className="input-group-text blue-bg-clr text-white px-4 clipboard"
                      id="clipboard"
                      style={{ borderRadius: "0px 0px 0px 0px" }}
                    >
                      Copy URL
                    </button> */}
                  </div>
                  <div className="text-start my-2">
                    <p
                      className="fontsi14 text-gr-clr my-2"
                      style={{ textAlign: "end", marginRight: 17 }}
                    ></p>
                    <span className="fontsi14 text-gr-clr my-2">
                      Looking for the full URL?{" "}
                      <span
                        type="button"
                        className="clipboard"
                        style={{ color: "blue" }}
                      >
                        Click here
                      </span>{" "}
                      to copy
                    </span>
                  </div>
                </div>
                <div className="row share-box">
                  <div className="col-md-4 col-xl-3 col-6" onClick={() => setScreen(1)}>
                    <a className="text-decoration-none">
                      <div className="py-4 border shr-bx box-shad-light">
                        <div className="imgbox">
                          <img src="../img/Icon zocial-email.svg" alt="" />
                        </div>
                        <h6 className="text-black pt-sm-5 pt-3 fontsi16">Email</h6>
                      </div>
                    </a>
                  </div>
                  {/*  */}
                  <div className="col-md-4 col-xl-3 col-6 " onClick={() => setScreen(2)}>
                    <a className="text-decoration-none">
                      <div className="py-4 border shr-bx box-shad-light">
                        <div className="imgbox">
                          <img src="../img/Icon material-sms.svg" alt="" />
                        </div>
                        <h6 className="text-black pt-sm-5 pt-3 fontsi16">SMS</h6>
                      </div>
                    </a>
                  </div>
                  {/*  */}
                  <div className="col-md-4 col-xl-3 col-6 ">
                    <a
                      href="#"
                      data-bs-target="#exampleModalToggleQR"
                      data-bs-toggle="modal"
                      className="text-decoration-none "
                    >
                      <div className="py-4 border shr-bx box-shad-light">
                        <input
                          type="hidden"
                          name="hidden_survey_id"
                          id="hidden_survey_id"
                          defaultValue=""
                        />
                        <div className="imgbox">
                          <img src="../img/Icon metro-qrcode.svg" alt="QR Code" />
                        </div>
                        <h6 className="text-black pt-sm-5 pt-3 fontsi16">
                          QR Code
                        </h6>
                      </div>
                    </a>
                  </div>
                  {/*  */}
                  <div className="col-md-4 col-xl-3 col-6 ">
                    <a href="#" className="text-decoration-none">
                      <div className="py-4 border shr-bx box-shad-light">
                        <div className="imgbox">
                          <img src="../img/Icon ionic-logo-whatsapp.svg" alt="" />
                        </div>
                        <h6 className="text-black pt-sm-5 pt-3 fontsi16">
                          Whatsapp
                        </h6>
                      </div>
                    </a>
                  </div>
                  {/*  */}
                  <div className="col-md-4 col-xl-3 col-6 ">
                    <a href="#" className="text-decoration-none">
                      <div className="py-4 border shr-bx box-shad-light">
                        <div className="imgbox">
                          <img src="../img/Icon awesome-linkedin (2).svg" alt="" />
                        </div>
                        <h6 className="text-black pt-sm-5 pt-3 fontsi16">
                          Linkedin
                        </h6>
                      </div>
                    </a>
                  </div>
                  {/*  */}
                  <div className="col-md-4 col-xl-3 col-6 ">
                    <a href="#" className="text-decoration-none">
                      <div className="py-4 border shr-bx box-shad-light">
                        <div className="imgbox">
                          <img src="../img/Icon awesome-facebook-square.svg" alt="" />
                        </div>
                        <h6 className="text-black pt-sm-5 pt-3 fontsi16">
                          Facebook
                        </h6>
                      </div>
                    </a>
                  </div>
                  {/*  */}
                  <div className="col-md-4 col-xl-3 col-6 ">
                    <a href="#" className="text-decoration-none">
                      <div className="py-4 border shr-bx box-shad-light">
                        <div className="imgbox">
                          <img src="../img/Icon ionic-logo-twitter.svg" alt="" />
                        </div>
                        <h6 className="text-black pt-sm-5 pt-3 fontsi16">
                          Twitter
                        </h6>
                      </div>
                    </a>
                  </div>
                  {/*  */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* MOdal QR Scanner Start  */}
        <div
          className="modal fade"
          id="exampleModalToggleQR"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered QRC">
            <div className="modal-content">
              <div className="modal-header border-0">
                <h1
                  className="modal-title fs-6 blue-clr fw-semibold"
                  id="exampleModalToggleLabel"
                >
                  Share by QR Code
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div
                className="modal-body pt-2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <div className="btunns">
                  <button className="blue-bg-clr w-100 py-2 text-white border-0 rounded-1 d-flex align-items-center justify-content-center">
                    <img
                      src="../img/dfda40b11a8c8c217be11e42ba2a0aaa.svg"
                      alt="Icon"
                      width="12px"
                    />
                    &nbsp;Share
                  </button>
                  <a href="">
                    <button className="org-bg-clr w-100 py-2 text-white rounded-1 border-0 mt-2 d-flex align-items-center justify-content-center ">
                      <img src="../img/Layer 2 (2).svg" alt="Icon" width="15px" />
                      &nbsp;Download QR Code
                    </button>
                  </a>
                </div>
                <a href=""></a>
              </div>
              <a href=""></a>
            </div>
            <a href=""></a>
          </div>
          <a href=""></a>
        </div>
        <a href=""></a>
      </main>}
      {/* <a href=""></a> */}
      {screen === 1 && <NpsEmailShare screen={screen} />}
      {screen === 2 && <NpsSmsShare screen={screen} surveyData={props}  />}
    </>

  )
}
export default NpsShare;