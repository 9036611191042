import React, { useState, useEffect, useRef } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
// import { config } from '../../utils/apiUrl';
// import { toast } from 'react-toastify';
// import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
// import RewardUseModal from '../common/rewardUserModal'
// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link } from "react-router-dom";
// import Loader2 from '../../Components/common/loader2/loader'
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CSVLink } from "react-csv";
// import json2csv from "json2csv";

const ViewProfileSingleSurveyRecord = (props) => {
    const api = new API()

    const csvLink = useRef()
    let { goBack, singleUserProfileSurveyRecord } = props

    const [viewSurvey, setViewSurvey] = useState(false)
    const [viewSurveyId, setViewSurveyId] = useState()
    const [downloadSurvey, setDownloadSurvey] = useState([])
    const [generate, setGenerate] = useState(false)
    const [excelFileName, setExcelFileName] = useState("")
    useEffect(() => {
       
    }, [])

    const viewSurveybtn = (id) => {
        setViewSurvey(!viewSurvey)
        setViewSurveyId(id)
    }
    const downloadSurveyExcel = (survey) => {
     
        setExcelFileName(survey.taskName)
        let result = survey.data.filter((sur) => {
            let opArr = []
            sur.options.filter(op => {
                opArr.push(op && op.option)
              
            })

            sur.options = opArr;
            // let newArr = sur +=
            // setDownloadSurvey([...downloadSurvey, newArr])
            return sur
        })
        setDownloadSurvey(result)
       
        setDownloadSurvey(result)
        setViewSurveyId(survey._id)
        setGenerate(true)
    }
    return (
        <div>
            <div className="container mt-4">


                <div className="card mb-5" >
                    <div className="card-header" style={{ backgroundColor: "gray" }}>
                        <h6 className="text-white"> User Email : {singleUserProfileSurveyRecord.user} </h6>
                    </div>
                    <div className="card-body" style={{ borderTop: "1px solid" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <h5>Total Survey UserAttended : <span style={{ color: "red" }}></span></h5>

                                <div className="form-group">
                                    {singleUserProfileSurveyRecord.profileTaskStatus.map((profileSurvey, index) => (
                                        <div className="mt-3">
                                            <div className="card">
                                                <div className="card-header">
                                                <label style={{textTransform: "capitalize"}}>{index + 1}: {profileSurvey.taskName}</label>
                                                </div>
                                                <div className="card card-body">
                                                <div className="form-group">

                                                
                                                <label className="col-md-5">Profile Survey Status : {profileSurvey.status == false ? <span style={{color:"red"}}>Pending</span> : <span style={{color:"blue"}}>Completed</span>}</label>
                                                
                                               
                                                <button onClick={() => viewSurveybtn(profileSurvey._id)} disabled={profileSurvey.status == false} className="btn btn-secondary ml-3">view</button>
                                                {generate && viewSurveyId == profileSurvey._id ?
                                                    <CSVLink color="black" data={downloadSurvey}
                                                        filename={singleUserProfileSurveyRecord.user+"="+excelFileName + ".csv"} ref={csvLink}>
                                                        <button disabled={profileSurvey.status == false} className="btn btn-success ml-3">Excel Download</button>
                                                    </CSVLink>
                                                    :
                                                    <button disabled={profileSurvey.status == false} onClick={() => downloadSurveyExcel(profileSurvey)} className="btn btn-secondary ml-3">Generate Excel</button>
                                                }
                                                </div>
                                                </div>
                                            </div>

                                            {viewSurvey == true && viewSurveyId == profileSurvey._id &&

                                                <div>
                                                    {profileSurvey.data.map((question, ind) => (
                                                        <div className="container">


                                                            <div className="card" style={{ margin: "50px" }}>
                                                                <div className="card card-header">
                                                                    <h5>{ind + 1}: {question.questions}</h5>
                                                                </div>
                                                                <div className="card card-body">
                                                                    {question.options.map((op, i) => (
                                                                        <div>
                                                                            <p>{i + 1}: {op && op.option}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="card card-footer">
                                                                    <span>Answer : {question.ans.length > 0 && question.ans.map((ans, indexx) => (
                                                                        <span> { ans},  </span>
                                                                    ))}</span>
                                                                    <h6> <span style={{ color: "red" }}>Free text : </span> {question.text_box}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            }
                                        </div>
                                    ))}
                                </div>


                                <div>
                                    <button onClick={goBack} className="btn btn-secondary">GoBack</button>
                                </div>
                            </div>
                            <div className="col-md-6 item-center">
                                {/* <center><button onClick={() => deleteUser(userSurveyRecord.email)} className="btn btn-danger btn-block col-md-6 text-white">Delete User</button></center> */}
                                {/* <center><button onClick={() => getUserAllRedeem(rewarduser, "pendingRedeem")} data-toggle="modal" data-target="#rewardUserModal" className="btn btn-link btn-block col-md-6 mt-3 text-white">Approve One by One Pending</button></center> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="card-footer">
                               
                            </div> */}

                </div>


            </div>

        </div>
    );
}

export default ViewProfileSingleSurveyRecord;