import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling';

import Loader2 from '../../utils/loader2/loader2'
const ShowSurveyModal = (props) => {
    const api = new API()
    const { survey_View, surveyViewQuestions } = props

    const [loader2, setLoader2] = useState(true)
    const [test, setTest] = useState(true)

    let viewsurveydata = []
    useEffect(() => {
        //  setSurveyViewQuestion(survey_View.questions)
   
    })


    return (
        <div>

            <div className="modal fade" id="showSurveyModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ minHeight: "450px" }}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">View Survey</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form>
                                {test == false ?
                                    <div>
                                        <Loader2 />
                                    </div>
                                    :
                                    <>
                                        <div className="container">
                                            <div className="card">
                                                <div className="card-header">
                                                    <h4><span>Survey Name :</span>{survey_View.survey_name}</h4>
                                                </div>
                                                <div className="card-body">
                                                    <h5><span>Survey Id :</span>{survey_View._id}</h5>
                                                    <h5><span>Survey Points :</span>{survey_View.points}</h5>
                                                    <h5><span>Survey Status :</span>{survey_View.status}</h5>
                                                    <h5><span>Survey Created Date :</span>{survey_View.date}</h5>
                                                </div>
                                                <div className="container">


                                                    {surveyViewQuestions.length > 0 && surveyViewQuestions.map((surveyview, index) => (
                                                        
                                                        <div className="card">
                                                           
                                                            <div className="card-header">
                                                                <h4>question {index + 1}: {surveyview.question}</h4>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="container">
                                                                    {(surveyview.options).map((op, indexOp) => (
                                                                        <div className="container">
                                                                            <table class="table col-md-6">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>option {indexOp + 1}</th>
                                                                                        <th>{op.option}</th>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                            {/* <span> option {indexOp+1} : {op.option} </span> */}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>


                                        </div>
                                    </>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
}

export default ShowSurveyModal;