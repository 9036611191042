import React, { useEffect, useState } from 'react'
import API from '../../utils/apiCalling';
import Config from '../../config/env';

export default function StudentCollegeID() {
  const api = new API()
  const [Approved, setApproved] = useState(null)
  const [studentData, setStudentData] = useState([])



  useEffect(() => {
    studentIds();
  }, [])

  const studentIds = async () => {
    let result = await fetch(`${Config.new.site_api}/client-survey/getStudentImage`, {
      method: 'get',
      headers: { 'content-Type': 'application/json' },
    });
    result = await result.json();

    if (result && result.code === 200) {
      setStudentData(result.data)

    } else {
      alert(result.message)
    }
  }
  const studentVerify = async (item,Approved) => {

    let data = {
      email: item.email,
      isApproval:Approved

    }
  
    let result = await fetch(`${Config.new.site_api}/client-survey/verifyStudentId`, {
      method: 'post',
      body: JSON.stringify(data),
      headers: { 'content-Type': 'application/json' },
    });
    result = await result.json();

    if (result && result.code === 200) {
      setStudentData(studentData&&studentData)
    
    } else {
      alert(result.message)
    }
  }
  return (
    <>
      <div className='row'>
        <h3 className='IdHeader'>  Student's College ID</h3>
        {studentData && studentData.length > 0 && studentData.map((item) => (
          <div className=' ContentTap1'>

            <div class="wrapper">
              <div class="card mb-3">
                <img height="200px" src={item&&item.Image} alt="" />
                <div id="container">
                  <div className='d-flex mt-2'>
                    <span className=''>Email:</span>
                    <p id="exp">{item.email}</p>
                  </div>
                  <div className='d-flex'>
                    {(item.isApproval===1||item.isApproval===0)?

                   <div  className={item.isApproval==1?'text-success approve':'text-danger approve'}>
                   {item.isApproval==1?"Approved":"Rejected"}
                   </div>
                   :
                   <>
                   <button onClick={(e) => studentVerify(item,Approved==null?1:0)} className='mr-3 btn' id="btn">Approved</button>
                   <button onClick={(e) => studentVerify(item,Approved==null?0:0)} id="button">Rejected</button>
                 </>
                    }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}

      </div>
    </>


  )
}
