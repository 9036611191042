import {v4 as uuid} from "uuid";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import API from '../utils/apiCalling'
import { toast, ToastContainer } from 'react-toastify';
import Config from '../config/env'

function ClientSurvey(props) {
    const {survey} = props
    console.log("Hello", survey)
    const api =new API()
    const [newUuid, setUuid] = useState()
    const history = new useHistory()
    const [surveyStartTime, setSurveyStartTime] = useState('')

    useEffect(() => {
        let uniqieId = uuid()
        setUuid(uniqieId)
        console.log(uniqieId, "uuid.v4()")
        // startSurvey(uniqieId)

        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const milliseconds = now.getMilliseconds();

        var surveyStartTime = (hours * 60 * 60 + minutes * 60 + seconds) * 1000 + milliseconds
        setSurveyStartTime(surveyStartTime)
    }, [])
    const startSurvey = async(id)=>{
        try {
            let data ={
                response_id:id,
                Respondent_router_entry_time:new Date(),
                Session_entry_time:new Date(),
                client_name:'',
                supplier_name:'',
                Complete_time:""
            }
        await api.post(`${Config.new.site_api}/client-survey/rederictResponse`, data)
        } catch (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
   
    return (
        <>
        <div className="App"
            style={{ height: 'calc(100vh - 20px)' }}>
            
            <iframe src={survey.url} height="100%" width="100%" title="Iframe Example"></iframe>
            <ToastContainer />
        </div>
        </>
    );
}
export default ClientSurvey;