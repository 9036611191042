import React, { useState, useEffect } from 'react';

import API from '../../utils/apiCalling';


const SendBySmsModal = (props) => {
    const api = new API()
    const {  } = props

    const [loader2, setLoader2] = useState(true)

    

    useEffect(() => {

    },[]);

 
  
    return (
        <div>
            <div className="modal fade" id="sendBySmsModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ minHeight: "450px" }}>
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Survey Link Send by SMS</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form>
                    
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
}

export default SendBySmsModal;