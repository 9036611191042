import React from 'react'
// import { message, Slider } from 'antd';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../utils/apiCalling';
import { useHistory } from 'react-router-dom';
import Loader from '../utils/loader';
import ReactPlayer from 'react-player/file';
import ReactAudioPlayer from 'react-audio-player';
import Config from '../config/env'
import { useSpeechSynthesis } from 'react-speech-kit'
import {v4 as uuid} from "uuid";

function SurveyScreen(props) {
    const { survey, setSurveyScreen, type, profilePercent, getAvailableSurveys, pointsPercent } = props;

    const { speak, voices } = useSpeechSynthesis()
    const [surveyQues, setSurveyQues] = useState(survey.type === "matrix" ? survey.questions[0] : survey.questions)
    const [displayKeys, setDisplayKeys] = useState({ question: "question", option: "option", que_mesage: "que_message" })
    const [quesNo, setQuesNo] = useState(0)
    const [user, setUserData] = useState("");
    const [button, setButton] = useState(false)
    const [answer, setTemporaryAns] = useState([])
    var [checkboxCount, setCount] = useState(0);
    const [progress, setProgress] = useState(0)
    const history = new useHistory();
    const [eventRadio, setEvent] = useState("");
    const [eventText, setEventText] = useState("");
    const [eventCheckbox, setEventCheckbox] = useState([])
    const [text_box, setTextBox] = useState(false)
    const [surveyStutas, setSurveyStutas] = useState()
    const [eventMatrix, setEventMatrix] = useState([])
    const [eventForm, setEventForm] = useState([])
    const [radioOption, setRadioOption] = useState("");
    const [loader, setLoader] = useState(false)
    const [savedAns, setSavedAns] = useState(['pnb', "others"])
    const [actionId, setActionId] = useState([])
    const [previousQue, setPreveiousQue] = useState()
    const [doubleDrop, setDoubleDrop] = useState(["", ""])
    const [language, setLanguage] = useState([]);
    const [selectedLang, setSelectedLang] = useState('en')
    const [lngCode, setLngCode] = useState(' ')
    const [checkBoxIndex, setCheckBoxIndex] = useState([])
    const [surveyStartTime, setSurveyStartTime] = useState('')
    const [starClass, setStarClass] = useState()
    const [audioPause, setAudioPause] = useState("onPause")
    const [ratingValue, setRatingValue] = useState(0)
    const [matHeader, setMatHeader] = useState([])
    const player = useRef();


    const changeOPSequence = arr => [...arr].sort(() => Math.random() - 0.5);

    const api = new API
    const goToNextQuestion = (question, option) => {



        if (type == 'profile') {

            if (quesNo + 1 === surveyQues.length) {
                saveResponses("complete")
            } else {
                setQuesNo(quesNo + 1)
            }
            deSelectAnswer(question)
            return
        }
        setCount(0)
        let finalAns = answer
        if (question.type == 'matrix' && question.selection == 'single') {
            let tempAns = ""
            answer.forEach(ans => {
                tempAns = `${tempAns}field:${ans.bank}-Answer:${ans.answer},`
            })
            finalAns = [tempAns]
        }
        else if (question.note_que == 'next_note_que') {
            setButton(false)
            setQuesNo(quesNo + 1)
            return
        }
        else if (question.type == 'matrix' && question.selection == 'multiple') {
            let tempAns = ""

            answer.forEach(ans => {
                let matAns = ""
                ans.answer.forEach(an => {
                    matAns = `${matAns} ${an},`
                })
                tempAns = `${tempAns}field:${ans.bank}-Answer:${matAns},`
            })
            finalAns = [tempAns]
        }
        else if (question.type == 'form' || question.type == 'contact') {
            let tempAns = "";
            let fields = Object.keys(finalAns[0])
            fields.forEach(f => {
                tempAns = `${tempAns}${f}:${finalAns[0][f]},`
            })
            finalAns = tempAns
        } else if (question.type == 'rating') {
            setQuesNo(quesNo + 1)
        }
        if (question.type == 'radio' || question.type == 'checkbox') {
            question.options.forEach(option => {
                console.log(option, 'option')
                if (option.textOpen === true) { option.textOpen = false }
            })
        }




        question.answer = finalAns;

        let finalActionId = actionId
        if (question.selection == "multiple") {

            question.options.forEach((op) => {
                if (op.trigger && actionId.includes(op.action_id)) {
                    finalActionId = [op.action_id]

                }
            })
        }
        setTemporaryAns([]);
        if (quesNo + 1 === surveyQues.length || finalActionId.includes('final')) {
            // setSurveys(survey.questions)

            setSurveyStutas('complete')
            saveResponses('complete');
        }
        if (finalActionId.includes("exit")) {

            saveResponses('uncomplete')
        }
        else {
            if (finalActionId.length) {
                for (let i = 0; i < surveyQues.length; i++) {
                    if (finalActionId.includes(surveyQues[i]._id)) {
                        setPreveiousQue(quesNo)
                        setQuesNo(i);
                        break
                    }
                }
            }
            else {
                setPreveiousQue(quesNo)
                setQuesNo(quesNo + 1);
            }
            setButton(false)
            if (question.que_message === "note-que") {
                setButton(true)
            }
        }
        deSelectAnswer(question)
        if (question.dependency && question.dependency == 'yes') {

            setSavedAns(answer);
        }

        setTextBox(false)
        setActionId([])
        setRadioOption("")
        setDoubleDrop([])
        setRatingValue(0)
        setCheckBoxIndex([])
    }
    const updateCount = async () => {
        setLoader(true)
        survey.questions.map((ans) => (
            ans.answer && delete ans.answer
        ))

        // const result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(survey) })
        setLoader(false)
    }

    const deSelectAnswer = (question) => {
        if (question.type == 'radio') {
            eventRadio.target.checked = false
        } else if (question.type == 'checkbox') {
            eventCheckbox.forEach((eve) => {
                eve.target.checked = false;
            })
        }
        else if (question.type == 'matrix') {
            eventMatrix.forEach((eve) => {
                eve.target.checked = false;
            })
        }
        else if (question.type == 'text') {
            eventText.target.value = ""
        }
        else if (question.type == 'matrix') {

            eventRadio.target.checked = false

        }
        else if (question.type == 'form' || question.type == 'contact') {
            eventForm.forEach((eve) => {
                eve.target.value = "";
            })
        }
    }

    const saveProfile = async (user) => {
        setLoader(true)
        const data = {
            email: user.email,
            type: survey.survey_name,
            attempQuestionTask: JSON.stringify(surveyQues),
            profileCompletionPoint: 0,
            newPercentage: profilePercent + 10
        }
        const result = await api.post(`${Config.new.site_api}/user-panel-profile/userProfileTaskSubmit`, data);
        if (result) {
            setTimeout(() => {
                if (result.code === 200) {
                    setLoader(false)
                    setSurveyScreen(false)
                    toast.success('Profile Saved Succesfully', {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    window.location.reload();
                } else {
                    toast.error(result.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            }, 100)
        }

    }

    const saveResponses = async (status) => {

        var now = new Date();
        var hours = now.getHours();
        var minutes = now.getMinutes();
        var seconds = now.getSeconds();
        var milliseconds = now.getMilliseconds();

        var timeString = (hours * 60 * 60 + minutes * 60 + seconds) * 1000 + milliseconds



        var totalSeconds = (timeString - surveyStartTime) / 1000



        let user = JSON.parse(localStorage.getItem('user'))

        if (type == 'profile') {
            saveProfile(user)

        }
        else {
            let data = {
                compltedSurveyNumber: 1,
                pointAdd: survey.points ? (survey.points * pointsPercent.active) / 100 : 0,
                email: user.email,
                surveyName: survey.survey_name,
                surveyId: survey._id ? survey._id : '',
                surveyType: survey.survey_type,
                surveyStatus: status,
                responseLimitName: "myntra",
                trackSharePlatform: "manual",
                questions: JSON.stringify(surveyQues),
                loi: totalSeconds
            }
            // setSurveyScreen(false)


            let result = await api.post(`${Config.new.site_api}/survey/SubmitSurvey`, data);

            if (result.code === 200) {
                let data2 = {
                    surveyId: survey._id ? survey._id : '',
                    email: user.email,
                };
                // setLoader(false)

                // setLoader(true)
                let pointDistribution = await api.post(`${Config.new.site_api}/survey/SurveyPointDistribution`, data2);
                toast.success("Survey submitted")
                updateCount()
                // getAvailableSurveys()
                setSurveyScreen(false)
            } else {
                setSurveyScreen(false)
                toast.error(result.message)
            }
            setLoader(false)
        }
    }
    // var getQuestion=(e)=>{

    // }

    useEffect(() => {

        let userData = JSON.parse(localStorage.getItem('user'));
        setUserData(userData);


        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const milliseconds = now.getMilliseconds();

        var surveyStartTime = (hours * 60 * 60 + minutes * 60 + seconds) * 1000 + milliseconds
        setSurveyStartTime(surveyStartTime)
        if (survey.is_client == 1) {
            let uniqieId = uuid()
            startSurvey(uniqieId)
        }
    }, [eventText, button])

    const startSurvey = async (id) => {
        try {
            let data = {
                response_id: id,
                surveyId: survey._id,
                Respondent_router_entry_time: new Date(),
                Session_entry_time: new Date(),
                User_Id: survey.User_Id,
                supplier_name: '',
                Complete_time: ""
            }
            await api.post(`${Config.new.site_api}/client-survey/rederictResponse`, data)
        } catch (error) {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    // const setAnswer = (event, type, option, question, selectedColumn) => {
    //     if (type === 'rating') {
    //         setStarClass(event)
    //         setButton(true)
    //         setRatingValue(event)
    //         setTemporaryAns([...answer, event])
    //         return
    //     }

    //     let actId = [...actionId]
    //     if (option.action_id != "") {
    //         if (type == 'video' || event.target.checked || type == 'form' || type == 'text' || type == 'contact') {
    //             if (type === "radio" && actId.length > 0) {
    //                 actId = []
    //                 if (option.action_id && option.action_id != "") {
    //                     actId.push(option.action_id)
    //                 }

    //             } else {
    //                 if (option.action_id && option.action_id != "") {
    //                     actId.push(option.action_id)
    //                 }


    //             }
    //         } else {
    //             if (option.trigger && option.action_id && option.action_id !== "") {

    //                 let index = actId.indexOf(option.action_id)
    //                 actId.splice(index, 1)
    //             } else if (option.action_id && option.action_id !== "") {
    //                 let index = actId.indexOf(option.action_id)
    //                 actId.splice(index, 1)

    //             }
    //         }
    //     }
    //     setActionId(actId)
    //     if (option.count_limit && (option.count || option.count == 0)) {
    //         if (option.count >= option.count_limit) {
    //             setActionId("exit")
    //         } else {
    //             option.count += 1

    //         }
    //     }

    //     if (type == "radio") {
    //         setEvent(event)
    //     } else if (type === "checkbox") {
    //         eventCheckbox.push(event);
    //         setEventCheckbox(eventCheckbox)
    //     }
    //     else if (type === "matrix") {
    //         eventMatrix.push(event);
    //         setEventMatrix(eventMatrix)
    //     }
    //     else if (type === "form" || type == 'contact') {
    //         eventForm.push(event)
    //         setEventForm(eventForm)
    //     }
    //     else {

    //         setEventText(event)
    //     }

    //     let ans = []

    //     if (type == 'checkbox' || question?.type == 'checkbox') {
    //         if (option && option.text_box == 'yes') {

    //             option.textOpen = true
    //             setTextBox(true)
    //             let { value, checked } = event.target;
    //             let ansIndex = [...checkBoxIndex]

    //             setButton(false)
    //             if (value.trim() == "" || (checked == true) || value == null || value == undefined) {

    //                 if (!ansIndex.includes(option.option)) {
    //                     ansIndex.push(option.option)
    //                 }
    //                 console.log(ansIndex)
    //                 setCheckBoxIndex(ansIndex)
    //                 let ans = [...answer]
    //                 ans.push(event.target.value)
    //                 setTemporaryAns(ans)
    //                 setButton(false)
    //             }
    //             else if (checked == false) {

    //                 answer.splice(ansIndex.indexOf(option.option), 1);

    //                 ansIndex.splice(ansIndex.indexOf(option.option), 1)

    //                 setCheckBoxIndex(ansIndex)
    //                 option.textOpen = false
    //             }
    //         }
    //         else {
    //             if (answer.includes(event.target.value)) {

    //                 let index = answer.indexOf(event.target.value)
    //                 answer.splice(index, 1);
    //             }
    //             else {

    //                 setTemporaryAns([...answer, event.target.value])
    //             }
    //         }
    //     }
    //     else if (type == 'matrix') {


    //         if (question.selection == 'single') {

    //             let index = 0
    //             let flag = false;
    //             for (let i = 0; i < answer.length; i++) {
    //                 if (answer[i].bank == selectedColumn) {
    //                     index = i;
    //                     flag = true;
    //                     break
    //                 }
    //             }

    //             if (flag) {
    //                 answer.splice(index, 1);
    //                 flag = false
    //             }
    //             let ans = { bank: selectedColumn, answer: event.target.value }


    //             if (answer.length + 1 != question.options.length) {
    //                 setButton(false)

    //             } else {

    //                 setButton(true)
    //             }
    //             setTemporaryAns([...answer, ans])

    //         }
    //         else {
    //             let sameIndex = 0
    //             let bankMatch = false

    //             for (let i = 0; i < answer.length; i++) {

    //                 if (answer[i].bank == selectedColumn) {
    //                     sameIndex = i
    //                     bankMatch = true

    //                     break
    //                 }
    //             }

    //             if (bankMatch) {
    //                 if (event.target.checked == true || question.selection == 'dropdown') {
    //                     answer[sameIndex].answer.push(event.target.value);

    //                 }
    //                 else {
    //                     let targetIndex = answer[sameIndex].answer.indexOf(event.target.value)

    //                     answer[sameIndex].answer.splice(targetIndex, 1)
    //                 }
    //             } else {
    //                 if (event.target.checked == true || question.selection == 'dropdown') {
    //                     let an = { bank: selectedColumn, answer: [] }
    //                     an.answer.push(event.target.value)
    //                     setTemporaryAns([...answer, an])
    //                 }
    //             }
    //             if (answer.length != question.options.length) {
    //                 setButton(false)

    //             } else {

    //                 setButton(true)
    //             }
    //         }
    //     }
    //     else if (type === 'form' || type == 'contact') {
    //         let form = answer.length == 0 ? {} : { ...answer[0] }

    //         if (selectedColumn) {
    //             doubleDrop[1] = event.target.value
    //         }
    //         else {
    //             doubleDrop[0] = event.target.value
    //         }

    //         // form[option.option] = option.text_box != 'yes' ? doubleDrop : event.target.value
    //         form[option.option] = event.target.value
    //         let optionKeys = Object.keys(form)
    //         let notEmpty = true
    //         optionKeys.forEach(op => {

    //             if (!form[op] || form[op] == "") {
    //                 notEmpty = false
    //             }
    //         })
    //         if (optionKeys.length == question.options.length && notEmpty) {
    //             setButton(true)
    //         } else {
    //             setButton(false)
    //         }
    //         setTemporaryAns([form])
    //     }
    //     else if (type == 'video') {
    //         setButton(true)
    //     }
    //     else {
    //         if (option && option.text_box == 'yes') {
    //             option.textOpen = true
    //             setTextBox(true)
    //             setButton(false)
    //             if ((type == 'radio' || event.target.value.trim() == "" || event.target.value == null || event.target.value == undefined) && option.text_box == 'yes') {
    //                 setButton(false)
    //             }
    //             else {
    //                 if (type == 'radio') {
    //                     setRadioOption(event.target.value)
    //                 }
    //                 else {
    //                     setTemporaryAns(`${radioOption || option.option}:${event.target.value}`)
    //                 }


    //                 setButton(true)
    //             }


    //             return
    //         }

    //         else {

    //             ans.push(event.target.value)
    //             setTemporaryAns(ans)
    //             if (event.target.value.trim() == "") {
    //                 setButton(false)
    //             }
    //             if (ans.length == 0) {
    //                 setButton(false)
    //             }
    //         }
    //     }
    //     if (type != 'matrix' && type !== 'form' && type !== "contact" && type !== "checkbox") {
    //         if (event.target.value.trim() == "") {
    //             setButton(false)

    //         }
    //         if (event.target.value.trim() > "") {

    //             if (!event.target.checked && answer.length == 0) {
    //                 setButton(false)
    //             }
    //             else {

    //                 setButton(true)
    //             }
    //         }

    //     }
    //     // if (type !== 'matrix' && type !== 'form' || type !== 'contact') {
    //     //     if (event.target.value.trim() == "") {
    //     //         setButton(false)

    //     //     }
    //     //     if (event.target.value.trim() > "") {

    //     //         setButton(true)
    //     //     }
    //     // }


    // }

    const setAnswer = (event, type, option, question, selectedColumn, selection) => {
        // console.log(event, type)
        // setBackQueAns([])
        // setTextarea([])

        if (type === 'rating') {

            setStarClass(event)

            setButton(true)
            setRatingValue(event)
            setTemporaryAns(event)
            return
        }

        let actId = [...actionId]

        if (option.action_id != "") {
            if (type == 'video' || event.target.checked || type == 'form' || type == 'text' || type === "date" || type === "number") {
                if (type === "radio" && actId.length > 0) {
                    actId = []
                    actId.push(option.action_id)

                } else {
                    actId.push(option.action_id)

                }
            } else {
                if (option.trigger) {
                    let index = actId.indexOf(option.action_id)
                    actId.splice(index, 1)

                } else {
                    let index = actId.indexOf(option.action_id)
                    actId.splice(index, 1)

                }
            }
        }
        setActionId(actId)


        if (option.count_limit && (option.count || option.count == 0)) {
            if (option.count >= option.count_limit) {
                setActionId("exit")
            } else {
                // option.count += 1

            }
        }

        if (type == "radio") {

            // if (option.Imagerequired === 'yes' || option.option == 'Yes') {
            //     setselectImage('yes')
            // } else if (option.Audiorequired === 'yes' || option.option == 'Yes') {
            //     setselectImage('yes')
            // }
            // else if (option.Imagerequired === 'yes' || option.option == 'No') {
            //     setselectImage('no')
            // }
            // if (option.TataImagerequired === "yes") {
            //     setButtonIndex(selectedColumn)
            //     setselectImage('yes')
            // }
            question.options.forEach((op, index) => {
                if (selectedColumn !== index && op.textOpen === true) {
                    op.textOpen = false
                }
            })
            setEvent(event)
        } else if (type == "checkbox") {
            eventCheckbox.push(event);
            setEventCheckbox(eventCheckbox)
        }
        else if (type == "matrix") {
            eventMatrix.push(event);
            setEventMatrix(eventMatrix)
        }
        else if (type === "form" || type === "contact") {
            eventForm.push(event)
            setEventForm(eventForm)
        }
        else {

            setEventText(event)
        }

        let ans = []

        if (type == 'checkbox' || question?.type == 'checkbox') {
            console.log(selectedColumn, "poiugfghjkjhgfghj")
            if (answer.length < 0) {
                setButton(false)
            }


            if (option && option.text_box == 'yes') {

                option.textOpen = true
                setTextBox(true)
                let { value, checked } = event.target;
                let ansIndex = [...checkBoxIndex]

                setButton(false)
                let valueBlankCheckedUndefined = false
                if (value.trim() == "" || (checked == true) || value == null || value == undefined) {

                    if (!ansIndex.includes(option.option)) {
                        ansIndex[answer.length] = option.option
                    }
                    setCheckBoxIndex(ansIndex)
                    let ans = [...answer]
                    ans.push(option.option)
                    if (checked) { setTemporaryAns(ans) }

                    setButton(false)
                    valueBlankCheckedUndefined = true
                    option.selectedOption = true
                }
                else if (checked == false) {
                    let findIndex
                    answer.forEach((an, i) => {
                        if (an.includes(option.option)) {
                            findIndex = i
                        }
                    })
                    answer.splice(findIndex, 1);
                    ansIndex.splice(ansIndex.indexOf(option.option), 1)

                    setCheckBoxIndex(ansIndex)
                    option.textOpen = false
                    option.selectedOption = false

                }
                else {

                    let findIndex
                    answer.forEach((an, i) => {
                        if (an.includes(option.option)) {
                            findIndex = i
                        }
                    })

                    answer[findIndex] = `${option.option}:${event.target.value}`
                    if (checkboxCount == 0) {
                        setCount(1)
                    }
                    setButton(true)
                }

                if (!event.target.checked) {
                    if (answer.length > 0 && !valueBlankCheckedUndefined) {
                        setButton(true)
                    }
                    else {
                        setButton(false)
                    }
                }
                return
            }
            else {

                if (answer.includes(event.target.value)) {
                    let index = answer.indexOf(event.target.value)
                    answer.splice(index, 1);
                    let i = checkBoxIndex.indexOf('Other');
                    option.selectedOption = false

                    if (i == -1 || answer[i].length > 8) {

                        setButton(true)
                    }
                    else if (answer.length == 0) {
                        setButton(false)
                    }
                    else {
                        setButton(false)
                    }
                }
                else {

                    setTemporaryAns([...answer, event.target.value])
                    option.selectedOption = true
                    console.log(option, "iiiiiiiii")
                    let i = checkBoxIndex.indexOf('Other');

                    if (i == -1 || answer[i].length > 8) {
                        setButton(true)
                    }
                }

            }
            if (!event.target.checked) {

                let i = checkBoxIndex.indexOf('Other');

                if (answer.length > 0 && (i == -1 || answer[i].length > 8)) {
                    setButton(true)
                }
                // if () {
                //     setButton(true)
                // }
                else {
                    setButton(false)
                }
            }
        }
        else if (type == 'matrix') {

            if (question.selection == 'single') {

                let index = 0
                let flag = false;
                for (let i = 0; i < answer.length; i++) {
                    if (answer[i].bank == selectedColumn) {
                        index = i;
                        flag = true;
                        break
                    }
                }

                if (flag) {
                    answer.splice(index, 1);
                    flag = false
                }
                let ans = { bank: selectedColumn, answer: event.target.value }

                if (answer.length + 1 != question.options.length) {
                    setButton(false)
                } else {
                    setButton(true)
                }
                setTemporaryAns([...answer, ans])

            }
            else {
                let sameIndex = 0
                let bankMatch = false
                for (let i = 0; i < answer.length; i++) {

                    let a = typeof (answer[i])
                    if (answer[i].bank == selectedColumn && question.selection != "input" && question.selection != "random") {
                        sameIndex = i
                        bankMatch = true

                        break
                    } else if ((question.selection == "input" || question.selection == "random") && answer[i].bank == selectedColumn && answer[i].quality == option.option) {

                        sameIndex = i
                        bankMatch = true

                        break

                    }
                }

                if (bankMatch) {
                    if (event.target.checked == true || question.selection == 'dropdown' || question.selection == 'input' || question.selection == "random") {
                        if (question.selection == 'input' || question.selection == "random") {
                            if (event.target.value.trim() == "") {
                                answer.splice(sameIndex, 1)
                                setButton(false)
                            } else {
                                answer[sameIndex].answer = event.target.value

                            }
                        } else {
                            answer[sameIndex].answer.push(event.target.value);
                        }

                    }
                    else {
                        let targetIndex = answer[sameIndex].answer.indexOf(event.target.value)

                        answer[sameIndex].answer.splice(targetIndex, 1)
                    }
                }
                else {
                    if (event.target.checked == true || question.selection == 'dropdown' || question.selection == 'input' || question.selection == "random") {

                        let an = { bank: selectedColumn, answer: [] }
                        if (question.selection == 'input' || question.selection == "random") {
                            an.answer = event.target.value
                            an.quality = option.option
                            let b = { ...an }
                            if (answer.length + 1 != (question.options.length * matHeader.length)) {
                                setButton(false)
                            } else {
                                setButton(true)
                            }
                            setTemporaryAns([...answer, b])
                        }
                        // if(question.selection == "random") {

                        // } 
                        else {
                            an.answer.push(event.target.value)
                            setTemporaryAns([...answer, an])
                        }

                    }
                }
                if (question.selection === 'random') {
                    // if (answer.length != (question.options.length * matHeader.length)) {
                    //     setButton(false)
                    // } else {
                    //     setButton(true)
                    // }
                } else {
                    if (answer.length != question.options.length) {
                        setButton(false)
                    } else {
                        setButton(true)
                    }
                }
            }
        }

        else if (type === 'form' || type === "contact") {

            let temp = answer.length > 0 ? answer[0] : {}


            if (selectedColumn) {
                doubleDrop[1] = event.target.value
            }
            else {
                doubleDrop[0] = event.target.value
            }

            // temp[option.option] = option.text_box != 'yes' ? doubleDrop : event.target.value
            temp[option.option] = event.target.value


            setTemporaryAns([temp])


            let keyInAnswer = Object.keys(temp)

            let notEmpty = true
            keyInAnswer.forEach(op => {
                if (!temp[op] || temp[op] == "") {
                    notEmpty = false
                }
            })
            if (question.options.length === keyInAnswer.length && notEmpty) {
                setButton(true)

            } else {

                setButton(false)
            }


        }
        else if (type == 'video') {

            setButton(true)
        }

        else {
            if (option && option.text_box == 'yes') {
                option.textOpen = true
                setTextBox(true)
                setButton(false)
                if ((type == 'radio' || event.target.value.trim() == "" || event.target.value == null || event.target.value == undefined) && option.text_box == 'yes') {

                    setButton(false)
                }
                else {
                    if (type == 'radio') {
                        setRadioOption(event.target.value)
                    }
                    else {
                        setTemporaryAns(`${radioOption || option.option}:${event.target.value}`)
                    }

                    setButton(true)
                }
                return
            }

            else {
                option.textOpen = false
                setTextBox(false)
                ans.push(event.target.value)
                setTemporaryAns(ans)
                if (event.target.value.trim() == "") {
                    setButton(false)
                }
                if (ans.length == 0) {
                    setButton(false)
                }
                if (event.target.value == option.option && event.checked) {
                    event.checked = true
                }

            }
        }
        if (type != 'matrix' && type !== 'form' && type !== "contact" && type !== "checkbox") {
            if (event.target.value.trim() == "") {
                setButton(false)

            }
            if (event.target.value.trim() > "") {

                if (!event.target.checked && answer.length == 0) {
                    setButton(false)
                }
                else {

                    setButton(true)
                }
            }

        }
        if (type === 'number') {
            if (option.minVlaue > Number(event.target.value)) {

                setButton(false)
                return

            } else {
                let matHeader = []
                for (let i = 1; i <= event.target.value; i++) {
                    matHeader.push(`${"Pet " + i}`)

                }
                setMatHeader(matHeader)
                if (event.target.value === "") {
                    setButton(false)
                } else {
                    setButton(true)
                }
            }
        }
        // setButton(true)

    }
    const backQuestion = (question) => {
        setButton(false)
        if (question.type == 'radio') {
            eventRadio.target.checked = false
        } else if (question.type == 'checkbox') {
            eventCheckbox.forEach((eve) => {
                eve.target.checked = false;
            })
        }
        setQuesNo(previousQue)
    }


    const questionAudio = (question) => {

        speak({ text: question.question, voice: voices[0] })

        if (question.type != 'text' && question.type != 'rating') {
            question.options.forEach((op, index) => {
                let str = String.fromCharCode(index + 65)
                speak({ text: `option   ${str}`, voice: voices[0] })
                speak({ text: op.option, voice: voices[0] })
            })
        }

    }


    const getSurveyLangCode = async (e) => {
        setSelectedLang(e.target.value)
        if (e.target.value == 'en') {
            setDisplayKeys({ ...displayKeys, question: 'question', option: 'option', que_message: 'que_message' })
            return
        }
        else {

            setDisplayKeys({ ...displayKeys, question: e.target.value !== "" ? `question_${e.target.value}` : 'question', option: e.target.value !== "" ? `option_${e.target.value}` : 'option' })
        }
    }

    const audioPlay = () => {
        if (audioPause == "onPlay") {
            setAudioPause("onPause")
            player.current.audioEl.current.pause()
        } else if (audioPause !== "onPlay") {
            setAudioPause("onPlay")
            player.current.audioEl.current.play()
        }

    }


    return (
        <main className='bg-lgrey position-relative container-fluid p-0'>
            {loader && <Loader />}
            <section id="all-section" className="admin-header position-relative">

                <nav className="navbar navbar-expand-lg bg-white p-0 border border-bottom headerpanlist" style={{ height: "80px" }}>
                    <div className="container-fluid p-xsm-0">
                        <div className='d-flex justify-content-between w-100 p-2'>
                            <a className="navbar-brand1 logo-itemsw"><img src={survey.logo ? survey.logo : "../images/new-logo.png"} width="100px" alt="" /></a>
                            <ul className="navbar-nav w-auto">
                                {survey && survey.languages && survey.languages.length > 1 && <div className="nav-item dropdown notification-drop">
                                    <label>Language:</label>
                                    <select className='form-control' name="languages" id="lang" value={selectedLang} onChange={(e) => getSurveyLangCode(e)}>
                                        {survey && survey.languages && survey.languages.length > 0 && survey.languages.map((data, index) => (<option name='language' value={data.lang_code}>{data.lang_name}</option>))}
                                    </select>
                                </div>}
                                <li className="profile-drop nav-item dropdown" onClick={e => setSurveyScreen(false)}>
                                    <a className="nav-link nav dropdown-toggle d-flex align-items-center position-static" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src={user.gender == 'female' ? "../images/woman.png" : "../images/man.png"} width="50px" className='rounded-circle' alt="" />
                                    </a>
                                    {/* <ul className="dropdown-menu dropdown-menu-lg-end">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul> */}
                                </li>
                            </ul>
                        </div>
                    </div></nav>
            </section>
            {survey.is_client == 1 ?
                (

                    <div className="App"
                        style={{ height: 'calc(100vh - 20px)' }}>
                        {console.log("djf", survey.url)}
                        <iframe src={survey.url} height="100%" width="100%" title="Iframe Example"></iframe>
                    </div>
                ) :
                <section className='h-100 survey-screen container-fluid'>
                    <div className='row justify-content-center'>
                        <div className='col-md-9 mb-5'>
                            <div className='survey-questions bg-white p-3 my-3 com-bsl w-100 mb-5'>
                                {/* <div className='company-name w-100 mb-3'><img src='../images/logo (3).png' className=''></img></div> */}
                                <div className='row justify-content-center' >

                                    {surveyQues && (type === "profile" || (surveyQues[quesNo]?.layout !== undefined || surveyQues[quesNo]?.layout) && (('layout' in surveyQues[quesNo] == false) || (surveyQues[quesNo].layout == 1))) && <>
                                        <div className='col-md-10 justify-content-center d-flex flex-column'>
                                            <p className='fs-5 fw-bold py-2 text-center'>{surveyQues[quesNo][displayKeys.question]}
                                                {survey.audio && selectedLang == 'en' && <button className='border-0 bg-transparent ms-2' onClick={(e) => questionAudio(surveyQues[quesNo])}><img src={(audioPause !== "onPlay") ? "../images/audio/c4559ad4-1cf8-4416-9f97-386f93d3772c.png" : "../images/pause.png"} style={{ width: "37px" }} /></button>}
                                            </p>
                                            {surveyQues && surveyQues[quesNo].que_message && <spam className='fs-5 fw-bold py-2 text-center mb-0'>{surveyQues && surveyQues[quesNo][displayKeys.que_mesage]}</spam>}

                                            {surveyQues[quesNo].question_audio && (<ReactAudioPlayer preload='metadata'
                                                ref={player} onPause={() => setAudioPause('onPause')} src={surveyQues[quesNo].question_audio} />)}
                                            {surveyQues[quesNo].attachment && surveyQues[quesNo].attachment != "" && <div className='d-flex justify-content-center'><img className="mb-4" src={surveyQues[quesNo].attachment} width="100px"></img></div>}

                                            {/* <lottie-player src="../images/028895cd-d87b-4c0d-9e3e-a8a546559417.json" background="transparent" speed={1} style={{ width: '100%', height: '400px', marginTop: '-140px' }} loop autoPlay /> */}
                                        </div>
                                        {surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto" id="options" >
                                            <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>
                                            </textarea>
                                        </div>)}
                                        <div className='col-md-12'>
                                            {(surveyQues && surveyQues[quesNo].type == 'form' || surveyQues[quesNo].type == 'contact') && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 mb-3' >
                                                    <div className="form-check h-100">
                                                        {/* <textarea className="form-check-input fs-3" type="text" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }} /> */}
                                                        <div className='row w-100'>
                                                            <div className='col-md-8'>
                                                                <label className="fs-5 form-check-label w-100" >{option[displayKeys.option]}</label></div>
                                                            <div className='col-md-4'>
                                                                {(option.option_type === "dropdown") ? <select className='form-control' name="que" id="que" onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} >
                                                                    <option >Select Option</option>
                                                                    {option.dropdown_option && option.dropdown_option.length > 0 && option[selectedLang=='en'?'dropdown_option':'dropdown_option_'+selectedLang].map((op) =>
                                                                        <>
                                                                            <option value={op}>{op}</option></>
                                                                    )}
                                                                </select> :
                                                                    <input type={option.option_type} maxLength="255" className="form-control" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />}
                                                            </div>
                                                        </div>

                                                        {/* <textarea className="form-control col-md-10 mt-2" type="text" value={option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }} /> */}
                                                        {/* {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, 'text', option,surveyQues[quesNo]) }}>
                                                        </textarea>} */}
                                                    </div>
                                                </div>))}

                                            </div>)}

                                        </div>
                                        {(surveyQues && surveyQues[quesNo].type == 'csat' || surveyQues && surveyQues[quesNo].type == 'nps') && (
                                            <div className="container-fluid allQueRport py-3 mt-2 earnSec">
                                                <div className="row">
                                                    <div className="col-lg-12">

                                                        <div className="panel-body">
                                                            <div>
                                                                <div className="react-bs-table-container">
                                                                    <div className="react-bs-table react-bs-table-bordered" style={{ height: '100%' }}>
                                                                        <div className="react-bs-container-header table-header-wrapper">

                                                                            {surveyQues && surveyQues[quesNo].selection == 'emoji' && <div class="col-lg-12 h-100">
                                                                                {/* {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => ( */}
                                                                                <div class="d-flex ratingBoxSec w-100 pl-2">
                                                                                    {/* { console.log(index)} */}
                                                                                    <div class="comnRating angryRating m-2 angryRatingaligment" onClick={() => setAnswer(1, "rating", surveyQues[quesNo])}>
                                                                                        <img src="../images/angry_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a >1</a>
                                                                                        </div>
                                                                                        <input value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[0].option} />
                                                                                    </div>
                                                                                    <div class="comnRating upsetRating m-1 angryRatingaligment" onClick={() => setAnswer(2, "rating", surveyQues[quesNo])}>
                                                                                        <img src="../images/upset_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a >2</a>
                                                                                        </div>
                                                                                        <input value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[1].option} />                                                                                   </div>
                                                                                    <div class="comnRating naturalRating m-1 angryRatingaligment" onClick={() => setAnswer(3, "rating", surveyQues[quesNo])}>
                                                                                        <img src="../images/neutral_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a>3</a>
                                                                                        </div>
                                                                                        <input value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[2].option} />
                                                                                    </div>
                                                                                    <div class="comnRating happyRating m-1 angryRatingaligment" onClick={() => setAnswer(4, "rating", surveyQues[quesNo])}>
                                                                                        <img src="../images/happy_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a >4</a>
                                                                                        </div>
                                                                                        <input value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[3].option} />                                                                                   </div>
                                                                                    <div class="comnRating excitedRating m-1 angryRatingaligment" onClick={() => setAnswer(5, "rating", surveyQues[quesNo])}>
                                                                                        <img src="../images/excited_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a >5</a>
                                                                                        </div>
                                                                                        <input value={surveyQues[quesNo].options.length && surveyQues[quesNo].options[4].option} />
                                                                                    </div>
                                                                                </div>
                                                                                {/* ))} */}
                                                                            </div>}



                                                                            {surveyQues && surveyQues[quesNo].selection == "ynm" && <div className=" row gap-3 justify-content-center my-5 ps-3 mn-like">
                                                                                <label className="Like-thumb rounded-1 p-0" style={{ width: '125px' }} onClick={() => setAnswer("Yes", "rating", surveyQues[quesNo])}>
                                                                                    <input type="radio" className="rounded-1 " name="radio" />
                                                                                    <div className="checkmarksen d-grid text-center " style={{ padding: '20px 30px 0px' }}>
                                                                                        <img src="../images/Thumb-yes.svg" alt="" className="m-auto" width="40px" />
                                                                                        <span className="pt-3 pb-2 fs-6">Yes</span>
                                                                                    </div>
                                                                                </label>
                                                                                <label className="Like-thumb p-0" style={{ width: '125px' }} onClick={() => setAnswer("No", "rating", surveyQues[quesNo])}>
                                                                                    <input type="radio" name="radio" />
                                                                                    <div className="checkmarksen d-grid text-center" style={{ padding: '20px 30px 0px' }}>
                                                                                        <img src="../images/Thumb-no.svg" className="m-auto" alt="" width="40px" />
                                                                                        <span className="pt-3 pb-2 fs-6">No</span>
                                                                                    </div>
                                                                                </label>
                                                                                <label className="Like-thumb p-0" style={{ width: '125px' }} onClick={() => setAnswer("Maybe", "rating", surveyQues[quesNo])}>
                                                                                    <input type="radio" name="radio" />
                                                                                    <div className="checkmarksen d-grid text-center" style={{ padding: '20px 30px 0px' }}>
                                                                                        <img src="../images/Maybe.svg" alt="" className="m-auto" width="37px" />
                                                                                        <span className="pt-3 pb-2" style={{ fontSize: '14px' }}>Maybe</span>
                                                                                    </div>
                                                                                </label>
                                                                            </div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <div className='col-md-12'>
                                            {surveyQues[quesNo].type == 'checkbox' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-6 mb-3' >
                                                    <div className="form-check h-100 ">
                                                        <label className="fs-5 form-check-label  w-100" >
                                                            <input className="form-check-input fs-3" type="checkbox" value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />
                                                            {option.imageUrl && <img src={option.imageUrl} style={{ height: "100%", width: "100%" }}></img>}
                                                            {option[displayKeys.option]}
                                                            {option.text_box == 'yes' && option.textOpen && <textarea type="text" maxLength="255" className="form-control col-md-10 mt-2" row="3" id={option.option} placeholder={option.placeholder ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option, surveyQues[quesNo]) }}>
                                                            </textarea>}
                                                        </label>
                                                    </div>
                                                </div>))}

                                            </div>)}

                                        </div>
                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'video' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 ' >
                                                    <div className="form-checkvideo h-100 w-100 justify-content-center" style={{ backgroundColor: "#f1f1f1 !important" }}>
                                                        <ReactPlayer url={option.option} controls={true} value={option.option} onEnded={(e) => { setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo]) }} />
                                                    </div>
                                                </div>))}
                                            </div>)}
                                        </div>
                                        {/* <div className='col-md-12'>
                                        {surveyQues && surveyQues[quesNo].type == 'note' && (<div className='row surv-op'>

                                            {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 ' >
                                                <div className="form-checkvideo h-100 w-100 justify-content-center" style={{ backgroundColor: "#f1f1f1 !important" }}>

                                                </div>
                                            </div>))}
                                        </div>)}
                                    </div> */}
                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == 'note' && (<div className='row surv-op'>

                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-12 ' >
                                                    <div className="form-checkvideo h-100 w-100 justify-content-center" style={{ backgroundColor: "#f1f1f1 !important" }}>
                                                        {/* <ReactPlayer url='http://localhost:3009/survey_video/UPI_AutoPay.mp4'  controls={true} value={option.option} onStart={(e) => { setAnswer(e, surveyQues[quesNo].type, option,surveyQues[quesNo]) }}/> */}

                                                    </div>
                                                </div>))}

                                            </div>)}

                                        </div>
                                        <div className='col-md-12'>

                                            {surveyQues[quesNo].type == 'radio' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-6 mb-3'>
                                                    {/* <label className="fs-5 fw-normal form-check-label  w-100" > */}
                                                    <div className="form-check h-100 ">
                                                        <label className="fs-5 form-check-label w-100" >
                                                            <input className="form-check-input fs-3" type="radio" name={surveyQues[quesNo]._id} value={(option.option == '' && option?.imageUrl) ? index + 1 : option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                            {option.imageUrl && <img src={option.imageUrl} style={{ height: "100%", width: "100%" }}></img>}
                                                            {option[displayKeys.option]}
                                                        </label>
                                                        {option.text_box == 'yes' && option.textOpen && <textarea type="text" maxLength="255" className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option) }}>
                                                        </textarea>}
                                                    </div>
                                                </div>))}
                                            </div>)}
                                        </div>
                                        <div className="col-md-12 ">
                                            {surveyQues && surveyQues[quesNo].type == 'rating' && surveyQues[quesNo].selection == 'rating' && (<div className="">
                                                {/* app.css line no.-6842    color: #F8B84E !important;*/}
                                                <div className="quationSec w-100 position-relative mt-3">

                                                    <div className="optionsBox w-100 mb-5">
                                                        <div className="startReview1 d-flex justify-content-center" id="startReview1" style={{ display: '{{(isset($created_survey_options[0]["option_value"]) && ($created_survey_options[0]["option_value"] == "startReview1") ? "block":"none")}}' }}>
                                                            <ul className="d-flex p-0 ratingReviewsq startReview" >
                                                                {surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (

                                                                    <li><button type="button" class={ratingValue == option.option ? 'btn-outline-primary active' : 'btn-outline-primary'} onClick={(e) => setAnswer(option.option, surveyQues[quesNo].type, surveyQues[quesNo])}>{option.option}</button>

                                                                    </li>

                                                                ))}

                                                                {/* <li><button type="button" class="btn-outline-primary" onClick={(e)=>setAnswer(index, surveyQues[quesNo].type,  surveyQues[quesNo])}>1</button></li> */}

                                                            </ul>
                                                            {surveyQues[quesNo].options[surveyQues[quesNo].options.length - 1].option > surveyQues[quesNo].options[0].option && <input type="range" class="formewrange mt-2" step="0" min="1" style={{ width: (81 * surveyQues[quesNo].options.length) }} value={ratingValue} max={surveyQues[quesNo].options.length} onChange={(e) => setAnswer(e.target.value, surveyQues[quesNo].type, surveyQues[quesNo])}></input>}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>)}
                                            {surveyQues && surveyQues[quesNo].type == 'rating' && surveyQues[quesNo].selection == 'star' && (<div className="">
                                                {/* app.css line no.-6842    color: #F8B84E !important;*/}
                                                <div className="quationSec w-100 position-relative mt-3">

                                                    <div className="optionsBox w-100 mb-5">
                                                        <div className="startReview1 d-flex justify-content-center" id="startReview1" style={{ display: '{{(isset($created_survey_options[0]["option_value"]) && ($created_survey_options[0]["option_value"] == "startReview1") ? "block":"none")}}' }}>
                                                            <ul className="d-flex p-0 startReview" >
                                                                {/* <li><a href="javascript:void(0);"><i className="fa fa-star-o" />
                                                                        <i className="fa fa-star" />
                                                                        <span>0</span></a>
                                                                    </li> */}
                                                                {surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (

                                                                    <li><a style={{ color: '#F8B84E ' }} onClick={(e) => setAnswer(option.option, surveyQues[quesNo].type, surveyQues[quesNo])}><i className={starClass >= option.option ? "fa fa-star" : "fa fa-star-o"} />
                                                                        <i className="fa fa-star" />
                                                                        <span>{option.option}</span></a>
                                                                    </li>))}

                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>)}
                                        </div>
                                        {surveyQues && surveyQues[quesNo].type == 'nps' && (<div class="col-lg-12  artingNPSver artingNPSverTom">
                                            {/* {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => ( */}
                                            <div class="d-flex ratingBoxSec pl-2">
                                                <div class="comnRating angryRating angryRatingaligment" >
                                                    <img src="../images/angry_mini.svg" style={{ width: "40px" }} />
                                                    <div class="blockRating" >
                                                        <a onClick={() => setAnswer(0, "rating", surveyQues[quesNo])} >0</a>
                                                        <a onClick={() => setAnswer(1, "rating", surveyQues[quesNo])} >1</a>
                                                        <a onClick={() => setAnswer(2, "rating", surveyQues[quesNo])} >2</a>
                                                    </div>
                                                </div>
                                                <div class="comnRating upsetRating angryRatingaligment" onClick={() => ""}>
                                                    <img src="../images/upset_mini.svg" style={{ width: "40px" }} />
                                                    <div class="blockRating">
                                                        <a onClick={() => setAnswer(3, "rating", surveyQues[quesNo])}>3</a>
                                                        <a onClick={() => setAnswer(4, "rating", surveyQues[quesNo])}>4</a>
                                                        <a onClick={() => setAnswer(5, "rating", surveyQues[quesNo])}>5</a>
                                                    </div>                                                                                 </div>
                                                <div class="comnRating naturalRating angryRatingaligment" onClick={() => ""}>
                                                    <img src="../images/neutral_mini.svg" style={{ width: "40px" }} />
                                                    <div class="blockRating">
                                                        <a onClick={() => setAnswer(6, "rating", surveyQues[quesNo])}>6</a>
                                                        <a onClick={() => setAnswer(7, "rating", surveyQues[quesNo])}>7</a>

                                                    </div>
                                                </div>
                                                <div class="comnRating happyRating angryRatingaligment" onClick={() => ""}>
                                                    <img src="../images/happy_mini.svg" style={{ width: "40px" }} />
                                                    <div class="blockRating">
                                                        <a onClick={() => setAnswer(8, "rating", surveyQues[quesNo])}>8</a>
                                                    </div>                                                                                 </div>
                                                <div class="comnRating excitedRating angryRatingaligment" onClick={() => ""}>
                                                    <img src="../images/excited_mini.svg" style={{ width: "40px" }} />
                                                    <div class="blockRating">
                                                        <a onClick={() => setAnswer(9, "rating", surveyQues[quesNo])}>9</a>
                                                        <a onClick={() => setAnswer(10, "rating", surveyQues[quesNo])}>10</a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ))} */}
                                        </div>)}
                                        <div className='col-md-12'>
                                            {surveyQues[quesNo].type == 'image' && (<div className='row surv-op'>
                                                {surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className='col-md-6 mb-3'>
                                                    {/* <label className="fs-5 fw-normal form-check-label  w-100" > */}
                                                    <div className="form-check h-100 ">
                                                        <label className="fs-5 form-check-label w-100" >
                                                            <input className="form-check-input fs-3" type="radio" name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, "radio", option, surveyQues[quesNo])} />
                                                            <div class="d-flex flex-column">

                                                                <img src={option.attachment}></img>&nbsp;
                                                                {option[displayKeys.option]}
                                                            </div>
                                                        </label>
                                                        {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control  mt-2" placeholder={(option.placeholder) ? option.placeholder : "Enter Here ..."} onChange={(e) => { setAnswer(e, 'text', option) }}>
                                                        </textarea>}
                                                    </div>

                                                </div>))}

                                            </div>)}

                                        </div>



                                        <div className='col-md-12'>
                                            {surveyQues && surveyQues[quesNo].type == "matrix" && (
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            {surveyQues[quesNo].matOption && surveyQues[quesNo].matOption.length > 0 && surveyQues[quesNo].matOption.map((column, index) => (
                                                                <th scope="col" key={index}>{column} </th>

                                                            ))

                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {surveyQues[quesNo].options && surveyQues[quesNo].options.length > 0 && surveyQues[quesNo].options.map((row, index) => (
                                                            <tr>

                                                                <>
                                                                    <th scope="row">{row.option}</th>
                                                                    {surveyQues[quesNo].matOption && surveyQues[quesNo].matOption.length > 0 && surveyQues[quesNo].matOption.map((column, i) => (
                                                                        <td>
                                                                            <div className="form-check">
                                                                                {surveyQues[quesNo].selection == 'single' && <input className="form-check-input" type="radio" value={column} onClick={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], row.option)} name={index} />
                                                                                }
                                                                                {surveyQues[quesNo].selection == 'multiple' && <input type="checkbox" name="checkbox" value={row.option} onClick={(event) => setAnswer(event, "matrix", row, surveyQues[quesNo], column)} className="form-check-input" />
                                                                                }
                                                                                {surveyQues[quesNo].selection == 'dropdown' && <select className='form-control' name="que" id="que" onChange={(e) => { setAnswer(e, 'matrix', row, surveyQues[quesNo], column) }} >
                                                                                    <option hidden value="">{'Select Option'} </option>
                                                                                    {surveyQues[quesNo].matDropdown && surveyQues[quesNo].matDropdown.length > 0 && surveyQues[quesNo].matDropdown.map((op) =>
                                                                                        <>
                                                                                            <option value={op}>{op}</option></>
                                                                                    )}
                                                                                </select>}
                                                                            </div>
                                                                        </td>

                                                                    ))}


                                                                </>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </>}
                                    {surveyQues && surveyQues[quesNo]?.layout && surveyQues[quesNo].layout == 2 &&

                                        <div className='survey-question'>
                                            <h4 className='q-n d-flex'> <span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                            <p type={'text'} className=' fadeInUp' placeholder='Description (optional)'></p>
                                            <div className='align-con py-4 row  '>
                                                <div className="col-lg-5">
                                                    {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (
                                                        surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                                            <div className="input-group layoutoption mb-3">
                                                                <label className='fs-5  form-check-label layoutSurvey' >
                                                                    <input className="input-group-text bg-white border-0 rounded-1 mr-2 layoutRadio" type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                    {option[displayKeys.option]}
                                                                </label>
                                                                {option.text_box == 'yes' && text_box && <textarea type="text" maxLength="255" className="form-control d-flex layoutTexterea  mt-2" placeholder={"Enter Here ..."} onChange={(e) => { }}>
                                                                </textarea>}
                                                            </div>
                                                        ))
                                                    )}
                                                    {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                        <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                        </textarea>
                                                    </div>)}


                                                </div>
                                                <div className='col-lg-7'>
                                                    <img src={surveyQues[quesNo].attachment ? surveyQues[quesNo].attachment : '../images/login-bg.jpg'} className='w-100 '></img>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* </div> */}


                                    {/* <div className="tab-pane question-alignment fade directSurveylayoutwq" id="img-left-pane" aria-labelledby="img-left" > */}
                                    {surveyQues && surveyQues[quesNo]?.layout && surveyQues[quesNo].layout == 3 &&

                                        <div className='survey-question'>
                                            <h4 className='q-n d-flex'> <span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                            <p type={'text'} className=' fadeInUp' placeholder='Description (optional)'></p>
                                            <div className='align-con py-4  row  '>


                                                <div className='col-lg-7'>
                                                    <img src={surveyQues[quesNo].attachment ? surveyQues[quesNo].attachment : '../images/login-bg.jpg'} className='w-100 '></img>
                                                </div>
                                                <div className="col-lg-5 py-5">
                                                    {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (
                                                        (surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className="input-group mb-3">
                                                            <label className='fs-5  form-check-label layoutSurvey' >
                                                                <input className="input-group-text bg-white border-0 rounded-1 mr-2 layoutRadio" type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                {option[displayKeys.option]}
                                                            </label>

                                                        </div>)))
                                                    )}
                                                    {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                        <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                        </textarea>
                                                    </div>)}

                                                </div>

                                            </div>
                                        </div>
                                    }
                                    {/* </div> */}
                                    {/* <div className="tab-pane question-alignment fade directSurveylayoutwq" id="bimg-right-pane" aria-labelledby="bimg-right" > */}
                                    {surveyQues && surveyQues[quesNo]?.layout && surveyQues[quesNo].layout == 4 &&
                                        <div className='survey-question'>
                                            <div className='align-con py-4 row align-items-center'>
                                                <div className="col-lg-5">
                                                    <h4 className='q-n d-flex'><span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                                    <p><input type={'text'} className=' fadeInUp' placeholder='Description (optional)'></input></p>

                                                    {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (

                                                        (surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                                            <div className="input-group mb-3">
                                                                <label className='fs-5  form-check-label layoutSurvey' >
                                                                    <input className="input-group-text bg-white border-0 rounded-1 mr-2 layoutRadio" type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                    {option[displayKeys.option]}
                                                                </label>

                                                            </div>)))
                                                    )}
                                                    {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                        <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                        </textarea>
                                                    </div>)}


                                                    {/* <div className="input-group mb-3">
                        <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">A</span>
                        <input type="text" className="form-control bg-transparent" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

                    </div> */}

                                                    {/* <div className="input-group mb-3">
                        <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">A</span>
                        <input type="text" className="form-control bg-transparent" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

                    </div>

                    <div className="input-group mb-3">
                        <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">A</span>
                        <input type="text" className="form-control bg-transparent" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

                    </div> */}

                                                </div>
                                                <div className='col-lg-7'>
                                                    <img src={surveyQues[quesNo].attachment ? surveyQues[quesNo].attachment : '../images/login-bg.jpg'} className='w-100 '></img>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* </div> */}

                                    {/* <div className="tab-pane question-alignment fade directSurveylayoutwq" id="bimg-left-pane" aria-labelledby="bimg-left" > */}
                                    {surveyQues && surveyQues[quesNo]?.layout && surveyQues[quesNo].layout == 5 &&


                                        <div className='align-con py-4 row align-items-center'>
                                            <div className='col-lg-7'>
                                                <img src={surveyQues[quesNo].attachment ? surveyQues[quesNo].attachment : '../images/login-bg.jpg'} className='w-100 '></img>
                                            </div>
                                            <div className="col-lg-5">
                                                <h4 className='q-n d-flex'> <span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                                <p type={'text'} className=' fadeInUp' placeholder='Description (optional)'></p>

                                                <div className='survey-question'>
                                                    {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (
                                                        (surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (<div className="input-group mb-3">
                                                            <label className='fs-5  form-check-label layoutSurvey' >
                                                                <input className="input-group-text bg-white border-0 rounded-1 mr-2 layoutRadio" type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                {option[displayKeys.option]}
                                                            </label>

                                                        </div>)))
                                                    )}
                                                    {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center" id="options" >
                                                        <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                        </textarea>
                                                    </div>)}
                                                    {/* <div className="input-group mb-3">
                <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">A</span>
                <input type="text" className="form-control bg-transparent" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

                </div>

                <div className="input-group mb-3">
                <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">A</span>
                <input type="text" className="form-control bg-transparent" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

                </div>

                <div className="input-group mb-3">
                <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">A</span>
                <input type="text" className="form-control bg-transparent" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

                </div> */}

                                                </div>

                                            </div>
                                        </div>}
                                    {/* </div> */}
                                    {surveyQues && surveyQues[quesNo]?.layout && surveyQues[quesNo].layout == 6 &&
                                        <div className="tab-pane question-alignment fade  directSurveylayoutwq" id="fimg-pane" aria-labelledby="fimg" >

                                            <div className='align-con m-0 py-4 row align-items-center justify-content-center'>

                                                <div className="col-lg-6">
                                                    <h4 className='q-n d-flex'><span className='text-orange'><i className="bi bi-arrow-right"></i></span><p type={'text'} className=' fadeInUp' placeholder="Please enter your question here">{surveyQues && surveyQues[quesNo][displayKeys.question]}</p></h4>
                                                    <p type={'text'} className=' fadeInUp' placeholder='Description (optional)'></p>

                                                    <div className='survey-question '>
                                                        {surveyQues && (surveyQues[quesNo].type == 'radio' || surveyQues[quesNo].type == 'checkbox') && (
                                                            (surveyQues[quesNo] && surveyQues[quesNo].options && surveyQues[quesNo].options.length && surveyQues[quesNo].options.map((option, index) => (
                                                                <div className="input-group mb-3">
                                                                    <label className='fs-5  form-check-label layoutSurvey' style={{ color: 'white' }} >
                                                                        <input className="input-group-text text-light  border-0 rounded-1 mr-2 layoutRadio" type={surveyQues && surveyQues[quesNo].type} name={surveyQues[quesNo]._id} value={option.option} onChange={(e) => setAnswer(e, surveyQues[quesNo].type, option, surveyQues[quesNo])} />
                                                                        {option[displayKeys.option]}
                                                                    </label>

                                                                </div>)))
                                                        )}
                                                        {surveyQues && surveyQues[quesNo].type == 'text' && (<div className="ml-md-2 ml-sm-3 pl-md-3 pt-sm-0 pt-3 o-auto d-flex justify-content-center " id="options" >
                                                            <textarea type="text" maxLength={surveyQues[quesNo].maxLength ? surveyQues[quesNo].maxLength : 255} className="form-control col-md-10 mt-2 layoutTextereabackground" row="3" placeholder="Enter Here ..." onChange={(e) => { setAnswer(e, surveyQues[quesNo].type, surveyQues[quesNo].options[0]) }}>

                                                            </textarea>
                                                        </div>)}


                                                        {/* <div className="input-group mb-3">
                <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">A</span>
                <input type="text" className="form-control bg-transparent" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

            </div> */}

                                                        {/* <div className="input-group mb-3">
                <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">A</span>
                <input type="text" className="form-control bg-transparent" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

            </div>

            <div className="input-group mb-3">
                <span className="input-group-text bg-white border-0 rounded-1 mr-2" id="basic-addon1">A</span>
                <input type="text" className="form-control bg-transparent" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

            </div> */}

                                                    </div>

                                                </div>
                                            </div>
                                        </div>}

                                    <div className='col-md-12 text-center'>
                                        {quesNo < surveyQues.length - 1 && button && (<div className='py-3'><button className=' mainbtn ' onClick={(e) => { goToNextQuestion(surveyQues[quesNo]) }}  >Next Question</button></div>)}
                                        {quesNo == surveyQues.length - 1 && button && (<div className='w-100 text-center my-4'>
                                            <a className="mainbtn " onClick={(e) => goToNextQuestion(surveyQues[quesNo])}>Submit</a>
                                        </div>)}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='w-100' style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}>
                        <div className='container'>
                            <div className='row progress-survey w-100 bg-white com-bsl m-0 p-2'>
                                {quesNo > 0 && <div className='col-1 p-0'>
                                    {/* <div className='bg-blue h-100 d-flex align-items-center justify-content-center w-fit rounded-2'><i className="bi bi-chevron-left text-white fs-3" onClick={(e) => { backQuestion(surveyQues[quesNo]) }}></i></div> */}
                                </div>}
                                {/* <div className='col-lg-11 p-0'><Slider
                                defaultValue={0}
                                value={(quesNo + 1) * 100 / surveyQues.length}
                                tooltip={{
                                    open: true,
                                }}
                            /></div> */}

                            </div>
                        </div>
                    </div>
                </section>
            }
            <div className='w-100  powered-btn'>
                <a href='https://qdegrees.com/' target="_blank"> <div className='mainbtnblue m-2 position-fixed bottom-0 end-0'><small className='f-12'>Powered by</small>&nbsp;<b className='f-14'>QDegrees</b></div></a>
            </div>
            <ToastContainer />
        </main>
    )
}

export default SurveyScreen
