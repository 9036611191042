import React, { useState, useEffect,useRef } from "react";
import Header from "../../Components/ReusableComponents/Header";
import Header2 from "../../Components/ReusableComponents/Header2";
import Footer from "../../Components/ReusableComponents/Footer";
import { useHistory } from "react-router-dom";
import API from "../../utils/apiCalling";
import Payment from "../../website/payment";
import Config from '../../config/env'

const PlanManagement = () => {
  const api = new API();
  const modelOpen1= useRef(null);
  const history = new useHistory()
  let getUser = JSON.parse(localStorage.getItem("user"));
  const [monthly, setMonthly] = useState("Month");
  const [pland, setPland] = useState([]);
  const [basicPlan, setBasicPaln] = useState();
  const [premiumplan, setPremiumPlan] = useState();
  const [EnterpricePlan, setEnterpricePlan] = useState();
  const [amount, setAmount] = useState();
  const [Package, setPackage] = useState();
  const [actvie, setActive] = useState(0)
  const [Plan, SetPlan] = useState([])
  const [edit,setEdit]=useState(1)

  
  
  // let date=new Date();
  // date.toString();
   

  useEffect(() => {
    getPlan();
    getPackages()
  }, []);

  const handlePlan = (type) => {
    if (type === true) {
      setMonthly("Year");
      setBasicPaln(pland[1]?.Yearly);
      setPremiumPlan(pland[2]?.Yearly);
      setEnterpricePlan(pland[3]?.Yearly);
    } else {
      setMonthly("Month");
      setBasicPaln(pland[1]?.Monthly);
      setPremiumPlan(pland[2]?.Monthly);
      setEnterpricePlan(pland[3]?.Monthly);
    }
    console.log(type);
  };
  const handleClick = () => {
    modelOpen1.current.click();
 }
const trialDate=()=>{
 let today = new Date();
let dd = today.getDate();

let mm = today.getMonth()+1; 
let yyyy = today.getFullYear();

today = dd+ 7 +'-'+mm+'-'+yyyy;
return today}

  const handlepaymentt = async(amount, Packages) => {
    if(Packages.Monthly === 'Free'){
      console.log("Hello packages", Packages)
      let data = {
        plan_id: Packages._id,
        Id: getUser._id,
        plan_days: 7,
      };
      let result = await api.post(`${Config.new.site_api}/client-survey/addPlan`,data);
      if(result.code === 200){
        handleClick()
        
    
      
      }
    }else{
      
    setAmount(amount);
    setPackage(Packages);
    setActive(1);
    }
  };
  const getPlan = async () => {
    let result = await api.get(
      `${Config.new.site_api}/client-survey/getPlan`
    );
    if (result.code === 200) {
      setPland(result.data)
      setBasicPaln(result?.data[1].Monthly);
      setPremiumPlan(result?.data[2].Monthly);
      setEnterpricePlan(result?.data[3].Monthly);
    }
  };

  const getPackages = async()=>{
    let data = {
      _id:getUser._id
    }
    let result = await api.post(`${Config.new.site_api}/client-survey/getPackages`, data)
    if(result.code === 200){
      // console.log("Heellllo", result.data)
      SetPlan(result.data)
    }
  }

  return (
    <>
      {actvie === 0 ? (
        <>
  {/* <!-- Modal --> */}
<div class="modal fade" id="trynowtog" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header align-items-center">
        <h1 class="modal-title fs-5 mt-0" id="exampleModalLabel">Modal title</h1>
        <button type="button" class="btn-close" data-bs-target="#trynowtog" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
       Free plan is activeted in your account for 7 days. This will end on { trialDate()}.
      </div>
      {/* date.setDate(date.getDate() + 7) */}
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"data-bs-toggle="modal" data-bs-target="#trynowtog"onClick={() =>history.push('/adminDash')}  aria-label="Close">Save changes</button>
      </div>
    </div>
  </div>
</div>
          <Header2 />
          
          <section className="PlanPricing">
            <div className="container">
              <div class="form-check form-switch p-0 m-0 mb-3 d-flex justify-content-end">
                <span className="fw-bold text-orange">Monthly</span>
                <input
                  class="form-check-input position-relative p-0 mx-3 m-0"
                  type="checkbox"
                  role="switch"
                  onChange={(e) => handlePlan(e.target.checked)}
                  id="flexSwitchCheckDefault"
                  value="1"
                />
                <span className="fw-bold text-orange">Yearly</span>
              </div>
              <div className={(Plan[0]?.totalPlan)?.length > 0 ? "ItemPricing" : ''}>
                {console.log(Plan[0],"pppppppppppppp")}
                <table className="table table-bordered text-center mb-0">
                  <thead className="top-theader">
                    <tr className="res-d-none">
                      <th className="align-middle py-5 text-center px-2">
                        <div className="pricing-duration">
                          <div className="text-center">
                            <p
                              className="fs-3 text-blue p-0 m-0 mb-2"
                            >
                              Our Plans
                            </p>
                            {/* <a class="navbar-brand" href="/">
                          <img src="../images/new-logo.png" width="35%" alt="" />
                          </a> */}
                          </div>
                        </div>
                      </th>
                      {pland &&
                        pland.map((e) => (
                          <th className="align-middle py-5 text-center px-2" >
                            <div className="pricing-duration">
                              <div className="text-center">
                                <h5>
                                  {" "}
                                  <span className="text-blue">{e.Name}</span>
                                </h5>
                                <div className="monthly">
                                  <div className="d-flex align-items-end justify-content-center mt-3 mb-2 text-nowrap">
                                    <h5 className="align-self-start">₹</h5>
                                    <h2 className="mb-0 lh30 text-orange font-weight-bold">
                                      {monthly === "Month"
                                        ? e.Monthly
                                        : e.Billed_Annually}
                                    </h2>
                                    /
                                    {e.Monthly === "Free" ? " 7 Days" : monthly === "Month" ? "Month" : "Month"}
                                  </div>
                                  <p className="fw-normal">{monthly !== "Month"?(e.Monthly !== "Free" ? "Billed Annually" : " "):''} </p>
                                </div>
                                <button
                                  className="mainbtn"
                                 
                                  
                                  type="button"
                                  onClick={(q) =>
                                    handlepaymentt(
                                      monthly === "Month"
                                        ? e.Monthly
                                        : e.Yearly,
                                      e
                                    )
                                  }
                                >
                                  {e.Monthly === "Free" ? "Try Now" : "Buy Now"}
                                </button>
                              </div>
                            </div>
                          </th>
                        ))}
                    </tr>
                  </thead>
                  
                  <tbody>
                    <tr className="monthly">
                      <td class="text-left">
                        <b>
                          {" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </b>
                      </td>
                      <td>
                        <p className="mb-0 exte">
                          Pay As You Go{" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </p>
                      </td>
                      <td>
                        <p className="mb-0 exte">
                          Pay As You Go{" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </p>
                      </td>
                      <td>
                        <p className="mb-0 exte">
                          Pay As You Go{" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </p>
                      </td>
                      <td className="">
                        <p className="mb-0 exte">
                          Customised Solutions for Your Business{" "}
                          <span
                            className="info d-inline-block align-middle ml-1"
                            data-toggle="tooltip"
                            title
                            data-original-title="1st device license included"
                          />
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem1"
                    aria-expanded="false"
                    aria-controls="groupItem1"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Limit</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem1"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td className="text-left">
                        <b>Number of Users</b>
                      </td>
                    <td className="align-middle">{pland[0]?.Limit[0].number_of_Users}</td>
                     <td className="align-middle">{(pland[1]?.Limit[0].number_of_Users)}</td>
                      <td className="align-middle">{(pland[2]?.Limit[0].number_of_Users)}</td>
                      <td className="align-middle">{(pland[3]?.Limit[0].number_of_Users)}</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Number of Responses/month <span className="planquess ms-1"><i class="bi bi-question-circle"></i></span><span className="planTooltips">For reaching out specific TG/respondents use our panel</span></b>
                      </td>
                      <td className="align-middle">{pland[0]?.Limit[0].number_of_Responses_per_month}</td>
                      <td className="align-middle">{pland[1]?.Limit[0].number_of_Responses_per_month}</td>
                      <td className="align-middle">{pland[2]?.Limit[0].number_of_Responses_per_month}</td>
                      <td className="align-middle">{pland[3]?.Limit[0].number_of_Responses_per_month}</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td className="text-left">
                        <b>Share Survey<span className="planquess ms-1"><i class="bi bi-question-circle"></i></span><span className="planTooltips">Panel fee applicable
                        </span></b>
                      </td>
                      <td className="align-middle">/*System-Generated URL*/</td>
                      <td className="align-middle">
                        {console.log(pland,"kkkkkkkkkk")}
                        {pland[1]?.Limit[0].share_Survey}
                      </td>
                      <td className="align-middle">{pland[2]?.Limit[0].share_Survey}</td>
                      <td>
                      {pland[3]?.Limit[0].share_Survey}
                      </td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem2"
                    aria-expanded="false"
                    aria-controls="groupItem2"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Research Approach</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem2"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td class="text-left">
                        <b>Digital/Web Surveys</b>
                      </td>
                      <td>
                      {pland[0]?.Research_Approach[0].Digital_Web_Surveys}
                      </td>
                      <td>
                      {pland[1]?.Research_Approach[0].Digital_Web_Surveys}
                      </td>
                      <td>
                      {pland[2]?.Research_Approach[0].Digital_Web_Surveys}
                      </td>
                      <td>{pland[3]?.Research_Approach[0].Digital_Web_Surveys}</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td class="text-left">
                        <b>Focus Group Discussion<span className="planquess ms-1"><i class="bi bi-question-circle"></i></span><span className="planTooltips">Customized Cost Applicable
                        </span></b>
                      </td>
                      <td>
                      {pland[0]?.Research_Approach[0].Focus_Group_Discussion}
                      </td>
                      <td>
                      {pland[1]?.Research_Approach[0].Focus_Group_Discussion}
                      </td>
                      <td>
                      {pland[2]?.Research_Approach[0].Focus_Group_Discussion}
                      </td>
                      <td>{pland[3]?.Research_Approach[0].Focus_Group_Discussion}</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td class="text-left">
                        <b>Telephonic Interviews<span className="planquess ms-1"><i class="bi bi-question-circle"></i></span><span className="planTooltips">Customized Cost Applicable
                        </span></b>
                      </td>
                      <td>
                      {pland[0]?.Research_Approach[0].Personal_Interview}
                      </td>
                      <td>
                      {pland[1]?.Research_Approach[0].Personal_Interview}
                      </td>
                      <td>
                      {pland[2]?.Research_Approach[0].Personal_Interview}
                      </td>
                      <td> {pland[3]?.Research_Approach[0].Personal_Interview}</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td class="text-left">
                        <b>Personal Interview<span className="planquess ms-1"><i class="bi bi-question-circle"></i></span><span className="planTooltips">Customized Cost Applicable
                        </span></b>
                      </td>
                      <td>
                      {pland[0]?.Research_Approach[0].Telephonic_Interviews}
                      </td>
                      <td>
                      {pland[1]?.Research_Approach[0].Telephonic_Interviews}
                      </td>
                      <td>
                      {pland[2]?.Research_Approach[0].Telephonic_Interviews}
                      </td>
                      <td>{pland[3]?.Research_Approach[0].Telephonic_Interviews}</td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem3"
                    aria-expanded="false"
                    aria-controls="groupItem3"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Digital Survey Type</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem3"
                    className="collapse collapse-horizontal"
                  >
                    <tr>
                      <td class="text-left">
                        <b>Classic Digital Survey</b>
                      </td>
                      <td>
                      {pland[0]?.Digital_Survey_Type[0].Classic_Digital_Survey}
                      </td>
                      <td>
                      {pland[1]?.Digital_Survey_Type[0].Classic_Digital_Survey}
                      </td>
                      <td>
                      {pland[2]?.Digital_Survey_Type[0].Classic_Digital_Survey}
                      </td><td>
                      {pland[3]?.Digital_Survey_Type[0].Classic_Digital_Survey}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Vernacular Survey</b>
                      </td>
                      <td>
                      {pland[0]?.Digital_Survey_Type[0].Vernacular_Survey}
                      </td>
                      <td>
                      {pland[1]?.Digital_Survey_Type[0].Vernacular_Survey}
                      </td>
                      <td>
                      {pland[2]?.Digital_Survey_Type[0].Vernacular_Survey}
                      </td>
                      <td>{pland[3]?.Digital_Survey_Type[0].Vernacular_Survey}</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td class="text-left">
                        <b>Chat Based Surveys<span className="planquess ms-1"><i class="bi bi-question-circle"></i></span><span className="planTooltips">Differential Panel fee applicable
                        </span></b>
                      </td>
                      <td>
                      {pland[0]?.Digital_Survey_Type[0].Chat_Based_Surveys}
                      </td>
                      <td>
                      {pland[1]?.Digital_Survey_Type[0].Chat_Based_Surveys}
                      </td>
                      <td>
                      {pland[2]?.Digital_Survey_Type[0].Chat_Based_Surveys}
                      </td>
                      <td>{pland[3]?.Digital_Survey_Type[0].Chat_Based_Surveys}</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td class="text-left">
                        <b>Audio Survey<span className="planquess ms-1"><i class="bi bi-question-circle"></i></span><span className="planTooltips">Differential Panel fee applicable
                        </span></b>
                      </td>
                      <td>
                      {pland[0]?.Digital_Survey_Type[0].Audio_Survey}
                      </td>
                      <td>
                      {pland[1]?.Digital_Survey_Type[0].Audio_Survey}
                      </td>
                      <td>
                      {pland[2]?.Digital_Survey_Type[0].Audio_Survey}
                      </td>
                      <td>{pland[3]?.Digital_Survey_Type[0].Audio_Survey}</td>
                    </tr>
                    <tr className="Tooltipexampl">
                      <td class="text-left">
                        <b>Video Survey<span className="planquess ms-1"><i class="bi bi-question-circle"></i></span><span className="planTooltips">Differential Panel fee applicable
                        </span></b>
                      </td>
                      <td>
                      {pland[0]?.Digital_Survey_Type[0].Video_Survey}
                      </td>
                      <td>
                      {pland[1]?.Digital_Survey_Type[0].Video_Suevey}
                      </td>
                      <td>
                      {pland[2]?.Digital_Survey_Type[0].Video_Survey}
                      </td>
                      <td>{pland[3]?.Digital_Survey_Type[0].Video_Survey}</td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem6"
                    aria-expanded="false"
                    aria-controls="groupItem6"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Survey Designing</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem6"
                    className="collapse collapse-horizontal"
                  >
                    <tr>
                      <td class="text-left">
                        <b>No. of Questions</b>
                      </td>
                      <td>{pland[0]?.Survey_Designing[0].Number_of_Questions}</td>
                      <td>{pland[1]?.Survey_Designing[0].Number_of_Questions}</td>
                      <td>{pland[2]?.Survey_Designing[0].Number_of_Questions}</td>
                      <td>{pland[3]?.Survey_Designing[0].Number_of_Questions}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Survey Layout</b>
                      </td>
                      <td>{pland[0]?.Survey_Designing[0].Survey_Layout}</td>
                      <td>{pland[1]?.Survey_Designing[0].Survey_Layout}</td>
                      <td>{pland[2]?.Survey_Designing[0].Survey_Layout}</td>
                      <td>{pland[3]?.Survey_Designing[0].Survey_Layout}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Questions Logics</b>
                      </td>
                      <td>{pland[0]?.Survey_Designing[0].Questions_Logics}</td>
                      <td>{pland[1]?.Survey_Designing[0].Questions_Logics}</td>
                      <td>{pland[2]?.Survey_Designing[0].Questions_Logics}</td>
                      <td>{pland[3]?.Survey_Designing[0].Questions_Logics}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Languages</b>
                      </td>
                      <td>{pland[0]?.Survey_Designing[0].Languages}</td>
                      <td>{pland[1]?.Survey_Designing[0].Languages}</td>
                      <td>{pland[2]?.Survey_Designing[0].Languages}</td>
                      <td>{pland[3]?.Survey_Designing[0].Languages}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Font Types & Sizes</b>
                      </td>
                      <td>{pland[0]?.Survey_Designing[0].Font_Types_and_Sizes}</td>
                      <td>{pland[1]?.Survey_Designing[0].Font_Types_and_Sizes}</td>
                      <td>{pland[2]?.Survey_Designing[0].Font_Types_and_Sizes}</td>
                      <td>{pland[3]?.Survey_Designing[0].Font_Types_and_Sizes}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Customizing Header & Footer</b>
                      </td>
                      <td>{pland[0]?.Survey_Designing[0].Customizing_Header_and_Footer}</td>
                      <td>{pland[1]?.Survey_Designing[0].Customizing_Header_and_Footer}</td>
                      <td>{pland[2]?.Survey_Designing[0].Customizing_Header_and_Footer}</td>
                      <td>{pland[3]?.Survey_Designing[0].Customizing_Header_and_Footer}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Whitelisting surveys</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><i class="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Personal branding</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><i class="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>API Integrations</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td><i class="bi bi-check-circle"></i></td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem4"
                    aria-expanded="false"
                    aria-controls="groupItem4"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Question Types</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem4"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td class="text-left">
                        <b>Contact Information</b>
                      </td>
                      <td>
                      {pland[0]?.Question_Type[0].Contact_Information}
                      </td>
                      <td>
                        {pland[1]?.Question_Type[0].Contact_Information}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Contact_Information}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Contact_Information}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Single line text Input</b>
                      </td>
                      <td>
                        {pland[0]?.Question_Type[0].Single_line_text_Input}
                      </td>
                      <td>
                        {pland[1]?.Question_Type[0].Single_line_text_Input}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Single_line_text_Input}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Single_line_text_Input}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Multiple Choice</b>
                      </td>
                      <td>
                        {pland[0]?.Question_Type[0].Multiple_Choice}
                      </td>
                      <td>
                        {pland[1]?.Question_Type[0].Multiple_Choice}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Multiple_Choice}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Multiple_Choice}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Number Input</b>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td><i class="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Radio Option</b>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td><i class="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Multiple Choice</b>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td><i class="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Rating Question</b>
                      </td>
                      <td>
                        {pland[0]?.Question_Type[0].Rating_Question}
                      </td>
                      <td>
                        {pland[1]?.Question_Type[0].Rating_Question}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Rating_Question}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Rating_Question}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>NPS</b>
                      </td>
                      <td>
                        {pland[0]?.Question_Type[0].NPS}
                      </td>
                      <td>
                        {pland[1]?.Question_Type[0].NPS}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].NPS}
                      </td>
                      <td>{pland[3]?.Question_Type[0].NPS}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>CSAT</b>
                      </td>
                      <td>
                         {pland[0]?.Question_Type[0].CSAT}
                      </td>
                      <td>
                         {pland[1]?.Question_Type[0].CSAT}
                      </td>
                      <td>
                         {pland[2]?.Question_Type[0].CSAT}
                      </td>
                      <td> {pland[3]?.Question_Type[0].CSAT}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Matrix Question</b>
                      </td>
                      <td>
                      {pland[0]?.Question_Type[0].Mtrix_Question}
                     
                      </td>
                      <td>
                        {pland[1]?.Question_Type[0].Mtrix_Question}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Mtrix_Question}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Mtrix_Question}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Emoji Rating scale</b>
                      </td>
                      <td>
                      {pland[0]?.Question_Type[0].Emoji_Rating_scale}
                      </td>
                      <td>
                         {pland[1]?.Question_Type[0].Emoji_Rating_scale}
                      </td>
                      <td>
                         {pland[2]?.Question_Type[0].Emoji_Rating_scale}
                      </td>
                      <td> {pland[3]?.Question_Type[0].Emoji_Rating_scale}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Ranking</b>
                      </td>
                      <td>
                      {pland[0]?.Question_Type[0].Ranking}
                      </td>
                      <td>
                        {pland[1]?.Question_Type[0].Ranking}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Ranking}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Ranking}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Opinion scale</b>
                      </td>
                      <td>
                       {pland[0]?.Question_Type[0].Opinion_scale}
                      </td>
                      <td>
                        {pland[1]?.Question_Type[0].Opinion_scale}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Opinion_scale}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Opinion_scale}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>2 column checkbox</b>
                      </td>
                      <td>
                      {pland[0]?.Question_Type[0].two_column_checkbox}
                      </td>
                      <td>
                      {pland[1]?.Question_Type[0].two_column_checkbox}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].two_column_checkbox}
                      </td>
                      <td>{pland[3]?.Question_Type[0].two_column_checkbox}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Checkbox Grid</b>
                      </td>
                      <td>
                       {pland[0]?.Question_Type[0].Checkbox_Grid}
                      </td>
                      <td>
                       {pland[1]?.Question_Type[0].Checkbox_Grid}
                      </td>
                      <td>
                      {pland[2]?.Question_Type[0].Checkbox_Grid}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Checkbox_Grid}</td>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <b>Checkbox with Others Grid</b>
                      </td>
                      <td>
                        <i class="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-x-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td><i class="bi bi-check-circle"></i></td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>NPS Grid</b>
                      </td>
                      <td>
                       {pland[0]?.Question_Type[0].NPS_Grid}
                      </td>
                      <td>
                       {pland[1]?.Question_Type[0].NPS_Grid}
                      </td>
                      <td>
                         {pland[2]?.Question_Type[0].NPS_Grid}
                      </td>
                      <td> {pland[3]?.Question_Type[0].NPS_Grid}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Dropdown Grid</b>
                      </td>
                      <td>
                     {pland[0]?.Question_Type[0].Dropdown_Grid}
                      </td>
                      <td>
                     {pland[1]?.Question_Type[0].Dropdown_Grid}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Dropdown_Grid}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Dropdown_Grid}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Dropdown with other Grid</b>
                      </td>
                      <td>
                        {pland[0]?.Question_Type[0].Dropdown_with_other_Grid}
                      </td>
                      <td>
                        {pland[1]?.Question_Type[0].Dropdown_with_other_Grid}
                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Dropdown_with_other_Grid}
                      </td>
                      <td>{pland[3]?.Question_Type[0].Dropdown_with_other_Grid}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Single line Text Grid</b>
                      </td>
                      <td>
                        {pland[0]?.Question_Type[0].Single_line_Text_Grid}

                      </td>
                      <td>
                       {pland[1]?.Question_Type[0].Single_line_Text_Grid}

                      </td>
                      <td>
                        {pland[2]?.Question_Type[0].Single_line_Text_Grid}

                      </td>
                      <td>{pland[3]?.Question_Type[0].Single_line_Text_Grid}
</td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem5"
                    aria-expanded="false"
                    aria-controls="groupItem5"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Online FGD Delivarables</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem5"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td class="text-left">
                        <b>Discussion Guide (combined)</b>
                      </td>
                      <td>
                      {pland[0]?.Online_FGD_Delivarables[0].Discussion_Guide_combined}
                      </td>
                      <td>
                      {pland[1]?.Online_FGD_Delivarables[0].Discussion_Guide_combined}
                      </td>
                      <td>
                      {pland[2]?.Online_FGD_Delivarables[0].Discussion_Guide_combined}
                      </td>
                      <td> {pland[3]?.Online_FGD_Delivarables[0].Discussion_Guide_combined}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Transcripts (per group)</b>
                      </td>
                      <td>
                      {pland[0]?.Online_FGD_Delivarables[0].Discussion_Guide_combined}
                      </td>
                      <td>
                        {pland[1]?.Online_FGD_Delivarables[0].Transcripts_per_group}
                      </td>
                      <td>
                        {pland[2]?.Online_FGD_Delivarables[0].Transcripts_per_group}
                      </td>
                      <td>{pland[3]?.Online_FGD_Delivarables[0].Transcripts_per_group}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Recordings (per group)</b>
                      </td>
                      <td>
                      {pland[0]?.Online_FGD_Delivarables[0].Recordings_per_group}
                      </td>
                      <td>
                       {pland[1]?.Online_FGD_Delivarables[0].Recordings_per_group}
                      </td>
                      <td>
                       {pland[2]?.Online_FGD_Delivarables[0].Recordings_per_group}
                      </td>
                      <td>{pland[3]?.Online_FGD_Delivarables[0].Recordings_per_group}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Live FGD Link (per group)</b>
                      </td>
                      <td>
                          {pland[0]?.Online_FGD_Delivarables[0].Live_FGD_Link_per_group}
                      </td>
                      <td>
                          {pland[1]?.Online_FGD_Delivarables[0].Live_FGD_Link_per_group}
                      </td>
                      <td>
                       {pland[2]?.Online_FGD_Delivarables[0].Live_FGD_Link_per_group}
                      </td>
                      <td>  {pland[3]?.Online_FGD_Delivarables[0].Live_FGD_Link_per_group}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Reporting (combined)</b>
                      </td>
                      <td>
                        {pland[0]?.Online_FGD_Delivarables[0].Reporting_combined}
                      </td>
                      <td>
                        {pland[1]?.Online_FGD_Delivarables[0].Reporting_combined}
                      </td>
                      <td>
                        {pland[2]?.Online_FGD_Delivarables[0].Reporting_combined}
                      </td>
                      <td>{pland[3]?.Online_FGD_Delivarables[0].Reporting_combined}</td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem91"
                    aria-expanded="false"
                    aria-controls="groupItem5"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Survey Time Data</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem91"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td class="text-left">
                        <b>Real Time Access To Data</b>
                      </td>
                      <td>
                        {pland[0]?.Survey_Time_Data[0].Real_Time_Access_To_Data}
                      </td>
                      <td>
                        {pland[1]?.Survey_Time_Data[0].Real_Time_Access_To_Data}
                      </td>
                      <td>
                        {pland[2]?.Survey_Time_Data[0].Real_Time_Access_To_Data}
                      </td>
                      <td>{pland[3]?.Survey_Time_Data[0].Real_Time_Access_To_Data}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Download Data to Excel/CSV</b>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td>
                        <i class="bi bi-check-circle"></i>
                      </td>
                      <td><i class="bi bi-check-circle"></i></td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem92"
                    aria-expanded="false"
                    aria-controls="groupItem5"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Report and Analysis</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem92"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td class="text-left">
                        <b>Dashboard Analytics (User Customized)</b>
                      </td>
                      <td>
                        {pland[0]?.Report_and_Analysis[0].Dashboard_Analytics_User_Customized}
                      </td>
                      <td>
                        {pland[1]?.Report_and_Analysis[0].Dashboard_Analytics_User_Customized}
                      </td>
                      <td>
                        {pland[2]?.Report_and_Analysis[0].Dashboard_Analytics_User_Customized}
                      </td>
                      <td>{pland[3]?.Report_and_Analysis[0].Dashboard_Analytics_User_Customized}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Online Survey Analytics</b>
                      </td>
                      <td>
                      {pland[0]?.Report_and_Analysis[0].Online_Survey_Analytics}
                      </td>
                      <td>
                        {pland[1]?.Report_and_Analysis[0].Online_Survey_Analytics}
                      </td>
                      <td>
                        {pland[2]?.Report_and_Analysis[0].Online_Survey_Analytics}
                      </td>
                      <td>{pland[3]?.Report_and_Analysis[0].Online_Survey_Analytics}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Response Count Summary</b>
                      </td>
                      <td>
                      {pland[0]?.Report_and_Analysis[0].Response_Count_Summary}
                      </td>
                      <td>
                        {pland[1]?.Report_and_Analysis[0].Response_Count_Summary}
                      </td>
                      <td>
                        {pland[2]?.Report_and_Analysis[0].Response_Count_Summary}
                      </td>
                      <td>{pland[3]?.Report_and_Analysis[0].Response_Count_Summary}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Response Reports</b>
                      </td>
                      <td>
                      {pland[0]?.Report_and_Analysis[0].Response_Reports}
                      </td>
                      <td>
                        {pland[1]?.Report_and_Analysis[0].Response_Reports}
                      </td>
                      <td>
                        {pland[2]?.Report_and_Analysis[0].Response_Reports}
                      </td>
                      <td>{pland[3]?.Report_and_Analysis[0].Response_Reports}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Download Report in PDF</b>
                      </td>
                      <td>
                      {pland[0]?.Report_and_Analysis[0].Download_Report_in_PDF}
                      </td>
                      <td>
                        {pland[1]?.Report_and_Analysis[0].Download_Report_in_PDF}
                      </td>
                      <td>
                        {pland[2]?.Report_and_Analysis[0].Download_Report_in_PDF}
                      </td>
                      <td>{pland[3]?.Report_and_Analysis[0].Download_Report_in_PDF}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Download Report in PPT</b>
                      </td>
                      <td>
                        {pland[0]?.Report_and_Analysis[0].Download_Report_in_PPT}
                      </td>
                      <td>
                        {pland[1]?.Report_and_Analysis[0].Download_Report_in_PPT}
                      </td>
                      <td>
                        {pland[2]?.Report_and_Analysis[0].Download_Report_in_PPT}
                      </td>
                      <td>{pland[3]?.Report_and_Analysis[0].Download_Report_in_PPT}</td>
                    </tr>
                    <tr>
                      <td class="text-left">
                        <b>Detailed Consultative Report</b>
                      </td>
                      <td>
                        {pland[0]?.Report_and_Analysis[0].Detailed_Consultative_Report}
                      </td>
                      <td>
                        {pland[1]?.Report_and_Analysis[0].Detailed_Consultative_Report}
                      </td>
                      <td>
                        {pland[2]?.Report_and_Analysis[0].Detailed_Consultative_Report}
                      </td>
                      <td>{pland[3]?.Report_and_Analysis[0].Detailed_Consultative_Report}</td>
                    </tr>
                  </tbody>
                  <thead
                    className="collapsed CollapGrounp"
                    data-bs-toggle="collapse"
                    data-bs-target="#groupItem93"
                    aria-expanded="false"
                    aria-controls="groupItem5"
                  >
                    <tr className="text-left text-default bg-light-green">
                      <th colSpan={5}>
                        <span className="d-flex align-items-center position-relative">
                          <i
                            className="fa fa-angle-right fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <i
                            className="fa fa-angle-down fa-2x text-orange position-absolute"
                            aria-hidden="true"
                          />
                          <span>Consultation</span>
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    id="groupItem93"
                    className="collapse collapse-horizontal"
                    style={{}}
                  >
                    <tr>
                      <td class="text-left">
                        <b>Chat Support</b>
                      </td>
                      <td>
                      {pland[0]?.Consultation[0].Chat_Support}
                      </td>
                      <td>
                        {pland[1]?.Consultation[0].Chat_Support}
                      </td>
                      <td>
                        {pland[2]?.Consultation[0].Chat_Support}
                      </td>
                      <td>{pland[3]?.Consultation[0].Chat_Support}</td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <div ref={modelOpen1} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#trynowtog"></div>
          <Footer />
        </>
      ) : (
        <Payment amount={amount} Package={Package} monthly={monthly} />
      )}
    </>
  );
};

export default PlanManagement;
