import { useState } from "react";

function Dropdown({options,selectedOption,setType,index,editDropdown,dropdownName,user}) {
// console.log(user,">>>>>>>>>")
    const [active,setActive]=useState(false)

    const callFn=(option,index)=>{
        if(setType ){
            setType(option,index) 
       if(option.value!=='add' && (!option.plan || option.plan.includes(user.plan_id))){ setActive(!active)}
    }
}

    return (
        <div className="dropdownNainji ">
            <div className="dropdownNainji-btn" onClick={()=>setActive(!active)}>{selectedOption}
            <span className="fa fa-caret-down"></span>
            </div>
            { active  &&  (<div className="dropdownNainji-content">
                
                {options.map((option,ind)=><div className={option.plan && !option.plan.includes(user?.plan_id)?"tooltip w-100 text-dark"  :"text-dark"}><div onClick={()=>{callFn(option,index)}} className={option.value=="add" ||options[0].value=='add'?"dropdownNainji-item1 " :"dropdownNainji-item "}>
                    {(options[0].value!=='add'|| ind == 0)&& <> <span >{option.name?option.name:option}{option.plan && !option.plan.includes(user.plan_id)&&<i class="bi bi-lock-fill pl-3"></i>}</span><div ><span className="text-danger mr-2"></span>
                    {option.plan && !option.plan.includes(user.plan_id)&&<span className="tooltiptextt w-100 mt-4">Feature not available .Upgrade your plan to unlock this feature.</span>}</div></>}
                    {options[0].value=='add'&& ind > 0 &&<input value={option} onChange={(e)=>editDropdown("edit",ind,dropdownName,e)}/>}
                    {options[0].value=='add'&& ind > 0 &&<div className="dropdownEdit"><i className="bi bi-trash3 m-2 " onClick={(e)=>editDropdown("del",ind,dropdownName,e)}></i></div>}
                <span><img src={option.icon?option.icon:""} width={30}></img></span>
                </div></div>)}
                
                
            </div>)}


        </div>
    )
}

export default Dropdown;