import React, { useEffect, useState } from "react";
import './npsCss.css'
import NpsShare from "./npsShare";
import JoditEditor from "jodit-react";

const NpsEmailShare = (props) => {
    const [screen, setScreen] = useState(1)
    const [editor,setEditor] = useState()
    const [contant,setContant]= useState()

    useEffect(() => {
        props.screen && setScreen(props.screen)
    }, [])


    return (
        <>

            {screen == 1 && <main className="mainSec">
                <section className="main-dash-sec bg-white">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3">
                                <div
                                    className=" p-3 position-relative Mmail_Email"
                                    style={{ height: "90vh" }}
                                >
                                    <div className="mb-2" onClick={() => { setScreen(0) }}>
                                        <button className="border-0 bg-transparent d-flex align-items-center ">

                                            <img src="../img/back-orange.svg" alt="arrow" width="10px" />
                                            <h6 className="org-clr m-0 mx-2">Back </h6>
                                        </button>
                                        <h5 className="blue-clr fw-semibold">Email</h5>
                                    </div>
                                    <hr className="text-secondary" />
                                    <div className="d-flex">
                                        <div
                                            className="nav flex-column nav-pills w-100 Email-2-share me-3"
                                            id="v-pills-tab"
                                            role="tablist"
                                            aria-orientation="vertical"
                                        >
                                            <div className="First_tabs d-flex align-items-center mb-4 border-0">
                                                <button
                                                    className="nav-link active text-black fontsi13 text-start w-75"
                                                    id="v-pills-home-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-home"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="v-pills-home"
                                                    aria-selected="true"
                                                >
                                                    Survey Trigger Mail
                                                </button>
                                                <label
                                                    className="switch text-black"
                                                    style={{ marginTop: 10 }}
                                                >
                                                    <input type="checkbox" />
                                                    <span
                                                        className="slider round mb-0"
                                                        style={{ right: "-75px" }}
                                                    />
                                                </label>
                                            </div>
                                            <div className="First_tabs_2 d-flex align-items-center">
                                                <button
                                                    className="nav-link text-black fontsi13 w-75 text-start"
                                                    id="v-pills-profile-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-profile"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="v-pills-profile"
                                                    aria-selected="false"
                                                >
                                                    Thank you email
                                                </button>
                                                <label
                                                    className="switch text-black"
                                                    style={{ marginTop: 10 }}
                                                >
                                                    <input type="checkbox" />
                                                    <span
                                                        className="slider round mb-0"
                                                        style={{ right: "-75px" }}
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mx-auto position-absolute bottom-0 left-0  w-100">
                                        <button
                                            className="ntun-mn bg-nones my-3"
                                            data-bs-target="#exampleModalToggleSurveyT"
                                            data-bs-toggle="modal"
                                        >
                                            Share Selected Mail
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 bg-white">
                                <div
                                    className="Email_2 scrl"
                                    style={{ overflow: "auto", height: 600, overflowX: "hidden" }}
                                >
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="v-pills-home"
                                            role="tabpanel"
                                            aria-labelledby="v-pills-home-tab"
                                            tabIndex={0}
                                        >
                                            <div className="row">
                                                <div className="col-md-8 mx-auto p-4 all-0p">
                                                    <div className="shadow mx-auto">
                                                        <div className="Email-header p-3 pb-0 position-relative" style={{ padding: '0px !important' }}>
                                                            <div
                                                                className="Custmize_setting position-absolute"
                                                                style={{
                                                                    right: "-79px",
                                                                    top: 49,
                                                                    transform: "rotate(90deg)",
                                                                    borderRadius: "0px 5px 5px 0px"
                                                                }}
                                                            >
                                                                <button
                                                                    className="border-0 bg-transparent"
                                                                    data-bs-toggle="offcanvas"
                                                                    data-bs-target="#offcanvasRight"
                                                                    aria-controls="offcanvasRight"
                                                                >
                                                                    <span className="blue-clr cus fw-bold text-end">
                                                                        <i className="bi bi-gear-fill" />
                                                                        &nbsp; Customize
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="border-bottom d-flex align-items-center pb-2">
                                                                <h6 className="gry-clr m-0 fontsi14 fw-semibold">To :</h6>
                                                                <div className=" d-flex align-items-center overflow-auto">
                                                                    <span className="d-flex mx-3 px-2 text-black span_emil">
                                                                        <p className="m-0">Abs Email</p>{" "}
                                                                        <button className="bg-transparent border-0 text-end text-black">
                                                                            <i className="bi bi-x-lg" />
                                                                        </button>
                                                                    </span>
                                                                    <span className="d-flex mx-2 px-2 text-black span_emil">
                                                                        <p className="m-0">Abs Email</p>{" "}
                                                                        <button className="bg-transparent border-0 text-end">
                                                                            <i className="bi bi-x-lg" />
                                                                        </button>
                                                                    </span>
                                                                    <span className="d-flex mx-2 px-2 text-black span_emil">
                                                                        <p className="m-0">Abs Email</p>{" "}
                                                                        <button className="bg-transparent border-0 text-end">
                                                                            <i className="bi bi-x-lg" />
                                                                        </button>
                                                                    </span>
                                                                    <span className="d-flex mx-2 px-2 text-black span_emil">
                                                                        <p className="m-0">Abs Email</p>{" "}
                                                                        <button className="bg-transparent border-0 text-end">
                                                                            <i className="bi bi-x-lg" />
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="border-bottom d-flex align-items-center pb-2 my-3">
                                                                <h6 className="gry-clr m-0 fontsi14 fw-semibold">Subject :</h6>
                                                                <span className="fontsi12 mx-3">
                                                                    Thank you for helping us out!
                                                                </span>
                                                            </div>
                                                            <div className="border-bottom d-flex align-items-center pb-2 my-0 mb-0">
                                                                <h6 className="gry-clr m-0 fontsi14 fw-semibold">From :</h6>
                                                                <span className="fontsi12 mx-3">
                                                                    yourmail@gmail.com
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="Mail_body " style={{ maxWidth: 700 }}>
                                                            <div className="border-orange-top" />
                                                            <div className="Response-Email p-3 pb-0 w-100">
                                                                <table align="center" cellespacing={0}>
                                                                    <tbody>
                                                                        <tr align="center">
                                                                            <td
                                                                                style={{ paddingTop: 30, paddingBottom: 0 }}
                                                                            >
                                                                                <img
                                                                                    src="../img/new-logo.png"
                                                                                    alt=""
                                                                                    width="160px"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr align="center">
                                                                            <td style={{ padding: 0, margin: 0 }}>
                                                                                <img
                                                                                    src="../img/Mail-fif.gif"
                                                                                    alt=""
                                                                                    width="300pxpx"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr style={{ padding: "0px 10px" }}>
                                                                            <td className="fontsi14">
                                                                                Hi {"{"}contact.fullName{"}"},
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td />
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="fontsi14">
                                                                                Thank you for responding to our survey.
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="fontsi14">
                                                                                <div className="w-100">
                                                                                    <p style={{ whiteSpace: "normal" }}>
                                                                                        {" "}
                                                                                        We appreciate you for contributing your
                                                                                        valuable time, your honest information,
                                                                                        and your thoughtful suggestions.{" "}
                                                                                    </p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="fontsi14">Best, </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="fontsi14">
                                                                                Team {"{"}companyName{"}"}{" "}
                                                                            </td>
                                                                        </tr>
                                                                        <tr style={{ backgroundColor: "#e5e5e5" }}>
                                                                            <td
                                                                                align="center"
                                                                                style={{ padding: 0, margin: 0 }}
                                                                            >
                                                                                <table
                                                                                    cellPadding={0}
                                                                                    cellSpacing={0}
                                                                                    align="center"
                                                                                    role="presentation"
                                                                                    style={{
                                                                                        msoTableLspace: "0pt",
                                                                                        msoTableRspace: "0pt",
                                                                                        borderCollapse: "collapse",
                                                                                        borderSpacing: 0
                                                                                    }}
                                                                                >
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td
                                                                                                align="center"
                                                                                                style={{
                                                                                                    padding: 10,
                                                                                                    margin: 0,
                                                                                                    fontSize: 18,
                                                                                                    color: "rgb(255, 255, 255)",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif'
                                                                                                }}
                                                                                            >
                                                                                                <a
                                                                                                    className="appstore logoinr"
                                                                                                    href="#"
                                                                                                >
                                                                                                    <img
                                                                                                        src="../img/new-logo.png"
                                                                                                        alt="facebook-link"
                                                                                                    />
                                                                                                </a>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                align="center"
                                                                                                style={{ padding: 0, margin: 0 }}
                                                                                            >
                                                                                                <table
                                                                                                    cellPadding={0}
                                                                                                    cellSpacing={0}
                                                                                                    align="center"
                                                                                                    role="presentation"
                                                                                                    style={{
                                                                                                        msoTableLspace: "0pt",
                                                                                                        msoTableRspace: "0pt",
                                                                                                        borderCollapse: "collapse",
                                                                                                        borderSpacing: 0
                                                                                                    }}
                                                                                                >
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                valign="top"
                                                                                                                width={50}
                                                                                                                id="esd-menu-id-0"
                                                                                                                style={{
                                                                                                                    margin: 0,
                                                                                                                    paddingLeft: 5,
                                                                                                                    paddingRight: 5,
                                                                                                                    paddingTop: 10,
                                                                                                                    paddingBottom: 10,
                                                                                                                    border: 0
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    className="appstore"
                                                                                                                    href="#"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="../img/Facebooks.svg"
                                                                                                                        alt="facebook-link"
                                                                                                                        width={35}
                                                                                                                        height={35}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                valign="top"
                                                                                                                width={50}
                                                                                                                id="esd-menu-id-1"
                                                                                                                style={{
                                                                                                                    margin: 0,
                                                                                                                    paddingLeft: 5,
                                                                                                                    paddingRight: 5,
                                                                                                                    paddingTop: 10,
                                                                                                                    paddingBottom: 10,
                                                                                                                    border: 0
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    className="playstore"
                                                                                                                    href="#"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="../img/tweeter.svg"
                                                                                                                        alt="pintrest-link"
                                                                                                                        width={35}
                                                                                                                        height={35}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                valign="top"
                                                                                                                width={50}
                                                                                                                id="esd-menu-id-2"
                                                                                                                style={{
                                                                                                                    margin: 0,
                                                                                                                    paddingLeft: 5,
                                                                                                                    paddingRight: 5,
                                                                                                                    paddingTop: 10,
                                                                                                                    paddingBottom: 10,
                                                                                                                    border: 0
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    className="appstore"
                                                                                                                    href="#"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="../img/Insta.svg"
                                                                                                                        alt="insta-link"
                                                                                                                        width={35}
                                                                                                                        height={35}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                valign="top"
                                                                                                                width={50}
                                                                                                                id="esd-menu-id-2"
                                                                                                                style={{
                                                                                                                    margin: 0,
                                                                                                                    paddingLeft: 5,
                                                                                                                    paddingRight: 5,
                                                                                                                    paddingTop: 10,
                                                                                                                    paddingBottom: 10,
                                                                                                                    border: 0
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    className="playstore"
                                                                                                                    href="#"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="../img/link.svg"
                                                                                                                        alt="youtube-link"
                                                                                                                        width={35}
                                                                                                                        height={35}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                align="center"
                                                                                                style={{
                                                                                                    color: "#818E94",
                                                                                                    fontSize: 15,
                                                                                                    height: 30,
                                                                                                    fontWeight: 400,
                                                                                                    textAlign: "center",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif'
                                                                                                }}
                                                                                            >
                                                                                                Copyright © 2020 QDegrees
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                align="center"
                                                                                                style={{
                                                                                                    color: "#818E94",
                                                                                                    fontSize: 15,
                                                                                                    height: 50,
                                                                                                    fontWeight: 400,
                                                                                                    textAlign: "center",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif'
                                                                                                }}
                                                                                            >
                                                                                                Unsubscribe
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="v-pills-profile"
                                            role="tabpanel"
                                            aria-labelledby="v-pills-profile-tab"
                                            tabIndex={0}
                                        >
                                            <div className="row">
                                                <div className="col-md-8 mx-auto p-4 all-0p">
                                                    <div className="shadow mx-auto">
                                                        <div className="Email-header p-3 pb-0 position-relative">
                                                            <div
                                                                className="Custmize_setting position-absolute"
                                                                style={{
                                                                    right: "-79px",
                                                                    top: 49,
                                                                    transform: "rotate(90deg)",
                                                                    borderRadius: "0px 5px 5px 0px"
                                                                }}
                                                            >
                                                                <button
                                                                    className="border-0 bg-transparent"
                                                                    data-bs-toggle="offcanvas"
                                                                    data-bs-target="#offcanvasRight"
                                                                    aria-controls="offcanvasRight"
                                                                >
                                                                    <span className="blue-clr cus fw-bold text-end">
                                                                        <i className="bi bi-gear-fill" />
                                                                        &nbsp; Customize
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="border-bottom d-flex align-items-center pb-2">
                                                                <h6
                                                                    className="gry-clr m-0 fontsi14 fw-semibold"
                                                                    style={{ width: "max-content" }}
                                                                >
                                                                    To&nbsp;:
                                                                </h6>
                                                                <div className=" d-flex align-items-center overflow-auto">
                                                                    <span className="d-flex mx-3 mx-sm-2 px-2 text-black span_emil">
                                                                        <p className="m-0">Abs Email</p>{" "}
                                                                        <button className="bg-transparent border-0 text-end text-black">
                                                                            <i className="bi bi-x-lg" />
                                                                        </button>
                                                                    </span>
                                                                    <span className="d-flex mx-2 px-2 text-black span_emil">
                                                                        <p className="m-0">Abs Email</p>{" "}
                                                                        <button className="bg-transparent border-0 text-end">
                                                                            <i className="bi bi-x-lg" />
                                                                        </button>
                                                                    </span>
                                                                    <span className="d-flex mx-2 px-2 text-black span_emil">
                                                                        <p className="m-0">Abs Email</p>{" "}
                                                                        <button className="bg-transparent border-0 text-end">
                                                                            <i className="bi bi-x-lg" />
                                                                        </button>
                                                                    </span>
                                                                    <span className="d-flex mx-2 px-2 text-black span_emil">
                                                                        <p className="m-0">Abs Email</p>{" "}
                                                                        <button className="bg-transparent border-0 text-end">
                                                                            <i className="bi bi-x-lg" />
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="border-bottom d-flex align-items-center pb-2 my-3">
                                                                <h6 className="gry-clr m-0 fontsi14 fw-semibold">Subject :</h6>
                                                                <span className="fontsi12 mx-3">
                                                                    Thank you for helping us out!
                                                                </span>
                                                            </div>
                                                            <div className=" d-flex align-items-center pb-2 my-0 mb-0">
                                                                <h6 className="gry-clr m-0 fontsi14 fw-semibold">From :</h6>
                                                                <span className="fontsi12 mx-3">
                                                                    yourmail@gmail.com
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="Mail_body ">
                                                            <div className="border-orange-top" />
                                                            <div className="Response-Email p-3">
                                                                <table width="100%" align="center" cellespacing={0}>
                                                                    <tbody>
                                                                        <tr align="center">
                                                                            <td
                                                                                style={{ paddingTop: 30, paddingBottom: 0 }}
                                                                            >
                                                                                <img
                                                                                    src="../img/new-logo.png"
                                                                                    alt=""
                                                                                    width="160px"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr align="center">
                                                                            <td style={{ padding: 0, margin: 0 }}>
                                                                                <img
                                                                                    src="../img/Mail-fif.gif"
                                                                                    alt=""
                                                                                    width="300pxpx"
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr style={{ padding: "0px 10px" }}>
                                                                            <td className="fontsi14">
                                                                                Hi {"{"}contact.fullName{"}"},
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td />
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="fontsi14">New Survey</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td
                                                                                className="fontsi14"
                                                                                style={{ whiteSpace: "normal" }}
                                                                            >
                                                                                Lorem Ipsum is simply dummy text of the
                                                                                printing and typesetting industry. Lorem
                                                                                Ipsum has been the industry's standard dummy
                                                                                text ever since the 1500s, when an
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="fontsi14">
                                                                                <a
                                                                                    href="#"
                                                                                    style={{
                                                                                        color: "blue",
                                                                                        marginTop: 30,
                                                                                        marginBottom: 30
                                                                                    }}
                                                                                >
                                                                                    https://vaibhav8877.SurveyCXM.com/builder/edit/376409
                                                                                </a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="fontsi14">
                                                                                Team {"{"}companyName{"}"}{" "}
                                                                            </td>
                                                                        </tr>
                                                                        <tr style={{ backgroundColor: "#FFF" }}>
                                                                            <td
                                                                                align="center"
                                                                                style={{ padding: 0, margin: 0 }}
                                                                            >
                                                                                <table
                                                                                    cellPadding={0}
                                                                                    width="100%"
                                                                                    cellSpacing={0}
                                                                                    align="center"
                                                                                    role="presentation"
                                                                                    style={{
                                                                                        msoTableLspace: "0pt",
                                                                                        msoTableRspace: "0pt",
                                                                                        borderCollapse: "collapse",
                                                                                        borderSpacing: 0
                                                                                    }}
                                                                                >
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td
                                                                                                align="center"
                                                                                                style={{
                                                                                                    padding: 10,
                                                                                                    margin: 0,
                                                                                                    fontSize: 18,
                                                                                                    color: "rgb(255, 255, 255)",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif'
                                                                                                }}
                                                                                            >
                                                                                                <a
                                                                                                    className="appstore logoinr"
                                                                                                    href="#"
                                                                                                >
                                                                                                    <img
                                                                                                        src="../img/new-logo.png"
                                                                                                        alt="doyoursurvey"
                                                                                                    />
                                                                                                </a>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                align="center"
                                                                                                style={{ padding: 0, margin: 0 }}
                                                                                            >
                                                                                                <table
                                                                                                    cellPadding={0}
                                                                                                    cellSpacing={0}
                                                                                                    align="center"
                                                                                                    role="presentation"
                                                                                                    style={{
                                                                                                        msoTableLspace: "0pt",
                                                                                                        msoTableRspace: "0pt",
                                                                                                        borderCollapse: "collapse",
                                                                                                        borderSpacing: 0
                                                                                                    }}
                                                                                                >
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                valign="top"
                                                                                                                width={50}
                                                                                                                id="esd-menu-id-0"
                                                                                                                style={{
                                                                                                                    margin: 0,
                                                                                                                    paddingLeft: 5,
                                                                                                                    paddingRight: 5,
                                                                                                                    paddingTop: 10,
                                                                                                                    paddingBottom: 10,
                                                                                                                    border: 0
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    className="appstore"
                                                                                                                    href="#"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="../img/Facebooks.svg"
                                                                                                                        alt="facebook-link"
                                                                                                                        width={35}
                                                                                                                        height={35}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                valign="top"
                                                                                                                width={50}
                                                                                                                id="esd-menu-id-1"
                                                                                                                style={{
                                                                                                                    margin: 0,
                                                                                                                    paddingLeft: 5,
                                                                                                                    paddingRight: 5,
                                                                                                                    paddingTop: 10,
                                                                                                                    paddingBottom: 10,
                                                                                                                    border: 0
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    className="playstore"
                                                                                                                    href="#"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="../img/tweeter.svg"
                                                                                                                        alt="pintrest-link"
                                                                                                                        width={35}
                                                                                                                        height={35}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                valign="top"
                                                                                                                width={50}
                                                                                                                id="esd-menu-id-2"
                                                                                                                style={{
                                                                                                                    margin: 0,
                                                                                                                    paddingLeft: 5,
                                                                                                                    paddingRight: 5,
                                                                                                                    paddingTop: 10,
                                                                                                                    paddingBottom: 10,
                                                                                                                    border: 0
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    className="appstore"
                                                                                                                    href="#"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="../img/Insta.svg"
                                                                                                                        alt="insta-link"
                                                                                                                        width={35}
                                                                                                                        height={35}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </td>
                                                                                                            <td
                                                                                                                align="center"
                                                                                                                valign="top"
                                                                                                                width={50}
                                                                                                                id="esd-menu-id-2"
                                                                                                                style={{
                                                                                                                    margin: 0,
                                                                                                                    paddingLeft: 5,
                                                                                                                    paddingRight: 5,
                                                                                                                    paddingTop: 10,
                                                                                                                    paddingBottom: 10,
                                                                                                                    border: 0
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    className="playstore"
                                                                                                                    href="#"
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src="../img/link.svg"
                                                                                                                        alt="youtube-link"
                                                                                                                        width={35}
                                                                                                                        height={35}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                align="center"
                                                                                                style={{
                                                                                                    color: "#818E94",
                                                                                                    fontSize: 15,
                                                                                                    height: 30,
                                                                                                    fontWeight: 400,
                                                                                                    textAlign: "center",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif'
                                                                                                }}
                                                                                            >
                                                                                                Copyright © 2020 QDegrees
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td
                                                                                                align="center"
                                                                                                style={{
                                                                                                    color: "#818E94",
                                                                                                    fontSize: 15,
                                                                                                    height: 50,
                                                                                                    fontWeight: 400,
                                                                                                    textAlign: "center",
                                                                                                    fontFamily:
                                                                                                        'arial, "helvetica neue", helvetica, sans-serif'
                                                                                                }}
                                                                                            >
                                                                                                Unsubscribe
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Modal Survey Trigar */}
                <div
                    className="modal fade"
                    id="exampleModalToggleSurveyT"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel"
                    tabIndex={-1}
                >
                    <div className="modal-dialog modal-dialog-centered survey_trigar rounded-0">
                        <div className="modal-content rounded-0">
                            <div className="modal-header">
                                <h1 className="modal-title fs-6 fw-semibold" id="exampleModalToggleLabel">
                                    Survey Trigger
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close fs-6"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <div className="text-center">
                                    <div className="text-center " style={{ display: "flex", justifyContent: "center" }} >
                                        <lottie-player
                                            src="../img/lf30_editor_7nocnjt0.json"
                                            className="mx-auto"
                                            background="transparent"
                                            speed={1}
                                            style={{ width: 215 }}
                                            loop=""
                                            autoPlay=""
                                        ></lottie-player>
                                    </div>
                                    <p className="text-black fontsi14 fw-semibold">Simple Dummy Text Enter</p>
                                </div>
                                <div className="Schedule d-flex justify-content-around">
                                    <button
                                        className="ntun-mns sche"
                                        data-bs-target="#exampleModalToggleSetRem"
                                        data-bs-toggle="modal"
                                    >
                                        Schedule Survey
                                    </button>
                                    <button
                                        className="ntun-mn bg-nones"
                                        data-bs-target="#exampleModalToggleSurveyTsucc"
                                        data-bs-toggle="modal"
                                    >
                                        Survey Trigger Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Survey Trigar Close */}
                {/* Modal Survey set reminder start */}
                <div
                    className="modal fade"
                    id="exampleModalToggleSetRem"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel"
                    tabIndex={-1}
                >
                    <div className="modal-dialog modal-dialog-centered SetRemnder">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-6 fw-semibold" id="exampleModalToggleLabel">
                                    Set Reminder
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <div className="Remindr d-flex justify-content-between align-items-center gap-2">
                                    <div
                                        className="Select-Time border d-flex justify-content-between"
                                        style={{ padding: "12px 20px" }}
                                    >
                                        <span className="fontsi13 fw-semibold">Select Date</span>
                                        <img
                                            src="../img/select_Date.svg"
                                            alt=""
                                            className="ms-4"
                                            width="15px"
                                        />
                                    </div>
                                    {/*  */}
                                    <div
                                        className="Select-Time border d-flex justify-content-between"
                                        style={{ padding: "12px 20px" }}
                                    >
                                        <span className="fontsi13 fw-semibold">Select Time</span>
                                        <img
                                            src="../img/Icon awesome-clock.svg"
                                            alt=""
                                            className="ms-3"
                                            width="17px"
                                        />
                                    </div>
                                    {/*  */}
                                    <div className="Select-Time ">
                                        <img src="../img/plus_reminder.svg" alt="" width="25px" />
                                    </div>
                                    {/*  */}
                                </div>
                                <div className="text-center">
                                    <button className="ntun-mn bg-nonese mt-5 mb-3">
                                        Save Reminder
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Survey set reminder End */}
                {/* Off Canvas E-mail Template start   */}
                <div
                    className="offcanvas offcanvas-end Edt-Email"
                    tabIndex={-1}
                    id="offcanvasRight"
                    aria-labelledby="offcanvasRightLabel"
                >
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title fs-6 fw-semibold" id="offcanvasRightLabel">
                            Edit Mail Template
                        </h5>
                        <hr />
                    </div>
                    <form method="post" action="" encType="multipart/form-data">
                        <div className="offcanvas-body pt-0">
                            <div className="to-Emal d-flex justify-content-between">
                                <h6>
                                    To <img src="../img/i_icon_black.svg" alt="icon" width="18px" />
                                </h6>
                                <button className="border-0 bg-transparent">
                                    <h6 className="org-clr fontsi13">
                                        <img src="../img/Download-d.svg" alt="" />
                                        &nbsp; Download CSV Format
                                    </h6>
                                </button>
                            </div>
                            <div className="input-group mb-1" style={{ height: 42 }}>
                                <input
                                    type="email"
                                    name="user_mail"
                                    className="form-control rounded-0"
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon2"
                                    style={{ height: 42 }}
                                />
                                <input type="file" name="user_mail" id="upload" hidden="" style={{ display: "none" }} />
                                <label
                                    htmlFor="upload"
                                    className="input-group-text fontsi12 imprt rounded-0 text-white"
                                >
                                    + Import Recipents
                                </label>
                                <button
                                    className="input-group-text fontsi12 imprt rounded-0 text-white"
                                    id="basic-addon2"
                                >
                                    <i className="bi bi-plus" /> Import Recipents
                                </button>
                            </div>
                            <div className="text-area d-flex justify-content-between my-2">
                                <h6 className="grays-clr fontsi14 fw-semibold">Subject</h6>
                                <span className="grays-clr fontsi12">24/140</span>
                            </div>
                            <div className="form-floating mb-2 my-2">
                                <textarea
                                    className="form-control"
                                    name="subject"
                                    placeholder="Leave a comment here"
                                    id="floatingTextarea2"
                                    style={{ height: 100 }}
                                    required=""
                                    defaultValue={""}
                                />
                                <label htmlFor="floatingTextarea2" className="fontsi12">
                                    Thank you for helping us out!
                                </label>
                            </div>
                            <select
                                className="form-select selt border-0 fontsi14 mb-2"
                                name="from"
                                aria-label="Default select example"
                            >
                                <option className="" value={1}>
                                    From :&nbsp; yourmail@gmail.com
                                </option>
                                <option value={1}>One</option>
                                <option value={2}>Two</option>
                                <option value={3}>Three</option>
                            </select>
                            <div className="ck-editor mb-2">
                                <h6 className="grays-clr fontsi14 fw-semibold">Mail body</h6>
                                {/* <textarea
                                    name="mail_body"
                                    className="scrl"
                                    id="editor"
                                    style={{ height: 250 }}
                                    required=""
                                    defaultValue={"\n                         "}
                                /> */}
                                <div  className="scrl"
                                    id="editor"
                                    style={{ height: 250 }}>
                                <JoditEditor
                                    ref={editor}
                                    value={contant}
                                    onChange={newContent => setContant(newContent)}
                                />
                                </div>
                            </div>
                            <div className="set">
                                <h5 className="text-black fw-semibold fs-6">SETTINGS</h5>
                                <label className="switch w-100 text-black mb-2 ">
                                    Show Company Logo
                                    <input type="checkbox" name="company_logo" />
                                    <span className="slider round" />
                                </label>
                                <label className="switch w-100 text-black">
                                    Show SurveyCXM Logo
                                    <input type="checkbox" name="surveycxm_logo" defaultChecked="" />
                                    <span className="slider round" />
                                </label>
                            </div>
                            <div className="save-teemp d-flex my-2 ">
                                <button
                                    className="border-0 text-white fontsi14 rounded-1 px-4 py-2 d-flex align-items-center"
                                    style={{ backgroundColor: "#F47321" }}
                                >
                                    Save&nbsp; <i className="bi bi-check2-all fontsi18" />
                                </button>
                                <button
                                    className="border-0 text-black fontsi14 px-4 py-2 bg-transparent"
                                    data-bs-dismiss="offcanvas"
                                    aria-label="Close"
                                >
                                    <u> Cancel</u>{" "}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                {/* Off Canvas E-mail Template  end */}
                {/* modal Survey triggar mail successfully start */}
                <div
                    className="modal fade"
                    id="exampleModalToggleSurveyTsucc"
                    aria-hidden="true"
                    aria-labelledby="exampleModalToggleLabel"
                    tabIndex={-1}
                >
                    <div className="modal-dialog modal-dialog-centered survey_trigar rounded-0">
                        <div className="modal-content rounded-0">
                            <div className="modal-header">
                                <h1 className="modal-title fs-6 fw-semibold" id="exampleModalToggleLabel">
                                    Survey Triggered Successfully
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close fs-6"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <div className="text-center" style={{ display: "flex", justifyContent: "center" }}>
                                    <lottie-player
                                        src="../img/5242-check-orange.json"
                                        className="mx-auto"
                                        background="transparent"
                                        speed={1}
                                        style={{ width: 215 }}
                                        loop=""
                                        autoPlay=""
                                    ></lottie-player>

                                </div>
                                <p className="text-black fontsi14 text-center fw-semibold">
                                    Your Survey is triggered successfully
                                </p>
                                <div className="Schedule text-center mt-2">
                                    <button className="ntun-mn bg-nonese">My Workspace</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* modal Survey triggar mail successfuly end */}
            </main>}
            {screen == 0 && <NpsShare screen={screen} />}
        </>

    )
}
export default NpsEmailShare;