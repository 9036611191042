import "./App.css";

import { Route, HashRouter, Switch } from "react-router-dom";
import Home from "./Pages/Home/Home";
import React, { useEffect } from "react";
import PanelistHome from "./Pages/Panelist/PanelistHome";
import Login from "./Pages/Auth/Login";
import ClientHome from "./Pages/ClientHomeDashboard/ClientHome";
import Signup from "./Pages/Auth/Signup";
import OtpVerify from "./Pages/Auth/OtpVerify";
import Admindash from "./website/admindash";
import AllRecentSyrvey from "./website/allrecentsurvey";
import PanelistDashboard from "./website/panelistDashboard";
import Register from "./Pages/Auth/registration";
import Estimate from "./Pages/ClientHomeDashboard/EstimatePage/Estimate";
import EditProfile from "./website/editProfile";
import { useLocation } from "react-router-dom";

import SurveyWiseResponse from "./admin/surveyWiseResponce/surveyWiseResponce";
import Paytm from "./admin/paytm/paytm";
import Pushnotification from "./admin/pushNotification/Pushnotification";
import Users from "./admin/usersManagement/users";
import SurveyDashboard from "./admin/surveyDashboard/surveyDashboard";
import SurveyRecord from "./admin/surveyRecordAdminDashboard/surveyRecord";
import ProfileSurveyRecord from "./admin/profileSurveyRecordAdminDashboard/profileSurveyRecord";
import AddSurvey from "./admin/AddSurveyAdminDashboard/addSurvey";
import RewardPanelAdminDashboard from "./admin/rewardPanelAdminDashboard/rewardPanelAdminDashboard";
import ProfilePanelAdminDashboard from "./admin/profilePanelAdminDashboard/profilePanelAdminDashboard";
import Report from "./Pages/ClientHomeDashboard/ClientAdmin/report";
import RegReport from "./admin/Registration report/registrationReport";
// import SurveyScreen from './website/surveyScreen';+
import AdminDashboard from "./Pages/ClientHomeDashboard/ClientAdmin/AdminDashboard";
import DirectSurvey from "./website/directSurvey";
import DirectSurveyNew from "./website/directSurveyNew";
import Scratchsurvey from "./website/scratchsurvey2";
import CreateSurvey from "./website/SurveyScreen/Index";
import Templeates from "./website/ClientDashboard/templates";
import TempleateDetails from "./website/ClientDashboard/templeateDetails";
import TargetingQueOption from "./website/ClientDashboard/targetingQueOption";
import TargetingYearOptions from "./website/ClientDashboard/targetingYearOptions";
import Demoreport from "./website/Demoreport";
import PlanPage from "./website/PlanPage";
import "./utils/notification";

import NewDesign from "./website/new-design";
import Chat from "../src/Components/ReusableComponents/Chat";
import CrossTab from "./website/crossTab";
import Avanti from "./website/Avanti/index";
import DownloadExcel from "./website/Avanti/downloadExcel";
import ImageView from "./website/Avanti/ImageView";
import ErrorPage from "./website/404error";
import UserManagement from "./website/userManagement";
import Clientdemo from "./admin/demoSurvey/Clientdemo";
import ClientProfile from './website/ClientProfile'

import PointDistribution from "./admin/Point Distribution/pointDistribution";
import Fgd from './website/FGD/LobbyScreen'
import Video from './website/FGD/room'
// import Room from './website/FGD/groupVC'

import Invoice from "./website/invoice";
import SurveyTemplets from "./website/surveyTemplets";
import Dictaphone from "./website/speechToText";
// import PlanManagement from "./admin/surveyWiseResponce/planmanagement";
import PlanManagement from "./admin/surveyWiseResponce/planmanagement";
import ClientSurvey from "./website/clientSurvey";
import StudentCollegeID from "./admin/StudentApproval/StudentCollegeID";
import SurveyImages from "./website/SurveyScreen/SurveyImages";
import Recording from "./website/FGD/recording";
// import Main from './components/Main/Main';
import Room1 from './website/FGD/components/Room/Room'
import Main from './website/FGD/components/Main/Main'
import styled from 'styled-components';
import NpsReport from "./website/SurveyScreen/nps/npsReport";
import NpsQuestions from "./website/SurveyScreen/nps/allQuestions";
import NpsShare from "./website/SurveyScreen/nps/npsShare";
import NpsEmailShare from "./website/SurveyScreen/nps/npsEmailShare";
import NpsSmsShare from "./website/SurveyScreen/nps/npsSmsShare";
import CsatReport from "./website/SurveyScreen/nps/csatReport";
import DefaultReport from "./website/SurveyScreen/nps/defaultReport"
import DataTable from "./Components/ReusableComponents/excelreport";
import RuparReport from "./Components/ReusableComponents/Rupaynccfasttagreport";
import TestRecording from "./website/testRecording";
import { DynamicOnlyExcel } from "./website/SurveyScreen/nps/onlyReportDynamicFields";
import { RawReport } from "./website/rawReport";
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const token = false;
  if (token) {
    return (
      <HashRouter>
        <React.Suspense>
          <Switch></Switch>
        </React.Suspense>
      </HashRouter>
    );
  } else {
    return (


      <HashRouter>
        <React.Suspense>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/raw/:id"
              name="raw"
              render={(props) => <DynamicOnlyExcel  />}
            />
            <Route
              exact
              path="/rawData/:id"
              name="raw"
              render={(props) => <RawReport  />}
            />
            <Route
              exact
              path="/dashboard"
              name="csat"
              render={(props) => <CsatReport  />}
            />
            <Route
              exact
              path="/dashboardReport"
              name="csat"
              render={(props) => <DefaultReport  />}
            />
            <Route
              exact
              path="/signup/:reffer?"
              name="Login"
              render={(props) => <Signup {...props} />}
            />
            <Route
              exact
              path="/"
              name="Home"
              render={(props) => <Home {...props} />}
            />
            <Route
              exact
              path="/panelist"
              name="Panellist"
              render={(props) => <PanelistHome {...props} />}
            />
            <Route
              exact
              path="/client"
              name="Panellist"
              render={(props) => <ClientHome {...props} />}
            />
            <Route
              exact
              path="/otpVerify"
              name="OTP"
              render={(props) => <OtpVerify {...props} />}
            />
            <Route
              exact
              path="/AdminDash"
              name="OTP"
              render={(props) => <Admindash {...props} />}
            />
            <Route
              exact
              path="/allrecentsurvey"
              name="RecentSurvey"
              render={(props) => <AllRecentSyrvey {...props} />}
            />
            <Route
              exact
              path="/panelistDash"
              name="OTP"
              render={(props) => <PanelistDashboard {...props} />}
            />
             <Route
              exact
              path="/excelreport/:id"
              name="ExcelReport"
              render={(props) => <DataTable {...props} />}
            />
             <Route
              exact
              path="/excel-report/:id"
              name="ExcelReport"
              render={(props) => <RuparReport {...props} />}
            />
            <Route
              exact
              path="/registration"
              name="OTP"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/estimatePage"
              name="OTP"
              render={(props) => <Estimate {...props} />}
            />
            <Route
              exact
              path="/editProfile"
              name="OTP"
              render={(props) => <EditProfile {...props} />}
            />
            <Route
              exact
              path="/surveyTemplates"
              name="OTP"
              render={(props) => <SurveyTemplets {...props} />}
            />
            <Route
              exact
              path="/192837465/surveyWiseResponce"
              name="OTP"
              render={(props) => <SurveyWiseResponse {...props} />}
            />
            <Route
              exact
              path="/paytm"
              name="OTP"
              render={(props) => <Paytm {...props} />}
            />
            <Route
              exact
              path="/192837465/pushnotification"
              name="OTP"
              render={(props) => <Pushnotification {...props} />}
            />
            <Route
              exact
              path="/192837465/sudentcollegeid"
              name="OTP"
              render={(props) => <StudentCollegeID {...props} />}
            />

            <Route
              exact
              path="/192837465/Clientdemo"
              name="OTP"
              render={props => <Clientdemo {...props} />}   //Sid
            />

            <Route
              exact
              path="/192837465/planmanagement"
              name="OTP"
              render={(props) => <PlanManagement {...props} />}
            />


            <Route
              exact
              path="/192837465/SurveyDashboard"
              name="OTP"
              render={(props) => <Users {...props} />}
            />
            <Route
              exact
              path="/192837465/reward-panel-admin-dashboard"
              name="OTP"
              render={(props) => <RewardPanelAdminDashboard {...props} />}
            />
            <Route
              exact
              path="/192837465/adminDashboard"
              name="OTP"
              render={(props) => <SurveyDashboard {...props} />}
            />
            <Route
              exact
              path="/192837465/SurveyRecord"
              name="OTP"
              render={(props) => <SurveyRecord {...props} />}
            />
            <Route
              exact
              path="/192837465/profileSurveyRecord"
              name="OTP"
              render={(props) => <ProfileSurveyRecord {...props} />}
            />
            <Route
              exact
              path="/192837465/add-survey"
              name="OTP"
              render={(props) => <AddSurvey {...props} />}
            />
            <Route
              exact
              path="/192837465/profile-panel-admin-dashboard"
              name="OTP"
              render={(props) => <ProfilePanelAdminDashboard {...props} />}
            />
            <Route
              exact
              path="/192837465/registration-report"
              name="OTP"
              render={(props) => <RegReport {...props} />}
            />
            {/* for survey attempt by email */}
            <Route
              exact
              path="/attemp-survey/:id"
              name="OTP"
              render={(props) => <DirectSurvey {...props} />}
            />
            {/* For reviewing Survey*/}
            <Route
              exact
              path="/review/:id"
              name="OTP"
              render={(props) => <DirectSurvey {...props} />}
            />
            {/* for review Templates*/}
            <Route
              exact
              path="/template/:id"
              name="OTP"
              render={(props) => <DirectSurvey {...props} />}
            />
            {/* for survey attempt by surveyId (by lucid) */}
            <Route
              exact
              path="/fieldSurvey/:id"
              name="OTP"
              render={(props) => <DirectSurvey {...props} />}
            />
            <Route
              exact
              path="/NewSurvey/:id"
              name="OTP"
              render={(props) => <DirectSurvey {...props} />}
            />
            <Route
              exact
              path="/newSurvey/:id/surveyId=:id"
              name="OTP"
              render={(props) => <DirectSurvey {...props} />}
            />
            <Route
              exact
              path="/lucidSurvey/:id"
              name="OTP"
              render={(props) => <DirectSurvey {...props} />}
            />
            <Route
              exact
              path="/npsSurvey/:id/rId=:id"
              name="Login"
              render={(props) => <DirectSurvey {...props} />}
            />
            {/* for check user registered if not mail will send*/}
            <Route
              exact
              path="/check-user/:id"
              name="Login"
              render={(props) => <DirectSurveyNew {...props} />}
            />
             <Route
              exact
              path="/Survey/:id"
              name="Login"
              render={(props) => <DirectSurveyNew {...props} />}
            />
            <Route
              exact
              path="/report"
              name="OTP"
              render={(props) => <Report {...props} />}
            />
            <Route
              exact
              path="/userManagement"
              name="userManagement"
              render={(props) => <UserManagement {...props} />}
            />

            <Route
              exact
              path="/createSurvey/"
              name="OTP"
              render={(props) => <Scratchsurvey {...props} />}
            />
            <Route
              exact
              path="/createSurvey/:id"
              name="OTP"
              render={(props) => <CreateSurvey {...props} />}
            />
            <Route
              exact
              path="/useTemplate/:id"
              name="OTP"
              render={(props) => <CreateSurvey {...props} />}
            />
            <Route
              exact
              path="/admin"
              name="OTP"
              render={(props) => <AdminDashboard {...props} />}
            />

            <Route
              exact
              path="/templeate"
              name="OTP"
              render={(props) => <Templeates {...props} />}
            />

            <Route
              exact
              path="/templeateDetails"
              name="OTP"
              render={(props) => <TempleateDetails {...props} />}
            />

            <Route
              exact
              path="/SurveyCreate"
              name="OTP"
              render={(props) => <CreateSurvey {...props} />}
            />

            <Route
              exact
              path="/targetingQue"
              name="OTP"
              render={(props) => <TargetingQueOption {...props} />}
            />
            <Route
              exact
              path="/targetingYear"
              name="OTP"
              render={(props) => <TargetingYearOptions {...props} />}
            />
            <Route
              exact
              path="/pointDistribution"
              name="OTP"
              render={(props) => <PointDistribution {...props} />}
            />

            <Route
              exact
              path="/newDesign"
              name="OTP"
              render={(props) => <NewDesign {...props} />}
            />

            <Route
              exact
              path="/chatbot"
              name="CHAT"
              render={(props) => <Chat {...props} />}
            />

            <Route
              exact
              path="/crossTab/:id"
              name="CHAT"
              render={(props) => <CrossTab {...props} />}
            />

            <Route
              exact
              path="/Avanti"
              name="avanti"
              render={(props) => <Avanti {...props} />}
            />
            <Route
              exact
              path="/Avanti/Partner&Market"
              name="avanti"
              render={(props) => <Avanti {...props} />}
            />
            <Route
              exact
              path="/IDFC/Agency"
              name="avanti"
              render={(props) => <Avanti {...props} />}
            />
            <Route
              exact
              path="/IDFC/Yard"
              name="avanti"
              render={(props) => <Avanti {...props} />}
            />
            <Route
              exact
              path="/Demoreport"
              name="Demoreport"
              render={(props) => <Demoreport {...props} />}
            />
            <Route
              exact
              path="/testRecording/:id"
              name="Demoreport"
              render={(props) => <TestRecording {...props} />}
            />

            <Route
              exact
              path="/surveyimages"
              name="Surveyimages"
              render={(props) => <SurveyImages {...props} />}
            />
            <Route
              exact
              path="/surveyimages/:id"
              name="Surveyimages"
              render={(props) => <SurveyImages {...props} />}
            />
            <Route
              exact
              path="/avanti_info"
              name="avanti"
              render={(props) => <Avanti {...props} />}
            />
            <Route
              exact
              path="/Plan"
              name="plan"
              render={(props) => <PlanPage {...props} />}
            />

            <Route
              exact
              path="/attemp-survey1/:id"
              name="OTP"
              render={(props) => <Avanti {...props} />}
            />
            <Route
              exact
              path="/downloadexcel"
              name="OTP"
              render={(props) => <DownloadExcel {...props} />}
            />
            <Route
              exact
              path="/downloadAgencyexcel"
              name="OTP"
              render={(props) => <DownloadExcel {...props} />}
            />
            <Route
              exact
              path="/downloadYardexcel"
              name="OTP"
              render={(props) => <DownloadExcel {...props} />}
            />
            <Route
              exact
              path="/Image"
              name="OTP"
              render={(props) => <ImageView {...props} />}
            />

            <Route
              exact
              path="/invoice/:id"
              name="OTP"
              render={(props) => <Invoice {...props} />}
            />
            <Route
              exact
              path="/:id/setPassword"
              name="Login"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/Fgd"
              name="Fgd"
              render={(props) => <Fgd {...props} />}
            />
            <Route
              exact
              path="/room"
              name="Video"
              render={(props) => <Video {...props} />}
            />
            <Route
              exact
              path="/roo/:id"
              name="Video"
              render={(props) => <Video {...props} />}
            />
            <Route
              exact
              path="/ClientProfile"
              name="Login"
              render={(props) => <ClientProfile {...props} />}
            />
            <Route
              exact
              path="/clientSurvey"
              name="Login"
              render={(props) => <ClientSurvey {...props} />}
            />
            <Route
              exact
              path="/clientSurvey/:id"
              name="Login"
              render={(props) => <ClientSurvey {...props} />}
            />
            <Route
              exact
              path="/recording"
              name="Login"
              render={(props) => <Recording {...props} />}
            />
            <Route
              exact
              path="/speechtotext"
              name="Login"
              render={(props) => <Dictaphone {...props} />}
            />
            {/* <NpsCss> */}
              <Route
                exact
                path="/npsReport"
                name="OTP"
                render={props => <NpsReport {...props} />}
              />
              <Route
                exact
                path="/npsQuestions"
                name="OTP"
                render={props => <NpsQuestions {...props} />}
              />
              <Route
                exact
                path="/npsShare"
                name="OTP"
                render={props => <NpsShare {...props} />}
              />
              <Route
                exact
                path="/npsSmsShare"
                name="OTP"
                render={props => <NpsSmsShare {...props} />}
              />
              <Route
                exact
                path="/npsEmailShare"
                name="OTP"
                render={props => <NpsEmailShare {...props} />}
              />
            {/* </NpsCss> */}
            <AppContainer>
              {/* <Switch> */}
              <Route exact path="/videoCall" component={Main} />
              {/* {console.log(Main)}
              {console.log(Room1)} */}
              <Route path="/room/:roomId" Component={Room1} />
              {/* </Switch> */}
            </AppContainer>

            <Route
              exact
              path="*"
              name="404"
              render={(props) => <ErrorPage {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
  color: white;
  background-color: #454552;
  text-align: center;

  text-align: center;

  background-color: #454552;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

export default App;
