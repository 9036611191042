import { LoadingOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import { Result, UploadProps } from 'antd';
import { message, Upload } from 'antd';
import BarChart from 'react-bar-chart';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import API from '../utils/apiCalling';
import Config from "../config/env";
import ReactApexChart from "react-apexcharts";
import { faCommentDots, faL } from '@fortawesome/free-solid-svg-icons';
import { Chart } from "react-google-charts";
import { InputGroup } from 'reactstrap';
import { useParams, useHistory } from "react-router-dom";
import Select from 'react-select';
import CrossTab from './crossTab';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Multiselect from 'multiselect-react-dropdown';
import { CSVLink } from "react-csv";
import {
    EmailShareButton,
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    VKShareButton,
    WhatsappShareButton,
    InstagramIcon
} from "react-share";



function Scratchsurvey(prop) {
    let api = new API()
    const { id } = useParams();
    let survey = JSON.parse(localStorage.getItem('survey'))
    let getUser = JSON.parse(localStorage.getItem('user'))

    const [addQuestion, setAddQuestion] = useState([
        {
            //    description:"",
            questions: [
                // options=[]
            ],
            //    optionarray:[{
            //     option:""
            //    }]
        }
    ])
    const history = new useHistory();
    const [questions, setQuestions] = useState('');
    const [surveyQuestion, setSurveyQuestion] = useState('');
    const [options, setOptions] = useState('');
    const [optionValue, setOptionValue] = useState([]);
    const [questionsArray, setQuestionsArray] = useState([])


    const [divClassl, setDivClassl] = useState(" ")
    const [divIdl, setDivIdl] = useState("sidebarl")
    const [divClassrig, setDivClassrig] = useState(" ")
    const [divIdrig, setDivIdrig] = useState("sidebarrig")
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState()
    const [state, setState] = React.useState({});
    const [inputFields, setInputFields] = useState([])

    const [userData, setUserData] = useState()
    const [optionType, setOptionType] = useState('')

    const [required, setRequired] = useState("optional")
    const [multipleSelection, setMultipleSelection] = useState("single")
    const [isRequired, setIsRequired] = useState("no")
    const [getsuevey, setGetSurvey] = useState([])
    const [dashboard, setDashBoard] = useState()
    const [optionKeys, setOptionKey] = useState()
    const [optionValues, setOptionValues] = useState()
    const [questionArray, setQuestionArray] = useState()

    const [edit, setEditd] = useState()
    const [deleteQuestion, setDeleteQuestions] = useState()
    const [logicData, setLogicData] = useState([])
    const [logicOption, setLogicOptiond] = useState('')
    const [surveys, setSurveyId] = useState([])
    const [formatQuestion, setFormatQuestion] = useState('')
    const [qid, setQid] = useState()
    const [q_id, setQ_id] = useState()
    const [select, SetSelect] = useState([])
    const [questiondata, setQuestiondata] = useState([])
    const [report, setReport] = useState([])
    const [graph, setGraph] = useState('')
    const [MyId, setId] = useState()
    const [referCode, setReferCode] = useState("")
    const [copyStatus, setCopyStatus] = useState(false)
    const [title1, setTitle1] = useState(`Hi there! We would love to hear your feedback. Please take a moment to complete our survey by clicking on the following link.`)
    const [title2, setTitle2] = useState(" Thank you in advance for your participation! ")
    const [whatsAppTitle2, setWhatsAppTitle2] = useState(" with your friends and earn points on registration with the following link ")
    const [checkgraph, setChackGraph] = useState(true)
    const [Selected, setSelected] = useState([])


    const [data1, setData] = useState([])
    const [header, setHeader] = useState([])
    const [chartCount, setChartCount] = useState(0)
    const [chartData1, setChartData1] = useState({
        series: [
            //   {
            //     name: 'Respondents (%)',
            //     data: [84.40, 70.60, 48.70, 46.60, 39.00, 30.40, 23.50, 56.00, 75.70, 26.10, 1.30],
            //   },
            {
                name: 'Answers (%)',
                data: [16.80, 14.06, 9.70, 9.28, 7.76, 6.05, 4.68, 11.15, 15.07, 5.20, 0.26],
            },
            //   {
            //     name: 'Count',
            //     data: [844, 706, 487, 466, 390, 304, 235, 560, 757, 261, 13],
            //   },
        ],

        options: {
            colors: ['#F47321'],
            chart: {
                type: 'bar',
                height: 350,

            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: [
                    'Facebook',
                    'Instagram',
                    'Twitter',
                    'Snapchat',
                    'TikTok',
                    'Pinterest',
                    'Reddit',
                    'WhatsApp',
                    'YouTube',
                    'LinkedIn',
                    'Other',
                ],
            },
            yaxis: {
                title: {
                    text: 'Answer %',
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
        },
    });

    const [chartData, setChartData] = useState({
        series: [84.40, 70.60, 48.70, 46.60, 39.00, 30.40, 23.50, 56.00, 75.70, 26.10, 1.30],
        options: {
            chart: {
                type: 'pie',
            },
            labels: [
                'Facebook',
                'Instagram',
                'Twitter',
                'Snapchat',
                'TikTok',
                'Pinterest',
                'Reddit',
                'WhatsApp',
                'YouTube',
                'LinkedIn',
                'Other',
            ],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                position: 'right',
                offsetY: 0,
                height: 230,
            },
        },
    });


    const [chartData2, setChartData2] = useState({
        series: [
            //   {
            //     name: 'Respondents (%)',
            //     data: [84.40, 70.60, 48.70, 46.60, 39.00, 30.40, 23.50, 56.00, 75.70, 26.10, 1.30],
            //   },
            {
                name: 'Answers (%)',
                data: [4.30, 9.00, 28.80, 32.20, 25.70],
            },
            //   {
            //     name: 'Count',
            //     data: [844, 706, 487, 466, 390, 304, 235, 560, 757, 261, 13],
            //   },
        ],

        options: {
            colors: ['#F47321'],
            chart: {
                type: 'bar',
                height: 350,

            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: [
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',

                ],
            },
            yaxis: {
                title: {
                    text: 'Answer %',
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
        },
    });


    const [chartData3, setChartData3] = useState({
        series: [4.30, 9.00, 28.80, 32.20, 25.70],
        options: {
            chart: {
                type: 'pie',
            },
            labels: [
                '1',
                '2',
                '3',
                '4',
                '5',

            ],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                position: 'right',
                offsetY: 0,
                height: 230,
            },
        },
    });


    const [chartData4, setChartData4] = useState({
        series: [
            //   {
            //     name: 'Respondents (%)',
            //     data: [84.40, 70.60, 48.70, 46.60, 39.00, 30.40, 23.50, 56.00, 75.70, 26.10, 1.30],
            //   },
            {
                name: 'Answers (%)',
                data: [6.00, 3.00, 4.60, 3.50, 72.50, 10.40],
            },
            //   {
            //     name: 'Count',
            //     data: [844, 706, 487, 466, 390, 304, 235, 560, 757, 261, 13],
            //   },
        ],

        options: {
            colors: ['#F47321'],
            chart: {
                type: 'bar',
                height: 350,

            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: [
                    'A',
                    'B',
                    'C',
                    'D',
                    'E',
                    "None of the above"

                ],
            },
            yaxis: {
                title: {
                    text: 'Answer %',
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    },
                },
            },
        },
    });


    const [chartData5, setChartData5] = useState({
        series: [6.00, 3.00, 4.60, 3.50, 72.50, 10.40],
        options: {
            chart: {
                type: 'pie',
            },
            labels: [
                'A',
                'B',
                'C',
                'D',
                'E',
                "None of the above"

            ],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 300
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                position: 'right',
                offsetY: 0,
                height: 230,
            },
        },
    });




    const editOptions = (e, i) => {
        let newInputArr = inputFields
        let inputObj = newInputArr[i]
        inputObj["option"] = e.target.value
        newInputArr[i] = inputObj

        setInputFields([...newInputArr])
        setOptions([...newInputArr])
    }

    const setEdit = (e, type, i) => {
        setQid(i + 1)
        if (type === "edit") {
            setFormatQuestion(type)
        }
        let value = questionArray.filter((Obj) => Obj._id === e)
        setQ_id(value[0]._id)
        setQuestions(value[0].question)
        setSurveyQuestion(value[0].que_message)
        setOptionType(value[0].type)

        setOptions((value[0].options)?.map(e => e.option))
        setInputFields(value[0].options)
    }



    const setLogicOption = (e, option) => {
        setLogicOptiond(e.target.value)
        if ((e.target.value) === "p1") {
            option.disable = true
        } else {
            option.disable = false
            option.action_id = e.target.value
        }
    }
    const setDeleteQuestion = (e) => {
        DeleteQuestion(questionArray.filter((Obj) => Obj._id !== e.target.value))
    }

    const setLogicQuestion = (e, option) => {
        option.action_id = e.target.value
    }

    const setLogic = (id) => {
        let filterQuestion = questionArray.filter(e => e._id === id)
        setLogicData(filterQuestion)

    }

    const Multiple111 = (value) => {
        setOptionType(value)
    }


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem('user'))
        setUserData(user)
        getSuevey(user?._id)
        getSueveyById(((id === undefined) ? survey?._id : id))
        setReferCode(`https://doyoursurvey.com/#/attemp-survey/${id}`)
    }, [checkgraph])

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1)
        setInputFields(data)
    }

    const getSurveyQuestion = (event) => {
        let { name, value } = event.target

        if (name === 'question') {
            setQuestions({ ...questions, [name]: value })

        } else {
            setSurveyQuestion({ ...surveyQuestion, [name]: value })
        }
    }

    var mongoObjectId = function () {
        var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    };

    const headerData = async (data) => {
        let filterResponse = []
        data1 && data1.length > 0 && data1[0].questions.map((que) => {
            filterResponse.push({ label: que.question, key: que.question })
            header.push(que.que_message)


        })

    }

    const fieldEmpty = () => {
        setQuestions('')
        setSurveyQuestion('')
        setInputFields([])
        setOptionValue([])
        setOptions('')
        setOptionType('')
        setRequired('optional')
        setMultipleSelection('single')
    }



    const handelNext = () => {
        let obj = {
            _id: mongoObjectId(),
            question: questions,
            que_message: surveyQuestion,
            options: optionValue,
            selection: multipleSelection,
            type: optionType,
            status: "Active",
            condition: required
        }
        questionsArray.push(obj)
        fieldEmpty()
        isOther(false)
    }
    let values = {
        _id: mongoObjectId(),
        questions: questions
    }

    const setActionId = () => {
        let id = []
        for (var i = 0; i < questionArray.length; i++) {
            id.push(questionArray[i]._id)
        }
        if (questionArray.length > 0) {
            questionArray?.map((e, index) => {
                return (e.options)?.map(q => {
                    q.action_id = id[index + 1] ? id[index + 1] : 'final'
                })
            })
        }
    }

    const addNewOptionCol = () => {
        let newfield = { option: "" }
        setInputFields([...inputFields, newfield])
        setOptionValue((state) => [...state, { option: options, action_id: "", text_box: isRequired }])
        setIsRequired("no")
    }

    const changeclassnamel = () => {
        if (divIdl === "sidebarl" && divClassl === " ") {
            setDivClassl("handleClassNameAndIdl")
            setDivIdl(" ")
        } else {
            setDivClassl(" ")
            setDivIdl("sidebarl")
        }
    }

    const changeclassnamerig = () => {
        if (divIdrig === "sidebarrig" && divClassrig === " ") {
            setDivClassrig("handleClassNameAndIdrig")
            setDivIdrig(" ")
        } else {
            setDivClassrig(" ")
            setDivIdrig("sidebarrig")
        }
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const { Dragger } = Upload;


    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }

        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };



    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };


    const isOther = (e) => {
        if (e.target.checked === true) {
            addNewOptionCol()
            setIsRequired('yes')
        }
    }
    const isText = (e) => {
        if (e.target.checked === true) {
            optionValue.map(e => {
                e.text_box = 'yes'
            })
        }
    }
    const getSuevey = async (id) => {
        let data = {
            User_Id: id
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/getSurvey`, data)
        if (result.code === 200) {
            setGetSurvey(result.data)
            getDashBoard(result.data[0]?._id)
        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }
    const getSueveyById = async (id) => {
        let data = {
            id: id
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/getSurvey`, data)
        if (result.code === 200) {
            setSurveyId(result.data)
            setQuestionArray(result.data.questions)
        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    const getDashBoard = async (sueveyId) => {

        setId(sueveyId)
        // let _ID
        // if (id !== undefined) {
        //     _ID = id
        // } else {
        //     _ID = sueveyId
        // }
        let data = {
            surveyId: sueveyId
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/getDashboard`, data)
        if (result.code === 200) {

            setDashBoard(result.data)
            // SetSelect((dashboard[4]?.Question[0])?.map(e=>e.question))
            // setSurveyOption(result.data[3])
        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        let surveyData = await api.post(`${Config.new.site_api}/client-survey/Dashboard`, data)
        if (surveyData.code === 200) {
            setQuestiondata(surveyData.data)
            setHeader(surveyData.data)
        }
    }
    // Change dropdown option selected------------------------------------
    const label = document.querySelector(".dropdown__filter-selected");
    const options1 = Array.from(
        document.querySelectorAll(".dropdown__select-option")
    );

    options1.forEach((option) => {
        option.addEventListener("click", () => {
            label.textContent = option.textContent;
        });
    });

    // Close dropdown onclick outside----------------------
    // document.addEventListener("click", (e) => {
    //     const toggle = document.querySelector(".dropdown__switch");
    //     const element = e.target;

    //     if (element == toggle) return;

    //     const isDropdownChild = element.closest(".dropdown__filter");

    //     if (!isDropdownChild) {
    //         toggle.checked = false;
    //     }
    // });
    // Close dropdown onclick outside----------------------End
    const createSurvey = async () => {
        if (optionType !== '') {
            let obj = {
                _id: mongoObjectId(),
                question: questions,
                que_message: surveyQuestion,
                options: optionValue,
                selection: multipleSelection,
                type: optionType,
                status: "Active",
                condition: required
            }
            questionArray.push(obj)
            setActionId()
            let _ID
            if (id === undefined) {
                _ID = survey._id
            } else {
                _ID = surveys._id
            }
            let data = {
                _id: _ID,
                questions: questionArray,
            }
            let result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(data) })
            if (result.code === 200) {
                setQuestionsArray([])
                fieldEmpty()
                toast.success("Add Question Successfully", {
                    position: toast.POSITION.TOP_RIGHT
                })
            } else {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        } else {
            toast.error("Please Select Question Type", {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }
    const editSurvey = async () => {
        if (optionType !== '') {
            let obj = {
                _id: mongoObjectId(),
                question: questions,
                que_message: surveyQuestion,
                options: optionValue,
                selection: multipleSelection,
                type: optionType,
                status: "Active",
                condition: required
            }
            // let value = questionArray.filter((Obj) => Obj._id === q_id)
            // questionArray.push(obj)
            setActionId()
            let _ID
            if (id === undefined) {
                _ID = survey._id
            } else {
                _ID = surveys._id
            }
            let data = {
                _id: _ID,
                questions: questionArray,
            }
            let result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(data) })
            if (result.code === 200) {
                setQuestionsArray([])
                fieldEmpty()
                toast.success("Add Question Successfully", {
                    position: toast.POSITION.TOP_RIGHT
                })
                setFormatQuestion('')
                setQid(questionArray.length + 1)
            } else {
                toast.error(result.message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        } else {
            toast.error("Please Select Question Type", {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }
    const parentToChild = () => {
        history.push(`/crossTab/${MyId}`)

    }

    const SaveLogic = async () => {
        let _ID
        if (id === undefined) {
            _ID = survey._id
        } else {
            _ID = surveys._id
        }
        let data = {
            _id: _ID,
            questions: questionArray,
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(data) })
        if (result.code === 200) {
            setQuestionsArray([])
            fieldEmpty()
            toast.success("Logic set Successfully", {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }
    const logOut = () => {
        localStorage.clear();
        history.push('/login')
    }
    const DeleteQuestion = async (value) => {
        let _ID
        if (id === undefined) {
            _ID = survey._id
        } else {
            _ID = surveys._id
        }
        let data = {
            _id: _ID,
            questions: value,
        }
        let result = await api.post(`${Config.new.site_api}/client-survey/createNewSurvey`, { survey: JSON.stringify(data) })
        if (result.code === 200) {
            setQuestionArray(result.data.questions)
            fieldEmpty()
            toast.success("Question Deleted Successfully", {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            toast.error(result.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }

    }
    const onSelect = (id) => {

    }
    // const onRemove=(id)=>{

    // }


    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : ""}
            <div className='f-12 fw-bold d-flex flex-column justify-content-around align-items-center'>
                <p className='text-orange m-0'>Upload Logo</p>
                <p className='m-0'>Drop your files here or</p>
                <span className='text-orange'>Browse</span>
            </div>
        </div>
    );

    const setgraph = (value, index) => {

        setGraph(value)
        report[index].graph = value
    }


    const getQuestion = async (id, item) => {
        setSelected(item)
        const multiValue = id

        let finalArray = []
        multiValue?.map(a => {
            let Question = questiondata?.map(e => { return (e.questions).filter(q => q._id === a._id) })
            let Answer = Question.map(e => e[0]?.answer)
            let response = Question[0]
            let Option = Question[0]?.map(e => (e.options))
            let newArray = []
            Option[0]?.map((e) => {
                let sum = 0
                Answer?.map((q) => {
                    if (q) {
                        if (Array.isArray(q)) {
                            if (e.option == q[0]) {
                                return sum = sum + 1
                            }
                        } else if (e.option.toLowerCase() == 'other') {
                            return sum = sum + 1
                        }
                    }
                })
                newArray.push({ "Option": e.option, "Answer": sum })
            })

            let totalResponses = Answer.length

            newArray.map(e => {
                e.count = parseInt(((e.Answer) * 100) / totalResponses)
            })
            let GraphKey = []
            let GraphValue = []
            newArray.map(e => {
                GraphKey.push(e.Option)
                GraphValue.push(e.count)
            })

            response.map(e => {
                e.ans = newArray
                e.GraphKey = GraphKey
                e.GraphValue = GraphValue
            }
            )
            finalArray.push(response[0])
            setReport(finalArray)
            setGraph('')
        })
    }

    const onRemove = (id) => {
        setReport(id)
    }




    const SetGragh = (id, ID, index) => {
        setgraph(id, index)
        let newID = report.filter(e => e._id === ID)

        report[index].chartData = {
            series: [{
                name: 'Answer',
                data: newID[0]?.GraphValue
            }],
            options: {
                colors: ['#F47321'],
                chart: {
                    type: 'bar',
                    height: 450
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },

                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: newID[0]?.GraphKey
                    ,
                },
                yaxis: {
                    title: {
                        text: 'Answer %'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return + val + " % "
                        }
                    }
                },
                toolbar: {
                    show: false
                }

            },

        }

        report[index].picData = {
            series: newID[0]?.GraphValue,
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: newID[0]?.GraphKey,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }

        }
        if (id === 'chart') {
            if (graph == id) {
                setChackGraph(!checkgraph)
            } else {
                setChackGraph(!checkgraph)
                setgraph(id, index)

            }
        } else if (id === 'pichart') {
            if (graph == id) {
                setChackGraph(!checkgraph)
            } else {
                setChackGraph(!checkgraph)
                setgraph(id, index)
            }

        }

    }

    let msgbody = `Hi there! `
    let msgbody1 = `We would love to hear your feedback. Please take a moment to complete our survey by clicking on the following link`
    let surveylink = (id) ? `https://doyoursurvey.com/#/attemp-survey/` + id : "https://doyoursurvey.com/#/signup"
    let emailbody = `Dear,%0DWe hope this email finds you well. As a valued member of our community, we would greatly appreciate your feedback. We are conducting a survey to better understand the needs and preferences of our customers, and we would be grateful if you could take a few moments to complete it.%0DYour input will help us to improve our services and provide you with the best possible experience. To participate in the survey, please click on the following link. Thank you for your time and participation. We look forward to hearing your thoughts and feedback.` + `https://doyoursurvey.com/#/attemp-survey/${id}`
    let emailLink = `https://mail.google.com/mail/?view=cm&fs=1&body=${(emailbody)}`

    const backToAdminDash = () => {
        history.push('/adminDash');
    };


    return (
        <>
            {logicData &&

                <section>
                    <div class="modal logicItem_pop fade" id="exampleModall-12" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header align-items-center">
                                    <h1 class="modal-title m-0 p-0 fs-5 text-orange" id="exampleModalLabel">Question Logic</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                {(logicData[0]?.options)?.map((option, index) => (
                                    <div class="modal-body logicContens">
                                        <p className='d-flex p-0 m-0 mb-4 align-items-center'>
                                            <span>{index + 1}</span>
                                            <div class="dropdownnma w-100">
                                                <p>{option?.option}</p>
                                            </div>
                                            <span>is</span>
                                            <div class="dropdownnma w-100">
                                                <select className="dropdown__options-filter m-0" onChange={e => setLogicOption(e, option)} style={{ height: "42px" }} >
                                                    <option hidden value="">Choose page</option>
                                                    <option value="p1">P1</option>
                                                    <option value="final">End of Survey</option>
                                                    <option value="exit">Disqualification</option>
                                                </select>
                                            </div>
                                            <span>Skip to</span>
                                            {questionArray &&
                                                <div class="dropdownnma w-100">
                                                    <select className="dropdown__options-filter m-0" onChange={e => setLogicQuestion(e, option)} style={{ height: "42px" }} disabled={!option.disable} >
                                                        <option hidden value="">Choose Question</option>
                                                        {questionArray.map((Question, index) =>
                                                            <option value={Question._id}>Question {index + 1}</option>
                                                        )}
                                                    </select>
                                                </div>}
                                            <i class="bi bi-trash"></i>
                                        </p>
                                    </div>
                                ))}
                                <div className='my-4 d-flex justify-content-center'><button class="mainbtn f-14" data-bs-dismiss="modal" aria-label="Close" onClick={SaveLogic}>Save</button></div>
                            </div>
                        </div>
                    </div>
                </section>
            }
            <main className='bg-lgrey'>
                <section id="all-section" className="admin-header">
                    <nav className="navbar navbar-expand-lg bg-white p-0 border">
                        <div className="container-fluid px-2">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-white m-0">
                                    <li className="breadcrumb-item"><a onClick={backToAdminDash}>My workspace</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{survey?.survey_name}</li>
                                </ol>
                            </nav>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContentabc" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"><i className="fs-1 bi bi-list"></i></span>
                            </button>
                            <div className="collapse navbar-collapse mb-3 mb-md-0" id="navbarSupportedContentabc">
                                <div className=" mx-auto w-100">
                                    <ul className="nav navbar-nav justify-content-center">
                                        <ul className="nav nav-tabs border-0 justify-content-around " id="myTab" role="tablist">
                                            {/* <li className="nav-item" role="presentation">
                                                <button className="nav-link scratchheadernavpill active" id="create-tab" data-bs-toggle="tab" data-bs-target="#create-tab-pane" type="button" role="tab" aria-controls="create-tab-pane" aria-selected="true">Create</button>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <button className="nav-link scratchheadernavpill" id="share-tab" data-bs-toggle="tab" data-bs-target="#share-tab-pane" type="button" role="tab" aria-controls="share-tab-pane" aria-selected="false">Share</button>
                                            </li> */}
                                            <li className="nav-item " role="presentation">
                                                {/* <button className="nav-link scratchheadernavpill" id="result-tab" data-bs-toggle="tab" data-bs-target="#result-tab-pane" type="button" role="tab" aria-controls="result-tab-pane" aria-selected="true">Report</button> */}
                                                <button className="nav-link scratchheadernavpill active" id="result-tab" data-bs-toggle="tab" data-bs-target="#result-tab-pane" type="button" role="tab" aria-controls="result-tab-pane" aria-selected="true">Report</button>
                                            </li>
                                        </ul>
                                    </ul>
                                </div>
                            </div>
                            <div className='catn d-flex align-items-center justify-content-between'>
                                {/* <div className='preview-web '>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill " viewBox="0 0 16 16">
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                    </svg>
                                </div> */}
                                <a className='mainbtn f-14 mx-4'>Buy Target Audience</a>
                                <ul className="navbar-nav flex-row">
                                    <div className="nav-item dropdown notification-drop ">
                                        <a id="dLabel" role="button" className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-bell-fill text-blue fs-4 position-relative"><span className="position-absolute top-0 start-100 translate-middle badge rounded-circle text-blue f-12 bg-grey">
                                                <small className='fw-bold'>10</small>
                                                <span className="visually-hidden">unread messages</span>
                                            </span></i>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-lg-end notifications" role="menu" aria-labelledby="dLabel">
                                            <div className="notification-heading"><p className="text-orange m-2 f-14">Notifications</p>
                                            </div>
                                            <li className="divider" />
                                            <div className="notifications-wrapper">
                                                <a className="content" href="#">
                                                    <div className="notification-item  read">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>

                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
                                                        </div></div>
                                                </a>
                                                <a className="content" href="#">

                                                    <div className="notification-item  read">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content" href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item  ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                                <a className="content " href="#">

                                                    <div className="notification-item ">
                                                        <img src='../images/2.png'></img>
                                                        <div className='tableform'>
                                                            <p className="text-orange f-14">Marrisa Fair<span className='float-right f-12'>4:00PM</span></p>
                                                            <p className="item-info f-12">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the </p>
                                                        </div></div>
                                                </a>
                                            </div>

                                        </ul>
                                    </div>
                                    <li className="nav-item dropdown profile-drop">
                                        <a className="nav-link dropdown-toggle d-flex align-items-center position-static " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="../images/2@2x.png" width="50px" className='rounded-circle' alt="" /><p className='profile-name text-grey m-0 f-14 px-2'> <i className="bi bi-chevron-down text-grey mt-1"></i></p>
                                        </a>
                                        {/* <ul className="dropdown-menu dropdown-menu-lg-end">
                                            <li><a className="dropdown-item" href="#">Profile Settings</a></li>
                                            <li><a className="dropdown-item" onClick={logOut}>Logout</a></li>
                                            
                                        </ul> */}
                                        <ul className="dropdown-menu dropdown-menu-lg-end">
                                            {/* <li><a className="dropdown-item border-bottom" href="#"><i className="bi bi-person-fill pe-2" />Profile</a></li> */}
                                            <li><a className="dropdown-item" onClick={logOut}><i className="fa fa-sign-out pe-2" aria-hidden="true" />Logout</a></li></ul>
                                    </li>
                                </ul>
                            </div>

                        </div></nav>
                </section>
                <section id="all-section" className="section-survey ">
                    <div className="container-fluid">
                        <div className="tab-content persCreate" id="myTabContent">
                            <div className="tab-pane fade show active  layout-list" id="result-tab-pane" role="tabpanel" aria-labelledby="result-tab" tabIndex={-1}>
                                <>
                                    <div className="tab-content  w-100 rewards1" id="v-pills-tabContent">
                                        <div className='container-fluid py-3 mt-2 earnSec'>
                                            <div className='row'>
                                                <div className='col-lg-12 thisTopbar d-flex'>

                                                    <select class="form-select p-3" aria-label="Default select example" onChange={e => getDashBoard(e.target.value)}>
                                                        <option hidden>Survey</option>
                                                        {getsuevey && getsuevey.map(e =>
                                                            <option value={e._id}>{e.survey_name}</option>
                                                        )}
                                                    </select>
                                                    {/* </div>

                                                <div className="col-lg-5"> */}
                                                    <div className="d-flex flex-row align-items-center mndin">
                                                        <div className="divmnt">
                                                            <u> <input placeholder="Select your date" type="date" className=" fw-semibold text-secondary inpcls" name="checkIn" /> </u>
                                                        </div>
                                                        <h6 className="forange mx-2 fw-semibold">to</h6>
                                                        <input placeholder="Select your date" type="date" className=" dashbin fw-semibold text-secondary " name="checkIn" />
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                        {dashboard &&
                                            <div className="tab-pane com-bs fade show active" id="v-pills-rewards" role="tabpanel" aria-labelledby="v-pills-rewards-tab" style={{ boxShadow: "none" }}>
                                                <div className='reward-dash container-fluid px-0 py-2'>
                                                    <div className='l-sec w-100'>
                                                        <div className='row'>
                                                            {/* <div className='col-md-6 col-lg-3  mb-3'>
                                                                <a><div className='rew-card h-100 rounded-2'>
                                                                    <p className='text-dblue-re m-0'>Survey Completed</p>
                                                                    <div className='d-flex justify-content-between align-items-center pt-3 pr-2'>
                                                                        <div>

                                                                            <span className='fs-4 text-orange-re fw-bold'>{dashboard && dashboard.survey[0] && dashboard.survey[0].result}</span><br />
                                                                            <i class="bi bi-arrow-up-right-circle-fill" style={{ color: "#0E9023", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i>
                                                                            <span className='srvesa-items'>Survey completed</span><span className='green'> 20%</span>
                                                                        </div>
                                                                        <div style={{ width: 65, height: 65 }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[0] && (dashboard.survey[0].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[0] && dashboard.survey[0].percent}%`} /></div>
                                                                    </div>
                                                                </div>
                                                                </a>
                                                            </div>
                                                            <div className='col-md-6 col-lg-3 mb-3'>
                                                                <a><div className='rew-card h-100 rounded-2'>
                                                                    
                                                                    <p className='head-Surveysa m-0 fw-normal'><i class="bi fw-bold fs-5 bi-arrow-bar-down"></i> Drop-Off Rate</p>
                                                                    <div className='d-flex justify-content-between align-items-center pt-3 pr-2'>
                                                                        <div className='w-100'>
                                                                        <div className='progPress justify-content-between d-flex align-items-center'>
                                                                            <span className='fw-semibold fs-4' style={{color: "#F47321"}}>75%</span>
                                                                            <span className='fs-5 text-orange-re fw-bold' style={{color: "#354C9E"}}>{dashboard && dashboard.survey[1] && dashboard.survey[1].result}</span>
                                                                            </div>
                                                                            <br></br>
                                                                            <i class="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i>
                                                                            <div class="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                                <div class="progress-bar w-75"></div>
                                                                            </div>
                                                                            <span className='srvesa-items'>Goal: Below</span><span  style={{color: "#354C9E"}}> 13%</span>
                                                                        </div>
                                                                        <div style={{ width: 65, height: 65 }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[1] && (dashboard.survey[1].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[1] && dashboard.survey[1].percent}%`} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </a>
                                                            </div>
                                                            <div className='col-md-6 col-lg-3 mb-3'>

                                                                <a><div className='rew-card h-100 rounded-2'>
                                                                    <p className=' text-dblue-re m-0'>Completion LOI</p>
                                                                    <div className='d-flex justify-content-between align-items-center pt-3 pr-2'>
                                                                        <div>

                                                                            <span className='fs-4 text-orange-re fw-bold'>{dashboard && dashboard.survey[2] && dashboard.survey[2].result}</span><br></br>
                                                                            <i class="bi bi-arrow-up-right-circle-fill" style={{ color: "#0E9023", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i>
                                                                            <span className='srvesa-items'>Percentage of</span><span className='green'> 20%</span><br></br>
                                                                            <span className='srvesa-items'>Expected</span><span className='red'> 20%</span>

                                                                        </div>
                                                                        <div style={{ width: 65, height: 65, }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[2] && (dashboard.survey[2].avg) / 100} maxValue={1} text={`${dashboard && dashboard.survey[2] && (dashboard.survey[2].avg)}`} /></div>

                                                                    </div>

                                                                </div></a>

                                                            </div>
                                                            <div className='col-md-6 col-lg-3 mb-3'>
                                                                <a data-bs-toggle="modal" data-bs-target="#comple-survey"><div className='rew-card h-100 rounded-2'>
                                                                    <p className='text-dblue-re m-0'>Termination LOI</p>
                                                                    <div className='d-flex justify-content-between clockfor align-items-center pt-3 pr-2'>
                                                                        <div>
                                                                            <i class="bi bi-arrow-up-right-circle-fill" style={{ color: "#0E9023", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i>
                                                                            <span className='srvesa-items'>Total Time spending </span><span className='green'>{dashboard && dashboard.survey[2] && dashboard.survey[2].result}</span><br></br>
                                                                            <span className='srvesa-items'>Avg Time spending </span><span className='green'> {dashboard && dashboard.survey[2] && (dashboard.survey[2].avg)}</span><br></br>
                                                                            <span className='srvesa-items'>Expected</span><span className='red'> 6</span>
                                                                        </div>
                                                                        <div style={{ width: 65, height: 65 }}>
                                                                        <CircularProgressbar value={value} maxValue={1} text={`${value * 100}%`} /></div>
                                                                        <i class="bi bi-clock"></i>
                                                                    </div>
                                                                </div></a>
                                                            </div> */}

                                                            <div className='col-md-6 col-lg-3 mb-3'>
                                                                <a><div className='rew-card h-100 rounded-2 d-flex justify-content-center px-3 flex-column '>
                                                                    <p className='head-Surveysa m-0 fw-semibold'><i class="bi bi-person-check-fill"></i> Survey Completed</p>
                                                                    <div className='d-flex justify-content-between align-items-center pt-2'>
                                                                        <div className='w-100'>
                                                                            <div className='progPress justify-content-between d-flex align-items-center'>
                                                                                <span className='fw-semibold fs-4' style={{ color: "#F47321" }}>{dashboard && dashboard[0]?.Count}%</span>
                                                                                <span className='fs-5 text-blue fw-bold text-blue'>{dashboard && dashboard[0]?.TotalCount}</span>
                                                                            </div>
                                                                            {/* <br></br> */}
                                                                            {/* <i class="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i> */}
                                                                            <div class="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                                <div className="progress-bar" style={{ width: `${dashboard && dashboard[0]?.Count}%` }}></div>
                                                                            </div>
                                                                            <span className='srvesa-items fw-semibold'>Expected:</span><span style={{ color: "#354C9E" }}> {dashboard && dashboard[0]?.Expected}</span>
                                                                        </div>
                                                                        {/* <div style={{ width: 65, height: 65 }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[1] && (dashboard.survey[1].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[1] && dashboard.survey[1].percent}%`} />
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                </a>
                                                            </div>
                                                            <div className='col-md-6 col-lg-3 mb-3'>
                                                                <a><div className='rew-card h-100 rounded-2 d-flex justify-content-center px-3 flex-column '>
                                                                    <p className='head-Surveysa m-0 fw-semibold'><i class="bi fw-bold fs-5 bi-arrow-bar-down"></i> Drop-Off Rate</p>
                                                                    <div className='d-flex justify-content-between align-items-center pt-2'>
                                                                        <div className='w-100'>
                                                                            <div className='progPress justify-content-between d-flex align-items-center'>
                                                                                <span className='fw-semibold fs-4' style={{ color: "#F47321" }}>{dashboard && dashboard[1]?.Count}%</span>
                                                                                <span className='fs-5 text-blue fw-bold text-blue'>{dashboard && dashboard[1].TotalCount}</span>
                                                                            </div>
                                                                            {/* <br></br> */}
                                                                            {/* <i class="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i> */}
                                                                            <div class="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                                <div class="progress-bar" style={{ width: `${dashboard && dashboard[1]?.Count}%` }}></div>
                                                                            </div>
                                                                            <span className='srvesa-items fw-semibold'>Entrants:</span><span style={{ color: "#354C9E" }}> {dashboard && dashboard[1]?.Expected}</span>
                                                                        </div>
                                                                        {/* <div style={{ width: 65, height: 65 }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[1] && (dashboard.survey[1].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[1] && dashboard.survey[1].percent}%`} />
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                </a>
                                                            </div>
                                                            <div className='col-md-6 col-lg-3 mb-3'>
                                                                <a><div className='rew-card h-100 rounded-2 d-flex justify-content-center px-3 flex-column '>
                                                                    <p className='head-Surveysa m-0 fw-semibold'><i class="bi bi-calendar2-check"></i> Completion LOI</p>
                                                                    <div className='d-flex justify-content-between align-items-center pt-2'>
                                                                        <div className='w-100'>
                                                                            <div className='progPress justify-content-between d-flex align-items-center'>
                                                                                <span className='fw-semibold fs-4' style={{ color: "#F47321" }}>75%</span>
                                                                                <span className='fs-5 text-blue fw-bold text-blue'>{dashboard && dashboard[2].TotalCount}</span>
                                                                            </div>
                                                                            {/* <br></br> */}
                                                                            {/* <i class="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i> */}
                                                                            <div class="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                                <div class="progress-bar " style={{ width: `${dashboard && dashboard[2]?.Count}%` }}></div>
                                                                            </div>
                                                                            <span className='srvesa-items fw-semibold'>Goal: Below</span><span style={{ color: "#354C9E" }}> 13%</span>
                                                                        </div>
                                                                        {/* <div style={{ width: 65, height: 65 }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[1] && (dashboard.survey[1].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[1] && dashboard.survey[1].percent}%`} />
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                </a>
                                                            </div>
                                                            <div className='col-md-6 col-lg-3 mb-3'>
                                                                <a><div className='rew-card h-100 rounded-2 d-flex justify-content-center px-3 flex-column '>
                                                                    <p className='head-Surveysa m-0 fw-semibold'><i class="bi bi-calendar-x"></i> Termination LOI</p>
                                                                    <div className='d-flex justify-content-between align-items-center pt-2'>
                                                                        <div className='w-100'>
                                                                            <div className='progPress justify-content-between d-flex align-items-center'>
                                                                                <span className='fw-semibold fs-4' style={{ color: "#F47321" }}>75%</span>
                                                                                <span className='fs-5 text-blue fw-bold text-blue'>{dashboard && dashboard[3].TotalCount}</span>
                                                                            </div>
                                                                            {/* <br></br> */}
                                                                            {/* <i class="bi bi-arrow-down-right-circle-fill" style={{ color: "#FF0000", fontSize: "14px" }}>&nbsp;&nbsp;+14% Inc</i> */}
                                                                            <div class="progress my-2" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                                                                <div class="progress-bar" style={{ width: `${dashboard && dashboard[3]?.Count}%` }}></div>
                                                                            </div>
                                                                            <span className='srvesa-items fw-semibold'>Goal: Below</span><span style={{ color: "#354C9E" }}> 13%</span>
                                                                        </div>
                                                                        {/* <div style={{ width: 65, height: 65 }}>
                                                                            <CircularProgressbar value={dashboard && dashboard.survey[1] && (dashboard.survey[1].percent) / 100} maxValue={1} text={`${dashboard && dashboard.survey[1] && dashboard.survey[1].percent}%`} />
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {/* <div className='container-fluid py-3 mt-2 earnSec'>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='card' style={{ minHeight: "400px" }}>

                                                        <h4 className='text-orange p-2'>Device</h4>
                                                        <div className='row'>
                                                            <div className='col-sm-6'>
                                                                <div className='text-center w-100'>
                                                                    <img src="../images/Group 11577.svg" className='mb-4' />
                                                                    <p className='text-dblue-re m-0'>Desktop</p>
                                                                    <p className='text-black fs-3 fw-bold'>100%</p>

                                                                </div>
                                                            </div>
                                                            <div className='col-sm-6'>
                                                                <div className='text-center w-100'>
                                                                    <img src="../images/Group 12402.svg" width={'150px'} className='mb-4' />
                                                                    <p className='text-dblue-re m-0'>Mobile</p>
                                                                    <p className='text-black fs-3 fw-bold'>0%</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='w-100 p-3'>
                                                            <button class="mainbtn">Report Export</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {dashboard && dashboard.gender &&
                                                    <div className='col-md-6 col-lg-6'>
                                                        <div className='card' style={{ minHeight: "400px" }}>
                                                            <h4 className='text-orange p-2'>Users</h4>
                                                            <div className='row'>
                                                                <div className='col-sm-6'>
                                                                    <div className='text-center w-100'>
                                                                        <img src="../images/Group 17662.svg" className='mb-4' />

                                                                    </div>
                                                                </div>
                                                                <div className='col-sm-6 d-flex align-items-center'>
                                                                    <div className='text-center w-100'>                                          

                                                                        <span className='text-dblue-re m-0'>Male</span><span className='text-black fs-3 fw-bold'>&nbsp;&nbsp;&nbsp;{dashboard.gender.Male}%</span><br></br>
                                                                        <span className='text-dblue-re m-0'>Female</span><span className='text-black fs-3 fw-bold'>&nbsp;&nbsp;&nbsp;{dashboard.gender.Famale}%</span><br></br>
                                                                        <span className='text-dblue-re m-0'>Other</span><span className='text-black fs-3 fw-bold'>&nbsp;&nbsp;&nbsp;{dashboard.gender.Other}%</span>




                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='w-100 p-3'>
                                                                <button class="mainbtn">Report Export</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                            </div>

                                        </div> */}
                                        {/* <p className='text-dblue-re m-0'>Mobile</p>
                                    <p className='text-black fs-3 fw-bold'>70%</p>
                                    <p className='text-dblue-re m-0'>Mobile</p>
                                    <p className='text-black fs-3 fw-bold'>70%</p>
                                    <p className='text-dblue-re m-0'>Mobile</p>
                                    <p className='text-black fs-3 fw-bold'>70%</p> */}
                                        {/* } */}
                                        {dashboard &&
                                            <div className='container-fluid py-3 mt-2 earnSec'>
                                                <div className='row'>
                                                    <div className='col-lg-4 col-md-6 d-flex mb-md-0 mb-3 filter-Quap'>
                                                        {/* <!-- Button trigger modal --> */}

                                                        <button type="button" className="mainbtn mainbtsase mr-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                            <i class="bi bi-funnel-fill"></i>Filter
                                                        </button>
                                                        <button type="button" className="mainbtn mainbtsase" onClick={() => parentToChild()}>
                                                            Cross Tab
                                                        </button>

                                                        {/* <!-- Modal --> */}
                                                        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div class="modal-dialog">
                                                                <div class="modal-content">
                                                                    <div class="modal-header align-items-center">
                                                                        <h1 class="modal-title fs-6 fw-bold mt-0" id="exampleModalLabel">Filter Applied</h1>
                                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <div className="filters-applied">
                                                                            <div className="filter-stat">
                                                                                <h5 style={{ cursor: 'pointer' }}>
                                                                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                                    <span>Gender</span>
                                                                                    <div className="clearfix" />
                                                                                </h5>
                                                                                <div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />male</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />female</div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="filter-stat">
                                                                                <h5 style={{ cursor: 'pointer' }}>
                                                                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                                    <span>Age</span>
                                                                                    <div className="clearfix" />
                                                                                </h5>
                                                                                <div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />25 - 34</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />35 - 44</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />18 - 24</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />45 - 54</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />16 - 17</div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="filter-stat">
                                                                                <h5 style={{ cursor: 'pointer' }}>
                                                                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                                    <span>Income</span>
                                                                                    <div className="clearfix" />
                                                                                </h5>
                                                                                <div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />Lower I</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />Lower II</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />Middle I</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />Middle II</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />High II</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item title"><input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />High III</div>

                                                                                    </div>
                                                                                </div>
                                                                                <button className="pf-text--link" type="button">Show 1 more filter</button>
                                                                            </div>
                                                                            <div className="filter-stat">
                                                                                <h5 style={{ cursor: 'pointer' }}>
                                                                                    <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                                    <span>Country</span>
                                                                                    <div className="clearfix" />
                                                                                </h5>
                                                                                <div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item flag">
                                                                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                                            <span className="flag-icon flag-icon-gb" />
                                                                                        </div>
                                                                                        <div className="flex-item title">UK</div>

                                                                                    </div>
                                                                                    <div role="presentation" className="active flexContainer">
                                                                                        <div className="flex-item icon">
                                                                                            <span className="icon-checked" />
                                                                                        </div>
                                                                                        <div className="flex-item flag">
                                                                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                                                                                            <span className="flag-icon flag-icon-us" />
                                                                                        </div>
                                                                                        <div className="flex-item title">US</div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button type="button" class="mainbtn">Save</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-8 col-md-6 filter-Quap2'>
                                                        {/* <select class="form-select" aria-label="Default select example"
                                                            onChange={e => getQuestion(e.target.value)}
                                                        >
                                                            <option hidden>Question</option>
                                                            {(dashboard[4]?.Question[0]).map(q =>
                                                                (<option value={q._id}>{q.question}</option>)
                                                            )}
                                                        </select> */}

                                                        <Multiselect
                                                            placeholder='Select Question'
                                                            options={(dashboard[4]?.Question[0]).map(q => q)} // Options to display in the dropdown
                                                            selectedValues={Selected[0]?.question} // Preselected value to persist in dropdown
                                                            onSelect={getQuestion} // Function will trigger on select event
                                                            onRemove={onRemove} // Function will trigger on remove event
                                                            displayValue="question" // Property name to display in the dropdown options
                                                        />

                                                        {/* <Select isMulti
                                                            placeholder="Search Question"
                                                            aria-label="Default select example"
                                                            onChange={e=>SetQuestion(e.target.value)}
                                                            options={(dashboard[4]?.Question[0])?.map((label)=>({
                                                            label,
                                                            value: label        }))} /> */}
                                                        {/* select.map((label) => ({ label, value: label })) */}
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                        {/* Que start */}
                                        {/* 1 */}
                                        {report && report.map((setValue, index) => (
                                            <div className='container-fluid allQueRport py-3 mt-2 earnSec'>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className="panel panel-default" style={{ opacity: 1 }}>
                                                            <div className="panel-heading">
                                                                <div>
                                                                    <div className="result-title">
                                                                        <div className="question-type result-title__type">Single Selection</div>
                                                                        <div className="clearfix">
                                                                            <div className="result-title__question--overflow-hidden">
                                                                                <div>
                                                                                    <h4 className="result-title__question">
                                                                                        <span className="result-title__question-label"></span>{setValue?.question}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="clearer" />
                                                                </div>
                                                            </div>
                                                            <div className="panel-body">
                                                                <div>
                                                                    <div className="react-bs-table-container">
                                                                        <div className="react-bs-table react-bs-table-bordered tblAmain" style={{ height: '100%' }}>
                                                                            <div className="react-bs-container-header table-header-wrapper">
                                                                                <table className="table table-hover table-bordered" data-classes-applied="true">
                                                                                    <colgroup>
                                                                                        <col style={{ display: 'none' }} />
                                                                                        <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                        <col />
                                                                                        <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                        <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                        <col style={{ width: '100px', minWidth: '100px' }} />
                                                                                    </colgroup>
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th data-is-only-head="false" data-field="id" style={{ textAlign: 'left', display: 'none' }}>
                                                                                                <div />
                                                                                            </th>
                                                                                            <th className="text-title" data-is-only-head="false" title="#" data-field="ai" style={{ textAlign: 'center' }}>#
                                                                                                <div />
                                                                                            </th>
                                                                                            <th className="text-title answer-legible-text" data-is-only-head="false" title="Answers" data-field="body" style={{ textAlign: 'left' }}>Answers
                                                                                                <div />
                                                                                            </th>
                                                                                            <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                                <div className="nowrap">Answers (%)</div>
                                                                                                <div />
                                                                                            </th>
                                                                                            <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                                <div className="nowrap">Count</div>
                                                                                                <div />
                                                                                            </th>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <th className="text-title hidden-xs" data-is-only-head="false" data-field="percent" style={{ textAlign: 'left' }}>
                                                                                                <div />
                                                                                            </th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                </table>
                                                                            </div>
                                                                            {(setValue.ans)?.map((e, index) => (
                                                                                <div className="react-bs-container-body" style={{ height: '100%' }}>
                                                                                    <table className="table table-bordered table-hover table-responsive" data-classes-applied="true">
                                                                                        <colgroup>
                                                                                            <col style={{ display: 'none' }} />
                                                                                            <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                            <col />
                                                                                            <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                            <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                            <col style={{ width: '100px', minWidth: '100px' }} />
                                                                                        </colgroup>
                                                                                        <tbody>
                                                                                            <tr className="selected">
                                                                                                <td tabIndex={1} style={{ textAlign: 'left', display: 'none' }}>33665813</td>
                                                                                                <td tabIndex={2} className="indicator" style={{ textAlign: 'center' }}>
                                                                                                    <div title="Filter results by this answer">A{index + 1}</div>
                                                                                                </td>
                                                                                                <td tabIndex={3} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                                    <div className="answer__container selected-item">
                                                                                                        <div className="answer__text">
                                                                                                            <div className="flex">
                                                                                                                <span className="flex-1">{e.Option}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td tabIndex={5} className="percent" style={{ textAlign: 'right' }}>
                                                                                                    <div>
                                                                                                        <div>
                                                                                                            <span className="selected-item">{e.count}%</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td tabIndex={6} className="percent" style={{ textAlign: 'right' }}>
                                                                                                    <div>
                                                                                                        <div className="selected-item">{e.Answer}</div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td tabIndex={4} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                                    <div>
                                                                                                        <div className="full-bar">
                                                                                                            <div className="bar-container">
                                                                                                                <span className="percentage-bar selected" style={{ width: `${e.count}%` }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>))}
                                                                        </div>
                                                                        <div className="s-alert-wrapper" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="panel-footer">
                                                                <div className="row">
                                                                    <div className="available-charts col-sm-4">
                                                                        <div>
                                                                            <button type="button" className="chart-type chart-type-btn" onClick={Q => SetGragh("chart", setValue._id, index)} >
                                                                                <i className="bi bi-bar-chart-line-fill" />
                                                                            </button>
                                                                            <button type="button" className="chart-type chart-type-btn" onClick={Q => SetGragh("pichart", setValue._id, index)}>
                                                                                <i className="bi bi-pie-chart-fill" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-8">
                                                                        <div className="result-footer">
                                                                            <div className="total-unique-answers-label">Completes</div>
                                                                            <div className="total-unique-answers">{((setValue?.ans)?.map(e => e.Answer).reduce((a, b) => a + b))}</div>
                                                                            <div className="clearer" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {setValue.graph && setValue.graph === 'chart' && checkgraph && (<div className='d-flex justify-content-center'> <ReactApexChart
                                                                    options={setValue.chartData ? setValue.chartData.options : []}
                                                                    series={setValue.chartData ? setValue.chartData.series : []}
                                                                    type="bar"
                                                                    width="500"
                                                                />
                                                                </div>)}
                                                                {setValue.graph && setValue.graph === 'pichart' && checkgraph && (<div className='d-flex justify-content-center'><ReactApexChart options={setValue.picData?.options} series={setValue.picData?.series} type="pie" width={380} /> </div>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}





































                                        {/* 2 */}
                                        <div className='container-fluid allQueRport py-3 mt-2 earnSec'>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className="panel panel-default" style={{ opacity: 1 }}>
                                                        <div className="panel-heading">
                                                            <div>
                                                                <div className="result-title">
                                                                    <div className="question-type result-title__type">Multiple Selection</div>
                                                                    <div className="clearfix">
                                                                        <div className="result-title__question--overflow-hidden">
                                                                            <div>
                                                                                <h4 className="result-title__question">
                                                                                    <span className="result-title__question-label">Q2.</span>Which of the following social media platforms do you currently log into at least weekly?
                                                                                </h4>
                                                                                <div className="pf-info">
                                                                                    <span className="icon-info pf-info__icon" />
                                                                                    <p className="pf-info__text">Percent (Respondents) is calculated by dividing each answer count by the total unique respondents. <br />Percent (Answers) is calculated by dividing each answer count by the total counts collected. </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearer" />
                                                            </div>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div>
                                                                <div className="react-bs-table-container">
                                                                    <div className="react-bs-table react-bs-table-bordered tblAmain" style={{ height: '100%' }}>
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <table className="table table-hover table-bordered" data-classes-applied="true">
                                                                                <colgroup>
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '170px', minWidth: '170px' }} />
                                                                                    <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                    <col style={{ width: '170px', minWidth: '170px' }} />
                                                                                    <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                    <col style={{ width: '320px', minWidth: '320px' }} />
                                                                                </colgroup>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th data-is-only-head="false" data-field="id" style={{ textAlign: 'left', display: 'none' }}>

                                                                                        </th>
                                                                                        <th className="text-title" data-is-only-head="false" title="#" data-field="ai" style={{ textAlign: 'center' }}>#

                                                                                        </th>
                                                                                        <th className="text-title answer-legible-text" data-is-only-head="false" title="Answers" data-field="body" style={{ textAlign: 'left' }}>

                                                                                            <div className="nowrap">Answers</div>
                                                                                        </th>
                                                                                        <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                            <div className="nowrap">Respondents (%)</div>

                                                                                        </th>
                                                                                        <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                            <div className="nowrap">Answers (%)</div>
                                                                                        </th>
                                                                                        <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                            <div className="nowrap">Count</div>
                                                                                        </th>
                                                                                        <th className="text-title hidden-xs" data-is-only-head="false" data-field="percent" style={{ textAlign: 'left' }}>
                                                                                            <div />
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                            </table>
                                                                        </div>
                                                                        <div className="react-bs-container-body" style={{ height: '100%' }}>
                                                                            <table className="table table-bordered table-hover table-responsive" data-classes-applied="true">
                                                                                <colgroup>
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '170px', minWidth: '170px' }} />
                                                                                    <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                    <col style={{ width: '170px', minWidth: '170px' }} />
                                                                                    <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                    <col style={{ width: '320px', minWidth: '320px' }} />
                                                                                </colgroup>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td tabIndex={1} style={{ textAlign: 'left', display: 'none' }}>33665777</td>
                                                                                        <td tabIndex={2} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A1</div>
                                                                                        </td>
                                                                                        <td tabIndex={3} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container selected-item">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">Facebook</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={5} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span className="selected-item">84.40%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={6} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span className="selected-item">16.80%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={7} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div className="selected-item">844</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={4} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar selected" style={{ width: '84.4%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={8} style={{ textAlign: 'left', display: 'none' }}>33665778</td>
                                                                                        <td tabIndex={9} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A2</div>
                                                                                        </td>
                                                                                        <td tabIndex={10} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">Instagram</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={12} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>70.60%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={13} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>14.06%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={14} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>706</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={11} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '70.6%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={15} style={{ textAlign: 'left', display: 'none' }}>33665779</td>
                                                                                        <td tabIndex={16} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A3</div>
                                                                                        </td>
                                                                                        <td tabIndex={17} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">Twitter</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={19} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>48.70%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={20} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>9.70%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={21} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>487</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={18} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '48.7%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={22} style={{ textAlign: 'left', display: 'none' }}>33665780</td>
                                                                                        <td tabIndex={23} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A4</div>
                                                                                        </td>
                                                                                        <td tabIndex={24} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">Snapchat</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={26} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>46.60%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={27} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>9.28%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={28} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>466</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={25} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '46.6%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={29} style={{ textAlign: 'left', display: 'none' }}>33665781</td>
                                                                                        <td tabIndex={30} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A5</div>
                                                                                        </td>
                                                                                        <td tabIndex={31} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">TikTok</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={33} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>39.00%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={34} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>7.76%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={35} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>390</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={32} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '39%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={36} style={{ textAlign: 'left', display: 'none' }}>33665782</td>
                                                                                        <td tabIndex={37} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A6</div>
                                                                                        </td>
                                                                                        <td tabIndex={38} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">Pinterest </span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={40} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>30.40%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={41} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>6.05%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={42} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>304</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={39} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '30.4%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={43} style={{ textAlign: 'left', display: 'none' }}>33665783</td>
                                                                                        <td tabIndex={44} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A7</div>
                                                                                        </td>
                                                                                        <td tabIndex={45} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">Reddit</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={47} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>23.50%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={48} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>4.68%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={49} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>235</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={46} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '23.5%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={50} style={{ textAlign: 'left', display: 'none' }}>33665784</td>
                                                                                        <td tabIndex={51} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A8</div>
                                                                                        </td>
                                                                                        <td tabIndex={52} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">WhatsApp</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={54} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>56.00%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={55} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>11.15%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={56} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>560</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={53} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '56%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={57} style={{ textAlign: 'left', display: 'none' }}>33665785</td>
                                                                                        <td tabIndex={58} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A9</div>
                                                                                        </td>
                                                                                        <td tabIndex={59} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">YouTube</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={61} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>75.70%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={62} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>15.07%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={63} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>757</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={60} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '75.7%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={64} style={{ textAlign: 'left', display: 'none' }}>33665823</td>
                                                                                        <td tabIndex={65} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A10</div>
                                                                                        </td>
                                                                                        <td tabIndex={66} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">LinkedIn</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={68} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>26.10%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={69} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>5.20%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={70} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>261</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={67} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '26.1%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={71} style={{ textAlign: 'left', display: 'none' }}>33665786</td>
                                                                                        <td tabIndex={72} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A11</div>
                                                                                        </td>
                                                                                        <td tabIndex={73} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">Other</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={75} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>1.30%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={76} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>0.26%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={77} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>13</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={74} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '1.3%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="s-alert-wrapper" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel-footer">
                                                            <div className="row">
                                                                <div className="available-charts col-sm-4">
                                                                    <div>
                                                                        <button type="button" className="chart-type chart-type-btn" onClick={() => { setChartCount(1) }}>
                                                                            <i className="bi bi-bar-chart-line-fill" />
                                                                        </button>

                                                                        <button type="button" className="chart-type chart-type-btn" onClick={() => { setChartCount(2) }}>
                                                                            <i className="bi bi-pie-chart-fill" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <div className="result-footer">
                                                                        <div className="total-unique-answers-label">Completes</div>
                                                                        <div className="total-unique-answers">1000</div>
                                                                        <div className="clearer" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex justify-content-center'>
                                                                {chartCount == 1 && <ReactApexChart
                                                                    options={chartData1.options}
                                                                    series={chartData1.series}
                                                                    type="bar"
                                                                    height={350}
                                                                />}
                                                                {chartCount == 2 && <ReactApexChart
                                                                    options={chartData.options}
                                                                    series={chartData.series}
                                                                    type="pie"
                                                                    width="500"
                                                                />}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 3 */}
                                        <div className='container-fluid allQueRport py-3 mt-2 earnSec'>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className="with-simple-view panel panel-default" style={{ opacity: 1 }}>
                                                        <div className="panel-heading">
                                                            <div>
                                                                <div className="result-title">
                                                                    <div className="question-type result-title__type">Matrix, Single Selection</div>
                                                                    <div className="clearfix">
                                                                        <div className="result-title__question--overflow-hidden">
                                                                            <div>
                                                                                <h4 className="result-title__question">
                                                                                    <span className="result-title__question-label">Q9.</span>How important are the following factors to you when purchasing a new television?
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearer" />
                                                            </div>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div className="matrix">
                                                                <div className="react-bs-table-container matrix-table">
                                                                    <div className="react-bs-table react-bs-table-bordered tblAmain" style={{ height: '100%' }}>
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <table className="table table-hover table-bordered">
                                                                                <colgroup>
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '150px', minWidth: '150px' }} />
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '150px', minWidth: '150px' }} />
                                                                                    <col style={{ width: '150px', minWidth: '150px' }} />
                                                                                    <col style={{ width: '190px', minWidth: '190px' }} />
                                                                                    <col style={{ width: '150px', minWidth: '150px' }} />
                                                                                    <col style={{ width: '200px', minWidth: '200px' }} />
                                                                                    <col style={{ display: 'none' }} />
                                                                                </colgroup>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-title" data-is-only-head="false" title="#" data-field="ai" style={{ textAlign: 'center' }}>#
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title" data-is-only-head="false" title="Statements" data-field="body" style={{ textAlign: 'left' }}>Statements
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title scorepoint" data-is-only-head="false" data-field="undefined" style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title scorepoint" data-is-only-head="false" data-field="undefined" style={{ textAlign: 'left' }}>Very important
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title scorepoint" data-is-only-head="false" data-field="undefined" style={{ textAlign: 'left' }}>Somewhat Important
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title scorepoint" data-is-only-head="false" data-field="undefined" style={{ textAlign: 'left' }}>Neither important or unimportant
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title scorepoint" data-is-only-head="false" data-field="undefined" style={{ textAlign: 'left' }}>Somewhat unimportant
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title scorepoint" data-is-only-head="false" data-field="undefined" style={{ textAlign: 'left' }}>Very unimportant
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title scorepoint" data-is-only-head="false" data-field="undefined" style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div />
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                            </table>
                                                                        </div>
                                                                        <div className="react-bs-container-body" style={{ height: '100%' }}>
                                                                            <table className="table table-bordered table-hover table-responsive">
                                                                                <colgroup>
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '150px', minWidth: '150px' }} />
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '150px', minWidth: '150px' }} />
                                                                                    <col style={{ width: '150px', minWidth: '150px' }} />
                                                                                    <col style={{ width: '150px', minWidth: '150px' }} />
                                                                                    <col style={{ width: '150px', minWidth: '150px' }} />
                                                                                    <col style={{ width: '200px', minWidth: '200px' }} />
                                                                                    <col style={{ display: 'none' }} />
                                                                                </colgroup>
                                                                                <tbody>
                                                                                    <tr className>
                                                                                        <td tabIndex={1} className="indicator" style={{ textAlign: 'center' }}>A1</td>
                                                                                        <td tabIndex={2} className="text" style={{ textAlign: 'left' }}>
                                                                                            <div className="flex">
                                                                                                <span className="flex-1">Price</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={3} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">920</div>
                                                                                                        <div className="percent">( <span className>93.50%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={4} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">638</div>
                                                                                                        <div className="percent">( <span className>64.84%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={5} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">282</div>
                                                                                                        <div className="percent">( <span className>28.66%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={6} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">56</div>
                                                                                                        <div className="percent">( <span className>5.69%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={7} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">6</div>
                                                                                                        <div className="percent">( <span className>0.61%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={8} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">2</div>
                                                                                                        <div className="percent">( <span className>0.20%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={9} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">8</div>
                                                                                                        <div className="percent">( <span className>0.81%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className>
                                                                                        <td tabIndex={10} className="indicator" style={{ textAlign: 'center' }}>A2</td>
                                                                                        <td tabIndex={11} className="text" style={{ textAlign: 'left' }}>
                                                                                            <div className="flex">
                                                                                                <span className="flex-1">Smart TV features (Integration with apps, internet connectivity)</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={12} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">866</div>
                                                                                                        <div className="percent">( <span className>88.01%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={13} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">542</div>
                                                                                                        <div className="percent">( <span className>55.08%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={14} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">324</div>
                                                                                                        <div className="percent">( <span className>32.93%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={15} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">83</div>
                                                                                                        <div className="percent">( <span className>8.43%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={16} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">19</div>
                                                                                                        <div className="percent">( <span className>1.93%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={17} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">16</div>
                                                                                                        <div className="percent">( <span className>1.63%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={18} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">35</div>
                                                                                                        <div className="percent">( <span className>3.56%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className>
                                                                                        <td tabIndex={19} className="indicator" style={{ textAlign: 'center' }}>A3</td>
                                                                                        <td tabIndex={20} className="text" style={{ textAlign: 'left' }}>
                                                                                            <div className="flex">
                                                                                                <span className="flex-1">Screen size</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={21} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">936</div>
                                                                                                        <div className="percent">( <span className>95.12%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={22} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">622</div>
                                                                                                        <div className="percent">( <span className>63.21%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={23} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">314</div>
                                                                                                        <div className="percent">( <span className>31.91%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={24} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">41</div>
                                                                                                        <div className="percent">( <span className>4.17%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={25} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">7</div>
                                                                                                        <div className="percent">( <span className>0.71%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={26} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">0</div>
                                                                                                        <div className="percent">( <span className>0.00%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={27} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">7</div>
                                                                                                        <div className="percent">( <span className>0.71%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className>
                                                                                        <td tabIndex={28} className="indicator" style={{ textAlign: 'center' }}>A4</td>
                                                                                        <td tabIndex={29} className="text" style={{ textAlign: 'left' }}>
                                                                                            <div className="flex">
                                                                                                <span className="flex-1">Warranty</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={30} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">815</div>
                                                                                                        <div className="percent">( <span className>82.83%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={31} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">433</div>
                                                                                                        <div className="percent">( <span className>44.00%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={32} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">382</div>
                                                                                                        <div className="percent">( <span className>38.82%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={33} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">125</div>
                                                                                                        <div className="percent">( <span className>12.70%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={34} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">31</div>
                                                                                                        <div className="percent">( <span className>3.15%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={35} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">13</div>
                                                                                                        <div className="percent">( <span className>1.32%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={36} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">44</div>
                                                                                                        <div className="percent">( <span className>4.47%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className>
                                                                                        <td tabIndex={37} className="indicator" style={{ textAlign: 'center' }}>A5</td>
                                                                                        <td tabIndex={38} className="text" style={{ textAlign: 'left' }}>
                                                                                            <div className="flex">
                                                                                                <span className="flex-1">Screen resolution (720p, 1080p, 4K)</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={39} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">922</div>
                                                                                                        <div className="percent">( <span className>93.70%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={40} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">622</div>
                                                                                                        <div className="percent">( <span className>63.21%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={41} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">300</div>
                                                                                                        <div className="percent">( <span className>30.49%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={42} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">50</div>
                                                                                                        <div className="percent">( <span className>5.08%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={43} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">7</div>
                                                                                                        <div className="percent">( <span className>0.71%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={44} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">5</div>
                                                                                                        <div className="percent">( <span className>0.51%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={45} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">12</div>
                                                                                                        <div className="percent">( <span className>1.22%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr className>
                                                                                        <td tabIndex={46} className="indicator" style={{ textAlign: 'center' }}>A6</td>
                                                                                        <td tabIndex={47} className="text" style={{ textAlign: 'left' }}>
                                                                                            <div className="flex">
                                                                                                <span className="flex-1">Number of HDMI ports</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={48} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">809</div>
                                                                                                        <div className="percent">( <span className>82.22%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={49} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">352</div>
                                                                                                        <div className="percent">( <span className>35.77%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={50} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">457</div>
                                                                                                        <div className="percent">( <span className>46.44%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={51} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">129</div>
                                                                                                        <div className="percent">( <span className>13.11%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={52} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">36</div>
                                                                                                        <div className="percent">( <span className>3.66%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={53} className style={{ textAlign: 'left' }}>
                                                                                            <div className="matrix-values" style={{ cursor: 'pointer' }}>
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">10</div>
                                                                                                        <div className="percent">( <span className>1.02%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={54} className style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div className="matrix-values">
                                                                                                <div className="values">
                                                                                                    <div>
                                                                                                        <div className="count">46</div>
                                                                                                        <div className="percent">( <span className>4.67%</span>) </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="s-alert-wrapper" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel-footer">
                                                            <div className="row">
                                                                <div className="available-charts col-sm-4">
                                                                    <div>
                                                                        <button type="button" className="chart-type chart-type-btn">
                                                                            <i class="bi bi-bar-chart-steps"></i>
                                                                        </button>
                                                                        <button type="button" className="score-points-top-btn">Show Top 2 Box Reporting</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <div className="result-footer">
                                                                        <div className="total-unique-answers-label">Completes</div>
                                                                        <div className="total-unique-answers">984</div>
                                                                        <div className="clearer" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 4 */}
                                        <div className='container-fluid allQueRport py-3 mt-2 earnSec'>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className="panel panel-default" style={{ opacity: 1 }}>
                                                        <div className="panel-heading">
                                                            <div>
                                                                <div className="result-title">
                                                                    <div className="question-type result-title__type">
                                                                        <span>NPS <sup>®</sup>
                                                                        </span>
                                                                    </div>
                                                                    <div className="clearfix">
                                                                        <div className="result-title__question--overflow-hidden">
                                                                            <div>
                                                                                <h4 className="result-title__question">
                                                                                    <span className="result-title__question-label">Q18.</span>How likely are you to recommend Coca-Cola to a friend or colleague? (0 - Not at all likely to recommend to 10 - Extremely likely to recommend)
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearer" />
                                                            </div>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div>
                                                                <div className="react-bs-table-container">
                                                                    <div className="react-bs-table react-bs-table-bordered tblAmain" style={{ height: '100%' }}>
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <table className="table table-hover table-bordered" data-classes-applied="true">
                                                                                <colgroup>
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '100px', minWidth: '100px' }} />
                                                                                    <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                    <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                    <col style={{ width: '100px', minWidth: '100px' }} />
                                                                                </colgroup>
                                                                            </table>
                                                                        </div>
                                                                        <div className="react-bs-container-body" style={{ height: '100%' }}>
                                                                            <table className="table table-bordered table-hover" data-classes-applied="true">
                                                                                <colgroup>
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '100px', minWidth: '100px' }} />
                                                                                    <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                    <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                    <col style={{ width: '100px', minWidth: '100px' }} />
                                                                                </colgroup>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td tabIndex={1} style={{ textAlign: 'left', display: 'none' }}>33665757</td>
                                                                                        <td tabIndex={2} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A1</div>
                                                                                        </td>
                                                                                        <td tabIndex={3} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">0</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={5} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>5.50%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={6} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>55</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={4} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '5.5%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={7} style={{ textAlign: 'left', display: 'none' }}>33665751</td>
                                                                                        <td tabIndex={8} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A2</div>
                                                                                        </td>
                                                                                        <td tabIndex={9} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">1</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={11} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>1.80%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={12} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>18</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={10} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '1.8%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={13} style={{ textAlign: 'left', display: 'none' }}>33665752</td>
                                                                                        <td tabIndex={14} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A3</div>
                                                                                        </td>
                                                                                        <td tabIndex={15} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">2</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={17} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>2.10%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={18} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>21</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={16} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '2.1%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={19} style={{ textAlign: 'left', display: 'none' }}>33665759</td>
                                                                                        <td tabIndex={20} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A4</div>
                                                                                        </td>
                                                                                        <td tabIndex={21} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">3</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={23} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>4.30%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={24} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>43</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={22} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '4.3%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={25} style={{ textAlign: 'left', display: 'none' }}>33665760</td>
                                                                                        <td tabIndex={26} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A5</div>
                                                                                        </td>
                                                                                        <td tabIndex={27} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">4</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={29} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>3.20%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={30} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>32</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={28} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '3.2%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={31} style={{ textAlign: 'left', display: 'none' }}>33665753</td>
                                                                                        <td tabIndex={32} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A6</div>
                                                                                        </td>
                                                                                        <td tabIndex={33} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">5</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={35} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>7.80%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={36} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>78</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={34} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '7.8%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={37} style={{ textAlign: 'left', display: 'none' }}>33665758</td>
                                                                                        <td tabIndex={38} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A7</div>
                                                                                        </td>
                                                                                        <td tabIndex={39} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">6</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={41} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>6.20%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={42} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>62</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={40} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '6.2%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={43} style={{ textAlign: 'left', display: 'none' }}>33665756</td>
                                                                                        <td tabIndex={44} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A8</div>
                                                                                        </td>
                                                                                        <td tabIndex={45} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">7</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={47} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>11.10%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={48} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>111</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={46} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '11.1%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={49} style={{ textAlign: 'left', display: 'none' }}>33665755</td>
                                                                                        <td tabIndex={50} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A9</div>
                                                                                        </td>
                                                                                        <td tabIndex={51} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">8</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={53} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>15.70%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={54} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>157</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={52} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '15.7%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={55} style={{ textAlign: 'left', display: 'none' }}>33665750</td>
                                                                                        <td tabIndex={56} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A10</div>
                                                                                        </td>
                                                                                        <td tabIndex={57} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">9</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={59} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>10.90%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={60} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>109</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={58} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '10.9%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={61} style={{ textAlign: 'left', display: 'none' }}>33665754</td>
                                                                                        <td tabIndex={62} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A11</div>
                                                                                        </td>
                                                                                        <td tabIndex={63} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container selected-item">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">10</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={65} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span className="selected-item">31.40%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={66} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div className="selected-item">314</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={64} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar selected" style={{ width: '31.4%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="s-alert-wrapper" />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div style={{ height: '100%', alignItems: 'center', display: 'flex' }}>
                                                                    <div style={{ padding: '20px', color: 'rgb(143, 152, 169)', textAlign: 'center' }}>Net Promoter Score = <span style={{ color: 'rgb(88, 204, 32)', fontWeight: 'bolder' }}>% Promoters</span> - <span style={{ color: 'rgb(251, 56, 82)', fontWeight: 'bolder' }}>% Detractors</span>
                                                                    </div>
                                                                </div>
                                                                <div className="nps-container row" style={{ display: 'flex' }}>
                                                                    <div className="col-lg-12 hiChartYU">
                                                                        <div style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}>
                                                                            <div style={{ margin: '1px' }}>
                                                                                <div data-highcharts-chart={11}>
                                                                                    <div id="highcharts-dc9w08v-75" dir="ltr" className="highcharts-container " style={{ position: 'relative', overflow: 'hidden', width: '100%', height: '100%', textAlign: 'left', lineHeight: 'normal', zIndex: 0 }}>
                                                                                        <svg version="1.1" className="highcharts-root" style={{ fontFamily: '"Roboto", sans-serif', fontSize: '12px' }} xmlns="http://www.w3.org/2000/svg" width={458} height={250} viewBox="0 0 458 250">
                                                                                            <desc>Created with Highcharts 6.2.0</desc>
                                                                                            <defs>
                                                                                                <clipPath id="highcharts-dc9w08v-77">
                                                                                                    <rect x={0} y={0} width={438} height={225} fill="none" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                            <rect fill="#ffffff" className="highcharts-background" x={0} y={0} width={458} height={250} rx={0} ry={0} />
                                                                                            <rect fill="none" className="highcharts-plot-background" x={10} y={10} width={438} height={225} />
                                                                                            <g className="highcharts-pane-group" data-z-index={0}>
                                                                                                <path fill="#eef3f7" d="M 71.5 201.24999999999997 A 157.5 157.5 0 0 1 386.49992125000654 201.09250002625006 L 323.49995275000396 201.15550001575005 A 94.5 94.5 0 0 0 134.5 201.25 Z" stroke="#cccccc" strokeWidth={1} className="highcharts-pane " />
                                                                                            </g>
                                                                                            <g className="highcharts-grid highcharts-yaxis-grid " data-z-index={1}>
                                                                                                <path fill="none" data-z-index={1} className="highcharts-grid-line" d="M 229 201.25 L 71.5 201.24999999999997" opacity={1} />
                                                                                                <path fill="none" data-z-index={1} className="highcharts-grid-line" d="M 229 201.25 L 386.5 201.25000000000006" opacity={1} />
                                                                                            </g>
                                                                                            <rect fill="none" className="highcharts-plot-border" data-z-index={1} x={10} y={10} width={438} height={225} />
                                                                                            <g className="highcharts-axis highcharts-yaxis " data-z-index={2}>
                                                                                                <path fill="none" className="highcharts-tick" stroke="#ccd6eb" strokeWidth={2} d="M 71.5 201.24999999999997 L 81.5 201.24999999999997" opacity={1} />
                                                                                                <path fill="none" className="highcharts-tick" stroke="#ccd6eb" strokeWidth={2} d="M 386.5 201.25000000000006 L 376.5 201.25000000000006" opacity={1} />
                                                                                                <path fill="none" className="highcharts-axis-line" data-z-index={7} d="M 71.5 201.24999999999997 A 157.5 157.5 0 0 1 386.49992125000654 201.09250002625 M 229 201.25 A 0 0 0 0 0 229 201.25 " />
                                                                                            </g>
                                                                                            <g data-z-index={2} className="highcharts-data-labels highcharts-series-0 highcharts-solidgauge-series  highcharts-tracker" visibility="visible" transform="translate(10,10) scale(1 1)">
                                                                                                <g className="highcharts-label highcharts-data-label highcharts-data-label-color-0  highcharts-tracker" data-z-index={1} transform="translate(200,142)" />
                                                                                            </g>
                                                                                            <g className="highcharts-series-group" data-z-index={3}>
                                                                                                <g data-z-index="0.1" className="highcharts-series highcharts-series-0 highcharts-solidgauge-series  highcharts-tracker" transform="translate(10,10) scale(1 1)" clipPath="url(#highcharts-dc9w08v-77)">
                                                                                                    <path fill="#FB3852" d="M 61.5 191.24999999999997 A 157.5 157.5 0 0 1 245.9236650615813 36.06827987919522 L 235.15419903694877 98.14096792751712 A 94.5 94.5 0 0 0 124.5 191.25 Z" className="highcharts-point highcharts-color-0" sweep-flag={0} strokeLinecap="round" strokeLinejoin="round" rounded="undefined" />
                                                                                                </g>
                                                                                                <g data-z-index="0.1" className="highcharts-markers highcharts-series-0 highcharts-solidgauge-series " transform="translate(10,10) scale(1 1)" clipPath="none" />
                                                                                            </g>
                                                                                            <g className="highcharts-exporting-group" data-z-index={3}>
                                                                                                <g className="highcharts-button highcharts-contextbutton" strokeLinecap="round" transform="translate(424,10)">
                                                                                                    <title>Chart context menu</title>
                                                                                                    <rect fill="#ffffff" className=" highcharts-button-box" x="0.5" y="0.5" width={24} height={22} rx={2} ry={2} stroke="none" strokeWidth={1} />
                                                                                                    <path fill="#3d4757" d="M 6 6.5 L 20 6.5 M 6 11.5 L 20 11.5 M 6 16.5 L 20 16.5" className="highcharts-button-symbol" data-z-index={1} stroke="#3d4757" strokeWidth={3} />
                                                                                                    <text x={0} data-z-index={1} style={{ fontWeight: 'normal', color: 'rgb(51, 51, 51)', cursor: 'pointer', fill: 'rgb(51, 51, 51)' }} y={12} />
                                                                                                </g>
                                                                                            </g>
                                                                                            <text x={229} textAnchor="middle" className="highcharts-title" data-z-index={4} style={{ color: 'rgb(51, 51, 51)', fontSize: '18px', fill: 'rgb(51, 51, 51)' }} y={24} />
                                                                                            <text x={229} textAnchor="middle" className="highcharts-subtitle" data-z-index={4} style={{ color: 'rgb(102, 102, 102)', fill: 'rgb(102, 102, 102)' }} y={24} />
                                                                                            <g className="highcharts-legend" data-z-index={7}>
                                                                                                <rect fill="none" className="highcharts-legend-box" rx={0} ry={0} x={0} y={0} width={8} height={8} visibility="hidden" />
                                                                                                <g data-z-index={1}>
                                                                                                    <g />
                                                                                                </g>
                                                                                            </g>
                                                                                            <g className="highcharts-axis-labels highcharts-yaxis-labels " data-z-index={7}>
                                                                                                <text x="96.5" style={{ color: 'rgb(102, 102, 102)', cursor: 'default', fontSize: '11px', fill: 'rgb(102, 102, 102)' }} textAnchor="middle" transform="translate(0,0)" y="217.24999999999997" opacity={1}>-100</text>
                                                                                                <text x="361.5" style={{ color: 'rgb(102, 102, 102)', cursor: 'default', fontSize: '11px', fill: 'rgb(102, 102, 102)' }} textAnchor="middle" transform="translate(0,0)" y="217.25000000000006" opacity={1}>100</text>
                                                                                            </g>
                                                                                        </svg>
                                                                                        <div className="highcharts-data-labels chrtMINa highcharts-series-0 highcharts-solidgauge-series  highcharts-tracker" style={{ position: 'absolute', left: '10px', top: '10px', opacity: 1, visibility: 'visible' }}>
                                                                                            <div className="highcharts-label highcharts-data-label highcharts-data-label-color-0  highcharts-tracker" style={{ position: 'absolute', left: '200px', top: '142px', opacity: 1 }}>
                                                                                                <span data-z-index={1} style={{ fontSize: '11px', position: 'absolute', whiteSpace: 'nowrap', fontWeight: 'bold', color: 'rgb(0, 0, 0)', marginLeft: '0px', marginTop: '0px', left: '5px', top: '5px' }}>
                                                                                                    <div style={{ textAlign: 'center' }}>
                                                                                                        <span style={{ fontSize: '25px', color: 'black' }}>11</span>
                                                                                                        <br />
                                                                                                        <span style={{ fontSize: '12px', color: 'rgb(143, 152, 169)' }}>NPS</span>
                                                                                                    </div>
                                                                                                </span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div style={{ margin: '20px' }}>
                                                                                    <div style={{ float: 'left', backgroundColor: 'rgb(251, 56, 82)', height: '6px', width: '30.9%' }} />
                                                                                    <div style={{ float: 'left', backgroundColor: 'rgb(250, 203, 58)', height: '6px', width: '26.8%' }} />
                                                                                    <div style={{ float: 'left', backgroundColor: 'rgb(88, 204, 32)', height: '6px', width: '42.3%' }} />
                                                                                    <div className="clearfix" />
                                                                                </div>
                                                                                <div className="row" style={{ paddingLeft: '20px', paddingRight: '10px' }}>
                                                                                    <div className="col-lg-4">
                                                                                        <div style={{ maxWidth: '155px', margin: '8px auto' }}>
                                                                                            <span style={{ display: 'block', position: 'relative', borderRadius: '50%', top: '15px', left: '0px', width: '10px', height: '10px', backgroundColor: 'rgb(251, 56, 82)' }} />
                                                                                            <p style={{ margin: '0px', paddingLeft: '20px' }}>Detractors 30.9%</p>
                                                                                            <p style={{ margin: '0px', paddingLeft: '20px', color: 'rgb(143, 152, 169)', fontSize: '12px' }}>People who voted (0-6)</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4">
                                                                                        <div style={{ maxWidth: '155px', margin: '8px auto' }}>
                                                                                            <span style={{ display: 'block', position: 'relative', borderRadius: '50%', top: '15px', left: '0px', width: '10px', height: '10px', backgroundColor: 'rgb(250, 203, 58)' }} />
                                                                                            <p style={{ margin: '0px', paddingLeft: '20px' }}>Passives 26.8%</p>
                                                                                            <p style={{ margin: '0px', paddingLeft: '20px', color: 'rgb(143, 152, 169)', fontSize: '12px' }}>People who voted (7-8)</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-4">
                                                                                        <div style={{ maxWidth: '155px', margin: '8px auto' }}>
                                                                                            <span style={{ display: 'block', position: 'relative', borderRadius: '50%', top: '15px', left: '0px', width: '10px', height: '10px', backgroundColor: 'rgb(88, 204, 32)' }} />
                                                                                            <p style={{ margin: '0px', paddingLeft: '20px' }}>Promoters 42.3%</p>
                                                                                            <p style={{ margin: '0px', paddingLeft: '20px', color: 'rgb(143, 152, 169)', fontSize: '12px' }}>People who voted (9-10)</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel-footer">
                                                            <div className="row">
                                                                <div className="available-charts col-xs-4">
                                                                    <div />
                                                                </div>
                                                                <div className="col-xs-8">
                                                                    <div className="result-footer">
                                                                        <div className="total-unique-answers-label">Completes</div>
                                                                        <div className="total-unique-answers">1000</div>
                                                                        <div className="clearer" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 5 */}
                                        <div className='container-fluid allQueRport py-3 mt-2 earnSec'>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className="panel panel-default" style={{ opacity: 1 }}>
                                                        <div className="panel-heading">
                                                            <div>
                                                                <div className="result-title">
                                                                    <div className="question-type result-title__type">Rating Stars</div>
                                                                    <div className="clearfix">
                                                                        <div className="result-title__question--overflow-hidden">
                                                                            <div>
                                                                                <h4 className="result-title__question">
                                                                                    <span className="result-title__question-label">Q5.</span>Please rate the following Facebook ad, with 5 stars being great and 1 star being terrible.
                                                                                </h4>
                                                                                <div className="image-container">
                                                                                    <img src="../images/faceAdvo.jpg" role="presentation" alt="Uncaptioned" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearer" />
                                                            </div>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div>
                                                                <div className="react-bs-table-container">
                                                                    <div className="react-bs-table react-bs-table-bordered tblAmain" style={{ height: '100%' }}>
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <table className="table table-hover table-bordered" data-classes-applied="true">
                                                                                <colgroup>
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                    <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                    <col style={{ width: '200px', minWidth: '200px' }} />
                                                                                    <col style={{ width: '500px', minWidth: '500px' }} />
                                                                                </colgroup>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th data-is-only-head="false" data-field="id" style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title" data-is-only-head="false" title="#" data-field="ai" style={{ textAlign: 'center' }}>#
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title answer-legible-text" data-is-only-head="false" title="Answers" data-field="body" style={{ textAlign: 'left' }}>Answers
                                                                                            <div />
                                                                                        </th>
                                                                                        <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                            <div className="nowrap">Answers (%)</div>
                                                                                        </th>
                                                                                        <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                            <div className="nowrap">Count</div>
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title hidden-xs" data-is-only-head="false" data-field="percent" style={{ textAlign: 'left' }}>
                                                                                            <div />
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                            </table>
                                                                        </div>
                                                                        <div className="react-bs-container-body" style={{ height: '100%' }}>
                                                                            <table className="table table-bordered table-hover table-responsive" data-classes-applied="true">
                                                                                <colgroup>
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                    <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                    <col style={{ width: '200px', minWidth: '200px' }} />
                                                                                    <col style={{ width: '500px', minWidth: '500px' }} />
                                                                                </colgroup>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td tabIndex={1} style={{ textAlign: 'left', display: 'none' }}>33669241</td>
                                                                                        <td tabIndex={2} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A1</div>
                                                                                        </td>
                                                                                        <td tabIndex={3} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">1</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={5} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>4.30%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={6} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>43</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={4} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '4.3%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={7} style={{ textAlign: 'left', display: 'none' }}>33669242</td>
                                                                                        <td tabIndex={8} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A2</div>
                                                                                        </td>
                                                                                        <td tabIndex={9} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">2</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={11} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>9.00%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={12} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>90</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={10} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '9%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={13} style={{ textAlign: 'left', display: 'none' }}>33669243</td>
                                                                                        <td tabIndex={14} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A3</div>
                                                                                        </td>
                                                                                        <td tabIndex={15} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">3</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={17} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>28.80%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={18} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>288</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={16} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '28.8%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={19} style={{ textAlign: 'left', display: 'none' }}>33669244</td>
                                                                                        <td tabIndex={20} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A4</div>
                                                                                        </td>
                                                                                        <td tabIndex={21} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container selected-item">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">4</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={23} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span className="selected-item">32.20%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={24} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div className="selected-item">322</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={22} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar selected" style={{ width: '32.2%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={25} style={{ textAlign: 'left', display: 'none' }}>33669245</td>
                                                                                        <td tabIndex={26} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A5</div>
                                                                                        </td>
                                                                                        <td tabIndex={27} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">5</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={29} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>25.70%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={30} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>257</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={28} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '25.7%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="s-alert-wrapper" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel-footer">
                                                            <div className="row">
                                                                <div className="available-charts col-sm-4">
                                                                    <div>
                                                                        <button type="button" class="chart-type chart-type-btn" onClick={() => { setChartCount(3) }}>
                                                                            <i class="bi bi-bar-chart-line-fill"></i>
                                                                        </button>
                                                                        <button type="button" class="chart-type chart-type-btn" onClick={() => { setChartCount(4) }}>
                                                                            <i class="bi bi-pie-chart-fill"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <div className="result-footer">
                                                                        <div className="total-unique-answers-label">Completes</div>
                                                                        <div className="total-unique-answers">1000</div>
                                                                        <div className="clearer" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex justify-content-center'>
                                                                {chartCount == 3 && <ReactApexChart
                                                                    options={chartData2.options}
                                                                    series={chartData2.series}
                                                                    type="bar"
                                                                    height={350}
                                                                />}
                                                                {chartCount == 4 && <ReactApexChart
                                                                    options={chartData3.options}
                                                                    series={chartData3.series}
                                                                    type="pie"
                                                                    width="500"
                                                                />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 6 */}
                                        <div className="container-fluid allQueRport py-3 mt-2 earnSec">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="panel panel-default" style={{ opacity: 1 }}>
                                                        <div className="panel-heading">
                                                            <div>
                                                                <div className="result-title">
                                                                    <div className="question-type result-title__type">Single Selection</div>
                                                                    <div className="clearfix">
                                                                        <div className="result-title__question--overflow-hidden">
                                                                            <div>
                                                                                <h4 className="result-title__question">
                                                                                    <span className="result-title__question-label">Q13.</span>Which of the following athletes is Lewis Hamilton?
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearer" />
                                                            </div>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div>
                                                                <div className="react-bs-table-container">
                                                                    <div className="react-bs-table react-bs-table-bordered tblAmain" style={{ height: '100%' }}>
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <table className="table table-hover table-bordered" data-classes-applied="true">
                                                                                <colgroup>
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                    <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                    <col style={{ width: '200px', minWidth: '200px' }} />
                                                                                    <col style={{ width: '500px', minWidth: '500px' }} />
                                                                                </colgroup>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th data-is-only-head="false" data-field="id" style={{ textAlign: 'left', display: 'none' }}>
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title" data-is-only-head="false" title="#" data-field="ai" style={{ textAlign: 'center' }}>#
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title answer-legible-text" data-is-only-head="false" title="Answers" data-field="body" style={{ textAlign: 'left' }}>Answers
                                                                                            <div />
                                                                                        </th>
                                                                                        <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                            <div className="nowrap">Answers (%)</div>
                                                                                            <div />
                                                                                        </th>
                                                                                        <th data-is-only-head="false" data-field="weightedPercent" style={{ textAlign: 'right' }}>
                                                                                            <div className="nowrap">Count</div>
                                                                                            <div />
                                                                                        </th>
                                                                                        <th className="text-title hidden-xs" data-is-only-head="false" data-field="percent" style={{ textAlign: 'left' }}>
                                                                                            <div />
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                            </table>
                                                                        </div>
                                                                        <div className="react-bs-container-body" style={{ height: '100%' }}>
                                                                            <table className="table table-bordered table-hover table-responsive" data-classes-applied="true">
                                                                                <colgroup>
                                                                                    <col style={{ display: 'none' }} />
                                                                                    <col style={{ width: '45px', minWidth: '45px' }} />
                                                                                    <col style={{ width: '140px', minWidth: '140px' }} />
                                                                                    <col className="hidden-xs" style={{ width: '180px', minWidth: '180px' }} />
                                                                                    <col style={{ width: '200px', minWidth: '200px' }} />
                                                                                    <col style={{ width: '500px', minWidth: '500px' }} />
                                                                                </colgroup>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td tabIndex={1} style={{ textAlign: 'left', display: 'none' }}>33665721</td>
                                                                                        <td tabIndex={2} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A1</div>
                                                                                        </td>
                                                                                        <td tabIndex={3} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__image-wrapper">
                                                                                                    <img src="https://s3.amazonaws.com/pf.survey.image.production/3366572116119493770496e0eeac6-a68f-43e9-99ab-76cddc74e347.jpg" alt="uncaptioned" className="answer__image" />
                                                                                                </div>
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">A</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={5} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>6.00%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={6} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>60</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={4} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '6%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={7} style={{ textAlign: 'left', display: 'none' }}>33665722</td>
                                                                                        <td tabIndex={8} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A2</div>
                                                                                        </td>
                                                                                        <td tabIndex={9} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__image-wrapper">
                                                                                                    <img src="https://s3.amazonaws.com/pf.survey.image.production/33665722161194937721792f3e9de-8a33-4f79-9ad3-fdfe6485e91d.jpg" alt="uncaptioned" className="answer__image" />
                                                                                                </div>
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">B</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={11} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>3.00%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={12} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>30</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={10} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '3%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={13} style={{ textAlign: 'left', display: 'none' }}>33665723</td>
                                                                                        <td tabIndex={14} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A3</div>
                                                                                        </td>
                                                                                        <td tabIndex={15} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__image-wrapper">
                                                                                                    <img src="https://s3.amazonaws.com/pf.survey.image.production/336657231611949377373c8d3428d-5b20-4dd9-a7e3-ac9a055db5a3.jpg" alt="uncaptioned" className="answer__image" />
                                                                                                </div>
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">C</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={17} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>4.60%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={18} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>46</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={16} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '4.6%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={19} style={{ textAlign: 'left', display: 'none' }}>33665724</td>
                                                                                        <td tabIndex={20} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A4</div>
                                                                                        </td>
                                                                                        <td tabIndex={21} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__image-wrapper">
                                                                                                    <img src="https://s3.amazonaws.com/pf.survey.image.production/336657241611949377544adf25b0f-3576-41e1-9ad4-07f5923e6564.jpg" alt="uncaptioned" className="answer__image" />
                                                                                                </div>
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">D</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={23} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>3.50%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={24} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>35</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={22} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '3.5%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={25} style={{ textAlign: 'left', display: 'none' }}>33665725</td>
                                                                                        <td tabIndex={26} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A5</div>
                                                                                        </td>
                                                                                        <td tabIndex={27} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container selected-item">
                                                                                                <div className="answer__image-wrapper">
                                                                                                    <img src="https://s3.amazonaws.com/pf.survey.image.production/33665725161194937774797495286-8a31-43c2-88ec-478dd3d44e5f.jpg" alt="uncaptioned" className="answer__image" />
                                                                                                </div>
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">E</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={29} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span className="selected-item">72.50%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={30} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div className="selected-item">725</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={28} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar selected" style={{ width: '72.5%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td tabIndex={31} style={{ textAlign: 'left', display: 'none' }}>33665726</td>
                                                                                        <td tabIndex={32} className="indicator" style={{ textAlign: 'center' }}>
                                                                                            <div title="Filter results by this answer">A6</div>
                                                                                        </td>
                                                                                        <td tabIndex={33} className="text answer-legible-text" style={{ textAlign: 'left' }}>
                                                                                            <div className="answer__container">
                                                                                                <div className="answer__text">
                                                                                                    <div className="flex">
                                                                                                        <span className="flex-1">None of the above</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={35} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>
                                                                                                    <span>10.40%</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={36} className="percent" style={{ textAlign: 'right' }}>
                                                                                            <div>
                                                                                                <div>104</div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td tabIndex={34} className="percent hidden-xs" style={{ textAlign: 'left' }}>
                                                                                            <div>
                                                                                                <div className="full-bar">
                                                                                                    <div className="bar-container">
                                                                                                        <span className="percentage-bar" style={{ width: '10.4%' }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="s-alert-wrapper" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="panel-footer">
                                                            <div className="row">
                                                                <div className="available-charts col-sm-4">
                                                                    <div>
                                                                        <button type="button" className="chart-type chart-type-btn" onClick={() => { setChartCount(5) }}>
                                                                            <i className="bi bi-bar-chart-line-fill" />
                                                                        </button>
                                                                        <button type="button" className="chart-type chart-type-btn" onClick={() => { setChartCount(6) }}>
                                                                            <i className="bi bi-pie-chart-fill" />
                                                                        </button>
                                                                        {/* <button type="button" className="chart-type chart-type-btn">
                                                                            <i className="bi bi-search" />
                                                                        </button> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-8">
                                                                    <div className="result-footer">
                                                                        <div className="total-unique-answers-label">Completes</div>
                                                                        <div className="total-unique-answers">1000</div>
                                                                        <div className="clearer" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex justify-content-center'>
                                                                {chartCount == 5 && <ReactApexChart
                                                                    options={chartData4.options}
                                                                    series={chartData4.series}
                                                                    type="bar"
                                                                    height={350}
                                                                />}
                                                                {chartCount == 6 && <ReactApexChart
                                                                    options={chartData5.options}
                                                                    series={chartData5.series}
                                                                    type="pie"
                                                                    width="500"
                                                                />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 7 */}
                                        <div className="container-fluid allQueRport py-3 mt-2 earnSec">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="panel panel-default" style={{ opacity: 1 }}>
                                                        <div className="panel-heading">
                                                            <div>
                                                                <div className="result-title">
                                                                    <div className="question-type result-title__type">Free Text</div>
                                                                    <div className="clearfix">
                                                                        <div className="result-title__question--overflow-hidden">
                                                                            <div>
                                                                                <h4 className="result-title__question">
                                                                                    <span className="result-title__question-label">Q14.</span>Word Cloud
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearer" />
                                                            </div>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div>
                                                                <div className="react-bs-table-container">
                                                                    <div className="react-bs-table react-bs-table-bordered tblAmain" style={{ height: '100%' }}>
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <img src="../images/FreeText.png" role="presentation" className='freeText_' alt="Free Text" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 8 */}
                                        <div className="container-fluid allQueRport py-3 mt-2 earnSec">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="panel panel-default" style={{ opacity: 1 }}>
                                                        <div className="panel-heading">
                                                            <div>
                                                                <div className="result-title">
                                                                    <div className="question-type result-title__type">CSAT</div>
                                                                    <div className="clearfix">
                                                                        <div className="result-title__question--overflow-hidden">
                                                                            <div>
                                                                                <h4 className="result-title__question">
                                                                                    <span className="result-title__question-label">Q15.</span>Star Rating, Five Scale Rating, Emoji Rating
                                                                                </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="clearer" />
                                                            </div>
                                                        </div>
                                                        <div className="panel-body">
                                                            <div>
                                                                <div className="react-bs-table-container">
                                                                    <div className="react-bs-table react-bs-table-bordered tblAmain" style={{ height: '100%' }}>
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <div className="d-flex w-100 multiChoiceSec align-items-center my-3">
                                                                                <span>
                                                                                    <i className="fa fa-smile-o" /> CSAT 5 Emoji Rating Question </span>
                                                                            </div>
                                                                            <div class="col-lg-5 h-100" style={{ marginLeft: "25px" }}>
                                                                                <div class="d-flex ratingBoxSec w-100">
                                                                                    <div class="comnRating angryRating">
                                                                                        <img src="../images/angry_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a href="javascript:void(0);">1</a>
                                                                                        </div>
                                                                                        <span>Angry</span>
                                                                                    </div>
                                                                                    <div class="comnRating upsetRating">
                                                                                        <img src="../images/upset_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a href="javascript:void(0);">2</a>
                                                                                        </div>
                                                                                        <span>Upset</span>
                                                                                    </div>
                                                                                    <div class="comnRating naturalRating">
                                                                                        <img src="../images/neutral_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a href="javascript:void(0);">3</a>
                                                                                        </div>
                                                                                        <span>Neutral</span>
                                                                                    </div>
                                                                                    <div class="comnRating happyRating">
                                                                                        <img src="../images/happy_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a href="javascript:void(0);">4</a>
                                                                                        </div>
                                                                                        <span>Happy</span>
                                                                                    </div>
                                                                                    <div class="comnRating excitedRating">
                                                                                        <img src="../images/excited_mini.svg" />
                                                                                        <div class="blockRating">
                                                                                            <a href="javascript:void(0);">5</a>
                                                                                        </div>
                                                                                        <span>Excited</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='d-flex w-100 multiChoiceSec align-items-center my-3'><span><i className='fa fa-star'></i> CSAT 5 Star Rating Question</span></div>
                                                                            <div className="col-lg-5 h-100">
                                                                                <div className="optionsBox mb-5">
                                                                                    <div className="startReview1">
                                                                                        <ul className="d-flex p-0 startReview w-100">
                                                                                            <li>
                                                                                                <a href="javascript:void(0);" className="active">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>1</span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="javascript:void(0);" className="active">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>2</span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="javascript:void(0);">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>3</span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="javascript:void(0);">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>4</span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="javascript:void(0);">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>5</span>
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div className="startReview2">
                                                                                        <ul className="d-flex p-0 startReview w-50">
                                                                                            <li>
                                                                                                <a href="javascript:void(0);" className="active">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>5</span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="javascript:void(0);" className="active">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>4</span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="javascript:void(0);" className="active">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>3</span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="javascript:void(0);">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>2</span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="javascript:void(0);">
                                                                                                    <i className="fa fa-star-o" />
                                                                                                    <i className="fa fa-star" />
                                                                                                    <span>1</span>
                                                                                                </a>
                                                                                            </li>
                                                                                            <li>
                                                                                                <a href="javascript:void(0);" className="tooltip swipIocn" id="swipIcon2">
                                                                                                    <img src="../img/swip-icon.svg" height="28px" />
                                                                                                    <span className="tooltiptext ">Reverse Rating</span>
                                                                                                </a>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="d-flex w-100 multiChoiceSec align-items-center mb-3">
                                                                                <span>
                                                                                    <img src="../images/Csat-yes-no.svg" width="29px" alt="" /> CSAT Yes/No/Maybe Question </span>
                                                                            </div>
                                                                            <div className=" row gap-3 my-5 ps-3 mn-like">
                                                                                <label className="Like-thumb rounded-1 p-0" style={{ width: '125px' }}>
                                                                                    <input type="radio" className="rounded-1 " name="radio" />
                                                                                    <div className="checkmarksen d-grid text-center " style={{ padding: '20px 30px 0px' }}>
                                                                                        <img src="../images/Thumb-yes.svg" alt="" className="m-auto" width="40px" />
                                                                                        <span className="pt-3 pb-2 fs-6">Yes</span>
                                                                                    </div>
                                                                                </label>
                                                                                <label className="Like-thumb p-0" style={{ width: '125px' }}>
                                                                                    <input type="radio" name="radio" />
                                                                                    <div className="checkmarksen d-grid text-center" style={{ padding: '20px 30px 0px' }}>
                                                                                        <img src="../images/Thumb-no.svg" className="m-auto" alt="" width="40px" />
                                                                                        <span className="pt-3 pb-2 fs-6">No</span>
                                                                                    </div>
                                                                                </label>
                                                                                <label className="Like-thumb p-0" style={{ width: '125px' }}>
                                                                                    <input type="radio" name="radio" />
                                                                                    <div className="checkmarksen d-grid text-center" style={{ padding: '20px 30px 0px' }}>
                                                                                        <img src="../images/Maybe.svg" alt="" className="m-auto" width="37px" />
                                                                                        <span className="pt-3 pb-2" style={{ fontSize: '14px' }}>Maybe</span>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Que End */}
                                        {/* <div className='container-fluid py-3 mt-2 earnSec'>
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className='card' style={{ minHeight: "400px" }}>
                                                        <h4 class="text-orange p-2">Bar Chart</h4>
                                                        <div style={{ width: '50%' }}>
                                                            <ReactApexChart
                                                                options={chartData.options}
                                                                series={chartData.series}
                                                                type="bar"
                                                                width="500"
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <div className='card' style={{ minHeight: "400px" }}>
                                                        <h4 class="text-orange p-2">Survey by Country</h4>

                                                        <div className='charts-cls'>
                                                            <Chart
                                                                chartEvents={[
                                                                    {
                                                                        eventName: "select",
                                                                        callback: ({ chartWrapper }) => {
                                                                            const chart = chartWrapper.getChart();
                                                                            const selection = chart.getSelection();
                                                                            if (selection.length === 0) return;
                                                                            const region = newData[selection[0].row + 1];
                                                                          
                                                                        },
                                                                    },
                                                                ]}
                                                                chartType="GeoChart"
                                                                width="100%"
                                                                height="240px"
                                                                data={newData}

                                                                options={{
                                                                    chart: {
                                                                        title: 'Spend Uplift',
                                                                        backgroundColor: 'red'
                                                                    },
                                                                    colors: ['#FB7A21']
                                                                }}
                                                            />
                                                        </div>
                                                        <div class="w-100 p-3"><button class="mainbtn">Report Export</button></div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div> */}
                                        {/* <CSVLink>
                                        <button data={data1} headers={""}
             filename={"surveyWiseUserResponseFullDetails.csv"}>downloadExcel</button> </CSVLink> */}

                                        <div className='container-fluid py-3 mt-2 earnSec'>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <table class="table table-striped responsive-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">First</th>
                                                                <th scope="col">Last</th>
                                                                <th scope="col">Handle</th>
                                                                <th scope="col">Last</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">1</th>
                                                                <td>Mark</td>
                                                                <td>Otto</td>
                                                                <td>@mdo</td>
                                                                <td>@mdo</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">2</th>
                                                                <td>Jacob</td>
                                                                <td>Thornton</td>
                                                                <td>@fat</td>
                                                                <td>@mdo</td>

                                                            </tr>
                                                            <tr>
                                                                <th scope="row">3</th>
                                                                <td colspan="2">Larry the Bird</td>
                                                                <td>@twitter</td>
                                                                <td>@mdo</td>


                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </></div>
                        </div>
                    </div>
                </section>
                {/* ---------------------------------Change file modal -----------------------------------*/}
                <div className="modal fade" id="filedrop" tabIndex={-1} aria-labelledby="filedropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered survey-title-modal">
                        <div className="modal-content">
                            <div className="modal-header media_gallery_model_header px-3 align-items-center">
                                <h5 className="modal-title" id="exampleModalLabel">Media Gallery</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body text-center model-image-newsurvey">
                                <ul className="nav nav-tabs justify-content-around" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="upload-tab" data-bs-toggle="tab" data-bs-target="#upload-tab-pane" type="button" role="tab" aria-controls="upload-tab-pane" aria-selected="true">Upload</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="image-tab" data-bs-toggle="tab" data-bs-target="#image-tab-pane" type="button" role="tab" aria-controls="image-tab-pane" aria-selected="false">Image</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="video-tab" data-bs-toggle="tab" data-bs-target="#video-tab-pane" type="button" role="tab" aria-controls="video-tab-pane" aria-selected="false">Video</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="icon-tab" data-bs-toggle="tab" data-bs-target="#icon-tab-pane" type="button" role="tab" aria-controls="icon-tab-pane" aria-selected="false">Icon</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="mygallery-tab" data-bs-toggle="tab" data-bs-target="#mygallery-tab-pane" type="button" role="tab" aria-controls="mygallery-tab-pane" aria-selected="false">My Gallery</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    {/* Upload button */}
                                    <div className="tab-pane fade show active tab-pane-content file-choose-drop position-relative" id="upload-tab-pane" role="tabpanel" aria-labelledby="upload-tab" tabIndex={0}>

                                        <Dragger >
                                            <div className='sur-bg-img bg-grey h-100 mx-3 d-flex flex-column justify-content-center align-items-center  '>
                                                <lottie-player src="../images/c699de4f-5e56-44ea-a5be-50a7d3bb823b.json" background="transparent" speed={1} style={{ width: '200px', height: '200px' }} loop autoPlay />
                                                <p>Upload or drop an image right here</p>
                                                <small>Gif ( up to 4 MB), PNG or JPG (up to 10 MB)</small>
                                            </div>
                                        </Dragger>
                                    </div>
                                    {/* Upload button */}
                                    {/* Image button */}
                                    <div className="tab-pane fade tab-pane-content file-choose-drop" id="image-tab-pane" role="tabpanel" aria-labelledby="image-tab" tabIndex={0}>
                                        <div className="row justify-content-center m-2">


                                            <div className="form-group has-search main mt-2position-relative">
                                                <span className="form-control-feedback"><i className="bi bi-search"></i></span>
                                                <input type="text" className="form-control br-2" placeholder="Search free high-resolution photos" />
                                            </div>
                                            <div className="col-6  img-width-select-model"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-6 "><img src='../images/Mask Group 11.png'></img></div>
                                        </div>
                                    </div>

                                    {/* Image button */}
                                    {/* Video button */}
                                    <div className="tab-pane fade tab-pane-content file-choose-drop" id="video-tab-pane" role="tabpanel" aria-labelledby="video-tab" tabIndex={0}>
                                        <div className="row m-1">
                                            <div className="text-start">
                                                <p className='fw-bold pt-2'>Use videos from YouTube</p>
                                            </div>
                                            {/* <div className="input-group m-3">
        <input type="text" className="form-control" placeholder="Change layout to add a YouTube video" aria-label="Recipient's username" aria-describedby="basic-addon2">
        <span className="input-group-text p-0" id="basic-addon2"><button className="border-0">Add Lnik</button></span>
      </div> */}
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control br-2" placeholder="Change layout to add a YouTube video" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <button className="input-group-text addlinkbtn" id="basic-addon2">Add Link</button>
                                            </div>
                                            <div className="col-6  img-width-select-model">
                                                <iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                            </div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="w-100 d-none d-md-block" />
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="w-100 d-none d-md-block" />
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                            <div className="col-6 "><iframe width="100%" height="100%" src="https://www.youtube.com/embed/kJb6BZwqCGM" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
                                        </div>
                                    </div>
                                    {/* Video button */}
                                    {/* Icon button */}
                                    <div className="tab-pane fade tab-pane-content file-choose-drop" id="icon-tab-pane" role="tabpanel" aria-labelledby="icon-tab" tabIndex={0}>
                                        <div className="row justify-content-center tab-icon-drop  m-1">

                                            <div className="form-group has-search main mt-3 position-relative">
                                                <span className="form-control-feedback"><i className="bi bi-search"></i></span>
                                                <input type="text" className="form-control br-2" placeholder="Search free Icons" />
                                            </div>

                                            <div className="row tab-icon-drop">
                                                <div className="col-3 "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="w-100 d-none d-md-block" />
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="w-100 d-none d-md-block" />
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>
                                                <div className="col-3  "><img className='fa-icon-bg' src='../images/Icon awesome-image.svg '></img></div>

                                            </div>
                                            <div className="text-right p-3">
                                                <a className="mainbtn text-white">Add</a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Icon button */}
                                    {/* My Gallery button */}
                                    <div className="tab-pane fade tab-pane-content file-choose-drop" id="mygallery-tab-pane" role="tabpanel" aria-labelledby="mygallery-tab" tabIndex={0}>
                                        <div className="row justify-content-center pt-3 m-0">
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            {/* <div className="w-100 d-none d-md-block"></div> */}
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                            <div className="col-4"><img src='../images/Mask Group 11.png'></img></div>
                                        </div>
                                    </div>
                                    {/* My Gallery button */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* <ToastContainer /> */}


        </>
    )
}

export default Scratchsurvey
