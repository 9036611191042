import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Config from '../../config/env';
import API from '../../utils/apiCalling';
import Loader from '../../utils/loader';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const Datatable = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [surveyId, setSurveyId] = useState(null);
  const checkarray = ["Which is the lender of your most recent active loan?",
    "Was {var} purchased new or second hand?",
    "Which of these types of business do you fall under?",
    "Which city do you currently reside in?", "POST CODE BELOW"]
  const id = useParams();

  let api = new API();

  useEffect(() => {
    getSurveyById(id)
    getDashBoard(id)
  }, []);

  const getSurveyById = async (id) => {
    let data = { id: id.id };
    let result = await api.post(`${Config.new.site_api}/client-survey/getSurvey`, data);

    if (result && result.code === 200) {
      const questionsWithState = result.data.questions.map(q => ({ ...q, isOpen: false }));
      setAllQuestions(questionsWithState);
    }
  };

  const getDashBoard = async (surveyId) => {
    setLoader(true)
    let data = { surveyId: surveyId.id };
    let surveyData = await api.post(`${Config.new.site_api}/client-survey/Dashboard`, data);

    if (surveyData?.code === 200) {
      setDashboardData(surveyData.data);
      setLoader(false)
    }
    else {
      setLoader(false)
    }
  };
  // Helper function to check if the question is in the checkarray
  const isQuestionInCheckArray = (question) => {
    return checkarray.includes(question);
  };
  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 45;
    worksheet.getColumn(4).width = 10;
    worksheet.getColumn(5).width = 10;
    worksheet.getColumn(6).width = 10;
    worksheet.getColumn(7).width = 10;
    worksheet.getColumn(8).width = 10;
    worksheet.getColumn(9).width = 10;
    worksheet.getColumn(10).width = 10;
    worksheet.getColumn(11).width = 10;
    worksheet.getColumn(12).width = 10;
    worksheet.getColumn(13).width = 10;
    worksheet.getColumn(14).width = 10;
    worksheet.getColumn(15).width = 10;

    // Merge cells for headers
    worksheet.mergeCells('A3:A3');
    worksheet.mergeCells('A4:A4');
    worksheet.mergeCells('A5:A5');
    worksheet.mergeCells('A6:A6');
    worksheet.mergeCells('A7:A7');
    worksheet.mergeCells('A8:A8');
    worksheet.mergeCells('A9:A9');
    worksheet.mergeCells('A10:A10');
    worksheet.mergeCells('A11:A11');
    worksheet.mergeCells('A12:A12');
    worksheet.mergeCells('A13:A13');
    worksheet.mergeCells('A14:A14');
    worksheet.mergeCells('A15:A15');
    worksheet.mergeCells('A16:A16');
    worksheet.mergeCells('A17:A17');
    worksheet.mergeCells('A18:A18');
    worksheet.mergeCells('A19:A19');
    worksheet.mergeCells('A20:A20');
    worksheet.mergeCells('A21:A21');
    worksheet.mergeCells('A22:A22');
    worksheet.mergeCells('A23:A23');
    worksheet.mergeCells('A24:A24');
    worksheet.mergeCells('A25:A25');

    worksheet.mergeCells('B3:B3');
    worksheet.mergeCells('B4:B5');
    worksheet.mergeCells('B6:B7');
    worksheet.mergeCells('B8:B10');
    worksheet.mergeCells('B11:B12');
    worksheet.mergeCells('B13:B14');
    worksheet.mergeCells('B15:B15');
    worksheet.mergeCells('B16:B16');
    worksheet.mergeCells('B17:B18');
    worksheet.mergeCells('B19:B19');
    worksheet.mergeCells('B20:B23');
    worksheet.mergeCells('B25:B25');


    worksheet.mergeCells('C3:C3'); // Merge for alignment with Category
    worksheet.mergeCells('C4:C4');
    worksheet.mergeCells('C5:C5');
    worksheet.mergeCells('C6:C6');
    worksheet.mergeCells('C7:C7');
    worksheet.mergeCells('C8:C8');
    worksheet.mergeCells('C9:C9');
    worksheet.mergeCells('C10:C10');
    worksheet.mergeCells('C11:C11');
    worksheet.mergeCells('C12:C12');
    worksheet.mergeCells('C13:C13');
    worksheet.mergeCells('C14:C14');
    worksheet.mergeCells('C15:C15');
    worksheet.mergeCells('C16:C16');
    worksheet.mergeCells('C17:C17');
    worksheet.mergeCells('C18:C18');
    worksheet.mergeCells('C19:C19');
    worksheet.mergeCells('C20:C20');
    worksheet.mergeCells('C21:C21');
    worksheet.mergeCells('C22:C22');
    worksheet.mergeCells('C23:C23');

    // Merge cells for new headers
    worksheet.mergeCells('D2:F2');
    worksheet.mergeCells('G2:I2');
    worksheet.mergeCells('J2:L2');
    worksheet.mergeCells('M2:O2');
    worksheet.mergeCells('P2:R2');
    worksheet.mergeCells('S2:U2');
    worksheet.mergeCells('V2:X2');
    worksheet.mergeCells('Y2:AA2');
    worksheet.mergeCells('AB2:AD2');
    worksheet.mergeCells('AE2:AG2');
    worksheet.mergeCells('AH2:AJ2');
    worksheet.mergeCells('AK2:AM2');
    worksheet.mergeCells('AN2:AP2');
    worksheet.mergeCells('AQ2:AS2');
    worksheet.mergeCells('AT2:AV2');
    worksheet.mergeCells('AW2:AY2');
    worksheet.mergeCells('AZ2:BB2');


    // Define styles
    const headerStyle = {
      font: { bold: true, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'dededd' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };

    const plannedstyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'bdd3f9' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const cellStyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const plannedcellstyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'dfebf7' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const achievedstyle = {
      font: { color: { argb: '00000000' }, size: 12, },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '92c67c' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const achievedcellstyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ddf9d6' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const pendingtyle = {
      font: { color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'fe9c9c' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const pendingcellstyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffe6e6' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };


    // Define cell values and styles for column A
    const cellsToStyleA = [
      { cell: 'A3', value: 'S.No.' },
      { cell: 'A4', value: '1' },
      { cell: 'A5', value: '2' },
      { cell: 'A6', value: '3' },
      { cell: 'A7', value: '4' },
      { cell: 'A8', value: '5' },
      { cell: 'A9', value: '6' },
      { cell: 'A10', value: '7' },
      { cell: 'A11', value: '8' },
      { cell: 'A12', value: '9' },
      { cell: 'A13', value: '10' },
      { cell: 'A14', value: '11' },
      { cell: 'A15', value: '12' },
      { cell: 'A16', value: '13' },
      { cell: 'A17', value: '14' },
      { cell: 'A18', value: '15' },
      { cell: 'A19', value: '16' },
      { cell: 'A20', value: '17' },
      { cell: 'A21', value: '18' },
      { cell: 'A22', value: '19' },
      { cell: 'A23', value: '20' },
      { cell: 'A25', value: ' ' },

    ];

    cellsToStyleA.forEach(({ cell, value }) => {
      const excelCell = worksheet.getCell(cell);
      excelCell.value = value;
      if (value == "S.No.") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }
      else {
        // excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
        excelCell.fill = cellStyle.fill;
      }
    });

    // Define cell values and styles for column B
    const cellsToStyleB = [
      { cell: 'B3', value: 'Category' },
      { cell: 'B4', value: 'Captive Finance' },
      { cell: 'B6', value: 'NBFC' },
      { cell: 'B8', value: 'Bank' },
      { cell: 'B11', value: 'Seller' },
      { cell: 'B13', value: 'Aggregator' },
      { cell: 'B17', value: 'Vehicle Category' },
      { cell: 'B20', value: 'User Category' },
      { cell: 'B25', value: ' ' },
    ];

    cellsToStyleB.forEach(({ cell, value }) => {


      const excelCell = worksheet.getCell(cell);
      excelCell.value = value;
      if (value == "Category") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }
      else {
        // excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
        excelCell.fill = cellStyle.fill;
      }
    });

    // Define cell values and styles for column C
    const cellsToStyleC = [
      { cell: 'C3', value: 'Organisation' },
      { cell: 'C4', value: 'Mahindra Finance' },
      { cell: 'C5', value: 'Tata Motors Finance' },
      { cell: 'C6', value: 'Cholamandalam' },
      { cell: 'C7', value: 'HDB' },
      { cell: 'C8', value: 'IDFC First Bank' },
      { cell: 'C9', value: 'IndusInd Bank' },
      { cell: 'C10', value: 'AU Small Finance Bank' },
      { cell: 'C11', value: 'Tata OK' },
      { cell: 'C12', value: 'Shriram Automall' },
      { cell: 'C13', value: 'Zinka' },
      { cell: 'C14', value: 'Other' },
      { cell: 'C15', value: 'Total' },
      { cell: 'C17', value: 'New Vehicle (Minimum 40%)' },
      { cell: 'C18', value: 'Used Vehicle (Minimum 30%)' },
      { cell: 'C20', value: 'DCO (Driver cum Owner) (Minimum 20%)' },
      { cell: 'C21', value: 'Retail Transporter (Minimum 20%)' },
      { cell: 'C22', value: 'Captive / Own business (Minimum 20%)' },
      { cell: 'C23', value: 'Commission agent (Minimum 10%)' },
      { cell: 'C25', value: 'Multiple Vehicle owner (Minimum 40%) (More than 1 Vehicle)' },

    ];

    let data = ['Organized', 'Unorganized', 'Below 6 lakh', '6-10 lakh', 'More than 10 lakh', 'Retired (60+)', 'NCCS A1,A2,A3(50%)', 'Minimum 50%']

    cellsToStyleC.forEach(({ cell, value }) => {
      const excelCell = worksheet.getCell(cell);
      excelCell.value = value;
      if (value == "Organisation") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      } else if (value == "Total") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }
      else {
        // excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
        excelCell.fill = cellStyle.fill;
      }


    });

    // Define cell values and styles for new headers
    const cellsToStyleNew = [
      { cell: 'D2', value: 'Total' },
      { cell: 'G2', value: 'Delhi' },
      { cell: 'J2', value: 'NCR' },
      { cell: 'M2', value: 'Mumbai' },
      { cell: 'P2', value: 'Chennai' },
      { cell: 'S2', value: 'Kolkata' },
      { cell: 'V2', value: 'Pune' },
      { cell: 'Y2', value: 'Hyderabad' },
      { cell: 'AB2', value: 'Coimbatore' },
      { cell: 'AE2', value: 'Ahmedabad' },
      { cell: 'AH2', value: 'Indore' },
      { cell: 'AK2', value: 'Nasik' },
      { cell: 'AN2', value: 'Cochin' },
      { cell: 'AQ2', value: 'Mysuru' },
      { cell: 'AT2', value: 'Lucknow' },
      { cell: 'AW2', value: 'Rachi' },
      { cell: 'AZ2', value: 'Surat' },
      { cell: 'D3', value: 'Planned' },
      { cell: 'E3', value: 'Achieved' },
      { cell: 'F3', value: 'Pending' },
      { cell: 'G3', value: 'Planned' },
      { cell: 'H3', value: 'Achieved' },
      { cell: 'I3', value: 'Pending' },
      { cell: 'J3', value: 'Planned' },
      { cell: 'K3', value: 'Achieved' },
      { cell: 'L3', value: 'Pending' },
      { cell: 'M3', value: 'Planned' },
      { cell: 'N3', value: 'Achieved' },
      { cell: 'O3', value: 'Pending' },
      { cell: 'P3', value: 'Planned' },
      { cell: 'Q3', value: 'Achieved' },
      { cell: 'R3', value: 'Pending' },
      { cell: 'S3', value: 'Planned' },
      { cell: 'T3', value: 'Achieved' },
      { cell: 'U3', value: 'Pending' },
      { cell: 'V3', value: 'Planned' },
      { cell: 'W3', value: 'Achieved' },
      { cell: 'X3', value: 'Pending' },
      { cell: 'Y3', value: 'Planned' },
      { cell: 'Z3', value: 'Achieved' },
      { cell: 'AA3', value: 'Pending' },
      { cell: 'AB3', value: 'Planned' },
      { cell: 'AC3', value: 'Achieved' },
      { cell: 'AD3', value: 'Pending' },
      { cell: 'AE3', value: 'Planned' },
      { cell: 'AF3', value: 'Achieved' },
      { cell: 'AG3', value: 'Pending' },
      { cell: 'AH3', value: 'Planned' },
      { cell: 'AI3', value: 'Achieved' },
      { cell: 'AJ3', value: 'Pending' },
      { cell: 'AK3', value: 'Planned' },
      { cell: 'AL3', value: 'Achieved' },
      { cell: 'AM3', value: 'Pending' },
      { cell: 'AN3', value: 'Planned' },
      { cell: 'AO3', value: 'Achieved' },
      { cell: 'AP3', value: 'Pending' },
      { cell: 'AQ3', value: 'Planned' },
      { cell: 'AR3', value: 'Achieved' },
      { cell: 'AS3', value: 'Pending' },
      { cell: 'AT3', value: 'Planned' },
      { cell: 'AU3', value: 'Achieved' },
      { cell: 'AV3', value: 'Pending' },
      { cell: 'AW3', value: 'Planned' },
      { cell: 'AX3', value: 'Achieved' },
      { cell: 'AY3', value: 'Pending' },
      { cell: 'AZ3', value: 'Planned' },
      { cell: 'BA3', value: 'Achieved' },
      { cell: 'BB3', value: 'Pending' },
    ];
    let data2 = ['Total', 'Delhi', 'NCR', 'Mumbai', 'Chennai', 'Kolkata', 'Pune', 'Hyderabad', 'Coimbatore', 'Ahmedabad', 'Indore', 'Nasik', 'Cochin', 'Mysuru', 'Lucknow', 'Rachi', 'Surat']
    cellsToStyleNew.forEach(({ cell, value }) => {
      const excelCell = worksheet.getCell(cell);
      excelCell.value = value;
      if (data2.includes(value)) {
        excelCell.font = headerStyle.font;
        excelCell.fill = cellStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      } else if (value == 'Planned') {
        excelCell.font = headerStyle.font;
        excelCell.fill = plannedstyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      } else if (value == 'Achieved') {
        excelCell.font = headerStyle.font;
        excelCell.fill = achievedstyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      } else if (value == 'Pending') {
        excelCell.font = headerStyle.font;
        excelCell.fill = pendingtyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }
      else {
        // excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
        excelCell.fill = headerStyle.fill;
        excelCell.font = headerStyle.font;
      }

    });
    let columnDValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnDValues = [68, 68, 68, 68, 136, 68, 68, 68, 68, 68, 0, 748, 299, 224, 150, 150, 150, 75]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {
      columnDValues = [68, 68, 68, 68, 136, 68, 68, 68, 68, 68, 0, 748, 299, 224, 150, 150, 150, 75, 299]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnDValues = [68, 68, 68, 68, 136, 68, 68, 68, 68, 68, 0, 748, 299, 224, 150, 150, 150, 75]
    }
    // Fill values in column D only if the corresponding cell in column C is not empty
    let valueIndex = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`D${rowIndex}`);
        cellD.value = columnDValues[valueIndex++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;
          cellD.fill = plannedcellstyle.fill;

        }

       
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }

    }


    // Define the values to fill in column G


    let columnGValues = []
    // Define the values to fill in column H
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnGValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {
      columnGValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3, 13]

    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnGValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }
    // Fill values in column G only if the corresponding cell in column C is not empty
    let valueIndexG = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`G${rowIndex}`);
        cellD.value = columnGValues[valueIndexG++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {

          cellD.font = cellStyle.font;


          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    // Define the values to fill in column K


    let columnJValues = []
    // Define the values to fill in column K
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnJValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {
      columnJValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3, 13]

    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnJValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]

    }
    // Fill values in column G only if the corresponding cell in column C is not empty
    let valueIndexJ = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`J${rowIndex}`);
        cellD.value = columnJValues[valueIndexJ++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }


    // Define the values to fill in column N

    let columnMValues = []
    // Define the values to fill in column N
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnMValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {
      columnMValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7, 26]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnMValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7]
    }

    // Fill values in column M only if the corresponding cell in column C is not empty
    let valueIndexM = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`M${rowIndex}`);
        cellD.value = columnMValues[valueIndexM++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }
    // Define the values to fill in column Q
    let columnPValues = []
    // Define the values to fill in column Q
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnPValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {
      columnPValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7, 26]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnPValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7]
    }

    // Fill values in column P only if the corresponding cell in column C is not empty
    let valueIndexP = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`P${rowIndex}`);
        cellD.value = columnPValues[valueIndexP++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    // Define the values to fill in column T


    let columnSValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnSValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {
      columnSValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7, 26]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnSValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7]
    }

    // Fill values in column S only if the corresponding cell in column C is not empty
    let valueIndexS = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`S${rowIndex}`);
        cellD.value = columnSValues[valueIndexS++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    // Define the values to fill in column W


    let columnValues = []

    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnValues = [5, 5, 5, 5, 10, 5, 5, 5, 5, 5, 0, 55, 22, 17, 11, 11, 11, 6]

    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnValues = [5, 5, 5, 5, 10, 5, 5, 5, 5, 5, 0, 55, 22, 17, 11, 11, 11, 6, 22]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnValues = [5, 5, 5, 5, 10, 5, 5, 5, 5, 5, 0, 55, 22, 17, 11, 11, 11, 6]
    }


    let valueIndexV = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`V${rowIndex}`);
        cellD.value = columnValues[valueIndexV++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    // Define the values to fill in column Z


    let columnYValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnYValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnYValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7, 26]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnYValues = [6, 6, 6, 6, 12, 6, 6, 6, 6, 6, 0, 66, 26, 20, 13, 13, 13, 7]
    }

    // Fill values in column Y only if the corresponding cell in column C is not empty
    let valueIndexY = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`Y${rowIndex}`);
        cellD.value = columnYValues[valueIndexY++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    // Define the values to fill in column AC



    let columnABValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnABValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4]

    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {
      // indore
      columnABValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4, 18]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnABValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4]
    }

    // Fill values in column AB only if the corresponding cell in column C is not empty
    let valueIndexAB = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`AB${rowIndex}`);
        cellD.value = columnABValues[valueIndexAB++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    // Define the values to fill in column AF


    let columnAEValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnAEValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnAEValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4, 18]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnAEValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4]
    }

    // Fill values in column AE only if the corresponding cell in column C is not empty
    let valueIndexAE = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`AE${rowIndex}`);
        cellD.value = columnAEValues[valueIndexAE++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    // Define the values to fill in column AI

    let columnAHValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnAHValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnAHValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4, 18]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnAHValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4]
    }

    // Fill values in column AH only if the corresponding cell in column C is not empty
    let valueIndexAH = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`AH${rowIndex}`);
        cellD.value = columnAHValues[valueIndexAH++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    // Define the values to fill in column AL

    let columnAKValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnAKValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnAKValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3, 13]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnAKValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }

    // Fill values in column AK only if the corresponding cell in column C is not empty
    let valueIndexAK = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`AK${rowIndex}`);
        cellD.value = columnAKValues[valueIndexAK++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    // Define the values to fill in column AL

    let columnANValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnANValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnANValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3, 13]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnANValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }

    // Fill values in column AK only if the corresponding cell in column C is not empty
    let valueIndexAN = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`AN${rowIndex}`);
        cellD.value = columnANValues[valueIndexAN++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }
    // Define the values to fill in column AL

    let columnAQValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnAQValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnAQValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3, 13]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnAQValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }

    // Fill values in column AK only if the corresponding cell in column C is not empty
    let valueIndexAQ = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`AQ${rowIndex}`);
        cellD.value = columnAQValues[valueIndexAQ++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }
    // Define the values to fill in column AL

    let columnATValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnATValues = [5, 5, 5, 5, 10, 5, 5, 5, 5, 5, 0, 55, 22, 17, 11, 11, 11, 6]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnATValues = [5, 5, 5, 5, 10, 5, 5, 5, 5, 5, 0, 55, 22, 17, 11, 11, 11, 6, 22]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnATValues = [5, 5, 5, 5, 10, 5, 5, 5, 5, 5, 0, 55, 22, 17, 11, 11, 11, 6]
    }

    // Fill values in column AK only if the corresponding cell in column C is not empty
    let valueIndexAT = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`AT${rowIndex}`);
        cellD.value = columnATValues[valueIndexAT++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }
    // Define the values to fill in column AL

    let columnAWValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnAWValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnAWValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3, 13]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnAWValues = [3, 3, 3, 3, 6, 3, 3, 3, 3, 3, 0, 33, 13, 10, 7, 7, 7, 3]
    }

    // Fill values in column AK only if the corresponding cell in column C is not empty
    let valueIndexAW = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`AW${rowIndex}`);
        cellD.value = columnAWValues[valueIndexAW++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }
    // Define the values to fill in column AL

    let columnAZValues = []
    // Define the values to fill in column D
    if (id?.id == "667fb4695fe2ebbc73be4293") {
      columnAZValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4]
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {

      columnAZValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4, 18]
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      columnAZValues = [4, 4, 4, 4, 8, 4, 4, 4, 4, 4, 0, 44, 18, 13, 9, 9, 9, 4]
    }

    // Fill values in column AK only if the corresponding cell in column C is not empty
    let valueIndexAZ = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const cellD = worksheet.getCell(`AZ${rowIndex}`);
        cellD.value = columnAZValues[valueIndexAZ++];
        if (rowIndex == 15) {
          cellD.font = headerStyle.font
          cellD.fill = plannedstyle.fill;
        }
        else {
          cellD.font = cellStyle.font;

          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }
    // ---------Delhi-------------       
    // Define criteria for "Achieved" columns
    const criteriaMap = {
      // 3: ['Delhi', 'Organized sector - you or your firm/ company is registered', 'Self-Employed Professional/Businessman/Freelancer'],
      4: ['Delhi', 'Mahindra Finance'],
      5: ['Delhi', 'Tata Motors Finance'],
      6: ['Delhi', 'Cholamandalam'],
      7: ['Delhi', 'HDB'],
      8: ['Delhi', 'IDFC First Bank'],
      9: ['Delhi', 'IndusInd Bank'],
      10: ['Delhi', 'AU Small Finance Bank'],
      11: ['Delhi', 'Tata OK'],
      12: ['Delhi', 'Shriram Automall'],
      13: ['Delhi', 'Zinka'],
      14: ['Delhi',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Delhi', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Delhi', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Delhi', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Delhi', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Delhi', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Delhi', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Delhi', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
   };

    // Helper function to check if a criterion is met
    const isCriterionMet = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatches = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMet(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMet(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMap).forEach(rowIndex => {
      const criteria = criteriaMap[rowIndex];
      const count = countMatches(criteria);
      const cellH = worksheet.getCell(`H${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });

    // --------jaipur-------
    // Define criteria for "Achieved" columns
    const criteriaMapNCR = {
      4: ['NCR', 'Mahindra Finance'],
      5: ['NCR', 'Tata Motors Finance'],
      6: ['NCR', 'Cholamandalam'],
      7: ['NCR', 'HDB'],
      8: ['NCR', 'IDFC First Bank'],
      9: ['NCR', 'IndusInd Bank'],
      10: ['NCR', 'AU Small Finance Bank'],
      11: ['NCR', 'Tata OK'],
      12: ['NCR', 'Shriram Automall'],
      13: ['NCR', 'Zinka'],
      14: ['NCR',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['NCR', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['NCR', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['NCR', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['NCR', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['NCR', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['NCR', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['NCR', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
  };

    // Helper function to check if a criterion is met
    const isCriterionMetNCR = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesNCR = (criteria) => {
      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetNCR(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetNCR(q.answer, criterion))
        )
      ).length;

    };

    Object.keys(criteriaMapNCR).forEach(rowIndex => {
      const criteria = criteriaMapNCR[rowIndex];
      const count = countMatchesNCR(criteria);
      const cellH = worksheet.getCell(`K${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });
    // ------------------------Chandigarh---------------
    // Define criteria for "Achieved" columns
    const criteriaMapMumbai = {
      4: ['Mumbai', 'Mahindra Finance'],
      5: ['Mumbai', 'Tata Motors Finance'],
      6: ['Mumbai', 'Cholamandalam'],
      7: ['Mumbai', 'HDB'],
      8: ['Mumbai', 'IDFC First Bank'],
      9: ['Mumbai', 'IndusInd Bank'],
      10: ['Mumbai', 'AU Small Finance Bank'],
      11: ['Mumbai', 'Tata OK'],
      12: ['Mumbai', 'Shriram Automall'],
      13: ['Mumbai', 'Zinka'],
      14: ['Mumbai',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Mumbai', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Mumbai', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Mumbai', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Mumbai', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Mumbai', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Mumbai', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Mumbai', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
  };

    // Helper function to check if a criterion is met
    const isCriterionMetMumbai = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesMumbai = (criteria) => {
      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetMumbai(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetMumbai(q.answer, criterion))
        )
      ).length;

    };

    Object.keys(criteriaMapMumbai).forEach(rowIndex => {
      const criteria = criteriaMapMumbai[rowIndex];
      const count = countMatchesMumbai(criteria);
      const cellH = worksheet.getCell(`N${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });

    // ------------------------Kolkata---------------
    // Define criteria for "Achieved" columns
    const criteriaMapChennai = {
      4: ['Chennai', 'Mahindra Finance'],
      5: ['Chennai', 'Tata Motors Finance'],
      6: ['Chennai', 'Cholamandalam'],
      7: ['Chennai', 'HDB'],
      8: ['Chennai', 'IDFC First Bank'],
      9: ['Chennai', 'IndusInd Bank'],
      10: ['Chennai', 'AU Small Finance Bank'],
      11: ['Chennai', 'Tata OK'],
      12: ['Chennai', 'Shriram Automall'],
      13: ['Chennai', 'Zinka'],
      14: ['Chennai',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Chennai', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Chennai', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Chennai', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Chennai', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Chennai', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Chennai', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Chennai', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
   };

    // Helper function to check if a criterion is met
    const isCriterionMetChennai = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesChennai = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetChennai(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetChennai(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapChennai).forEach(rowIndex => {
      const criteria = criteriaMapChennai[rowIndex];
      const count = countMatchesChennai(criteria);
      const cellH = worksheet.getCell(`Q${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });
    // ------------------------Patna---------------
    // Define criteria for "Achieved" columns
    const criteriaMapKolkata = {
      4: ['Kolkata', 'Mahindra Finance'],
      5: ['Kolkata', 'Tata Motors Finance'],
      6: ['Kolkata', 'Cholamandalam'],
      7: ['Kolkata', 'HDB'],
      8: ['Kolkata', 'IDFC First Bank'],
      9: ['Kolkata', 'IndusInd Bank'],
      10: ['Kolkata', 'AU Small Finance Bank'],
      11: ['Kolkata', 'Tata OK'],
      12: ['Kolkata', 'Shriram Automall'],
      13: ['Kolkata', 'Zinka'],
      14: ['Kolkata',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Kolkata', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Kolkata', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Kolkata', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Kolkata', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Kolkata', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Kolkata', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Kolkata', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
   };

    // Helper function to check if a criterion is met
    const isCriterionMetKolkata = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesKolkata = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetKolkata(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetKolkata(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapKolkata).forEach(rowIndex => {
      const criteria = criteriaMapKolkata[rowIndex];
      const count = countMatchesKolkata(criteria);
      const cellH = worksheet.getCell(`T${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });


    // ------------------------Mumbai---------------
    // Define criteria for "Achieved" columns
    const criteriaMapPune = {
      4: ['Pune', 'Mahindra Finance'],
      5: ['Pune', 'Tata Motors Finance'],
      6: ['Pune', 'Cholamandalam'],
      7: ['Pune', 'HDB'],
      8: ['Pune', 'IDFC First Bank'],
      9: ['Pune', 'IndusInd Bank'],
      10: ['Pune', 'AU Small Finance Bank'],
      11: ['Pune', 'Tata OK'],
      12: ['Pune', 'Shriram Automall'],
      13: ['Pune', 'Zinka'],
      14: ['Pune',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Pune', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Pune', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Pune', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Pune', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Pune', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Pune', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Pune', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
  };

    // Helper function to check if a criterion is met
    const isCriterionMetPune = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesPune = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetPune(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetPune(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapPune).forEach(rowIndex => {
      const criteria = criteriaMapPune[rowIndex];
      const count = countMatchesPune(criteria);
      const cellH = worksheet.getCell(`W${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });

    // ------------------------Ahmedabad---------------
    // Define criteria for "Achieved" columns
    const criteriaMapHyderabad = {
      4: ['Hyderabad', 'Mahindra Finance'],
      5: ['Hyderabad', 'Tata Motors Finance'],
      6: ['Hyderabad', 'Cholamandalam'],
      7: ['Hyderabad', 'HDB'],
      8: ['Hyderabad', 'IDFC First Bank'],
      9: ['Hyderabad', 'IndusInd Bank'],
      10: ['Hyderabad', 'AU Small Finance Bank'],
      11: ['Hyderabad', 'Tata OK'],
      12: ['Hyderabad', 'Shriram Automall'],
      13: ['Hyderabad', 'Zinka'],
      14: ['Hyderabad',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Hyderabad', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Hyderabad', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Hyderabad', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Hyderabad', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Hyderabad', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Hyderabad', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Hyderabad', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
   };

    // Helper function to check if a criterion is met
    const isCriterionMetHyderabad = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesHyderabad = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetHyderabad(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetHyderabad(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapHyderabad).forEach(rowIndex => {
      const criteria = criteriaMapHyderabad[rowIndex];

      const count = countMatchesHyderabad(criteria);

      const cellH = worksheet.getCell(`Z${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });

    // ------------------------Indore---------------
    // Define criteria for "Achieved" columns
    const criteriaMapCoimbatore = {
      4: ['Coimbatore', 'Mahindra Finance'],
      5: ['Coimbatore', 'Tata Motors Finance'],
      6: ['Coimbatore', 'Cholamandalam'],
      7: ['Coimbatore', 'HDB'],
      8: ['Coimbatore', 'IDFC First Bank'],
      9: ['Coimbatore', 'IndusInd Bank'],
      10: ['Coimbatore', 'AU Small Finance Bank'],
      11: ['Coimbatore', 'Tata OK'],
      12: ['Coimbatore', 'Shriram Automall'],
      13: ['Coimbatore', 'Zinka'],
      14: ['Coimbatore',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Coimbatore', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Coimbatore', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Coimbatore', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Coimbatore', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Coimbatore', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Coimbatore', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Coimbatore', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
   };

    // Helper function to check if a criterion is met
    const isCriterionMetCoimbatore = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesCoimbatore = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetCoimbatore(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetCoimbatore(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapCoimbatore).forEach(rowIndex => {
      const criteria = criteriaMapCoimbatore[rowIndex];
      const count = countMatchesCoimbatore(criteria);
      const cellH = worksheet.getCell(`AC${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });
    // ------------------------Bengaluru---------------
    // Define criteria for "Achieved" columns
    const criteriaMapAhmedabad = {
      4: ['Ahmedabad', 'Mahindra Finance'],
      5: ['Ahmedabad', 'Tata Motors Finance'],
      6: ['Ahmedabad', 'Cholamandalam'],
      7: ['Ahmedabad', 'HDB'],
      8: ['Ahmedabad', 'IDFC First Bank'],
      9: ['Ahmedabad', 'IndusInd Bank'],
      10: ['Ahmedabad', 'AU Small Finance Bank'],
      11: ['Ahmedabad', 'Tata OK'],
      12: ['Ahmedabad', 'Shriram Automall'],
      13: ['Ahmedabad', 'Zinka'],
      14: ['Ahmedabad',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Ahmedabad', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Ahmedabad', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Ahmedabad', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Ahmedabad', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Ahmedabad', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Ahmedabad', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Ahmedabad', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
   };

    // Helper function to check if a criterion is met
    const isCriterionMetAhmedabad = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesAhmedabad = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetAhmedabad(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetAhmedabad(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapAhmedabad).forEach(rowIndex => {
      const criteria = criteriaMapAhmedabad[rowIndex];
      const count = countMatchesAhmedabad(criteria);
      const cellH = worksheet.getCell(`AF${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });
    // ------------------------Hyderabad---------------
    // Define criteria for "Achieved" columns
    const criteriaMapIndore = {
      4: ['Indore', 'Mahindra Finance'],
      5: ['Indore', 'Tata Motors Finance'],
      6: ['Indore', 'Cholamandalam'],
      7: ['Indore', 'HDB'],
      8: ['Indore', 'IDFC First Bank'],
      9: ['Indore', 'IndusInd Bank'],
      10: ['Indore', 'AU Small Finance Bank'],
      11: ['Indore', 'Tata OK'],
      12: ['Indore', 'Shriram Automall'],
      13: ['Indore', 'Zinka'],
      14: ['Indore',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Indore', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Indore', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Indore', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Indore', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Indore', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Indore', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Indore', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
   };

    // Helper function to check if a criterion is met
    const isCriterionMetIndore = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesIndore = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetIndore(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetIndore(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapIndore).forEach(rowIndex => {
      const criteria = criteriaMapIndore[rowIndex];
      const count = countMatchesIndore(criteria);
      const cellH = worksheet.getCell(`AI${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });


    // ------------------------Coimbatore---------------
    // Define criteria for "Achieved" columns
    const criteriaMapNasik = {
      4: ['Nasik', 'Mahindra Finance'],
      5: ['Nasik', 'Tata Motors Finance'],
      6: ['Nasik', 'Cholamandalam'],
      7: ['Nasik', 'HDB'],
      8: ['Nasik', 'IDFC First Bank'],
      9: ['Nasik', 'IndusInd Bank'],
      10: ['Nasik', 'AU Small Finance Bank'],
      11: ['Nasik', 'Tata OK'],
      12: ['Nasik', 'Shriram Automall'],
      13: ['Nasik', 'Zinka'],
      14: ['Nasik',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Nasik', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Nasik', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Nasik', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Nasik', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Nasik', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Nasik', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Nasik', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      };

    // Helper function to check if a criterion is met
    const isCriterionMetNasik = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesNasik = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetNasik(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetNasik(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapNasik).forEach(rowIndex => {
      const criteria = criteriaMapNasik[rowIndex];
      const count = countMatchesNasik(criteria);
      const cellH = worksheet.getCell(`AL${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });

    // ------------------------Coimbatore---------------
    // Define criteria for "Achieved" columns
    const criteriaMapCochin = {
      4: ['Cochin', 'Mahindra Finance'],
      5: ['Cochin', 'Tata Motors Finance'],
      6: ['Cochin', 'Cholamandalam'],
      7: ['Cochin', 'HDB'],
      8: ['Cochin', 'IDFC First Bank'],
      9: ['Cochin', 'IndusInd Bank'],
      10: ['Cochin', 'AU Small Finance Bank'],
      11: ['Cochin', 'Tata OK'],
      12: ['Cochin', 'Shriram Automall'],
      13: ['Cochin', 'Zinka'],
      14: ['Cochin',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Cochin', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Cochin', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Cochin', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Cochin', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Cochin', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Cochin', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Cochin', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
  };

    // Helper function to check if a criterion is met
    const isCriterionMetCochin = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesCochin = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetCochin(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetCochin(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapCochin).forEach(rowIndex => {
      const criteria = criteriaMapCochin[rowIndex];
      const count = countMatchesCochin(criteria);
      const cellH = worksheet.getCell(`AO${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });



    // ------------------------Coimbatore---------------
    // Define criteria for "Achieved" columns
    const criteriaMapMysuru = {
      4: ['Mysuru', 'Mahindra Finance'],
      5: ['Mysuru', 'Tata Motors Finance'],
      6: ['Mysuru', 'Cholamandalam'],
      7: ['Mysuru', 'HDB'],
      8: ['Mysuru', 'IDFC First Bank'],
      9: ['Mysuru', 'IndusInd Bank'],
      10: ['Mysuru', 'AU Small Finance Bank'],
      11: ['Mysuru', 'Tata OK'],
      12: ['Mysuru', 'Shriram Automall'],
      13: ['Mysuru', 'Zinka'],
      14: ['Mysuru',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Mysuru', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Mysuru', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Mysuru', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Mysuru', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Mysuru', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Mysuru', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Mysuru', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
  };

    // Helper function to check if a criterion is met
    const isCriterionMetMysuru = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesMysuru = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetMysuru(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetMysuru(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapMysuru).forEach(rowIndex => {
      const criteria = criteriaMapMysuru[rowIndex];
      const count = countMatchesMysuru(criteria);
      const cellH = worksheet.getCell(`AR${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });


    // ------------------------Coimbatore---------------
    const criteriaMapLucknow = {
      4: ['Lucknow', 'Mahindra Finance'],
      5: ['Lucknow', 'Tata Motors Finance'],
      6: ['Lucknow', 'Cholamandalam'],
      7: ['Lucknow', 'HDB'],
      8: ['Lucknow', 'IDFC First Bank'],
      9: ['Lucknow', 'IndusInd Bank'],
      10: ['Lucknow', 'AU Small Finance Bank'],
      11: ['Lucknow', 'Tata OK'],
      12: ['Lucknow', 'Shriram Automall'],
      13: ['Lucknow', 'Zinka'],
      14: ['Lucknow',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Lucknow', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Lucknow', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Lucknow', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Lucknow', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Lucknow', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Lucknow', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Lucknow', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
   };

    // Helper function to check if a criterion is met
    const isCriterionMetLucknow = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
        if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };



    // Count matches and fill values in "Achieved" column
    const countMatchesLucknow = (criteria) => {
      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetLucknow(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetLucknow(q.answer, criterion))
        )
      ).length;
    };

    // Iterate over criteria map and update worksheet
    Object.keys(criteriaMapLucknow).forEach(rowIndex => {
      const criteria = criteriaMapLucknow[rowIndex];
      const count = countMatchesLucknow(criteria);
      const cellH = worksheet.getCell(`AU${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });

    // ------------------------Coimbatore---------------
    // Define criteria for "Achieved" columns
    const criteriaMapRachi = {
      4: ['Rachi', 'Mahindra Finance'],
      5: ['Rachi', 'Tata Motors Finance'],
      6: ['Rachi', 'Cholamandalam'],
      7: ['Rachi', 'HDB'],
      8: ['Rachi', 'IDFC First Bank'],
      9: ['Rachi', 'IndusInd Bank'],
      10: ['Rachi', 'AU Small Finance Bank'],
      11: ['Rachi', 'Tata OK'],
      12: ['Rachi', 'Shriram Automall'],
      13: ['Rachi', 'Zinka'],
      14: ['Rachi',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Rachi', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Rachi', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Rachi', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Rachi', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Rachi', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Rachi', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Rachi', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
   };

    // Helper function to check if a criterion is met
    const isCriterionMetRachi = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesRachi = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetRachi(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetRachi(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapRachi).forEach(rowIndex => {
      const criteria = criteriaMapRachi[rowIndex];
      const count = countMatchesRachi(criteria);
      const cellH = worksheet.getCell(`AX${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });



    // ------------------------Coimbatore---------------
    // Define criteria for "Achieved" columns
    
    const criteriaMapSurat = {
      4: ['Surat', 'Mahindra Finance'],
      5: ['Surat', 'Tata Motors Finance'],
      6: ['Surat', 'Cholamandalam'],
      7: ['Surat', 'HDB'],
      8: ['Surat', 'IDFC First Bank'],
      9: ['Surat', 'IndusInd Bank'],
      10: ['Surat', 'AU Small Finance Bank'],
      11: ['Surat', 'Tata OK'],
      12: ['Surat', 'Shriram Automall'],
      13: ['Surat', 'Zinka'],
      14: ['Surat',  ['Other','Hinduja Leyland','Shriram Finance','Sundaram Finance','Equitas Bank','Bank of Baroda','Lendingkart','Mahindra M Trust/ Mahindra First Choice','Eicher','Truck Dekho','Credit Mantri','Trucksuvidha','Trucksbuses.com','Trucksdekho','Gaadibazar.in','Loankorker.com','Jugnoo','Moovo','Rego']],
      17: ['Surat', 'New Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      18: ['Surat', 'Used Vehicle',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      20: ['Surat', 'DCO (Driver cum Owner) – having only one truck and himself driving his truck',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      21: ['Surat', 'Retail Transporter (transport/ logistic company) – should fulfil all the below criteria <br/> •\thaving any number of trucks (1 or more)   <br/>•\tcarrying third party goods in their trucks   <br/> •\tnot driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      22: ['Surat', 'Captive / Own business (carrying own goods in their trucks)',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      23: ['Surat', 'Commission agent/ Freight booking agent – should fulfil all the below criteria <br/> •\tworking as commission agent between principal/customer and fleet owner <br/> •\tCarrying third party goods <br/> •\tShould not be driving only by self',['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
      25: ['Surat', ['2-5 vehicles', '6-9 vehicles', '10 or more than 10 Vehicles'],['Mahindra Finance','Tata Motors Finance','Cholamandalam','HDB','IDFC First Bank','IndusInd Bank','AU Small Finance Bank','Tata OK','Shriram Automall','Zinka']],
    };

    // Helper function to check if a criterion is met
    const isCriterionMetSurat = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
       if (Array.isArray(answer)) {
          return answer.includes(criterion) || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion || 
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };

    // Count matches and fill values in "Achieved" column
    const countMatchesSurat = (criteria) => {

      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetSurat(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMetSurat(q.answer, criterion))
        )
      ).length;
    };

    Object.keys(criteriaMapSurat).forEach(rowIndex => {
      const criteria = criteriaMapSurat[rowIndex];
      const count = countMatchesSurat(criteria);
      const cellH = worksheet.getCell(`BA${rowIndex}`);
      cellH.value = count;
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    });




    const calculateAndSetSum = (columnLetter, sumRange, targetRow) => {
      let sum = 0;
      for (let rowIndex = sumRange.start; rowIndex <= sumRange.end; rowIndex++) {
        sum += worksheet.getCell(`${columnLetter}${rowIndex}`).value || 0;
      }
      worksheet.getCell(`${columnLetter}${targetRow}`).value = sum;
      worksheet.getCell(`${columnLetter}${targetRow}`).font = headerStyle.font;
      worksheet.getCell(`${columnLetter}${targetRow}`).fill = achievedcellstyle.fill;
      worksheet.getCell(`${columnLetter}${targetRow}`).alignment = cellStyle.alignment;
      worksheet.getCell(`${columnLetter}${targetRow}`).border = cellStyle.border;
    };

    // Define the range of rows to sum (rows 4 to 13)
    const sumRowRange = { start: 4, end: 13 };

    // List of columns you want to calculate sums for
    const columnsToSum = ['H', 'K', 'N', 'Q', 'T', 'W', 'Z', 'AC', 'AF', 'AI', 'AL', 'AO', 'AR', 'AU', 'AX', 'BA']

    // Apply the sum calculation for each column
    columnsToSum.forEach(column => {
      calculateAndSetSum(column, sumRowRange, 15); // Sum rows 4-13 and set the result in row 14
    });


    // -------total Values --------  



    //  Sum the values of the specified columns for each row and fill in column E
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`C${rowIndex}`);
      if (cellC.value) {
        const sum = ['H', 'K', 'N', 'Q', 'T', 'W', 'Z', 'AC', 'AF', 'AI', 'AL', 'AO', 'AR', 'AU', 'AX', 'BA']
          .map(col => worksheet.getCell(`${col}${rowIndex}`).value || 0)
          .reduce((acc, value) => acc + value, 0);

        const cellE = worksheet.getCell(`E${rowIndex}`);
        cellE.value = sum;
        if (rowIndex == 15) {
          cellE.font = headerStyle.font
          cellE.fill = achievedstyle.fill
        }
        else {
          cellE.font = cellStyle.font;

          cellE.fill = achievedcellstyle.fill;
        }

        cellE.alignment = cellStyle.alignment;
        cellE.border = cellStyle.border;
      }
    }

    const columnsFinal = [
      { result: 'F', from: 'D', subtract: 'E' },
      { result: 'I', from: 'G', subtract: 'H' },
      { result: 'L', from: 'J', subtract: 'K' },
      { result: 'O', from: 'M', subtract: 'N' },
      { result: 'R', from: 'P', subtract: 'Q' },
      { result: 'U', from: 'S', subtract: 'T' },
      { result: 'X', from: 'V', subtract: 'W' },
      { result: 'AA', from: 'Y', subtract: 'Z' },
      { result: 'AD', from: 'AB', subtract: 'AC' },
      { result: 'AG', from: 'AE', subtract: 'AF' },
      { result: 'AJ', from: 'AH', subtract: 'AI' },
      { result: 'AM', from: 'AK', subtract: 'AL' },
      { result: 'AP', from: 'AN', subtract: 'AO' },
      { result: 'AS', from: 'AQ', subtract: 'AR' },
      { result: 'AV', from: 'AT', subtract: 'AU' },
      { result: 'AY', from: 'AW', subtract: 'AX' },
      { result: 'BB', from: 'AZ', subtract: 'BA' },
    ];

    columnsFinal.forEach(({ result, from, subtract }) => {
      for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
        const cellC = worksheet.getCell(`C${rowIndex}`);
        if (cellC.value) {
          const cellFrom = worksheet.getCell(`${from}${rowIndex}`);
          const cellSubtract = worksheet.getCell(`${subtract}${rowIndex}`);
          const cellToFill = worksheet.getCell(`${result}${rowIndex}`);
          const valueFrom = cellFrom.value || 0;
          const valueSubtract = cellSubtract.value || 0;
          cellToFill.value = valueFrom - valueSubtract;
          if (rowIndex == 15) {
            cellToFill.font = headerStyle.font
            cellToFill.fill = pendingtyle.fill;
          }
          else {
            
            cellToFill.font = cellStyle.font;

            cellToFill.fill = pendingcellstyle.fill;
          }
          cellToFill.alignment = cellStyle.alignment;
          cellToFill.border = cellStyle.border;
        }
      }
    });





    if (id?.id == "667fb4695fe2ebbc73be4293") {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'QdegreesReport.xlsx');
      setLoader(false);
    }
    else if (id?.id == "66b0b194d3934822a34cfd3b") {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'LMVReport.xlsx');
      setLoader(false);
    }
    else if (id?.id == "668676b66fbb594103bcb9b2") {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'KadenceReport.xlsx');
      setLoader(false);
    }

  };

  return (
    <>
      {loader && <Loader />}
      <div className="excelcontainer">
        <div className="excelcard">
          {id?.id == "667fb4695fe2ebbc73be4293" &&
            <> <label>Download Excel For Project Gold Final Survey</label>
              <button className="btn btn-primary" onClick={() => handleDownload()}>Download</button></>}
          <br />
          {id?.id == "66b0b194d3934822a34cfd3b" &&
            <> <label>Download Excel For Project IDFC LMV </label>
              <button className="btn btn-primary" onClick={() => handleDownload()}>Download</button>
            </>}
          <br />
          {id?.id == "668676b66fbb594103bcb9b2" &&
            <> <label>Download Excel For Project Gold Final Survey (KADENCE)</label>
              <button className="btn btn-primary" onClick={() => handleDownload()}>Download</button>
            </>}
        </div>

      </div>
    </>
  );
};

export default Datatable;

