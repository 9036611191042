import './index.css';
import App from './App';
import {
  HashRouter
} from "react-router-dom";
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import WhatsApp from './whatsappIcon';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HashRouter>
  <App />
  <WhatsApp/>
      
      {/* <a
        href="https://wa.me/8696900829"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        
        <i className="fa fa-comments whatsapp-icon"></i>
        
      </a> */}
      {/* <span className='text_float'>Get In Touch</span> */}
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results 
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
