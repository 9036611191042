// import React from 'react';
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// const Dictaphone = () => {
//   const {
//     transcript,
//     listening,
//     resetTranscript,
//     browserSupportsSpeechRecognition
//   } = useSpeechRecognition();

//   if (!browserSupportsSpeechRecognition) {
//     return <span>Browser doesn't support speech recognition.</span>;
//   }

//   return (
//     <div>
//       <p>Microphone: {listening ? 'on' : 'off'}</p>
//       <button onClick={SpeechRecognition.startListening}>Start</button><br></br>
//       <button onClick={SpeechRecognition.stopListening}>Stop</button><br></br>
//       <button onClick={resetTranscript}>Reset</button><br></br>
//       <p>{transcript}</p>
//       {console.log(transcript)}
//     </div>
//   );
// };
// export default Dictaphone;


// import React, { useEffect, useState } from 'react';
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// const Dictaphone = () => {
//   const { transcript, resetTranscript } = useSpeechRecognition();
//   const [isListening, setIsListening] = useState(false);
//   const [recordingTime, setRecordingTime] = useState(0);
//   const [intervalId, setIntervalId] = useState(null);

//   useEffect(() => {
//     if (isListening) {
//       SpeechRecognition.startListening();
//       const id = setInterval(() => {
//         setRecordingTime((prev) => prev + 1);
//       }, 1000); // Update every second
//       setIntervalId(id);
//     } else {
//       SpeechRecognition.stopListening();
//       if (intervalId) clearInterval(intervalId);
//     }

//     return () => {
//       if (intervalId) clearInterval(intervalId);
//     };
//   }, [isListening]);

//   useEffect(() => {
//     if (recordingTime >= 300) { // Set your desired time limit here
//       setIsListening(false);
//       setRecordingTime(0); // Reset recording time
//     }
//   }, [recordingTime]);

//   const handleStart = () => {
//     setIsListening(true);
//     setRecordingTime(0); // Reset recording time on new start
//   };

//   const handleStop = () => {
//     setIsListening(false);
//   };

//   return (
//     <div>
//       <button onClick={handleStart}>Start Recording</button>
//       <button onClick={handleStop}>Stop Recording</button>
//       <p>Transcript: {transcript}</p>
//       <p>Recording Time: {recordingTime} seconds</p>
//     </div>
//   );
// };

// export default Dictaphone;




import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const SpeechToText = () => {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [sessionTimeoutId, setSessionTimeoutId] = useState(null);
  const [autoStopTimeoutId, setAutoStopTimeoutId] = useState(null);

  const maxRecordingTime = 300; // 300 seconds = 5 minutes
  const sessionDuration = 5; // Duration of each session in seconds

  useEffect(() => {
    if (isListening) {
      SpeechRecognition.startListening({ continuous: true, language: 'as-in' });
      const intervalId = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000); // Update every second

      // Automatically stop recording after a short duration and restart
      const stopTimeoutId = setTimeout(() => {
        setIsListening(false);
        setRecordingTime(prev => prev); // Preserve the recording time

        // Automatically restart recording after a short delay
        const restartTimeoutId = setTimeout(() => {
          setIsListening(true);
        }, 1000); // Restart after 1 second

        setAutoStopTimeoutId(restartTimeoutId);
      }, sessionDuration * 60000); // Stop after sessionDuration seconds

      setSessionTimeoutId(stopTimeoutId);

      return () => {
        clearInterval(intervalId);
        clearTimeout(stopTimeoutId);
        clearTimeout(autoStopTimeoutId);
      };
    } else {
      SpeechRecognition.stopListening();
      setRecordingTime(0); // Reset recording time
      if (sessionTimeoutId) clearTimeout(sessionTimeoutId);
      if (autoStopTimeoutId) clearTimeout(autoStopTimeoutId);
    }
  }, [isListening]);

  useEffect(() => {
    if (recordingTime >= maxRecordingTime) {
      setIsListening(false);
    }
  }, [recordingTime]);

  const handleStart = () => {
    setIsListening(true);
    setRecordingTime(0); // Reset recording time on new start
  };

  const handleStop = () => {
    setIsListening(false);
  };

  return (
    <div>
      <button onClick={handleStart}>Start Recording</button>
      <button onClick={handleStop}>Stop Recording</button>
      <p>Transcript: {transcript}</p>
      <p>Recording Time: {recordingTime} seconds</p>
    </div>
  );
};

export default SpeechToText;

