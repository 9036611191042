import React from 'react';
import { useState, useEffect } from 'react';
import API from '../../utils/apiCalling';
import { saveAs, FileSaver } from 'file-saver';
import { useLocation, useParams } from "react-router-dom";
import Config from '../../config/env'

// import FileSaver from 'file-saver';


const SurveyImages = () => {
  let api = new API()
  const { id } = useParams();
  const [image, setImage] = useState([])
  const [findImage, findLoanNumber] = useState('')
  const [secondsearch, setSecondSearch] = useState('')

  useEffect(() => {
    getImage()
  }, [])



  const getImage = async () => {

    let result
    console.log(id, "idddddddddddd")
    if (id === undefined) {
      result = await api.get(`${Config.new.site_api}/client-survey/getTatalImage`)
    } else {
      result = await api.get(`${Config.new.site_api}/client-survey/getSurveyattachments`, true, { surveyId: id })
    }
    if (result.code === 200) {
      console.log(result.data)
      setImage(result.data)
    }
  }

  const secondImage = async () => {
    let data = {
      ImageArray: JSON.stringify(image),
      searchValue: secondsearch
    }
    let result = await api.post(`${Config.new.site_api}/Avanti/searchImagetwo`, data)
    if (result.code === 200) {
      setImage(result.data)
    }
  }



  const handleDownload = (e) => {
    if (id === undefined) {
      saveAs(e.Image, e.Id)
    } else {
      saveAs(e.image, e.ID)
    }
  }

  const handleDownloadImage = async () => {
    window.location.href = 'https://api.doyoursurvey.com:3009/Avanti/downloadImage';
  }

  return (
    <>
      <div className='col-lg-12 '>
        {/* <label>Enter Loan No</label>
        <input className='ml-4' type='text' onChange={e=>findLoanNumber(e.target.value)} placeholder='Search...' />
        <button className='py-1 mainbtn m-4' onClick={getImage}>Find</button>
         <label>Enter location</label>
        <input className='ml-4' type='text' onChange={e=>setSecondSearch(e.target.value)} placeholder='Search...' />
        <button className='py-1 mainbtn m-4' onClick={secondImage}>second Find</button>
        <button className='py-1 mainbtn m-4' onClick={handleDownloadImage}>All Download</button> */}
        <div className='d-flex flex-wrap'>
          {image && image?.map((e) => {
            return (
              <div className='card ContentTap1' >
                {/* {/ <button><i className="bi bi-three-dots-vertical" ></i></button> /} */}
                <div className='UnnderItems text-center' >

                  {/* {/ <h4 className='text-orange mb-1'>e.survey_name</h4> /} */}
                  {id === undefined ? <><video src={e.Image} alt='Img' height={180} width={240} />
                    <p className='mb-0'>Dealer Name <span>: {e.Id}</span></p></>
                    : <>
                      <video src={e.image} alt='Img' height={180} width={240} />
                      <p className='mb-0'>Id <span>: {e.ID}</span></p></>}
                  <p className='mb-0 text-orange' onClick={(q) => handleDownload(e)} style={{ cursor: 'pointer' }}>Download </p>
                </div>
                <div className='UnnderItems UnderSamers h-100'>
                </div>
              </div>)
          })}
        </div>
      </div>
    </>
  );
}

export default SurveyImages;