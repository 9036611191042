
export const dateFilter = (type) => {
     let date = new Date()
     let currentYear = date.getFullYear()
     let currentMonth = null
     let currentDate = '1'
     if (type === 'start_date') {
          currentMonth = date.getMonth()
     } else {
          currentMonth = date.getMonth() + 1
          currentDate = date.getDate()
     }
     if (currentMonth < 10) {
          currentMonth = `0${currentMonth}`
     }
     if (currentDate < 10) {
          currentDate = `0${currentDate}`
     }


     let finalDate = `${currentYear}-${currentMonth}-${currentDate}`

     return finalDate
}

export const capitalizeFirstLetter = (value) => {
     if (value) {
          let str = [...value]
          str[0] = str[0].toUpperCase();
          str = str.join("")
          return str
     }
     else {
          return value
     }
}
export const Spaces = (value) => {
     let arr = value.split("")
     const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
     if (value !== undefined) {
          for (let i = 0; i < value.length; i++) {
               if (alphabet.includes(value[i])) {
                    let index = arr.indexOf(value[i])
                    arr.splice(index, 0, " ")

                    console.log("djkehhbdAknshdku3w", arr)

                    //     }
               }
          }
     }
     return capitalizeFirstLetter(arr.join(""))
}

export const getNameById = (id, arr) => {
     let name = ""
     arr && arr.length > 0 && arr.map((e) => {
          if (id === e._id) {
               name += e.category;
          }
     })
     return name

}



export const currentMonth = (type) => {
     let date = new Date()
     let currentYear = date.getFullYear()
     let currentMonth = null
     let currentDate = '1'
     currentMonth = date.getMonth() + 2
     if (type === 'end_date') {
          currentDate = date.getDate()
     }
     if (currentMonth < 10) {
          currentMonth = `0${currentMonth}`
     }
     if (currentDate < 10) {
          currentDate = `0${currentDate}`
     }


     let finalDate = `${currentYear}-${currentMonth}-${currentDate}`

     return finalDate
}

export const lastMonth = (type) => {
     let date = new Date()
     let currentYear = date.getFullYear()
     let currentMonth = date.getMonth()
     let currentDate = '1'
     if (type === 'end_date') {
          currentDate = new Date(currentYear, currentMonth, 0).getDate()
     }
     if (currentMonth < 10) {
          currentMonth = `0${currentMonth}`
     }
     if (currentDate < 10) {
          currentDate = `0${currentDate}`
     }


     let finalDate = `${currentYear}-${currentMonth}-${currentDate}`

     return finalDate
}