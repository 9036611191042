import React, { useState, useEffect, useRef } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
// import { config } from '../../utils/apiUrl';
// import { toast } from 'react-toastify';
// import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CoustomStorage';
// import RewardUseModal from '../common/rewardUserModal'
// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link } from "react-router-dom";
import Loader2 from '../../utils/loader2/loader2'
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CSVLink } from "react-csv";
// import json2csv from "json2csv";

const ViewSingleSurveyRecordDownload = (props) => {
    const api = new API()

    const csvLink = useRef()
    let { goBack, singleUserSurveyRecord, email } = props

    const [viewSurvey, setViewSurvey] = useState(false)
    const [viewSurveyId, setViewSurveyId] = useState()
    const [downloadSurvey, setDownloadSurvey] = useState([])
    const [generate, setGenerate] = useState(false)
    const [excelFileName, setExcelFileName] = useState("")
    useEffect(() => {

    }, [])

    const viewSurveybtn = (id) => {
        setViewSurvey(!viewSurvey)
        setViewSurveyId(id)
    }
    const downloadSurveyExcel = (survey) => {
   
        setExcelFileName(survey.surveyName)
        let result = survey.questions.filter((sur) => {
            let opArr = []
            sur.options.filter(op=>{
                opArr.push(op && op.option)
           
            })
            
            sur.options = opArr;
            // let newArr = sur +=
            // setDownloadSurvey([...downloadSurvey, newArr])
            return sur
        })
        setDownloadSurvey(result)
      
        setDownloadSurvey(result)
        setViewSurveyId(survey._id)
        setGenerate(true)
    }
    return (
        <div>
            <div className="container mt-4">


                <div className="card mb-5" >
                    <div className="card-header" style={{ backgroundColor: "gray" }}>
                        <h6 className="text-white"> User Email : {singleUserSurveyRecord.email} </h6>
                    </div>
                    <div className="card-body" style={{ borderTop: "1px solid" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <h5>Total Survey UserAttended : <span style={{ color: "red" }}>{singleUserSurveyRecord.attendedSurvey.length}</span></h5>

                                <div className="form-group">
                                    {singleUserSurveyRecord.attendedSurvey.map((survey, index) => (
                                        <div className="mt-3">

                                            <label>{index + 1}: {survey.surveyName}</label>
                                            <button onClick={() => viewSurveybtn(survey._id)} className="btn btn-secondary ml-3">view</button>
                                            {/* {generate == false &&
                                                <button onClick={() => downloadSurveyExcel(survey)} className="btn btn-secondary ml-3">Generate Excel</button>
                                            } */}
                                            {generate && viewSurveyId == survey._id ?
                                                <CSVLink color="black" data={downloadSurvey}
                                                    filename={singleUserSurveyRecord.email+excelFileName+".csv"} ref={csvLink}>
                                                    <button className="btn btn-success ml-3">Excel Download</button>
                                                </CSVLink>
                                                :
                                                <button onClick={() => downloadSurveyExcel(survey)} className="btn btn-secondary ml-3">Generate Excel</button>
                                            }

                                            {viewSurvey == true && viewSurveyId == survey._id &&

                                                <div>
                                                    {survey.questions.map((question, ind) => (
                                                        <div className="container">


                                                            <div className="card" style={{ margin: "50px" }}>
                                                                <div className="card card-header">
                                                                    <h5>{ind + 1}: {question.questions}</h5>
                                                                </div>
                                                                <div className="card card-body">
                                                                    {question.options.map((op, i) => (
                                                                        <div>
                                                                            <p>{i + 1}: {op && op.option}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="card card-footer">
                                                                    <span>Answer : {question.answer.length > 0 && question.answer.map((ans, indexx) => (
                                                                        <span> { ans},  </span>
                                                                    ))}</span>
                                                                    <h6> <span style={{ color: "red" }}>Free text : </span> {question.textVal}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>

                                            }
                                        </div>
                                    ))}
                                </div>


                                <div>
                                    <button onClick={goBack} className="btn btn-secondary">GoBack</button>
                                </div>
                            </div>
                            <div className="col-md-6 item-center">
                                {/* <center><button onClick={() => deleteUser(userSurveyRecord.email)} className="btn btn-danger btn-block col-md-6 text-white">Delete User</button></center> */}
                                {/* <center><button onClick={() => getUserAllRedeem(rewarduser, "pendingRedeem")} data-toggle="modal" data-target="#rewardUserModal" className="btn btn-link btn-block col-md-6 mt-3 text-white">Approve One by One Pending</button></center> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="card-footer">
                               
                            </div> */}

                </div>


            </div>

        </div>
    );
}

export default ViewSingleSurveyRecordDownload;