import React, { useState, useEffect } from 'react'
import Loader2 from '../../utils/loader2/loader2';
import SurveyDashboard from '../surveyDashboard/surveyDashboard'

import API from '../../utils/apiCalling';
import Config from '../../config/env'


export default function Clientdemo() {

  const api = new API()
  const [data, setData] = useState([]);
  const [loader2, setLoader2] = useState(true)


  const fetchUsers = async () => {
    console.log("API........................")
    let result = await api.get(`${Config.new.site_api}/client-survey/getFreeDemo`)

    if (result.code === 200) {
      setLoader2(false)
      setData(result.data);
      console.log(result.data)
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [])



  return (



    <div style={{ textAlign: 'center', marginBottom: "10" }}>
      <table className="table table-bordered  " style={{ border: "1px solid #f47321" }} >

        <thead style={{ position: "sticky", top: "0" }} >
          <tr style={{ backgroundColor: "Gray" }}>
            <th scope="col">Name</th>
            <th scope="col">Number</th>
            <th scope="col">Email</th>
            <th scope="col">Time</th>
            <th scope="col">Date</th>
            <th scope="col">Status</th>

          </tr>
        </thead>
        
        {loader2 == true ? 
          <tr><td></td><td></td><div className="container mt-2  " style={{ maxHeight: "100%" , maxWeidth: "100%"}}>
            <Loader2 />
          </div></tr>
         :
          <tbody>
            
            <>
              {

                data && data.length > 0 && data.map((item) => (

                  <tr >
                    <td >{item.name}</td>
                    <td>{item.number}</td>
                    <td>{item.email}</td>
                    <td>{item.time}</td>
                    <td>{item.date}</td>
                    <td>{item.status == 0 ? "pending" : "completed"}</td>

                  </tr>

                ))

              }
            </>


          </tbody>}

      </table>


    </div>

  )
}
