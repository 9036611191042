// import {config} from '../helper/ApiUrl';

import axios from "axios";
import { toast } from 'react-toastify';

// import { get } from 'lodash'


class API {
     async get(url, haveParams = false, params = {}) {

          try {
               const headers = new Headers();
               headers.set("Content-Type", "application/x-www-form-urlencoded");
               headers.set("x-auth-token", await this.getToken());

               const request = await fetch(`${url}${haveParams ? `?${new URLSearchParams(params)}` : ''}`, {
                    method: 'GET',
                    headers,

               })
               const response = await request.json();
               return response;
          } catch (error) {
               console.log("res123", error)

               return {
                    success: false,
                    error
               }
          }
     }
     async gett(url, haveParams = false, params = {}) {
          console.log(url)
          try {
               const headers = new Headers();
               headers.set("Content-Type", "application/x-www-form-urlencoded");
               // headers.set("x-auth-token", await this.getToken());

               const request = await fetch(`${url}${haveParams ? `?${new URLSearchParams(params)}` : ''}`, {
                    method: 'GET',
                    headers,

               })
               const response = await request.json();
               return response;
          } catch (error) {
               console.log("res123", error)

               return {
                    success: false,
                    error
               }
          }
     }

     async post(url, body) {
          const data = Object.keys(body).map((key) => {
               return encodeURIComponent(key) + '=' + encodeURIComponent(body[key]);
          }).join('&');
          try {
               const headers = new Headers();
               headers.set('Content-Type', 'application/x-www-form-urlencoded');
               headers.set("x-auth-token", await this.getToken());

               const request = await fetch(url, {
                    method: 'POST',
                    headers,
                    body: data
               })

               const response = await request.json();
               return response;
          } catch (error) {
               toast.warning('Something went wrong')

          }
     }
     async putPostFileFun(url, formData, msgdisplay) {
          try {
               console.log(formData,"formdata")
               const configdata = {
                    headers: {
                         "content-type": "multipart/form-data",
                         // "x-auth-token": await this.getToken(),
                    },
               };
               let response = await axios.post(url, formData, configdata);
               if (response) {
                    if (response.data.error === false) {

                         //   msgDisplayFunc(msgdisplay, response.data.error, response.data.msg);
                         return response.data;
                    } else {
                         //   msgDisplayFunc(msgdisplay, response.data.error, response.data.msg);
                         return response.data;
                    }
               }
          } catch (error) {
               return {
                    success: false,
                    error,
               };
               //   toast.warning("Please check your internet connectivity");
          }
     }



     async put(url, body) {
          // let token =  await this.getToken()
          // body.token = token
          try {
               const headers = new Headers();
               headers.set('Content-Type', 'application/json');
               const request = await fetch(url, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(body)
               })
               const response = await request.json();
               return response;
          } catch (error) {
               return {
                    success: false,
                    error
               }
          }
     }

     async delete(url, params) {
          // let token =  await this.getToken()
          // body.token = token

          try {
               const headers = new Headers();
               headers.set('Content-Type', 'application/json');
               const request = await fetch(`${url}/${params}`, {
                    method: 'DELETE',
                    headers,
               })
               const response = await request.json();
               return response;
          } catch (error) {
               return {
                    success: false,
                    error
               }
          }
     }

     // async getToken() {
     //     const token = await ''
     //     const defaultToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC90aGV0ZXhkZWFsLml0cGVvcGxlNHUuY29tXC93ZWJhcGlcL2FwaVwvYWRtaW5cL2xvZ2luIiwiaWF0IjoxNTk0MzcyMDk5LCJuYmYiOjE1OTQzNzIwOTksImp0aSI6IlNuamdaalZjTnJkWU9aQ1UiLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.5S1PAEnWN_YTV7na3nDE-CHvI5-vokuViF-9OHyqmTs';
     //     return token 
     // }
     async getToken() {

          const token = await JSON.parse(localStorage.getItem("token")) || "";

          return token;
     }

     async postUpload(url, file) {
          //     const data = new FormData()
          //    data.append("file",image)
          //    data.append("upload_preset","cookandcookies")
          //    data.append("cloud_name","cook-cookies")
          try {
               const request = await fetch(url, {
                    method: "post",
                    body: file
               })
               const response = await request.json();
               return response;
          } catch (error) {
               return {
                    success: false,
                    error
               }
          }
     }

}

export default API;