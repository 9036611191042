import React, { useState, useEffect } from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import Config from '../../config/env';
import API from '../../utils/apiCalling';
import Loader from '../../utils/loader';
import { useParams } from 'react-router-dom/cjs/react-router-dom';

const RuparReport = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [surveyId, setSurveyId] = useState(null);
  const checkarray = ['Select your city.','May I know if you currently have a NETC FASTag?','Select the type of RuPay Card you own.','INTERVIEWER TO SELECT CARD AS PER QUOTA & CODE BELOW','CODE GENDER','Your age lie under the range of']
  const id = useParams();

  let api = new API();

  useEffect(() => {
    getSurveyById(id)
    getDashBoard(id)
  }, []);

  const getSurveyById = async (id) => {
    let data = { id: id.id };
    let result = await api.post(`${Config.new.site_api}/client-survey/getSurvey`, data);

    if (result && result.code === 200) {
      const questionsWithState = result.data.questions.map(q => ({ ...q, isOpen: false }));
      setAllQuestions(questionsWithState);
    }
  };

  const getDashBoard = async (surveyId) => {
    setLoader(true)
    let data = { surveyId: surveyId.id };
    let surveyData = await api.post(`${Config.new.site_api}/client-survey/Dashboard`, data);

    if (surveyData?.code === 200) {
      setDashboardData(surveyData.data);
      setLoader(false)
    }
    else {
      setLoader(false)
    }
  };
  // Helper function to check if the question is in the checkarray
  const isQuestionInCheckArray = (question) => {
    
    return checkarray.includes(question);
  };
  const handleDownloadRupay = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    worksheet.getColumn(1).width = 13;
    worksheet.getColumn(2).width = 23;
    worksheet.getColumn(3).width = 13;
    worksheet.getColumn(4).width = 13;
    worksheet.getColumn(5).width = 13;
    worksheet.getColumn(6).width = 13
    worksheet.getColumn(7).width = 13;
    worksheet.getColumn(8).width = 13;
    worksheet.getColumn(9).width = 13;
    worksheet.getColumn(10).width = 13;
    worksheet.getColumn(11).width = 13;
    worksheet.getColumn(12).width = 13;
    worksheet.getColumn(13).width = 13;
    worksheet.getColumn(14).width = 13;
    worksheet.getColumn(15).width = 13;
    worksheet.getColumn(16).width = 13;
    worksheet.getColumn(17).width = 13;
    worksheet.getColumn(18).width = 13;
    worksheet.getColumn(19).width = 13;
    worksheet.getColumn(20).width = 13;
    worksheet.getColumn(21).width = 13;
    worksheet.getColumn(22).width = 13;
    worksheet.getColumn(23).width = 13;

    // Merge cells for headers
    worksheet.mergeCells('A3:A3');
    worksheet.mergeCells('A6:A7');
    worksheet.mergeCells('A10:A11');
    worksheet.mergeCells('A16:A16');
    worksheet.mergeCells('A19:A20');
    worksheet.mergeCells('A23:A24');
   
    worksheet.mergeCells('B3:B3');
    worksheet.mergeCells('B4:B4');
    worksheet.mergeCells('B5:B5');
    worksheet.mergeCells('B6:B6');
    worksheet.mergeCells('B7:B7');
    worksheet.mergeCells('B8:B8');
    worksheet.mergeCells('B9:B9');
    worksheet.mergeCells('B10:B10');
    worksheet.mergeCells('B12:B12');
    worksheet.mergeCells('B13:B13');
    worksheet.mergeCells('B14:B14');
    worksheet.mergeCells('B16:B16');
    worksheet.mergeCells('B17:B17');
    worksheet.mergeCells('B18:B18');
    worksheet.mergeCells('B19:B19');
    worksheet.mergeCells('B20:B20');
    worksheet.mergeCells('B21:B21');
    worksheet.mergeCells('B22:B22');
    worksheet.mergeCells('B23:B23');
    worksheet.mergeCells('B24:B24');


   
    // Merge cells for new headers
    worksheet.mergeCells('C1:W1');
    worksheet.mergeCells('C2:E2');
    worksheet.mergeCells('F2:H2');
    worksheet.mergeCells('I2:K2');
    worksheet.mergeCells('L2:N2');
    worksheet.mergeCells('O2:Q2');
    worksheet.mergeCells('R2:T2');
    worksheet.mergeCells('U2:W2');
    worksheet.mergeCells('C14:W14');
    worksheet.mergeCells('C15:E15');
    worksheet.mergeCells('F15:H15');
    worksheet.mergeCells('I15:K15');
    worksheet.mergeCells('L15:N15');
    worksheet.mergeCells('O15:Q15');
    worksheet.mergeCells('R15:T15');
    worksheet.mergeCells('U15:W15');
   

    // Define styles
    const headerStyle = {
      font: { bold: true, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'fffed9' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };

    const plannedstyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'bdd3f9' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const cellStyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const SpecialStyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'e3e3e3' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const plannedcellstyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const achievedstyle = {
      font: { color: { argb: '00000000' }, size: 12, },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '92c67c' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const achievedcellstyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const pendingtyle = {
      font: { color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffcc98' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };
    const pendingcellstyle = {
      font: { bold: false, color: { argb: '00000000' } },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: 'center', vertical: 'middle' },
      border: {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } }
      }
    };

    const cellsToStyleA = [
      { cell: 'A3', value: 'Factors' },
      // { cell: 'B4', value: 'Delhi' },
      { cell: 'A6', value: 'Age' },
      { cell: 'A10', value: 'Gender' },
      { cell: 'A16', value: 'Factors' },
      // { cell: 'B4', value: 'Delhi' },
      { cell: 'A19', value: 'Age' },
      { cell: 'A23', value: 'Gender' },
      
    ];

    cellsToStyleA.forEach(({ cell, value }) => {


      const excelCell = worksheet.getCell(cell);
      excelCell.value = value;
      if (value == "Factors") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }else  if (value == "Age") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }else  if (value == "Gender") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }
      else {
        // excelCell.fill = headerStyle.fill;
        excelCell.font = headerStyle.font;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
        excelCell.fill = cellStyle.fill;
      }
    });

    // Define cell values and styles for column B
    const cellsToStyleB = [
      { cell: 'B3', value: 'Cities' },
      { cell: 'B4', value: 'Total' },
      // { cell: 'B4', value: 'Delhi' },
      // { cell: 'B5', value: 'Age' },
      { cell: 'B6', value: '18-34 (Minimum 30%)' },
      { cell: 'B7', value: '35+ (Minimum 30%)' },
      // { cell: 'B9', value: 'Gender' },
      { cell: 'B10', value: 'Male (Minimum 30%)' },
      { cell: 'B11', value: 'Female (Minimum 30%)' },

      { cell: 'B16', value: 'Cities' },
      { cell: 'B17', value: 'Total' },
      // { cell: 'B4', value: 'Delhi' },
      // { cell: 'B5', value: 'Age' },
      { cell: 'B19', value: '18-34 (Minimum 30%)' },
      { cell: 'B20', value: '35+ (Minimum 30%)' },
      // { cell: 'B9', value: 'Gender' },
      { cell: 'B23', value: 'Male (Minimum 30%)' },
      { cell: 'B24', value: 'Female (Minimum 30%)' },
    

    ];

    cellsToStyleB.forEach(({ cell, value }) => {


      const excelCell = worksheet.getCell(cell);
      excelCell.value = value;
      if (value == "Cities") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }else  if (value == "Total") {
        excelCell.font = headerStyle.font;
        excelCell.fill = SpecialStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;}
      else  if (value == "Age") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }else  if (value == "Gender") {
        excelCell.font = headerStyle.font;
        excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }
      else {
        // excelCell.fill = headerStyle.fill;
        excelCell.font = headerStyle.font;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
        excelCell.fill = cellStyle.fill;
      }
    });

    // Define cell values and styles for column C


    

   

    // Define cell values and styles for new headers
    const cellsToStyleNew = [
      { cell: 'C1', value: 'RuPay Debit Card' },
      { cell: 'C2', value: 'Total' },
      { cell: 'F2', value: 'Delhi' },
      { cell: 'I2', value: 'Mumbai' },
      { cell: 'L2', value: 'Kolkata' },
      { cell: 'O2', value: 'Chennai' },
      { cell: 'R2', value: 'Bangalore' },
      { cell: 'U2', value: 'Ahmedabad' },
      { cell: 'C3', value: 'Planned' },
      { cell: 'D3', value: 'Achieved' },
      { cell: 'E3', value: 'Pending' },
      { cell: 'F3', value: 'Planned' },
      { cell: 'G3', value: 'Achieved' },
      { cell: 'H3', value: 'Pending' },
      { cell: 'I3', value: 'Planned' },
      { cell: 'J3', value: 'Achieved' },
      { cell: 'K3', value: 'Pending' },
      { cell: 'L3', value: 'Planned' },
      { cell: 'M3', value: 'Achieved' },
      { cell: 'N3', value: 'Pending' },
      { cell: 'O3', value: 'Planned' },
      { cell: 'P3', value: 'Achieved' },
      { cell: 'Q3', value: 'Pending' },
      { cell: 'R3', value: 'Planned' },
      { cell: 'S3', value: 'Achieved' },
      { cell: 'T3', value: 'Pending' },
      { cell: 'U3', value: 'Planned' },
      { cell: 'V3', value: 'Achieved' },
      { cell: 'W3', value: 'Pending' },
      
      
      { cell: 'C14', value: 'RuPay Credit Card' },
      { cell: 'C15', value: 'Total' },
      { cell: 'F15', value: 'Delhi' },
      { cell: 'I15', value: 'Mumbai' },
      { cell: 'L15', value: 'Kolkata' },
      { cell: 'O15', value: 'Chennai' },
      { cell: 'R15', value: 'Bangalore' },
      { cell: 'U15', value: 'Ahmedabad' },
      { cell: 'C16', value: 'Planned' },
      { cell: 'D16', value: 'Achieved' },
      { cell: 'E16', value: 'Pending' },
      { cell: 'F16', value: 'Planned' },
      { cell: 'G16', value: 'Achieved' },
      { cell: 'H16', value: 'Pending' },
      { cell: 'I16', value: 'Planned' },
      { cell: 'J16', value: 'Achieved' },
      { cell: 'K16', value: 'Pending' },
      { cell: 'L16', value: 'Planned' },
      { cell: 'M16', value: 'Achieved' },
      { cell: 'N16', value: 'Pending' },
      { cell: 'O16', value: 'Planned' },
      { cell: 'P16', value: 'Achieved' },
      { cell: 'Q16', value: 'Pending' },
      { cell: 'R16', value: 'Planned' },
      { cell: 'S16', value: 'Achieved' },
      { cell: 'T16', value: 'Pending' },
      { cell: 'U16', value: 'Planned' },
      { cell: 'V16', value: 'Achieved' },
      { cell: 'W16', value: 'Pending' },
    ];
    let data2 = ['RuPay Debit Card','RuPay Credit Card']
    cellsToStyleNew.forEach(({ cell, value }) => {
      const excelCell = worksheet.getCell(cell);
      excelCell.value = value;
      if (data2.includes(value)) {
        excelCell.font = headerStyle.font;
        excelCell.fill = SpecialStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      } else if (value == 'Planned') {
        excelCell.font = headerStyle.font;
        excelCell.fill = plannedstyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      } else if (value == 'Achieved') {
        excelCell.font = headerStyle.font;
        excelCell.fill = achievedstyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      } else if (value == 'Pending') {
        excelCell.font = headerStyle.font;
        excelCell.fill = pendingtyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
      }
      else {
        // excelCell.fill = headerStyle.fill;
        excelCell.alignment = headerStyle.alignment;
        excelCell.border = headerStyle.border;
        excelCell.fill = headerStyle.fill;
        excelCell.font = headerStyle.font;
      }

    });
    let columnCValues = []
    // Define the values to fill in column D
   if (id?.id == "66fe6d36940d43e48e8f7cfc") {
      columnCValues = [100, 30, 30, 30, 30,100, 30, 30, 30, 30]
    }
 
    // Fill values in column D only if the corresponding cell in column C is not empty
    let valueIndex = 0;
    let datatowrite=['Total','18-34 (Minimum 30%)','35+ (Minimum 30%)','Male (Minimum 30%)','Female (Minimum 30%)']
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellB = worksheet.getCell(`B${rowIndex}`);
      if (cellB.value && datatowrite.includes(cellB.value)) {
        const cellD = worksheet.getCell(`C${rowIndex}`);
        cellD.value = columnCValues[valueIndex++];
        if (rowIndex == 4 || rowIndex == 17) {
          cellD.font = headerStyle.font
          cellD.fill = cellStyle.fill;
        } 
        else {
          cellD.font = cellStyle.font;
          cellD.fill = plannedcellstyle.fill;

        }

       
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }

    }


   


    let columnFValues = []
    // Define the values to fill in column H
  if (id?.id == "66fe6d36940d43e48e8f7cfc") {
      columnFValues = [18,5,5,5,5,18,5,5,5,5]
    }
   
    // Fill values in column G only if the corresponding cell in column C is not empty
    let valueIndexG = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellB = worksheet.getCell(`B${rowIndex}`);
      if (cellB.value  && datatowrite.includes(cellB.value)) {
        const cellD = worksheet.getCell(`F${rowIndex}`);
        cellD.value = columnFValues[valueIndexG++];
        if (rowIndex == 4 || rowIndex == 17) {
          cellD.font = headerStyle.font
          cellD.fill = cellStyle.fill;
        }
        else {

          cellD.font = cellStyle.font;


          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    

    let columnIValues = []
    // Define the values to fill in column H
  if (id?.id == "66fe6d36940d43e48e8f7cfc") {
      columnIValues = [18,5,5,5,5,18,5,5,5,5]
    }
   
    // Fill values in column G only if the corresponding cell in column C is not empty
    let valueIndexI = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellB = worksheet.getCell(`B${rowIndex}`);
      if (cellB.value  && datatowrite.includes(cellB.value)) {
        const cellD = worksheet.getCell(`I${rowIndex}`);
        cellD.value = columnIValues[valueIndexI++];
        if (rowIndex == 4 || rowIndex == 17) {
          cellD.font = headerStyle.font
          cellD.fill = cellStyle.fill;
        }
        else {

          cellD.font = cellStyle.font;


          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    

    let columnLValues = []
    // Define the values to fill in column H
  if (id?.id == "66fe6d36940d43e48e8f7cfc") {
      columnLValues = [16,5,5,5,5,16,5,5,5,5]
    }
   
    // Fill values in column G only if the corresponding cell in column C is not empty
    let valueIndexL = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellB = worksheet.getCell(`B${rowIndex}`);
      if (cellB.value && datatowrite.includes(cellB.value)) {
        const cellD = worksheet.getCell(`L${rowIndex}`);
        cellD.value = columnLValues[valueIndexL++];
        if (rowIndex == 4 || rowIndex == 17) {
          cellD.font = headerStyle.font
          cellD.fill = cellStyle.fill;
        }
        else {

          cellD.font = cellStyle.font;


          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }
    

    let columnOValues = []
    // Define the values to fill in column H
  if (id?.id == "66fe6d36940d43e48e8f7cfc") {
      columnOValues = [16,5,5,5,5,16,5,5,5,5]
    }
   
    // Fill values in column G only if the corresponding cell in column C is not empty
    let valueIndexO = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellB = worksheet.getCell(`B${rowIndex}`);
      if (cellB.value  && datatowrite.includes(cellB.value)) {
        const cellD = worksheet.getCell(`O${rowIndex}`);
        cellD.value = columnOValues[valueIndexO++];
        if (rowIndex == 4 || rowIndex == 17) {
          cellD.font = headerStyle.font
          cellD.fill = cellStyle.fill;
        }
        else {

          cellD.font = cellStyle.font;


          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }



    let columnRValues = []
    // Define the values to fill in column H
  if (id?.id == "66fe6d36940d43e48e8f7cfc") {
      columnRValues = [16,5,5,5,5,16,5,5,5,5]
    }
   
    // Fill values in column G only if the corresponding cell in column C is not empty
    let valueIndexR = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellB = worksheet.getCell(`B${rowIndex}`);
      if (cellB.value  && datatowrite.includes(cellB.value)) {
        const cellD = worksheet.getCell(`R${rowIndex}`);
        cellD.value = columnRValues[valueIndexR++];
        if (rowIndex == 4 || rowIndex == 17) {
          cellD.font = headerStyle.font
          cellD.fill = cellStyle.fill;
        }
        else {

          cellD.font = cellStyle.font;


          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }


    let columnUValues = []
    // Define the values to fill in column H
  if (id?.id == "66fe6d36940d43e48e8f7cfc") {
      columnUValues = [16,5,5,5,5,16,5,5,5,5]
    }
   
    // Fill values in column G only if the corresponding cell in column C is not empty
    let valueIndexU = 0;
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellB = worksheet.getCell(`B${rowIndex}`);
      if (cellB.value  && datatowrite.includes(cellB.value)) {
        const cellD = worksheet.getCell(`U${rowIndex}`);
        cellD.value = columnUValues[valueIndexU++];
        if (rowIndex == 4 || rowIndex == 17) {
          cellD.font = headerStyle.font
          cellD.fill = cellStyle.fill;
        }
        else {

          cellD.font = cellStyle.font;


          cellD.fill = plannedcellstyle.fill;
        }
        cellD.alignment = cellStyle.alignment;
        cellD.border = cellStyle.border;
      }
    }

    const isCriterionMet = (answer, criterion) => {
        if (Array.isArray(criterion)) {
          return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
        } else {
          if (Array.isArray(answer)) {
            return answer.includes(criterion) ||
                   (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                   (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
          } else {
            return answer === criterion ||
                   (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                   (criterion === 'Other' && answer?.includes('Other:'));
          }
        }
      };
    
      
     
      
    
      // Updated countMatches function to incorporate the new logic
      const countMatches = (criteria) => {
        return dashboardData.filter(response =>
          criteria.every(criterion =>
            Array.isArray(criterion) ? criterion.some(subCriterion =>
              response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMet(q.answer, subCriterion))
            ) :
              response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMet(q.answer, criterion))
          )
        ).length;
      };
    
      
      const criteriaMap = {
        4: ['Debit Card', 'Delhi'],
        6: ['Debit Card', 'Delhi', ['18-25 years','26-35 years']],
        7: ['Debit Card', 'Delhi', ['36-45 years','45-55 years','55+ years']],
        10: ['Debit Card', 'Delhi','Male'],
        11: ['Debit Card', 'Delhi','Female'],
        17: ['Credit Card', 'Delhi'],
        19: ['Credit Card', 'Delhi', ['18-25 years','26-35 years']],
        20: ['Credit Card', 'Delhi', ['36-45 years','45-55 years','55+ years']],
        23: ['Credit Card', 'Delhi','Male'],
        24: ['Credit Card', 'Delhi','Female'],
      };
    
      Object.keys(criteriaMap).forEach(rowIndex => {
        const criteria = criteriaMap[rowIndex];
        const count = countMatches(criteria);
        const cellH = worksheet.getCell(`G${rowIndex}`);
        cellH.value = count;
        if (rowIndex == 4 || rowIndex == 17) {
          cellH.font = headerStyle.font
          cellH.fill = cellStyle.fill;
          cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
        }
        else{
        cellH.font = cellStyle.font;
        cellH.fill = achievedcellstyle.fill;
        cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
      }
      });
    
      // --------jaipur------- (For Credit Cards)
      const criteriaMapMumbai = {
        4: ['Debit Card', 'Mumbai'],
        6: ['Debit Card', 'Mumbai', ['18-25 years','26-35 years']],
        7: ['Debit Card', 'Mumbai', ['36-45 years','45-55 years','55+ years']],
        10: ['Debit Card', 'Mumbai','Male'],
        11: ['Debit Card', 'Mumbai','Female'],
        17: ['Credit Card', 'Mumbai'],
        19: ['Credit Card', 'Mumbai', ['18-25 years','26-35 years']],
        20: ['Credit Card', 'Mumbai', ['36-45 years','45-55 years','55+ years']],
        23: ['Credit Card', 'Mumbai','Male'],
        24: ['Credit Card', 'Mumbai','Female'],
      };
    
      Object.keys(criteriaMapMumbai).forEach(rowIndex => {
        const criteria = criteriaMapMumbai[rowIndex];
        const count = countMatches(criteria);
        const cellH = worksheet.getCell(`J${rowIndex}`);
        cellH.value = count;
        if (rowIndex == 4 || rowIndex == 17) {
          cellH.font = headerStyle.font
          cellH.fill = cellStyle.fill;
          cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
        }
        else{
        cellH.font = cellStyle.font;
        cellH.fill = achievedcellstyle.fill;
        cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
      }
      });
    
      const criteriaMapKolkata  = {
        4: ['Debit Card', 'Kolkata'],
        6: ['Debit Card', 'Kolkata', ['18-25 years','26-35 years']],
        7: ['Debit Card', 'Kolkata', ['36-45 years','45-55 years','55+ years']],
        10: ['Debit Card', 'Kolkata','Male'],
        11: ['Debit Card', 'Kolkata','Female'],
        17: ['Credit Card', 'Kolkata'],
        19: ['Credit Card', 'Kolkata', ['18-25 years','26-35 years']],
        20: ['Credit Card', 'Kolkata', ['36-45 years','45-55 years','55+ years']],
        23: ['Credit Card', 'Kolkata','Male'],
        24: ['Credit Card', 'Kolkata','Female'],
      };
    
      Object.keys(criteriaMapKolkata).forEach(rowIndex => {
        const criteria = criteriaMapKolkata[rowIndex];
        const count = countMatches(criteria);
        const cellH = worksheet.getCell(`M${rowIndex}`);
        cellH.value = count;
        if (rowIndex == 4 || rowIndex == 17) {
          cellH.font = headerStyle.font
          cellH.fill = cellStyle.fill;
          cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
        }
        else{
        cellH.font = cellStyle.font;
        cellH.fill = achievedcellstyle.fill;
        cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
      }
      });

      const criteriaMapChennai  = {
        4: ['Debit Card', 'Chennai'],
        6: ['Debit Card', 'Chennai', ['18-25 years','26-35 years']],
        7: ['Debit Card', 'Chennai', ['36-45 years','45-55 years','55+ years']],
        10: ['Debit Card', 'Chennai','Male'],
        11: ['Debit Card', 'Chennai','Female'],
        17: ['Credit Card', 'Chennai'],
        19: ['Credit Card', 'Chennai', ['18-25 years','26-35 years']],
        20: ['Credit Card', 'Chennai', ['36-45 years','45-55 years','55+ years']],
        23: ['Credit Card', 'Chennai','Male'],
        24: ['Credit Card', 'Chennai','Female'],
      };
    
      Object.keys(criteriaMapChennai).forEach(rowIndex => {
        const criteria = criteriaMapChennai[rowIndex];
        const count = countMatches(criteria);
        const cellH = worksheet.getCell(`P${rowIndex}`);
        cellH.value = count;
        if (rowIndex == 4 || rowIndex == 17) {
          cellH.font = headerStyle.font
          cellH.fill = cellStyle.fill;
          cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
        }
        else{
        cellH.font = cellStyle.font;
        cellH.fill = achievedcellstyle.fill;
        cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
      }
      });
    
      const criteriaMapBangalore  = {
        4: ['Debit Card', 'Bangalore'],
        6: ['Debit Card', 'Bangalore', ['18-25 years','26-35 years']],
        7: ['Debit Card', 'Bangalore', ['36-45 years','45-55 years','55+ years']],
        10: ['Debit Card', 'Bangalore','Male'],
        11: ['Debit Card', 'Bangalore','Female'],
        17: ['Credit Card', 'Bangalore'],
        19: ['Credit Card', 'Bangalore', ['18-25 years','26-35 years']],
        20: ['Credit Card', 'Bangalore', ['36-45 years','45-55 years','55+ years']],
        23: ['Credit Card', 'Bangalore','Male'],
        24: ['Credit Card', 'Bangalore','Female'],
      };
    
      Object.keys(criteriaMapBangalore).forEach(rowIndex => {
        const criteria = criteriaMapBangalore[rowIndex];
        const count = countMatches(criteria);
        const cellH = worksheet.getCell(`S${rowIndex}`);
        cellH.value = count;
        if (rowIndex == 4 || rowIndex == 17) {
          cellH.font = headerStyle.font
          cellH.fill = cellStyle.fill;
          cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
        }
        else{
        cellH.font = cellStyle.font;
        cellH.fill = achievedcellstyle.fill;
        cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
      }
      });

      const criteriaMapAhmedabad  = {
        4: ['Debit Card', 'Ahmedabad'],
        6: ['Debit Card', 'Ahmedabad', ['18-25 years','26-35 years']],
        7: ['Debit Card', 'Ahmedabad', ['36-45 years','45-55 years','55+ years']],
        10: ['Debit Card', 'Ahmedabad','Male'],
        11: ['Debit Card', 'Ahmedabad','Female'],
        17: ['Credit Card', 'Ahmedabad'],
        19: ['Credit Card', 'Ahmedabad', ['18-25 years','26-35 years']],
        20: ['Credit Card', 'Ahmedabad', ['36-45 years','45-55 years','55+ years']],
        23: ['Credit Card', 'Ahmedabad','Male'],
        24: ['Credit Card', 'Ahmedabad','Female'],
      };
    
      Object.keys(criteriaMapAhmedabad).forEach(rowIndex => {
        const criteria = criteriaMapAhmedabad[rowIndex];
        const count = countMatches(criteria);
        const cellH = worksheet.getCell(`V${rowIndex}`);
        cellH.value = count;
        if (rowIndex == 4 || rowIndex == 17) {
          cellH.font = headerStyle.font
          cellH.fill = cellStyle.fill;
          cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
        }
        else{
        cellH.font = cellStyle.font;
        cellH.fill = achievedcellstyle.fill;
        cellH.alignment = cellStyle.alignment;
        cellH.border = cellStyle.border;
      }
      });



    const calculateAndSetSum = (columnLetter, sumRange, targetRow) => {
        let sum = 0;
        for (let rowIndex = sumRange.start; rowIndex <= sumRange.end; rowIndex++) {
          sum += worksheet.getCell(`${columnLetter}${rowIndex}`).value || 0;
        }
        worksheet.getCell(`${columnLetter}${targetRow}`).value = sum;
        worksheet.getCell(`${columnLetter}${targetRow}`).font = headerStyle.font;
        worksheet.getCell(`${columnLetter}${targetRow}`).fill = achievedcellstyle.fill;
        worksheet.getCell(`${columnLetter}${targetRow}`).alignment = cellStyle.alignment;
        worksheet.getCell(`${columnLetter}${targetRow}`).border = cellStyle.border;
      };
  
      // Define the range of rows to sum (rows 4 to 13)
      const sumRowRange = { start: 6, end: 11 };
      const sumRowRange2 = { start: 19, end: 24 };
  
      
      const columnsToSum = ['G', 'J','M','P','S','V']
  
      
      // columnsToSum.forEach(column => {
      //   calculateAndSetSum(column, sumRowRange, 4); 
      // });

       
      // columnsToSum.forEach(column => {
      //   calculateAndSetSum(column, sumRowRange2, 17); 
      // });

      // TOTAL  ACHIVED VALUES IN D 
      for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
        const cellC = worksheet.getCell(`B${rowIndex}`);
        if (cellC.value && datatowrite.includes(cellC.value)) {
          const sum = ['G','J','M','P','S','V']
            .map(col => worksheet.getCell(`${col}${rowIndex}`).value || 0)
            .reduce((acc, value) => acc + value, 0);
  
          const cellE = worksheet.getCell(`D${rowIndex}`);
          cellE.value = sum;
          if (rowIndex == 4 || rowIndex == 17) {
            cellE.font = headerStyle.font
            cellE.fill = cellStyle.fill;
          }
          else {
            cellE.font = cellStyle.font;
  
            cellE.fill = achievedcellstyle.fill;
          }
  
          cellE.alignment = cellStyle.alignment;
          cellE.border = cellStyle.border;
        }
      }
  // TOTAL PENDING VALUES


      const columnsFinal = [
        { result: 'E', from: 'C', subtract: 'D' },
        { result: 'H', from: 'F', subtract: 'G' },
        { result: 'K', from: 'I', subtract: 'J' },
        { result: 'N', from: 'L', subtract: 'M' },
        { result: 'Q', from: 'O', subtract: 'P' },
        { result: 'T', from: 'R', subtract: 'S' },
        { result: 'W', from: 'U', subtract: 'V' },
       
      ];
  
      columnsFinal.forEach(({ result, from, subtract }) => {
        for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
          const cellC = worksheet.getCell(`B${rowIndex}`);
          if (cellC.value  && datatowrite.includes(cellC.value)) {
            const cellFrom = worksheet.getCell(`${from}${rowIndex}`);
            const cellSubtract = worksheet.getCell(`${subtract}${rowIndex}`);
            const cellToFill = worksheet.getCell(`${result}${rowIndex}`);
            const valueFrom = cellFrom.value || 0;
            const valueSubtract = cellSubtract.value || 0;
            cellToFill.value = valueFrom - valueSubtract;
            if (rowIndex == 4 || rowIndex == 17) {
              cellToFill.font = headerStyle.font
              cellToFill.fill = cellStyle.fill;
            }
            else {
              
              cellToFill.font = cellStyle.font;
  
              cellToFill.fill = pendingcellstyle.fill;
            }
            cellToFill.alignment = cellStyle.alignment;
            cellToFill.border = cellStyle.border;
          }
        }
      });
  
  
  


  if (id?.id == "66fe6d36940d43e48e8f7cfc") {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'RupayCard.xlsx');
      setLoader(false);
    }
   

  };
 
const handleDownloadFastag = async () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  worksheet.getColumn(1).width = 13;
  worksheet.getColumn(2).width = 25;
  worksheet.getColumn(3).width = 13;
  worksheet.getColumn(4).width = 13;
  worksheet.getColumn(5).width = 13;
  worksheet.getColumn(6).width = 13
  worksheet.getColumn(7).width = 13;
  worksheet.getColumn(8).width = 13;
  worksheet.getColumn(9).width = 13;
  worksheet.getColumn(10).width = 13;
  worksheet.getColumn(11).width = 13;
  worksheet.getColumn(12).width = 13;
  worksheet.getColumn(13).width = 13;
  worksheet.getColumn(14).width = 13;
  worksheet.getColumn(15).width = 13;
  worksheet.getColumn(16).width = 13;
  worksheet.getColumn(17).width = 13;
  worksheet.getColumn(18).width = 13;
  worksheet.getColumn(19).width = 13;
  worksheet.getColumn(20).width = 13;
  worksheet.getColumn(21).width = 13;
  worksheet.getColumn(22).width = 13;
  worksheet.getColumn(23).width = 13;

  // Merge cells for headers
  worksheet.mergeCells('A3:A3');
  worksheet.mergeCells('A6:A7');
  worksheet.mergeCells('A10:A10');
 
 
  worksheet.mergeCells('B3:B3');
  worksheet.mergeCells('B4:B4');
  worksheet.mergeCells('B5:B5');
  worksheet.mergeCells('B6:B6');
  worksheet.mergeCells('B7:B7');
  worksheet.mergeCells('B8:B8');
  worksheet.mergeCells('B9:B9');
  worksheet.mergeCells('B10:B10');
  worksheet.mergeCells('B12:B12');
  worksheet.mergeCells('B13:B13');
  worksheet.mergeCells('B14:B14');
  worksheet.mergeCells('B16:B16');
  worksheet.mergeCells('B17:B17');
  worksheet.mergeCells('B18:B18');
  worksheet.mergeCells('B19:B19');
  worksheet.mergeCells('B20:B20');
  worksheet.mergeCells('B21:B21');
  worksheet.mergeCells('B22:B22');
  worksheet.mergeCells('B23:B23');
  worksheet.mergeCells('B24:B24');


 
  // Merge cells for new headers
  worksheet.mergeCells('C1:W1');
  worksheet.mergeCells('C2:E2');
  worksheet.mergeCells('F2:H2');
  worksheet.mergeCells('I2:K2');
  worksheet.mergeCells('L2:N2');
  worksheet.mergeCells('O2:Q2');
  worksheet.mergeCells('R2:T2');
  worksheet.mergeCells('U2:W2');

 

  // Define styles
  const headerStyle = {
    font: { bold: true, color: { argb: '00000000' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'fffed9' } },
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    }
  };

  const plannedstyle = {
    font: { bold: false, color: { argb: '00000000' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'bdd3f9' } },
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    }
  };
  const cellStyle = {
    font: { bold: false, color: { argb: '00000000' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    }
  };
  const SpecialStyle = {
    font: { bold: false, color: { argb: '00000000' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'e3e3e3' } },
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    }
  };
  const plannedcellstyle = {
    font: { bold: false, color: { argb: '00000000' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    }
  };
  const achievedstyle = {
    font: { color: { argb: '00000000' }, size: 12, },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '92c67c' } },
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    }
  };
  const achievedcellstyle = {
    font: { bold: false, color: { argb: '00000000' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    }
  };
  const pendingtyle = {
    font: { color: { argb: '00000000' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ffcc98' } },
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    }
  };
  const pendingcellstyle = {
    font: { bold: false, color: { argb: '00000000' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } },
    alignment: { horizontal: 'center', vertical: 'middle' },
    border: {
      top: { style: 'thin', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FF000000' } },
      bottom: { style: 'thin', color: { argb: 'FF000000' } },
      right: { style: 'thin', color: { argb: 'FF000000' } }
    }
  };

  const cellsToStyleA = [
    { cell: 'A3', value: 'Factors' },
    // { cell: 'B4', value: 'Delhi' },
    { cell: 'A6', value: 'Age' },
    { cell: 'A10', value: 'Gender' },

    
  ];

  cellsToStyleA.forEach(({ cell, value }) => {


    const excelCell = worksheet.getCell(cell);
    excelCell.value = value;
    if (value == "Factors") {
      excelCell.font = headerStyle.font;
      excelCell.fill = headerStyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    }else  if (value == "Age") {
      excelCell.font = headerStyle.font;
      excelCell.fill = headerStyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    }else  if (value == "Gender") {
      excelCell.font = headerStyle.font;
      excelCell.fill = headerStyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    }
    else {
      // excelCell.fill = headerStyle.fill;
      excelCell.font = headerStyle.font;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
      excelCell.fill = cellStyle.fill;
    }
  });

  // Define cell values and styles for column B
  const cellsToStyleB = [
    { cell: 'B3', value: 'Cities' },
    { cell: 'B4', value: 'Total' },
    // { cell: 'B4', value: 'Delhi' },
    // { cell: 'B5', value: 'Age' },
    { cell: 'B6', value: '18-34 (Minimum 30%)' },
    { cell: 'B7', value: '35+ (Minimum 30%)' },
    // { cell: 'B9', value: 'Gender' },
    { cell: 'B10', value: 'Note: No Quotas By gender' },
   

  
  

  ];

  cellsToStyleB.forEach(({ cell, value }) => {


    const excelCell = worksheet.getCell(cell);
    excelCell.value = value;
    if (value == "Cities") {
      excelCell.font = headerStyle.font;
      excelCell.fill = headerStyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    }else  if (value == "Total"|| value == "Note: No Quotas By gender") {
      excelCell.font = headerStyle.font;
      excelCell.fill = SpecialStyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;}
    else  if (value == "Age") {
      excelCell.font = headerStyle.font;
      excelCell.fill = headerStyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    }else  if (value == "Gender") {
      excelCell.font = headerStyle.font;
      excelCell.fill = headerStyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    }
    else {
      // excelCell.fill = headerStyle.fill;
      excelCell.font = headerStyle.font;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
      excelCell.fill = cellStyle.fill;
    }
  });

  // Define cell values and styles for column C


  

 

  // Define cell values and styles for new headers
  const cellsToStyleNew = [
    { cell: 'C1', value: 'NETC FASTag' },
    { cell: 'C2', value: 'Total' },
    { cell: 'F2', value: 'Delhi' },
    { cell: 'I2', value: 'Mumbai' },
    { cell: 'L2', value: 'Kolkata' },
    { cell: 'O2', value: 'Chennai' },
    { cell: 'R2', value: 'Bangalore' },
    { cell: 'U2', value: 'Ahmedabad' },
    { cell: 'C3', value: 'Planned' },
    { cell: 'D3', value: 'Achieved' },
    { cell: 'E3', value: 'Pending' },
    { cell: 'F3', value: 'Planned' },
    { cell: 'G3', value: 'Achieved' },
    { cell: 'H3', value: 'Pending' },
    { cell: 'I3', value: 'Planned' },
    { cell: 'J3', value: 'Achieved' },
    { cell: 'K3', value: 'Pending' },
    { cell: 'L3', value: 'Planned' },
    { cell: 'M3', value: 'Achieved' },
    { cell: 'N3', value: 'Pending' },
    { cell: 'O3', value: 'Planned' },
    { cell: 'P3', value: 'Achieved' },
    { cell: 'Q3', value: 'Pending' },
    { cell: 'R3', value: 'Planned' },
    { cell: 'S3', value: 'Achieved' },
    { cell: 'T3', value: 'Pending' },
    { cell: 'U3', value: 'Planned' },
    { cell: 'V3', value: 'Achieved' },
    { cell: 'W3', value: 'Pending' },
   ];
  let data2 = ['NETC FASTag']
  cellsToStyleNew.forEach(({ cell, value }) => {
    const excelCell = worksheet.getCell(cell);
    excelCell.value = value;
    if (data2.includes(value)) {
      excelCell.font = headerStyle.font;
      excelCell.fill = SpecialStyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    } else if (value == 'Planned') {
      excelCell.font = headerStyle.font;
      excelCell.fill = plannedstyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    } else if (value == 'Achieved') {
      excelCell.font = headerStyle.font;
      excelCell.fill = achievedstyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    } else if (value == 'Pending') {
      excelCell.font = headerStyle.font;
      excelCell.fill = pendingtyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
    }
    else {
      // excelCell.fill = headerStyle.fill;
      excelCell.alignment = headerStyle.alignment;
      excelCell.border = headerStyle.border;
      excelCell.fill = headerStyle.fill;
      excelCell.font = headerStyle.font;
    }

  });
  let columnCValues = []
  // Define the values to fill in column D
 if (id?.id == "66fba6aa15224501f9cfb785") {
    columnCValues = [100, 30, 30]
  }

  // Fill values in column D only if the corresponding cell in column C is not empty
  let valueIndex = 0;
  let datatowrite=['Total','18-34 (Minimum 30%)','35+ (Minimum 30%)','Male (Minimum 30%)','Female (Minimum 30%)']
  for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
    const cellB = worksheet.getCell(`B${rowIndex}`);
    if (cellB.value && datatowrite.includes(cellB.value)) {
      const cellD = worksheet.getCell(`C${rowIndex}`);
      cellD.value = columnCValues[valueIndex++];
      if (rowIndex == 4 || rowIndex == 17) {
        cellD.font = headerStyle.font
        cellD.fill = cellStyle.fill;
      } 
      else {
        cellD.font = cellStyle.font;
        cellD.fill = plannedcellstyle.fill;

      }

     
      cellD.alignment = cellStyle.alignment;
      cellD.border = cellStyle.border;
    }

  }


 


  let columnFValues = []
  // Define the values to fill in column H
if (id?.id == "66fba6aa15224501f9cfb785") {
    columnFValues = [18,5,5]
  }
 
  // Fill values in column G only if the corresponding cell in column C is not empty
  let valueIndexG = 0;
  for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
    const cellB = worksheet.getCell(`B${rowIndex}`);
    if (cellB.value  && datatowrite.includes(cellB.value)) {
      const cellD = worksheet.getCell(`F${rowIndex}`);
      cellD.value = columnFValues[valueIndexG++];
      if (rowIndex == 4 || rowIndex == 17) {
        cellD.font = headerStyle.font
        cellD.fill = cellStyle.fill;
      }
      else {

        cellD.font = cellStyle.font;


        cellD.fill = plannedcellstyle.fill;
      }
      cellD.alignment = cellStyle.alignment;
      cellD.border = cellStyle.border;
    }
  }

  

  let columnIValues = []
  // Define the values to fill in column H
if (id?.id == "66fba6aa15224501f9cfb785") {
    columnIValues = [18,5,5]
  }
 
  // Fill values in column G only if the corresponding cell in column C is not empty
  let valueIndexI = 0;
  for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
    const cellB = worksheet.getCell(`B${rowIndex}`);
    if (cellB.value  && datatowrite.includes(cellB.value)) {
      const cellD = worksheet.getCell(`I${rowIndex}`);
      cellD.value = columnIValues[valueIndexI++];
      if (rowIndex == 4 || rowIndex == 17) {
        cellD.font = headerStyle.font
        cellD.fill = cellStyle.fill;
      }
      else {

        cellD.font = cellStyle.font;


        cellD.fill = plannedcellstyle.fill;
      }
      cellD.alignment = cellStyle.alignment;
      cellD.border = cellStyle.border;
    }
  }

  

  let columnLValues = []
  // Define the values to fill in column H
if (id?.id == "66fba6aa15224501f9cfb785") {
    columnLValues = [16,5,5]
  }
 
  // Fill values in column G only if the corresponding cell in column C is not empty
  let valueIndexL = 0;
  for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
    const cellB = worksheet.getCell(`B${rowIndex}`);
    if (cellB.value && datatowrite.includes(cellB.value)) {
      const cellD = worksheet.getCell(`L${rowIndex}`);
      cellD.value = columnLValues[valueIndexL++];
      if (rowIndex == 4 || rowIndex == 17) {
        cellD.font = headerStyle.font
        cellD.fill = cellStyle.fill;
      }
      else {

        cellD.font = cellStyle.font;


        cellD.fill = plannedcellstyle.fill;
      }
      cellD.alignment = cellStyle.alignment;
      cellD.border = cellStyle.border;
    }
  }
  

  let columnOValues = []
  // Define the values to fill in column H
if (id?.id == "66fba6aa15224501f9cfb785") {
    columnOValues = [16,5,5]
  }
 
  // Fill values in column G only if the corresponding cell in column C is not empty
  let valueIndexO = 0;
  for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
    const cellB = worksheet.getCell(`B${rowIndex}`);
    if (cellB.value  && datatowrite.includes(cellB.value)) {
      const cellD = worksheet.getCell(`O${rowIndex}`);
      cellD.value = columnOValues[valueIndexO++];
      if (rowIndex == 4 || rowIndex == 17) {
        cellD.font = headerStyle.font
        cellD.fill = cellStyle.fill;
      }
      else {

        cellD.font = cellStyle.font;


        cellD.fill = plannedcellstyle.fill;
      }
      cellD.alignment = cellStyle.alignment;
      cellD.border = cellStyle.border;
    }
  }



  let columnRValues = []
  // Define the values to fill in column H
if (id?.id == "66fba6aa15224501f9cfb785") {
    columnRValues = [16,5,5]
  }
 
  // Fill values in column G only if the corresponding cell in column C is not empty
  let valueIndexR = 0;
  for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
    const cellB = worksheet.getCell(`B${rowIndex}`);
    if (cellB.value  && datatowrite.includes(cellB.value)) {
      const cellD = worksheet.getCell(`R${rowIndex}`);
      cellD.value = columnRValues[valueIndexR++];
      if (rowIndex == 4 || rowIndex == 17) {
        cellD.font = headerStyle.font
        cellD.fill = cellStyle.fill;
      }
      else {

        cellD.font = cellStyle.font;


        cellD.fill = plannedcellstyle.fill;
      }
      cellD.alignment = cellStyle.alignment;
      cellD.border = cellStyle.border;
    }
  }


  let columnUValues = []
  // Define the values to fill in column H
if (id?.id == "66fba6aa15224501f9cfb785") {
    columnUValues = [16,5,5]
  }
 
  // Fill values in column G only if the corresponding cell in column C is not empty
  let valueIndexU = 0;
  for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
    const cellB = worksheet.getCell(`B${rowIndex}`);
    if (cellB.value  && datatowrite.includes(cellB.value)) {
      const cellD = worksheet.getCell(`U${rowIndex}`);
      cellD.value = columnUValues[valueIndexU++];
      if (rowIndex == 4 || rowIndex == 17) {
        cellD.font = headerStyle.font
        cellD.fill = cellStyle.fill;
      }
      else {

        cellD.font = cellStyle.font;


        cellD.fill = plannedcellstyle.fill;
      }
      cellD.alignment = cellStyle.alignment;
      cellD.border = cellStyle.border;
    }
  }

  const isCriterionMet = (answer, criterion) => {
      if (Array.isArray(criterion)) {
        return criterion.some(crit => Array.isArray(answer) ? answer.includes(crit) : answer === crit);
      } else {
        if (Array.isArray(answer)) {
          return answer.includes(criterion) ||
                 (criterion === 'Rs. 10-15 lakh per annum' && answer.includes('More than Rs. 15 lakh per annum')) ||
                 (criterion === 'Other' && answer.some(ans => ans.includes('Other:')));
        } else {
          return answer === criterion ||
                 (criterion === 'Rs. 10-15 lakh per annum' && answer === 'More than Rs. 15 lakh per annum') ||
                 (criterion === 'Other' && answer?.includes('Other:'));
        }
      }
    };
  
    
  
    // Updated countMatches function to incorporate the new logic
    const countMatches = (criteria) => {
      return dashboardData.filter(response =>
        criteria.every(criterion =>
          Array.isArray(criterion) ? criterion.some(subCriterion =>
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMet(q.answer, subCriterion))
          ) :
            response.questions.some(q => isQuestionInCheckArray(q.question) && isCriterionMet(q.answer, criterion))
        )
      ).length;
    };
  
    
    const criteriaMap = {
      4: ['Yes', 'Delhi'],
      6: ['Yes', 'Delhi', ['18-25 years','26-35 years']],
      7: ['Yes', 'Delhi', ['36-45 years','45-55 years','55+ years']],
    };
  
    Object.keys(criteriaMap).forEach(rowIndex => {
      const criteria = criteriaMap[rowIndex];
      const count = countMatches(criteria);
      const cellH = worksheet.getCell(`G${rowIndex}`);
      cellH.value = count;
      if (rowIndex == 4) {
        cellH.font = headerStyle.font
        cellH.fill = cellStyle.fill;
        cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
      }
      else{
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    }
    });
  
    // --------jaipur------- (For Credit Cards)
    const criteriaMapMumbai = {
      4: ['Yes', 'Mumbai'],
      6: ['Yes', 'Mumbai', ['18-25 years','26-35 years']],
      7: ['Yes', 'Mumbai', ['36-45 years','45-55 years','55+ years']],
    };
  
    Object.keys(criteriaMapMumbai).forEach(rowIndex => {
      const criteria = criteriaMapMumbai[rowIndex];
      const count = countMatches(criteria);
      const cellH = worksheet.getCell(`J${rowIndex}`);
      cellH.value = count; 
      if (rowIndex == 4) {
        cellH.font = headerStyle.font
        cellH.fill = cellStyle.fill;
        cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
      }
      else{
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    }
  
    });
  
    const criteriaMapKolkata  = {
      4: ['Yes', 'Kolkata'],
      6: ['Yes', 'Kolkata', ['18-25 years','26-35 years']],
      7: ['Yes', 'Kolkata', ['36-45 years','45-55 years','55+ years']],
    };
  
    Object.keys(criteriaMapKolkata).forEach(rowIndex => {
      const criteria = criteriaMapKolkata[rowIndex];
      const count = countMatches(criteria);
      const cellH = worksheet.getCell(`M${rowIndex}`);
      cellH.value = count;
      if (rowIndex == 4) {
        cellH.font = headerStyle.font
        cellH.fill = cellStyle.fill;
        cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
      }
      else{
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    }
    });

    const criteriaMapChennai  = {
      4: ['Yes', 'Chennai'],
      6: ['Yes', 'Chennai', ['18-25 years','26-35 years']],
      7: ['Yes', 'Chennai', ['36-45 years','45-55 years','55+ years']],
    };
  
    Object.keys(criteriaMapChennai).forEach(rowIndex => {
      const criteria = criteriaMapChennai[rowIndex];
      const count = countMatches(criteria);
      const cellH = worksheet.getCell(`P${rowIndex}`);
      cellH.value = count;
      if (rowIndex == 4) {
        cellH.font = headerStyle.font
        cellH.fill = cellStyle.fill;
        cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
      }
      else{
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    }
    });
  
    const criteriaMapBangalore  = {
      4: ['Yes', 'Bangalore'],
      6: ['Yes', 'Bangalore', ['18-25 years','26-35 years']],
      7: ['Yes', 'Bangalore', ['36-45 years','45-55 years','55+ years']],
    };
  
    Object.keys(criteriaMapBangalore).forEach(rowIndex => {
      const criteria = criteriaMapBangalore[rowIndex];
      const count = countMatches(criteria);
      const cellH = worksheet.getCell(`S${rowIndex}`);
      cellH.value = count;
      if (rowIndex == 4) {
        cellH.font = headerStyle.font
        cellH.fill = cellStyle.fill;
        cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
      }
      else{
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    }
    });

    const criteriaMapAhmedabad  = {
      4: ['Yes', 'Ahmedabad'],
      6: ['Yes', 'Ahmedabad', ['18-25 years','26-35 years']],
      7: ['Yes', 'Ahmedabad', ['36-45 years','45-55 years','55+ years']],
    };
  
    Object.keys(criteriaMapAhmedabad).forEach(rowIndex => {
      const criteria = criteriaMapAhmedabad[rowIndex];
      const count = countMatches(criteria);
      const cellH = worksheet.getCell(`V${rowIndex}`);
      cellH.value = count;
      if (rowIndex == 4) {
        cellH.font = headerStyle.font
        cellH.fill = cellStyle.fill;
        cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
      }
      else{
      cellH.font = cellStyle.font;
      cellH.fill = achievedcellstyle.fill;
      cellH.alignment = cellStyle.alignment;
      cellH.border = cellStyle.border;
    }
    });



  const calculateAndSetSum = (columnLetter, sumRange, targetRow) => {
      let sum = 0;
      for (let rowIndex = sumRange.start; rowIndex <= sumRange.end; rowIndex++) {
        sum += worksheet.getCell(`${columnLetter}${rowIndex}`).value || 0;
      }
      worksheet.getCell(`${columnLetter}${targetRow}`).value = sum;
      worksheet.getCell(`${columnLetter}${targetRow}`).font = headerStyle.font;
      worksheet.getCell(`${columnLetter}${targetRow}`).fill = achievedcellstyle.fill;
      worksheet.getCell(`${columnLetter}${targetRow}`).alignment = cellStyle.alignment;
      worksheet.getCell(`${columnLetter}${targetRow}`).border = cellStyle.border;
    };

    // Define the range of rows to sum (rows 4 to 13)
    const sumRowRange = { start: 6, end: 7 };
   

    
    const columnsToSum = ['G', 'J','M','P','S','V']

    
    // columnsToSum.forEach(column => {
    //   calculateAndSetSum(column, sumRowRange, 4); 
    // });

     
   

    // TOTAL  ACHIVED VALUES IN D 
    for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
      const cellC = worksheet.getCell(`B${rowIndex}`);
      if (cellC.value && datatowrite.includes(cellC.value)) {
        const sum = ['G','J','M','P','S','V']
          .map(col => worksheet.getCell(`${col}${rowIndex}`).value || 0)
          .reduce((acc, value) => acc + value, 0);

        const cellE = worksheet.getCell(`D${rowIndex}`);
        cellE.value = sum;
        if (rowIndex == 4 ) {
          cellE.font = headerStyle.font
          cellE.fill = cellStyle.fill;
        }
        else {
          cellE.font = cellStyle.font;

          cellE.fill = achievedcellstyle.fill;
        }

        cellE.alignment = cellStyle.alignment;
        cellE.border = cellStyle.border;
      }
    }
// TOTAL PENDING VALUES


    const columnsFinal = [
      { result: 'E', from: 'C', subtract: 'D' },
      { result: 'H', from: 'F', subtract: 'G' },
      { result: 'K', from: 'I', subtract: 'J' },
      { result: 'N', from: 'L', subtract: 'M' },
      { result: 'Q', from: 'O', subtract: 'P' },
      { result: 'T', from: 'R', subtract: 'S' },
      { result: 'W', from: 'U', subtract: 'V' },
     
    ];

    columnsFinal.forEach(({ result, from, subtract }) => {
      for (let rowIndex = 4; rowIndex <= worksheet.rowCount; rowIndex++) {
        const cellC = worksheet.getCell(`B${rowIndex}`);
        if (cellC.value  && datatowrite.includes(cellC.value)) {
          const cellFrom = worksheet.getCell(`${from}${rowIndex}`);
          const cellSubtract = worksheet.getCell(`${subtract}${rowIndex}`);
          const cellToFill = worksheet.getCell(`${result}${rowIndex}`);
          const valueFrom = cellFrom.value || 0;
          const valueSubtract = cellSubtract.value || 0;
          cellToFill.value = valueFrom - valueSubtract;
          if (rowIndex == 4 ) {
            cellToFill.font = headerStyle.font
            cellToFill.fill = cellStyle.fill;
          }
          else {
            
            cellToFill.font = cellStyle.font;

            cellToFill.fill = pendingcellstyle.fill;
          }
          cellToFill.alignment = cellStyle.alignment;
          cellToFill.border = cellStyle.border;
        }
      }
    });





if (id?.id == "66fba6aa15224501f9cfb785") {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    saveAs(blob, 'FastTag.xlsx');
    setLoader(false);
  }
 

};

  return (
    <>
      {loader && <Loader />}
      <div className="excelcontainer">
        <div className="excelcard">
          
          {id?.id == "66fe6d36940d43e48e8f7cfc" &&
            <> <label>RuPay Card Qcc </label>
              <button className="btn btn-primary" onClick={() => handleDownloadRupay()}>Download</button>
            </>}
          <br />
          {id?.id == "66fba6aa15224501f9cfb785" &&
    <> <label>Fast-Tag Qcc</label>
      <button className="btn btn-primary" onClick={() => handleDownloadFastag()}>Download</button></>}
  <br />
        
        </div>

      </div>
    </>
  );
};

export default RuparReport;


  
  
  