import React, { useState, useEffect, useRef } from 'react';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import RewardUseModal from '../common/rewardUserModal'

import { Redirect, Link, useHistory } from "react-router-dom";
import Loader2 from '../../utils/loader2/loader2'
import Moment from 'react-moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { CSVLink } from "react-csv";

const Users = (props) => {
    const api = new API()
    const history = new useHistory()
    const csvLink = useRef()
    const [rewardUser, setRewardUSer] = useState([])
    const [loader2, setLoader2] = useState(true)
    const [userBasicDetails, setUserBasicDetails] = useState([])
    const [generate, setGenerate] = useState(false)
    const [dataForExcelDownload, setDataForExcelDownload] = useState([])
    const [excelFileName, setExcelFileName] = useState("")
    const [generateExcelUserId, setGenerateExcelUserId] = useState()
    const [isGenerateExcelForAllUser, setIsGenerateExcelForAllUser] = useState(false)
    const [pageCount, setPageCount] = useState(0)
    const [gender,setGender]=useState();
    const [type,setType]=useState();
    

    let adminToken = getLocalDataAsObject("adminToken")
    if(!adminToken) {
        history.push("/")
    }
    let headers = [
        "firstName",
        "lastName",
        "city",
        "date",
        "dob",
        "email",
        "gender",
        "mobile",
        "referCode",
        "completedSurvey",
        "pointAvailable",
        "pointEarned",
        "pointRedeemed",
        "profilePercent",
        "registerWithReferCodeUser"];

    useEffect(() => {

        getUserBasicsDetails()
    }, [])

    
    const getUserBasicsDetails = async (count) => {
       
        let data
        (count==undefined)?data={}:
         data={
            type:type,
            gender:gender,
            pageNumber:count,
            pageSize:10
        }        
        const result = await api.post(config.adminDashboardGetUserBasicsDetails,data)
        if (result.code === 200) {
            setLoader2(false)
            setUserBasicDetails(result.data)

        }
    }

const sendPageCount=(value)=>{
if(value==='-1'){
    
    setPageCount(pageCount-1)
   
    getUserBasicsDetails(pageCount-1);
}
if(value==='1'){
    
    setPageCount(pageCount+1)
   
    getUserBasicsDetails(pageCount+1);
}
}

    const deleteUser = async (user) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: `Are you sure to delete user ${user}`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        const result = await api.post(config.adminDashboardDeleteUser, { email: user })
                        if (result && result.code === 200) {
                            getUserBasicsDetails(0)
                            toast.success("User deleted successfully")
                        }
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.warning("User Not Deleted")
                    }
                }
            ]
        });
    }
    const downloadSurveyExcel = (user) => {
        setIsGenerateExcelForAllUser(false)
       
        setGenerateExcelUserId(user.UserDetails[0]._id)

        let  arr = []
        setExcelFileName(user.UserDetails[0].email)
        arr.push(user.UserDetails[0].firstName)
        arr.push(user.UserDetails[0].lastName)
        arr.push(user.UserDetails[0].city)
        arr.push(user.UserDetails[0].date)
        arr.push(user.UserDetails[0].dob)
        arr.push(user.UserDetails[0].email)
        arr.push(user.UserDetails[0].gender)
        arr.push(user.UserDetails[0].mobile)
        arr.push(user.UserDetails[0].referCode)
        arr.push(user.data[0].completedSurvey)
        arr.push(user.data[0].pointAvailable)
        arr.push(user.data[0].pointEarned)
        arr.push(user.data[0].pointRedeemed)
        arr.push(user.data[0].profilePercent)
        setDataForExcelDownload([...dataForExcelDownload, arr])
        setGenerate(true)
        
    }
    const downloadSurveyExcelAllUser = () => {
        setGenerateExcelUserId()
        
       
        
        userBasicDetails.map((user) => {
            let referedUserEmail = []
            userBasicDetails.map((referedUser)=>{
                if(referedUser.UserDetails[0]&&referedUser.UserDetails[0].refferalBy&&referedUser.UserDetails[0].refferalBy==user.UserDetails[0]&&user.UserDetails[0].referCode){
                    referedUserEmail.push(referedUser.UserDetails[0].email)
                }
            })
            const  arr = []
            arr.push(user.UserDetails[0]&&user.UserDetails[0].firstName)
            arr.push(user.UserDetails[0]&&user.UserDetails[0].lastName)
            arr.push(user.UserDetails[0]&&user.UserDetails[0].city)
            arr.push(user.UserDetails[0]&&user.UserDetails[0].date)
            arr.push(user.UserDetails[0]&&user.UserDetails[0].dob)
            arr.push(user.UserDetails[0]&&user.UserDetails[0].email)
            arr.push(user.UserDetails[0]&&user.UserDetails[0].gender)
            arr.push(user.UserDetails[0]&&user.UserDetails[0].mobile) 
            arr.push(user.UserDetails[0]&&user.UserDetails[0].referCode)
            arr.push(user.UserDetails[0]&&user.data[0].completedSurvey)
            arr.push(user.UserDetails[0]&&user.data[0].pointAvailable)
            arr.push(user.UserDetails[0]&&user.data[0].pointEarned)
            arr.push(user.UserDetails[0]&&user.data[0].pointRedeemed)
            arr.push(user.UserDetails[0]&&user.data[0].profilePercent)
            arr.push(user.UserDetails[0]&&referedUserEmail)

            dataForExcelDownload.push(arr)
            setDataForExcelDownload([...dataForExcelDownload, dataForExcelDownload])
        })
        setIsGenerateExcelForAllUser(true)
        setGenerate(true)
    }
    

    return (
        <div className='d-flex'>
            {/* <Header {...props} /> */}
            {/* <Tabs {...props}/> */}
            {/* <Footer {...props} /> */}
{pageCount>0&&<div><button className='btn btn-secondary' onClick={()=>sendPageCount("-1")}><i class="fa fa-arrow-left"></i></button></div>}
            <div className="container mt-5">
                {generate 
                // && isGenerateExcelForAllUser 
                ?
                    <CSVLink style={{ float: "right" }} color="black" data={dataForExcelDownload}
                        headers={headers}
                        filename={"AllUserData_doyoursurveypanel.csv"} ref={csvLink}>
                        <button className="btn btn-success ml-3">All User Excel Download</button>
                    </CSVLink>
                    :
                    <button style={{ float: "right" }} onClick={() => downloadSurveyExcelAllUser()} className="btn btn-secondary ml-3 text-white">Generate Excel All User</button>
                }
                <div className='row justify-content-right'>
                    <div className='col-md-10 d-flex'>
                    <select className='form-control'  placeholder='Gender' name="gender" onChange={(e)=>setGender(e.target.value)}>
                    <option value="" hidden>Please Select Gender</option>
                    <option value="Male" >Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                </select>
                <select className='form-control  ml-2' placeholder='Type' name="type" onChange={(e)=>setType(e.target.value)}>
                    <option value="" hidden>Please Select Device</option>
                    <option value="Android">Android</option>
                    <option value="iosapp">IOS</option>
                    <option value="WebApp" >WebApp</option>
                    <option value="MobileApp">MobileApp</option>
                </select>
                <button className='btn btn-secondary ml-2 text-white' onClick={()=>getUserBasicsDetails(0)}><i className="fa fa-filter" aria-hidden="true"></i></button>

                <button className='btn btn-secondary ml-2 text-white' onClick={()=>getUserBasicsDetails()}><i class="fa-duotone fa-square-xmark" aria-hidden="true"></i></button>
                    </div>
                </div>
                <div className="container mt-4">
                    {loader2 == true ?
                        <div className="container" style={{ marginTop: "10%" }}>
                            <Loader2 />
                        </div>
                        : <>
                            
                            {userBasicDetails.map((user, index) => (
                                
                                <div className="card mb-5" style={{ backgroundColor: "white" }}>
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-6">
                                                {/* <h6 className="text-white">{index + 1} User Name :{user.UserDetails[0].firstName && <> {user.UserDetails[0].firstName}  {user.UserDetails[0].lastName}</>}</h6> */}
                                            </div>
                                            <div className="col-md-6">
                                                {generate && generateExcelUserId == user.UserDetails[0]._id? 
                                                    <CSVLink style={{ float: "right" }} color="black" data={dataForExcelDownload}
                                                        headers={headers}
                                                        filename={excelFileName+"UserDetails.csv"} ref={csvLink}>
                                                        <button className="btn btn-success ml-3">Excel Download</button>
                                                    </CSVLink>
                                                    :
                                                    <button style={{ float: "right" }} onClick={() => downloadSurveyExcel(user)} className="btn btn-secondary ml-3 text-white">Generate Excel</button>
                                                }
                                            </div>
                                        </div>




                                        <div className="card-body" style={{ borderTop: "1px solid" }}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <table className="table text-center text-black">
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">Email</th>
                                                                <td>{user.UserDetails[0]&&user.UserDetails[0].email}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Dob</th>
                                                                <td>{<Moment format="DD/MM/YYYY">
                                                                    {user.UserDetails[0]&&user.UserDetails[0].dob}
                                                                </Moment>}</td>

                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Mobile</th>
                                                                <td>{user.UserDetails[0]&&user.UserDetails[0].mobile}</td>

                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Gender</th>
                                                                <td>{user.UserDetails[0]&&user.UserDetails[0].gender}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">city</th>
                                                                <td>{user.UserDetails[0]&&user.UserDetails[0].city}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">referCode</th>
                                                                <td>{user.UserDetails[0]&&user.UserDetails[0].referCode}</td>
                                                            </tr>


                                                            <tr>
                                                                <th scope="row">Created Date</th>
                                                                <td>{<Moment format="YYYY/MM/DD - hh:mm:ss a">
                                                                    {user.UserDetails[0]&&user.UserDetails[0].date}
                                                                </Moment>}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="col-md-6 item-center">
                                                    <div className="container">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Total Attended Survey</th>
                                                                    <th>{user.data[0]&&user.data[0].completedSurvey}</th>
                                                                   
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Profile Status</td>
                                                                    <td>{user.data[0]&&user.data[0].profilePercent == "100" ? <span style={{ color: "blue" }}>Completed</span> : <span style={{ color: "red" }}>Pending</span>}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td>Total Earn Point </td>
                                                                    <td>{user.data[0]&&user.data[0].pointEarned}</td>

                                                                </tr>
                                                                <tr>
                                                                    <td>Available Point </td>
                                                                    <td>{user.data[0]&&user.data[0].pointAvailable}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="container">
                                                    <div className="container mb-3" style={{border: "1px solid", height:"170px", overflow:"auto"}}>
                                                        <h5 align="center" style={{borderBottom:"1px solid"}}>Registered User with Refer code User</h5>
                                                        <div>
                                                            {/* {userBasicDetails.map((filterReferdUser)=>(
                                                                <div>
                                                                    {filterReferdUser.UserDetails[0].refferalBy==user.UserDetails[0].referCode && 
                                                                   
                                                                   <div>
                                                                       
                                                                   
                                                                    <h6>{filterReferdUser.UserDetails[0].email}</h6>
                                                                </div>    
                                                                }
                                                                </div>
                                                            ))} */}
                                                    
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <center><button onClick={() => deleteUser(user.UserDetails[0].email)} className="btn btn-danger btn-block col-md-6 text-white">Delete User</button></center>
                                                 


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>
                            ))}
                        </>}
                </div>

            </div>
            <div><button className='btn btn-secondary' onClick={()=>sendPageCount("1")}><i class="fa fa-arrow-right"></i></button></div>
        </div>
    );
}

export default Users;