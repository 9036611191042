import React, { useState, useEffect } from 'react';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import { Redirect, Link, useHistory } from "react-router-dom";
import Loader2 from '../../utils/loader2/loader2';
import './profilePanelAdminDashboard.css'

const ProfilePanelAdminDashboard = (props) => {
    const api = new API()
    const history = new useHistory()
    const [welcomePoint, setWelcomePoint] = useState([])
    const [referPoint, setReferPoint] = useState([])
    const [ points,setPoints]= useState()
    
    const [loader2, setLoader2] = useState(true)
    const [isWelcomePoint, setIsWelcomePoint] = useState(false)
    const [isReferPoint, setIsReferPoint] = useState(false)
    const [newPoint, setNewPoint] = useState(0)

    const [selfPoint,setSelfPoint] = useState()
    const [refer1Point, setRefer1Point] = useState()
    const [refer2Point, setRefer2Point] = useState()


    let adminToken = getLocalDataAsObject("adminToken")
    if(!adminToken) {
        history.push("/dashboard")
    }
    useEffect(() => {
        // profilePanelGetDynamicContent()
        getMLMpoints()
    }, [])

    const profilePanelGetDynamicContent = async () => {

        let data = {

        }
        const result = await api.post(config.adminDashboardProfilePanelGetDynamicContent, data)
        if (result && result.code === 200) {
            let finalResult = result.data
      
            setWelcomePoint(finalResult.welcome_point)
            setReferPoint(finalResult.refer_point)
            // alert("success")

        }
    }
    const changeWelcomePoint = async (e) => {
        if(newPoint == 0 || newPoint == undefined || newPoint == ""){
            alert("please Enter Point")
        } else {
            
        let data = {
            newPoint: newPoint
        }
        const result = await api.post(config.adminDashboardProfilePanelWelcomePoint, data)
        if (result && result.code === 200) {
            alert("success")
            profilePanelGetDynamicContent()
            setNewPoint(0)
        }
        
    }
    }
    const changeReferPoint = async (e) => {
        if(newPoint == 0 || newPoint == undefined || newPoint == ""){
            alert("please Enter Point")
        } else{

        let data = {
            newPoint: newPoint
        }
        const result = await api.post(config.adminDashboardProfilePanelReferPoint, data)
        if (result && result.code === 200) {
            alert("success")
            profilePanelGetDynamicContent()
            setNewPoint(0)

        }
    }}
    const clickedOnLeftNavBarBtn = (e) =>{
        // alert (e.target.value)
        let val = e.target.value
        if(val == "welcomePoint"){
            setIsReferPoint(false)
            setIsWelcomePoint(true)
            setSelfPoint(false)
            setRefer1Point(false)
            setRefer2Point(false)
        }
        if(val == "referPoint"){
            setIsWelcomePoint(false)
            setIsReferPoint(true)
        }


        if(val == "SelfPoint"){
            setSelfPoint(true)
            setRefer1Point(false)
            setRefer2Point(false)
            setIsWelcomePoint(false)
        }
        if(val == "refer1Point"){
            setSelfPoint(false)
            setRefer1Point(true)
            setRefer2Point(false)
            setIsWelcomePoint(false)
        }
        if(val == "refer2Point"){
            setSelfPoint(false)
            setRefer1Point(false)
            setRefer2Point(true)
            setIsWelcomePoint(false)
        }
     
    }

    const getMLMpoints=async()=>{

        // let result = await api.get('https://api.doyoursurvey.com:3009/admin-dashboard/referralPercent')
        let result = await api.get(`${config.adminDashboardReferralPercent}`)
        setPoints(result.data)
    }



    return (
        <div>
            {/* <Header {...props} /> */}
            {/* <Tabs {...props}/> */}
            <div className="container" style={{ marginTop: "10%" }}>
                <h2 className="text-center">Profile Admin Panel</h2>
                <div className="row">
                    <div className="col-md-3" style={{border:"1px solid"}}>
                        <ul>
                            <li><button onClick={clickedOnLeftNavBarBtn} value="welcomePoint" className="btn btn-default">Welcome Point</button></li>
                            {/* <li><button onClick={clickedOnLeftNavBarBtn} value="referPoint" className="btn btn-default">Refer Point</button></li> */}
                            <li><button onClick={clickedOnLeftNavBarBtn} value="SelfPoint" className="btn btn-default">Self Point</button></li>
                            <li><button onClick={clickedOnLeftNavBarBtn} value="refer1Point" className="btn btn-default">Refer 1 Point</button></li>
                            <li><button onClick={clickedOnLeftNavBarBtn} value="refer2Point" className="btn btn-default">Refer 2 Point</button></li>
                            {/* <li><button onClick={clickedOnLeftNavBarBtn} className="btn btn-default">Option Point</button></li> */}
                        </ul>
                    </div>
                    <div className="col-md-8" >
                        <div className="container text-center" style={{border:"1px solid", minHeight:"350px"}}>

                      {isWelcomePoint && 
                      <div className="mt-5">
                          <h4>Welcome Point</h4>
                        <h3>{welcomePoint}</h3>
                        <div className="form-group">
                            <input type="text" onChange={(e)=>setNewPoint(e.target.value)} value={newPoint} placeholder="Enter Point to Update..."  />
                        </div>
                        <button onClick={changeWelcomePoint} onChange={(e)=>setNewPoint(e.target.value)} className="btn btn-primary">Change Welcome Point</button>
                        </div>
                        }
                        {isReferPoint && 
                        <div className="mt-5">
                            <h4>Refer Point</h4>
                        <h3>{referPoint}</h3>
                        <div className="form-group">
                            <input type="text" onChange={(e)=>setNewPoint(e.target.value)} value={newPoint} placeholder="Enter Point to Update..."  />
                        </div>
                        <button onClick={changeReferPoint} className="btn btn-primary">Change Welcome Point</button>
                        </div>}



                        {selfPoint && 
                      <div className="mt-5">
                          <h4>Self Point</h4>
                        <h3>{points&&points.active}</h3>
                        <div className="form-group">
                            <input type="text" onChange={(e)=>setNewPoint(e.target.value)} value={points.active} placeholder="Enter Point to Update..."  />
                        </div>
                        <button onClick={changeWelcomePoint} onChange={(e)=>setNewPoint(e.target.value)} className="btn btn-primary">Change Self Point</button>
                        </div>
                        }


                        {refer1Point && 
                      <div className="mt-5">
                          <h4>Refer 1 Point</h4>
                        <h3>{points&&points.parent1}</h3>
                        <div className="form-group">
                            <input type="text" onChange={(e)=>setNewPoint(e.target.value)} value={points.parent1} placeholder="Enter Point to Update..."  />
                        </div>
                        <button onClick={changeWelcomePoint} onChange={(e)=>setNewPoint(e.target.value)} className="btn btn-primary">Change Refer 1 Point</button>
                        </div>
                        }
                        {refer2Point && 
                      <div className="mt-5">
                          <h4>Refer 2 Point</h4>
                        <h3>{points&&points.parent2}</h3>
                        <div className="form-group">
                            <input type="text" onChange={(e)=>setNewPoint(e.target.value)} value={points.parent2} placeholder="Enter Point to Update..."  />
                        </div>
                        <button onClick={changeWelcomePoint} onChange={(e)=>setNewPoint(e.target.value)} className="btn btn-primary">Change Refer 2 Point</button>
                        </div>
                        }
                        </div>
                    </div>
                </div>

            </div>
            <div className="footer">
                {/* <Footer {...props} /> */}
            </div>

        </div>
    );
}

export default ProfilePanelAdminDashboard;