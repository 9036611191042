import { useState, useEffect, useRef } from "react";
import React from "react";
import Header from "../Components/ReusableComponents/Header";
import API from '../utils/apiCalling'
import { useParams, useHistory } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { ToWords } from 'to-words';
import moment from 'moment';
import Config from "../config/env";


const Invoice = () => {
  const toWords = new ToWords();
  const pdfref = useRef()
  const history = useHistory()
  const api = new API()
  const id = useParams()
  const [value, setValue] = useState([])
  const [Invoice, setInvoive] = useState([])
  const [word, setword] = useState(0)
  console.log("Hewjhfjkaskjfhksl Invoice", Invoice)
  let getUser = JSON.parse(localStorage.getItem('user'))



  useEffect(() => {
    getInvoice()
    getPackages()
  }, [])

  console.log("Id", id)


  let getInvoice = async () => {
    let result = await api.get(`${Config.new.site_api}/client-survey/getInvoice`, true, id)
    if (result.code === 200) {
      setValue(result.data)
      setword((result.data[0]?.amount) / 100)
      console.log("Hello result", result)
    }
  }

  let getPackages = async () => {
    let data = {
      _id: getUser._id
    }
    let result = await api.post(`${Config.new.site_api}/client-survey/getPackages`, data)
    if (result.code === 200) {
      setInvoive(result.data)
      console.log("Hehgfdsggfsd", result.data)
    }
  }
  const Downloadpdff = () => {
    const input = pdfref.current
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/*');
      const pdf = new jsPDF('p', 'mm', 'a4', true);
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeigth = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeigth = canvas.height
      const radio = Math.min(pdfWidth / imgWidth, pdfHeigth / imgHeigth)
      const imgx = (pdfWidth - imgWidth * radio) / 2
      const imgy = 30
      pdf.addImage(imgData, 'PNG', imgx, imgy, imgWidth * radio, imgHeigth * radio)
      pdf.save('invoice.pdf')
    })
  }




  return (
    <>
      <Header />

      <section className="paymentInvoice-page" ref={pdfref}>
        <div className="container">
          <table width="100%" style={{ tableLayout: "fixed" }}>
            <tr>
              <td
                style={{
                  border: "1px solid #ddd",
                }}
              >
                <div class="logo d-flex align-items-center justify-content-evenly">
                  <img
                    src="../images/logo-QDegrees.svg"
                    alt="logo"
                    style={{ width: "137px", height: "40px" }}
                  />
                  <div className="logo-contentrfst">
                    <p className="text-bold f-13 m-0">
                      QDegrees Services Pvt Ltd
                    </p>
                    <p className="f-12 m-0" style={{ width: "234px" }}>
                      B-9, 1st Floor, Mahalaxmi Nagar, Malviya Nagar, behind WTP
                      South Block, Jaipur<br></br>GSTIN/UIN: 08AAACQ4569M1ZV
                      <br></br>State Name: Rajasthan, Code: 08CIN:
                      U27900RJ2017PTCO56940<br></br>E-Mail: finance@qdegrees.com
                    </p>
                  </div>
                </div>
              </td>
              <td
                style={{
                  border: "1px solid #ddd",
                }}
              >
                <table width="100%">
                  <tr height="40px">
                    <td className="px-2" style={{ border: "1px solid #ddd", borderLeft: "none", borderTop: "none", width: "50%" }}>
                      <p className="m-0 f-12">Voucher No.</p>
                      <p className="m-0">{value[0]?.Invoice_Id}</p>
                    </td>
                    <td className="px-2" style={{ border: "1px solid #ddd", borderRight: "none", borderTop: "none", width: "50%" }}>
                      <p className="m-0 f-12">Dated</p>
                      <p className="m-0 f-13">{moment((value[0]?.date)).format("Do MMM YYYY")}</p>
                    </td>
                  </tr>
                  <tr height="40px">
                    <td className="px-2" style={{ border: "1px solid #ddd", borderLeft: "none", width: "50%" }}>

                    </td>
                    <td className="px-2" style={{ border: "1px solid #ddd", borderRight: "none", width: "50%" }}>
                      <p className="m-0 f-12">Mode/Terms of Payment</p>
                    </td>
                  </tr>
                  <tr height="40px">
                    <td className="px-2" style={{ border: "1px solid #ddd", borderLeft: "none", borderBottom: "none", width: "50%" }}>
                      <p className="m-0 f-12">Buyer's Ref./Order No.</p>
                      <p className="m-0">{value[0]?.order_id}</p>
                    </td>
                    <td className="px-2" style={{ border: "1px solid #ddd", borderRight: "none", borderBottom: "none", width: "50%" }}>
                      <p className="m-0 f-12">Other Reference</p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{
                border: "1px solid #ddd", padding: "20px"
              }}>
                <p className="f-12 m-0">Consignee (Ship to)</p>
                <p>{value[0]?.b_address === '' ? value[0]?.c_address : value[0]?.b_address}, {value[0]?.b_city === '' ? value[0]?.c_city : value[0]?.b_city}, {value[0]?.b_state === '' ? value[0]?.c_state : value[0]?.b_state},<br />{value[0]?.b_pin_code === '' ? value[0]?.c_pin_code : value[0]?.b_pin_code}</p>
                <p className="m-0">GSTIN/UIN:<span className="ml-3">{value[0]?.gstin_number}</span></p>
                {/* <p className="m-0">PAN/IT No:<span className="ml-3">FNDND654H</span></p> */}
                <p className="m-0">State Name:<span className="ml-3">{value[0]?.b_state === '' ? value[0]?.c_state : value[0]?.b_state}</span></p>
                {/* <p className="m-0">Code:<span className="ml-3">08</span></p> */}
              </td>
              <td style={{
                border: "1px solid #ddd", borderBottom: "1px solid #fafafa"
              }}>
                <table width="100%">
                  <tr height="40px">
                    <td className="px-2" style={{ border: "1px solid #ddd", borderLeft: "none", borderBottom: "1px solid #ddd", width: "50%" }}>
                      <p className="m-0">Dispatched Through</p>
                    </td>
                    <td className="px-2" style={{ border: "1px solid #ddd", borderRight: "none", borderBottom: "1px solid #ddd", width: "50%" }}>
                      <p className="m-0">Destination</p>
                    </td>
                  </tr>
                  <tr>
                    <td className="px-2" style={{ border: "none", width: "50%" }}>
                      <p className="m-0">Terms of Delivery</p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr style={{
              border: "1px solid #ddd"
            }}>
              <td style={{ border: "1px solid #ddd", padding: "20px" }}>
                <p className="f-12 m-0">Buyer (Bill to)</p>
                <p>{value[0]?.b_address === '' ? value[0]?.c_address : value[0]?.b_address}, {value[0]?.b_city === '' ? value[0]?.c_city : value[0]?.b_city}, {value[0]?.b_state === '' ? value[0]?.c_state : value[0]?.b_state},<br />{value[0]?.b_pin_code === '' ? value[0]?.c_pin_code : value[0]?.b_pin_code}</p>
                <p className="m-0">GSTIN/UIN:<span className="ml-3"> {value[0]?.gstin_number}</span></p>
                {/* <p className="m-0">PAN/IT No:<span className="ml-3"></span></p> */}
                <p className="m-0">State Name:<span className="ml-3">{value[0]?.b_state === '' ? value[0]?.c_state : value[0]?.b_state}</span></p>
                {/* <p className="m-0">Code:<span className="ml-3"></span></p> */}
              </td>
              <td style={{
                border: "1px solid #ddd", padding: "20px"
              }}>
                <p></p>
              </td>
            </tr>
          </table>
          <table className="tblMAx" style={{ borderTop: "0" }}>
            <thead>
              <tr>
                <th rowSpan="1" colSpan="1" style={{ borderTop: "0" }}>S.No.</th>
                <th rowSpan="1" colSpan="1" style={{ borderTop: "0" }}>Description of Service</th>
                <th rowSpan="1" colSpan="1" style={{ borderTop: "0" }}>HSN/SAC</th>
                <th rowSpan="1" colSpan="1" style={{ borderTop: "0" }}>GST Rate</th>
                <th rowSpan="1" colSpan="1" style={{ borderTop: "0" }}>Date on</th>
                <th rowSpan="1" colSpan="1" style={{ borderTop: "0" }}>Quantity</th>
                <th rowSpan="1" colSpan="1" style={{ borderTop: "0" }}>Rate</th>
                <th rowSpan="1" colSpan="1" style={{ borderTop: "0" }}>Per</th>
                <th rowSpan="1" colSpan="1" style={{ borderTop: "0" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ borderBottom: "0" }}>1</td>
                <td style={{ borderBottom: "0" }}>
                  <p className="mb-1 f-15">{Invoice[0]?.package_details.Name}</p>
                  {/* <p className="m-0 f-12">Rate of Duty 18%</p>
                  <p className="m-0 f-12">3-May-2023</p> */}
                </td>
                <td style={{ borderBottom: "0" }}></td>
                <td style={{ borderBottom: "0" }}>18%</td>
                <td style={{ borderBottom: "0" }}>{moment((value[0]?.date)).format("Do MMM YYYY")}</td>
                <td style={{ borderBottom: "0" }}>1</td>
                <td style={{ borderBottom: "0" }}>₹ {value[0]?.amount === 'free' ? 0 : parseInt((((value[0]?.amount) / 100)*84.75)/100)}</td>
                <td style={{ borderBottom: "0" }}>No.</td>
                <td style={{ borderBottom: "0" }}>₹ {value[0]?.amount === 'free' ? 0 : parseInt((((value[0]?.amount) / 100)*84.75)/100)}</td>
              </tr>
              <tr>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
              </tr>
              <tr>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
              </tr>
              <tr>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}><p className="text-bold fst-italic text-right">GST Output</p></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}>18</td>
                <td style={{ borderTop: "0", borderBottom: "0" }}>%</td>
                <td style={{ borderTop: "0", borderBottom: "0" }}>₹ {value[0]?.amount === 'free' ? 0 : ((parseInt((((value[0]?.amount) / 100)*84.75)/100))*18)/100}</td>
              </tr>
              <tr>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
              </tr>
              <tr>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
                <td style={{ borderTop: "0", borderBottom: "0" }}></td>
              </tr>
              <tr>
                <td className="text-right"></td>
                <td className="text-right">Total</td>
                <td className="text-right"></td>
                <td className="text-right"></td>
                <td className="text-right"></td>
                <td className="text-right">1</td>
                <td className="text-right"></td>
                <td className="text-right"></td>
                <td className="text-right"><h5>₹ {value[0]?.amount === 'free' ? 0 : (value[0]?.amount) / 100}</h5></td>
              </tr>
            </tbody>
          </table>
          <table className="tblMAx" style={{ borderTop: "0" }}>
            <tbody>
              <tr>
                <td style={{ border: "0", width: "50%" }}>Amount Chargeable (in words)</td>
                <td className="fst-italic text-right" style={{ border: "0", width: "50%" }}>E.& O.E</td>
              </tr>
              <tr>
                <td style={{ border: "0" }}><p className="text-bold m-0">INR {toWords.convert(word)} Only</p></td>
              </tr>
              <tr>
                <td style={{ border: "0" }}></td>
              </tr>
              <tr>
                <td style={{ border: "0" }}></td>
              </tr>
              <tr>
                <td style={{ border: "0" }}></td>
              </tr>
              <tr>
                <td style={{ border: "0" }}>Company's PAN:<span></span></td>
                <td style={{ border: "0" }}>
                  <p className="text-bold m-0">Company’s Bank Details</p>
                  <p className="m-0">A/c Holder's Name:</p>
                  <p className="m-0">Bank Name:</p>
                  <p className="m-0">A/c No.:</p>
                  <p className="m-0">Branch & IFS Code:</p>

                </td>

              </tr>
              <tr>
                <td style={{ border: "0" }}></td>
                <td style={{ borderTop: "1px solid #ddd", borderLeft: "1px solid #ddd" }}>
                  <p className="text-bold text-right">For QDegrees Services Pvt Ltd</p>
                  <p className="text-right m-0 f-12">Authorized Signatory</p>

                </td>

              </tr>

            </tbody>
          </table>
          <p className="f-16 py-3 text-center">This is a Computer-Generated Document. It Does not Require a Signature.</p>
        </div>
      </section>
      <div className="d-flex align-items-center justify-content-center my-2 pb-5">
        <button className="mainbtn text-white me-3" style={{ backgroundColor: '#f47321' }} onClick={(e) => history.push('/adminDash')} > Go to Dashboard</button>
        <button className="mainbtnblue" onClick={Downloadpdff}> Download PDF</button>
      </div>
    </>
  );
};

export default Invoice;
