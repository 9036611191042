import React from 'react'

export default function AnalysisCart(props) {
     const { heading, description } = props
     return (
          <div className="col-md-6">
               <div className="card research-cards">
                    <div className="ontopbanner researchBox">
                         <h5 className="m-0">{heading}</h5>
                    </div>
                    <div className="card-body p-4">
                         <p className="boldnormal-p peragraphFont">{description}</p>
                    </div>
               </div>
          </div>
     )
}
