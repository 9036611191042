import React from "react";
import { useLocation } from "react-router-dom";

const WhatsApp = () => {
    const Location = new useLocation()
    return (
        <>
            {!Location.pathname.includes("/newSurvey/") && !Location.pathname.includes("/npsSurvey/") && !Location.pathname.includes("/dashboardReport") && !Location.pathname.includes("/review/") && <a
                href="https://wa.me/8696900829"
                className="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >

                <i className="fa fa-comments whatsapp-icon"></i>

            </a>}
        </>
    )
}

export default WhatsApp