import React, { useEffect, useState } from "react"
import NpsReport from "./npsReport"
import API from '../../../utils/apiCalling';
import Config from '../../../config/env'
import ReactApexChart from "react-apexcharts";

const NpsQuestions = (props) => {
    console.log(props, "props")
    let api = new API()
    const [screen, setScreen] = useState(5)
    const [user, setUser] = useState({})
    const [reportData, setReportData] = useState([])
    const [clientAllSurveys, setClientAllSurveys] = useState([])
    const [report, setReport] = useState([])
    const [graph, setGraph] = useState('')
    const [words, setWords] = useState([])
    const [matrix, setmatrix] = useState([])
    const [questionvalue, setQuestionvalue] = useState('')
    const [neww, setnew] = useState([])
    const [newAnswer, setNewAnswer] = useState([])
    const [formtype, setformtype] = useState(0)
    const [matrixResponse, setTotalMatrixReponse] = useState([])
    const [matReports, setMatReport] = useState({})
    const [newMatrix, setNewMatrix] = useState([])

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('user')))
        getData(props.surveyData.id)
        setClientAllSurveys(props.surveyData?.selectedSurveys)
    }, [])

    const getData = async (Id) => {
        // let counts = { ...boxCounts }
        // console.log({ ...boxCounts }, 'props')
        let data = {
            surveyId: Id
        }
        const surveyData = await api.post(`${Config.new.site_api}/client-survey/getNpsReport`, data)
        if (surveyData.code === 200) {
            setReportData(surveyData.data)
            // generateExcelData(surveyData.data)
            newDashBoardData(surveyData.data)
            surveyData.data.map((e, index) => {
                console.log("e?.dynamicFields?.MobileNo", e, "e?.dynamicFields?.MobileNo")
                if (e?.dynamicFields?.MobileNo) {
                    // counts.triggerCount = counts.triggerCount + 1
                    // console.log(counts.triggerCount, "kkk")
                }
                if (surveyData.data.length - 1 == index) {
                    // setBoxCounts(counts)
                }
            })

        }
    }
    const wish = () => {
        let d = new Date();
        let timeOfDay = d.getHours();

        if (timeOfDay >= 0 && timeOfDay < 12) {
            return 'Good morning'
        }
        if (timeOfDay >= 12 && timeOfDay < 16) {
            return 'Good afternoon'
        }
        if (timeOfDay >= 16 && timeOfDay < 21) {
            return 'Good Evening'
        } if (timeOfDay >= 21 && timeOfDay < 24) {
            return "Good Night"
        }
    }

    const newhandleformtype = async (item, formAnss) => {
        setnew(item)
        let newwww = 0
        item.map((q, i) => {
            let indexvalue = item.map((e, index) => { if (e.option === q.option) { return index } })
            let IndexValue = (indexvalue.filter(e => e !== undefined))
            for (let value of IndexValue) { newwww = value }
            let newAnsArray = countDuplicates(findName(formAnss))
            function findName(array) {
                const names = [];
                for (let i = 0; i < array.length; i++) {
                    const element = array[i];
                    const name = (element?.split(',')[newwww])?.split(':')[1];
                    names.push(name);
                }
                return names;
            }


            function countDuplicates(array) {
                const countMap = new Map();
                array.forEach((value) => {
                    countMap.set(value, (countMap.get(value) || 0) + 1);
                });
                const duplicates = [];
                countMap.forEach((value, text) => {
                    if (value >= 1) {
                        duplicates.push({ text, value });
                    }
                });
                return duplicates;
            }
            q.Ans = newAnsArray
        })
        newAnswer.push(...item)

    }

    const newDashBoardData = (newData) => {
        (newData[0]?.questions)?.map(e => {
            if (e.type === "radio" || e.type === 'checkbox') {
                let finalArray = []
                let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                let Answer = Question.map(e => e[0]?.answer)
                let response = Question[0]
                let Option = Question[0]?.map(e => (e.options))
                let newArray = []
                Option[0]?.map((e) => {
                    let sum = 0
                    Answer?.map((q) => {
                        if (q) {
                            if (Array.isArray(q)) {
                                if (e.option == q[0]) {
                                    return sum = sum + 1
                                }
                            } else if (e.option.toLowerCase() == 'other') {
                                return sum = sum + 1
                            }
                        }
                    })
                    newArray.push({ "Option": e.option, "Answer": sum })
                })

                let totalResponses = Answer.length
                newArray.map(e => {
                    e.count = parseInt(((e.Answer) * 100) / totalResponses)
                })
                let GraphKey = []
                let GraphValue = []
                newArray.map(e => {
                    GraphKey.push(e.Option)
                    GraphValue.push(e.count)
                })
                response.map(e => {
                    e.ans = newArray
                    e.GraphKey = GraphKey
                    e.GraphValue = GraphValue
                })
                finalArray.push(response[0])

                report.push(...finalArray)

                // setReport(finalArray)
                setGraph('')

            } else if (e.type === 'star' || e.type === "nps" || e.type === "rating") {
                let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                let Answer = Question.map(e => e[0]?.answer)
                let response = Question[0]
                let finalArray = []
                let newArray = []
                let Option = Question[0]?.map(e => (e.options))
                Option[0]?.map(e => {
                    let sum = 0
                    Answer.map(q => {
                        if (e.option === q) {
                            sum = sum + 1
                        }
                    })
                    newArray.push({ "Option": e.option, "Answer": sum })
                })
                let totalResponses = Answer.length
                newArray.map(e => {
                    e.count = parseInt(((e.Answer) * 100) / totalResponses)
                })
                let GraphKey = []
                let GraphValue = []
                newArray.map(e => {
                    GraphKey.push(e.Option)
                    GraphValue.push(e.count)
                })

                response.map(e => {
                    e.ans = newArray
                    e.GraphKey = GraphKey
                    e.GraphValue = GraphValue
                })
                finalArray.push(response[0])

                report.push(...finalArray)

                setGraph('')

            } else if (e.type === "text") {
                let Answer
                let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                Answer = countDuplicates((Question.map(e => e[0]?.answer)).map(e => { if (e !== undefined) { return e[0] } }))
                function countDuplicates(array) {
                    const countMap = new Map();
                    array.forEach((value) => {
                        countMap.set(value, (countMap.get(value) || 0) + 1);
                    });
                    const duplicates = [];
                    countMap.forEach((value, text) => {
                        if (value >= 1) {
                            duplicates.push({ text, value });
                        }
                    });
                    return duplicates;
                }
                e.Ans = Answer
                words.push(e)
            } else if (e.type === "form") {
                setQuestionvalue(e)
                setformtype(1)
                let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                let Answer = Question.map(e => e[0]?.answer)
                // setarrayOfInadex(Question[0][0].options)
                // setFormAns(Answer)
                newhandleformtype(Question[0][0].options, Answer)
            } else if (e.type === 'matrix') {
                if (e.selection !== "input") {
                    let newResult = e
                    let matrixAnswerReport = {}
                    let tempQue = []
                    let newArray = []
                    let totalResponses = []
                    let Question = (newData.map(q => q.questions)).map(value => { return value.filter(item => item._id === e._id) })
                    let Answer = Question.map(e => e[0]?.answer)
                    Answer.length > 0 && Answer.map(e => {
                        if (e) {
                            let newValue = (((e.toString()).replaceAll('-', ',')).slice(0, -1)?.split(','))
                            const result = [];
                            for (let i = 0; i < newValue.length; i += 2) {
                                const field = newValue[i]?.split(':')[1];
                                const answer = newValue[i + 1]?.split(':')[1];
                                result.push({ field, answer });
                                if (!tempQue.includes(field)) {
                                    tempQue.push(field)
                                }
                            }
                            totalResponses.push(result)

                        }
                    })
                    // })
                    setmatrix(newResult)
                    tempQue.forEach((que) => {
                        matrixAnswerReport[que] = {}
                    })

                    setTotalMatrixReponse(totalResponses)
                    totalResponses.forEach((responses) => {
                        responses.forEach((response) => {
                            matrixAnswerReport[response.field][response.answer] = matrixAnswerReport[response.field][response.answer] ? matrixAnswerReport[response.field][response.answer] + 1 : 1
                        })
                    })
                    setMatReport(matrixAnswerReport);
                    e.matReport = matrixAnswerReport
                    newMatrix.push(e)
                    newArray.push(matrixAnswerReport)

                    console.log(matrixAnswerReport,
                        tempQue,
                        newArray,
                        totalResponses, "mmmmmmmmmmm")
                }
            }
        })

    }

    const SetGragh = (id, ID, index) => {
        // setgraph(id, index)
        let newID = report.filter(e => e._id === ID)

        report[index].chartData = {
            series: [{
                name: 'Answer',
                data: newID[0]?.GraphValue
            }],
            options: {
                colors: ['#F47321'],
                chart: {
                    type: 'bar',
                    height: 450
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: newID[0]?.GraphKey
                    ,
                },
                yaxis: {
                    title: {
                        text: 'Answer %'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return + val + " % "
                        }
                    }
                },
                toolbar: {
                    show: false
                }

            },

        }

        report[index].picData = {
            series: newID[0]?.GraphValue,
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: newID[0]?.GraphKey,
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        }
    }
    console.log(report, "reportreportreportreportreportreport")
    return (
        <>
            {/* <header>
                <div className="container-fluid">
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        <div className="headLeft">
                            <a href="">
                                <img src="images/new-logo.png" alt="" width={90} />
                            </a>
                        </div>
                        <div className="headRight">
                            <div className="dropdown">
                                <a href="" className="userprofile" data-bs-toggle="dropdown">
                                    <img src="images/profile.png" /> Smith Jhones
                                </a>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            My Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            Logout
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header> */}
            {screen == 5 && <main>
                <div className="dashMian">
                    <div className="container-fluid">
                        <div className="cardBox dashboardBnner mb-3">
                            <div className="d-flex align-items-center justify-content-between">
                                <h1>
                                    {wish()}, <b className="fw-semibold">{user?.fullName}!</b>
                                </h1>
                                <div className="d-flex align-items-center">
                                    <div className="calenderCont">
                                        <h6>Today :</h6>
                                        <span className="grays-clr fontsi13">
                                            2022-12-13 To 2022-12-13
                                        </span>
                                    </div>
                                    <div className="dropdown">
                                        <button
                                            data-bs-toggle="dropdown"
                                            className="border-0 bg-transparent ms-2"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src="images/calender-blue-round.svg"
                                                width={40}
                                                alt="calender-image"
                                            />
                                        </button>
                                        <ul
                                            className="dropdown-menu calenderMenuman"
                                            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 10px" }}
                                        >
                                            <li>
                                                <a className="dropdown-item" href="javascript:void(0);">
                                                    {" "}
                                                    Today
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Yesterday
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Last 7 Days
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Last 30 Days
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    This Month
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Select Months
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item " href="javascript:void(0);">
                                                    Custom Range Month
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <button
                                        className="filterToggle"
                                        type="button"
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#filterOffcanvas"
                                    >
                                        <img src="images/Filters-orgs-rounnd.svg" alt="" width={40} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="cardBox mb-3">
                            <div className="d-flex align-items-center justify-content-between mb-4 border-bottom pb-4">
                                <div>
                                    <h3 className="section-title mb-3">Survey Question Wise Summary</h3>
                                    <div className="catSelect">
                                        <label>Select Survey</label>
                                        <select className="form-select">
                                            {/* <option>XYZ Survey</option>
                                        <option>XYZ Survey</option>
                                        <option>XYZ Survey</option>*/}
                                            {/* <option>XYZ Survey</option>  */}
                                            {clientAllSurveys.length > 0 && clientAllSurveys.map((survey) => {
                                                if (survey?.surveyFor === "NPS") {
                                                    return <option value={survey._id}>{survey.survey_name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="btn btn-primary px-4" onClick={() => { setScreen(4) }}>
                                    Back
                                </div>
                            </div>
                            <div className="row progressMain">
                                <div className="col-md-3">
                                    <h4 className="fs-7 fw-bold">Question</h4>
                                    <p className="fs-8 fw-medium">
                                        Based on the availed two-wheeler loan, how likely are you to
                                        recommend XYZ Finance to your friends and family? (10 Being the
                                        highest and 0 being the lowest)
                                    </p>
                                    <span className="npsNumber">
                                        <img src="images/nps.svg" width={15} alt="" />
                                        n= 5026
                                    </span>
                                </div>
                                <div className="col-md-6 px-md-5 ">
                                    <h4 className="fs-7 fw-bold">NPS</h4>
                                    <div className="progress-stacked overflow-visible">
                                        <div
                                            className="progress progressredhvr"
                                            role="progressbar"
                                            aria-label="Segment one"
                                            aria-valuenow={15}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                            style={{ width: "75%" }}
                                        >
                                            <div className="hoverInfo progresshvr">
                                                <strong>Promoter</strong>
                                                <span className="text-success">75%</span>
                                            </div>
                                            <div className="progress-bar bg-success text-start ps-2">
                                                75%
                                            </div>
                                        </div>
                                        <div
                                            className="progress progresshvr"
                                            role="progressbar"
                                            aria-label="Segment two"
                                            aria-valuenow={30}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                            style={{ width: "10%" }}
                                        >
                                            <div className="hoverInfo progresshvr">
                                                <strong>Passive</strong>
                                                <span className="text-warning">10%</span>
                                            </div>
                                            <div className="progress-bar bg-warning">10%</div>
                                        </div>
                                        <div
                                            className="progress"
                                            role="progressbar"
                                            aria-label="Segment three"
                                            aria-valuenow={20}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                            style={{ width: "15%" }}
                                        >
                                            <div className="hoverInfo progresshvr">
                                                <strong>Detractor</strong>
                                                <span className="text-danger">15%</span>
                                            </div>
                                            <div className="progress-bar bg-danger">15%</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 ps-md-4">
                                    <div className="netPromotorScore">
                                        <h4 className="fs-7 fw-bold">NET PROMOTOR SCORE</h4>
                                        <strong className="d-block fs-3">60.25</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {report && report.map((que,index) => {
                            SetGragh("chart", que._id, index)
                            return (<div className="cardBox mb-3">
                                <h3 className="fs-7 fw-bold mb-1">Question</h3>
                                <p className="fs-8 fw-medium">{console.log(que, "jjjjjjjjjjjjjj")}
                                    {que.question}
                                </p>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="table-responsive tableDiv">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Option</th>
                                                        <th>Count</th>
                                                        <th>Contribution (%)</th>
                                                    </tr>
                                                </thead>
                                                {/* <tbody>
                                                    <tr>
                                                        <td>uuuuuu</td>
                                                        <td>396</td>
                                                        <td>44.70%</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Deviations in interest rate</td>
                                                        <td>294</td>
                                                        <td>33.18%</td>
                                                    </tr>
                                                    </tbody> */}
                                                   {que.ans.map((e)=>
                                                    <tbody>
                                                    <td>{e.Option}</td>
                                                    <td>{e.Answer}</td>
                                                    <td>{e.count}%</td>
                                                    </tbody>
                                                   )}
                                                
                                            </table>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        {/* <div id="question1" style={{ height: 280 }} /> */}
                                        <ReactApexChart
                                                    options={que.chartData ? que.chartData.options : []}
                                                    series={que.chartData ? que.chartData.series : []}
                                                    type="bar"
                                                    // width="500"
                                                    height= '280'
                                                />
                                    </div>
                                </div>
                            </div>)
                        })}
                        {/* <div className="cardBox mb-3">
                        <h3 className="fs-7 fw-bold mb-1">Question</h3>
                        <p className="fs-8 fw-medium">
                            How satisfied are you with the overall processing time of our loan
                            disbursement process?
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="table-responsive tableDiv">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Option</th>
                                                <th>Count</th>
                                                <th>Contribution (%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Unclear Processing fees</td>
                                                <td>396</td>
                                                <td>44.70%</td>
                                            </tr>
                                            <tr>
                                                <td>Deviations in interest rate</td>
                                                <td>294</td>
                                                <td>33.18%</td>
                                            </tr>
                                            <tr>
                                                <td>Discrepancies in EMI payments</td>
                                                <td>110</td>
                                                <td>12.42%</td>
                                            </tr>
                                            <tr>
                                                <td>Miscommunication about loan tenure</td>
                                                <td>53</td>
                                                <td>5.98%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div id="question1" style={{ height: 280 }} />
                            </div>
                        </div>
                    </div>*/}
                        {/* <div className="cardBox mb-3">
                        <h3 className="fs-7 fw-bold mb-1">Question</h3>
                        <p className="fs-8 fw-medium">
                            How satisfied are you with sales executive's knowledge and behavior?
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="table-responsive tableDiv">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Option</th>
                                                <th>Count</th>
                                                <th>Contribution (%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Very Satisfied</td>
                                                <td>396</td>
                                                <td>44.70%</td>
                                            </tr>
                                            <tr>
                                                <td>Satisfied</td>
                                                <td>294</td>
                                                <td>33.18%</td>
                                            </tr>
                                            <tr>
                                                <td>Neutral</td>
                                                <td>110</td>
                                                <td>12.42%</td>
                                            </tr>
                                            <tr>
                                                <td>Very Dissatisfied</td>
                                                <td>53</td>
                                                <td>5.98%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div id="question2" style={{ height: 280 }} />
                            </div>
                        </div>
                    </div>  */}
                    </div>
                </div>
                <div
                    className="offcanvas offcanvas-end filterOffcanvas"
                    tabIndex={-1}
                    id="filterOffcanvas"
                >
                    <div className="offcanvas-header border-bottom">
                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                            Filter
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                        />
                    </div>
                    <div className="offcanvas-body pe-0">
                        <div className="filterForm pe-3">
                            <div className="form-group mb-3">
                                <label>Channel</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>SMS</option>
                                    <option>WhatsApp</option>
                                    <option>Email</option>
                                    <option>QR</option>
                                    <option>IVR</option>
                                    <option>CATI</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Zone </label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>East</option>
                                    <option>West</option>
                                    <option>North</option>
                                    <option>South</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Service Centre</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>Q</option>
                                    <option>W</option>
                                    <option>D</option>
                                    <option>F</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Branch</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>A</option>
                                    <option>B</option>
                                    <option>C</option>
                                    <option>D</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>State</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>State </option>
                                    <option>State </option>
                                    <option>State </option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Segment</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>P</option>
                                    <option>PAS</option>
                                    <option>DET</option>
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label>Feedback Status</label>
                                <select className="form-control selectpicker cstmSelectCheck">
                                    <option>All</option>
                                    <option>Response Received</option>
                                    <option>Response Not Received</option>
                                </select>
                            </div>
                        </div>
                        <div className="filterButton">
                            <button type="button" className="btn btn-primary">
                                Apply
                            </button>
                            <button type="button" className="btn btn-light">
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            </main>}
            {screen == 4 && <NpsReport screen={screen} />}
        </>

    )
}

export default NpsQuestions;