import axios from "axios";
import { useEffect, useState,useRef } from "react"
import html2canvas from 'html2canvas';
import GaugeChart from 'react-gauge-chart';
import Config from '../../../config/env'
import jsPDF from 'jspdf';
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";

import Popup from "../../../Components/ReusableComponents/popup";

export default function CsatReport(props) {

  let history=useHistory()
  const [data, setData] = useState([]);
  const [dash, setDash] = useState([]);
  const [show,setShow]=useState(true)
  const [langWise, setLangWise] = useState({})
  const [scoreWise, setScoreWise] = useState({});
  const [languages, setLanguages] = useState([]);
  const [cumScore, setCumScore] = useState(0)
  const [excelData, setExceldata] = useState([])

  const [isOpen, setIsOpen] = useState(true);
  const [surveys, setSurveys] = useState([])
  const [selected, setSelected] = useState({});
  var printRef = useRef()

  useEffect(() => {
    checkLogin();
    getSurvey();

  }, [])
 

  function checkLogin(){
    if(!localStorage.getItem('user')){
history.push('login');
    }
  }
  


  async function getSurvey() {
    let userid = JSON.parse(localStorage.getItem('user'))._id;
    axios.post(`${Config.new.site_api}/client/getCsatSurveyDetails`, { user_id: userid }).then(result => {
      if (result.data.code == 200) {
        if (result.data.data.length) {
          setSurveys(result.data.data[0].surveyDetails)
          setSelected(result.data.data[0].surveyDetails[0])
          getData(result.data.data[0].surveyDetails[0])
          setLanguages(result.data.data[0].surveyDetails[0].languages)
        }
      }
    })
  }

  function selectSurvey(survey){
    survey=JSON.parse(survey)
    setSelected(survey)
          getData(survey)
          setLanguages(survey.languages)
  }



  function manageLangWIse(responses,lang) {
   let ex=[]
   
    responses.forEach((ele)=>{
      let tempex={}
      tempex.Date=ele.response_date?ele.response_date:"N/A"
      tempex.Language=ele.answeredLanguage?lang.filter(e=>{return e.lang_code==ele.answeredLanguage})[0]?.lang_name:"N/A"
      ele.questions.forEach((q)=>{
        tempex[q.question]=q.answer?q.answer:"N/A"
      })
      tempex['Mobile']=ele.dynamicFields&&ele.dynamicFields.mobile?ele.dynamicFields.mobile:"N/A";
      tempex['Email']=ele.dynamicFields&&ele.dynamicFields.email?ele.dynamicFields.email:"N/A";

      ex.push({...tempex});

    })

    setExceldata(ex)


    let temp = { ...langWise }
    lang.map(ele => {

      let a = responses.filter((resp) => {
        return resp.answeredLanguage == ele.lang_code
      })
      a = a.map((ele) => {
        return ele.questions.filter(q => {
          return q.type == 'csat'
        })[0]
      })

      temp[ele.lang_code] = a
    })

    setLangWise(temp);
  }

  function manageSscoreWise(responses) {
    let temp = { ...scoreWise }
    let score = 0
    let arr = [1, 2, 3, 4, 5]
    arr.map(ele => {
      let count = 0
      responses.forEach((resp) => {
        if (resp && resp.answer == ele) { count++ }
      })

      score = score + (count * ele)
      temp[ele] = count
    })
    setCumScore(score);
    setScoreWise(temp);
  }

  const getData = async (survey) => {
    let data = {
      surveyId: survey._id
    }
    const surveyData = await axios.post(`${Config.new.site_api}/client-survey/getNpsReport`, data)
    if (surveyData.data.code === 200) {
      let a = surveyData.data.data.map((ele) => {
        return ele.questions.filter(q => {
          return q.type == 'csat'
        })[0]
      })


      setData(a)
      setDash(surveyData.data.dashboardData[0])
      manageLangWIse(surveyData.data.data,survey.languages);
      manageSscoreWise(a)
    }
  }

  function downloadPdf() {
    setShow(false);
    const input = printRef.current;
    console.log(input)
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4', true);
      const pdfwidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfwidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfwidth - imgWidth * ratio) / 2
      const imgY = 30;
      pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
      pdf.save(selected.survey_name + ".pdf");
      setShow(true)
    })
  }

  return (<div ref={printRef}>
  
  <section id="all-section nav-section " style={{zIndex:"1", position:"relative"}} >

<nav className="navbar  navbar-expand-lg  w-100 fadeInDown" style={{backgroundColor:"white"}}>
     <div className="container-fluid">
          <a className="navbar-brand" href="/">
               <img src="../images/new-logo.png" width={"35%"} alt="" ></img>
          </a>
          <h3 style={{color:"#f47321"}}>CSAT Performance</h3>
         <div  hidden={!show}>
        <button className="btn btn-primary mx-1" onClick={()=>{downloadPdf()}}>Download Report</button>
         <CSVLink data={excelData}
                    filename={`${selected.survey_name} Report.csv`} ><button className="btn btn-secondary mx-1">Download Responses</button>
        </CSVLink>
         </div>
          
     </div>
</nav>
</section>
 

    <div className="container mt-5" style={{ marginTop: "15rem" }}>
      <div className="card p-4" >
        <div>
          <h4> Survey</h4>
          <select className="form-control" onChange={(e)=>{selectSurvey(e.target.value)}}>
            {surveys.length && surveys.map((s) => { return (<><option value={JSON.stringify(s)}>{s.survey_name}</option>
            </>) })}
          </select>
        </div>
        <hr></hr>
        <div className="row">
          {/* <div className="col-lg-6">
        <div className="ramboSec">
          <canvas id="chart-container" />
          <figure className="emojiFigure emojiFigure1">
            <img src="images/smiley_sad_white_icon.svg" alt="" width={40} />
          </figure>
          <figure className="emojiFigure emojiFigure2">
            <img src="images/smiley_sad_white_icon.svg" alt="" width={40} />
          </figure>
          <figure className="emojiFigure emojiFigure3">
            <img src="images/smiley_meh_white_icon.svg" alt="" width={40} />
          </figure>
          <figure className="emojiFigure emojiFigure4">
            <img src="images/smiley_white_icon.svg" alt="" width={40} />
          </figure>
          <figure className="emojiFigure emojiFigure5">
            <img src="images/smiley_white_icon.svg" alt="" width={40} />
          </figure>
        </div>
      </div> */}
          <div className="col-lg-6 pt-3">
            <GaugeChart
              id="gauge-chart"
              nrOfLevels={5}
              colors={['#ff4230', '#ffb700', '#ffea00', '#83c400', '#00b81e']}
              arcWidth={0.3}
              percent={dash.total_responses ? ((cumScore / dash.total_responses) / 5) : 0}
              textColor={'transparent'}
              hideText={true} // If you want to hide the text
            />

          </div>
          <div className="col-lg-6">
            <div className="table-responsive csatTableBox">
              <table className="table">
                <thead>
                  <tr>
                    <th>CSAT</th>
                    <th>Number of Responses</th>
                    <th>Cumulative Scores</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 1 }).length}</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 1 }).length * 1}</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 2 }).length}</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 2 }).length * 2}</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 3 }).length}</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 3 }).length * 3}</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 4 }).length}</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 4 }).length * 4}</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 5 }).length}</td>
                    <td>{data?.length && data?.filter(ele => { return ele &&ele.answer == 5 }).length * 5}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td className="bg-light">Total</td>
                    <td className="bg-light">{dash.total_responses?dash.total_responses:0}</td>
                    <td className="bg-light">{cumScore}</td>
                  </tr>
                  <tr>
                    <td className="border-0" />
                    <td className="border-0" />
                    <td className="bg-success text-white">{(dash.total_responses ? cumScore / dash.total_responses : 0).toFixed(2)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-lg-6">
            <div className="table-responsive csatTableBox">
              <table className="table">
                <thead>
                  <tr>
                    <th>Trigger Count</th>
                    <th>Responses Received</th>
                    <th>Response %</th>
                    <th>CSAT Score</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{dash.total_triggered}</td>
                    <td>{dash.total_responses}</td>
                    <td>{((dash.total_responses / dash.total_triggered) * 100).toFixed(0)}%</td>
                    <td>{dash.total_responses ? (cumScore / dash.total_responses ).toFixed(2): 0}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="table-responsive csatTableBox">
              <table className="table">
                <thead>
                  <tr>
                    <th>Language</th>
                    <th>Responses Received</th>
                    <th>CSAT</th>
                  </tr>
                </thead>
                <tbody>
                  {languages && languages.length && languages.map((ele) => {
                    return <tr>
                      <td>{ele.lang_name}</td>
                      <td>{langWise[ele.lang_code]?.length}</td>
                      <td>{(langWise[ele.lang_code]?.length ? (langWise[ele.lang_code]?.reduce((a, b) => { return a + b.answer }, 0)) / langWise[ele.lang_code].length : 0).toFixed(2)}</td>
                    </tr>
                  })}

                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-12 my-3">
            <div className="table-responsive csatTableBox">
              <table className="table">
                <thead>
                  <tr>
                    <th>Language</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>Responses Received</th>
                  </tr>
                </thead>
                <tbody>
                  {languages && languages.length && languages.map((ele) => {
                    return <tr>
                      <td>{ele.lang_name}</td>
                      <td>{(langWise[ele.lang_code]?.length ? ((langWise[ele.lang_code]?.filter((q) => { return q.answer == 1 }).length) / langWise[ele.lang_code]?.length) * 100 : 0).toFixed(0)} %</td>
                      <td>{(langWise[ele.lang_code]?.length ? ((langWise[ele.lang_code]?.filter((q) => { return q.answer == 2 }).length) / langWise[ele.lang_code]?.length) * 100 : 0).toFixed(0)} %</td>
                      <td>{(langWise[ele.lang_code]?.length ? ((langWise[ele.lang_code]?.filter((q) => { return q.answer == 3 }).length) / langWise[ele.lang_code]?.length) * 100 : 0).toFixed(0)} %</td>
                      <td>{(langWise[ele.lang_code]?.length ? ((langWise[ele.lang_code]?.filter((q) => { return q.answer == 4 }).length) / langWise[ele.lang_code]?.length) * 100 : 0).toFixed(0)} %</td>
                      <td>{(langWise[ele.lang_code]?.length ? ((langWise[ele.lang_code]?.filter((q) => { return q.answer == 5 }).length) / langWise[ele.lang_code]?.length) * 100 : 0).toFixed(0)} %</td>
                      <td>{langWise[ele.lang_code]?.length}</td>
                    </tr>
                  })}


                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
    </div>
  )
}