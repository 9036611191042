import React,{ useState } from 'react'

const NewDesign = () => {
  const [image,setImage]=useState(0)

    return (
      
        <div className=''>
            <div className="modal fade" id="Secondmodal" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered size" style={{maxWidth:"400px"}}>
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            <h1 className="modal-title fs-5 orangeclr fw-normal" style={{ color: '#ff6a00' }} id="exampleModalToggleLabel">Edit Profile</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>

                        <div className="modal-body">
                            <div className='w-100'>
                                <div className='text-center'>
                                    <div className='profile-img'>
                                        <img  src='Images/alla.png'  width={"100px"}></img>
                                        <input type="file" id="upload" hidden/>
        
                                           <label for="upload" className='icon-set'>
                                            <i class="bi bi-pencil-square bg-white rounded fs-6 text-orange"></i>
                                            </label>
                                            {/* <div className='text-center'>
                                              <p>Edit Profile New</p>
                                            </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="successimg mt-3 px-3">
                                <form className="row g-3">
                                    <div className='w-100'>

                                        <input type="text" className="form-control p-4" id="inputPassword2" placeholder="Enter Your Name.." />
                                    </div>

                                    <div className="col">
                                        <select class="form-select w-100 p-2" aria-label="Default select example">
                                            <option selected>male </option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>

                                    </div>
                                    <div className="col">
                                        <select class="form-select w-100 p-2" aria-label="Default select example">
                                            <option selected>Jaipur </option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                  
                                    <div className='w-100'>

                                        <input type="text" className="form-control p-4" id="inputPassword2" placeholder="Enter Your Number..." />
                                    </div>

                                    <div className='w-100'>

                                        <input type="date" className="form-control p-4" id="inputPassword2" placeholder="Enter Your Name.." />
                                    </div>
                                    <div class="w-100 mb-3">
                                        
                                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>

                                </form>

                            </div>
    
                            <div className="goprofile text-center">
                                <button className="border-0 text-white px-5 py-3 rounded-1" style={{ backgroundColor: '#ff6a00' }}>Update </button>
                            </div>
                        </div>


                        {/* <div class="modal-footer">
                    <button class="btn btn-primary" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal">Open
                        second modal</button>
                </div> */}

                    </div>
                </div>
            </div>
            <a className="btn btn-primary" data-bs-toggle="modal" href="#Secondmodal" role="button">Second Modal</a>

            <div className="container-fluid">
        <div className="row justify-content-center mt-2">
          <div className="col"> 

            {/* <button className="border-0 text-white text-end ms-auto org-bg-clr px-3 py-1 d-flex align-items-center" style={{borderRadius: '2px'}}><img src="Images/1575c9756ee4b577d76452665191747a.svg" alt="image" width="12px" />
              &nbsp; Back</button> */}
          </div>

          <div className="col-md-8">

            <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex={0}>
                <div className="mann-nien border " style={{height: '100%'}}>
                  <div className="row ">
                    <div className="col-md-12 p-0">
                      <div className="Question p-3 py-1">
                        <div className="text-center">
                          <div className="lgo my-3 pb-2 shadow-sm">
                            <img src="Images/new-logo.png" alt="" width="130px" />
                          </div>
                        </div>
                        <div className=" px-md-5">
                          <div className="my-4">
                            <span className="fontsi14 p-2 rounded-1 fw-semibold" style={{backgroundColor: '#f4722150'}}>Category :
                              Multiple Choice</span>
                          </div>

                          <h5 className="text-black">1.&nbsp; Simple Dummy Test</h5>
                          <p className="text-black fontsi14 mb-4">Add description to your
                            question.(optional)</p>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="d-flex border-quest justify-content-between mb-2 p-2 qs align-items-center questions-bg shadow-sm">
                                <h6 className="text-black m-0">Dummy Text</h6><span>A</span>
                              </div>
                              {/*  */}
                              <div className="d-flex border-quest justify-content-between mb-2 p-2 qs align-items-center questions-bg shadow-sm">
                                <h6 className="text-black m-0">Dummy Text</h6><span>B</span>
                              </div>
                              {/*  */}
                              <div className="d-flex border-quest justify-content-between mb-2 p-2 qs align-items-center questions-bg shadow-sm">
                                <h6 className="text-black m-0">Dummy Text</h6><span>C</span>
                              </div>
                              {/*  */}
                              <div className="d-flex border-quest justify-content-between mb-2 p-2 qs align-items-center questions-bg shadow-sm">
                                <h6 className="text-black m-0">Dummy Text</h6><span>D</span>
                              </div>
                              {/*  */}
                            </div>
                            {/* <div className="col-md-7">
                              <div className="m">
                                <img src="Images/Mask Group 31.png" className="object-fit-cover object-fit-contain h-100 w-100" alt="image" />
                              </div>
                            </div> */}

                            <div className="h-100 d-flex justify-content-end my-3">
                              <div className="me-3">
                                <span className="pwd blue-clr fontsi14"> Powered by <b>Qdegrees</b>
                                </span>
                              </div>
                              <div className="blue-bg-clr rounded-1 px-2 py-1" style={{width: '75px'}}>
                                <a href="#" className="text-decoration-none pe-2 text-white">
                                  <i className="fa fa-chevron-down" aria-hidden="true" />
                                </a>
                                <div className="vr text-white" />
                                <a href="#" className="text-decoration-none ps-2 text-white">
                                  <i className="fa fa-chevron-up" aria-hidden="true" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="text-black fontsi14 mt-3 m-0 border p-2 rounded-1">simply dummy text
                          of the
                          printing and
                          typesetting industry. Lorem Ipsum has been the industry's standard
                          dummy
                          text ever since the 1500s, when an unknown printer took a galley</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          
              <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex={0}>
                <div className="Mobiles position-relative" style={{width: '250px', height: '480px', overflowY: 'auto', padding: '30px 0px 10px', margin: 'auto'}}>
                  {/* background-image: url('img/Phone.png'); background-repeat: no-repeat; background-size: contain; */}
                  <img src="Images/Group 25635.svg" alt="image" className="lgoph position-absolute w-100 top-0 left-0" style={{zIndex: -1, height: '100%'}} />
                  <div className="imag-phn scrl" style={{overflow: 'auto', overflowX: 'hidden', height: '100%'}}>
                    <div className="row">
                      <div className="Question p-3">
                        <div className="text-center">
                          <div className="lgo mt-1">
                            <img src="Images/new-logo.png" alt="" width="100px" />
                          </div>
                        </div>

                        
                        <hr className="text-secondary" />
                        <div className="px-md-4">
                          <span className="arow-next blue-bg-clr d-inline-block"><i className="bi bi-chevron-right text-white" /></span>
                          <h5 className="text-black fontsi14">1. Simple Dummy Test</h5>
                          <p className="text-black fontsi12 mb-4">Add description to your
                            question.(optional)</p>
                          <div className="d-flex flex-column-reverse">
                            <div className="col">
                              <div className="d-flex border-quest mb-2 p-2 qs align-items-center questions-bg shadow-sm">
                                <span>A</span>
                                <h6 className="text-black m-0 fontsi14 ps-3">Dummy Text</h6>
                              </div>
                              {/*  */}
                              <div className="d-flex border-quest mb-2 p-2 qs align-items-center questions-bg shadow-sm">
                                <span className>B</span>
                                <h6 className="text-black m-0 fontsi14 ps-3">Dummy Text</h6>
                              </div>
                              {/*  */}
                              <div className="d-flex border-quest mb-2 p-2 qs align-items-center questions-bg shadow-sm">
                                <span className>C</span>
                                <h6 className="text-black m-0 fontsi14 ps-3">Dummy Text</h6>
                              </div>
                              {/*  */}
                              <div className="d-flex border-quest mb-2 p-2 qs align-items-center questions-bg shadow-sm">
                                <span className>D</span>
                                <h6 className="text-black m-0 fontsi14 ps-3">Dummy Text</h6>
                              </div>
                              
                              {/*  */}
                            </div>

                            {/* <div className="col">
                              <div className="ment mb-2">
                                <img src="Images/Mask Group 31.png" className="object-fit-cover object-fit-contain h-100 w-100" alt="image" />
                              </div>
                            </div> */}

                          </div>
                          <button className="org-bg-clr rounded-1 w-100 border-0 text-white fontsi14 py-2">
                            Submit
                          </button>
                          
                          <span className="fontsi14 my-2 blue-clr text-center d-block">Powered by
                            <b>Qdegrees</b></span>
                            
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>  
            </div>

          </div>
          <div className="nav-tabs col fifty d-flex border-0" id="nav-tab" role="tablist">
            <div className=" p-0 m-0">
              <button className="nav-link active org-bg-clr py-2 px-2 rounded-0 fontsi14" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><i className="bi bi-display" /> Desktop</button>
            </div>
            <div className=" m-0 p-0">
              <button className="nav-link rounded-0 px-2 py-2 fontsi14" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"><i className="bi bi-phone" /> Mobile</button>
            </div>
          </div>
        </div>
      </div>

    

      {/* <div className='container-fluid'>
<div className='row'>
  <div className='col-md-12'>
  <div className="row no-gutters">
    
        <div className="col">
          <input className="form-control border-secondary border-right-0 rounded-0" type="search"placeholder="Search" id="example-search-input4" />
        </div>
        <div className="col-auto">
          <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right" type="button">
            <i className="fa fa-search" />
          </button>
        </div>

        <h5 class="modal-title text-orange" id="staticBackdropLabel">All Question Types</h5>

        <div className='col-sm-3'>
          <span className='new-text-color fs-6 '>Recommended</span>
          <div className='text-left'>

            <ul>
              <li style={{listStyle:"none"}}><img src='Images/Group 24253.svg'></img> Short Text</li>
            </ul>
</div>

        </div>

        <div className='col-sm-3'>
          <span className='new-text-color fs-6 '>Recommended</span>
          <div className='text-left'>

            <ul>
              <li style={{listStyle:"none"}}><img src='Images/Group 24253.svg'></img> Short Text</li>
            </ul>
</div>

        </div>

        <div className='col-sm-3'>
          <span className='new-text-color fs-6 '>Recommended</span>
          <div className='text-left'>

            <ul>
              <li style={{listStyle:"none"}}><img src='Images/Group 24253.svg'></img> Short Text</li>
            </ul>
</div>

        </div>

        <div className='col-sm-3'>
          <span className='new-text-color fs-6 '>Recommended</span>
          <div className='text-left'>

            <ul>
              <li style={{listStyle:"none"}}><img src='Images/Group 24253.svg'></img> Short Text</li>
            </ul>
</div>

        </div>
      </div>
  </div>
</div>

</div> */}


<div className='mt-3'>
  <div className='text-center'>
    <p>text-center1111 </p>
    <div className='dail-img mt-5'>

      <img className='img-d-1' onClick={()=>setImage(0)} src='images/Artboard – 2/Group 25457.svg'></img>
      <img className='img-d-2' onClick={()=>setImage(1)} src='images/Artboard – 2/Group 25458.svg'></img>
      <img className='img-d-3' onClick={()=>setImage(2)} src='images/Artboard – 2/Group 25459.svg'></img>
      <img className='img-d-4' onClick={()=>setImage(3)} src='images/Artboard – 2/Group 25460.svg'></img>
      <img className='img-d-5' onClick={()=>setImage(4)} src='images/Artboard – 2/Group 25461.svg'></img>

    </div>

    <div className='dail-img-2' style={{position:"relative", top:"45px"}}>
      {image==4&&(
    <img className='img-d-some' src='images/Artboard – 2/Group 25451.svg'></img>)}
    {image==3&&(
    <img className='img-d-some' src='images/Artboard – 2/Group 25452.svg'></img>)}
    {image==2&&(
    <img className='img-d-some' src='images/Artboard – 2/Group 25453.svg'></img>)}
    {image==1&&(
    <img className='img-d-some' src='images/Artboard – 2/Group 25454.svg'></img>)}
{image==0&&(
    <img className='img-d-some' src='images/Artboard – 2/Group 25455.svg'></img>)}

    </div>
  </div>

</div>
        </div>

    )

}
export default NewDesign