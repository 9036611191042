import React, { useState, useEffect } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
// import Header from '../../Components/header'
// import Footer from '../../Components/dashboard/footer'
import { Redirect, Link, useHistory } from "react-router-dom";
import Navbar from './navbar'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Moment from 'react-moment';

const SuccessPaytmVoucher = (props) => {

    const { } = props
    const api = new API()
    const history = new useHistory()

    const [pendingRedeemVoucher, setPendingRedeemVoucher] = useState([])

    useEffect(() => {
        getPaytmRedeem()
    }, [])


    const getPaytmRedeem = async () => {
        let result = await api.get(config.getPaytmRedeem)
       
        if (result && result.status == "success") {
        
            setPendingRedeemVoucher(result.data)
        }
    }
    
    const declineCoupon = async (email, couponeId) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to redeem points.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        
                      
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.warning("Request canceled")
                    }
                }
            ]
        })

    }
    return (


        <div>
            
            {pendingRedeemVoucher && pendingRedeemVoucher.map((voucherUser, index) => (
                <div className="container mt-5 col-md-10">
                    {voucherUser.redeemPoint.map((voucher, index) => (
                <div className="card mt-3">
                {/* {voucherUser.redeemPoint.map((voucher, index) => ( */}
                    {/* <div> */}
                    <div className="card-header">
                        <span>{index + 1} : {voucherUser.user}</span>
                    </div>
                    <div className="card-body">
                        {/* {voucherUser.redeemPoint.map((voucher, index) => ( */}
                            <div>
                                { voucher.voucherFor == "paytm" && voucher.status == "success" &&
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table">

                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Mobile Number</th>
                                                        <td>{voucher.paytmMobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Redeem Point</th>
                                                        <td>{voucher.RedeemPoint}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Voucher For</th>
                                                        <td>{voucher.voucherFor}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Status</th>
                                                        <td>{voucher.status}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Redeem Date</th>
                                                        <td><Moment format="YYYY/MM/DD - hh:mm:ss a">
                                                            {voucher.date}</Moment></td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <div className="card-footer">
                                            <button type="submit" onClick={() => approveCoupon(voucherUser.user, voucher)} className="btn btn-primary m-2">Accept</button>
                                            <button type="submit" onClick={() => declineCoupon(voucherUser.user, voucher._id)} className="btn btn-danger m-2">Decline</button>
                                        </div> */}
                                    </div>
                                 } 
                            </div>
                            
                         {/* ))} */}

                    </div>
                    

                </div>
                ))}
                </div>
            ))}
        </div>
     





    );
}
export default SuccessPaytmVoucher;