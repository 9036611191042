import React, { useState, useEffect } from 'react';

import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import { Redirect, Link, useHistory } from "react-router-dom";

const NotificationEmail = (props) => {
    const api = new API()
    const history = useHistory()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [notificationFor, setNotificationFor] = useState("redeemPoints")
    const [notificationEmail, setNotificationEmail] = useState([])
    const [AddNewEmail, setAddNewEmail] = useState(false)
    

    let adminToken = getLocalDataAsObject("adminToken")
    if (!adminToken) {
        history.push("/dashboard")
    }

    useEffect(() => {
        getNotificationEmail()
    }, [])

    const getNotificationEmail = async () => {
        let data = {
            notificationFor: notificationFor
        }
        const result = await api.post(config.adminDashboardgetNotificationEmail, data)
        if (result && result.code == 200) {
           
            setNotificationEmail(result.data)

        }
    }
    const addNotificationEmail = async () => {
        if (name == "" && email == "") {
            alert("Pls enter name and email")
        } else {

            let data = {
                name: name,
                email: email,
                notificationFor: notificationFor

            }
            const result = await api.post(config.adminDashboardAddEmailTemplateNotificationEmail, data)
            if (result && result.code === 200) {
                setName("")
                setEmail("")
                alert("success")
                getNotificationEmail()
                setAddNewEmail(false)
            

            }
        }
    }
    const addNewNotificationEmail = () =>{
        setAddNewEmail(true)
    }
    const editNotificationEmail = (val) => {
        setName(val.name)
        setEmail(val.email)
        setAddNewEmail(true)
    }
    const deleteNotificationEmail = async(val) =>{
        let data = {
            email: val.email,
            notificationFor: notificationFor
        }
        const result = await api.post(config.adminDashboardDeleteNotificationEmail, data)
        if (result && result.code === 200) {
            setName("")
            setEmail("")
            alert("success")
            getNotificationEmail()
            setAddNewEmail(false)
           

        }
    }



    return (
        <div>
            <div className="container">
                <div className="card">
                    <div className="card-header">
                        <h4 align="center">List of notification email while redeemed coupons someone</h4>
                    </div>
                    <div className="card-body">
                        <table class="table col-md-7">
                            <thead class="thead">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notificationEmail && notificationEmail.length > 0 && notificationEmail.map((val, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td>{val.name}</td>
                                        <td>{val.email}</td>
                                        <td><button onClick={()=>deleteNotificationEmail(val)} className="btn-danger mr-2">Delete</button></td>
                                        <td><button onClick={()=>editNotificationEmail(val)} className="btn-success">Edit</button></td>
                                    </tr>
                                ))

                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="card-footer">
                        <button className="btn-success" onClick={addNewNotificationEmail}>Add New</button>
                        {AddNewEmail && 
                        <form class="form-inline">
                            <div class="form-group">

                                <input type="text" class="form-control" placeholder="Enter name" id="name" onChange={(e) => setName(e.target.value)} value={name} />
                            </div>
                            <div class="form-group mx-sm-3">

                                <input type="text" class="form-control" id="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} value={email} />
                            </div>
                            <button type="submit" onClick={addNotificationEmail} class="btn btn-primary btn-small">Submit</button>
                        </form>
                        }
                    </div>
                </div>
            </div>
        </div>

    );
}

export default NotificationEmail;