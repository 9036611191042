import React, { useState, useEffect } from 'react';
// import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { toast } from 'react-toastify';
import { setLocalDataAsObject, getLocalDataAsObject } from '../../utils/CustomLocalStorage';
import Header from '../../Components/ReusableComponents/Header';
import Footer from '../../Components/ReusableComponents/Footer';
import { Redirect, Link, useHistory } from "react-router-dom";
// import Navbar from './navbar'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Config from '../../config/env'

const DeclinePaytmVoucher = (props) => {

    const { } = props
    const api = new API()
    const history = new useHistory()

    const [pendingRedeemVoucher, setPendingRedeemVoucher] = useState([])

    useEffect(() => {
        getPaytmRedeem()
      
    }, [])


    const getPaytmRedeem = async () => {
        let result = await api.get(`${Config.new.site_api}/common/surveyActiveNotification/admin-dashboard_paytm/getPaytmRedeem`)
        if (result && result.status == "success") {
         
            setPendingRedeemVoucher(result.data)
        }
    }
    
    const declineCoupon = async (email, couponeId) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to redeem points.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        
                      
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        toast.warning("Request canceled")
                    }
                }
            ]
        })

    }
    return (


        <div>

            {pendingRedeemVoucher && pendingRedeemVoucher.map((voucherUser, index) => (
                <div className="container mt-5 col-md-10">
                <div className="card mt-3">
                    <div className="card-header">
                        <span>{index + 1} : {voucherUser.user}</span>
                    </div>
                    <div className="card-body">
                        {voucherUser.redeemPoint.map((voucher, index) => (
                            <div>
                                { voucher.voucherFor == "paytm" && voucher.status == "decline" &&
                                    <div className="card">
                                        <div className="card-body">
                                            <table class="table">

                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Mobile Number</th>
                                                        <td>{voucher.paytmMobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Redeem Point</th>
                                                        <td>{voucher.RedeemPoint}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Voucher For</th>
                                                        <td>{voucher.voucherFor}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Status</th>
                                                        <td>{voucher.status}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Redeem Date</th>
                                                        <td>{voucher.date}</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </div>
                                        {/* <div className="card-footer">
                                            <button type="submit" onClick={() => approveCoupon(voucherUser.user, voucher)} className="btn btn-primary m-2">Accept</button>
                                            <button type="submit" onClick={() => declineCoupon(voucherUser.user, voucher._id)} className="btn btn-danger m-2">Decline</button>
                                        </div> */}
                                    </div>
                                }
                            </div>
                        ))}

                    </div>

                </div>
</div>
            ))}
        </div>
     





    );
}
export default DeclinePaytmVoucher;