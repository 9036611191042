import React, { useEffect, useState } from "react";
import Header from "../Components/ReusableComponents/Header";
import Header2 from "../Components/ReusableComponents/Header2";
import Multiselect from "multiselect-react-dropdown";
import API from "../utils/apiCalling";
import config from "../config/env";
import { useHistory } from "react-router-dom";

const SurveyTemplets = () => {
  let api = new API();
  let history = useHistory();

  const [templates, setTemplates] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setCategory] = useState({});
  const [filteredCategory, setFilteredCategory] = useState();
  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    api.post(`${config.new.site_api}/client-survey/getTemplateSurvey`, {})
      .then((res) => {
        if (res.code === 200) {
          setCategories(res.data);
          setCategory(res.data[0]);
          getTemplates(res.data[0]._id);
          setFilteredCategory(res.data);
        }
      });
  };
  const filterCategory = (value) => {
    let temp = [];
    if (value === "") {
      setFilteredCategory(categories);
    } else {
      categories.forEach((element) => {
        if (element.category.toLowerCase().includes(value.toLowerCase())) {
          temp.push(element);
        }
      });
      setFilteredCategory(temp);
    }
  };
  const getTemplates = (id) => {
    let obj = {
      category_id: id,
    };
    api.post(`${config.new.site_api}/client-survey/getTemplateSurvey`, obj)
      .then((res) => {
        if (res.code === 200) {
          setTemplates(res.data);
        }
      });
  };

  return (
    <>
      <Header2 />
      {/* scratch modal */}
      <div
        className="modal overflow-auto fade survey-popup"
        id="from-scratch-modaltmplt"
        data
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        //  style={{display:"block", opacity:"1"}}
      >
        <div className="modal-dialog h-100 modal-dialog-centered survey-title-modal">
          <div className="modal-content">
            <div className="modal-header pb-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Create a new survey
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                // ref={closeModel}
              />
            </div>
            <div className="modal-body text-center arownew p-4 model-image-newsurvey">
              <div className="surForm d-flex w-100 justify-content-between">
                <div className="suresForm">
                  <label className="text-start">Survey Name</label>
                  <form className="form-group w-100">
                    <input
                      type="text"
                      className="form-control"
                      // onChange={(e) => setSurveyTitle(e.target.value)}
                      id="exampleInputPassword1"
                      placeholder="Enter Survey Name"
                    />
                    <span
                      style={{
                        color: "red",
                        width: "100%",
                        display: "block",
                        textAlign: "left",
                      }}
                    >
                      {/* {message} */}
                    </span>
                  </form>
                </div>
                {/* <lottie-player src="/../images/6c4fd321-e509-4d3a-a3ff-8da5bea55f10.json" background="transparent" speed={1} style={{ width: '250px', height: '250px' }} loop autoPlay /> */}
                <div className="suresForm">
                  <label className="text-start">
                    Expected Incidence Rate
                    <span>
                      <i className="bi ms-2 bi-question-circle-fill"></i>
                      <span className="quesnmsw">Lorem Ipsum Dolor</span>
                    </span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="formGroupExampleInput"
                    // readOnly={active}
                    // onChange={(e) => setExIncidence(e.target.value)}
                    placeholder="Number Type"
                  />
                  <span
                    style={{
                      color: "red",
                      width: "100%",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {/* {message4} */}
                  </span>
                </div>
              </div>
              <div className="surForm d-flex w-100 justify-content-between">
                <div className="suresForm">
                  <label className="text-start">
                    Survey Audience
                    <span>
                      <i className="bi ms-2 bi-question-circle-fill"></i>
                      <span className="quesnmsw">Lorem Ipsum Dolor</span>
                    </span>
                  </label>
                  <select
                    className="form-control survey_border border-white"
                    id="formGroupExampleInput"
                    // onChange={(e) => setAudienceHandle(e.target.value)}
                    placeholder="Survey Category"
                  >
                    <option default>Select Audience</option>
                    <option value="Buy Audience">Buy Audience</option>
                    <option value="My Own Audience">My Own Audience</option>
                  </select>
                  <span
                    style={{
                      color: "red",
                      width: "100%",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {/* {message1} */}
                  </span>
                </div>
                <div className="suresForm">
                  <label className="text-start">
                    Expected Completes
                    <span>
                      <i className="bi ms-2 bi-question-circle-fill"></i>
                      <span className="quesnmsw">Lorem Ipsum Dolor</span>
                    </span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="formGroupExampleInput"
                    // readOnly={active}
                    // onChange={(e) => setExCompletes(e.target.value)}
                    placeholder="Number Type"
                  />
                  <span
                    style={{
                      color: "red",
                      width: "100%",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {/* {message3} */}
                  </span>
                </div>
              </div>
              <div className="surForm d-flex w-100 justify-content-between">
                <div className="suresForm">
                  <label className="text-start">
                    Survey Category
                    <span>
                      <i className="bi ms-2 bi-question-circle-fill"></i>
                      <span className="quesnmsw">Lorem Ipsum Dolor</span>
                    </span>
                  </label>
                  <select
                    className="form-control survey_border border-white"
                    id="formGroupExampleInput"
                    // onChange={(e) => setSurveyCategory(e.target.value)}
                    placeholder="Survey Category"
                  >
                    <option default>Select Category</option>
                    {/* {surveycategory &&
                            surveycategory.map((e) => (
                              <option value={e._id}>{e.category}</option>
                            ))} */}
                  </select>
                  <span
                    style={{
                      color: "red",
                      width: "100%",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {/* {message2} */}
                  </span>
                </div>
                <div className="suresForm">
                  <label className="text-start">
                    Expected Completion LOI
                    <span>
                      <i className="bi ms-2 fs-7 bi-question-circle-fill"></i>
                      <span className="quesnmsw">Lorem Ipsum Dolor</span>
                    </span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="formGroupExampleInput"
                    // readOnly={active}
                    // onChange={(e) => setExLOI(e.target.value)}
                    placeholder="Number Type"
                  />
                  <span
                    style={{
                      color: "red",
                      width: "100%",
                      display: "block",
                      textAlign: "left",
                    }}
                  >
                    {/* {message5} */}
                  </span>
                </div>
              </div>

              <div className="surForm d-flex w-100 justify-content-between">
                <div className="suresForm">
                  <label className="text-start">Languages</label>
                  <form className="form-group w-100">
                    <Multiselect
                      className="survey_border border-white"
                      isObject={false}
                      // onRemove={(e)=> SelectLng(e,"setData")}
                      // onSearch={(e)=> SelectLng(e,"setData")}
                      // onSelect={(e)=> SelectLng(e,"setData")}
                      // // options={lang}
                      placeholder="Select Languages"
                    />
                  </form>
                </div>
              </div>

              <div className="text-center py-4">
                {/* onClick={addDasgboard} > */}
                <a
                  className="mainbtn text-white"
                  // data-bs-dismiss="modal"
                  // aria-label="Close"
                >
                  Create Survey
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="surveytemplets">
        <div className="surveys-bar bg-white">
          <div className="tab-content" id="myTabContent">
            <div className="layout-grid-content justify-content-center">
              <div class="wrapper">
                <nav id="sidebarl" className="mt-3 mystiHeader myHeadRefun com-bsl">
                  <div className="position-relative  d-flex flex-column justify-content-between">
                    <div className="f-14 py-3">
                      <h5 className="text-orange mb-0">Template's Category</h5>
                      <small>We have Some Templates Ready For you here</small>
                      <div class="search-container position-relative my-2">
                        {/* <button
                          type="button"
                          id="sidebarCollapse"
                          class="btn border-0 text-blue"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            fill="white"
                            class="bi bi-arrow-left-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                            ></path>
                          </svg>
                        </button> */}
                        <form class="bg-lgrey d-flex align-items-center">
                          <button type="submit" class="border-0">
                            <img
                              src="../images/Icon feather-search.svg"
                              alt=""
                              class="m-2 w-50"
                            />
                          </button>
                          <input
                            type="text"
                            placeholder="Search"
                            name="search"
                            class="bg-lgrey form-control"
                            onChange={(e) => filterCategory(e.target.value)}
                          />
                        </form>
                      </div>
                    </div>
                    <div className="intconq">
                      {/* <button
                        type="button"
                        id="sidebarCollapsel"
                        className="btn border-0 text-blue"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={12}
                          height={12}
                          fill="white"
                          className="bi bi-arrow-left-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                          />
                        </svg>
                      </button> */}
                      {filteredCategory &&
                        filteredCategory.length > 0 &&
                        filteredCategory.map((template) => (
                          <div
                            className={
                              selectedCategory._id == template._id
                                ? "questions-tab py-3 active"
                                : "questions-tab py-3 "
                            }
                            onClick={(e) => {
                              setCategory(template);
                            }}
                            style={{
                              borderBottom:
                                "1px solid rgba(175, 175, 175, 0.314)",
                            }}
                          >
                            <div
                              className="question-count d-flex justify-content-between align-items-center"
                              onClick={(e) => getTemplates(template._id)}
                            >
                              <div className="d-flex align-items-center">
                                <p className="f-12 m-0 px-2">
                                  {template.category}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="upload-logo uploadLogoBox ">
                    <span className="ant-upload-picture-card-wrapper avatar-uploader">
                      <div className="ant-upload ant-upload-select ant-upload-select-picture-card antTemplts">
                        <span tabIndex={0} className="ant-upload" role="button">
                          {" "}
                          {/* <input type="file" accept style={{ display: "none" }} /> */}{" "}
                          <div>
                            <div
                              className="d-flex flex-column justify-content-around align-items-center"
                              data-bs-toggle="modal"
                              data-bs-target="#from-scratch-modaltmplt"
                            >
                              <img
                                src="../images/idea.svg"
                                alt="Img"
                                width={"50px"}
                              />
                              {/* <lottie-player src="/../images/ideas.json" background="transparent" speed={1} style={{ width: '50px', height: '50px' }} loop autoPlay /> */}
                              <p className="m-0 f-12 mt-3">
                                Have your own idea in mind?
                              </p>
                              <a
                                className="text-primary"
                                style={{ textDecoration: "underline" }}
                              >
                                Start Blank Survey
                              </a>
                            </div>
                          </div>
                        </span>
                      </div>
                    </span>
                  </div>
                </nav>
                      {/* Categories Small sc offcanvas */}
                      <div class="offcanvas offcanvas-start " tabindex="-1" id="sidecnvsvsb" aria-labelledby="offcanvasExampleLabel">
                        <div class="offcanvas-header position-absolute w-100">
                          <button type="button" class="btn-close position-absolute" data-bs-dismiss="offcanvas" aria-label="Close" style={{bottom:"-17px",right:"14px"}}></button>
                        </div>
                        <div class="offcanvas-body offMantance overflow-hidden">
                          <nav id="sidebarl" className="mystiHeader">
                            <div className="position-relative  d-flex flex-column justify-content-between">
                              <div className="f-14 py-3">
                                <h5 className="text-orange mb-0">Template's Category</h5>
                                <small>We have Some Templates Ready For you here</small>
                                <div class="search-container position-relative my-2">
                                  {/* <button
                                    type="button"
                                    id="sidebarCollapse"
                                    class="btn border-0 text-blue"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="white"
                                      class="bi bi-arrow-left-right"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                                      ></path>
                                    </svg>
                                  </button> */}
                                  <form class="bg-lgrey d-flex align-items-center">
                                    <button type="submit" class="border-0">
                                      <img
                                        src="../images/Icon feather-search.svg"
                                        alt=""
                                        class="m-2 w-50"
                                      />
                                    </button>
                                    <input
                                      type="text"
                                      placeholder="Search"
                                      name="search"
                                      class="bg-lgrey form-control"
                                      onChange={(e) => filterCategory(e.target.value)}
                                    />
                                  </form>
                                </div>
                              </div>
                              <div className="intconq">
                                {/* <button
                                  type="button"
                                  id="sidebarCollapsel"
                                  className="btn border-0 text-blue"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={12}
                                    height={12}
                                    fill="white"
                                    className="bi bi-arrow-left-right"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
                                    />
                                  </svg>
                                </button> */}
                                {filteredCategory &&
                                  filteredCategory.length > 0 &&
                                  filteredCategory.map((template) => (
                                    <div
                                      className={
                                        selectedCategory._id == template._id
                                          ? "questions-tab py-3 active"
                                          : "questions-tab py-3 "
                                      }
                                      onClick={(e) => {
                                        setCategory(template);
                                      }}
                                      style={{
                                        borderBottom:
                                          "1px solid rgba(175, 175, 175, 0.314)",
                                      }}
                                    >
                                      <div
                                        className="question-count d-flex justify-content-between align-items-center"
                                        onClick={(e) => getTemplates(template._id)}
                                      >
                                        <div className="d-flex align-items-center">
                                          <p className="f-12 m-0 px-2">
                                            {template.category}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <div className="upload-logo uploadLogoBox ">
                              <span className="ant-upload-picture-card-wrapper avatar-uploader">
                                <div className="ant-upload ant-upload-select ant-upload-select-picture-card antTemplts">
                                  <span tabIndex={0} className="ant-upload" role="button">
                                    {" "}
                                    {/* <input type="file" accept style={{ display: "none" }} /> */}{" "}
                                    <div>
                                      <div
                                        className="d-flex flex-column justify-content-around align-items-center"
                                        data-bs-toggle="modal"
                                        data-bs-target="#from-scratch-modaltmplt"
                                      >
                                        <img
                                          src="../images/idea.svg"
                                          alt="Img"
                                          width={"50px"}
                                        />
                                        {/* <lottie-player src="/../images/ideas.json" background="transparent" speed={1} style={{ width: '50px', height: '50px' }} loop autoPlay /> */}
                                        <p className="m-0 f-12 mt-3">
                                          Have your own idea in mind?
                                        </p>
                                        <a
                                          className="text-primary"
                                          style={{ textDecoration: "underline" }}
                                        >
                                          Start Blank Survey
                                        </a>
                                      </div>
                                    </div>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </nav>
                        </div>
                      </div>
                      {/* Categories Small sc offcanvas */}
                <div id="content" class="com-bsl scratsurmid mt-3 scratMie mansa">
                  <div class="tab-content" id="aligned-survey">
                    <h5 className="tabuisa text-blue fw-bold d-flex justify-content-between align-items-center"><span>Business</span><button
                      className="mainbtn actYuas acyus"
                      type="button"
                      data-bs-toggle="offcanvas" data-bs-target="#sidecnvsvsb" aria-controls="offcanvasExample"
                      style={{lineHeight:"1",padding: "2px 5px"}}
                    >
                    <i class="bi bi-list" style={{fontSize:"26px"}}></i>
                    </button></h5>

                    <div className="row">
                      {templates &&
                        templates.length > 0 &&
                        templates.map((template) => (
                          <div className="col-md-4 my-3">
                            <div
                              className={
                                selectedCategory._id == template._id
                                  ? "card ContentTap1 active "
                                  : "card ContentTap1 h-100"
                              }
                              onClick={(e) => setCategory(template)}
                            >
                              <div
                                className="UnnderItems text-center"
                                onClick={(e) =>
                                  history.push(`/template/${template._id}`)
                                }
                              >
                                <img
                                  src={
                                    template.logo
                                      ? template.logo
                                      : "../images/Surve1.png"
                                  }
                                  alt="Img"
                                />
                                <h5
                                  className="text-orange mb-1"
                                  style={{ cursor: "pointer" }}
                                >
                                  {" "}
                                  {template.survey_name}{" "}
                                </h5>
                                <p className="mb-0 f-5">
                                  {template.questions.length} Questions
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SurveyTemplets;
