import '../../App.css';

import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Header() {
     const [show, setShow] = useState(false);



     useEffect(() => {
          const user = JSON.parse(localStorage.getItem("user"))

          if (user && (user.userType == `client` || user.userType == `admin`)) {
               setShow(false);
          } else {
               setShow(true)
          }
     })

     return (
          <>
               {/* <section>

                    
                    <div className="topBar w-100 ">
                         <div className="container">
                              <div className="row" style={{ position: 'relative', padding:"6px" }}>
                                   <div className="col-md-4 col-lg-6 mx-auto ">
                                   <p className="text-white text-center mb-0 fs-6">All that Construction needs3</p>
                                   </div>
                                   <div className='col-lg-4 mx-auto'>
                                   <ul className="d-flex mb-0 justify-content-end detailTopView">
                                        <li><a href="#"><i className="fa fa-phone" aria-hidden="true" /> +91 7878159468</a></li>
                                        <li><a href="#"><i className="fa fa-envelope" aria-hidden="true" /></a></li>
                                        <li> <a href="#">Get App</a></li>
                                    </ul>
                                   </div>
                              </div>
                         </div>
                    </div>
                    <div data-index={0} className="slick-slide" tabIndex={-1} aria-hidden="true" style={{ outline: 'none', width: '546px' }}></div>
                    <div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs</p></div></div></div><div data-index={1} className="slick-slide" tabIndex={-1} aria-hidden="true" style={{ outline: 'none', width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs2</p></div></div></div><div data-index={2} className="slick-slide slick-active slick-current" tabIndex={-1} aria-hidden="false" style={{ outline: 'none', width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs3</p></div></div></div><div data-index={3} tabIndex={-1} className="slick-slide slick-cloned" aria-hidden="true" style={{ width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs</p></div></div></div><div data-index={4} tabIndex={-1} className="slick-slide slick-cloned" aria-hidden="true" style={{ width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs2</p></div></div></div><div data-index={5} tabIndex={-1} className="slick-slide slick-cloned" aria-hidden="true" style={{ width: '546px' }}><div><div className tabIndex={-1} style={{ width: '100%', display: 'inline-block' }}><p className="text-black text-center mb-0">All that Construction needs3</p></div></div></div></div><ul className="d-flex mb-0 justify-content-end detailTopView"><li><a href="#"><i className="fa fa-phone" aria-hidden="true" /> +91 7878159468</a></li><li><a href="#"><i className="fa fa-envelope" aria-hidden="true" /></a></li><li> <a href="#">Get App</a></li></ul>
               </section> */}

               <section id="all-section nav-section" style={{zIndex:"1", position:"relative"}}>

                    <nav className="navbar menuglta navbar-expand-lg py-2 w-100 fadeInDown">
                         <div className="container-fluid">
                              <a className="navbar-brand" href="/">
                                   <img src="../images/new-logo.png" width={"35%"} alt="" ></img>
                              </a>
                              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                   <span className="navbar-toggler-icon" />
                              </button>
                              <div className="" id="navbarSupportedContent">
                                   <form className="ms-auto" role="search">
                                        <Link to="/demo">
                                             {/* <button className="mainbtn mx-3 fadeInUp" href="#" target="_self">
                                                  Book a Demo
                                             </button> */}
                                        </Link>
                                        {show ?

                                             <Link to="/login"> <button className="mainbtn fadeInUp" href="#" target="_self">
                                                  Sign In

                                             </button>
                                             </Link> : null}

                                   </form>
                              </div>
                         </div>
                    </nav>
               </section>

          </>
     )
}

export default Header

